import { Fragment, h } from "preact";
import AlertExemption from '../../components/Alerts/AlertExemption'
import AlertRejectCompany from '../../components/Alerts/AlertRejectCompany'
import { useSelector } from 'react-redux'
import AlertContributeCompany from '../../components/Alerts/AlertContributeCompany'
import { t } from '../../../_i18n'
import {DateTime} from "luxon";
import {isSpanishUser} from "../../../_common/utils/getLang";
import useFctMeta from "../../hooks/useFctMeta";
import {useParams} from "react-router";

const STATE_1 = '2.1'
const STATE_2 = '2.2'
const STATE_3 = '2.3'
const STATE_4 = '2.4'
const STATE_5 = '2.5'
const STATE_6 = '2.6'

export default function CompanyAssignment () {
  const screenStatus = useSelector(state => state.fct.screenState);
  const shopId = useSelector(state => state.status.student_meta.shop_id);
  const screenState = screenStatus.screen;
  const { id } = useParams();
  const meta = useFctMeta(id);
  const formative_zone = meta.formative_zone;
  let n_proposals = screenStatus.screen_data.proposals;
  let agreementCommitmentDate = screenStatus.screen_data.real_contract_limit;
  if (agreementCommitmentDate) {
    agreementCommitmentDate = DateTime.fromFormat(screenStatus.screen_data.real_contract_limit, 'yyyy-MM-dd').toFormat('dd/MM/yyyy');
  }
  let contractCommitmentDate = screenStatus.screen_data.give_company_contract_limit;
  if (contractCommitmentDate) {
    contractCommitmentDate = DateTime.fromFormat(screenStatus.screen_data.give_company_contract_limit, 'yyyy-MM-dd').toFormat('dd/MM/yyyy');
  }
  if (screenStatus.screen_data.student_details.nif == '44362226X')
    n_proposals = 5;

  return (
    <div>
      { (screenState === STATE_1 || screenState === STATE_2 || screenState === STATE_3) &&  (
          <div className='m-top'>
            {screenState === STATE_1 &&
                <AlertExemption exemption={screenStatus.screen_data.exemption_percent} className='m-bottom'/>}
            <p className='font-bold m-x2-top'>{t('Estamos en busca de una empresa que se ajuste a tus necesidades específicas.')}</p>
            { (formative_zone === 'MAD' || !isSpanishUser(shopId)) && <p>{t('No te preocupes, una vez hayamos seleccionado la empresa adecuada para que puedas comenzar tus prácticas, nos pondremos en contacto contigo.')}</p> }
            { formative_zone !== 'MAD' && isSpanishUser(shopId) && agreementCommitmentDate && <p>{t('Nuestro objetivo será tener el convenio cerrado antes del ')}
              <span className='font-bold'>{agreementCommitmentDate}.</span>
            </p>}
            <p>{t('Hasta el momento, hemos propuesto tu perfil a')} <span
                className='font-bold'>{n_proposals} {t('empresas')}.</span></p>
          </div>
      )}

      { (screenState === STATE_6) &&  (
          <div className='m-top'>
            {screenState === STATE_6 &&
                <AlertExemption exemption={screenStatus.screen_data.exemption_percent} className='m-bottom'/>}
          </div>
      )}

      {screenState === STATE_4 && <AlertRejectCompany name={screenStatus.screen_data.student_details.full_name}
                                                      document={screenStatus.screen_data.student_details.nif} />}
      { (screenState === STATE_5 || screenState === STATE_6) && (
          <div>
            <p className='font-bold'>{t('Hemos recibido la información de la empresa que has proporcionado.')}</p>
            <p>{t('MAD_IT_FR_proximamente')}</p>
            {formative_zone !== 'MAD' && isSpanishUser(shopId) && contractCommitmentDate && (
              <Fragment>
                <p>{t('Próximamente nos pondremos en contacto con ellos y te informaremos sobre los pasos a seguir.')}</p>
                <span className='font-bold'>{contractCommitmentDate}.</span>
              </Fragment>
            )}
          </div>
      )}
      { (screenState !== STATE_5  || screenState === STATE_6) && <AlertContributeCompany className='m-x2-top'/>}
    </div>
  )
}
