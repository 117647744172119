/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CompanyNewCompany model module.
 * @module model/CompanyNewCompany
 * @version v1
 */
class CompanyNewCompany {
    /**
     * Constructs a new <code>CompanyNewCompany</code>.
     * Solo para el post de la landing [no hay diseño de pantalla]
     * @alias module:model/CompanyNewCompany
     */
    constructor() { 
        
        CompanyNewCompany.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CompanyNewCompany</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CompanyNewCompany} obj Optional instance to populate.
     * @return {module:model/CompanyNewCompany} The populated <code>CompanyNewCompany</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CompanyNewCompany();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('nif')) {
                obj['nif'] = ApiClient.convertToType(data['nif'], 'String');
            }
            if (data.hasOwnProperty('text')) {
                obj['text'] = ApiClient.convertToType(data['text'], 'String');
            }
            if (data.hasOwnProperty('contact_email')) {
                obj['contact_email'] = ApiClient.convertToType(data['contact_email'], 'String');
            }
            if (data.hasOwnProperty('contact_password')) {
                obj['contact_password'] = ApiClient.convertToType(data['contact_password'], 'String');
            }
            if (data.hasOwnProperty('contact_name')) {
                obj['contact_name'] = ApiClient.convertToType(data['contact_name'], 'String');
            }
            if (data.hasOwnProperty('contact_surname')) {
                obj['contact_surname'] = ApiClient.convertToType(data['contact_surname'], 'String');
            }
            if (data.hasOwnProperty('contact_phone')) {
                obj['contact_phone'] = ApiClient.convertToType(data['contact_phone'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} name
 */
CompanyNewCompany.prototype['name'] = undefined;

/**
 * @member {String} nif
 */
CompanyNewCompany.prototype['nif'] = undefined;

/**
 * @member {String} text
 */
CompanyNewCompany.prototype['text'] = undefined;

/**
 * @member {String} contact_email
 */
CompanyNewCompany.prototype['contact_email'] = undefined;

/**
 * @member {String} contact_password
 */
CompanyNewCompany.prototype['contact_password'] = undefined;

/**
 * @member {String} contact_name
 */
CompanyNewCompany.prototype['contact_name'] = undefined;

/**
 * @member {String} contact_surname
 */
CompanyNewCompany.prototype['contact_surname'] = undefined;

/**
 * @member {String} contact_phone
 */
CompanyNewCompany.prototype['contact_phone'] = undefined;






export default CompanyNewCompany;

