import React, { Fragment, Component, h} from "preact";
import {t} from '../../../_i18n';
import {connect} from 'react-redux';
import DropdownBadge from '../../../_common/components/DropdownBadge/DropdownBadge';
import {useState} from "preact/hooks";
import ModalCertificateClasses from "../../classes/components/ModalCertificate";

function HeaderClasses ({ studentMail, classesStatus, locale }) {
  const allowDownloadClassCertificate = classesStatus.current?.class_certificate || classesStatus.next?.class_certificate || classesStatus.certificates_academic_agreements?.length > 0
  const allowDownloadJoinedCertificate = classesStatus.current?.joined_certificate || classesStatus.next?.joined_certificate || classesStatus.certificates_academic_agreements?.length > 0
  const showDropdown = allowDownloadClassCertificate || allowDownloadJoinedCertificate
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')

  const openModal = (type) => {
    setModalType(type)
    setShowModal(true)
  }

  const handleClose = () => {
    setShowModal(false)
    setModalType('')
  }

  return (
    <div className='d-flex flex-center-y flex-space-between'>
      <h1 className='hero'>{t('Clases presenciales')}</h1>
      {showDropdown &&
        <DropdownBadge title={
          <Fragment>
            <span className='d-none sm-d-line'><i className="far fa-file-download font-color-555555" />&nbsp;&nbsp;{t('Descargar')}&nbsp;</span>
            <span className='sm-d-none'><i className="far fa-file-download font-color-555555" /></span>
          </Fragment>
        }>
          {allowDownloadClassCertificate && <div className='dropdown-item' onClick={() => openModal('class')}>{t('Descargar justificante de asistencia')}</div>}
          {allowDownloadJoinedCertificate && <div className='dropdown-item' onClick={() => openModal('joined')}>{t('Descargar justificante de inscripción')}</div>}
        </DropdownBadge>
      }
        <ModalCertificateClasses showModal={showModal} onClose={handleClose} type={modalType} studentMail={studentMail} locale={locale} academicSections={classesStatus.certificates_academic_agreements}/>
    </div>
  )
}

export default connect(
  ({status, enrollmentClasses, lang})=>({
    studentMail: status.student_meta.student_email,
    classesStatus: enrollmentClasses.status,
    locale: lang
  }),
  {})(HeaderClasses);
