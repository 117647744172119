export const EDUCATION_LAW_LOGSE = 'LOGSE'
export const EDUCATION_LAW_LOE = 'LOE'

export const OPT_GRADE_CERTIFICATE = 'grade_certificate'
export const OPT_ATTENDANCE_RECEIPT = 'attendance_receipt'

export const EDUCATION_LAW_DIC = {
    1: 'LOE',
    2: 'LOGSE',
    3: 'S&Y Pro',
    4: 'S&Y University',
    5: 'LOMCE',
    6: 'UCAM'
}
