import { h } from 'preact'
import '../UploadDocument/upload-document-styles.scss'
import { strHash } from '../../../_common/common'
import { downloadFileFtp, saveDocument } from '../../../_rx/fct/actions'
import { toastService } from '../../../_services/toast-service'
import { t } from '../../../_i18n'
import { FILE_ACCEPT_FILES } from '../../constants'
import {useState} from "preact/hooks";

export default function UploadDocumentTimeline ({ className, disabled, name = 'files', input, contractId }) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const id = strHash(name);

  const handleChange = (val) => {
    const files = val.target.files
    Promise.all(Object.values(files).map(file => saveDocument(contractId, file, 'attachment')))
      .then(res => {
        setUploadedFiles(prevFiles => [...prevFiles, ...res]);
        input.onChange([...uploadedFiles, ...res]);
      })
      .catch(() => toastService.showToast({message: t('Ha sucedido un error con la subida del archivo, prueba de nuevo'), type: 'danger'}))
  }

  const handleRemove = (id) => {
    setUploadedFiles(prevFiles => prevFiles.filter(file => file.id !== id))
    input.onChange(uploadedFiles)
  }

  return (
    <div className={`fct-upload-document ${className}`}>
      {uploadedFiles.map( file => (
        <div className='fct-upload-document__value m-bottom-half'>
          <div className='font-bold t-underline'><span className='cursor-pointer' onClick={() => downloadFileFtp(file.id, file.file_name)}>{file.file_name}</span></div>
          <div className='font-bold t-underline cursor-pointer font-color-149BAB' onClick={() => handleRemove(file.id)}>{t('Eliminar')}</div>
        </div>
      ))}
      <div className='m-top-x2'><label className='btn btn-ghost width-fit-content' htmlFor={!disabled && id}>{t('Adjuntar archivo')}</label></div>
      <input type="file" id={id} onChange={handleChange} accept={FILE_ACCEPT_FILES} multiple value='' />
    </div>
  )
}
