import {Component, h } from "preact";
import {Card, CF, E, FR, R, RE} from "../../_common/layout";
import {connect} from "react-redux";
import {fetchHistorialMatriculas} from "../../_rx/historial-matriculas/actions";
import {Link} from "react-router-dom";
import {formatCurrency, formatDDMMYYYY} from "../../_common/common.ts";
import Badge from "../../_common/components/Badge";



let OrderHistory = class extends Component {
    componentDidMount() {
        this.props.fetchHistorialMatriculas();
    }

    render({historial_matriculas}) {
        historial_matriculas = historial_matriculas || [];

        return (
            <CF class="max-width-sm">
                <Card smOnly={false} className="p-bottom">
                    {historial_matriculas.map(matricula =>
                        <Link to={"/enrollments/" + matricula.Referencia} className="no-decoration txt-color-black">
                            <R class=" " key={matricula.Referencia}>
                                <E class="col-40">
                                    {matricula.Referencia} <br/>
                                    <small>{formatDDMMYYYY(matricula.Fecha)}</small>
                                </E>
                                <E class="col-25">
                                    <Badge type={matricula.EstadoBadge}>{matricula.Estado}</Badge>
                                </E>
                                {["1", "17", "23", "24", "31", "44", "47", "16", "32"].includes(matricula.shop) ?
                                    <E class="col-35 t-right pa">
                                        <span className="price">{formatCurrency(matricula.Importe)}</span> &nbsp;
                                        <i className="fa fa-chevron-right txt-color-black" />
                                    </E>
                                    : ''}
                            </R>
                        </Link>
                    )}
                </Card>
            </CF>
        )

    }
};

OrderHistory = connect(
    ({historial_matriculas})=>({historial_matriculas}),
    {fetchHistorialMatriculas})(OrderHistory);

export default OrderHistory;
