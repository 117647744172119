import React, { Fragment, Component, h} from "preact";
import {isMobileAgent} from '../../_common/common';

export default function useScroll () {

  const focusDropdown = () => {
    if (isMobileAgent()) {
      const sidebar = document.querySelector('.enrollment-sidebar-content')
      const position = document.querySelector('.building_section_title').offsetTop;
      sidebar.scroll({
        left: 0,
        top: position - 45,
        behavior: 'smooth'
      })
    }
  }

  return { focusDropdown }
}
