import React, {Component, Fragment, h} from "preact";
import {E} from "../../layout";

export const ButtonPrimary = ({children, disabled, className = '', onClick, ...props}: any) =>
    <button className={`btn btn-primary ${className}`} disabled={disabled} onClick={onClick} {...props}>{children}</button>

export const ButtonPrimaryHighlight = ({children, disabled, className = '', onClick, ...props}: any) =>
    <button className={`btn btn-primary-highlight ${className}`} disabled={disabled} onClick={onClick} {...props}>{children}</button>

export const ButtonGhost = ({children, disabled, className = '', onClick, ...props}: any) =>
    <button className={`btn btn-ghost ${className}`} disabled={disabled} onClick={onClick} {...props}>{children}</button>

export const ButtonLink = ({children, disabled, className = '', onClick, ...props}: any) =>
    <button className={`btn btn-link ${className}`} disabled={disabled} onClick={onClick} {...props}>{children}</button>
