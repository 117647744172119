import {Component, Fragment, h} from "preact";
import {useState} from "preact/hooks";
import './summary.scss'


export default function Summary({children,title, classNameTitle, classNameIcon = ''}){
    const [show, setShow] = useState(false);
    const classShow = show ? "summary__visible" : "summary__collapse";

    const handleClick = () => {
        setShow(!show);
    };


    return (
        <div className="summary-container">
            <div className='summary-header'>
                <div className={`summary-header__title ${classNameTitle}`} >
                    <span className='summary pointer' onClick={handleClick}>{title}</span>
                </div>
                <div className={`summary-header__icon ${classNameIcon}`}>
                    {show
                        ? <i className="far fa-minus" onClick={handleClick}/>
                        : <i className="fal fa-plus" onClick={handleClick}/>}
                </div>
            </div>
            {show && <div className={classShow}>
                {children}
            </div>
            }
        </div>
    );
}