import React, { Fragment, Component, h} from "preact";
import {connect} from "react-redux";
import {getNextExams, getPastExams, setCancelEnroll, setExamSelected, getEnrollPacs} from "../../../_rx/enrollment/exams/actions";
import {useEffect} from "preact/hooks";
import HistoricalExamsCard from "../components/HistoricalExamsCard/HistoricalExamsCard";
import {useHistory} from "react-router-dom";
import {t} from '../../../_i18n';

function HistoricalExams ({ status, studentId, getNextExams, getPastExams, historicalExams, loadingHistorical, setExamSelected, setCancelEnroll, getEnrollPacs }) {
    const history = useHistory()
    const isNextEvents = status === 'next'
    const funcHistoryEvents = isNextEvents ? getNextExams : getPastExams
    const emptyEvents = historicalExams?.length === 0
    const emptyEventsMessage = isNextEvents ? `${t('No tienes ningún examen programado')}.` : `${t('No has realizado aún ningún examen')}.`

    useEffect(async () => {
        await funcHistoryEvents(studentId)
    }, [status])

    const modifyEnroll = (exam) => {
        history.push('/examenes/modify')
        setExamSelected(exam)
    }

    const viewEnrollPacs = (exam) => {
        setExamSelected(exam)
        getEnrollPacs(studentId, exam.exam_convocation_id, exam.formative_action_id);
    }
    const cancelEnroll = (exam) => setCancelEnroll(exam)

    if (!historicalExams)
        return <div className='font-color-879CA1'>{t('Cargando')}...</div>

    return (
        <div>
            {emptyEvents
                ? <div className='font-color-879CA1'>{emptyEventsMessage}</div>
                : historicalExams.map(event =>
                    <HistoricalExamsCard
                        event={event}
                        isNextExam={isNextEvents}
                        loading={loadingHistorical}
                        handleModify={modifyEnroll}
                        handleCancel={cancelEnroll}
                        handleViewPACs={viewEnrollPacs}
                    />
                )
            }
        </div>
    )
}


HistoricalExams = connect(
    ({auth, enrollmentExams})=>({
        studentId: auth.loggedUser.id,
        historicalExams: enrollmentExams.historicalExams,
        loadingHistorical: enrollmentExams.loadingHistorical
    }),
    {getNextExams, getPastExams, setExamSelected, setCancelEnroll, getEnrollPacs})(HistoricalExams);


export default ({...rest}) =>  <HistoricalExams {...rest}/>
