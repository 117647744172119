/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AcademicProgram from '../model/AcademicProgram';
import AcademicSection from '../model/AcademicSection';
import Family from '../model/Family';
import FormativeAction from '../model/FormativeAction';
import Province from '../model/Province';
import Shop from '../model/Shop';

/**
* SIS service.
* @module api/SISApi
* @version 1.0
*/
export default class SISApi {

    /**
    * Constructs a new SISApi. 
    * @alias module:api/SISApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get academic programs
     * @param {Object} opts Optional parameters
     * @param {String} opts.search Generic filter
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AcademicProgram>} and HTTP response
     */
    appHttpControllersSISControllerAcademicProgramsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'search': opts['search']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [AcademicProgram];
      return this.apiClient.callApi(
        '/api/v1/sis/academicPrograms', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get academic programs
     * @param {Object} opts Optional parameters
     * @param {String} opts.search Generic filter
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AcademicProgram>}
     */
    appHttpControllersSISControllerAcademicPrograms(opts) {
      return this.appHttpControllersSISControllerAcademicProgramsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get academic sections
     * @param {Object} opts Optional parameters
     * @param {String} opts.search Generic filter
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AcademicSection>} and HTTP response
     */
    appHttpControllersSISControllerAcademicSectionsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'search': opts['search']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [AcademicSection];
      return this.apiClient.callApi(
        '/api/v1/sis/academicSections', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get academic sections
     * @param {Object} opts Optional parameters
     * @param {String} opts.search Generic filter
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AcademicSection>}
     */
    appHttpControllersSISControllerAcademicSections(opts) {
      return this.appHttpControllersSISControllerAcademicSectionsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all academic families
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Family>} and HTTP response
     */
    appHttpControllersSISControllerFamiliesWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Family];
      return this.apiClient.callApi(
        '/api/v1/sis/families', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get all academic families
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Family>}
     */
    appHttpControllersSISControllerFamilies() {
      return this.appHttpControllersSISControllerFamiliesWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get formative actions
     * @param {Object} opts Optional parameters
     * @param {String} opts.search Generic filter
     * @param {String} opts.academicProgramId Ids of the academic program (comma separated)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/FormativeAction>} and HTTP response
     */
    appHttpControllersSISControllerFormativeActionsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'search': opts['search'],
        'academic_program_id': opts['academicProgramId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [FormativeAction];
      return this.apiClient.callApi(
        '/api/v1/sis/formativeActions', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get formative actions
     * @param {Object} opts Optional parameters
     * @param {String} opts.search Generic filter
     * @param {String} opts.academicProgramId Ids of the academic program (comma separated)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/FormativeAction>}
     */
    appHttpControllersSISControllerFormativeActions(opts) {
      return this.appHttpControllersSISControllerFormativeActionsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get provinces
     * @param {Object} opts Optional parameters
     * @param {String} opts.search Generic filter
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Province>} and HTTP response
     */
    appHttpControllersSISControllerProvincesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'search': opts['search']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Province];
      return this.apiClient.callApi(
        '/api/v1/sis/provinces', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get provinces
     * @param {Object} opts Optional parameters
     * @param {String} opts.search Generic filter
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Province>}
     */
    appHttpControllersSISControllerProvinces(opts) {
      return this.appHttpControllersSISControllerProvincesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get shops
     * @param {Object} opts Optional parameters
     * @param {String} opts.search Generic filter
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Shop>} and HTTP response
     */
    appHttpControllersSISControllerShopsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'search': opts['search']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Shop];
      return this.apiClient.callApi(
        '/api/v1/sis/shops', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get shops
     * @param {Object} opts Optional parameters
     * @param {String} opts.search Generic filter
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Shop>}
     */
    appHttpControllersSISControllerShops(opts) {
      return this.appHttpControllersSISControllerShopsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
