import {h} from "preact";

export const CfgsRequirements = ({id}) =><div className="margin-auto" id={id}>
        <div className="innerText">
            <p>Para matricularte al ciclo de Grado Superior, debes tener <strong>más de 18 años o cumplirlos durante el año natural del comienzo del ciclo</strong>. Además, tienes que cumplir al menos uno de los siguientes requisitos académicos:</p>
        </div>
        <ul>
            <li>Tener el título de Bachillerato o Bachiller.</li>
            <li>Tener el título de Técnico/a (ciclo de Grado Medio, CFGM).</li>
            <li>Tener el título de Técnico/a superior o especialista (ciclo de Grado Superior, CFGS).</li>
            <li>Tener el título Bachillerato Unificado Polivalente (BUP).</li>
            <li>Haber superado el segundo curso de Bachillerato de cualquier modalidad de Bachillerato experimental.</li>
            <li>Haber superado el Curso de Orientación Universitaria (COU) o el preuniversitario (PREU).</li>
            <li>Tener la tarjeta de la prueba de acceso a la Universidad (PAU).</li>
            <li>Tener cualquier titulación universitaria o una de equivalente.</li>
            <li>Haber superado la prueba de acceso a la Universidad para mayores de 25 años.</li>
            <li>Haber superado la prueba de acceso que te acredite para estudiar tu ciclo de Grado Superior deseado.</li>
        </ul>
        <div className="warning-text">
            <span>Las personas con <strong>titulaciones extranjeras</strong> deben presentar la resolución positiva de la solicitud de homologación o convalidación del título que puede darles acceso al Grado Superior. Si este procedimiento aún no está resuelto, deben presentar el volante de inscripción condicional vigente para el curso en que se hayan matriculado. Cuando se resuelva, deberán remitir al centro la correspondiente resolución de homologación.</span>
        </div>
        <div className="innerText">
            <p><strong>No permiten el acceso</strong> al ciclo de Grado Superior los siguientes documentos:</p>
        </div>
        <ul>
            <li>Tener un certificado de profesionalidad.</li>
            <li>Tener el título de Graduado Escolar (EGB).</li>
            <li>Tener el título de Bachillerato elemental.</li>
            <li>Haber superado una prueba de acceso que no contenga la parte específica de la familia del ciclo deseado.</li>
            <li>Tener un título de un ciclo formativo de la familia de Artes Plásticas y Diseño.</li>
            <li>Tener un título de un ciclo formativo de la familia de Enseñanzas Deportivas.</li>
        </ul>
    </div>;
