import { h } from "preact";
import './AlertCollapse.scss'
import { useState } from 'preact/hooks'

interface Props {
  className?: string,
  title: string,
  children: JSX.Element | JSX.Element[],
  type?: 'warning' | 'danger'
}

export default function AlertCollapse ({ title, children, className, type = 'warning' }: Props) {
  const [show, setShow] = useState(true)

  return (
    <div className={`alert-collapse ${type} ${className}`}>
      <div className='alert-collapse__header' onClick={() => setShow(!show)}>
        <div className='font-bold'>{title}</div>
        <div><i className={`far ${show ? 'fa-chevron-up' : 'fa-chevron-down'} `} /></div>
      </div>
      { show && <div className='alert-collapse__body'>
        {children}
      </div>}
    </div>
  )
}
