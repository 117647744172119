import {Fragment, h} from "preact";
import {AppPage} from '../../../_common/components/app-page'
import {useSelector} from 'react-redux'
import {t} from '../../../_i18n'
import {Link} from 'react-router-dom'
import './dashboard-styles.scss'
import Card from '../../../_common/components/CardDashboard/Card'
import CardHelp from '../../../_common/components/CardDashboard/CardHelp'
import {isSpanishUser} from '../../../_common/utils/getLang'
import {C} from '../../../_common/layout'
import Comunicados from '../../../comunicados/comunicados'
import {STUDENT_IS_ON_SITE, STUDENT_NO_TITLE, STUDENT_VALIDATED} from '../../../app/getUserRol'
import Badge from '../../../_common/components/Badge'

export default function Dashboard() {
    const {user, comunicados_status, student_meta, student_access} = useSelector(state => ({
        user: state.auth.loggedUser,
        comunicados_status: state.comunicados_status,
        student_meta: state.status.student_meta,
        student_access: state.student_access
    }))

    const handleNotices = () => {
        const element = document.querySelector('.comunicados')
        element.scrollIntoView({behavior: 'smooth'})
    }

    return (
        <AppPage name="dashboard">
            <C className='dashboard'>
                <h1 className='hero'>{t('Hola,')} {user.name}.</h1>
                <div>
                    {student_meta.has_pending_exam_inscriptions && <div className='font-18 m-bottom dashboard__notify'>
                        <span>👉 {t('Tienes inscripciones a exámenes pendientes')}. <Link className='font-color-149BAB'
                                                                                         to='/examenes'>{t('Realiza las inscripciones')}</Link></span>
                    </div>}
                    {student_meta.has_pending_class_inscriptions && <div className='font-18 m-bottom dashboard__notify'>
                        <span>👉 {t('Tienes inscripciones a clases presenciales pendientes')}. <Link
                            className='font-color-149BAB' to='/clases'>{t('Realiza las inscripciones')}</Link></span>
                    </div>}
                    {comunicados_status?.unread > 0 && (
                        <div className='font-18 m-bottom dashboard__notify'>
                            👋 <span> {(comunicados_status.unread === 1)
                            ?
                            <span>{t('Tienes {num} comunicado sin leer').replace('{num}', comunicados_status.unread)}. <span
                                onClick={handleNotices}
                                className='font-color-149BAB t-underline cursor-pointer'>{t('Leer comunicado')}</span></span>
                            :
                            <span>{t('Tienes {num} comunicados sin leer').replace('{num}', comunicados_status.unread)}. <span
                                onClick={handleNotices}
                                className='font-color-149BAB t-underline cursor-pointer'>{t('Leer comunicados')}</span></span>
                        }</span>
                        </div>
                    )}
                </div>
                <div className='d-flex m-top dashboard__panel'>
                    {(student_access === STUDENT_VALIDATED || student_access === STUDENT_NO_TITLE) && student_meta.canvas_access &&
                        <Card title={t('Campus')} to={student_meta.online_campus_url}
                              content={t('Accede a los contenidos y aulas virtuales de tus asignaturas.')} external/>}
                    {student_access === STUDENT_IS_ON_SITE && student_meta.canvas_access &&
                        <Card title={t('Campus Presencial')} inverse to={student_meta.presential_campus_url} external/>}
                    {(student_access === STUDENT_VALIDATED || student_access === STUDENT_NO_TITLE) && student_meta?.fct_contracts.map(fct =>
                        <Card title={t('FCT')} to={`/fct/${fct.contract_id}`} content={
                            <Fragment>
                            <div>{t(fct.program)}</div>
                            {fct.status &&
                                <Badge className='m-top' type='warning'>{t(fct.status)}</Badge>
                            }
                            </Fragment>
                            }
                        />)}
                    {(student_access === STUDENT_VALIDATED || student_access === STUDENT_NO_TITLE) && isSpanishUser(student_meta.shop_id) &&
                        <Card title={t('Secretaría')}
                              content={t('Solicita certificados o realiza otras gestiones administrativas con el departamento de Secretaría.')}
                              to='/secretaria'/>}
                    {(student_access === STUDENT_VALIDATED || student_access === STUDENT_NO_TITLE) && isSpanishUser(student_meta.shop_id) &&
                        <Card title={t('Bolsa de trabajo')}
                              content={t('Busca ofertas de empleo relacionadas con tu ciclo formativo en empresas de tu zona.')}
                              to='/jobs'/>}
                    {(student_access === STUDENT_VALIDATED || student_access === STUDENT_NO_TITLE) && isSpanishUser(student_meta.shop_id) && !student_meta.is_eso && student_meta.coursing_academic_programs.length > 0 &&
                        <Card title={t('Área Onliner')}
                              content={t('Consulta información académica y los tutoriales de ayuda.')}
                              to='/online-area'/>}
                    {(student_access === STUDENT_VALIDATED || student_access === STUDENT_NO_TITLE) && isSpanishUser(student_meta.shop_id) &&
                        <CardHelp/>}
                </div>
                <div className='m-top-x4'>
                    <Comunicados isolateComponent/>
                </div>
            </C>
        </AppPage>
    )
}
