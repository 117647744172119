import React, {Component, Fragment, h} from "preact";
import {AppPage} from "../../../_common/components/app-page";
import {t} from '../../../_i18n';
import NifPage from "./nif-page";

export default function StudentConfigurationNif({history}) {

    return (
        <AppPage
            name="nif"
            className="max-width"
        >
            <NifPage history={history} title={t('Documento de identidad')}/>
        </AppPage>
    )
}
