/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AttributeType from '../model/AttributeType';
import InlineResponse2001 from '../model/InlineResponse2001';
import InlineResponse2003 from '../model/InlineResponse2003';
import InlineResponse2005 from '../model/InlineResponse2005';
import InlineResponse404 from '../model/InlineResponse404';
import Segment from '../model/Segment';

/**
* Segment service.
* @module api/SegmentApi
* @version 1.0
*/
export default class SegmentApi {

    /**
    * Constructs a new SegmentApi. 
    * @alias module:api/SegmentApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get the number of the targets generated by the segment
     * @param {Number} id Segment id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2003} and HTTP response
     */
    appHttpControllersSegmentControllerCountWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersSegmentControllerCount");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2003;
      return this.apiClient.callApi(
        '/api/v1/segmentCount/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get the number of the targets generated by the segment
     * @param {Number} id Segment id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2003}
     */
    appHttpControllersSegmentControllerCount(id) {
      return this.appHttpControllersSegmentControllerCountWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a new segment
     * @param {String} name name
     * @param {Object} opts Optional parameters
     * @param {String} opts.academicSectionId academic section id
     * @param {String} opts.academicProgramId academic program id
     * @param {String} opts.formativeActionId formative action id
     * @param {String} opts.shopId shop id
     * @param {Boolean} opts.isNewStudent is new student
     * @param {Boolean} opts.isSemiNewStudent is semi new student
     * @param {Boolean} opts.isPremium is premium
     * @param {String} opts.familyId family id
     * @param {Boolean} opts.hasPresentialInscribed has presential inscribed
     * @param {Boolean} opts.hasPresentialAssisted has presential assisted
     * @param {Boolean} opts.examInscribedStatus exam incribed status id
     * @param {Boolean} opts.hasExtraordinaryPending has extraordinary pending
     * @param {Boolean} opts.hasConvas has extraordinary pending
     * @param {Boolean} opts.hasFct has extraordinary pending
     * @param {Boolean} opts.isOldStudent is old student
     * @param {Boolean} opts.hasPresential has presential
     * @param {Number} opts.province province
     * @param {Boolean} opts.hasFinished has finished
     * @param {String} opts.studentEmail student_email
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2001} and HTTP response
     */
    appHttpControllersSegmentControllerCreateWithHttpInfo(name, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'name' is set
      if (name === undefined || name === null) {
        throw new Error("Missing the required parameter 'name' when calling appHttpControllersSegmentControllerCreate");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': name,
        'academic_section_id': opts['academicSectionId'],
        'academic_program_id': opts['academicProgramId'],
        'formative_action_id': opts['formativeActionId'],
        'shop_id': opts['shopId'],
        'is_new_student': opts['isNewStudent'],
        'is_semi_new_student': opts['isSemiNewStudent'],
        'is_premium': opts['isPremium'],
        'family_id': opts['familyId'],
        'has_presential_inscribed': opts['hasPresentialInscribed'],
        'has_presential_assisted': opts['hasPresentialAssisted'],
        'exam_inscribed_status': opts['examInscribedStatus'],
        'has_extraordinary_pending': opts['hasExtraordinaryPending'],
        'has_convas': opts['hasConvas'],
        'has_fct': opts['hasFct'],
        'is_old_student': opts['isOldStudent'],
        'has_presential': opts['hasPresential'],
        'province': opts['province'],
        'has_finished': opts['hasFinished'],
        'student_email': opts['studentEmail']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2001;
      return this.apiClient.callApi(
        '/api/v1/segment', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create a new segment
     * @param {String} name name
     * @param {Object} opts Optional parameters
     * @param {String} opts.academicSectionId academic section id
     * @param {String} opts.academicProgramId academic program id
     * @param {String} opts.formativeActionId formative action id
     * @param {String} opts.shopId shop id
     * @param {Boolean} opts.isNewStudent is new student
     * @param {Boolean} opts.isSemiNewStudent is semi new student
     * @param {Boolean} opts.isPremium is premium
     * @param {String} opts.familyId family id
     * @param {Boolean} opts.hasPresentialInscribed has presential inscribed
     * @param {Boolean} opts.hasPresentialAssisted has presential assisted
     * @param {Boolean} opts.examInscribedStatus exam incribed status id
     * @param {Boolean} opts.hasExtraordinaryPending has extraordinary pending
     * @param {Boolean} opts.hasConvas has extraordinary pending
     * @param {Boolean} opts.hasFct has extraordinary pending
     * @param {Boolean} opts.isOldStudent is old student
     * @param {Boolean} opts.hasPresential has presential
     * @param {Number} opts.province province
     * @param {Boolean} opts.hasFinished has finished
     * @param {String} opts.studentEmail student_email
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2001}
     */
    appHttpControllersSegmentControllerCreate(name, opts) {
      return this.appHttpControllersSegmentControllerCreateWithHttpInfo(name, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete of a segment
     * @param {Number} id Segment id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Segment} and HTTP response
     */
    appHttpControllersSegmentControllerDeleteWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersSegmentControllerDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Segment;
      return this.apiClient.callApi(
        '/api/v1/segment/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Delete of a segment
     * @param {Number} id Segment id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Segment}
     */
    appHttpControllersSegmentControllerDelete(id) {
      return this.appHttpControllersSegmentControllerDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get detail of a segment
     * @param {Number} id Segment id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Segment} and HTTP response
     */
    appHttpControllersSegmentControllerDetailsWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersSegmentControllerDetails");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Segment;
      return this.apiClient.callApi(
        '/api/v1/segment/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get detail of a segment
     * @param {Number} id Segment id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Segment}
     */
    appHttpControllersSegmentControllerDetails(id) {
      return this.appHttpControllersSegmentControllerDetailsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all the attribute types that can be used to segment creation
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AttributeType>} and HTTP response
     */
    appHttpControllersSegmentControllerGetAllAttributesTypesWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [AttributeType];
      return this.apiClient.callApi(
        '/api/v1/segment/attributeTypes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get all the attribute types that can be used to segment creation
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AttributeType>}
     */
    appHttpControllersSegmentControllerGetAllAttributesTypes() {
      return this.appHttpControllersSegmentControllerGetAllAttributesTypesWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get a list of all the segments
     * @param {Object} opts Optional parameters
     * @param {String} opts.name Filter by name
     * @param {String} opts.academicSectionId Filter by academic section id
     * @param {String} opts.academicProgramId Filter by academic program id
     * @param {String} opts.formativeActionId Filter by formative action id
     * @param {String} opts.shopId Filter by shop id
     * @param {Boolean} opts.isNewStudent Filter by new student
     * @param {Boolean} opts.isSemiNewStudent Filter by semi new student
     * @param {Boolean} opts.isPremium Filter by premium
     * @param {String} opts.familyId Filter by family
     * @param {Boolean} opts.hasPresentialInscribed Filter by presential inscribed
     * @param {Boolean} opts.hasPresentialAssisted Filter by presential assisted
     * @param {String} opts.examInscribedStatus Filter by exam inscription status
     * @param {Boolean} opts.hasExtraordinaryPending Filter by has extraordinary pending
     * @param {Boolean} opts.hasConvas Filter by has convas
     * @param {Boolean} opts.hasFct Filter by has fct
     * @param {Boolean} opts.isOldStudent Filter by old student
     * @param {Boolean} opts.hasPresential Filter by student with presentials
     * @param {Number} opts.province Filter by province
     * @param {Boolean} opts.hasFinished Filter by finished
     * @param {Boolean} opts.studentEmail Filter by student email
     * @param {Number} opts.page Page to show
     * @param {Number} opts.limit Elements per page
     * @param {module:model/String} opts.sort Sort by field
     * @param {module:model/String} opts.sortOrder sort order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2005} and HTTP response
     */
    appHttpControllersSegmentControllerIndexWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'name': opts['name'],
        'academic_section_id': opts['academicSectionId'],
        'academic_program_id': opts['academicProgramId'],
        'formative_action_id': opts['formativeActionId'],
        'shop_id': opts['shopId'],
        'is_new_student': opts['isNewStudent'],
        'is_semi_new_student': opts['isSemiNewStudent'],
        'is_premium': opts['isPremium'],
        'family_id': opts['familyId'],
        'has_presential_inscribed': opts['hasPresentialInscribed'],
        'has_presential_assisted': opts['hasPresentialAssisted'],
        'exam_inscribed_status': opts['examInscribedStatus'],
        'has_extraordinary_pending': opts['hasExtraordinaryPending'],
        'has_convas': opts['hasConvas'],
        'has_fct': opts['hasFct'],
        'is_old_student': opts['isOldStudent'],
        'has_presential': opts['hasPresential'],
        'province': opts['province'],
        'has_finished': opts['hasFinished'],
        'student_email': opts['studentEmail'],
        'page': opts['page'],
        'limit': opts['limit'],
        'sort': opts['sort'],
        'sort_order': opts['sortOrder']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2005;
      return this.apiClient.callApi(
        '/api/v1/segments', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get a list of all the segments
     * @param {Object} opts Optional parameters
     * @param {String} opts.name Filter by name
     * @param {String} opts.academicSectionId Filter by academic section id
     * @param {String} opts.academicProgramId Filter by academic program id
     * @param {String} opts.formativeActionId Filter by formative action id
     * @param {String} opts.shopId Filter by shop id
     * @param {Boolean} opts.isNewStudent Filter by new student
     * @param {Boolean} opts.isSemiNewStudent Filter by semi new student
     * @param {Boolean} opts.isPremium Filter by premium
     * @param {String} opts.familyId Filter by family
     * @param {Boolean} opts.hasPresentialInscribed Filter by presential inscribed
     * @param {Boolean} opts.hasPresentialAssisted Filter by presential assisted
     * @param {String} opts.examInscribedStatus Filter by exam inscription status
     * @param {Boolean} opts.hasExtraordinaryPending Filter by has extraordinary pending
     * @param {Boolean} opts.hasConvas Filter by has convas
     * @param {Boolean} opts.hasFct Filter by has fct
     * @param {Boolean} opts.isOldStudent Filter by old student
     * @param {Boolean} opts.hasPresential Filter by student with presentials
     * @param {Number} opts.province Filter by province
     * @param {Boolean} opts.hasFinished Filter by finished
     * @param {Boolean} opts.studentEmail Filter by student email
     * @param {Number} opts.page Page to show
     * @param {Number} opts.limit Elements per page
     * @param {module:model/String} opts.sort Sort by field
     * @param {module:model/String} opts.sortOrder sort order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2005}
     */
    appHttpControllersSegmentControllerIndex(opts) {
      return this.appHttpControllersSegmentControllerIndexWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get a list of all the segments without pagination
     * @param {Object} opts Optional parameters
     * @param {String} opts.name Filter by name
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Segment>} and HTTP response
     */
    appHttpControllersSegmentControllerIndexWoutPaginationWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'name': opts['name']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Segment];
      return this.apiClient.callApi(
        '/api/v1/segmentsWP', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get a list of all the segments without pagination
     * @param {Object} opts Optional parameters
     * @param {String} opts.name Filter by name
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Segment>}
     */
    appHttpControllersSegmentControllerIndexWoutPagination(opts) {
      return this.appHttpControllersSegmentControllerIndexWoutPaginationWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Preview a segment, returns the count
     * @param {String} name name
     * @param {Object} opts Optional parameters
     * @param {String} opts.academicSectionId academic section id
     * @param {String} opts.academicProgramId academic program id
     * @param {String} opts.formativeActionId formative action id
     * @param {String} opts.shopId shop id
     * @param {Boolean} opts.isNewStudent is new student
     * @param {Boolean} opts.isSemiNewStudent is semi new student
     * @param {Boolean} opts.isPremium is premium
     * @param {String} opts.familyId family id
     * @param {Boolean} opts.hasPresentialInscribed has presential inscribed
     * @param {Boolean} opts.hasPresentialAssisted has presential assisted
     * @param {Boolean} opts.examInscribedStatus exam incribed status id
     * @param {Boolean} opts.hasExtraordinaryPending has extraordinary pending
     * @param {Boolean} opts.hasConvas has extraordinary pending
     * @param {Boolean} opts.hasFct has extraordinary pending
     * @param {Boolean} opts.isOldStudent is old student
     * @param {Boolean} opts.hasPresential has presential
     * @param {Number} opts.province province
     * @param {Boolean} opts.hasFinished has finished
     * @param {String} opts.studentEmail student_email
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2003} and HTTP response
     */
    appHttpControllersSegmentControllerPreviewCountWithHttpInfo(name, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'name' is set
      if (name === undefined || name === null) {
        throw new Error("Missing the required parameter 'name' when calling appHttpControllersSegmentControllerPreviewCount");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': name,
        'academic_section_id': opts['academicSectionId'],
        'academic_program_id': opts['academicProgramId'],
        'formative_action_id': opts['formativeActionId'],
        'shop_id': opts['shopId'],
        'is_new_student': opts['isNewStudent'],
        'is_semi_new_student': opts['isSemiNewStudent'],
        'is_premium': opts['isPremium'],
        'family_id': opts['familyId'],
        'has_presential_inscribed': opts['hasPresentialInscribed'],
        'has_presential_assisted': opts['hasPresentialAssisted'],
        'exam_inscribed_status': opts['examInscribedStatus'],
        'has_extraordinary_pending': opts['hasExtraordinaryPending'],
        'has_convas': opts['hasConvas'],
        'has_fct': opts['hasFct'],
        'is_old_student': opts['isOldStudent'],
        'has_presential': opts['hasPresential'],
        'province': opts['province'],
        'has_finished': opts['hasFinished'],
        'student_email': opts['studentEmail']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2003;
      return this.apiClient.callApi(
        '/api/v1/segmentCount', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Preview a segment, returns the count
     * @param {String} name name
     * @param {Object} opts Optional parameters
     * @param {String} opts.academicSectionId academic section id
     * @param {String} opts.academicProgramId academic program id
     * @param {String} opts.formativeActionId formative action id
     * @param {String} opts.shopId shop id
     * @param {Boolean} opts.isNewStudent is new student
     * @param {Boolean} opts.isSemiNewStudent is semi new student
     * @param {Boolean} opts.isPremium is premium
     * @param {String} opts.familyId family id
     * @param {Boolean} opts.hasPresentialInscribed has presential inscribed
     * @param {Boolean} opts.hasPresentialAssisted has presential assisted
     * @param {Boolean} opts.examInscribedStatus exam incribed status id
     * @param {Boolean} opts.hasExtraordinaryPending has extraordinary pending
     * @param {Boolean} opts.hasConvas has extraordinary pending
     * @param {Boolean} opts.hasFct has extraordinary pending
     * @param {Boolean} opts.isOldStudent is old student
     * @param {Boolean} opts.hasPresential has presential
     * @param {Number} opts.province province
     * @param {Boolean} opts.hasFinished has finished
     * @param {String} opts.studentEmail student_email
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2003}
     */
    appHttpControllersSegmentControllerPreviewCount(name, opts) {
      return this.appHttpControllersSegmentControllerPreviewCountWithHttpInfo(name, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Preview a segment, returns the email list
     * @param {String} name name
     * @param {Object} opts Optional parameters
     * @param {String} opts.academicSectionId academic section id
     * @param {String} opts.academicProgramId academic program id
     * @param {String} opts.formativeActionId formative action id
     * @param {String} opts.shopId shop id
     * @param {Boolean} opts.isNewStudent is new student
     * @param {Boolean} opts.isSemiNewStudent is semi new student
     * @param {Boolean} opts.isPremium is premium
     * @param {String} opts.familyId family id
     * @param {Boolean} opts.hasPresentialInscribed has presential inscribed
     * @param {Boolean} opts.hasPresentialAssisted has presential assisted
     * @param {Boolean} opts.examInscribedStatus exam incribed status id
     * @param {Boolean} opts.hasExtraordinaryPending has extraordinary pending
     * @param {Boolean} opts.hasConvas has extraordinary pending
     * @param {Boolean} opts.hasFct has extraordinary pending
     * @param {Boolean} opts.isOldStudent is old student
     * @param {Boolean} opts.hasPresential has presential
     * @param {Number} opts.province province
     * @param {Boolean} opts.hasFinished has finished
     * @param {String} opts.studentEmail student_email
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
     */
    appHttpControllersSegmentControllerPreviewListWithHttpInfo(name, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'name' is set
      if (name === undefined || name === null) {
        throw new Error("Missing the required parameter 'name' when calling appHttpControllersSegmentControllerPreviewList");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': name,
        'academic_section_id': opts['academicSectionId'],
        'academic_program_id': opts['academicProgramId'],
        'formative_action_id': opts['formativeActionId'],
        'shop_id': opts['shopId'],
        'is_new_student': opts['isNewStudent'],
        'is_semi_new_student': opts['isSemiNewStudent'],
        'is_premium': opts['isPremium'],
        'family_id': opts['familyId'],
        'has_presential_inscribed': opts['hasPresentialInscribed'],
        'has_presential_assisted': opts['hasPresentialAssisted'],
        'exam_inscribed_status': opts['examInscribedStatus'],
        'has_extraordinary_pending': opts['hasExtraordinaryPending'],
        'has_convas': opts['hasConvas'],
        'has_fct': opts['hasFct'],
        'is_old_student': opts['isOldStudent'],
        'has_presential': opts['hasPresential'],
        'province': opts['province'],
        'has_finished': opts['hasFinished'],
        'student_email': opts['studentEmail']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/csv', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/api/v1/segmentPreview', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Preview a segment, returns the email list
     * @param {String} name name
     * @param {Object} opts Optional parameters
     * @param {String} opts.academicSectionId academic section id
     * @param {String} opts.academicProgramId academic program id
     * @param {String} opts.formativeActionId formative action id
     * @param {String} opts.shopId shop id
     * @param {Boolean} opts.isNewStudent is new student
     * @param {Boolean} opts.isSemiNewStudent is semi new student
     * @param {Boolean} opts.isPremium is premium
     * @param {String} opts.familyId family id
     * @param {Boolean} opts.hasPresentialInscribed has presential inscribed
     * @param {Boolean} opts.hasPresentialAssisted has presential assisted
     * @param {Boolean} opts.examInscribedStatus exam incribed status id
     * @param {Boolean} opts.hasExtraordinaryPending has extraordinary pending
     * @param {Boolean} opts.hasConvas has extraordinary pending
     * @param {Boolean} opts.hasFct has extraordinary pending
     * @param {Boolean} opts.isOldStudent is old student
     * @param {Boolean} opts.hasPresential has presential
     * @param {Number} opts.province province
     * @param {Boolean} opts.hasFinished has finished
     * @param {String} opts.studentEmail student_email
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
     */
    appHttpControllersSegmentControllerPreviewList(name, opts) {
      return this.appHttpControllersSegmentControllerPreviewListWithHttpInfo(name, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a segment
     * @param {Number} id Segment id
     * @param {Object} opts Optional parameters
     * @param {String} opts.name name
     * @param {String} opts.academicSectionId academic section id
     * @param {String} opts.academicProgramId academic program id
     * @param {String} opts.formativeActionId formative action id
     * @param {String} opts.shopId shop id
     * @param {Boolean} opts.isNewStudent is new student
     * @param {Boolean} opts.isSemiNewStudent is semi new student
     * @param {Boolean} opts.isPremium is premium
     * @param {String} opts.familyId family id
     * @param {Boolean} opts.hasPresentialInscribed has presential inscribed
     * @param {Boolean} opts.hasPresentialAssisted has presential assisted
     * @param {Boolean} opts.examInscribedStatus exam incribed status id
     * @param {Boolean} opts.hasExtraordinaryPending has extraordinary pending
     * @param {Boolean} opts.hasConvas has extraordinary pending
     * @param {Boolean} opts.hasFct has extraordinary pending
     * @param {Boolean} opts.isOldStudent is old student
     * @param {Boolean} opts.hasPresential has presential
     * @param {Number} opts.province province
     * @param {Boolean} opts.hasFinished has finished
     * @param {String} opts.studentEmail student_email
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2001} and HTTP response
     */
    appHttpControllersSegmentControllerUpdateWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersSegmentControllerUpdate");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'academic_section_id': opts['academicSectionId'],
        'academic_program_id': opts['academicProgramId'],
        'formative_action_id': opts['formativeActionId'],
        'shop_id': opts['shopId'],
        'is_new_student': opts['isNewStudent'],
        'is_semi_new_student': opts['isSemiNewStudent'],
        'is_premium': opts['isPremium'],
        'family_id': opts['familyId'],
        'has_presential_inscribed': opts['hasPresentialInscribed'],
        'has_presential_assisted': opts['hasPresentialAssisted'],
        'exam_inscribed_status': opts['examInscribedStatus'],
        'has_extraordinary_pending': opts['hasExtraordinaryPending'],
        'has_convas': opts['hasConvas'],
        'has_fct': opts['hasFct'],
        'is_old_student': opts['isOldStudent'],
        'has_presential': opts['hasPresential'],
        'province': opts['province'],
        'has_finished': opts['hasFinished'],
        'student_email': opts['studentEmail']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2001;
      return this.apiClient.callApi(
        '/api/v1/segment/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Update a segment
     * @param {Number} id Segment id
     * @param {Object} opts Optional parameters
     * @param {String} opts.name name
     * @param {String} opts.academicSectionId academic section id
     * @param {String} opts.academicProgramId academic program id
     * @param {String} opts.formativeActionId formative action id
     * @param {String} opts.shopId shop id
     * @param {Boolean} opts.isNewStudent is new student
     * @param {Boolean} opts.isSemiNewStudent is semi new student
     * @param {Boolean} opts.isPremium is premium
     * @param {String} opts.familyId family id
     * @param {Boolean} opts.hasPresentialInscribed has presential inscribed
     * @param {Boolean} opts.hasPresentialAssisted has presential assisted
     * @param {Boolean} opts.examInscribedStatus exam incribed status id
     * @param {Boolean} opts.hasExtraordinaryPending has extraordinary pending
     * @param {Boolean} opts.hasConvas has extraordinary pending
     * @param {Boolean} opts.hasFct has extraordinary pending
     * @param {Boolean} opts.isOldStudent is old student
     * @param {Boolean} opts.hasPresential has presential
     * @param {Number} opts.province province
     * @param {Boolean} opts.hasFinished has finished
     * @param {String} opts.studentEmail student_email
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2001}
     */
    appHttpControllersSegmentControllerUpdate(id, opts) {
      return this.appHttpControllersSegmentControllerUpdateWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
