/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import StaticInfo from '../model/StaticInfo';

/**
* Statics service.
* @module api/StaticsApi
* @version 1.0.0
*/
export default class StaticsApi {

    /**
    * Constructs a new StaticsApi. 
    * @alias module:api/StaticsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Download static file
     * Retrieves a binary file for the given document number.
     * @param {String} docName Document number of the static file to download.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
     */
    apiV1MyaccountStaticDocNameGetWithHttpInfo(docName) {
      let postBody = null;
      // verify the required parameter 'docName' is set
      if (docName === undefined || docName === null) {
        throw new Error("Missing the required parameter 'docName' when calling apiV1MyaccountStaticDocNameGet");
      }

      let pathParams = {
        'doc_name': docName
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/octet-stream'];
      let returnType = File;
      return this.apiClient.callApi(
        '/api/v1/myaccount/static/{doc_name}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Download static file
     * Retrieves a binary file for the given document number.
     * @param {String} docName Document number of the static file to download.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
     */
    apiV1MyaccountStaticDocNameGet(docName) {
      return this.apiV1MyaccountStaticDocNameGetWithHttpInfo(docName)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get static information
     * Retrieves a JSON object containing static information.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/StaticInfo} and HTTP response
     */
    apiV1MyaccountStaticsGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = StaticInfo;
      return this.apiClient.callApi(
        '/api/v1/myaccount/statics', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get static information
     * Retrieves a JSON object containing static information.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/StaticInfo}
     */
    apiV1MyaccountStaticsGet() {
      return this.apiV1MyaccountStaticsGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
