/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ActivityItem from '../model/ActivityItem';
import Message from '../model/Message';
import MessageResponse from '../model/MessageResponse';

/**
* Messaging service.
* @module api/MessagingApi
* @version 1.0.0
*/
export default class MessagingApi {

    /**
    * Constructs a new MessagingApi. 
    * @alias module:api/MessagingApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Obtener actividad del contrato
     * Obtiene la actividad relacionada con un contrato específico.
     * @param {Number} contractId ID del contrato
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ActivityItem>} and HTTP response
     */
    apiV1MyaccountActivityContractIdGetWithHttpInfo(contractId) {
      let postBody = null;
      // verify the required parameter 'contractId' is set
      if (contractId === undefined || contractId === null) {
        throw new Error("Missing the required parameter 'contractId' when calling apiV1MyaccountActivityContractIdGet");
      }

      let pathParams = {
        'contract_id': contractId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'examples'];
      let returnType = [ActivityItem];
      return this.apiClient.callApi(
        '/api/v1/myaccount/activity/{contract_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Obtener actividad del contrato
     * Obtiene la actividad relacionada con un contrato específico.
     * @param {Number} contractId ID del contrato
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ActivityItem>}
     */
    apiV1MyaccountActivityContractIdGet(contractId) {
      return this.apiV1MyaccountActivityContractIdGetWithHttpInfo(contractId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Enviar mensaje
     * Envía un mensaje asociado a un contrato específico.
     * @param {Number} contractId ID del contrato
     * @param {module:model/Message} message 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MessageResponse} and HTTP response
     */
    apiV1MyaccountSendMessageContractIdPostWithHttpInfo(contractId, message) {
      let postBody = message;
      // verify the required parameter 'contractId' is set
      if (contractId === undefined || contractId === null) {
        throw new Error("Missing the required parameter 'contractId' when calling apiV1MyaccountSendMessageContractIdPost");
      }
      // verify the required parameter 'message' is set
      if (message === undefined || message === null) {
        throw new Error("Missing the required parameter 'message' when calling apiV1MyaccountSendMessageContractIdPost");
      }

      let pathParams = {
        'contract_id': contractId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = MessageResponse;
      return this.apiClient.callApi(
        '/api/v1/myaccount/send_message/{contract_id}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Enviar mensaje
     * Envía un mensaje asociado a un contrato específico.
     * @param {Number} contractId ID del contrato
     * @param {module:model/Message} message 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MessageResponse}
     */
    apiV1MyaccountSendMessageContractIdPost(contractId, message) {
      return this.apiV1MyaccountSendMessageContractIdPostWithHttpInfo(contractId, message)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
