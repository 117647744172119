/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ScreenData from './ScreenData';

/**
 * The ContractStatus model module.
 * @module model/ContractStatus
 * @version 1.0.0
 */
class ContractStatus {
    /**
     * Constructs a new <code>ContractStatus</code>.
     * @alias module:model/ContractStatus
     */
    constructor() { 
        
        ContractStatus.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ContractStatus</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ContractStatus} obj Optional instance to populate.
     * @return {module:model/ContractStatus} The populated <code>ContractStatus</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ContractStatus();

            if (data.hasOwnProperty('academic_section')) {
                obj['academic_section'] = ApiClient.convertToType(data['academic_section'], 'Number');
            }
            if (data.hasOwnProperty('shop')) {
                obj['shop'] = ApiClient.convertToType(data['shop'], 'Number');
            }
            if (data.hasOwnProperty('screen')) {
                obj['screen'] = ApiClient.convertToType(data['screen'], 'String');
            }
            if (data.hasOwnProperty('legacy')) {
                obj['legacy'] = ApiClient.convertToType(data['legacy'], 'Boolean');
            }
            if (data.hasOwnProperty('screen_data')) {
                obj['screen_data'] = ScreenData.constructFromObject(data['screen_data']);
            }
        }
        return obj;
    }


}

/**
 * Sis academic section
 * @member {Number} academic_section
 */
ContractStatus.prototype['academic_section'] = undefined;

/**
 * shop
 * @member {Number} shop
 */
ContractStatus.prototype['shop'] = undefined;

/**
 * The screen version
 * @member {String} screen
 */
ContractStatus.prototype['screen'] = undefined;

/**
 * Is the conctract a legacy item
 * @member {Boolean} legacy
 */
ContractStatus.prototype['legacy'] = undefined;

/**
 * @member {module:model/ScreenData} screen_data
 */
ContractStatus.prototype['screen_data'] = undefined;






export default ContractStatus;

