/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import TemplateInfo from './TemplateInfo';

/**
 * The StaticInfoTemplates model module.
 * @module model/StaticInfoTemplates
 * @version 1.0.0
 */
class StaticInfoTemplates {
    /**
     * Constructs a new <code>StaticInfoTemplates</code>.
     * @alias module:model/StaticInfoTemplates
     */
    constructor() { 
        
        StaticInfoTemplates.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>StaticInfoTemplates</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StaticInfoTemplates} obj Optional instance to populate.
     * @return {module:model/StaticInfoTemplates} The populated <code>StaticInfoTemplates</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StaticInfoTemplates();

            if (data.hasOwnProperty('refuse_company')) {
                obj['refuse_company'] = TemplateInfo.constructFromObject(data['refuse_company']);
            }
            if (data.hasOwnProperty('company')) {
                obj['company'] = TemplateInfo.constructFromObject(data['company']);
            }
            if (data.hasOwnProperty('affidavit')) {
                obj['affidavit'] = TemplateInfo.constructFromObject(data['affidavit']);
            }
            if (data.hasOwnProperty('tasks')) {
                obj['tasks'] = TemplateInfo.constructFromObject(data['tasks']);
            }
            if (data.hasOwnProperty('qbid')) {
                obj['qbid'] = TemplateInfo.constructFromObject(data['qbid']);
            }
            if (data.hasOwnProperty('deferral')) {
                obj['deferral'] = TemplateInfo.constructFromObject(data['deferral']);
            }
            if (data.hasOwnProperty('tutor')) {
                obj['tutor'] = TemplateInfo.constructFromObject(data['tutor']);
            }
            if (data.hasOwnProperty('weekly')) {
                obj['weekly'] = TemplateInfo.constructFromObject(data['weekly']);
            }
            if (data.hasOwnProperty('exemption')) {
                obj['exemption'] = TemplateInfo.constructFromObject(data['exemption']);
            }
            if (data.hasOwnProperty('student_data')) {
                obj['student_data'] = TemplateInfo.constructFromObject(data['student_data']);
            }
            if (data.hasOwnProperty('affidavit_it')) {
                obj['affidavit_it'] = TemplateInfo.constructFromObject(data['affidavit_it']);
            }
            if (data.hasOwnProperty('company_it')) {
                obj['company_it'] = TemplateInfo.constructFromObject(data['company_it']);
            }
            if (data.hasOwnProperty('tasks_it')) {
                obj['tasks_it'] = TemplateInfo.constructFromObject(data['tasks_it']);
            }
            if (data.hasOwnProperty('exemption_it')) {
                obj['exemption_it'] = TemplateInfo.constructFromObject(data['exemption_it']);
            }
            if (data.hasOwnProperty('deferral_it')) {
                obj['deferral_it'] = TemplateInfo.constructFromObject(data['deferral_it']);
            }
            if (data.hasOwnProperty('tutor_it')) {
                obj['tutor_it'] = TemplateInfo.constructFromObject(data['tutor_it']);
            }
            if (data.hasOwnProperty('weekly_it')) {
                obj['weekly_it'] = TemplateInfo.constructFromObject(data['weekly_it']);
            }
            if (data.hasOwnProperty('qbid_it')) {
                obj['qbid_it'] = TemplateInfo.constructFromObject(data['qbid_it']);
            }
            if (data.hasOwnProperty('motiv_it')) {
                obj['motiv_it'] = TemplateInfo.constructFromObject(data['motiv_it']);
            }
            if (data.hasOwnProperty('motiv_it_es')) {
                obj['motiv_it_es'] = TemplateInfo.constructFromObject(data['motiv_it_es']);
            }
            if (data.hasOwnProperty('affidavit_fr')) {
                obj['affidavit_fr'] = TemplateInfo.constructFromObject(data['affidavit_fr']);
            }
            if (data.hasOwnProperty('company_fr')) {
                obj['company_fr'] = TemplateInfo.constructFromObject(data['company_fr']);
            }
            if (data.hasOwnProperty('tasks_fr')) {
                obj['tasks_fr'] = TemplateInfo.constructFromObject(data['tasks_fr']);
            }
            if (data.hasOwnProperty('exemption_fr')) {
                obj['exemption_fr'] = TemplateInfo.constructFromObject(data['exemption_fr']);
            }
            if (data.hasOwnProperty('deferral_fr')) {
                obj['deferral_fr'] = TemplateInfo.constructFromObject(data['deferral_fr']);
            }
            if (data.hasOwnProperty('tutor_fr')) {
                obj['tutor_fr'] = TemplateInfo.constructFromObject(data['tutor_fr']);
            }
            if (data.hasOwnProperty('weekly_fr')) {
                obj['weekly_fr'] = TemplateInfo.constructFromObject(data['weekly_fr']);
            }
            if (data.hasOwnProperty('qbid_fr')) {
                obj['qbid_fr'] = TemplateInfo.constructFromObject(data['qbid_fr']);
            }
            if (data.hasOwnProperty('motiv_fr')) {
                obj['motiv_fr'] = TemplateInfo.constructFromObject(data['motiv_fr']);
            }
            if (data.hasOwnProperty('motiv_fr_es')) {
                obj['motiv_fr_es'] = TemplateInfo.constructFromObject(data['motiv_fr_es']);
            }
            if (data.hasOwnProperty('final')) {
                obj['final'] = TemplateInfo.constructFromObject(data['final']);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/TemplateInfo} refuse_company
 */
StaticInfoTemplates.prototype['refuse_company'] = undefined;

/**
 * @member {module:model/TemplateInfo} company
 */
StaticInfoTemplates.prototype['company'] = undefined;

/**
 * @member {module:model/TemplateInfo} affidavit
 */
StaticInfoTemplates.prototype['affidavit'] = undefined;

/**
 * @member {module:model/TemplateInfo} tasks
 */
StaticInfoTemplates.prototype['tasks'] = undefined;

/**
 * @member {module:model/TemplateInfo} qbid
 */
StaticInfoTemplates.prototype['qbid'] = undefined;

/**
 * @member {module:model/TemplateInfo} deferral
 */
StaticInfoTemplates.prototype['deferral'] = undefined;

/**
 * @member {module:model/TemplateInfo} tutor
 */
StaticInfoTemplates.prototype['tutor'] = undefined;

/**
 * @member {module:model/TemplateInfo} weekly
 */
StaticInfoTemplates.prototype['weekly'] = undefined;

/**
 * @member {module:model/TemplateInfo} exemption
 */
StaticInfoTemplates.prototype['exemption'] = undefined;

/**
 * @member {module:model/TemplateInfo} student_data
 */
StaticInfoTemplates.prototype['student_data'] = undefined;

/**
 * @member {module:model/TemplateInfo} affidavit_it
 */
StaticInfoTemplates.prototype['affidavit_it'] = undefined;

/**
 * @member {module:model/TemplateInfo} company_it
 */
StaticInfoTemplates.prototype['company_it'] = undefined;

/**
 * @member {module:model/TemplateInfo} tasks_it
 */
StaticInfoTemplates.prototype['tasks_it'] = undefined;

/**
 * @member {module:model/TemplateInfo} exemption_it
 */
StaticInfoTemplates.prototype['exemption_it'] = undefined;

/**
 * @member {module:model/TemplateInfo} deferral_it
 */
StaticInfoTemplates.prototype['deferral_it'] = undefined;

/**
 * @member {module:model/TemplateInfo} tutor_it
 */
StaticInfoTemplates.prototype['tutor_it'] = undefined;

/**
 * @member {module:model/TemplateInfo} weekly_it
 */
StaticInfoTemplates.prototype['weekly_it'] = undefined;

/**
 * @member {module:model/TemplateInfo} qbid_it
 */
StaticInfoTemplates.prototype['qbid_it'] = undefined;

/**
 * @member {module:model/TemplateInfo} motiv_it
 */
StaticInfoTemplates.prototype['motiv_it'] = undefined;

/**
 * @member {module:model/TemplateInfo} motiv_it_es
 */
StaticInfoTemplates.prototype['motiv_it_es'] = undefined;

/**
 * @member {module:model/TemplateInfo} affidavit_fr
 */
StaticInfoTemplates.prototype['affidavit_fr'] = undefined;

/**
 * @member {module:model/TemplateInfo} company_fr
 */
StaticInfoTemplates.prototype['company_fr'] = undefined;

/**
 * @member {module:model/TemplateInfo} tasks_fr
 */
StaticInfoTemplates.prototype['tasks_fr'] = undefined;

/**
 * @member {module:model/TemplateInfo} exemption_fr
 */
StaticInfoTemplates.prototype['exemption_fr'] = undefined;

/**
 * @member {module:model/TemplateInfo} deferral_fr
 */
StaticInfoTemplates.prototype['deferral_fr'] = undefined;

/**
 * @member {module:model/TemplateInfo} tutor_fr
 */
StaticInfoTemplates.prototype['tutor_fr'] = undefined;

/**
 * @member {module:model/TemplateInfo} weekly_fr
 */
StaticInfoTemplates.prototype['weekly_fr'] = undefined;

/**
 * @member {module:model/TemplateInfo} qbid_fr
 */
StaticInfoTemplates.prototype['qbid_fr'] = undefined;

/**
 * @member {module:model/TemplateInfo} motiv_fr
 */
StaticInfoTemplates.prototype['motiv_fr'] = undefined;

/**
 * @member {module:model/TemplateInfo} motiv_fr_es
 */
StaticInfoTemplates.prototype['motiv_fr_es'] = undefined;

/**
 * @member {module:model/TemplateInfo} final
 */
StaticInfoTemplates.prototype['final'] = undefined;






export default StaticInfoTemplates;

