import {Component, Fragment, h} from "preact";
import {CF} from "../../_common/layout";
import {ChooseDeliver, places} from "./choose-deliver";
import {connect} from "react-redux";
import {fetchTitlePlace} from "../../_rx/student/actions";
import {addDays, formatDDMMYYYY} from "../../_common/common.ts";


const OnlineLabels = () => <Fragment>
    <p>Debes pasar a recoger el título oficial en:</p>
    <div className='card background-info p-all font-color-default'>
        <div className='bold'>ILERNA Online</div>
        <div className='m-top'>Complex Magical Media, Edifici 5,</div>
        <div>Turó de Gardeny</div>
        <div>25003 (Lleida)</div>
        <a href='https://g.page/ilernaonline?share' className='t-underline cursor-pointer'>Ver mapa</a>
        <div className='m-top'>Lunes a jueves: 10:00 a 13:30 y 15:30 a 17:30</div>
        <div>Viernes: de 10:00 a 13:30</div>
    </div>
</Fragment>

const PhysicalBuildLabels = ({date, place}) => <Fragment>
    <p>Debes pasar a recoger el título oficial a partir del {date} en:</p>
    <div className='card background-info p-all font-color-default'>
        <div className='bold'>ILERNA {place.value}</div>
        <div className='m-top'>{place.address}</div>
        <div>{place.postalCode}</div>
        <a href={place.map} className='t-underline cursor-pointer'>Ver mapa</a>
        <div className='m-top'>{place.shiftDays}: {place.shift}</div>
    </div>
</Fragment>


export let PendingPickup = class extends Component {

    componentDidMount() {
        this.props.fetchTitlePlace(this.props.auth.loggedUser.id);
    }

    render({title_place}) {
        const isOnline = title_place && (title_place.place === 'Lleida')
        const place = title_place && places.find(p=>p.value == title_place.place);
        const date = title_place ? formatDDMMYYYY(addDays(title_place.set_at, 15)) : '—';

        return <Fragment>
            {title_place &&
                <CF className="max-width" style='margin-top:32px'>
                    { isOnline ? <OnlineLabels /> : <PhysicalBuildLabels date={date} place={place}/> }
                </CF>
            }
        </Fragment>;
    }
}


PendingPickup = connect(
    ({auth, title_place})=>({auth, title_place}),
    {fetchTitlePlace})(PendingPickup);

