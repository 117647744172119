import React, {h} from 'preact';
import {useState} from "preact/hooks";
import {FR} from "../../../_common/layout";
import {t} from "../../../_i18n";
import Modal from "../../../_common/components/Modal";
import "../../styles/modal-evau-styles.scss"
import {UploadFile} from "../../../_common/forms/components/components";
import {isImageFile, resizeImage} from "../../../_common/common";
import {MAX_IMAGE_PIXELS} from "../../../constants";
import {getEvauDocumentationStatus, uploadEvauDocument} from "../../../_rx/enrollment/exams/actions";
import ButtonSpinner from "../../../_common/components/buttons/buttonSpinner/buttonSpinner";


export default function ModalEvau({studentId, visible, evauState, setEvauState, closeModal}) {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const formData = new FormData()

    const handleUploadFile = async (event) => {
        let eventFile = event.target.files[0];

        if(isImageFile(eventFile)) {
            eventFile = await resizeImage(eventFile, MAX_IMAGE_PIXELS);
        }
        setFile(eventFile);
    }

    const handleRemoveFile = () => {
        formData.delete('file');
        setFile(null);
        setEvauState({documents: [], hasValidatedDocuments: false});
    }

    const handleSubmitFile = async () => {
        setLoading(true)
        const resp = await uploadEvauDocument(studentId, file);

        if (resp.error) {
            setFile(null);
        } else {
            setEvauState(await getEvauDocumentationStatus(studentId));
        }

        setLoading(false)
        closeModal();
    }

    return (
        <Modal visible={visible} closeModal={closeModal} bodyClass='sm-m-top modal-evau'>
            <FR className={'modal-title-padding row-2-el p-all p-x2-left p-x2-right'}>
                <div className='col-80'>
                    <p className="t-left font-18 font-bold no-margin">{t('evau_modal_title')}</p>
                </div>
                <div className={'t-right col-20'}>
                    <a onClick={closeModal} className="modal-close-jobs modal-link">
                        <i className="fal fa-times font-20"/>
                    </a>
                </div>
            </FR>
            <hr className="separator-xxs no-margin-top"/>
            <div className='modal-evau__body'>
                <p dangerouslySetInnerHTML={{__html: t('evau_modal_greeting')}}></p>
                <p dangerouslySetInnerHTML={{__html: t('evau_modal_1')}}></p>
                <p dangerouslySetInnerHTML={{__html: t('evau_modal_2')}}></p>
                <p dangerouslySetInnerHTML={{__html: t('evau_modal_3')}}></p>
                <p dangerouslySetInnerHTML={{__html: t('evau_modal_4')}}></p>
                <div className='m-top'>
                    <strong>{t('Documento de solicitud')}:</strong>
                </div>
                {!file ?
                    <div className='m-top'>
                        <UploadFile classNameButton='btn btn-ghost m-top m-bottom t-upper'
                                    accept='image/jpeg,image/png,application/pdf' onChange={handleUploadFile}>
                            {t('Subir archivo')}
                        </UploadFile>
                        <span className='m-top font-color-879CA1'>{t('apply_review_types')}</span>
                    </div>
                    :
                    <div className='m-bottom d-flex flex-center-y'>
                        <span className='m-r'>{file.name}</span>
                        <i className="fal fa-times-circle icon-color-red cursor-pointer" onClick={handleRemoveFile}/>
                    </div>
                }
            </div>
            <hr className="separator-xxs no-margin"/>
            <div className='modal-evau__footer'>
                <ButtonSpinner showSpinner={loading} disabled={!file} onClick={handleSubmitFile}>
                    {t('ENVIAR DOCUMENTO')}
                </ButtonSpinner>
            </div>

        </Modal>
    )
}
