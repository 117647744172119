import {Component, h, Fragment} from "preact";
import './pagination.scss'

//<Pagination pageMax={18} page={actualPage} onClick={onClick}/>
const Pagination = ({...props}) => {
    const isPageMaxMoreThanSeven = props.pageMax > 7;
    const isPageMaxMoreThanOne = props.pageMax > 1

    return (isPageMaxMoreThanOne)
    ? (isPageMaxMoreThanSeven) ? <PaginationMoreThanSeven {...props}/> : <PaginationLessThanSeven {...props}/>
    : <Fragment />
}
export default Pagination;


const PaginationLessThanSeven = ({pageMin = 1, pageMax, page, onClick}: any) => {
    const isTheActualPageTheMin = page === pageMin;
    const isTheActualPageTheMax = page === pageMax;

    const renderPages = () => {
        const boxes = []
        for (let i = pageMin; i <= pageMax; i++) {
            boxes.push(<span key={i} className={`${page === i ? 'pagination page-actual' : 'pagination page'}`} onClick={() => onClick(i)} >{i}</span>)
        }
        return boxes
    }

    return <div className='pagination-component'>
        {!isTheActualPageTheMin && <span className='pagination page-xtrem' onClick={() => onClick(page - 1)}>
            <span className='pagination page-xtrem-icon'>{'<'}</span>
            <span className='pagination button left'>{'ANTERIOR'}</span>
        </span>}
        {renderPages()}
        {!isTheActualPageTheMax && <span className='pagination page-xtrem' onClick={() => onClick(page + 1)}>
            <span className='pagination button right'>{'SIGUIENTE'}</span>
            <span className='pagination page-xtrem-icon'>{'>'}</span>
        </span>}
    </div>
}




const PaginationMoreThanSeven = ({pageMin = 1, pageMax, page, onClick}: any) => {
    const isTheActualPageTheMin = page === pageMin;
    const isTheActualPageTheMax = page === pageMax;
    const isTheActualPageAlmostMin = (page >= pageMin + 1 && page <= pageMin + 3);
    const isTheActualPageAlmostMax = (page <= pageMax - 1 && page >= pageMax - 3);
    const middlePage = !isTheActualPageTheMin && !isTheActualPageTheMax && !isTheActualPageAlmostMin && !isTheActualPageAlmostMax



    return <div className='pagination-component'>
        {!isTheActualPageTheMin && <span className='pagination page-xtrem' onClick={() => onClick(page - 1)}>
            <span className='pagination page-xtrem-icon'>{'<'}</span>
            <span className='pagination button left'>{'ANTERIOR'}</span>
        </span>}
        {isTheActualPageTheMin && <Fragment>
            <span className='pagination page-actual'>{page}</span>
            <span className='pagination page' onClick={() => onClick(page + 1)}>{page + 1}</span>
            <span className='pagination page' onClick={() => onClick(page + 2)}>{page + 2}</span>
            <span className='pagination page' onClick={() => onClick(page + 3)}>{page + 3}</span>
            <span className='pagination page' onClick={() => onClick(page + 4)}>{page + 4}</span>
            <span className='pagination ellipsis'>...</span>
            <span className='pagination page' onClick={() => onClick(pageMax)}>{pageMax}</span>
        </Fragment>
        }

        {isTheActualPageAlmostMin && <Fragment>
            <span className='pagination page' onClick={() => onClick(pageMin)} >{pageMin}</span>
            <span className={`${page === pageMin + 1 ? 'pagination page-actual' : 'pagination page'}`} onClick={() => onClick(pageMin + 1)} >{pageMin + 1}</span>
            <span className={`${page === pageMin + 2 ? 'pagination page-actual' : 'pagination page'}`} onClick={() => onClick(pageMin + 2)} >{pageMin + 2}</span>
            <span className={`${page === pageMin + 3 ? 'pagination page-actual' : 'pagination page'}`} onClick={() => onClick(pageMin + 3)} >{pageMin + 3}</span>
            <span className={`${page === pageMin + 4 ? 'pagination page-actual' : 'pagination page'}`} onClick={() => onClick(pageMin + 4)} >{pageMin + 4}</span>
            <span className='pagination ellipsis'>...</span>
            <span className='pagination page'  onClick={() => onClick(pageMax)}>{pageMax}</span>
        </Fragment>
        }

        {middlePage
        &&  <Fragment>
            <span className='pagination page' onClick={() => onClick(pageMin)}>{pageMin}</span>
            <span className='pagination ellipsis'>...</span>
            <span className='pagination page' onClick={() => onClick(page - 1)}>{page - 1}</span>
            <span className='pagination page-actual'>{page}</span>
            <span className='pagination page' onClick={() => onClick(page + 1)}>{page + 1}</span>
            <span className='pagination ellipsis'>...</span>
            <span className='pagination page' onClick={() => onClick(pageMax)}>{pageMax}</span>
        </Fragment>
        }

        {isTheActualPageAlmostMax && <Fragment>
            <span className='pagination page' onClick={() => onClick(pageMin)} >{pageMin}</span>
            <span className='pagination ellipsis'>...</span>
            <span className={`${page === pageMax - 4 ? 'pagination page-actual' : 'pagination page'}`} onClick={() => onClick(pageMax - 4)} >{pageMax - 4}</span>
            <span className={`${page === pageMax - 3 ? 'pagination page-actual' : 'pagination page'}`} onClick={() => onClick(pageMax - 3)} >{pageMax - 3}</span>
            <span className={`${page === pageMax - 2 ? 'pagination page-actual' : 'pagination page'}`} onClick={() => onClick(pageMax - 2)} >{pageMax - 2}</span>
            <span className={`${page === pageMax - 1 ? 'pagination page-actual' : 'pagination page'}`} onClick={() => onClick(pageMax - 1)} >{pageMax - 1}</span>
            <span className='pagination page' onClick={() => onClick(pageMax)}>{pageMax}</span>
        </Fragment>
        }

        {isTheActualPageTheMax && <Fragment>
            <span className='pagination page' onClick={() => onClick(pageMin)} >{pageMin}</span>
            <span className='pagination ellipsis'>...</span>
            <span className='pagination page' onClick={() => onClick(page - 4)}>{page - 4}</span>
            <span className='pagination page' onClick={() => onClick(page - 3)}>{page - 3}</span>
            <span className='pagination page' onClick={() => onClick(page - 2)}>{page - 2}</span>
            <span className='pagination page' onClick={() => onClick(page - 1)}>{page - 1}</span>
            <span className='pagination page-actual'>{page}</span>
        </Fragment>
        }
        {!isTheActualPageTheMax && <span className='pagination page-xtrem' onClick={() => onClick(page + 1)}>
            <span className='pagination button right'>{'SIGUIENTE'}</span>
            <span className='pagination page-xtrem-icon'>{'>'}</span>
        </span>}


    </div>
}