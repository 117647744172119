import {Fragment, h} from "preact";
import {t} from '../../_i18n';

export const TechnicalRequirements = () =>
<Fragment>
    <h2>{t('Requisito tecnico')}</h2>
    <p className="section-explanation">
        {t('student_technical_requirement_1')}
    </p>
    <div className="doted-list" dangerouslySetInnerHTML={{__html: t('student_technical_requirement_2')}} />
</Fragment>
