import {SET_DIRECCION_ENVIO} from "./constants";
import {globalCatch} from "../../../_services/global-catch";
import {toastService} from "../../../_services/toast-service";
import {t} from "../../../_i18n";
import {svcPsService} from "../../../_services/ps-service";
import {internalFetchStatus} from "../../student/actions";


export const setDireccionEnvio = payload => ({type: SET_DIRECCION_ENVIO, payload});

export const fetchDireccionEnvio = () =>
    globalCatch.catch(async (dispatch) => {
        const dir = await (await svcPsService()).GetDireccionEnvio();
        dispatch(setDireccionEnvio(dir));
    })
;

export const saveDireccionEnvio = (direccion, studentId) =>
    globalCatch.catch(async (dispatch) => {
        await (await svcPsService()).SaveDireccionEnvio(direccion);
        dispatch(setDireccionEnvio(direccion));
        await internalFetchStatus(dispatch, studentId);
        toastService.showToast({message:t("Tus datos se han guardado correctamente"), type:'info'});
    })
;
