import React, {Component, Fragment, h} from "preact";
import {E, FR, R} from "../../_common/layout";
import {FormField, FormFieldPoblacion, FormFieldSelect} from "../../_common/forms/components/components";
import {t} from '../../_i18n';
import {treatCountriesList} from '../../_common/common'
import {COUNTRY_SIS_ID_ES, SHOP_SIS_COUNTRY_MAPPING, ZIP_PATTERNS} from "../../constants";


export let HomeAddress = class extends Component {
    render({ fixtures, values, poblaciones, shop }) {

        const vias_frecuentes = fixtures.via_type.filter(via => via.main);
        const vias_todas = fixtures.via_type.sort((via1, via2) => via1.name > via2.name? 1 : -1);
        const zipPattern = ZIP_PATTERNS[''+values.address.city.state.country.id];

        return (fixtures || null) && poblaciones && <Fragment>

                <FR>
                    <div className="col-100 sm-col-66">
                        <FormFieldSelect name="address.via_type.id" label={t('Tipo vía')} options_groups={[vias_frecuentes, vias_todas]} labels_groups={[t('Tipos de vía frecuentes').toUpperCase(), t('Listado alfabético').toUpperCase()]} required={true} />
                    </div>
                    <div className="col-100 sm-col-66">
                        <FormField name="address.name" label={t('Nombre vía')} required={true} />
                    </div>
                </FR>
                <FR>
                    <div className="col-100 sm-col-15">
                        <FormField name="address.number_address" label={t('Núm')} required={true} />
                    </div>
                    <div className="col-100 sm-col-15">
                        <FormField name="address.address_block" label={t('Bloq')} showOptional={false} />
                    </div>
                    <div className="col-100 sm-col-15">
                        <FormField name="address.address_stairs" label={t('Escal')} showOptional={false} />
                    </div>
                    <div className="col-100 sm-col-15">
                        <FormField name="address.address_flat" label={t('Piso')} showOptional={false} />
                    </div>
                    <div className="col-100 sm-col-15">
                        <FormField name="address.address_door" label={t('Puerta')} showOptional={false} />
                    </div>
                </FR>
                <hr className="separator-xxs"/>
                <FR>
                    <div className="col-100 sm-col-66">
                        <FormFieldSelect name="address.city.state.country.id" label={t('País residencia')} options={treatCountriesList(fixtures.country, SHOP_SIS_COUNTRY_MAPPING[shop]||COUNTRY_SIS_ID_ES)} required />
                    </div>
                </FR>
                <FR>
                    <div className="col-100 sm-col-30">
                        <FormField name="address.zip_code"
                                   label={t("Código Postal")} autocomplete="zip"
                                   required={true}
                                   pattern={zipPattern} />
                    </div>
                </FR>
                <FR>
                    {values.address.city.state.country.id == 61 &&
                        <div className="col-100 sm-col-50">
                            <FormFieldPoblacion name="address.city.id" label={t("Población")} required={true}
                                                list={poblaciones} pattern=".{2,}"
                                                country={values.address.city.state.country.id}/>
                        </div>
                    }
                    {values.address.city.state.country.id != 61 &&
                        <div className="col-100 sm-col-50">
                            <FormField name="address.city_name" label={t('Población')} required={true}/>
                        </div>
                    }
                </FR>

            </Fragment>
            ;
    }
};
