import React, {Component, Fragment, h} from "preact";
import './buttonSpinner.scss'
import {E, FR} from "../../../layout";
import {t} from "../../../../_i18n";
import {ButtonLink} from "../buttons";

export default function ButtonSpinner({className, classNameContainer, disabled, children, btnType='primary', showSpinner = false, onClick = () => {}, ...props}){
    const showText = showSpinner && 'buttonSpinner-text__inv'

    const handleCkick = () => !disabled && !showSpinner && onClick()

    return <div className={`buttonSpinner-container ${classNameContainer}`}>
        {showSpinner &&
            <div className='buttonSpinner-icon'>
                <div className='buttonSpinner-icon__spinner'><i className="far fa-spinner-third" /></div>
            </div>}
        <button className={`btn btn-${btnType} ${className} ${showText}`} disabled={disabled} {...props} onClick={handleCkick}>{children}</button>
    </div>

}


export function ButtonSpinnerRow({spinner = false, spinnerButtonClassName = '', cancelButtonClassName = '', onCancel, disabledSpinnerButton = false}) {

    return <FR className='m-top'>
        <E className='col-100 sm-col-25'>
            <ButtonSpinner
                className={spinnerButtonClassName}
                showSpinner={spinner}
                disabled={disabledSpinnerButton}
                type='submit'>
                {t('Guardar')}
            </ButtonSpinner>
        </E>
        {onCancel && <E className='col-100 sm-col-25'>
            <ButtonLink
                className={cancelButtonClassName}
                onClick={onCancel && onCancel}
                type='button'>
                {t('Cancelar')}
            </ButtonLink>
        </E>}

    </FR>

}
