/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The InlineObject3 model module.
 * @module model/InlineObject3
 * @version 1.0
 */
class InlineObject3 {
    /**
     * Constructs a new <code>InlineObject3</code>.
     * @alias module:model/InlineObject3
     * @param title {String} title
     * @param body {String} body
     * @param hasNotification {Boolean} has notification
     * @param segmentOperator {String} segment join operator (or/and)
     */
    constructor(title, body, hasNotification, segmentOperator) { 
        
        InlineObject3.initialize(this, title, body, hasNotification, segmentOperator);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, title, body, hasNotification, segmentOperator) { 
        obj['title'] = title;
        obj['body'] = body;
        obj['has_notification'] = hasNotification;
        obj['segment_operator'] = segmentOperator;
    }

    /**
     * Constructs a <code>InlineObject3</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/InlineObject3} obj Optional instance to populate.
     * @return {module:model/InlineObject3} The populated <code>InlineObject3</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InlineObject3();

            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('body')) {
                obj['body'] = ApiClient.convertToType(data['body'], 'String');
            }
            if (data.hasOwnProperty('programmed_at')) {
                obj['programmed_at'] = ApiClient.convertToType(data['programmed_at'], 'String');
            }
            if (data.hasOwnProperty('has_email')) {
                obj['has_email'] = ApiClient.convertToType(data['has_email'], 'Boolean');
            }
            if (data.hasOwnProperty('has_notification')) {
                obj['has_notification'] = ApiClient.convertToType(data['has_notification'], 'Boolean');
            }
            if (data.hasOwnProperty('notification_title')) {
                obj['notification_title'] = ApiClient.convertToType(data['notification_title'], 'String');
            }
            if (data.hasOwnProperty('notification_text')) {
                obj['notification_text'] = ApiClient.convertToType(data['notification_text'], 'String');
            }
            if (data.hasOwnProperty('segment_operator')) {
                obj['segment_operator'] = ApiClient.convertToType(data['segment_operator'], 'String');
            }
        }
        return obj;
    }


}

/**
 * title
 * @member {String} title
 */
InlineObject3.prototype['title'] = undefined;

/**
 * body
 * @member {String} body
 */
InlineObject3.prototype['body'] = undefined;

/**
 * programmed at
 * @member {String} programmed_at
 */
InlineObject3.prototype['programmed_at'] = undefined;

/**
 * has email
 * @member {Boolean} has_email
 */
InlineObject3.prototype['has_email'] = undefined;

/**
 * has notification
 * @member {Boolean} has_notification
 */
InlineObject3.prototype['has_notification'] = undefined;

/**
 * title of the notification
 * @member {String} notification_title
 */
InlineObject3.prototype['notification_title'] = undefined;

/**
 * text of the notification
 * @member {String} notification_text
 */
InlineObject3.prototype['notification_text'] = undefined;

/**
 * segment join operator (or/and)
 * @member {String} segment_operator
 */
InlineObject3.prototype['segment_operator'] = undefined;






export default InlineObject3;

