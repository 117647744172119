import {Fragment, h} from "preact";
import {AppPage} from "../../_common/components/app-page";
import {t} from '../../_i18n';
import {connect, useSelector} from "react-redux";
import {
    fetchExpedientAcademicProgram,
    fetchExpedientAcademicProgramsSelector,
    setReviewParams
} from "../../_rx/expediente/actions";
import {CF, E, FR, RE} from "../../_common/layout";
import {useEffect, useState} from "preact/hooks";
import AcademicProgramSelector from "../components/academicProgramSelector";
import NoticeModule from "../../_common/components/noticeModule/noticeModule";
import ModuleModal from "./moduleModal";
import {ShowTerms} from "../components/moduleModalDetail/gradeCalculation";
import ExpedienteMenu from "../components/expedienteHeader/expedienteMenu";
import Loading from "../../_common/components/loading/Loading";
import './expendiente.scss'
import ProgressSection from '../components/progressSection/ProgressSection'
import ProgressCard from '../components/progressSection/ProgressCard'
import {DateTime} from "luxon";
import {ButtonPrimary} from "../../_common/components/buttons/buttons";
import {ModalFinishedAPCertificate} from "../components/modalFinishedAPCertificate";
import {STUDENT_NO_TITLE, STUDENT_NO_VALIDATED, STUDENT_VALIDATED} from "../../app/getUserRol";
import {toastService} from "../../_services/toast-service";
import {isSpanishUser} from "../../_common/utils/getLang";

function ExpedienteView({match, history, canvasId, expedient, studentId, educationLaw, ...props}) {
    const [showAPCertificateButton, setShowAPCertificateButton] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const [modalAPFinishedVisible, setModalAPFinishedVisible] = useState(false)
    const [academicProgram, setAcademicProgram] = useState(null)
    const [academicProgramSelector, setAcademicProgramSelector] = useState(null)
    const [academicProgramSelected, setAcademicProgramSelected] = useState(null)
    const [module, setModule] = useState(null)
    const {lang, student_access} = useSelector(state => state);
    const student_meta = useSelector(state => state.status.student_meta);
    const studentAccessForAcademicProgramCard = [STUDENT_NO_VALIDATED, STUDENT_VALIDATED, STUDENT_NO_TITLE];

    useEffect(async () => {
        const academicProgramSelector = await props.fetchExpedientAcademicProgramsSelector(studentId);

        setAcademicProgramSelected(academicProgramSelector[0])
        setAcademicProgramSelector(academicProgramSelector)
        props.setReviewParams({})

    }, [])

    useEffect(() => {
        if (academicProgram?.certificates?.length > 1) {
            setShowAPCertificateButton(true)
        } else if (academicProgram?.allow_buy_grade_certificate_taxes) {
            const blocks = props.status?.blocks
            const studentAcademicProgramBlockKey = blocks ? Object.keys(blocks).find(key => blocks[key].block_name === 'student_academic_program') : false
            const studentAcademicProgramBlock = studentAcademicProgramBlockKey ? blocks[studentAcademicProgramBlockKey] : false
            const academicProgramCardAvailable = studentAcademicProgramBlock && studentAcademicProgramBlock?.status?.id > 0

            if (studentAccessForAcademicProgramCard.includes(student_access) && academicProgramCardAvailable) {
                setShowAPCertificateButton(true)
            } else {
                setShowAPCertificateButton(false)
            }
        } else {
            setShowAPCertificateButton(false)
        }
    }, [academicProgram?.certificates])

    useEffect(async () => {
        if (academicProgramSelected) {
            const academicProgram = await props.fetchExpedientAcademicProgram(studentId, academicProgramSelected.id);
            setAcademicProgram(academicProgram)
        }
    }, [academicProgramSelected])


    useEffect(() => {
        const moduleIds = match.params.module_id;

        if (!moduleIds && modalVisible) {
            setModalVisible(false)
            props.setReviewParams({})
        } else if (moduleIds && !modalVisible && module) {
            setModalVisible(true)
        } else if (!module && moduleIds) {
            history.push('/expediente')
        }
    }, [match.params.module_id])

    const handleProgramSelector = (programSelected) => {
        if (programSelected !== academicProgramSelected) {
            setAcademicProgramSelected(programSelected)
            setAcademicProgram(null)
        }
    }

    const handleModulePicked = (module) => {
        const hasChuldrenUrl = (module.has_children) ? 'ch' : 'sb';
        setModule(module)
        history.push('/expediente/' + hasChuldrenUrl + '-' + module.id)
    }

    const getRecognitionText = (module) => {
        if (!module.recognized_new || module.recognized_new.status === 0)
            return ''

        const timeLimitDate = DateTime.fromFormat(module.recognized_new.timelimit, 'yyyy-MM-dd hh:mm:ss')

        const statusKey = module.recognized_new.status === 2 ? 'recognition_denied' : 'recognition_requested'
        const statusKeyPrefix = module.recognized_new.type === 0 ? 'partial_' : ''
        const managementKey = timeLimitDate < DateTime.now() ? 'recognition_management_will_start' : 'recognition_management_started'

        const locale = lang === 'fr' && 'fr-FR' || lang === 'it' && 'it-IT' || 'es-ES'
        const dateString = timeLimitDate.setLocale(locale).toLocaleString({month: 'long', year: 'numeric'})

        const managementString = module.recognized_new.status !== 2 ? t(managementKey).replace('{datelimit}', dateString) + '. ' : ''

        return `<span class='font-bold'>${t(statusKeyPrefix + statusKey) + (module.recognized_new.status !== 2 ? ':' : '')} </span>${managementString}<span class='t-underline'>${t('recognition_more_info')}</span>.`
    }

    if (!academicProgramSelector) return <CF className="max-width"><Loading label={`${t('Cargando')}. . . `}/></CF>

    const handleCertificateButton = () => {
        if (academicProgram?.certificates?.length > 1) {
            setModalAPFinishedVisible(true);
        } else if (academicProgram?.allow_buy_grade_certificate_taxes) {
            history.push('/academic-program-card');
        } else {
            // This should never be reached but just in case...
            toastService.showToast({message: t('El certificado no esta disponible'), type: 'danger'})
        }
    }

    return (academicProgramSelector) &&
        <CF className="max-width">
            <div className='d-flex flex-space-between flex-center-y'>
                <h1 className='hero expedient-title'>{t('Expediente académico')}</h1>
                {academicProgram &&
                    <ExpedienteMenu academicProgramId={academicProgram.id} academicProgram={academicProgram}/>}
            </div>
            <RE>
                <AcademicProgramSelector academicProgramSelector={academicProgramSelector}
                                         onChange={handleProgramSelector}/>
            </RE>
            {!academicProgram ? <div className='m-top'><Loading label={`${t('Cargando')}. . . `}/></div>
                : <Fragment>
                    {academicProgram?.schoolCenter &&
                        <div>
                            <span>{t('Centro')}: <b>{academicProgram.schoolCenter?.onlineTitle ?? ''}</b> ({t('codigo')} {academicProgram.schoolCenter?.educationCenterCode ?? ''})</span>
                        </div>
                    }
                    {academicProgram.approved ?
                        <FR className='d-block sm-no-reverse'>
                            <E>
                                <div className='col-35 no-margin'>
                                <ProgressCard
                                        title={t('Nota final')}
                                        grade={academicProgram.grade}
                                        subtitle={t('Ciclo finalizado')}
                                    />
                                </div>
                            </E>
                            {isSpanishUser(student_meta.shop_id) && showAPCertificateButton &&
                                <FR>
                                    <E>
                                        <ButtonPrimary className='full-width sm-no-full-width'
                                                       onClick={handleCertificateButton}>
                                            {t('Solicitar certificado de ciclo finalizado')}
                                        </ButtonPrimary>
                                    </E>
                                    <ModalFinishedAPCertificate academicProgram={academicProgram}
                                                                visible={modalAPFinishedVisible}
                                                                closeModal={() => setModalAPFinishedVisible(false)}/>
                                </FR>
                            }
                        </FR>
                        : <ProgressSection academicProgram={academicProgram}/>
                    }
                    <div className='m-bottom'>
                        <div className='expedient-grades-header'>
                            <div
                                className='expedient-grades-header__title large font-14'>{t('Módulos profesionales')}</div>
                            <div className='expedient-grades-header__grade large font-14'>{t('nota')}</div>
                        </div>
                        {academicProgram.modules && academicProgram.modules.map(module => {
                                const teachingHours = module.teaching_hours ? `- ${module.teaching_hours} ${t('horas')}` : '';
                                return <NoticeModule
                                    key={module.id}
                                    subTitle={`${module.abbr} ${teachingHours}`}
                                    title={module.title}
                                    grade={module.grade}
                                    approved={module.approved}
                                    onClick={() => handleModulePicked(module)}
                                    isEnrolled={module.is_enrolled}
                                    enrolledStatus={module.enroll_status}
                                    textInfo={getRecognitionText(module)}
                                    hiddenGrade={module.hidden_grade}
                                    isProjectModule={module.is_project}
                                    isTrainingWorkCenterModule={module.is_training_work_center}
                                    //gradeTooltip={module.recognized && t('Convalidada')}
                                    large
                                    isEnabled
                                />
                            }
                        )}
                    </div>
                    <div className='m-x2-top'>
                        <ShowTerms educationLaw={educationLaw} formative_zone={academicProgram.formative_zone} main/>
                    </div>
                    <ModuleModal
                        visible={modalVisible}
                        matchParams={match.params.module_id}
                        params={match.params}
                        module={module}
                        onClick={() => history.push('/expediente')}
                        historyRoute={history}
                        formativeZone={academicProgram.formative_zone}
                    />
                </Fragment>
            }

        </CF>;
}


ExpedienteView = connect(
    ({auth: {loggedUser}, status, expedient: {educationLaw}}) => ({status, educationLaw, studentId: loggedUser.id}),
    {fetchExpedientAcademicProgramsSelector, fetchExpedientAcademicProgram, setReviewParams})(ExpedienteView);

let ExpedientePage = ({match, ...props}) =>
    <AppPage
        name="expediente"
        class="max-width">
        <ExpedienteView match={match} {...props}/>

    </AppPage>
;

export default ExpedientePage;
