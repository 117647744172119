/**
 * New Expedient
 * Expedient for Student Account
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The SubjectOfferInClassGrade model module.
 * @module model/SubjectOfferInClassGrade
 * @version 1.0
 */
class SubjectOfferInClassGrade {
    /**
     * Constructs a new <code>SubjectOfferInClassGrade</code>.
     * @alias module:model/SubjectOfferInClassGrade
     */
    constructor() { 
        
        SubjectOfferInClassGrade.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SubjectOfferInClassGrade</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SubjectOfferInClassGrade} obj Optional instance to populate.
     * @return {module:model/SubjectOfferInClassGrade} The populated <code>SubjectOfferInClassGrade</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SubjectOfferInClassGrade();

            if (data.hasOwnProperty('key_name')) {
                obj['key_name'] = ApiClient.convertToType(data['key_name'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('grade')) {
                obj['grade'] = ApiClient.convertToType(data['grade'], 'String');
            }
            if (data.hasOwnProperty('grade_factor')) {
                obj['grade_factor'] = ApiClient.convertToType(data['grade_factor'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {String} key_name
 */
SubjectOfferInClassGrade.prototype['key_name'] = undefined;

/**
 * @member {String} name
 */
SubjectOfferInClassGrade.prototype['name'] = undefined;

/**
 * @member {String} grade
 */
SubjectOfferInClassGrade.prototype['grade'] = undefined;

/**
 * @member {Number} grade_factor
 */
SubjectOfferInClassGrade.prototype['grade_factor'] = undefined;






export default SubjectOfferInClassGrade;

