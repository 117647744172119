import React, {Component, Fragment, h} from "preact";

import {CF} from "../../_common/layout";
import {t} from "../../_i18n";

import './choose-deliver.scss';
import {connect} from "react-redux";
import {fetchTitleCart, postTitlePlace} from "../../_rx/student/actions";
import {Payment} from "./payment";
import TextBox from "../../_common/components/textBox/textBox";

export const places = [
    {
        k:'loc-lleida',
        value: 'Lleida',
        name:'Recoger el título en ILERNA Online',
        location: 'Complex Magical Media, Edifici 5, Turó de Gardeny. 25003 (Lleida)',
        shiftDays:'Lunes a jueves',
        shift: '10:00 a 13:30 y 15:30 a 17:30  /  Viernes: de 10:00 a 13:30',
        map:'https://g.page/ilernaonline?share'
    },
    {
        k:'loc-madrid',
        value: 'Madrid',
        name:'Solicitar el traslado permanente de tu título en la sede de ILERNA Madrid',
        location: 'Calle Francisco Navacerrada, 57. 28028 (Madrid)',
        address: 'Calle Francisco Navacerrada, 57.',
        postalCode: '28028 (Madrid)',
        shiftDays:'Lunes a viernes',
        shift: '10:00 a 13:30 y 16:00 a 18:00',
        map:'https://g.page/ILERNAMadrid?share'
    },
    {
        k:'loc-sevilla',
        value: 'Sevilla',
        name:'Solicitar el traslado permanente de tu título en la sede de ILERNA Sevilla',
        location: 'Avenida de la Innovación, 9. Edificio Arena 2. 41002 (Sevilla)',
        address: 'Avenida de la Innovación, 9. Edificio Arena 2.',
        postalCode: '41002 (Sevilla)',
        shiftDays:'Lunes a viernes',
        shift: '10:00 a 13:30 y 16:00 a 18:00',
        map:'https://g.page/ilerna-sevilla?share'
    },
];


export let ChooseDeliver = class extends Component {

    state = {choosen: places[0].k};

    confirm(){
        // confirm choosen
        const value = places.find(place=> document.getElementById(place.k).checked).value;
        this.props.setTitlePlace(this.props.auth.loggedUser.id, value);
    }

    render() {
        return <Fragment>
            <CF className="max-width choose-location">
                <p>{t('Hemos recibido el título oficial en la sede central de ILERNA Online en Lleida')}.</p>
                <p className='bold'>{t('Indica qué opción de recogida prefieres')}:</p>

                {places.map(loc => <div>
                    <input type="radio" name="location" id={loc.k} checked={this.state.choosen === loc.k} />
                    <label key={loc.k} htmlFor={loc.k}>
                        <i className="fa fa-check-circle circle-validated font-20 choose-deliver-icon" />
                        <i  className="fal fa-circle circle-validated font-20 choose-deliver-no-icon"/>
                        <div className='font-18 col-95 bold no-margin'>{loc.name}</div>
                        <TextBox className='m-top' icon={<i className={`fal fa-building`}/>} >{loc.location}</TextBox>
                        <TextBox className='m-top-half' icon={<i className={`fal fa-building`}/>} >{loc.shiftDays}: {loc.shift}.</TextBox>
                    </label>
                </div>)}

                <button className="btn btn-primary full-width sm-no-full-width m-top" type="button"
                        onClick={() => this.confirm()}>
                    {t('Confirmar opción de entrega')}
                </button>
            </CF>

        </Fragment>;
    }
}


ChooseDeliver = connect(
    ({auth})=>({auth}),
    {setTitlePlace: postTitlePlace})(ChooseDeliver);

