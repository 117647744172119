/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DocumentInfo from './DocumentInfo';

/**
 * The OtherDocsDocuments model module.
 * @module model/OtherDocsDocuments
 * @version 1.0.0
 */
class OtherDocsDocuments {
    /**
     * Constructs a new <code>OtherDocsDocuments</code>.
     * @alias module:model/OtherDocsDocuments
     */
    constructor() { 
        
        OtherDocsDocuments.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>OtherDocsDocuments</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OtherDocsDocuments} obj Optional instance to populate.
     * @return {module:model/OtherDocsDocuments} The populated <code>OtherDocsDocuments</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OtherDocsDocuments();

            if (data.hasOwnProperty('final_evaluation_template')) {
                obj['final_evaluation_template'] = DocumentInfo.constructFromObject(data['final_evaluation_template']);
            }
            if (data.hasOwnProperty('final_evaluation')) {
                obj['final_evaluation'] = DocumentInfo.constructFromObject(data['final_evaluation']);
            }
            if (data.hasOwnProperty('deferral')) {
                obj['deferral'] = DocumentInfo.constructFromObject(data['deferral']);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/DocumentInfo} final_evaluation_template
 */
OtherDocsDocuments.prototype['final_evaluation_template'] = undefined;

/**
 * @member {module:model/DocumentInfo} final_evaluation
 */
OtherDocsDocuments.prototype['final_evaluation'] = undefined;

/**
 * @member {module:model/DocumentInfo} deferral
 */
OtherDocsDocuments.prototype['deferral'] = undefined;






export default OtherDocsDocuments;

