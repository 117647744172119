/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import RefuseResponse from '../model/RefuseResponse';
import SignContractResponse from '../model/SignContractResponse';

/**
* ContractManagement service.
* @module api/ContractManagementApi
* @version 1.0.0
*/
export default class ContractManagementApi {

    /**
    * Constructs a new ContractManagementApi. 
    * @alias module:api/ContractManagementApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Rechazar empresa de contrato
     * Rechaza una empresa asociada a un contrato específico.
     * @param {Number} contractId ID del contrato
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RefuseResponse} and HTTP response
     */
    apiV1MyaccountRefusecompanyContractIdPostWithHttpInfo(contractId) {
      let postBody = null;
      // verify the required parameter 'contractId' is set
      if (contractId === undefined || contractId === null) {
        throw new Error("Missing the required parameter 'contractId' when calling apiV1MyaccountRefusecompanyContractIdPost");
      }

      let pathParams = {
        'contract_id': contractId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = RefuseResponse;
      return this.apiClient.callApi(
        '/api/v1/myaccount/refusecompany/{contract_id}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Rechazar empresa de contrato
     * Rechaza una empresa asociada a un contrato específico.
     * @param {Number} contractId ID del contrato
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RefuseResponse}
     */
    apiV1MyaccountRefusecompanyContractIdPost(contractId) {
      return this.apiV1MyaccountRefusecompanyContractIdPostWithHttpInfo(contractId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Firma de contrato
     * Permite firmar un contrato específico.
     * @param {Number} contractId ID del contrato
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SignContractResponse} and HTTP response
     */
    apiV1MyaccountSigncontractContractIdPostWithHttpInfo(contractId) {
      let postBody = null;
      // verify the required parameter 'contractId' is set
      if (contractId === undefined || contractId === null) {
        throw new Error("Missing the required parameter 'contractId' when calling apiV1MyaccountSigncontractContractIdPost");
      }

      let pathParams = {
        'contract_id': contractId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = SignContractResponse;
      return this.apiClient.callApi(
        '/api/v1/myaccount/signcontract/{contract_id}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Firma de contrato
     * Permite firmar un contrato específico.
     * @param {Number} contractId ID del contrato
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SignContractResponse}
     */
    apiV1MyaccountSigncontractContractIdPost(contractId) {
      return this.apiV1MyaccountSigncontractContractIdPostWithHttpInfo(contractId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
