/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Attachment from '../model/Attachment';
import InlineResponse200 from '../model/InlineResponse200';
import InlineResponse2001 from '../model/InlineResponse2001';
import InlineResponse404 from '../model/InlineResponse404';

/**
* Attachment service.
* @module api/AttachmentApi
* @version 1.0
*/
export default class AttachmentApi {

    /**
    * Constructs a new AttachmentApi. 
    * @alias module:api/AttachmentApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Upload an attachment
     * @param {File} file file to upload
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2001} and HTTP response
     */
    appHttpControllersAttachmentControllerCreateWithHttpInfo(file) {
      let postBody = null;
      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling appHttpControllersAttachmentControllerCreate");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': file
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2001;
      return this.apiClient.callApi(
        '/api/v1/attachment', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Upload an attachment
     * @param {File} file file to upload
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2001}
     */
    appHttpControllersAttachmentControllerCreate(file) {
      return this.appHttpControllersAttachmentControllerCreateWithHttpInfo(file)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove an attachment
     * @param {Number} id Attachment id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Attachment} and HTTP response
     */
    appHttpControllersAttachmentControllerDeleteWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersAttachmentControllerDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Attachment;
      return this.apiClient.callApi(
        '/api/v1/attachment/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Remove an attachment
     * @param {Number} id Attachment id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Attachment}
     */
    appHttpControllersAttachmentControllerDelete(id) {
      return this.appHttpControllersAttachmentControllerDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get detail of an attachment
     * @param {Number} id Attachment id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Attachment} and HTTP response
     */
    appHttpControllersAttachmentControllerDetailsWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersAttachmentControllerDetails");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Attachment;
      return this.apiClient.callApi(
        '/api/v1/attachment/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get detail of an attachment
     * @param {Number} id Attachment id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Attachment}
     */
    appHttpControllersAttachmentControllerDetails(id) {
      return this.appHttpControllersAttachmentControllerDetailsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Download and previosly uploaded attachment
     * @param {Number} id Attachment id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
     */
    appHttpControllersAttachmentControllerDownloadWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersAttachmentControllerDownload");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/api/v1/attachment/{id}/download', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Download and previosly uploaded attachment
     * @param {Number} id Attachment id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
     */
    appHttpControllersAttachmentControllerDownload(id) {
      return this.appHttpControllersAttachmentControllerDownloadWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get list of attachments
     * @param {Object} opts Optional parameters
     * @param {String} opts.uuid Filter by uuid
     * @param {String} opts.name Filter by name
     * @param {String} opts.hash Filter by hash
     * @param {Number} opts.page Page to show
     * @param {Number} opts.limit Elements per page
     * @param {module:model/String} opts.sort Sort by field
     * @param {module:model/String} opts.sortOrder sort order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse200} and HTTP response
     */
    appHttpControllersAttachmentControllerIndexWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'uuid': opts['uuid'],
        'name': opts['name'],
        'hash': opts['hash'],
        'page': opts['page'],
        'limit': opts['limit'],
        'sort': opts['sort'],
        'sort_order': opts['sortOrder']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse200;
      return this.apiClient.callApi(
        '/api/v1/attachments', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get list of attachments
     * @param {Object} opts Optional parameters
     * @param {String} opts.uuid Filter by uuid
     * @param {String} opts.name Filter by name
     * @param {String} opts.hash Filter by hash
     * @param {Number} opts.page Page to show
     * @param {Number} opts.limit Elements per page
     * @param {module:model/String} opts.sort Sort by field
     * @param {module:model/String} opts.sortOrder sort order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse200}
     */
    appHttpControllersAttachmentControllerIndex(opts) {
      return this.appHttpControllersAttachmentControllerIndexWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update an attachment
     * @param {Number} id Attachment id
     * @param {File} file file to upload
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2001} and HTTP response
     */
    appHttpControllersAttachmentControllerUpdateWithHttpInfo(id, file) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersAttachmentControllerUpdate");
      }
      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling appHttpControllersAttachmentControllerUpdate");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': file
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2001;
      return this.apiClient.callApi(
        '/api/v1/attachment/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Update an attachment
     * @param {Number} id Attachment id
     * @param {File} file file to upload
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2001}
     */
    appHttpControllersAttachmentControllerUpdate(id, file) {
      return this.appHttpControllersAttachmentControllerUpdateWithHttpInfo(id, file)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
