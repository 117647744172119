import React, { h, Component, Fragment } from "preact";
import PropTypes from "prop-types";
import './Autocomplete.scss';
import {FormFieldContainer, FormFieldEventListeners, showError} from "./components";

function sinTildesNiHistorias(s:any) {
    // Si la búsqueda se nota lenta, quitar los "accents oberts". Si aún es lenta, quitar todo
    // s = s.replace(/  +/g, ' ');
    s = s.replace('á','a');
    s = s.replace('é','e');
    s = s.replace('í','i');
    s = s.replace('ó','o');
    s = s.replace('ú','u');
    s = s.replace('à','a');
    s = s.replace('è','e');
    // s = s.replace('ì','i');
    s = s.replace('ò','o');
    // s = s.replace('ù','u');
    // s = s.replace('ä','a');
    // s = s.replace('ë','e');
    // s = s.replace('ï','i');
    // s = s.replace('ö','o');
    // s = s.replace('ü','u');
    return s;
}

function normalizeStr(s:any) {
    return sinTildesNiHistorias(s.toLowerCase());
}

class Autocomplete extends Component<any,any> {
    static propTypes = {
        suggestions: PropTypes.instanceOf(Array)
    };

    static defaultProps = {
        suggestions: []
    };

    sList = []

    constructor(props:any) {
        super(props);

        this.sList = props.suggestions.map((v:any) => v.value)

        this.state = {
            // The active selection's index
            activeSuggestion: 0,
            // The suggestions that match the user's input by starting with the text
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            // What the user has entered
            userInput: this.props.userInput
        };

    }

    componentDidUpdate(prevProps:any, prevState:any) {
        // Corregir posibles desbordamientos al navegar con teclado:
        let el:any = document.querySelector(".suggestion-active");
        if (el) {
            let optionHeight = el.clientHeight;
            let contHeight:any = el.parentElement.clientHeight;
            if (el.offsetTop + optionHeight > el.parentElement.scrollTop + contHeight) {
                // Overflow por abajo:
                el.parentElement.scrollTop = el.offsetTop - contHeight + optionHeight;
            } else if (el.parentElement.scrollTop >= el.offsetTop) {
                // Overflow por arriba
                el.parentElement.scrollTop = el.offsetTop;
            }
        }
    }


    componentDidMount() {
        document.addEventListener("click",  this.onDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener("click",  this.onDocumentClick);
    }

    onDocumentClick = (e:any) => {
        this.setState({
            showSuggestions: false
        });
    }

    onChangeHidden = (e:any) => {
        this.props.onValueChanged(e);
    }

    onChange = (e:any) => {
        const userInput = e.currentTarget.value;

        if (userInput.length > 0) {

            let filteredSuggestions = this.sList.filter(
                suggestion => normalizeStr(suggestion).startsWith(normalizeStr(userInput))
            );

            if (userInput.length > 2) {
                let otherSuggesions = this.sList.filter(
                    suggestion => !normalizeStr(suggestion).startsWith(normalizeStr(userInput)) && normalizeStr(suggestion).indexOf(normalizeStr(userInput)) > -1
                );
                filteredSuggestions.push(...otherSuggesions)
            }

            this.setState({
                activeSuggestion: 0,
                filteredSuggestions,
                showSuggestions: true,
                userInput: e.currentTarget.value
            });
        } else {
            this.setState({
                showSuggestions: false,
                userInput: e.currentTarget.value
            });
        }


        if (userInput.length == 0) {
            (document.getElementById(this.props.id + '_hid') as any).value = ''
        }
        this.dispatchCustomeChange(e.currentTarget.value);
    };

    dispatchCustomeChange = (v:any) => {
        // No funciona llençar el change del input perquè (p)React sobreesciriu coses dels elements natius
        // Problema: https://github.com/cypress-io/cypress/commit/4a56ca83b3b6e19ec57d10a8160f54f513e8f8ec
        // Solució: https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js
        const element:any = document.getElementById(this.props.id + '_hid');
        const nativeInputValueSetter:any = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;
        // nativeInputValueSetter.call(element, v);
        nativeInputValueSetter.call(element, this.getCurrentValue(v));
        const e = new Event('input', { bubbles: true});
        element.dispatchEvent(e);
    }

    getCurrentValue(v:any) {
        if (this.props.map_keys_as_names)
            return v;
        const ele = this.props.suggestions.filter((ele:any) => ele.value.toLowerCase() == v.toLowerCase())
        if ( ele.length > 0) {
            return ele[0].key;
        }
        else
            return '';
    }

    getCurrentValueById(Id:any) {
        const ele = this.props.suggestions.filter((ele:any) => ele.key == Id)
        if (ele.length > 0) {
            return ele[0].value;
        }
        else {
            return Id;
        }
    }

    handleFocus = () => {
        if (this.props.onFocus) {
            this.props.onFocus()
        }
    }

    handleBlur = () => {
        if (this.props.onBlur) {
            this.props.onBlur()
        }
    }

    onClick = (e:any) => {
        e.preventDefault();
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText
        });
        this.dispatchCustomeChange(e.currentTarget.innerText);
    };

    onKeyDown = (e:any) => {
        const { activeSuggestion, filteredSuggestions } = this.state;

        // Enter
        if (e.keyCode === 13) {
            e.preventDefault();

            let uInput = '';
            if (filteredSuggestions[activeSuggestion]) {
                uInput = filteredSuggestions[activeSuggestion];
            }

            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: uInput
            });
            this.dispatchCustomeChange(uInput);
        }
        // Flecha arriba
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // Flecha abajo
        else if (e.keyCode === 40) {
            if (activeSuggestion === this.state.filteredSuggestions.length - 1) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
        // Esc y Tab
        else if (e.keyCode === 27 || e.keyCode === 9) {
            this.setState({
                showSuggestions: false
            });
        }
    };

    render() {
        const {
            onChangeHidden,
            onChange,
            onClick,
            onKeyDown,
            state: {
                activeSuggestion,
                filteredSuggestions,
                showSuggestions,
                userInput
            }
        } = this;

        let suggestionsListComponent;

        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                let sepExists = false;
                suggestionsListComponent = (
                    <ul class="suggestions">
                        {filteredSuggestions.length &&
                            filteredSuggestions.map((suggestion:any, index:any) => {
                                let className;

                                // Flag the active suggestion with a class
                                if (index === activeSuggestion) {
                                    className = "suggestion-active";
                                }

                                let separator:any = '';
                                if (!normalizeStr(suggestion).startsWith(normalizeStr(userInput)) && !sepExists) {
                                    separator = <hr/>;
                                    sepExists = true;
                                }

                                return (
                                    [separator,
                                    <li id={'suggestion_' + index} className={className} key={'suggestion_' + index} onClick={onClick}>
                                        {suggestion}
                                    </li>]
                                );
                            })
                        }
                    </ul>)
            }


        }

        // if (userInput && !filteredSuggestions.length) {
        //     suggestionsListComponent = (
        //         <div class="no-suggestions">
        //             <em>No se encontraron coincidencias</em>
        //         </div>
        //     );
        // }

        const hiddenId = (id:any) => {
            return id + "_hid"
        }

        return <Fragment>
                <FormFieldContainer
                    id={this.props.id}
                    label={this.props.label}
                    required={this.props.required}
                    name={this.props.name}
                    error={showError(this.props.meta)}>
                    <Fragment>
                        <input
                            id={hiddenId(this.props.id)}
                            type="hidden"
                            name={this.props.name}
                            onChange={onChangeHidden}
                            required={this.props.required}
                        />
                        <input
                            type="text"
                            placeholder=" "
                            id={this.props.id}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            value={this.getCurrentValueById(userInput)}
                            className={"form-control" + (showError(this.props.meta) ? " error" : "")}
                            autocomplete="off"
                            required={this.props.required}
                        />
                    </Fragment>
                </FormFieldContainer>
                {suggestionsListComponent}
            </Fragment>
        ;

    }
}

export default Autocomplete;


