import {SET_PROFILE_SETTINGS} from './constants';
import {globalCatch} from "../../../_services/global-catch";
import StudentAccountService from '../../../_services/student-account-service'
import {toastService} from "../../../_services/toast-service";
import {t} from "../../../_i18n";

export const fetchProfileSettings = (studentId) => globalCatch.catch(async () => {
    const data = await (await new StudentAccountService()).getAllProfilesSets(studentId)
    const profileSetting = {}
    data.forEach( profile => {
        profileSetting[profile.type] = profile.status
    })

    return profileSetting
})

export const sendProfileSettings = (idStudent, body) => globalCatch.catch(async () => {
    const dataToSend = Object.keys(body).map(type => ({
            type: type,
            status: {
                app: body[type].app,
                email: body[type].email
            }
        }))
    await (await new StudentAccountService()).setAllProfileData(idStudent, JSON.stringify(dataToSend));
})
