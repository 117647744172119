import { h } from "preact";

import './notify.scss';
import {Link} from "react-router-dom";

export let Notify = ({notifs, onClear}:any) => notifs.length ?
    <div className="notify">
        {notifs.map((notif:any)=>
            <div key={notif.id} className={notif.type.toLowerCase()}>
                <div className="notification-warning">
                    <p className="no-margin-y m-l-half">
                        <span dangerouslySetInnerHTML={{__html:notif.message}} /> {notif.action && <Link to={notif.route}><b>{notif.action}</b></Link>}
                    </p>
                </div>
            </div>
        )}
    </div> : null
;
