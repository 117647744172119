import React, { Fragment, Component, h} from "preact";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import HistoricalClassesCard from '../components/HistoricalClassesCard/HistoricalClassesCard'
import {t} from '../../../_i18n';
import {useEffect} from 'preact/hooks';
import {
  getPastClasses,
  getNextClasses,
  setClassSelected,
  setCancelEnroll,
  setShowGradesEnroll,
  getClassSessionGrades
} from '../../../_rx/enrollment/classes/actions';
import {DateTime} from "luxon";

function HistoricalClasses ({ status, historicalClasses, getNextClasses, getPastClasses, setClassSelected, setCancelEnroll, setShowGradesEnroll, studentId, classesStatus }) {
  const history = useHistory()
  const isNextEvents = status === 'next'
  const emptyEvents = historicalClasses?.length === 0
  const funcHistoryEvents = isNextEvents ? getNextClasses : getPastClasses
  const emptyEventsMessage = isNextEvents ? `${t('No tienes ninguna clase programada')}.` : `${t('No has realizado aún ninguna clase')}.`

  useEffect(async () => {
    await funcHistoryEvents(studentId)
  }, [status])

  const modifyEnroll = (event) => {
    history.push('/clases/modify')
    setClassSelected(event)
  }

  const cancelEnroll = (event) => setCancelEnroll(event)

  const showEnrollGrades = async (event) => {
    const grades = await getClassSessionGrades(studentId, event.event_calendar_id);
    grades.event_calendar = event;
    setShowGradesEnroll(grades)
  };

  if (!historicalClasses)
    return <div className='font-color-879CA1'>{t('Cargando')}...</div>

  return (
    <div>
      {emptyEvents
        ? <div className='font-color-879CA1'>{emptyEventsMessage}</div>
        : historicalClasses.map(event =>
            event.dateSelected.sessions.map(session =>
                <HistoricalClassesCard
                    event={event}
                    session={session}
                    isNextClasses={isNextEvents}
                    handleModify={modifyEnroll}
                    handleCancel={cancelEnroll}
                    handleShowGrades={showEnrollGrades}
                    isMultipleAcademicProgram={classesStatus.is_multiple_academic_program}
                />
            )
        )
      }
    </div>
  )
}


HistoricalClasses = connect(
  ({auth, enrollmentClasses})=>({
    studentId: auth.loggedUser.id,
    historicalClasses: enrollmentClasses.historicalClasses,
    classesStatus: enrollmentClasses.status
  }),
  {getPastClasses, getNextClasses, setClassSelected, setCancelEnroll, setShowGradesEnroll})(HistoricalClasses);


export default ({...rest}) =>  <HistoricalClasses {...rest}/>
