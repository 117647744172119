/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ContractDetails model module.
 * @module model/ContractDetails
 * @version 1.0.0
 */
class ContractDetails {
    /**
     * Constructs a new <code>ContractDetails</code>.
     * @alias module:model/ContractDetails
     */
    constructor() { 
        
        ContractDetails.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ContractDetails</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ContractDetails} obj Optional instance to populate.
     * @return {module:model/ContractDetails} The populated <code>ContractDetails</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ContractDetails();

            if (data.hasOwnProperty('contract_pdf_name')) {
                obj['contract_pdf_name'] = ApiClient.convertToType(data['contract_pdf_name'], 'String');
            }
            if (data.hasOwnProperty('contract_download_link')) {
                obj['contract_download_link'] = ApiClient.convertToType(data['contract_download_link'], 'String');
            }
            if (data.hasOwnProperty('start_date')) {
                obj['start_date'] = ApiClient.convertToType(data['start_date'], 'String');
            }
            if (data.hasOwnProperty('final_date')) {
                obj['final_date'] = ApiClient.convertToType(data['final_date'], 'String');
            }
            if (data.hasOwnProperty('sign_limit')) {
                obj['sign_limit'] = ApiClient.convertToType(data['sign_limit'], 'String');
            }
            if (data.hasOwnProperty('contract_received')) {
                obj['contract_received'] = ApiClient.convertToType(data['contract_received'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * The name of the contract PDF
 * @member {String} contract_pdf_name
 */
ContractDetails.prototype['contract_pdf_name'] = undefined;

/**
 * The download link for the contract PDF
 * @member {String} contract_download_link
 */
ContractDetails.prototype['contract_download_link'] = undefined;

/**
 * The start date of the contract
 * @member {String} start_date
 */
ContractDetails.prototype['start_date'] = undefined;

/**
 * The final date of the contract
 * @member {String} final_date
 */
ContractDetails.prototype['final_date'] = undefined;

/**
 * The sign limit for the contract
 * @member {String} sign_limit
 */
ContractDetails.prototype['sign_limit'] = undefined;

/**
 * Contract received signed by the student
 * @member {Boolean} contract_received
 */
ContractDetails.prototype['contract_received'] = undefined;






export default ContractDetails;

