
export const SET_CANDIDATE = 'SET_CANDIDATE';
export const SET_OFFERS = 'SET_OFFERS';
export const SET_OFFERS_COMPANY = 'SET_OFFERS_COMPANY';
export const SET_OFFER = 'SET_OFFER';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_STATES = 'SET_STATES';
export const SET_FILTER = 'SET_FILTER';
export const SET_ACADEMIC_PROGRAMS = 'SET_ACADEMIC_PROGRAMS';
export const SET_SCHEDULES = 'SET_SCHEDULES';
export const SET_CONTRACT_TYPES = 'SET_CONTRACT_TYPES';
export const SET_CANDIDATE_OFFERS_FILTER = 'SET_CANDIDATE_OFFERS_FILTER';
export const SET_CANDIDATE_OFFERS_COUNT = 'SET_CANDIDATE_OFFERS_COUNT';
