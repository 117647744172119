
import {h, Component} from "preact";
import {rndStr} from "../common";

export const Accordion = ({children, className})=><section class={'accordion ' + className}>{children}</section>;

export class AccordionElement extends Component {
    id = rndStr();
    render({title, children}) {
        return <div class="accordion-element">
            <input type="checkbox" id={this.id}/>
            <label for={this.id}>
                {title}
                <span className="accordion-indicator" />
            </label>
            <article>{children}</article>
        </div>;
    }
}