/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The FCTInOtherCity model module.
 * @module model/FCTInOtherCity
 * @version 1.0.0
 */
class FCTInOtherCity {
    /**
     * Constructs a new <code>FCTInOtherCity</code>.
     * @alias module:model/FCTInOtherCity
     */
    constructor() { 
        
        FCTInOtherCity.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>FCTInOtherCity</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/FCTInOtherCity} obj Optional instance to populate.
     * @return {module:model/FCTInOtherCity} The populated <code>FCTInOtherCity</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new FCTInOtherCity();

            if (data.hasOwnProperty('check')) {
                obj['check'] = ApiClient.convertToType(data['check'], 'Boolean');
            }
            if (data.hasOwnProperty('province_id')) {
                obj['province_id'] = ApiClient.convertToType(data['province_id'], 'Number');
            }
            if (data.hasOwnProperty('city_id')) {
                obj['city_id'] = ApiClient.convertToType(data['city_id'], 'Number');
            }
            if (data.hasOwnProperty('cp')) {
                obj['cp'] = ApiClient.convertToType(data['cp'], 'String');
            }
        }
        return obj;
    }


}

/**
 * Indica si la FCT se realiza en otra ciudad
 * @member {Boolean} check
 */
FCTInOtherCity.prototype['check'] = undefined;

/**
 * ID de la provincia
 * @member {Number} province_id
 */
FCTInOtherCity.prototype['province_id'] = undefined;

/**
 * ID de la ciudad
 * @member {Number} city_id
 */
FCTInOtherCity.prototype['city_id'] = undefined;

/**
 * Codigo postal
 * @member {String} cp
 */
FCTInOtherCity.prototype['cp'] = undefined;






export default FCTInOtherCity;

