import React, {Fragment, h} from "preact";
import {Link} from "react-router-dom";
import {t} from '../../_i18n';
import {Card, E, FR, R} from "../../_common/layout";
import {ModalLink} from "../../_common/components/modal";
import {isMobileAgent, rndStr} from "../../_common/common";
import {ModalEnroll} from "./modal_enroll";
import OfferBadgesContainer from "../../_common/components/offerBadgesContainer/OfferBadgesContainer";
import Badge from "../../_common/components/Badge";

export const OfferRequirements = ({offer, academic_programs, candidate, canApply, onEnroll, modalId})=> {
    const benefits = offer.benefits ? JSON.parse(offer.benefits) : null;
    const benefitsText = offer.benefits_text ? offer.benefits_text.split(',') : null;
    const academicProgramsArray = offer.academic_programs.map(id => academic_programs[id])
    const isMobile = isMobileAgent();


    return <Card smOnly={false}>
        <div className="flexrow flex-center-x flex-center-y">
            <div className="col-100 sm-col-40 md-col-20">
                <h2>{t('Titulación')}</h2>
            </div>
            <div className="col-100 sm-col-60 md-col-80">
               <OfferBadgesContainer academicProgramList={academicProgramsArray} isMobile={isMobile} numberMax={100}/>
            </div>
        </div>
        <hr className="separator-xxs"/>
        <FR className='sm-no-flex-column flex-column'>
            <E className='sm-col-20 col-100 p-left sm-no-p-left'>
                <h2 className='font-medium text-default'>Requisitos</h2>
            </E>
            <E className='sm-col-80 col-100 m-top preserve-lines p-left sm-no-p-left'>
                <div className='editor-class-text' dangerouslySetInnerHTML={{ __html: offer.requirements }} />
            </E>
        </FR>
        {/*<div className="flexrow flex-center-x">*/}
        {/*    <div className="col-100 sm-col-40 md-col-20">*/}
        {/*        <h2>{t('Requisitos')}</h2>*/}
        {/*    </div>*/}
        {/*    <div className="col-100 sm-col-60 md-col-80" dangerouslySetInnerHTML={{__html:offer.requirements}} />*/}
        {/*</div>*/}
        <hr className="separator-xxs"/>
        <FR className='sm-no-flex-column flex-column'>
            <E className='sm-col-20 col-100 p-left sm-no-p-left'>
                <h2 className='font-medium text-default'>Descripción</h2>
            </E>
            <E className='sm-col-80 col-100 m-top preserve-lines p-left sm-no-p-left'>
                <div className='editor-class-text' dangerouslySetInnerHTML={{ __html: offer.text }} />
            </E>
        </FR>
        {/*<div className="flexrow flex-center-x">*/}
        {/*    <div className="col-100 sm-col-40 md-col-20">*/}
        {/*        <h2>{t('Descripción')}</h2>*/}
        {/*    </div>*/}
        {/*    <div className="col-100 sm-col-60 md-col-80"  dangerouslySetInnerHTML={{__html:offer.text}} />*/}
        {/*</div>*/}
        {(benefits?.length > 0 || benefitsText?.length > 0) && <Fragment>
            <hr className="separator-xxs"/>
            <div className="flexrow flex-center-x">
                <div className="col-100 sm-col-40 md-col-20">
                    <h2>{t('Beneficios')}</h2>
                </div>
                <div className="col-100 sm-col-60 md-col-80">
                    {benefits.map(item=><Badge type='info' className='light m-r m-bottom-half'>{item}</Badge>)}
                    {benefitsText?.map(benefit => <Badge type='info' className='light m-r m-bottom-half'>{benefit}</Badge>)}

                </div>
            </div>
        </Fragment>}
        {/*<pre>{JSON.stringify(offer.academic_matching, null, '  ')}</pre>*/}
        {!offer.events.length ?
            <Fragment>
                <hr className="separator-xxs d-none sm-d-block"/>
                <R className="flex-right d-none sm-d-flex full-width no-margin-left">
                    {offer.academic_matching && candidate.has_filled_cv ?
                        <Fragment>
                            <ModalLink id={modalId}><button className={'btn btn-primary'}>{t('Inscribirme en esta oferta')}</button></ModalLink>
                        </Fragment>
                        :
                        <button className={'btn btn-primary'} type="button" disabled={true}>{t('Inscribirme en esta oferta')}</button>
                    }
                </R>
            </Fragment>
        : ''}
    </Card>;
}

