import {
    SET_EXAM_SELECTED,
    SET_EXAMS_STATUS,
    SET_HISTORICAL_EXAMS,
    SET_LOADING_HISTORICAL_EXAMS,
    SET_CANCEL_ENROLL, SET_ENROLL_PACS
} from "../actions/constants";
import {createReducer} from "../../../../_common/common.ts";

const initialState = {
    examSelected: null,
    examsStatus: null,
    historicalExams: null,
    loadingHistorical: false,
    enrollToCancel: null,
    enrollPacs: null
}


export const enrollmentExams = createReducer(initialState, {
    [SET_EXAM_SELECTED]: (state, examSelected)=>{
        state.examSelected = examSelected;
        return state
    },
    [SET_EXAMS_STATUS]: (state, examsStatus)=>{
        state.examsStatus = examsStatus;
        return state
    },
    [SET_HISTORICAL_EXAMS]: (state, historicalExams)=>{
        state.historicalExams = historicalExams;
        return state
    },
    [SET_LOADING_HISTORICAL_EXAMS]: (state, loadingHistorical)=>{
        state.loadingHistorical = loadingHistorical;
        return state
    },
    [SET_CANCEL_ENROLL]: (state, enrollToCancel)=>{
        state.enrollToCancel = enrollToCancel;
        return state
    },
    [SET_ENROLL_PACS]: (state, enrollPacs)=>{
        state.enrollPacs = enrollPacs;
        return state
    }
});
