import {CandidateApi, FixturesApi} from "../_api/jobs/src";
import {asyncLazySingleton, getHttp} from "../_common/common";

export const candidateApi = new asyncLazySingleton(async ()=>{

    let result = await getHttp(micuentaenv.JOBS_API_basePath + '/cas/login', null, true);

    if(result === false){
        result = await getHttp(micuentaenv.SSO_BaseUrl + '/get_st?service='+ encodeURIComponent(window.origin), null, true);

        //no hay cookie gt del cas
        if(result.st == false) window.location.href = micuentaenv.SSO_BaseUrl + '/login?service='+ encodeURIComponent(window.origin)

        result = await getHttp(micuentaenv.JOBS_API_basePath + '/cas/login?ticket='+result.st, null, true);
        if(result.status !== 'OK') throw new Error('Jobs Login failed');
    }

    return new CandidateApi();

}) ;
export const fixtureApi = new FixturesApi();
