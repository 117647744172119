import { h } from "preact";
import './badge.scss'

interface Props {
    className?: string,
    children: JSX.Element | string | any,
    type?: 'line'
        | 'info'
        | 'warning'
        | 'danger'
        | 'default'
        | 'default-opacity'
        | 'notice'
        | 'double-green'
        | 'success'
        | 'proceso'
        | 'menu'
        | 'dark-red'
        | 'dark-gray'
        | 'dark-green'
        | string;
}

export default function Badge ({type , className = '', children}: Props){

    return (
        <div className={`badge ${type ? `badge-${type}` : ''} ${className}`}>{children}</div>
    )
}
