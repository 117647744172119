import React, {h} from "preact";
import TextBox from "../../../../_common/components/textBox/textBox";
import Dropdown from "../../../../_common/components/dropdown";
import {t} from '../../../../_i18n';
import './historical-classes-card.scss'
import Badge from '../../../../_common/components/Badge';
import {DateTime} from "luxon";
import {ButtonGhost} from "../../../../_common/components/buttons/buttons";

export default function HistoricalClassesCard({
                                                  event,
                                                  isNextClasses,
                                                  isMultipleAcademicProgram,
                                                  handleCancel,
                                                  handleModify,
                                                  handleShowGrades,
                                                  loading,
                                                  session
                                              }) {

    const dateLimit = event.endDateJoin ? DateTime.fromFormat(event.endDateJoin, 'yyyy-MM-dd HH:mm:ss') : DateTime.now();
    const currentTime = DateTime.now();

    return (
        <div className='card m-top'>
            <div
                className={`p-x2-all historical-classes-card__container ${isNextClasses ? "d-flex" : "sm-d-flex sm-no-reverse"}`}>
                {loading && <div className='historical-classes-card__loading'/>}

                <div className='sm-full-width'>
                    <div className='t-upper font-color-879CA1 font-14'>{event.formative_action_code}</div>
                    <div className='font-18 font-medium'>{event.formative_action_title}</div>
                    <div className='font-14'>Sesión {session.session_number} de {event.dateSelected.session_total}</div>
                    <div className='m-top d-flex flex-column sm-no-flex-column'>
                        <TextBox className='full-width sm-auto-width'>
                            <div className='p-all-half d-flex flex-baseline-y'>
                                <i className="fal fa-calendar-alt m-l-half font-color-879CA1"/>
                                <div className='m-l-half'>{session.formatted_day}</div>
                            </div>
                        </TextBox>
                        <TextBox className='full-width sm-auto-width sm-m-l-half m-top-half sm-no-m-top'>
                            <div className='p-all-half d-flex flex-baseline-y'>
                                <i className="fal fa-clock  m-l-half font-color-879CA1"/>
                                <div className='m-l-half'>{session.start_date_hour} -{session.end_date_hour} </div>
                            </div>
                        </TextBox>
                    </div>
                    <div className='m-x2-top font-16 font-color-486268'>
                        <div className='d-block sm-d-none'>
                            <div className='font-medium'>{event.buildingSelected.building_name}</div>
                        </div>
                        <div className='sm-d-block d-none'>
                            <div className='font-medium'>{event.buildingSelected.building_name}</div>
                        </div>
                        <div className='m-top-half d-flex flex-space-between flex-column sm-no-flex-column'>
                            <span>{event.buildingSelected.address_name}</span>
                            {isMultipleAcademicProgram &&
                                <Badge className='font-light m-top-half sm-no-m-top'>{event.academic_program}</Badge>}
                        </div>
                    </div>
                </div>
                {isNextClasses ?
                    <div>
                        {(currentTime < dateLimit) &&
                            <Dropdown icon='fas fa-ellipsis-v font-color-486268' position='left' justIcon>
                                <div className='dropdown-item'
                                     onClick={() => handleModify(event)}>{t('Modificar inscripción')}</div>
                                <div className='dropdown-item' onClick={() => handleCancel(event)}>{t('Cancelar')}</div>
                            </Dropdown>}
                    </div>
                    :
                    <div className='m-top sm-fit-width'>
                        <ButtonGhost className='xs-full-width xxs-full-width sm-full-width nowrap'
                                     onClick={() => handleShowGrades(session)}>{t('VER DETALLE')}</ButtonGhost>
                    </div>
                }
            </div>
        </div>
    )
}
