import React, {h} from "preact";
import {connect} from "react-redux";
import {Card} from "../../_common/layout";
import {t} from "../../_i18n";

// const HOODIE_GIFT = 'Sudadera'
// const PREMIUM_GIFT = 'PremiumDto'

function DoneComponent({inviteFriendStatus}) {

    return (
        <Card className='animation-appear'>
            <div className='m-top m-x2-bottom'>
                {t('Ya has invitado a un amig@')}. <span className='font-black'>¡{t('Muchas gracias')}!</span>
                {/*<hr className='separator-xs'/>*/}
                {/*{inviteFriendStatus.selected_gift === HOODIE_GIFT && <HoodieGiftDone />}*/}
                {/*{inviteFriendStatus.selected_gift === PREMIUM_GIFT && <DiscountGiftDone />}*/}
            </div>
        </Card>
    )
}


DoneComponent = connect(
    ({inviteFriendStatus}) => ({inviteFriendStatus: inviteFriendStatus.status}),
    {})(DoneComponent);


export default ({...rest}) => <DoneComponent {...rest}/>
