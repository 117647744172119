import React, {Component, createRef, Fragment, h} from "preact";
import {Field, Form as FinalForm, FormSpy} from 'react-final-form'
import {
    FormField,
    FormFieldRadioCheckbox,
    FormFieldSelect,
    RadioCheckbox,
    SubmitButtonRow
} from "../../_common/forms/components/components";
import {Card, CF, E, FR, R, RE} from "../../_common/layout";
import {connect} from "react-redux";
import {
    fetchAcademicProgramDocument,
    fetchFixture,
    fetchStudentCourseAccess,
    saveCourseAccess, uploadAcademicProgramDocument,
    uploadDocument
} from "../../_rx/student/actions";
import {AppPage} from "../../_common/components/app-page";
import {t} from '../../_i18n';
import {flat, isImageFile, resizeImage} from "../../_common/common.ts";
import {AddImage} from "../../_common/components/add-image";
import {Prompt, Redirect} from "react-router";
import {Modal, ModalLink} from "../../_common/components/modal.tsx";
import {CfgmRequirements} from "../components/cfgm-requirements";
import {CfgsRequirements} from "../components/cfgs-requirements";
import {EsRequirements} from "../components/es-requirements";
import {TechnicalRequirements} from "../components/it-technical-requirements";
import {ItFrRequirements} from "../components/it-requirements";
import {
    MAX_IMAGE_PIXELS, DOC_NOTAS, DOC_RESGUARDO, DOC_RESGUARDO_Y_NOTAS, DOC_TITULO, PS_SHOPID_ES, PS_SHOPID_FR
} from "../../constants";

import "./requirements.scss";
import AlertBox from "../../_common/components/AlertBox/AlertBox";
import getLangByShopId from "../../_common/utils/getLang";

let CourseAccessForm = class extends Component {

    async componentDidMount() {

        const {shop_id, has_academic_program_document_types} = this.props.status.student_meta;

        if(!this.props.fixtures)
            this.props.fetchFixture(getLangByShopId(shop_id));

        this.props.fetchStudentCourseAccess(this.props.auth.loggedUser.id);

    }

    imageProps(id, subdoc){
        if(!id) return;
        id = parseInt(id);
        let dt = this.props.fixtures.document_type.find(d=>d.id == 7);
        dt = dt.children.find(d=> d.id == id);
        if(!dt) return;
        dt = dt.children.length ? dt.children[subdoc] : dt;

        const file =this.props.course_access.files.find(f=>f.type.id == dt.id);

        return {
            image: this.props.course_access.urls[dt.id],
            imageName: file && file.name,
            title: t('Añadir foto imagen') + dt.name,
            uploadCb: img=>this.upload(img, dt.id)
        }
    }

    imagePropsAcademicProgram(id){

        const doc = this.props.academic_program_document.documents &&
            this.props.academic_program_document.documents.find(doc=>doc.type.id==id);

        return {
            image: null,
            imageName: doc && doc.name,
            title: titles[id],
            uploadCb: img=>this.uploadAcademicProgramDoc(img, id)
        }
    }

    docErrors(ca, id){
        const file = ca.files.find(f=>f.type.id==id);
        if(!file) return [];
        let messages = file.messages;
        if(!messages) return [];
        messages = messages.map(m=>m.related_messages);
        messages = flat(messages);
        messages = messages.map(m=>m.description);
        return messages;
    }


    async upload(img, docId){
        if(isImageFile(img)) img = await resizeImage(img, MAX_IMAGE_PIXELS);
        await this.props.uploadDocument(this.props.auth.loggedUser.id, img, docId);
    }

    onSubmit(data){
       return this.props.saveCourseAccess(data).catch(err=> ({'error': true}));
    }

    render({ fixtures, course_access, status,academic_program_document, history}) {
        return fixtures && course_access && // hasta que no haya fixtures y el course_access no se muestra el form
            <CF className="max-width-sm"><FinalForm onSubmit={data=>this.onSubmit(data)} initialValues={course_access} render={
                ({handleSubmit, pristine, reset, submitting, errors, submitSucceeded, values})=>
                    <form className='form' action="" onSubmit={handleSubmit} noValidate >
                        {submitSucceeded && <Redirect to="/student" />}

                        <Card className="p-bottom">

                            <FR className="flex-center-y">
                                <div class="col-100 sm-col-60">
                                    <FormFieldSelect name="type.id" label={t('Titulación acceso')} required={true} options={fixtures.course_access_type} />
                                </div>
                                <div className="col-100 sm-col-40 sm-p-top">
                                    <Modal id="access-info">
                                        <E className="no-margin-bottom sm-p-x2-top">
                                            {status.student_meta.shop_id == PS_SHOPID_ES
                                                ? (course_access.level == 2
                                                    ? <CfgmRequirements id="gradoMedio"/>
                                                    : <CfgsRequirements id="gradoSuperior"/>)
                                                : <ItFrRequirements/>
                                            }
                                            <div className="access-requirements full-width-row">
                                                <div className="max-width-sm margin-auto">
                                                    {status.student_meta.shop_id == PS_SHOPID_ES
                                                        ? <EsRequirements/>
                                                        : <TechnicalRequirements/>
                                                    }
                                                </div>
                                            </div>
                                        </E>
                                    </Modal>

                                    <ModalLink id="access-info" className="t-underline"><small className="modal-link">{t('consulta requisitos acceso')}</small></ModalLink>

                                </div>
                            </FR>
                            <FR className="flex-center-y row-3-el sm-row-6-el">
                                <E><FormField name="grade" label={t('Nota final')} type="number" min="0" max="10" step="any" required={true} /></E>
                                <E className="p-top">
                                    <h2 className="d-flex tooltip-question-mark">
                                        <span className="tooltip-text">
                                            <i className="fal fa-question-circle"> </i>
                                            <span className="tooltip-content top default shadow-4">{t('No nota final')}</span>
                                        </span>
                                    </h2>
                                </E>
                            </FR>
                            <hr className='separator-xxs'/>

                            { status.student_meta.shop_id === PS_SHOPID_FR && <p dangerouslySetInnerHTML={{__html: t('course_access_france')}}/>}

                            {status.student_meta.shop_id === PS_SHOPID_ES
                                ? <Fragment>
                                    <h2>{t('Título pregunta')}</h2>
                                    <div className="col-100 md-col-50">
                                        <FormFieldRadioCheckbox type="radio" className="fit-content" name="document_type.id" label={t('Tiene título')} value={''+DOC_TITULO} required={true} />
                                        <FormFieldRadioCheckbox type="radio" className="fit-content" name="document_type.id" label={t('No tiene título')} value={''+DOC_RESGUARDO_Y_NOTAS} required={true} />
                                    </div>
                                </Fragment>
                                : <Field name="document_type.id" component="input" initialValue={DOC_TITULO} type="hidden" />
                            }

                            {values.document_type?.id == DOC_TITULO && <Fragment>
                                {/*<RE><p className="spaced-p" innerHTML={t('<strong>Añade resguardo pago</strong>')}/></RE>*/}

                                <R className="row-1-el md-row-2-el">
                                    <E>
                                        <h2>{t('Subir foto titulo')}</h2>
                                        <AddImage
                                            className="img-responsive"
                                            {...this.imageProps(DOC_TITULO, 0)} />
                                    </E>
                                    <E>
                                        {this.docErrors(course_access, values.document_type.id).map(msg=>
                                            <AlertBox type='danger'>{msg}</AlertBox>)}
                                    </E>
                                </R>
                            </Fragment>}

                            {values.document_type && values.document_type.id == DOC_RESGUARDO_Y_NOTAS && <Fragment>

                                <RE>
                                    {this.docErrors(course_access, DOC_RESGUARDO).map(msg=>
                                        <AlertBox type='danger'>{msg}</AlertBox>)}
                                    {this.docErrors(course_access, DOC_NOTAS).map(msg=>
                                        <AlertBox type='danger' >{msg}</AlertBox>)}
                                </RE>

                                <RE><p className="spaced-p" innerHTML={t('<strong>Añade resguardo pago</strong>')}/></RE>

                                <R className="row-1-el md-row-3-el">
                                    <E>
                                        <h2>{t('Resguardo pago tasas')}</h2>
                                        <AddImage
                                            className="img-responsive"
                                            {...this.imageProps(DOC_RESGUARDO_Y_NOTAS, 0)} />
                                    </E>
                                    <E>
                                        <h2>{t('Certificado de notas')}</h2>
                                        <AddImage
                                            className="img-responsive"
                                            {...this.imageProps(DOC_RESGUARDO_Y_NOTAS, 1)} />
                                    </E>
                                </R>

                            </Fragment>}

                            <div className="p-top m-bottom">
                                <div dangerouslySetInnerHTML={{__html:t('Puedes subir PDF')}} />
                                <AlertBox>
                                    <div dangerouslySetInnerHTML={{__html: t('Aviso compulsa')}}/>
                                </AlertBox>
                            </div>

                        </Card>

                        <Prompt
                            message={location=> document.location.pathname != location.pathname && t('Seguro pregunta')}
                            when={!pristine && !submitSucceeded}/>

                        <SubmitButtonRow disabled={submitting} onCancel={()=>history.push('/student')}/>
                    </form>}
            /></CF>;

    }
};

CourseAccessForm = connect(
    ({auth, fixtures, course_access, status, academic_program_document})=>({auth, fixtures, course_access, status, academic_program_document}),
    {
        fetchFixture,
        fetchStudentCourseAccess,
        saveCourseAccess,
        uploadDocument,
        fetchAcademicProgramDocument,
        uploadAcademicProgramDocument
    })(CourseAccessForm);


const CourseAccessPage = ({history}) =>
    <AppPage
        name="Course-access"
        title={t('Información de acceso al ciclo')}
        back="/student"
        className="max-width-sm"
    >
        <CourseAccessForm history={history}/>
    </AppPage>
;

export default CourseAccessPage;
