import React, {Component, Fragment, h} from "preact";
import {FormField, FormFieldPoblacion, FormFieldSelect} from "../../_common/forms/components/components";
import {Card, CF, E, FR, R} from "../../_common/layout";
import {Prompt, Redirect} from "react-router";
import {connect} from "react-redux";
import {fetchDireccionEnvio, saveDireccionEnvio} from "../../_rx/direccion-envio/actions";
import {fetchPoblaciones, fetchPaises} from "../../_rx/localizaciones/actions";
import {t} from '../../_i18n';
import {Form} from "react-final-form";
import {
    COUNTRY_PS_ID_ES,
    SHOP_PS_COUNTRY_MAPPING,
    ZIP_PATTERNS
} from "../../constants";
import {treatCountriesList} from "../../_common/common.ts";
import {fetchFixture} from "../../_rx/student/actions";
import getLangByShopId from "../../_common/utils/getLang";


let ShippingAddressForm = class extends Component {
    componentDidMount() {
        this.props.fetchDireccionEnvio();
        if(!this.props.fixtures)
            this.props.fetchFixture(getLangByShopId(this.props.status.student_meta.shop_id));
        if(!this.props.poblaciones)
            this.props.fetchPoblaciones();
        if(!this.props.paises)
            this.props.fetchPaises();
    }

    onSubmit(data){
        return this.props.saveDireccionEnvio(data, this.props.studentId).catch(err=> ({'error': true}));
    }

    prepareRedirect(isCancel){
        const {location} = this.props;
        const url = location?.query?.urlToBack || '/'
        return isCancel ? this.props.history.push(url) : <Redirect to={url} />
    }

    render({direccion_envio, lista_poblaciones, fixtures, paises, status, history}) {
        return fixtures && direccion_envio && paises && <Form onSubmit={data=>this.onSubmit(data)}
          initialValues={direccion_envio}
          render={
              ({handleSubmit, pristine, reset, submitting, errors, submitSucceeded, values, dirtyFields})=>{
                  const zipPattern = ZIP_PATTERNS[values.address_country_name];

                  return <CF className="max-width m-top-header-xs-sm"><form className='form' action="" onSubmit={(handleSubmit)} noValidate>
                      {submitSucceeded && this.prepareRedirect()}
                      <Card className="p-bottom">
                          <FR className='flex-column'>
                              <h1 className='no-margin m-bottom'>{t('Actualizar dirección de envío')}</h1>
                              <div className="sm-col-70 col-100">
                                  <FormField name="address_name" label={t('Dirección')} required={true} pattern=".{5,}" />
                              </div>
                              <div className="sm-col-25 col-60">
                                  <FormField name="address_zip_code" label={t('Código postal')} required={true} type="text" pattern={zipPattern} />
                              </div>
                              <div className="sm-col-70 col-100">
                                  <FormFieldPoblacion map_keys_as_names={true} name="address_city_name" label={t('Población')} required={true} list={lista_poblaciones} pattern=".{2,}" country={values.address_country_name} es_country="6" />
                              </div>
                              <div className="sm-col-70 col-100">
                                  <FormFieldSelect
                                      name="address_province_name"
                                      label={t('Provincia')}
                                      required={true}
                                      options={fixtures.province.map((element, index) => ({
                                          key: element["id"],
                                          id: index,
                                          name: element["name"]
                                      }))}
                                  />
                              </div>
                              <div className="sm-col-70 col-100">
                                  <FormFieldSelect
                                      name="address_country_name"
                                      label={t('País')}
                                      required={true}
                                      options={treatCountriesList(paises, SHOP_PS_COUNTRY_MAPPING[status.student_meta.shop_id]||COUNTRY_PS_ID_ES)} />
                              </div>
                          </FR>

                          <Prompt message={t('Seguro pregunta')} when={!pristine && !submitSucceeded}/>
                          <FR className='flex-center-y'>
                              <button className={'btn btn-primary submit sm-auto-width t-upper'} disabled={submitting || !Object.keys(dirtyFields).length > 0}>{t('guardar cambios')}</button>
                              <div className='t-upper t-underline sm-d-block d-none m-x2-top m-x2-l text-primary cursor-pointer' onClick={() => this.prepareRedirect(true)}>{t('cancelar')}</div>
                          </FR>
                      </Card>
                  </form></CF>
              }
          }
            />;

    }
};

ShippingAddressForm = connect((state)=>({
    direccion_envio: state.direccion_envio,
    fixtures: state.fixtures,
    lista_poblaciones: state.poblaciones,
    paises: state.paises_presta,
    status: state.status,
    studentId: state.auth.loggedUser.id
}), {
    fetchDireccionEnvio,
    fetchFixture,
    fetchPoblaciones,
    fetchPaises,
    saveDireccionEnvio,

})(ShippingAddressForm);

export default ShippingAddressForm;
