
export default async ()=> {
    let cfg =  await (await fetch('/env_v1.json')).json();

    if(cfg.env) {
        const k = Object.keys(cfg.env).find(k => location.hostname.endsWith(k));
        if (!k) throw new Error('Dominio desconocido ' + location.hostname);
        cfg = {...cfg, ...cfg.env[k]};
    }

    window.micuentaenv = cfg;
    window.isMobileApp = navigator.userAgent.startsWith('WebView');
    window.isWebApp = !window.isMobileApp;

};
