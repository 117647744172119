import React, {Component, Fragment, h} from "preact";
import {Link} from "react-router-dom";
import {CF} from "../../_common/layout";
import {t} from '../../_i18n';



export const Tabs = ({candidate})=> {
    const path = document.location.pathname;



    return <div className="tabs-container">
        <div className="inline">
            <Link to="/jobs/offers" className={path.split('/')[2] === 'offers' ? 'selected' :''}>{t('Ofertas')}</Link>
        </div>
        {candidate.has_filled_cv ?
            <div className="inline">
                <Link to="/jobs/applications" className={path.split('/')[2] === 'applications' ? 'selected' : ''}>{t('Tus candidaturas')}</Link>
            </div> : ''
        }
        <div className="inline">
            <Link to="/jobs/candidate" className={path.endsWith('candidate')?'selected':''}>{t('CV')}</Link>
        </div>
    </div>;
}
