/**
 * Student Account
 * Student Account Api - Validation flow
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dmartinez@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The InlineObject2 model module.
 * @module model/InlineObject2
 * @version 1.0.0
 */
class InlineObject2 {
    /**
     * Constructs a new <code>InlineObject2</code>.
     * @alias module:model/InlineObject2
     */
    constructor() { 
        
        InlineObject2.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>InlineObject2</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/InlineObject2} obj Optional instance to populate.
     * @return {module:model/InlineObject2} The populated <code>InlineObject2</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InlineObject2();

            if (data.hasOwnProperty('student_id')) {
                obj['student_id'] = ApiClient.convertToType(data['student_id'], 'Number');
            }
            if (data.hasOwnProperty('image')) {
                obj['image'] = ApiClient.convertToType(data['image'], 'String');
            }
            if (data.hasOwnProperty('allow_use_images')) {
                obj['allow_use_images'] = ApiClient.convertToType(data['allow_use_images'], 'Boolean');
            }
            if (data.hasOwnProperty('refuse_commercial_info')) {
                obj['refuse_commercial_info'] = ApiClient.convertToType(data['refuse_commercial_info'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} student_id
 */
InlineObject2.prototype['student_id'] = undefined;

/**
 * @member {String} image
 */
InlineObject2.prototype['image'] = undefined;

/**
 * @member {Boolean} allow_use_images
 */
InlineObject2.prototype['allow_use_images'] = undefined;

/**
 * @member {Boolean} refuse_commercial_info
 */
InlineObject2.prototype['refuse_commercial_info'] = undefined;






export default InlineObject2;

