import { h } from "preact";
import SignAddress from '../SignAddress/SignAddress'
import AlertCollapse from '../../../_common/components/AlertCollapse/AlertCollapse'
import { t } from '../../../_i18n'


export default function AlertExemption100 ({ className }) {
  return (
    <AlertCollapse title={t('👋 Para tramitar tu exención del 100% tienes que enviarnos el certificado de tareas')} className={className}>
      <p>{t('Para que podamos tramitar tu exención, deberás remitir vía correo postal el certificado de tareas con firma y sello originales a la siguiente dirección:')}</p>
      <SignAddress className='m-top' />
    </AlertCollapse>
  )
}
