import {Component, Fragment, h} from "preact";
import './dropdownSelector.scss';
import {useEffect, useState} from "preact/hooks";

export default function DropdownSelector({ values, onChange, iconDropdownVisible, iconDropdownHidden }){
    const [value, setValue] = useState(values ? values[0] : { id: 0, name: ''});
    const [selectorView, setSelectorView] = useState(false);
    const iconSelected = selectorView ? iconDropdownVisible : iconDropdownHidden;

    useEffect(() => {
        function docClick() {
            selectorView && setSelectorView(false)
        }
        document.addEventListener("click", docClick);
        return () => {
            document.removeEventListener("click", docClick);
        };
    }, [selectorView]);

    const handleSelectorView = () => {
        setSelectorView(!selectorView)
    }

    const handleSelect = (index) => {
        setValue(values[index])
        onChange(values[index])
    }

    return (
        <div className='dropdownSelector-container'>
            <div className='dropdownSelector-header' onClick={handleSelectorView}>
                <span className='dropdownSelector-header__title'>{value.name}</span>
                <span className='dropdownSelector-header__icon'>{iconSelected}</span>
            </div>
            { selectorView && <div className='dropdownSelector-dropdown' >
                {values.map((valueItem, index) => <div key={valueItem.id} className='dropdownSelector-dropdown__item' onClick={() => handleSelect(index)}>{valueItem.name}</div>)}
            </div>}
        </div>
    )
}