import { h } from "preact";
import './input-schedule-styles.scss'
import { Field } from 'react-final-form'
import { ButtonPrimary } from '../../../_common/components/buttons/buttons'
import { useState } from 'preact/hooks'
import { FormField, InputField, showError } from '../../../_common/forms/components/components'
import { validationSchedule } from './validation'
import ModalSchedule from './ModalSchedule'
import { t } from '../../../_i18n'


export default function InputSchedule ({ name, onSaveDraft }) {
  const [showModal, setShowModal] = useState(false)

  return (
    <Field name={name} validate={validationSchedule}>
      {({input, meta})=> (
        <div className={showError(meta) ? 'error' : ''}>
          <div>
            {input.value.monday.value && input.value.monday.check && <div className='input-schedule-days'><span className='font-color-149BAB font-bold'>{t('Lunes')}</span> {input.value.monday.value}</div>}
            {input.value.tuesday.value && input.value.tuesday.check && <div className='input-schedule-days'><span className='font-color-149BAB font-bold'>{t('Martes')}</span> {input.value.tuesday.value}</div>}
            {input.value.wednesday.value && input.value.wednesday.check && <div className='input-schedule-days'><span className='font-color-149BAB font-bold'>{t('Miércoles')}</span> {input.value.wednesday.value}</div>}
            {input.value.thursday.value && input.value.thursday.check && <div className='input-schedule-days'><span className='font-color-149BAB font-bold'>{t('Jueves')}</span> {input.value.thursday.value}</div>}
            {input.value.friday.value && input.value.friday.check && <div className='input-schedule-days'><span className='font-color-149BAB font-bold'>{t('Viernes')}</span> {input.value.friday.value}</div>}
          </div>
          <div><ButtonPrimary type='button' onClick={() => setShowModal(true)}>{t('Indicar disponibilidad')}</ButtonPrimary></div>
          {showModal && <ModalSchedule visible={showModal} input={input} onClose={() => setShowModal(false)} onBlur={onSaveDraft} />}
          {showError(meta) && meta.error && <div className='input-schedule-error'>{t('Este campo es obligatorio')}</div> }
        </div>
      )}
    </Field>
  )
}
