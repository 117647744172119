import React, {h} from "preact";
import AlertBox from "../../_common/components/AlertBox/AlertBox";
import {connect} from "react-redux";
import {t} from '../../_i18n';

function GiftPendingComponent({inviteFriendStatus}) {
    // const [hoodieSize, setHoodieSize] = useState('')
    // const showHoodieGift = inviteFriendStatus.gifts.includes('Sudadera')
    // const showDiscountGift = inviteFriendStatus.gifts.includes('PremiumDto')
    // const idModalHoodie = rndStr()
    // const idModalDiscount= rndStr()
    // const idModalSizeTable= rndStr()
    const string_email = t('INVITE_FRIEND_GIFT_PENDING_EMAIL').replace('{EMAIL}', inviteFriendStatus?.email_friend)

    return (
        <div>
            <AlertBox type='info'>
                <span dangerouslySetInnerHTML={{__html: string_email}}/>
            </AlertBox>
            {/*<div className='m-x2-top font-black m-x2-bottom'>{t('Selecciona un beneficio')}:</div>*/}
            {/*{showHoodieGift && <HoodieGift handleSetSize={(size) => setHoodieSize(size)} idModalSubmit={idModalHoodie} idModalSizeTable={idModalSizeTable} inviteFriendStatus={inviteFriendStatus}/>}*/}
            {/*{showDiscountGift && <DiscountGift idModalSubmit={idModalDiscount}/>}*/}
            {/*<ModalHoodie id={idModalHoodie} hoodieSize={hoodieSize}/>*/}
            {/*<ModalDiscount id={idModalDiscount} />*/}
            {/*<ModalSizeTable id={idModalSizeTable}/>*/}
        </div>
    )
}


GiftPendingComponent = connect(
    ({inviteFriendStatus}) => ({inviteFriendStatus: inviteFriendStatus.status}),
    {})(GiftPendingComponent);


export default ({...rest}) => <GiftPendingComponent {...rest}/>
