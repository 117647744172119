import {compose, createStore, applyMiddleware} from "redux";
import thunkMiddleware from 'redux-thunk';
import loadingMiddleware from 'redux-loading-middleware';


import reducers from './reducers';

const initialState = {
    loading:{}
};

let _store;

let enhancers = applyMiddleware(loadingMiddleware, thunkMiddleware);

if(window.__REDUX_DEVTOOLS_EXTENSION__)
    enhancers = compose(enhancers, window.__REDUX_DEVTOOLS_EXTENSION__());


_store = createStore(reducers, initialState, enhancers);

export const store = _store;

