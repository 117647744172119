import {h, Component, Fragment, createRef} from "preact";

import './camera-shot.scss';

const states = {
    start: 0,
    connecting: 1,
    capturing: 2,
    confirm: 3,
    stopping: 4
}

const events = {
    connect: 0,
    connected: 1,
    capture: 2,
    confirm: 3,
    close: 4
}

export class CameraShot extends Component{

    videoRef = createRef();
    overlayRef = createRef();
    stillRef = createRef();
    stream = null;
    currentState = states.start;

    componentDidMount(){
        this.resize();
        window.addEventListener('resize', ()=>this.resize());
        this.setState(states.connecting);
    }

    componentWillUnmount() {
        this.setState(states.stopping);
    }

    event(event){
        switch (this.currentState) {
            case states.start:
                if(event === events.connect) {
                    this.hideCapture();
                    this.startVideo();
                    this.currentState = states.connecting;
                }
                break;
            case states.connecting:
                if(event === events.connected) {
                    this.currentState = states.capturing;
                }
                break;
            case states.capturing:
                if(event === events.confirm) {
                    this.stopVideo();
                    this.showCapture();
                    this.currentState = newState;
                }
                break;
            case states.confirm:
                if(event === events.stopping) {
                    this.hideCapture();
                    this.stopVideo();
                    this.currentState = newState;
                }
                else if(event === events.capturing) {
                    this.startVideo();
                    this.currentState = newState;
                }
                break;
            case states.stopping:
                if(event === events.confirm) {
                    this.stopVideo();
                    this.showCapture();
                    this.currentState = newState;
                }
        }
    }

    resize(){
        let w = this.overlayRef.current.clientWidth;
        let h = this.overlayRef.current.clientHeight;
        this.overlayRef.current.width = w;
        this.overlayRef.current.height = h;
        this.stillRef.current.width = w;
        this.stillRef.current.height = h;
        const cropWidth = this.props.hotareaWidth * w;
        const cropHeight = this.props.hotareaWidth / this.props.hotareaAspect * w;
        const cropLeft = (w - cropWidth) /2;
        const cropTop = (h - cropHeight) /2;

        const ctx = this.overlayRef.current.getContext('2d');
        ctx.fillStyle = 'rgba(0, 0, 0, .6)';
        ctx.clearRect(0, 0, w, h);
        ctx.fillRect(0, 0, w, h);
        ctx.clearRect(cropLeft, cropTop, cropWidth, cropHeight);
    }

    stopVideo(){
        if(!this.stream) return;
        this.videoRef.current.srcObject = null;
        this.stream.getTracks().forEach(t=>t.stop());
        this.stream = null;
    }

    async startVideo(){
        if(!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) return;
        try {
            this.stillRef.current.style.display = 'none';
            this.stream = await navigator.mediaDevices.getUserMedia({video: true});
            this.videoRef.current.srcObject = this.stream;
            this.videoRef.current.play();
            this.setState(states.capturing);
        }
        catch(err){
            console.log(err);
        }
    }



    showCapture(){
        this.stillRef.current.style.display = 'block';
        this.stillRef.current.getContext('2d').drawImage(
            this.videoRef.current,
            0, 0,
            this.stillRef.current.clientWidth,
            this.stillRef.current.clientHeight
        );
    }

    hideCapture(){
        this.stillRef.style.display = 'none';
    }

    repeat(){
        this.startVideo();
    }

    ok(){
        if(!this.stream) return;
        if(this.props.onOk)
            this.props.onOk(this.videoRef.current)
    }

    render(){
        return <div className="camera-shot">
            <video ref={this.videoRef}></video>
            <canvas ref={this.stillRef}></canvas>
            <canvas ref={this.overlayRef}></canvas>
            <button onClick={()=>this.capture()}><i className="fa fa-circle"></i></button>
            <button onClick={()=>this.repeat()}><i className="fa fa-times"></i><br/>Repetir</button>
            <button onClick={()=>this.ok()}><i className="fa fa-check"></i><br/>Aceptar</button>
        </div>
    }

}

CameraShot.defaultProps = {hotareaWidth:0.8, hotareaAspect:4/3};
