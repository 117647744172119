import {h, Fragment, Component} from "preact";

import {Link} from "react-router-dom";
import {t} from '../../_i18n';
import {CF, R, RE} from "../layout";

import './app-page.scss';


type Props = {
    children: any,
    name?: string,
    title?: string,
    subtitle?: string,
    back?:Function|string|{text:string,href:string}[]|null,
    showHeader?: boolean,
    className?: string
}

export const AppPage = class extends Component<Props, {}> {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    doBack(back:Function){
        return (e:any)=> { e.preventDefault(); back(); };
    }

    render({children, name, title, subtitle, back=null, showHeader = true, className}:Props){

        const match = /back=([^&]+)/.exec(location.search);
        back = match ? match[1] : back;

        return <section className={`app-page-${name}`}>
            {showHeader && <div className="app-page-header">
                <CF className={className}>
                    <nav className="breadcrumb-wrapper p-top">
                        {
                            typeof back === "string" ? <Link to={back} className="back breadcrumb-item">← {t('Volver')}</Link>:
                            typeof back === "function" ? <a href="#" className="back breadcrumb-item" onClick={this.doBack(back)}>← {t('Volver')}</a>:
                            Array.isArray(back) ? back.map((b,i)=><Fragment>{!!i&&'/'}<Link key={b.href} to={b.href} className="back breadcrumb-item">&nbsp;{b.text}&nbsp;</Link></Fragment>) :
                            null
                        }
                    </nav>
                    {title &&
                        <Fragment>
                            <h1 className="hero m-top m-bottom">{title}</h1>
                            {/*<hr className="separator-xxs d-none md-d-block"/>*/}
                        </Fragment>
                    }
                    {subtitle && <div className={"d-none md-d-block app-page-subtitle " + className}>{subtitle}</div>}
                </CF>
            </div>}

            {subtitle && <CF className={"app-page-subtitle md-d-none " + className}>{subtitle}</CF>}

            {children}

        </section>;
    }
};

