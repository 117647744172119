/**
 * LTI Enroll Exam
 * LTI Enroll Exam for Students
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';
import InlineResponse200 from '../model/InlineResponse200';
import InlineResponse400 from '../model/InlineResponse400';

/**
* GraceExamConvocations service.
* @module api/GraceExamConvocationsApi
* @version 2.0.0
*/
export default class GraceExamConvocationsApi {

    /**
    * Constructs a new GraceExamConvocationsApi. 
    * @alias module:api/GraceExamConvocationsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Upload Grace Convocation Documentation
     * @param {String} studentId 
     * @param {Object} opts Optional parameters
     * @param {File} opts.file Archivo a subir
     * @param {String} opts.documentTypeId ID del tipo de documento
     * @param {String} opts.formativeActionIds ID de asignaturas separadas por coma
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse200} and HTTP response
     */
    graceExamConvocationsControllerStoreWithHttpInfo(studentId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'studentId' is set
      if (studentId === undefined || studentId === null) {
        throw new Error("Missing the required parameter 'studentId' when calling graceExamConvocationsControllerStore");
      }

      let pathParams = {
        'student_id': studentId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': opts['file'],
        'document_type_id': opts['documentTypeId'],
        'formative_action_ids': opts['formativeActionIds']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json', 'aplication/json'];
      let returnType = InlineResponse200;
      return this.apiClient.callApi(
        '/api/v1/grace-exam-convocations/students/{student_id}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Upload Grace Convocation Documentation
     * @param {String} studentId 
     * @param {Object} opts Optional parameters
     * @param {File} opts.file Archivo a subir
     * @param {String} opts.documentTypeId ID del tipo de documento
     * @param {String} opts.formativeActionIds ID de asignaturas separadas por coma
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse200}
     */
    graceExamConvocationsControllerStore(studentId, opts) {
      return this.graceExamConvocationsControllerStoreWithHttpInfo(studentId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
