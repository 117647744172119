import {Component, h} from "preact";
import {Card, CF, E, FR, R} from "../../_common/layout";
import {connect, useSelector} from "react-redux";
import {fetchMatriculaDetalle} from "../../_rx/historial-matriculas/actions";
import {AppPage} from "../../_common/components/app-page";
import {t} from '../../_i18n';
import {formatCurrency, formatDDMMYYYY} from "../../_common/common.ts";
import AuthService from "../../_services/auth-service";
import Badge from "../../_common/components/Badge";
import AlertBox from "../../_common/components/AlertBox/AlertBox";

let MatriculaDetalle = class extends Component {

    componentDidMount() {
        const {match} = this.props;
        if (!match.params || !match.params.param) {
            throw new Error(t('No referencia'));
        }
        const ref = match.params.param
        this.props.fetchMatriculaDetalle(ref);
    }

    privateLink(url) {
        if (isMobileApp)
            url = AuthService.instance().generateAutologgedUrl(this.props.status.student_meta.shop_id, url);
        return url;
    }

    linkFactura(matricula_detalle) {
        if (matricula_detalle.LinkFactura.includes('/it/'))
            return matricula_detalle.LinkFactura.replace('/it/', '/');
        return matricula_detalle.LinkFactura;
    }

    render({matricula_detalle, history, status}) {

        return matricula_detalle &&
            <AppPage
                name="matricula-detalle"
                title={t(matricula_detalle.EsMatricula ? 'Detalle de matrícula' : 'Detalle de pedido')}
                back={() => history.goBack()}
                class="max-width">
                <CF class="max-width">
                    {["1", "17", "23", "24", "31", "44", "47", "16", "32"].includes(matricula_detalle.shop) ?
                        <Card smOnly={false} className="p-bottom">
                            <FR class="row-2-el xs-row-3-el md-row-5-el font-medium medium-weight">
                                <E>
                                    <small>{t('Fecha')}</small><br/>
                                    {formatDDMMYYYY(matricula_detalle.Fecha)}
                                </E>
                                <E>
                                    <small>{t('Matrícula')}</small><br/>
                                    {matricula_detalle.Referencia}
                                </E>
                                <E>
                                    <small>{t('Importe')}</small><br/>
                                    <span
                                        className="text-danger font-medium medium-weight">{formatCurrency(matricula_detalle.Importe)}</span>
                                </E>
                                <E>
                                    <small>{t('Método pago')} </small><br/>
                                    {matricula_detalle.MetodoPago}
                                </E>
                                <E>
                                    <small>{t('Estado')}</small><br/>
                                    <Badge type={matricula_detalle.EstadoBadge}>{matricula_detalle.Estado}</Badge>
                                </E>
                            </FR>
                        </Card>
                        :
                        <Card smOnly={false} className="p-bottom">
                            <FR class="row-2-el xs-row-3-el md-row-5-el font-medium medium-weight">
                                <E>
                                    <h1>{matricula_detalle.shop}</h1>
                                    <small>{t('Fecha')}</small><br/>
                                    {formatDDMMYYYY(matricula_detalle.Fecha)}
                                </E>
                            </FR>
                        </Card>
                    }
                    <br/>
                </CF>

                <CF class="max-width">
                    <Card smOnly={false}>
                        <FR>
                            <section className="accordion full-width">
                                <div className="accordion-element m-l-half m-r-half">
                                    <input type="checkbox" id="detalle-"/>
                                    <label htmlFor="detalle-"><h2
                                        className="order-detail-title">{t(matricula_detalle.EsMatricula ? 'Resumen asignaturas' : 'Resumen de productos')}</h2>
                                        <span className="accordion-indicator"></span></label>
                                    <hr className="separator-xxs"/>
                                    <article className="order-detail-products-list">
                                        {matricula_detalle.Asignaturas.map(a =>
                                            <R className="dashed-bottom">
                                                <p className="font-medium medium-weight col-75" key={a.Id}>
                                                    {a.ProductId == 970 ?
                                                        <i className="fas fa-star"></i> : a.ProductReference.includes('-L') ?
                                                            <i className="fas fa-book"></i> :
                                                            <i className="fas fa-book-reader"></i>}
                                                    <span>{a.Nombre.split(': ')[1] ? a.Nombre.split(': ')[1] : a.Nombre.split(': ')[0]}</span><br/>
                                                    <small>{a.Nombre.split(': ')[1] ? a.Nombre.split(': ')[0] : ''}</small>
                                                </p>
                                            </R>
                                        )}
                                    </article>
                                </div>
                            </section>
                        </FR>
                        <FR>
                            {matricula_detalle.Direcciones.map(d =>
                                <E key={d.id} className="order-detail-address m-top">
                                    <h2>{d.descripcion}</h2>
                                    <div className="font-medium medium-weight disabled-aspect-gray">
                                        {d.nombre} <br/> {d.cp} {d.poblacion}
                                    </div>
                                </E>
                            )}
                        </FR>
                    </Card>
                    {
                        !["1", "24", "44"].includes(matricula_detalle.shop) &&
                        <AlertBox className={"m-top"} type={"info"}>
                            {t("factura_presencial")}
                        </AlertBox>
                    }
                    {
                        matricula_detalle.LinkFactura != '' && ["1", "24", "44"].includes(matricula_detalle.shop) &&
                        <div className="t-right">
                            <a className={"btn btn-primary submit md-auto-width m-bottom white" + (matricula_detalle.EstadoKey == 'estado_pagado' ? "" : "disabled-link")}
                               href={this.privateLink(this.linkFactura(matricula_detalle))}>{t('Descargar factura')}</a>
                        </div>
                    }
                    {
                        matricula_detalle.LinkFacturaAbono != '' && ["1", "24", "44"].includes(matricula_detalle.shop) &&
                        <div className="t-right">
                            <a className={"btn btn-primary md-auto-width m-bottom white" + (matricula_detalle.EstadoKey == 'estado_pagado' ? "" : "disabled-link")}
                               href={this.privateLink(matricula_detalle.LinkFacturaAbono)}>{t('Descargar Factura de Abono')}</a>
                        </div>
                    }
                </CF>
            </AppPage>;
    }
};

MatriculaDetalle = connect(
    ({matricula_detalle, status}) => ({matricula_detalle, status}),
    {fetchMatriculaDetalle})(MatriculaDetalle);

export default MatriculaDetalle;
