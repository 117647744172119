/**
 * Student Account
 * Student Account Api - Validation flow
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dmartinez@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Country from './Country';

/**
 * The State model module.
 * @module model/State
 * @version 1.0.0
 */
class State {
    /**
     * Constructs a new <code>State</code>.
     * @alias module:model/State
     * @param id {Number} 
     * @param name {String} 
     * @param country {module:model/Country} 
     */
    constructor(id, name, country) { 
        
        State.initialize(this, id, name, country);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, name, country) { 
        obj['id'] = id;
        obj['name'] = name;
        obj['country'] = country;
    }

    /**
     * Constructs a <code>State</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/State} obj Optional instance to populate.
     * @return {module:model/State} The populated <code>State</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new State();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('country')) {
                obj['country'] = Country.constructFromObject(data['country']);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
State.prototype['id'] = undefined;

/**
 * @member {String} name
 */
State.prototype['name'] = undefined;

/**
 * @member {module:model/Country} country
 */
State.prototype['country'] = undefined;






export default State;

