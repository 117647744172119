import { h } from "preact";
import { formatIBAN } from "./validateIBAN";
import { showError } from "../../../_common/forms/components/components";
import { postDocumentsMetaDraft } from "../../../_rx/fct/actions";
import { useParams } from 'react-router';

const IBANField = ({ input, meta, label, specificError, values }) => {
    const { onBlur, onChange, onFocus } = input;
    const { id } = useParams();

    const handleChange = e => {
        const formattedIBAN = formatIBAN(e.target.value);
        onChange(formattedIBAN);
    };

    const handleBlur = async e => {
        onBlur(e);
        if (!meta.error) {
            try {
                await postDocumentsMetaDraft(id, values.meta);
            } catch (error) {
                console.error('Error al guardar el borrador:', error);
            }
        }
    };

    const handleFocus = e => {
        onFocus(e);
    };

    return (
        <div>
            <label>{label}</label>
            <input
                {...input}
                type="text"
                className={"form-control" + (showError(meta) ? " error" : "")}
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
            />
            {meta.error && meta.touched && (
                <span className="font-color-danger m-top">{specificError || meta.error}</span>
            )}
        </div>
    );
};

export default IBANField;
