import {globalCatch} from "../../../_services/global-catch";
import {academicProgramsApi, formativeActionsApi, subjectOffersApi} from "../../../_services/expedient-service";
import {SET_EDUCATION_LAW, SET_REVIEW_PARAMS} from "./constants";
import {EDUCATION_LAW_DIC} from '../../../expediente/expedientConstants'
import {toastService} from "../../../_services/toast-service";
import {t} from "../../../_i18n";
import {TOKEN_EXPEDIENT} from "../../../constants";
import {downloadFile} from '../../../utils'

export const fetchExpedientAcademicProgramsSelector = studentId => globalCatch.catch(async () => {
    const data = await (await academicProgramsApi()).studentExpedientControllerListAcademicPrograms(studentId);
    return data.map(academicProgram => ({name: academicProgram.title, id: academicProgram.id}))
});

export const fetchExpedientAcademicProgram = (studentId, academicProgramId) => globalCatch.catch(async (dispatch) => {
    const response = await (await academicProgramsApi()).studentExpedientControllerGetAcademicProgramExpedient(studentId, academicProgramId);
    dispatch(setEducationLaw(EDUCATION_LAW_DIC[response.education_law_id]))
    return response
});

export const fetchFormativeActionsChildren = (studentId, formativeActionId) => globalCatch.catch(async () => {
    return await (await formativeActionsApi()).studentExpedientControllerGetFormativeActionChildrenExpedient(studentId, formativeActionId);
});

export const fetchSubjectOffers = (studentId, formativeActionId, hiddenGrade = false) => globalCatch.catch(async () => {
    return await (await subjectOffersApi()).studentExpedientControllerGetSubjectOffersFromFormativeAction(studentId, formativeActionId, hiddenGrade);
});

export const fetchSubjectOfferDetails = (studentId, formativeActionId, subjectOfferId, hiddenGrade = false) => globalCatch.catch(async () => {
    return await (await subjectOffersApi()).studentExpedientControllerGetSubjectOfferExpedient(studentId, formativeActionId, subjectOfferId, hiddenGrade);
});

export const downloadReviewFile = (lang) => {
    downloadFile({
        url: `${micuentaenv.STUDENT_ACCOUNT_API_basePath}/exam-revisions/${lang}/download`,
        fileName: 'review_file',
        TOKEN: TOKEN_EXPEDIENT
    })
}

export const downloadCertificate = ({studentId, academicProgram, opt}) => {

    const certificate = academicProgram.education_law_id === 5 ? 'IL-011-ESO' : 'IL-011'

    return downloadFile({
        url: micuentaenv.STUDENT_ACCOUNT_API_basePath + '/secretary/certificates/student/' + studentId + '/certificate/' + certificate + '/academic-program/' + academicProgram.id + '/locale/' + opt + '/export',
        fileName: 'certificate_' + studentId,
        method: 'POST',
        TOKEN: TOKEN_EXPEDIENT
    })
};

export const downloadAttendance = ({studentId, opt}) => {
    return downloadFile({
        url: micuentaenv.STUDENT_ACCOUNT_API_basePath + '/lti/exams/students/' + studentId + '/exam-certificate?is_sis_id=true&locale=' + opt,
        fileName: 'Attendance_' + studentId,
        TOKEN: TOKEN_EXPEDIENT
    })
};


export const uploadReviewFile = (params) => globalCatch.catch(async () => {
    await fetch(micuentaenv.STUDENT_ACCOUNT_API_basePath + '/exam-revisions/send', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + TOKEN_EXPEDIENT,
        },
        body: params
    }).then(
        response => response.json()
    ).then(
        (data) => {
            !data.errors
                ? toastService.showToast({message: t("Solicitud de revisión enviada correctamente"), type: 'info'})
                : toastService.showToast({message: t(data.errors.file), type: 'danger'})
        }
    ).catch(
        error => toastService.showToast({message: t(error), type: 'info'})
    );
});


export const setEducationLaw = educationLaw => ({type: SET_EDUCATION_LAW, payload: {educationLaw: educationLaw}});
export const setReviewParams = objetParams => ({type: SET_REVIEW_PARAMS, payload: {reviewParams: objetParams}});


