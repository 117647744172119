import {Component, Fragment, h} from "preact";
import {Link} from "react-router-dom";
import {t} from '../../_i18n';
import {R, FR, Card} from "../../_common/layout";
import {rndStr} from "../../_common/common";

export class CV extends Component {

    id = rndStr();

    state = {loading: false};

    async fileChanged(e) {
        try {
            this.setState({loading: true});
            await this.props.saveDocument(e.target.files[0]);
        } finally {
            this.setState({loading: false});
        }
    }

    render({candidate, deleteDocument}) {

        return <Fragment>
        <Card smOnly={false}>
            <input id={this.id} type="file" accept="application/pdf" className="d-none" value={''} onChange={e => {this.fileChanged(e);}}/>
            {/*<pre>{JSON.stringify(candidate, null, '  ')}</pre>*/}
            {candidate.document ?
                <Fragment>
                    <FR className="flex-center-y">
                        <div className="sm-col-50">
                            <h1>{t('Tu CV')}</h1>
                        </div>
                        <div className="sm-col-50 d-none sm-d-block">
                            <div className="flexrow flex-right flex-center-y">
                                <div>
                                    <label className={'btn btn-ghost'} onClick={()=> confirm(t('¿Seguro que deseas eliminarlo?')) && deleteDocument()}>{t('Eliminar')}</label>
                                </div>
                                <div>
                                    <label htmlFor={this.id} className="btn btn-ghost sm-m-l">{t('Actualizar')}</label>
                                </div>
                            </div>
                        </div>
                    </FR>

                    <FR className="flex-center-x flex-center-y">
                        <div className="col-100 sm-col-60">
                            <a href={`${window.micuentaenv.JOBS_API_basePath}/candidate/document`} target="_blank">{candidate.document['file_name']}</a>
                        </div>
                        <div className="d-flex sm-d-none flex-column full-width">
                            <div className="col-100">
                                <button className={'btn btn-ghost'} onClick={()=> confirm(t('¿Seguro que deseas eliminarlo?')) && deleteDocument()}>{t('Eliminar')}</button>
                            </div>
                            <div className="col-100 m-bottom">
                                <label htmlFor={this.id} className="btn btn-ghost">{t('Actualizar')}</label>
                            </div>
                        </div>
                        <div className="sm-col-40 flex-right d-flex flex-left sm-flex-right full-width">
                            <span className="font-small">{t('Archivos de 5MB máximo en formato PDF')}</span>
                        </div>
                    </FR>

                </Fragment>
                :
                <Fragment>
                    <FR>
                        <div className="col-100 sm-col-100">
                            <h1 className="no-margin">{t('Tu CV')}</h1>
                        </div>
                        <div className="col-100 sm-col-100">
                            <p className="m-bottom-half m-top-half">{t('Adjunta el CV con tu experiencia y estudios.')}</p>
                        </div>
                        <div className="col-100 sm-col-100">
                            <label htmlFor={this.id} className="btn btn-primary">{t('Subir CV')}</label>
                        </div>
                        <div className="col-100 sm-col-100">
                            <p className="font-small m-bottom-half m-top-half">{t('Archivos de 5MB máximo en formato PDF')}</p>
                        </div>
                    </FR>
                </Fragment>
            }
        </Card>
        <div className="p-bottom" />
        </Fragment>

    }
}
