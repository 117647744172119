import {
    SET_ACADEMIC_PROGRAMS,
    SET_CANDIDATE, SET_CANDIDATE_OFFERS_COUNT,
    SET_CANDIDATE_OFFERS_FILTER,
    SET_COMPANY,
    SET_CONTRACT_TYPES,
    SET_OFFER,
    SET_OFFERS,
    SET_OFFERS_COMPANY,
    SET_SCHEDULES,
    SET_STATES,
    SET_FILTER
} from "../actions/constants";
import {createReducer} from "../../../_common/common.ts";

export const candidate = createReducer(null, {
    [SET_CANDIDATE]: (state, student)=> student,
});

export const jobFilter = createReducer({
    modality: {},
    academicPrograms: {},
    states: {},
    presenceType: {},
}, {
    [SET_FILTER]: (state, filter)=> filter,
});

export const offers = createReducer(null, {
    [SET_OFFERS]: (state, offers)=> offers,
});

export const offersCompany = createReducer(null, {
    [SET_OFFERS_COMPANY]: (state, offers)=> offers,
});

export const offer = createReducer(null, {
    [SET_OFFER]: (state, offer)=> offer,
});

export const company = createReducer(null, {
    [SET_COMPANY]: (state, company)=> company,
});

export const offersFilterReducer = createReducer(null, {
   [SET_CANDIDATE_OFFERS_FILTER]: (state, offers) => offers,
});

export const offersCountReducer = createReducer(null, {
    [SET_CANDIDATE_OFFERS_COUNT]: (state, offers) => offers,
});

const initialFixtures = {
    states: null,
    academic_programs: null,
    schedules: null,
    contract_types: null
};

export const fixturesReducer = createReducer(initialFixtures, {
    [SET_STATES]: (state, {dic,kn})=>{
        state.states = dic;
        state.states_kn = kn;
        return state;
    },
    [SET_ACADEMIC_PROGRAMS]: (state, {dic,kn})=>{
        state.academic_programs = dic;
        state.academic_programs_kn = kn;
        return state;
    },
    [SET_SCHEDULES]: (state, {dic,kn})=>{
        state.schedules = dic;
        state.schedules_kn = kn;
        return state;
    },
    [SET_CONTRACT_TYPES]: (state, {dic,kn})=>{
        state.contract_types = dic;
        state.contract_types_kn = kn;
        return state;
    },
});
