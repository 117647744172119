import React, { h } from "preact";
import {Card} from "../../_common/layout";
import {t} from "../../_i18n";
import {Section, TextLabel} from "./components";
import {ButtonGhost} from "../../_common/components/buttons/buttons";
import {Link} from "react-router-dom";

export default function AccessData({email, changePasswordLink}){

    return (
        <div className='p-left-half sm-no-p-left p-right-half sm-no-p-right'>
            <Card className='m-top sm-p-x2-bottom'>
                <div className='font-24 font-medium m-top'>{t('Datos de acceso')}</div>
                <Section className='m-top'>
                    <TextLabel label={t('Correo electrónico')} value={email}/>
                </Section>
                <hr className="separator-xxs"/>
                <Section>
                    <TextLabel label={t('Contraseña')} value='************'/>
                    <Link to={changePasswordLink}><ButtonGhost className='m-top sm-no-m-top full-width sm-no-full-width'>{t('Cambiar')}</ButtonGhost></Link>
                </Section>
            </Card>
        </div>
    )
}