
class GlobalCatch{

    _globalCatcher = null;

    setGlobalCatch(catcher){
        this._globalCatcher = catcher;
    }

    catch(action){
        // console.log('GlobalCatch.catch a')
        return (...args)=> action(...args).catch(err=>{
            // console.log('GlobalCatch.catch', err)
            this._globalCatcher && this._globalCatcher(err);
            throw err;
        });
    }

}


export const globalCatch = new GlobalCatch();
