/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import InlineResponse404 from '../model/InlineResponse404';
import Segment from '../model/Segment';

/**
* NoticeSegments service.
* @module api/NoticeSegmentsApi
* @version 1.0
*/
export default class NoticeSegmentsApi {

    /**
    * Constructs a new NoticeSegmentsApi. 
    * @alias module:api/NoticeSegmentsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Add a segment to the notice
     * @param {Number} id id of the notice
     * @param {Number} idSegment id of the segment
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse404} and HTTP response
     */
    appHttpControllersNoticeControllerAddNoticeSegmentWithHttpInfo(id, idSegment) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersNoticeControllerAddNoticeSegment");
      }
      // verify the required parameter 'idSegment' is set
      if (idSegment === undefined || idSegment === null) {
        throw new Error("Missing the required parameter 'idSegment' when calling appHttpControllersNoticeControllerAddNoticeSegment");
      }

      let pathParams = {
        'id': id,
        'id_segment': idSegment
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse404;
      return this.apiClient.callApi(
        '/api/v1/notice_segments/{id}/{id_segment}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Add a segment to the notice
     * @param {Number} id id of the notice
     * @param {Number} idSegment id of the segment
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse404}
     */
    appHttpControllersNoticeControllerAddNoticeSegment(id, idSegment) {
      return this.appHttpControllersNoticeControllerAddNoticeSegmentWithHttpInfo(id, idSegment)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List all the segments asociated to the notice
     * @param {Number} id id of the notice
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Segment>} and HTTP response
     */
    appHttpControllersNoticeControllerGetNoticeSegmentsWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersNoticeControllerGetNoticeSegments");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Segment];
      return this.apiClient.callApi(
        '/api/v1/notice_segments/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List all the segments asociated to the notice
     * @param {Number} id id of the notice
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Segment>}
     */
    appHttpControllersNoticeControllerGetNoticeSegments(id) {
      return this.appHttpControllersNoticeControllerGetNoticeSegmentsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove a segment from a notice
     * @param {Number} id id of the notice
     * @param {Number} idSegment id of the segment
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse404} and HTTP response
     */
    appHttpControllersNoticeControllerRemoveNoticeSegmentWithHttpInfo(id, idSegment) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersNoticeControllerRemoveNoticeSegment");
      }
      // verify the required parameter 'idSegment' is set
      if (idSegment === undefined || idSegment === null) {
        throw new Error("Missing the required parameter 'idSegment' when calling appHttpControllersNoticeControllerRemoveNoticeSegment");
      }

      let pathParams = {
        'id': id,
        'id_segment': idSegment
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse404;
      return this.apiClient.callApi(
        '/api/v1/notice_segments/{id}/{id_segment}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Remove a segment from a notice
     * @param {Number} id id of the notice
     * @param {Number} idSegment id of the segment
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse404}
     */
    appHttpControllersNoticeControllerRemoveNoticeSegment(id, idSegment) {
      return this.appHttpControllersNoticeControllerRemoveNoticeSegmentWithHttpInfo(id, idSegment)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
