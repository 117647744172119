/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import InlineResponse2007 from '../model/InlineResponse2007';
import NoticeCount from '../model/NoticeCount';

/**
* Canvas service.
* @module api/CanvasApi
* @version 1.0
*/
export default class CanvasApi {

    /**
    * Constructs a new CanvasApi. 
    * @alias module:api/CanvasApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Return total notices to user and unread
     * @param {Number} canvasUserId canvas user id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/NoticeCount} and HTTP response
     */
    appHttpControllersUserControllerCanvasCountNoticesWithHttpInfo(canvasUserId) {
      let postBody = null;
      // verify the required parameter 'canvasUserId' is set
      if (canvasUserId === undefined || canvasUserId === null) {
        throw new Error("Missing the required parameter 'canvasUserId' when calling appHttpControllersUserControllerCanvasCountNotices");
      }

      let pathParams = {
        'canvas_user_id': canvasUserId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = NoticeCount;
      return this.apiClient.callApi(
        '/api/v1/canvas/countNotices/{canvas_user_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Return total notices to user and unread
     * @param {Number} canvasUserId canvas user id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/NoticeCount}
     */
    appHttpControllersUserControllerCanvasCountNotices(canvasUserId) {
      return this.appHttpControllersUserControllerCanvasCountNoticesWithHttpInfo(canvasUserId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List all notices of the user
     * @param {Number} canvasUserId canvas user id
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page Page number
     * @param {Number} opts.limit Number of elements per page
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2007} and HTTP response
     */
    appHttpControllersUserControllerCanvasIndexWithHttpInfo(canvasUserId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'canvasUserId' is set
      if (canvasUserId === undefined || canvasUserId === null) {
        throw new Error("Missing the required parameter 'canvasUserId' when calling appHttpControllersUserControllerCanvasIndex");
      }

      let pathParams = {
        'canvas_user_id': canvasUserId
      };
      let queryParams = {
        'page': opts['page'],
        'limit': opts['limit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2007;
      return this.apiClient.callApi(
        '/api/v1/canvas/listNotices/{canvas_user_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List all notices of the user
     * @param {Number} canvasUserId canvas user id
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page Page number
     * @param {Number} opts.limit Number of elements per page
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2007}
     */
    appHttpControllersUserControllerCanvasIndex(canvasUserId, opts) {
      return this.appHttpControllersUserControllerCanvasIndexWithHttpInfo(canvasUserId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
