
export const SET_MATRICULA = 'SET_MATRICULA';
export const SET_FIXTURES = 'SET_FIXTURES';
export const SET_DOCUMENT = 'SET_DOCUMENT';
export const SET_ACADEMIC_PROGRAM_DOCUMENT = 'SET_ACADEMIC_PROGRAM_DOCUMENT';
export const SET_STATUS = 'SET_STATUS';
export const SET_COURSE_ACCESS = 'SET_COURSE_ACCESS';
export const SET_CERTIFIED_COURSE_ACCESS = 'SET_CERTIFIED_COURSE_ACCESS';
export const SET_STUDENT = 'SET_STUDENT';
export const SET_STUDENT_ACCESS = 'SET_STUDENT_ACCESS';
export const SET_SHOP = 'SET_SHOP';
export const SET_TITLE_CART = 'SET_TITLE_CART';
export const SET_TITLE_PLACE = 'SET_TITLE_PLACE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_BANNER = 'SET_BANNER';
