import React, {Fragment, h} from "preact";
import {Card, E, FR} from "../../_common/layout";
import {t} from '../../_i18n';
import {Field, Form as FinalForm} from 'react-final-form'
import RepeatMailWrapper from "../components/RepeatMail/RepeatMailWrapper";
import {CheckboxFinalForm} from "../../_common/forms/components/Checkbox/Checkbox";
import {ButtonPrimary} from "../../_common/components/buttons/buttons";
import {useState} from "preact/compat";
import {connect} from "react-redux";
import {sendInviteFriend} from "../../_rx/inviteFriend/actions";
import ModalPendingInvite from "../components/ModalPendingInvite";


const initialState = {
    emails: {mail1: '', mail2: ''},
    conditions: false
}

function PendingInviteComponent({sendInviteFriend, inviteFriendStatus}) {
    const [submitButton, setSubmitButton] = useState(false)
    const idModal = 'conditions'
    // const usedPremium = !inviteFriendStatus.gifts.includes('PremiumDto')

    const message = t('<p>Estás a un paso de recibir un bono de descuento de 50€ para\n' +
        'tu próxima matrícula. ¿Cómo conseguirlo? Muy sencillo. Animando a un amigo,\n' +
        'conocido o familiar a que estudie con nosotros.</p>\n' +
        '\n' +
        '<p>Escribe su correo electrónico a continuación y, una vez se\n' +
        'haya matriculado, recibirás el cupón de descuento en tu correo electrónico. Además,\n' +
        'por si fuera poco, él también recibirá otro bono de 50€ de descuento.</p>\n' +
        '\n' +
        '<p>¡Recomiéndanos hoy mismo!</p>')

    const handleSubmit = async (value) => {
        setSubmitButton(true)
        sendInviteFriend(value.email)
            .then(() => setSubmitButton(false))
            .catch(() => setSubmitButton(false))
    }

    const renderSubmitButton = (values) => {
        const isDisabled = submitButton
            || values.emails.mail1.trim() === ''
            || values.emails.mail2.trim() === ''
            || values.conditions !== true

        return <ButtonPrimary disabled={isDisabled}>{t('¡INVITAR A AMIGO/A!')}</ButtonPrimary>
    }

    return (
        <Card className='m-top p-x2-bottom animation-appear'>
            <h2 className='font-24 m-top'>{t('¿Conoces a alguien a quién le gustaría estudiar en ILERNA?')}</h2>
            <div className='m-top font-16' dangerouslySetInnerHTML={{__html: message}}/>
            {/*<div className='m-top font-16'>*/}
            {/*    {t('Solo puedes enviar una invitación. Escoge bien a quien invitas.')} 😀*/}
            {/*</div>*/}
            <div className='m-top font-16 t-underline text-primary'>
                {/*<ModalLink id={idModal}>{t('Consulta las condiciones completas de la promoción.')}</ModalLink>*/}
                <a target="_blank" href="https://recursos.ilerna.es/bases_promo_diselo_a_un_amigo">Consulta las
                    condiciones completas de la promoción.</a>
            </div>
            <hr className='separator-xs'/>
            <FinalForm
                onSubmit={(values) => values.conditions && handleSubmit(values)}
                initialValues={initialState}
                render={({handleSubmit, values}) => <Fragment>
                    <form className='form' action="" onSubmit={handleSubmit} noValidate>
                        <FR>
                            <Field name="emails" component={RepeatMailWrapper}/>
                            <E className='col-70'>
                                <Field name="conditions"
                                       component={CheckboxFinalForm}
                                       label={
                                           <span>{t('He leído y acepto')}&nbsp;
                                               <a target="_blank"
                                                  href="https://recursos.ilerna.es/bases_promo_diselo_a_un_amigo"> las condiciones de la promoción.</a>
                                               {/*<ModalLink id={idModal}><span*/}
                                               {/*    dangerouslySetInnerHTML={{__html: t('INVITE_FRIEND_PENDING_CHECKBOX_CONDITIONS')}}/> </ModalLink>*/}
                                               </span>}
                                />
                                <ModalPendingInvite id={idModal}/>
                            </E>
                        </FR>
                        <div className='m-top'>
                            {renderSubmitButton(values)}
                        </div>
                    </form>
                </Fragment>}
            />
        </Card>
    )
}


PendingInviteComponent = connect(
    ({inviteFriendStatus}) => ({inviteFriendStatus: inviteFriendStatus.status}),
    {sendInviteFriend})(PendingInviteComponent);


export default ({...rest}) => <PendingInviteComponent {...rest}/>
