import React, { Fragment, Component, h} from "preact";
import {useEffect, useRef, useState} from 'preact/hooks';
import {connect} from "react-redux";
import {useParams} from "react-router";
import {t} from '../../../_i18n';
import '../../styles/enroll-sidebar-styles.scss'
import SideBarModal from "../../../_common/components/sideBarModal/sideBarModal";
import Loading from '../../../_common/components/loading/Loading';
import {getPastClasses, getNextClasses, getBuildings, getSchedules, enrollClass, getStatusClasses, modifyEnroll} from '../../../_rx/enrollment/classes/actions';
import Badge from '../../../_common/components/Badge';
import DropdownBuildings from '../../../_common/components/DropdownBuildings/DropdownBuildings';
import useSidebarLogic from '../../hooks/useSidebarLogic';
import useScroll from '../../hooks/useScroll';
import InputTime from '../components/InputTime/InputTime';
import ButtonSpinner from '../../../_common/components/buttons/buttonSpinner/buttonSpinner';
import {toastService} from '../../../_services/toast-service';
import AlertBox from "../../../_common/components/AlertBox/AlertBox";

function Sidebar ({ visible, studentId, handleCloseModal, historyEventsStatus, classSelected, getNextClasses, getPastClasses, getStatusClasses }){
  const { buildings, setBuildings, schedules, setSchedules, citySelected, setCitySelected, dateSelected, setDateSelected, disableSubmit, fetchBuildings, fetchSchedules } = useSidebarLogic()
  const [loading, setLoading] = useState(false)
  const isNextEvents = historyEventsStatus === 'next'
  const funcUpdateHistoryClass = isNextEvents ? getNextClasses : getPastClasses
  const {action} = useParams()
  const { focusDropdown } = useScroll()
  const isNewEnroll = action === 'apply'

  useEffect(async () => {
    if (visible) {
      if (isNewEnroll) {
        await fetchBuildings(() => getBuildings(classSelected.formative_action_id, studentId))
      } else {
        await fetchBuildings(() => getBuildings(classSelected.formative_action_id, studentId))
        await fetchSchedules(() => getSchedules({
          buildingId: classSelected.buildingSelected.building_id,
          studentId,
          formativeActionId: classSelected.formative_action_id
        }))
        if (buildings && buildings.find( (building) => building.id === classSelected.buildingSelected.building_id) !== undefined) {
          setCitySelected(classSelected.buildingSelected.building_id)
          setDateSelected(classSelected.dateSelected)
        }
      }
    } else {
      setCitySelected(null)
      setBuildings(null)
      setSchedules([])
      setDateSelected(null)
    }
  }, [visible])

  const handleSetCity = async (newCitySelected) => {
    const classSelected =  this.props.classSelected;
    if (citySelected !== newCitySelected) {
      await fetchSchedules(() => getSchedules({
        buildingId: newCitySelected,
        studentId,
        formativeActionId: classSelected.formative_action_id
      }))
      setCitySelected(newCitySelected)
      setDateSelected(null)
    }
  }

  const handleSubmitNewEnroll = async () => {
    setLoading(true)
    const {error} = await enrollClass({
      formativeActionId: classSelected.formative_action_id,
      eventId: dateSelected.event_id,
      studentId})
    if (error) toastService.showToast({message: t('La inscripción no se ha podido realizar. Por favor, vuelve a intentarlo.'), type:'danger'});
    else {
      await getStatusClasses(studentId)
      await funcUpdateHistoryClass(studentId)
      toastService.showToast({message: t('La inscripción se ha realizado correctamente.'), type:'info'});
      handleCloseModal()
    }
    setLoading(false)
  }

  const handleSubmitModifyEnroll = async () => {
    setLoading(true)
    const {error} = await modifyEnroll({
      eventId: dateSelected.event_id,
      formativeActionId: classSelected.formative_action_id,
      oldEventId: classSelected.dateSelected.event_id,
      studentId
    })
    if (error) toastService.showToast({message:t('La modificación de la inscripción no se ha podido realizar. Por favor, vuelve a intentarlo.'), type:'danger'});
    else {
      await getStatusClasses(studentId)
      await funcUpdateHistoryClass(studentId)
      toastService.showToast({message:t('La modificación de la inscripción se ha realizado correctamente.'), type:'info'});
      handleCloseModal()
    }
    setLoading(false)
  }



  return <SideBarModal visible={visible} onClose={handleCloseModal}>
    { visible && classSelected
      ? <Fragment>
        <div className='enrollment-sidebar-header'>
          <div className='enrollment-sidebar-header__icon' onClick={handleCloseModal}>
            <i className="fal fa-times "/>
          </div>
        </div>
        <div className='enrollment-sidebar-content'>
          <div className='m-l m-r sideExams-container'>
            <div className='font-32 font-medium p-top'>{t('Inscripción a clases presenciales')}</div>
            <div className='t-upper font-color-879CA1 font-14 m-top'>{classSelected.formative_action_code}</div>
            <div className='font-18'>{classSelected.formative_action_title}</div>
            {buildings && <Badge type='warning' className='m-top-half'>{classSelected.session_min_hour} horas</Badge>}
            <hr className="separator-xs"/>
            <div className='font-medium p-bottom-half building_section_title'>{t('Sede de la clase')}:</div>
            { buildings && buildings.length > 0 &&
                <DropdownBuildings
                  defaultTitleSelector={t('Seleccionar una sede')}
                  onClick={handleSetCity}
                  onOpenTransitionEnd={focusDropdown}
                  citySelectedId={citySelected}
                  items={buildings || []}
                  showAddress
                />
            }
            {buildings && buildings.length < 1 &&
                <AlertBox type='info' className='m-top-half'>{t('no_buildings_available')}</AlertBox>
            }

            { citySelected &&
              <div className='m-bottom'>
                <div className='font-medium m-x2-top'>{t('Selecciona una opción')}:</div>
                {/*<div>{JSON.stringify(dateSelected)}</div>*/}
                {/*<div>{JSON.stringify(classSelected)}</div>*/}
                {/*<div>{JSON.stringify(schedules)}</div>*/}
                {schedules?.length > 0 && schedules?.map( dateItem => {
                  return <InputTime
                    checked={dateSelected?.event_id === dateItem.event_id}
                    date={dateItem}
                    onClick={setDateSelected}
                    isThisScheduleSelected={classSelected.dateSelected?.event_id === dateItem.event_id}
                  />
                })}

                {schedules?.length < 1 &&
                    <AlertBox type='info' className='m-top-half'>{t('no_events_available')}</AlertBox>
                }
              </div>
            }
          </div>
        </div>
        <div className='enrollment-sidebar-button-container'>
          <ButtonSpinner
            classNameContainer='full-width'
            className='full-width'
            onClick={ isNewEnroll ? handleSubmitNewEnroll : handleSubmitModifyEnroll}
            disabled={disableSubmit}
            showSpinner={loading}
          >
            {t('Guardar Selección')}
          </ButtonSpinner>
        </div>
      </Fragment>
      : <Loading label={`${t('Cargando')}...`}/>
    }
  </SideBarModal>
}

Sidebar = connect(
  ({enrollmentClasses, auth})=>({
    classSelected: enrollmentClasses.classSelected,
    classStatus: enrollmentClasses.status,
    studentId: auth.loggedUser.id,
  }),
  { getPastClasses, getNextClasses, getStatusClasses })(Sidebar);


export default ({...rest}) => <Sidebar {...rest} />
