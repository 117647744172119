import {svcPsService} from "../../../_services/ps-service";
import {globalCatch} from "../../../_services/global-catch";
import {toastService} from "../../../_services/toast-service";
import {t} from "../../../_i18n";

export const savePassword = password =>
    globalCatch.catch(async (dispatch) => {
        await (await svcPsService()).ChangePassword(password.password, password.new_password);
        toastService.showToast({message:t("La contraseña se ha cambiado correctamente"), type:'info'});

    })
;
