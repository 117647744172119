import StudentAccountService from "../../../_services/student-account-service";
import {
    SET_FIXTURES,
    SET_MATRICULA,
    SET_DOCUMENT,
    SET_STATUS,
    SET_COURSE_ACCESS,
    SET_STUDENT,
    SET_SHOP,
    SET_CERTIFIED_COURSE_ACCESS,
    SET_TITLE_CART,
    SET_TITLE_PLACE,
    SET_ACADEMIC_PROGRAM_DOCUMENT,
    SET_LANGUAGE,
    SET_USER_GROUP_TYPE,
    SET_STUDENT_ACCESS, SET_BANNER
} from "./constants";
import FixtureService from "../../../_services/fixture-service";
import { lazySingleton} from "../../../_common/common.ts";
import {setStatusMessages} from "../../_notify/actions";
import {globalCatch} from "../../../_services/global-catch";
import {toastService} from "../../../_services/toast-service";
import {t} from "../../../_i18n";
import getUserRol from '../../../app/getUserRol'


// singleton
const svcStudent = lazySingleton(()=>new StudentAccountService());
const svcFixtures = lazySingleton(()=>new FixtureService());

export const setMatricula = payload => ({type: SET_MATRICULA, payload});

export const fetchMatricula = student_id => globalCatch.catch(async (dispatch) => {
    const matricula = await svcStudent().show(student_id);
    dispatch(setMatricula(matricula));
});

export const setShop = payload => ({type: SET_SHOP, payload});

export const fetchShop = student_id => globalCatch.catch(async (dispatch) => {
    const shop = await svcStudent().getStudentShop(student_id);
    dispatch(setShop(shop));
});

export const setStudent = payload => ({type: SET_STUDENT, payload});

export const fetchStudent = student_id => globalCatch.catch(async (dispatch) => {
    const student = await svcStudent().getStudentData(student_id);
    dispatch(setStudent(student));
});

export const saveStudent = student => globalCatch.catch(async (dispatch, getState) => {
    await svcStudent().update(student);
    const studentToSave = await svcStudent().getStudentData(student.data.id);
    dispatch(setStudent(studentToSave));
    toastService.showToast({message:t("Tus datos se han guardado correctamente"), type:'info'});
    await internalFetchStatus(dispatch, student.data.id);
});

export const saveStudentDocument = (doc, idStudent) => globalCatch.catch(async (dispatch, getState) => {
    await svcStudent().saveStudentDocument(doc, idStudent);
    await dispatch(fetchDocument(idStudent));
    toastService.showToast({message:t("Tus datos se han guardado correctamente"), type:'info'});
    await internalFetchStatus(dispatch, doc.student_id);
});

export const setStudentCourseAccess = payload => ({type: SET_COURSE_ACCESS, payload});

export const fetchStudentCourseAccess = student_id => globalCatch.catch(async (dispatch) => {
    const studentCourseAccess = await svcStudent().getStudentCourseAccess(student_id);
    dispatch(setStudentCourseAccess(studentCourseAccess));
});

export const saveCourseAccess = data => globalCatch.catch(async (dispatch, getState) => {
    await svcStudent().updateCourseAccess(data);
    toastService.showToast({message:t("Tus datos se han guardado correctamente"), type:'info'});
    await internalFetchStatus(dispatch, data.student_id);
});

export const setStudentCertifiedCourseAccess = payload => ({type: SET_CERTIFIED_COURSE_ACCESS, payload});

export const fetchCertifiedCourseAccess = student_id => globalCatch.catch(async (dispatch) => {
    const studentCourseAccess = await svcStudent().getStudentCertifiedCourseAccess(student_id);
    dispatch(setStudentCertifiedCourseAccess(studentCourseAccess));
});

export const setFixtures = payload => ({type: SET_FIXTURES, payload});

export const fetchFixture = locale => globalCatch.catch(async (dispatch) => {
    const fixtures = await svcFixtures().getFixture(locale);
    dispatch(setFixtures(fixtures));
});

export const setDocument = payload => ({type: SET_DOCUMENT, payload});

export const fetchDocument = student_id => globalCatch.catch(async (dispatch) => {
    const doc = await svcStudent().getStudentDocument(student_id);
    dispatch(setDocument(doc));
});

export const saveDocument = document => globalCatch.catch(async (dispatch, getState) => {
    await svcStudent().saveStudentDocument(document);
    dispatch(setDocument(document));
    toastService.showToast({message:t("Tus datos se han guardado correctamente"), type:'info'});
    await internalFetchStatus(dispatch, data.student_id);
});

export const uploadDocument = (studentId, img, docId) => globalCatch.catch(async () => {
    await svcStudent().uploadDocument(studentId, img, docId);
});

export const uploadSignature = (studentId, image, useOfImages, refuseCommercialInfo) => globalCatch.catch(async (dispatch) => {
    await svcStudent().uploadSignature(studentId, image, useOfImages, refuseCommercialInfo);
    toastService.showToast({message:t("Tus datos se han guardado correctamente"), type:'info'});
    await internalFetchStatus(dispatch, studentId);
});

export const setStatus = payload => ({type: SET_STATUS, payload});
export const setStudentAccess = payload => ({type: SET_STUDENT_ACCESS, payload});

export const internalFetchStatus = async (dispatch, student_id) => {
    const status = await svcStudent().getStatus(student_id);
    dispatch(setStudentAccess(getUserRol(status)))
    dispatch(setStatus(status));
    dispatch(setStatusMessages(status));
};

export const setTitleCart = payload => ({type: SET_TITLE_CART, payload});

export const fetchTitleCart = student_id => globalCatch.catch(async (dispatch) => {
    const data = await svcStudent().getStudentAcademicProgramCart(student_id);
    dispatch(setTitleCart(data));
});

export const fetchStatus = student_id => globalCatch.catch(dispatch=>internalFetchStatus(dispatch, student_id));

export const postTitlePlace = (student_id, place) => globalCatch.catch(async () => {
    await svcStudent().setAcademicTitlePlace(student_id, place);
    toastService.showToast({message:t("Tus datos se han guardado correctamente"), type:'info'});
});

export const fetchTitlePlace = (student_id) => globalCatch.catch(async (dispatch) => {
    const data = await svcStudent().getAcademicTitlePlace(student_id);
    dispatch(setTitlePlace(data));
});

export const setTitlePlace = payload => ({type: SET_TITLE_PLACE, payload});

export const fetchAcademicProgramDocument = (student_id) => globalCatch.catch(async (dispatch) => {
    const [documents, documentTypes] = await Promise.all([
        svcStudent().getAcademicProgramDocument(student_id),
        svcStudent().getAcademicProgramDocumentTypes(student_id)
    ]) ;
    dispatch(setAcademicProgramDocument({documents, documentTypes}));
});

export const setAcademicProgramDocument = payload => ({type: SET_ACADEMIC_PROGRAM_DOCUMENT, payload});

export const uploadAcademicProgramDocument = (studentId, img, docId) => globalCatch.catch(async () => {
    await svcStudent().uploadAcademicProgramDocument(studentId, img, docId);
});

export const fetchCommercialInfo = student_id => globalCatch.catch(async (dispatch) => {
    return await svcStudent().getCommercialInfo(student_id);
});

export const saveCommercialInfo = (student_id, refuse_commercial_info) => globalCatch.catch(async (dispatch, getState) => {
    await svcStudent().setCommercialInfo(student_id,refuse_commercial_info);
    toastService.showToast({message: t("Se ha guardado la configuración correctamente"), type: 'info'});
});

export const setLanguageRedux = lang =>( {type: SET_LANGUAGE, payload:lang});
export const setBanner = banner => ({type: SET_BANNER, payload: banner});
