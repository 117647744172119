import React, {Component, Fragment, h} from "preact";
import {useState} from "preact/hooks";


export const UseModalConf = ({closeModal, actionFn, studentId, academicProgram}) => {
    const [opt, setOpt] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleCloseModal = () => {
        setOpt(null)
        setLoading(false)
        closeModal()
    }

    const handleSubmit = () => {
        setLoading(true)
        actionFn({studentId, academicProgram, opt})
            .then(() => setLoading(false))
    }

    return {opt, setOpt, loading, setLoading, handleCloseModal, handleSubmit}
}
