import { h } from "preact";
import { Link } from 'react-router-dom'
import AlertBox from '../../../_common/components/AlertBox/AlertBox'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { t } from '../../../_i18n'

export default function AlertAddress () {
  const address = useSelector(state => state.student.address);
  const { id } = useParams()

  return (
    <AlertBox type='shadow' className='m-top d-flex sm-flex-bottom flex-align-start full-width flex-space-between m-top-half flex-column sm-no-reverse'>
      <div className='d-flex flex-column'>
        <div>{address.full_address}</div>
      </div>
      <Link to={{pathname: `/student/address`, query: {urlToBack: `/fct/${id}/documentacion`}}} className='t-upper font-14 txt-color-primary t-underline font-medium'>{t('Actualizar dirección')}</Link>
    </AlertBox>
  )
}
