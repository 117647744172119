import {SET_POBLACIONES} from "../actions/constants";
import {SET_PAISES_PRESTA} from "../actions/constants";
import {createReducer} from "../../../_common/common.ts";

export const poblaciones = createReducer(null, {
    [SET_POBLACIONES]: (state, payload)=> payload,
});

export const paises_presta = createReducer(null, {
    [SET_PAISES_PRESTA]: (state, payload)=> payload,
});

