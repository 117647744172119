/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DayAvailability from './DayAvailability';

/**
 * The Availability model module.
 * @module model/Availability
 * @version 1.0.0
 */
class Availability {
    /**
     * Constructs a new <code>Availability</code>.
     * @alias module:model/Availability
     */
    constructor() { 
        
        Availability.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Availability</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Availability} obj Optional instance to populate.
     * @return {module:model/Availability} The populated <code>Availability</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Availability();

            if (data.hasOwnProperty('monday')) {
                obj['monday'] = DayAvailability.constructFromObject(data['monday']);
            }
            if (data.hasOwnProperty('tuesday')) {
                obj['tuesday'] = DayAvailability.constructFromObject(data['tuesday']);
            }
            if (data.hasOwnProperty('wednesday')) {
                obj['wednesday'] = DayAvailability.constructFromObject(data['wednesday']);
            }
            if (data.hasOwnProperty('thursday')) {
                obj['thursday'] = DayAvailability.constructFromObject(data['thursday']);
            }
            if (data.hasOwnProperty('friday')) {
                obj['friday'] = DayAvailability.constructFromObject(data['friday']);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/DayAvailability} monday
 */
Availability.prototype['monday'] = undefined;

/**
 * @member {module:model/DayAvailability} tuesday
 */
Availability.prototype['tuesday'] = undefined;

/**
 * @member {module:model/DayAvailability} wednesday
 */
Availability.prototype['wednesday'] = undefined;

/**
 * @member {module:model/DayAvailability} thursday
 */
Availability.prototype['thursday'] = undefined;

/**
 * @member {module:model/DayAvailability} friday
 */
Availability.prototype['friday'] = undefined;






export default Availability;

