import React, {Component, Fragment, h} from "preact";
import {Card, CF, E, FR, R} from "../../_common/layout";
import {connect} from "react-redux";
import {deleteLetter, fetchCandidate, saveLetter} from "../../_rx/jobs/actions";
import {AppPage} from "../../_common/components/app-page";
import {t} from "../../_i18n";
import {FormField, FormFieldTextArea, SubmitButtonRow} from "../../_common/forms/components/components";
import {Form as FinalForm} from "react-final-form";
import {Link} from "react-router-dom";
import {Redirect} from "react-router";



let LetterView = class extends Component {
    state = {letter: {text: "", title: ""}};

    async componentDidMount() {
        if(this.props.match.url.endsWith('/new')){
            this.setState({letter: {text: "", title: ""}});
        } else {
            await this.props.fetchCandidate();
            const letter = this.props.candidate.letters.find((letter)=> letter.id == this.props.match.params.id);
            this.setState({letter});
        }
    }

    async onSubmit(data) {
        await this.props.saveLetter(data).catch(err=> ({'error': true}));
    }

    async onDelete(letterId) {
        if(!confirm(t('¿Seguro que deseas eliminarla?'))) return;

        await this.props.deleteLetter(letterId).catch(err=> ({'error': true}));
        history.back();
    }

    render({candidate, history}) {

        if(!this.state.letter) return null;
        return <CF className="max-width-980">
                <nav className="breadcrumb-wrapper p-x2-bottom">
                    <Link to='/jobs/candidate' className="back breadcrumb-item">← {t('Volver')}</Link>
                </nav>
            <FinalForm initialValues={this.state.letter} onSubmit={data => this.onSubmit(data)} render = {
                ({handleSubmit,  submitting, errors, submitSucceeded, values})=>
                    <form className="form profileForm" action="" onSubmit={handleSubmit} noValidate>
                        {submitSucceeded && <Redirect to="/jobs/candidate" />}
                        <Card>
                            <h1 className="m-x2-bottom">{t('Añadir carta de presentación')}</h1>

                            <div className="m-x2-bottom">
                                <FormField name="name" label={t('Nombre de la carta')} required/>
                            </div>
                            <div>
                                <FormFieldTextArea label={t('Texto de la carta')} className="t-right" name="text" id="contador" rows={10} maxlength={4000} required/>
                                {values.text !== undefined ? <p className="t-right">{values.text.length}/4000 caracteres</p> : <p className="t-right">0/4000 caracteres</p> }
                            </div>
                        </Card>
                        <R className="d-none row-2-el sm-d-flex">
                            <E>
                                <button disabled={submitting} className="btn btn-primary m-top m-bottom" type='submit'>{t('Guardar')}</button>
                                <button className="btn btn-link m-top m-bottom" type='button' onClick={()=>history.push('/jobs/candidate')}>{t('Cancelar')}</button>
                            </E>
                            <E className="d-flex flex-right">
                                <button disabled={submitting || !values.id} className="btn btn-ghost danger m-top m-bottom" type={"button"} onClick={()=>this.onDelete(values.id)}><span className={"text-danger"}>{t('Eliminar')}</span></button>

                            </E>
                        </R>
                        <R className="d-flex sm-d-none">
                            <E className="full-width">
                                <button disabled={submitting} className="btn btn-primary full-width" type='submit'>{t('Guardar')}</button>
                            </E>
                            <E className="full-width">
                                <button className="btn btn-link m-top m-bottom full-width" type='button' onClick={()=>history.push('/jobs/candidate')}>{t('Cancelar')}</button>
                            </E>
                        </R>
                        <R className="d-flex sm-d-none">
                            <E className="full-width">
                                <button disabled={submitting || !values.id} className="btn btn-ghost danger full-width" type={"button"} onClick={() => this.onDelete(values.id)}><span className={"text-danger"}>{t('Eliminar')}</span></button>
                            </E>
                        </R>
                    </form>
            }/>
        </CF>
    }

}

LetterView = connect(
    ({candidate, letter, candidateId})=>({candidate, letter, candidateId}), {fetchCandidate, saveLetter, deleteLetter})(LetterView);

const LetterPage = ({history, match}) =>
    <AppPage
        name="letter"
        subtitle=""
        className="max-width">
        <LetterView match={match} history={history} />
    </AppPage>
;

export default LetterPage;
