import {
    SET_ACADEMIC_PROGRAMS,
    SET_CANDIDATE, SET_CANDIDATE_OFFERS_COUNT, SET_CANDIDATE_OFFERS_FILTER,
    SET_COMPANY,
    SET_CONTRACT_TYPES, SET_FILTER,
    SET_LETTER,
    SET_OFFER,
    SET_OFFERS,
    SET_OFFERS_COMPANY,
    SET_SCHEDULES,
    SET_STATES
} from "./constants";

import {toDic, toKeyName} from "../../../_common/common.ts";
import {globalCatch} from "../../../_services/global-catch";

// singleton
import {candidateApi, fixtureApi} from '../../../_services/jobs-service';
import {toastService} from "../../../_services/toast-service";
import {t} from "../../../_i18n";

export const setCandidate = payload => ({type: SET_CANDIDATE, payload});

export const fetchCandidate = student_id => globalCatch.catch(async (dispatch) => {
    // TODO: comment to reenable jobs;
    // return;

    const candidate = await(await candidateApi()).candidateCandidateGet();
    if(!candidate.links){
        candidate.links = [];
    }
    if(!candidate.links.some(link => link.code == 'linkedin')){
        candidate.links.push({id:null,code:'linkedin',url:''});
    }
    if(!candidate.links.some(link => link.code == 'infojobs')){
        candidate.links.push({id:null,code:'infojobs',url:''});
    }
    dispatch(setCandidate(candidate));
});

export const setOffers = payload => ({type: SET_OFFERS, payload});

export const fetchOffers = filters => globalCatch.catch(async (dispatch) => {
    await dispatch(setOffers(null))
    const offers = await(await candidateApi()).candidateOffersPagedGet(filters);
    dispatch(setOffers(offers));
});

export const setOffersCompany = payload => ({type: SET_OFFERS_COMPANY, payload});

export const fetchOffersCompany = filters => globalCatch.catch(async (dispatch) => {
    const offers = await(await candidateApi()).candidateOffersPagedGet(filters);
    dispatch(setOffersCompany(offers));
});

export const setCandidateOffersFilter = payload => ({type: SET_CANDIDATE_OFFERS_FILTER, payload});
export const fetchCandidateOffersFilter = filters => globalCatch.catch(async (dispatch) => {
    const offersFilter = await(await candidateApi()).candidateOffersFiltersGet(filters);
    dispatch(setCandidateOffersFilter(offersFilter));
})

export const setCandidateOffersCount = payload => ({type: SET_CANDIDATE_OFFERS_COUNT, payload});
export const fetchCandidateOffersCount = (filters) => globalCatch.catch(async (dispatch) => {
    const offersNum = await(await candidateApi()).candidateOffersCountGet(filters||{});
    dispatch(setCandidateOffersCount(offersNum));
});

export const setOffer = payload => ({type: SET_OFFER, payload});
export const clearOffer = () => ({type: SET_OFFER, payload: null});

export const fetchOffer = id => globalCatch.catch(async (dispatch) => {
   const offer = await(await candidateApi()).candidateOffersIdGet(id);
   dispatch(setOffer(offer));
});

export const setCompany = payload => ({type: SET_COMPANY, payload});
export const clearCompany = () => ({type: SET_COMPANY, payload: null});

export const fetchCompany = id => globalCatch.catch(async (dispatch) => {
    const company = await(await candidateApi()).candidateCompanyIdGet(id);
    dispatch(setCompany(company));
});


export const saveLetter = candidateLetter => globalCatch.catch(async (dispatch) => {
    if(candidateLetter.id) {
        await(await candidateApi()).candidateLettersPut({candidateLetter});
        toastService.showToast({message: t("La carta se ha guardado correctamente"), type: 'info'});
    } else {
        await(await candidateApi()).candidateLettersPost({candidateLetter});
        toastService.showToast({message: t("La carta se ha creado correctamente"), type: 'info'});
    }
    dispatch(fetchCandidate());
});

export const deleteLetter = candidateId => globalCatch.catch(async (dispatch) => {
    await(await candidateApi()).candidateLettersIdDelete(candidateId);
    toastService.showToast({message: t("La carta se ha eliminado correctamente"), type: 'info'});
    dispatch(fetchCandidate());
});

export const saveLinks = candidateLink => globalCatch.catch(async (dispatch) => {
    candidateLink = candidateLink.filter(link => link.url);
    await(await candidateApi()).candidateLinksPost({candidateLink});
    toastService.showToast({message: t("Tus perfiles se han guardado correctamente"), type: 'info'});
    dispatch(fetchCandidate());
});

export const saveDocument = file => globalCatch.catch(async (dispatch) => {
    await(await candidateApi()).candidateDocumentPost({file});
    toastService.showToast({message: t("Tu CV se ha guardado correctamente"), type: 'info'});
    dispatch(fetchCandidate());
});

export const deleteDocument = () => globalCatch.catch(async (dispatch) => {
    await(await candidateApi()).candidateDocumentDelete();
    toastService.showToast({message: t("Tu CV se ha eliminado correctamente"), type: 'info'});
    dispatch(fetchCandidate());
});

export const saveEnroll = (id, inlineObject1) => globalCatch.catch(async (dispatch) => {
    // console.log(inlineObject1);
    await(await candidateApi()).candidateOffersIdApplyPost(id, {inlineObject1});
    toastService.showToast({message: t("Tu inscripción se ha realizado correctamente"), type: 'info'});
    dispatch(fetchOffer(id));
});

export const acceptPrivacyPolicy = () => globalCatch.catch(async (dispatch) => {
    await(await candidateApi()).candidateAcceptPrivacyPolicyPut();
});

export const setJobFilter = payload => ({type: SET_FILTER, payload});
export const resetJobFilter = () => ({type: SET_FILTER, payload: {
        modality: {},
        academicPrograms: {},
        states: {},
        presenceType: {},
    }});

///////////////////// FIXTURES


export const setStates = (dic,kn) => ({type: SET_STATES, payload:{dic,kn}});

export const fetchStates = () => globalCatch.catch(async (dispatch) => {
    const data = await fixtureApi.fixturesStatesGet();
    dispatch(setStates(toDic(data), toKeyName(data)));
});


export const setAcademicPrograms = (dic,kn) => ({type: SET_ACADEMIC_PROGRAMS, payload:{dic,kn}});

export const fetchAcademicPrograms = () => globalCatch.catch(async (dispatch) => {
    let data = await fixtureApi.fixturesAcademicProgramsGet();
    dispatch(setAcademicPrograms(toDic(data), toKeyName(data)));
});


export const setSchedules = (dic,kn) => ({type: SET_SCHEDULES, payload:{dic,kn}});

export const fetchSchedules = () => globalCatch.catch(async (dispatch) => {
    const data = await fixtureApi.fixturesSchedulesGet();
    dispatch(setSchedules(toDic(data), toKeyName(data)));
});

export const setContractTypes = (dic,kn) => ({type: SET_CONTRACT_TYPES, payload:{dic,kn}});

export const fetchContractTypes = () => globalCatch.catch(async (dispatch) => {
    const data = await fixtureApi.fixturesContractTypesGet();
    dispatch(setContractTypes(toDic(data), toKeyName(data)));
});


