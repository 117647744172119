/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Send model module.
 * @module model/Send
 * @version 1.0
 */
class Send {
    /**
     * Constructs a new <code>Send</code>.
     * @alias module:model/Send
     */
    constructor() { 
        
        Send.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Send</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Send} obj Optional instance to populate.
     * @return {module:model/Send} The populated <code>Send</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Send();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
            if (data.hasOwnProperty('ses_id')) {
                obj['ses_id'] = ApiClient.convertToType(data['ses_id'], 'Number');
            }
            if (data.hasOwnProperty('firebase_id')) {
                obj['firebase_id'] = ApiClient.convertToType(data['firebase_id'], 'Number');
            }
            if (data.hasOwnProperty('notice_id')) {
                obj['notice_id'] = ApiClient.convertToType(data['notice_id'], 'Number');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('send_at')) {
                obj['send_at'] = ApiClient.convertToType(data['send_at'], 'String');
            }
            if (data.hasOwnProperty('notification_read_at')) {
                obj['notification_read_at'] = ApiClient.convertToType(data['notification_read_at'], 'String');
            }
            if (data.hasOwnProperty('read_at')) {
                obj['read_at'] = ApiClient.convertToType(data['read_at'], 'String');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'String');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'String');
            }
        }
        return obj;
    }


}

/**
 * Class Send
 * @member {Number} id
 */
Send.prototype['id'] = undefined;

/**
 * Class Send
 * @member {String} uuid
 */
Send.prototype['uuid'] = undefined;

/**
 * Class Send
 * @member {Number} ses_id
 */
Send.prototype['ses_id'] = undefined;

/**
 * Class Send
 * @member {Number} firebase_id
 */
Send.prototype['firebase_id'] = undefined;

/**
 * Class Send
 * @member {Number} notice_id
 */
Send.prototype['notice_id'] = undefined;

/**
 * Class Send
 * @member {String} username
 */
Send.prototype['username'] = undefined;

/**
 * Class Send
 * @member {String} send_at
 */
Send.prototype['send_at'] = undefined;

/**
 * Class Send
 * @member {String} notification_read_at
 */
Send.prototype['notification_read_at'] = undefined;

/**
 * Class Send
 * @member {String} read_at
 */
Send.prototype['read_at'] = undefined;

/**
 * Class Send
 * @member {String} created_at
 */
Send.prototype['created_at'] = undefined;

/**
 * Class Send
 * @member {String} updated_at
 */
Send.prototype['updated_at'] = undefined;






export default Send;

