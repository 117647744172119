import { h } from 'preact'
import '../help-section.scss'
import Modal from '../../../_common/components/Modal/index'
import { useState } from 'preact/hooks'
import { isMobileAgent } from '../../../_common/common'
import {
  getTopics,
  optionsFCT,
  optionsFide,
  optionsSecretary, SCHEDULE_FCT, SCHEDULE_FIDE,
  SCHEDULE_SECRETARY,
  STEP_INFO,
  STEP_SCHEDULE,
  STEP_SELECT_TOPIC,
  TITLE
} from './constants.jsx'
import StepInfo from './StepInfo'
import StepSelectTopic from './StepSelectTopic'
import StepSchedule from './StepSchedule'
import { useSelector } from 'react-redux'

export default function ModalHelp ({ visible, onClose }) {
  const { has_fct, has_convas, formative_zone } = useSelector(state => state.status.student_meta)
  const [step, setStep] = useState(isMobileAgent() ? STEP_INFO : STEP_SELECT_TOPIC)
  const [schedule, setSchedule] = useState()

  const handleSelectTopic = (val) => {
    const topicSelected = getTopics(has_fct, has_convas).find(option => option.key === parseInt(val.topic))
    setSchedule(topicSelected.type)
    setStep(STEP_SCHEDULE)
  }

  return (
    <Modal visible={visible} closeModal={onClose}>
      <div className='p-all font-color-black'>
        <div className='d-flex flex-space-between'>
          <div className='font-30'>{TITLE[step]}</div>
          <div className='icon cursor-pointer' onClick={onClose}><i className='fal fa-times'/></div>
        </div>
        <div>
          {step === STEP_INFO && <StepInfo onSubmit={() => setStep(STEP_SELECT_TOPIC)} formative_zone={formative_zone} />}
          {step === STEP_SELECT_TOPIC && <StepSelectTopic onSubmit={handleSelectTopic} />}
          {step === STEP_SCHEDULE && <StepSchedule schedule={schedule} />}
        </div>
      </div>
    </Modal>
  )
}
