import {Component, h, Fragment} from "preact";
import {useEffect, useState} from "preact/hooks";
import {E} from "../../layout";
import './OfferBadgesContainer.scss'
import Badge from "../Badge";

export default function OfferBadgesContainer({ academicProgramList = [], numberMax , isMobile = null}: any ) {
    const [headList, setHeadList] = useState([]);
    const [restList, setRestList] = useState([]);
    const [logicDone, setLogicDone] = useState(false);
    const [toggle, setToggle] = useState(false);
    const SHOW_REST_LIST = restList.length > 0;
    const TEXT_EXPAND = 'Ver ' + restList.length + ((restList.length === 1) ? ' ciclo más' : ' ciclos más')
    const TEXT_COLLAPSE = 'Ocultar ' + ((restList.length === 1) ? ' ciclo' : ' ciclos')

    useEffect(() => {
        if (academicProgramList.length > 0 && !logicDone){
            (isMobile) ? listIsMobile() : listIsDesktop()
            setLogicDone(true)
        }
    }, [academicProgramList]);

    const listIsMobile = () => {
        academicProgramList.map((item:any , index:any) => {
            if (index < 3) { // @ts-ignore
                setHeadList((oldHeadList: any) => [...oldHeadList, item]);
            } else // @ts-ignore
                setRestList((oldRestList: any) => [...oldRestList, item]);
        })
    }

    const listIsDesktop = () => {
        let count = 0;

        academicProgramList.map((item : any) => {
            if (count < numberMax) { // @ts-ignore
                setHeadList((oldHeadList: any) => [...oldHeadList, item]);
                count = count + item.length;
            } else { // @ts-ignore
                setRestList((oldRestList: any) => [...oldRestList, item]);
            }
        });
    }

    const handleClick = () => {
        setToggle(!toggle)
    }

    return (
        <div className='offerBadgesContainer'>
            {headList.map(item => <Badge className='offerBadgesContainer-badge'>{item}</Badge>)}
            <div className={SHOW_REST_LIST ? 'offerBadgesContainer show' : 'offerBadgesContainer hidden'}>
                <div className={toggle ? 'offerBadgesContainer show' : 'offerBadgesContainer hidden'}>{restList.map(item => <Badge className='offerBadgesContainer-badge'>{item}</Badge>)}</div>
                <E>
                    <span  className='offerBadgesContainer__content' onClick={handleClick}>{toggle ? TEXT_COLLAPSE : TEXT_EXPAND}</span>
                </E>
            </div>

        </div>
    );
}
