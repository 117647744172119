import { h } from "preact";
import { AppPage } from '../../_common/components/app-page'
import { ButtonGhost, ButtonPrimary } from '../../_common/components/buttons/buttons'
import { Field, Form as FinalForm } from 'react-final-form'
import { C, F } from '../../_common/layout'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { useEffect, useState } from 'preact/hooks'
import { fetchAllDocuments, fetchStatus, putDocument } from '../../_rx/fct/actions'
import UploadDocument from '../components/UploadDocument/UploadDocument'
import { validationDocumentFCT } from '../components/UploadDocument/validationDocumentFCT'
import { Link } from 'react-router-dom'
import ButtonSpinner from '../../_common/components/buttons/buttonSpinner/buttonSpinner'
import { t } from '../../_i18n'
import { isSpanishUser } from '../../_common/utils/getLang'
import AlertBox from "../../_common/components/AlertBox/AlertBox";

export default function RequestPostponement () {
  const static_documents = useSelector(state => state.fct.static_documents)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [data, setData] = useState(null)
  const shopId = useSelector(state => state.status.student_meta.shop_id)

  useEffect(() => {
    fetchAllDocuments(id).then((data) => setData(data))
  }, [])

  const handleSubmit = (values) => {
    setLoading(true)
    putDocument(values.blocks.other_docs.documents.deferral.id)
      .then(() => {
        setLoading(false)
        dispatch(fetchStatus(id))
        history.push(`/fct/${id}`)
      })
      .catch(() => setLoading(false))
  }

  return (
    <AppPage title={t('Solicitar aplazamiento')} back={history.goBack} className="max-width-980">
      <F className="max-width-980">
        <FinalForm
          onSubmit={handleSubmit}
          initialValues={data}
          render={(formEvents)=> (
            <form action="" onSubmit={formEvents.handleSubmit} noValidate>
              <C className='m-top-x2'>
                <p>{t('Para solicitar el aplazamiento de tus prácticas, tienes enviarnos la solicitud de aplazamiento, así como la documentación que justifique tu solicitud.')}</p>
                <p>{t('En cuanto la recibamos la enviaremos a la Consejería de Educación de la Comunidad de Madrid para que evalúe tu solicitud.')}</p>
                <p>{t('En cuanto sepamos la resolución nos pondremos en contacto contigo.')}</p>
                <p>{t('Podrás solicitar el aplazamiento hasta el 30 de septiembre en primera instancia y hasta el 31 de marzo en segunda instancia.')}</p>
                <ol className='m-top-x2'>
                  <li>{t('Descárgate la solicitud de aplazamiento:')} <a href={static_documents.deferral.url}>‘Anexo IV. Solicitud de aplazamiento’</a>.
                    { !isSpanishUser(shopId) &&
                        <AlertBox type={"info"}>
                          {t('fct_international_help')}
                          <a href={t('fct_request_postponement_international_help_document_link')}>{t('fct_request_postponement_international_help_document')}.</a>
                        </AlertBox>
                    }
                  </li>
                  <li>{t('Rellena la solicitud con tus datos.')}</li>
                  <li>{t('Adjunta la solicitud y los documentos que acrediten tu aplazamiento a continuación:')}</li>
                </ol>
                <Field
                  name='blocks.other_docs.documents.deferral'
                  label={t('Documento de aplazamiento')}
                  component={UploadDocument}
                  typeDocument='deferral'
                  contractId={id}
                  className='m-top'
                  specificError={t('Por favor, agrega el documento de aplazamiento.')}
                  required
                  validate={validationDocumentFCT}
                />
                <div className='m-top-x2'><hr className="separator-xxs"/></div>
                <div className='ftp-button-bar'>
                  <Link to={`/fct/${id}`}><ButtonGhost type='button'>{t('Cancelar')}</ButtonGhost></Link>
                  <ButtonSpinner type='submit' showSpinner={loading}> {t('Enviar solicitud')}</ButtonSpinner>
                </div>
              </C>
            </form>
          )}/>
      </F>
    </AppPage>
  )
}
