import {Component, Fragment, h} from "preact";

import '../theme/theme.scss';

import {BrowserRouter, useHistory} from "react-router-dom";
import {setLanguage, t} from "../_i18n";
import {connect, Provider} from "react-redux";

import {store} from "../_rx/store"; // store normal de la app
import {LANG_ES} from "../constants.js";

import {fetchUser, logout} from "../_rx/_auth/actions";
import {clearNotification} from "../_rx/_notify/actions";
import {Notify} from "../_common/components/notify";
import {fetchStatus, fetchStudent, setLanguageRedux} from "../_rx/student/actions";
import {fetchComunicadosStatus} from "../_rx/comunicados/actions";
import {fetchStatusPresta} from "../_rx/statusPresta/actions";

import {computeAppLinks, Header} from "./header-component";

import AppDispatch from "../_services/app-dispatch";
import {toastService} from "../_services/toast-service";
import {rndStr} from "../_common/common.ts";
import AuthService from "../_services/auth-service";
import {fetchCandidate} from "../_rx/jobs/actions";
import {RoutesEmpty, RoutesIsOnSite, RoutesNoTitle, RoutesNoValidate, RoutesValidated, RoutesNoSignedConditions} from "./user-roles-routes";
import Spinner from "../_common/components/Spinner";
import getLangByShopId from "../_common/utils/getLang";
import {
    STUDENT_IS_ON_SITE,
    STUDENT_NO_ENROLLS,
    STUDENT_NO_TITLE,
    STUDENT_NO_VALIDATED,
    STUDENT_TRIAL,
    STUDENT_VALIDATED,
    STUDENT_NO_SIGNED_CONDITIONS
} from './getUserRol'
import {getStaticDocuments, loginFct} from '../_rx/fct/actions'


const LoadBar = (show) => <div className={`progress-bar striped animated main-progress-bar ${show ? 'show' : ''}`}>
    <span className="progress-bar-blue full-width"></span>
</div>;

const LogoutPage = () => <p className="t-center">
    <br/> Sesión no valida. <br/>
    <button className="btn btn-primary submit auto-width" onClick={() => this.props.logout()}>
        Inicie sesión de nuevo
    </button>
</p>;

const ToastList = ({toasts}) => {
    if (!toasts.length) return;

    const types = {'info': 'success', 'danger': 'danger'};
    return <div className="toast-container top">
        {toasts.map(t => <div key={t.key} className={'toast ' + types[t.type]}>
            {t.type == 'info' ?
                <i class="fas fa-check-circle"/> :
                <i class="far fa-exclamation-circle"/>} &nbsp;
            {t.message}
        </div>)}
    </div>;
};


let App = class extends Component {

    history = useHistory();
    app_dispatch = new AppDispatch();
    state = {toasts: [], languageIsSet: false};

    async componentDidMount() {

        this.history.listen((location, action) => {
            // Apaño para trabajar con los modales y el scroll del body. TODO: se debería cambiar el sistema de modales para no depender del hash de URL (o depender
            // de él usando un prefijo o similar:
            document.querySelector('body').style.overflowY = location.hash ? 'hidden' : 'auto';
        });

        toastService.setCallback(t => this.showToastCB(t));

        this.app_dispatch.setDispatchFromAppCallback(async (action, payload) => {
            try {
                await this.dispatchFromApp(action, payload);
                return true;
            } catch (err) {
                console.log(err);
            }
        });

        await this.props.fetchUser();
        await this.props.fetchCandidate();
        await this.props.loginFct();
        if (!this.props.auth.loggedUser) return;

        await this.props.fetchStatus(this.props.auth.loggedUser.id);
        await this.props.fetchStatusPresta();
        await this.props.getStaticDocuments();
        this.props.fetchStudent(this.props.auth.loggedUser.id);
        this.props.fetchComunicadosStatus().catch(err => console.error(err));
        const lang = getLangByShopId(this.props.status.student_meta.shop_id)
        this.props.setLanguageRedux(lang)
        await setLanguage(lang);
        this.setState({languageIsSet: true});

        if (window.location.search.includes("password_reset_ok=true"))
            toastService.showToast({message: t('Tu contraseña se ha restablecido correctamente.'), type: 'info'});

    }

    showToastCB(t) {
        if (isMobileApp) {
            this.app_dispatch.dispatchToApp('showToast', JSON.stringify(t));
        } else {
            t.key = rndStr();
            this.setState({toasts: [...this.state.toasts, t]});
            setTimeout(() => {
                this.setState({toasts: this.state.toasts.filter(i => i !== t)});
            }, 5000);
        }
    }

    async dispatchFromApp(action, payload) {
        console.log('dispatchFromApp', action, payload);

        if (action == 'url') {
            if (payload.startsWith('http'))
                location.href = payload;
            else
                this.history.push(payload);
        } else if (action == 'exec') {
            if (payload === 'logout') {
                this.props.logout();
            } else {
                console.log('unknown action ' + action);
            }
        } else if (action == 'sendDeviceToken') {
            await AuthService.instance().sendDeviceToken(payload);
        }

    }

    render({
               notify,
               auth,
               loading,
               shop,
               clearNotification,
               status,
               comunicados_status,
               candidate,
               status_presta,
               lang,
               student_access
           }) {

        // if(auth.authFailed) return <LogoutPage />
        const isLoading = !auth.loggedUser || shop === null || status === null || !this.state.languageIsSet;
        const showInviteFriend = status_presta?.InviteFriendStatus?.status !== 'Disabled';
        const showGift = status?.student_meta.present && lang === LANG_ES

        // NOTIFICATIONS OVERRIDE
        // This provisional until we have a common API Gateway to unify notifications
        // We weirdly mix eCommerce notifications with the ones coming from academical APIs. According to the information
        // at november 2021, there can not be collisions in real scenarios, since Student notifications only exist when the enrolment
        // process is yet to be completed, while eCommerce notifications take place later in the life-cycle.
        let messages = null;
        if ((!notify || notify.length === 0) &&
            [STUDENT_VALIDATED, STUDENT_NO_TITLE].includes(student_access) &&
            status_presta && status_presta.Messages.length > 0) {

            messages = status_presta.Messages;

        } else {
            messages = notify;
        }



        let appLinks;
        let isInitialState;

        if (!isLoading) {
            appLinks = computeAppLinks(status, auth, student_access, comunicados_status, status_presta, lang, showGift);
        } else {
            appLinks = [];
        }

        if (!isLoading && isMobileApp) {
            appLinks.forEach(lnk => delete lnk.extra);

            this.app_dispatch.dispatchToApp('setLinks', JSON.stringify(appLinks));

            const permanents = messages.filter(n => !n.dismissible);
            if (permanents.length) {
                const appNotifs = permanents.map(({type, message}) => ({
                    type,
                    message: message.replace(/<[^>]+>/g, '')
                }));
                this.app_dispatch.dispatchToApp('setPermanentNotifications', JSON.stringify(appNotifs[0]));
            }
        }

        return <Fragment>
            {isWebApp && student_access !== STUDENT_NO_SIGNED_CONDITIONS && messages && <Notify notifs={messages} onClear={clearNotification}/>}
            {isWebApp && <ToastList toasts={this.state.toasts}/>}
            {/*isLoading*/}
            {isLoading ?
                <div id="loader-container">
                    <div className="text-loader"><Spinner/></div>
                </div> :
                <Fragment>
                    {isWebApp && <Header status={status} appLinks={appLinks} student_access={student_access}/>}
                    {/*<LoadBar show={loading.loading} />*/}

                    {/* ROUTES */}
                    {student_access === STUDENT_TRIAL && <RoutesEmpty/>}
                    {student_access === STUDENT_IS_ON_SITE &&
                        <RoutesIsOnSite candidate={candidate} studentMeta={status.student_meta}/>}
                    {student_access === STUDENT_NO_SIGNED_CONDITIONS && <RoutesNoSignedConditions studentMeta={status.student_meta}/>}
                    {student_access === STUDENT_NO_ENROLLS && <RoutesEmpty/>}
                    {student_access === STUDENT_NO_VALIDATED && <RoutesNoValidate studentMeta={status.student_meta}/>}
                    {student_access === STUDENT_NO_TITLE &&
                        <RoutesNoTitle studentMeta={status.student_meta} candidate={candidate}/>}
                    {student_access === STUDENT_VALIDATED &&
                        <RoutesValidated studentMeta={status.student_meta} candidate={candidate} showGift={showGift}
                                         showInviteFriend={showInviteFriend}/>}
                </Fragment>
            }
        </Fragment>;
    }

}


App = connect(
    ({
         notify,
         auth,
         loading,
         shop,
         status,
         comunicados_status,
         candidate,
         status_presta,
         student_access,
         lang
     }) => ({notify, auth, loading, shop, status, student_access, comunicados_status, candidate, status_presta, lang}),
    {
        fetchUser,
        clearNotification,
        fetchStatus,
        logout,
        fetchComunicadosStatus,
        fetchCandidate,
        fetchStatusPresta,
        setLanguageRedux,
        fetchStudent,
        getStaticDocuments,
        loginFct
    }
)(App);


// App con store normal
export default () => <BrowserRouter>
    <Provider store={store}>
        <App/>
    </Provider>
</BrowserRouter>;
