import React, {Fragment, h} from "preact";
import {t} from '../../../_i18n';
import {connect} from "react-redux";
import CardUpcomingClassesView from "../components/CardUpcomingClassesView";
import AlertBox from "../../../_common/components/AlertBox/AlertBox";


function UpcomingClasses({classesStatus}) {
    const showCurrentEvents = classesStatus.current?.formative_actions.length > 0 && !classesStatus.current?.ended_enroll_period
    const showNextEvents = classesStatus.next?.formative_actions.length > 0 && !classesStatus.next?.ended_enroll_period
    const no_pending_inscriptions_string = t('no_pending_inscriptions')
    const classes_current_limit_string = t('classes_date_limit').replace('{CLASSES_DATE_LIMIT}', classesStatus?.current?.end_date_join)
    const classes_next_limit_string = t('classes_date_limit').replace('{CLASSES_DATE_LIMIT}', classesStatus?.next?.end_date_join)

    const current_markdown = (showCurrentEvents &&
        <div>
            <p className='m-top-x2 m-bottom font-20 font-big-size font-color-486268'>{t('Clases de asignaturas que estás cursando')}:</p>
            { classesStatus?.current?.end_date_join &&
                <AlertBox type='info' className='m-top sm-no-m-top'>
                    <span dangerouslySetInnerHTML={{__html: classes_current_limit_string}}/>
                </AlertBox>
            }
            {classesStatus.current.formative_actions.map(event => <CardUpcomingClassesView event={event}/>)}
        </div>
    )

    const next_markdown = (showNextEvents &&
        <div>
            <p className='m-top-x2 m-bottom font-20 font-color-486268'>{t('Clases de asignaturas del próximo periodo')}:</p>
            { classesStatus?.next?.end_date_join &&
                <AlertBox type='info' className='m-top sm-no-m-top'>
                    <span dangerouslySetInnerHTML={{__html: classes_next_limit_string}}/>
                </AlertBox>
            }
            {classesStatus.next.formative_actions.map(event => <CardUpcomingClassesView event={event}/>)}
        </div>
    )

    return <Fragment>
        {!this.props.provisional_is_next &&
            <Fragment>
                {(showCurrentEvents || showNextEvents)
                    ?
                    <div className='m-bottom font-heavy font-30 font-color-486268'>{t('Inscripciones pendientes')}</div>
                    :
                    <AlertBox type='info' className='m-top sm-no-m-top'>
                        {no_pending_inscriptions_string}
                    </AlertBox>}
            </Fragment>
        }
        {!this.props.provisional_is_next && current_markdown}
        {this.props.provisional_is_next && next_markdown}
    </Fragment>
}

UpcomingClasses = connect(
    ({enrollmentClasses}) => ({
        classesStatus: enrollmentClasses.status
    }),
    {})(UpcomingClasses);


export default ({...rest}) => {
    return (<UpcomingClasses {...rest} />);
}
