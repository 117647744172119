import React, { Fragment, Component, h} from "preact";
import {connect} from "react-redux";
import {ButtonGhost, ButtonPrimary} from '../../../_common/components/buttons/buttons';
import Modal from "../../../_common/components/Modal/index";
import {setClassSelected, setShowGradesEnroll} from "../../../_rx/enrollment/classes/actions";
import {F, FR} from "../../../_common/layout";
import {t} from "../../../_i18n";
import TextBox from "../../../_common/components/textBox/textBox";
import {DateTime} from "luxon";
import '../../styles/modal-grades-styles.scss'


function ModalGrades({ setShowGradesEnroll, classAttendanceGrades, studentId }) {

    const visible = !!classAttendanceGrades;

    let date = DateTime.now();
    if (visible) {
        if (classAttendanceGrades.attendance_date_time) {
            date = DateTime.fromFormat(classAttendanceGrades.attendance_date_time, 'yyyy-MM-dd hh:mm:ss');
        } else if (classAttendanceGrades.event_calendar) {
            const stringDate = classAttendanceGrades.event_calendar.day + ' ' + classAttendanceGrades.event_calendar.start_date_hour;
            date = DateTime.fromFormat(stringDate, 'dd/MM/yyyy hh:mm');
        }
    }

    const closeModal = () => {
        setShowGradesEnroll(null)
    }

    return classAttendanceGrades &&  <Modal visible={visible} closeModal={closeModal}>
        <div className='modal-grades'>
            <div className='modal-grades__header'>
                <FR className={'modal-title-padding row-2-el p-all p-x2-left p-x2-right'}>
                    <div className='col-80'>
                        <p className="t-left font-18 no-margin"><b>{t('Detalle de la sesión')}</b></p>
                    </div>
                    <div className={'t-right col-20'}>
                        <a onClick={closeModal} className="modal-close-jobs modal-link"> <i className="fal fa-times font-20" /> </a>
                    </div>
                </FR>
            </div>
            <div className='modal-grades__body'>
                <div className='sm-d-flex'>
                    <TextBox className='full-width'>
                        <div className='d-flex flex-baseline-y p-all-half'>
                            <i className="fal fa-calendar-alt m-l-half font-color-879CA1"/>
                            <div className='m-l-half'>{date.setLocale('es').toLocaleString(DateTime.DATE_HUGE)}</div>
                        </div>
                    </TextBox>
                    <TextBox className='full-width sm-flex-right'>
                        <div className='d-flex flex-baseline-y p-all-half'>
                            <i className="fal fa-clock  m-l-half font-color-879CA1"/>
                            <div className='m-l-half'>{date.setLocale('es').toLocaleString(DateTime.TIME_24_SIMPLE)}</div>
                        </div>
                    </TextBox>
                </div>
                <div className='m-top sm-d-flex flex-bottom full-width flex-center-x'>
                    <div className='d-flex flex-baseline-y m-r '>
                        <p className='m-lr'>Asistencia:</p>
                        {classAttendanceGrades.attendance === true ? (
                            <Fragment>
                                <p className='font-bold m-r-half'>Si</p>
                                <i className="fal fa-check font-20 icon-color-green" />
                            </Fragment>
                            ) :
                            (
                            <Fragment>
                                <Fragment>
                                    <p className='font-bold m-r-half'>No</p>
                                    <i className="fal fa-times font-20 icon-color-red" />
                                </Fragment>
                            </Fragment>
                            )
                        }
                    </div>
                    <div className='d-flex flex-bottom full-width flex-space-around'>
                        {classAttendanceGrades.grade_uf1 &&
                            <div className='d-block'>
                                <p className='font-bold font-color-149BAB' >UF1</p>
                                <p className='d-flex flex-center-x font-bold'>{classAttendanceGrades.grade_uf1}</p>
                            </div>
                        }
                        { classAttendanceGrades.grade_uf2 &&
                            <div className='d-block'>
                                <p className='font-bold font-color-149BAB'>UF2</p>
                                <p className='d-flex flex-center-x font-bold'>{classAttendanceGrades.grade_uf2}</p>
                            </div>
                        }
                        { classAttendanceGrades.grade_uf3 &&
                            <div className='d-block'>
                                <p className='font-bold font-color-149BAB'>UF3</p>
                                <p className='d-flex flex-center-x font-bold'>{classAttendanceGrades.grade_uf3}</p>
                            </div>
                        }
                        { classAttendanceGrades.grade_uf4 &&
                            <div className='d-block'>
                                <p className='font-bold font-color-149BAB'>UF4</p>
                                <p className='d-flex flex-center-x font-bold'>{classAttendanceGrades.grade_uf4}</p>
                            </div>
                        }
                        { classAttendanceGrades.grade_uf5 &&
                            <div className='d-block'>
                                <p className='font-bold font-color-149BAB'>UF5</p>
                                <p className='d-flex flex-center-x font-bold'>{classAttendanceGrades.grade_uf5}</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='modal-grades__footer'>
                <div className='m-top m-bottom d-flex full-width flex-right'>
                    <ButtonGhost className='full-width sm-no-full-width' onClick={closeModal}>{t('CERRAR')}</ButtonGhost>
                </div>
            </div>
        </div>
    </Modal>
}

ModalGrades = connect(
    ({enrollmentClasses, auth}) => ({
        studentId: auth.loggedUser.id,
        classAttendanceGrades: enrollmentClasses.classAttendanceGrades
    }),
    {setShowGradesEnroll})(ModalGrades);

export default ({...rest}) => <ModalGrades {...rest}/>
