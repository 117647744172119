import { h } from "preact";
import './upload-document-styles.scss'
import { strHash } from '../../../_common/common'
import { deleteDocument, downloadFileFtp, saveDocument } from '../../../_rx/fct/actions'
import { toastService } from '../../../_services/toast-service'
import { FILE_ACCEPT_FILES, FILE_SIZE_MAX } from '../../constants'
import { t } from '../../../_i18n'

export default function UploadDocument ({ name, label, required, disabled, input, typeDocument, className, contractId, meta, description, allowRemove = true, showFile = true, specificError,  ...rest }) {
  const id = strHash(name+label);
  const showError = input.value.error_msg
    ? input.value.error_msg
    : (meta.invalid && meta.submitFailed)

  const handleRemove = () => {
    deleteDocument(input.value.id).then(() => input.onChange(null))
  }

  const handleChange = (e) => {
    const file = e.target.files[0]
    if (file.size < FILE_SIZE_MAX) {
      saveDocument(contractId, file, typeDocument)
        .then(document => {
          input.onChange(document)
        })
    }
    else toastService.showToast({message: 'El archivo debe ocupar menos de 5 MB', type:'danger'})
  }

  return (
    <div className={`fct-upload-document ${className} ${showError ? 'error' : ''}`}>
      <div className='fct-upload-document__label font-bold'>{label}{required && <span className='required'> *</span>}</div>
      {description && <p>{description}</p>}
      {input.value?.file_name && showFile && (
        <div className='fct-upload-document__value'>
          <div className='font-bold t-underline'><span className='cursor-pointer' onClick={() => downloadFileFtp(input.value.id, input.value.file_name)}>{input.value.file_name}</span></div>
          { allowRemove && <div className='font-bold t-underline cursor-pointer font-color-149BAB' onClick={handleRemove}>{t('Eliminar')}</div>}
        </div>
      )}
      <div className='m-top'><label className='btn btn-primary width-fit-content' htmlFor={!disabled && id}>{t('Subir archivo')}</label></div>
      {showError && <div className='fct-upload-document__error'>{specificError ? specificError : meta.error}</div>}
      <input type="file" id={id} onChange={handleChange} accept={FILE_ACCEPT_FILES} value='' />
    </div>
  )
}
