import {h} from 'preact'
import {AppPage} from '../_common/components/app-page'
import {C, F} from '../_common/layout'
import {t} from '../_i18n'
import './secretary-styles.scss'
import Card from '../_common/components/CardDashboard/Card'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'

export default function Secretary() {
    const student_meta = useSelector(state => state.status.student_meta)
    const history = useHistory()

    return (
        <AppPage title={t('Secretaría académica')} back={history.goBack} className="max-width-980">
            <F className="max-width-980">
                <C>
                    <div className='m-top secretary__panel'>
                        {/*<Card title={t('Solicitar título')} to='/academic-program-card' />*/}
                        {student_meta.is_on_site === false &&
                            <Card title='Solicitar certificado' to='/secretaria/solicitar-certificado'/>}
                    </div>
                </C>
            </F>
        </AppPage>
    )
}
