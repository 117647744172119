import { h } from "preact";
import { Link } from 'react-router-dom'
import AlertBox from '../../../_common/components/AlertBox/AlertBox'
import { useParams } from 'react-router'
import { ButtonGhost } from '../../../_common/components/buttons/buttons'
import { useState } from 'preact/hooks'
import Modal from '../../../_common/components/Modal/index'
import { SCHEDULE_FCT } from '../../../home/HelpSection/ModalHelp/constants'
import StepSchedule from '../../../home/HelpSection/ModalHelp/StepSchedule'
import { t } from '../../../_i18n'
import { useSelector } from 'react-redux'
import {isSpanishUser} from "../../../_common/utils/getLang";
import useFctMeta from "../../hooks/useFctMeta";

const ModalSchedule = ({ visible, onClose }) => (
  <Modal visible={visible} closeModal={onClose}>
    <div className='p-all'>
      <div className='d-flex flex-space-between'>
        <div className='font-30'>{t('Reservar cita')}</div>
        <div className='icon cursor-pointer' onClick={onClose}><i className='fal fa-times'/></div>
      </div>
      <div>
        <StepSchedule schedule={SCHEDULE_FCT} />
      </div>
    </div>
  </Modal>
)


export default function AlertContact () {
  const { id } = useParams()
  const [openModal, setOpenModal] = useState(false)
  const shopId = useSelector(state => state.status.student_meta.shop_id)
  const meta = useFctMeta(id)

  return (
    <AlertBox type='shadow' className='m-top'>
        {(() => {
            if (isSpanishUser(shopId) && meta.formative_zone === 'CAT') {
                return (
                    <p>{t('Si tienes alguna duda puedes')} <a href='https://storage.googleapis.com/fct_documents/FCT_Normativa_Online_2024.pdf' target='_blank' className='font-color-149BAB'>{t('consultar la normativa de FCT')}</a>.</p>
                );
            } else if(shopId == 24 || shopId == 44) {
                return (
                    <p>{t('Si tienes alguna duda puedes')} <Link to={{pathname: `/fct/${id}/normativa`}} className='txt-color-primary t-underline font-medium'>{t('consultar la normativa de FCT')}</Link>.</p>
                );
            } else if(meta.formative_zone === 'MAD') {
                return (
                    <p>{t('Si tienes alguna duda puedes')} <a href='https://soporte.ilerna.es/hc/es-es/articles/16319986715037-Normativa-Formaci%C3%B3n-en-Centros-de-Trabajo-MAD' target='_blank' className='font-color-149BAB'>{t('consultar la normativa de FCT')}</a>.</p>
                );
            }
        })()}

        <p>{t('También puedes contactar con nosotros a través del número')} <span
            className='font-bold'>{t('91 901 59 29')}</span>. {t('Nuestro horario de atención es de lunes a jueves, desde las 9:00 hasta las 14:00 y desde las 15:00 hasta las 17:30. Los viernes, estamos disponibles de 9:00 a 14:00. Si lo deseas, puedes reservar una cita telefónica y uno de nuestros gestores te llamará.')}</p>
        {isSpanishUser(shopId) && <ButtonGhost className='inverse' onClick={() => setOpenModal(true)}>{t('Reservar cita')}</ButtonGhost>}
      {openModal && <ModalSchedule onClose={() => setOpenModal(false)} visible={openModal} />}
    </AlertBox>
  )
}
