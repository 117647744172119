/**
 * Student Account
 * Student Account Api - Validation flow
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dmartinez@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import City from './City';
import Country from './Country';
import GenderType from './GenderType';
import Nee from './Nee';

/**
 * The StudentPersonalData model module.
 * @module model/StudentPersonalData
 * @version 1.0.0
 */
class StudentPersonalData {
    /**
     * Constructs a new <code>StudentPersonalData</code>.
     * Student data
     * @alias module:model/StudentPersonalData
     * @param name {String} 
     * @param firstName {String} 
     * @param gender {module:model/GenderType} 
     * @param birthDate {Date} 
     * @param nee {module:model/Nee} 
     */
    constructor(name, firstName, gender, birthDate, nee) { 
        
        StudentPersonalData.initialize(this, name, firstName, gender, birthDate, nee);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, name, firstName, gender, birthDate, nee) { 
        obj['name'] = name;
        obj['first_name'] = firstName;
        obj['gender'] = gender;
        obj['birth_date'] = birthDate;
        obj['nee'] = nee;
    }

    /**
     * Constructs a <code>StudentPersonalData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StudentPersonalData} obj Optional instance to populate.
     * @return {module:model/StudentPersonalData} The populated <code>StudentPersonalData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StudentPersonalData();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('shop_id')) {
                obj['shop_id'] = ApiClient.convertToType(data['shop_id'], 'Number');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('full_name')) {
                obj['full_name'] = ApiClient.convertToType(data['full_name'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('first_name')) {
                obj['first_name'] = ApiClient.convertToType(data['first_name'], 'String');
            }
            if (data.hasOwnProperty('last_name')) {
                obj['last_name'] = ApiClient.convertToType(data['last_name'], 'String');
            }
            if (data.hasOwnProperty('gender')) {
                obj['gender'] = GenderType.constructFromObject(data['gender']);
            }
            if (data.hasOwnProperty('birth_date')) {
                obj['birth_date'] = ApiClient.convertToType(data['birth_date'], 'Date');
            }
            if (data.hasOwnProperty('birth_city')) {
                obj['birth_city'] = City.constructFromObject(data['birth_city']);
            }
            if (data.hasOwnProperty('birth_city_name')) {
                obj['birth_city_name'] = ApiClient.convertToType(data['birth_city_name'], 'String');
            }
            if (data.hasOwnProperty('birth_country')) {
                obj['birth_country'] = Country.constructFromObject(data['birth_country']);
            }
            if (data.hasOwnProperty('nee')) {
                obj['nee'] = Nee.constructFromObject(data['nee']);
            }
            if (data.hasOwnProperty('locale')) {
                obj['locale'] = ApiClient.convertToType(data['locale'], 'String');
            }
            if (data.hasOwnProperty('nickname')) {
                obj['nickname'] = ApiClient.convertToType(data['nickname'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
StudentPersonalData.prototype['id'] = undefined;

/**
 * Last enroll shop id
 * @member {Number} shop_id
 */
StudentPersonalData.prototype['shop_id'] = undefined;

/**
 * @member {String} email
 */
StudentPersonalData.prototype['email'] = undefined;

/**
 * @member {String} full_name
 */
StudentPersonalData.prototype['full_name'] = undefined;

/**
 * @member {String} name
 */
StudentPersonalData.prototype['name'] = undefined;

/**
 * @member {String} first_name
 */
StudentPersonalData.prototype['first_name'] = undefined;

/**
 * @member {String} last_name
 */
StudentPersonalData.prototype['last_name'] = undefined;

/**
 * @member {module:model/GenderType} gender
 */
StudentPersonalData.prototype['gender'] = undefined;

/**
 * @member {Date} birth_date
 */
StudentPersonalData.prototype['birth_date'] = undefined;

/**
 * @member {module:model/City} birth_city
 */
StudentPersonalData.prototype['birth_city'] = undefined;

/**
 * @member {String} birth_city_name
 */
StudentPersonalData.prototype['birth_city_name'] = undefined;

/**
 * @member {module:model/Country} birth_country
 */
StudentPersonalData.prototype['birth_country'] = undefined;

/**
 * @member {module:model/Nee} nee
 */
StudentPersonalData.prototype['nee'] = undefined;

/**
 * @member {String} locale
 */
StudentPersonalData.prototype['locale'] = undefined;






export default StudentPersonalData;

