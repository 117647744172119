import React, {Component, Fragment, h} from "preact";
import GradeDetail from "../components/moduleModalDetail/gradeDetail"
import GradeReview from "../components/moduleModalDetail/gradeReview";
import {connect} from "react-redux";
import {EDUCATION_LAW_LOE} from "../expedientConstants";

export function ModuleModalDetail({params, handleSubmit, parentModule, educationLaw, modulesToShow, formativeZone}){
    const specific = params.specific
    const moduleId = params.specific.replace('dt-', '')
    const module = modulesToShow?.children.find(moduleChildren => moduleChildren.id === parseInt(moduleId))

    const moduleMeta = (educationLaw === EDUCATION_LAW_LOE)
                ? {abbr: parentModule.abbr, title: parentModule.title, rawModule: parentModule}
                : {abbr: module?.title, title: module?.title, rawModule: module}

    return (specific.includes('dt'))
        ? module && <GradeDetail module={module} formativeZone={formativeZone}/>
        : <GradeReview handleSubmit={handleSubmit} moduleMeta={moduleMeta}/>

}

ModuleModalDetail = connect(
    ({expedient: {educationLaw}}) => ({educationLaw}),
    {})(ModuleModalDetail);

let ModuleModalDetailContainer = ({...props}) => <ModuleModalDetail {...props}/>


export default ModuleModalDetailContainer;
