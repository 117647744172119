import {globalCatch} from "../../../_services/global-catch";
import {svcPsService} from "../../../_services/ps-service";
import {SET_STATUS_PRESTA} from "./constants";


export const setStatusPresta = payload => ({type: SET_STATUS_PRESTA, payload});


export const fetchStatusPresta = () =>
    globalCatch.catch(async (dispatch) => {
        const status = await (await svcPsService()).getStatusPresta();
        dispatch(setStatusPresta(status));
    })

