import React, {Component, Fragment, h} from "preact";
import {t} from "../../../_i18n";
import Dropdown from "../../../_common/components/dropdown";
import {connect} from "react-redux";
import {useEffect, useState} from "preact/hooks";
import {ModalGradeCertificate} from "./modalGradeCertificate";
import {ModalAttendanceReceipt} from "./modalAttendanceReceipt";
import {isSpanishUser} from "../../../_common/utils/getLang";
import {downloadAttendance, downloadCertificate} from "../../../_rx/expediente/actions";
import DropdownBadge from '../../../_common/components/DropdownBadge/DropdownBadge';

let ExpedienteMenu = ({academicProgramId, academicProgram, idShop, lang, studentId}) =>{
    const [showGradeCertificate, setShowGradeCertificate] = useState(false)
    const [showAttendanceReceipt, setShowAttendanceReceipt] = useState(false)
    const allowGradeCertificate = academicProgram.allow_to_download_grade_certificate
    const allowAttendanceReceipt = academicProgram.allow_to_download_attendance;

    const closeModal = () => {
        setShowGradeCertificate(false)
        setShowAttendanceReceipt(false)
    }

    const handleGradeCertificate = () => {
        isSpanishUser(idShop)
            ? setShowGradeCertificate(true)
            : downloadCertificate({studentId, opt: lang, academicProgram})
    }

    const handleAttendanceReceipt = () => {
        isSpanishUser(idShop)
            ? setShowAttendanceReceipt(true)
            : downloadAttendance({studentId, opt: lang})
    }

    return (
        <div>
            <DropdownBadge type='default' title={
                <Fragment>
                    <span className='d-none sm-d-line'><i className="far fa-file-download font-color-555555" />&nbsp;&nbsp;{t('Descargar')}&nbsp;</span>
                    <span className='sm-d-none'><i className="far fa-file-download font-color-555555" /></span>
                </Fragment>
            }>
                { allowGradeCertificate &&
                  <div className='dropdown-item p-left p-right' onClick={handleGradeCertificate}>
                      {t('Certificado de notas')}
                  </div>
                }
                { allowAttendanceReceipt &&
                  <div className='dropdown-item p-left p-right' onClick={handleAttendanceReceipt}>
                      {t('Justificante de asistencia a exámenes')}
                  </div>
                }
            </DropdownBadge>

            <ModalGradeCertificate academicProgram={academicProgram} visible={showGradeCertificate} closeModal={closeModal}/>
            <ModalAttendanceReceipt visible={showAttendanceReceipt} closeModal={closeModal}/>

        </div>

    )


}

ExpedienteMenu = connect(
    ({status, lang, auth: {loggedUser}}) => ({idShop: status.student_meta.shop_id, lang, studentId : loggedUser.id}),
    {})(ExpedienteMenu);

let ExpedienteMenuContainer = ({...props}) => <ExpedienteMenu {...props}/>

export default ExpedienteMenuContainer;
