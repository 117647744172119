import React, {Fragment, h} from "preact";
import {Card, CF} from "../../../_common/layout";
import AlertBox from "../../../_common/components/AlertBox/AlertBox";
import UpcomingExams from "./UpcomingExams";
import {AppPage} from "../../../_common/components/app-page";
import {getStatusExams, setExamSelected, setEnrollPacs, checkEvauDocumentationStatus, getEvauDocumentationStatus} from '../../../_rx/enrollment/exams/actions/index'
import {connect, useSelector} from "react-redux";
import {t} from '../../../_i18n';
import Tabs from "../components/Tabs";
import {useParams} from "react-router";
import {useEffect, useState} from "preact/hooks";
import {useHistory} from "react-router-dom";
import Sidebar from "./sidebar";
import HistoricalExams from "./historicalExams";
import Loading from "../../../_common/components/loading/Loading";
import ModalCancel from "../components/ModalCancel";
import HeaderExams from '../components/HeaderExams';
import ModalPACs from "../components/ModalPACs";
import DisabledPage from "../../../disabled-page/disabledPage";
import ModalEvau from "../components/ModalEvau";
import {isSpanishUser} from "../../../_common/utils/getLang";

function Exams({studentId, setExamSelected, examsStatus, getStatusExams}) {
    const [historyEventsStatus, setHistoryEventsStatus] = useState('next')
    const [sideBar, setSideBar] = useState(false)
    const [showEvauAlert, setShowEvauAlert] = useState(false)
    const [evauState, setEvauState] = useState({documents: [], hasValidatedDocuments: false})
    const [showEvauModal, setShowEvauModal] = useState(false);
    const history = useHistory()
    const {action} = useParams()
    const student_meta = useSelector(state => state.status.student_meta);

    useEffect(async () => {
        history.push('/examenes')
        await getStatusExams(studentId)
        if (examsStatus?.available_evau_file_upload && examsStatus?.available_evau_file_upload?.lower_open) {
            const date = new Date();
            const startString = String(examsStatus.available_evau_file_upload.lower_open);
            const endString = String(examsStatus.available_evau_file_upload.higher_close);
            const startDate = startString !== 'Invalid Date' ? new Date(startString.replace(' ', 'T')) : new Date();
            const endDate = endString !== 'Invalid Date' ? new Date(endString.replace(' ', 'T')) : new Date();
            if (date > startDate && date < endDate) {
                const state = await getEvauDocumentationStatus(studentId)
                if (state.errors?.body?.code === 403 || !isSpanishUser(student_meta?.shop_id)) {
                    setShowEvauAlert(false)
                } else {
                    setEvauState(state);
                    setShowEvauAlert(true)
                }
            }
        }
    }, [])

    useEffect(() => {
        (action) ? setSideBar(true) : setSideBar(false)
    }, [action])

    const handleCloseModal = () => {
        history.push('/examenes')
        setSideBar(false)
        setExamSelected(null)
    }

    const handleCloseEvauModal = () => {
        setShowEvauModal(false)
    }

    if (!examsStatus)
        return (
            <CF className="max-width-980">
                <Loading label='Cargando...'/>
            </CF>
        )

    const no_pending_inscriptions = (examsStatus?.current.ended_enroll_period && examsStatus.current.formative_actions.length === 0)
        && (examsStatus?.next.ended_enroll_period && examsStatus.next.formative_actions.length === 0)

    return (
        <CF className="max-width-980">
            <Sidebar visible={sideBar} handleCloseModal={handleCloseModal} historyEventsStatus={historyEventsStatus}/>
            <ModalCancel/>
            <ModalPACs/>
            <HeaderExams/>
            { showEvauAlert &&
                <Fragment>
                    <ModalEvau
                        studentId={studentId}
                        visible={showEvauModal}
                        evauState={evauState}
                        setEvauState={setEvauState}
                        closeModal={handleCloseEvauModal}
                    />
                    <AlertBox type={evauState.hasValidatedDocuments ? 'success' : 'warning'} className="m-bottom">
                        <p>
                            <i className={evauState.hasValidatedDocuments ? 'far fa-check-circle m-r-half' : 'far fa-exclamation-circle m-r-half'}/>
                            {evauState.hasValidatedDocuments ?
                                t('evau_alert_documents_validated')
                                :
                                evauState.documents.length > 0 ?
                                    t('evau_alert_documents_pending_validation')
                                    :
                                    t('evau_alert_banner')
                            }
                            {!evauState.hasValidatedDocuments && evauState.documents.length < 1 &&
                                <span className='m-l-half font-bold t-underline cursor-pointer' onClick={() => setShowEvauModal(true)}>{t('evau_modal_link')}</span>
                            }
                        </p>
                    </AlertBox>
                </Fragment>
            }
            {no_pending_inscriptions &&
                <AlertBox type='info' className='m-top sm-no-m-top'>
                    <span>{t('no_pending_inscriptions')}</span>
                </AlertBox>
            }
            <UpcomingExams provisional_is_next={false}/>
            <div className='m-x2-top'>
                <Tabs onClick={setHistoryEventsStatus}/>
                <HistoricalExams status={historyEventsStatus}/>
            </div>
            <div className='m-top-x4'>
                <UpcomingExams provisional_is_next={true}/>
            </div>
        </CF>
    )
}


Exams = connect(
    ({auth, enrollmentExams}) => ({
        studentId: auth.loggedUser.id,
        examsStatus: enrollmentExams.examsStatus,
        enrollToCancel: enrollmentExams.enrollToCancel
    }),
    {getStatusExams, setExamSelected})(Exams);


export default ({...rest}) => {
    const disabled = false;
    return (
        <AppPage className="max-width">
            {disabled && <DisabledPage/>}
            {!disabled && <Exams {...rest}/>}
        </AppPage>
    );
}
