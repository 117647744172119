

export const prepareFetch = params => {
    const lastParam = params.pop().split('-')
    const hasChild = lastParam[0] === 'ch'
    const id = lastParam[1]
    return {hasChild, id}
}

export const countParamsNum = params => {

}