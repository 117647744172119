import React, { Fragment, h } from "preact";
import {FormField} from "../../../_common/forms/components/components";
import {t} from "../../../_i18n";
import {useState} from "preact/compat";
import {E} from "../../../_common/layout";
import {showErrorClosed, validateMail} from "../validateForms";

export default ({input, meta}) => {
    const [mail1, setMail1] = useState('')
    const [mail2, setMail2] = useState('')

    const handleChange = (text, type) => {
        const isMail1 = (type === 'mail1')
        isMail1 ? setMail1(text) : setMail2(text)
        input.onChange({
            mail1: isMail1 ? text : mail1,
            mail2: isMail1 ? mail2 : text
        })
    }

    return (
        <Fragment>
            <E className='col-70'>
                <FormField
                    name="email"
                    label={t('Introduce el e-mail de tu amig@:')}
                    required
                    validate={validateMail}
                    specificError={t('El e-mail no es correcto.')}
                    onChange={(text) => handleChange(text, 'mail1')}

                />
            </E>
            <div className='m-top' />
            <E className='col-70'>
                <FormField
                    name="repeatEmail"
                    label={t('Repite el e-mail:')}
                    required
                    validate={validateMail}
                    specificError={t('El e-mail no es correcto.')}
                    onChange={(text) => handleChange(text, 'mail2')}
                />
            </E>

            {showErrorClosed(meta) && <E className='font-color-danger'>{meta?.error}</E>}
        </Fragment>
    )
}
