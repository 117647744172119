import React, {Component, Fragment, h} from "preact";
import MatriculaDetalle from "../historial-matriculas/pages/matricula-detalle";
import {Redirect, Route} from "react-router-dom";
import {HomePageView} from "../home/home-page";
import DireccionEnvio from "../direccion-envio/pages/index-page";
import {CameraShot} from "../_common/components/camera-shot";
import ChangePassword from "../student-settings/pages/password";
import HistorialMatriculas from "../historial-matriculas";
import Jobs from "../jobs/jobs";
import Terms from "../jobs/pages/terms";
import EnrollmentProcessPage from "../student/pages/enrollment-process-page";
import Student from "../student-settings/pages/student";
import SignaturePage from "../student/pages/signature-page";
import FormPage from "../student/pages/form-page";
import Nee from "../student-settings/pages/nee";
import SendCopyPage from "../student/pages/send-copy";
import Phone from "../student-settings/pages/phone";
import CourseAccessPage from "../student/pages/course-access-page";
import OtherDocsPage from "../student/pages/other-docs-page";
import Comunicados from "../comunicados/comunicados";
import {Comunicado} from "../comunicados/comunicado";
import Exams from "../enrollment/exams/pages/index";
import configureNotifications from "../student-settings/pages/configureNotifications/configureNotifications";
import AcademicProgramCard from "../academic-program-card";
import AddressConfig from '../student-settings/pages/address'
import InitialFormNif from "../student/pages/nif/initialFormNif";
import StudentConfigurationNif from "../student/pages/nif/studentConfigurationNif";
import InviteFriend from '../inviteFriend'
import Gift from '../gift'
import {LANG_ES} from "../constants";
import getLangByShopId from "../_common/utils/getLang";
import Expediente from '../expediente/pages/expediente'
import ExpedienteCache from '../expediente/pages/expedienteCache/expedienteCache';
import Fct from '../fct'
import OnlineArea from '../OnlineArea/OnlineArea'
import Classes from '../enrollment/classes/pages/index'
import Secretary from '../secretary/Secretary'
import RequestCertificate from '../secretary/RequestCertificate'
import Nickname from "../student-settings/pages/nickname";

export const RoutesNoSignedConditions = () => <Fragment>
    <Route path="/start" exact><Redirect to="/"/></Route>
    <Route path="/inicio" exact><Redirect to="/"/></Route>
    <Route path="/student" exact><Redirect to="/"/></Route>    
    <Route path="/student/signature" exact><Redirect to="/"/></Route>      
    <Route path="/" exact component={SignaturePage} />
</Fragment>

export const RoutesEmpty = () => <Fragment>
    <Route path="/start" exact><Redirect to="/"/></Route>
    <Route path="/inicio" exact><Redirect to="/"/></Route>
    <Route path="/" exact component={HomePageView}></Route>
    <Route path="/enrollments/" exact component={HistorialMatriculas}/>
    <Route path="/enrollments/:param" component={MatriculaDetalle}/>
</Fragment>

export const RoutesIsOnSite = ({ studentMeta, candidate }) => <Fragment>
    <Route path="/start" exact><Redirect to="/"/></Route>
    <Route path="/inicio" exact><Redirect to="/"/></Route>
    <Route path="/" exact component={HomePageView} />
    <Route path="/enrollments/" exact component={HistorialMatriculas}/>
    <Route path="/enrollments/:param" component={MatriculaDetalle}/>
    <Route path="/address" component={DireccionEnvio} />
    {getLangByShopId(studentMeta.shop_id) === LANG_ES &&
      <Route path="/jobs/" component={candidate.accept_privacy_policy ? Jobs : Terms}/>}
</Fragment>

export const RoutesNoValidate = ({ studentMeta }) => <Fragment>
    <Route path="/start" exact><Redirect to="/"/></Route>
    <Route path="/inicio" exact><Redirect to="/"/></Route>
    <Route path="/student/signature" exact><Redirect to="/"/></Route>
    <Route path="/" exact><Redirect to="/student"/></Route>
    { studentMeta.has_whatsapp && <Fragment>
        <Route path="/comunicados" exact component={Comunicados} />
        <Route path="/notices" exact component={Comunicado}/>
        <Route path="/notices/:param" exact component={Comunicado}/>
    </Fragment>}
    <Route path="/student" exact component={EnrollmentProcessPage}/>
    <Route path="/student/form" component={FormPage} />
    <Route path="/student/send-copy" component={SendCopyPage}/>
    <Route path="/student/nif" component={InitialFormNif}/>
    <Route path="/student/course-access" component={CourseAccessPage} />
    <Route path="/student/other-docs" exact component={OtherDocsPage}/>
    <Route path="/address" component={DireccionEnvio}/>
    <Route path="/enrollments/" exact component={HistorialMatriculas}/>
    <Route path="/enrollments/:param" component={MatriculaDetalle}/>
    <Route path="/academic-program-card" exact component={AcademicProgramCard}/>
</Fragment>


export const RoutesNoTitle = ({ studentMeta, candidate }) => <Fragment>
    <Route path="/start" exact><Redirect to="/"/></Route>
    <Route path="/inicio" exact><Redirect to="/"/></Route>
    <Route path="/student/signature" exact><Redirect to="/"/></Route>
    <Route path="/notices/:param" exact component={Comunicado}/>
    <Route path="/" exact component={HomePageView} />
    <Route path="/student" exact component={EnrollmentProcessPage}/>
    <Route path="/student/form" component={FormPage} />
    <Route path="/student/send-copy" component={SendCopyPage}/>
    <Route path="/student/nif" component={InitialFormNif}/>
    <Route path="/student/course-access" component={CourseAccessPage} />
    <Route path="/student/other-docs" exact component={OtherDocsPage}/>
    <Route path="/address" component={DireccionEnvio}/>
    <Route path="/fct/:id" component={Fct}/>
    <Route path="/enrollments/" exact component={HistorialMatriculas}/>
    <Route path="/enrollments/:param" component={MatriculaDetalle}/>
    <Route path="/academic-program-card" exact component={AcademicProgramCard}/>
    <Route path="/clases/:action?" component={Classes} />
    <Route path="/examenes/:action?" component={Exams} />
    <Route path="/expediente/:module_id?/:specific?/:action?" component={studentMeta.show_expedient ? Expediente : ExpedienteCache }/>
    {getLangByShopId(studentMeta.shop_id) === LANG_ES &&
      <Route path="/jobs/" component={candidate.accept_privacy_policy ? Jobs : Terms}/>}
    <Route path="/online-area" exact component={OnlineArea} />
    <Route path="/secretaria" exact component={Secretary}/>
    <Route path="/secretaria/solicitar-certificado" exact component={RequestCertificate}/>
</Fragment>


export const RoutesValidated = ({ studentMeta, candidate, showInviteFriend, showGift }) => <Fragment>
    <Route path="/start" exact><Redirect to="/"/></Route>
    <Route path="/inicio" exact><Redirect to="/"/></Route>
    <Route path="/online-area" exact component={OnlineArea} />
    <Route path="/" exact component={HomePageView} />
    <Route path="/address" component={DireccionEnvio}/>
    <Route path="/camera" component={CameraShot}/>
    <Route path="/enrollments/" exact component={HistorialMatriculas}/>
    <Route path="/enrollments/:param" component={MatriculaDetalle}/>
    {getLangByShopId(studentMeta.shop_id) === LANG_ES &&
      <Route path="/jobs/" component={candidate.accept_privacy_policy ? Jobs : Terms}/>}
    {showInviteFriend && <Route path="/invite" component={InviteFriend}/>}
    {showGift && <Route path="/gift" component={Gift}/>}
    <Route path="/student" exact component={Student}/>
    <Route path="/fct/:id" component={Fct}/>
    <Route path="/student/address" component={AddressConfig}/>
    <Route path="/student/phone" component={Phone}/>
    <Route path="/student/nickname" component={Nickname}/>
    <Route path="/student/nee" component={Nee}/>
    <Route path="/secretaria" exact component={Secretary}/>
    <Route path="/secretaria/solicitar-certificado" exact component={RequestCertificate}/>
    <Route path="/student/id-document" component={StudentConfigurationNif}/>
    <Route path="/student/password" component={ChangePassword}/>
    <Route path="/notices" exact component={Comunicado}/>
    <Route path="/notices/:param" exact component={Comunicado}/>
    <Route path="/expediente/:module_id?/:specific?/:action?" component={studentMeta.show_expedient ? Expediente : ExpedienteCache }/>
    <Route path="/clases/:action?" component={Classes} />
    <Route path="/examenes/:action?" component={Exams} />
    <Route path="/notifications" component={configureNotifications}/>
    <Route path="/academic-program-card" exact component={AcademicProgramCard}/>
</Fragment>
