import React, {Fragment, h} from "preact";
import {Link} from "react-router-dom";
import {t} from '../../_i18n';
import {Card, E, FR, R} from "../../_common/layout";

export const CartaPresentacion = ({candidate}) =>
    <Fragment>
        <Card smOnly={false}>
            <FR className="flex-center-y">
                <div className="col-100 sm-col-65">
                    <h1 className="no-margin">{t('Carta de presentación')}</h1>
                </div>
                {candidate.letters.length > 0 && <div className="sm-col-35 sm-d-flex d-none flex-right">
                    <Link to={`./candidate/letter/new`} className="no-decoration text-info t-upper">
                        <button className={'btn btn-ghost'}>{t('Añadir Carta')}</button>
                    </Link>
                </div>
                }
                {candidate.letters.map((element) =>
                    <Fragment>
                        <E className="full-width">
                            <Link to={`./candidate/letter/` + element.id.toString()}>
                                {element.name}
                            </Link>
                        </E>
                    </Fragment>
                )}
                {candidate.letters.length > 0 && <div className="col-100 sm-d-none d-flex flex-left">
                    <Link to={`./candidate/letter/new`} className="no-decoration text-info t-upper">
                        <button className={'btn btn-ghost'}>{t('Añadir Carta')}</button>
                    </Link>
                </div>
                }
                {!candidate.letters.length && <Fragment>
                    <div className="col-100 sm-col-100">
                        <p className="m-bottom-half m-top-half">{t('Todavía no tienes ninguna carta.')}</p>
                    </div>
                    <div className="col-100 sm-col-100">
                        <Link to={`./candidate/letter/new`} className="no-decoration text-info t-upper">
                            <button className={'btn btn-primary'}>{t('Añadir Carta')}</button>
                        </Link>
                    </div>
                </Fragment>
                }
            </FR>
        </Card>
        <div className="p-bottom" />
    </Fragment>
