/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CompanyChangeEmail from '../model/CompanyChangeEmail';
import CompanyChangePassword from '../model/CompanyChangePassword';
import CompanyLogin from '../model/CompanyLogin';
import DefaultResponse from '../model/DefaultResponse';
import LoginResponse from '../model/LoginResponse';

/**
* CompanyAuth service.
* @module api/CompanyAuthApi
* @version v1
*/
export default class CompanyAuthApi {

    /**
    * Constructs a new CompanyAuthApi. 
    * @alias module:api/CompanyAuthApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Cambiar email de un compañia ya logada
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyChangeEmail} opts.companyChangeEmail 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DefaultResponse} and HTTP response
     */
    companyChangeEmailPostWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['companyChangeEmail'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DefaultResponse;
      return this.apiClient.callApi(
        '/company/change_email', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Cambiar email de un compañia ya logada
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyChangeEmail} opts.companyChangeEmail 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DefaultResponse}
     */
    companyChangeEmailPost(opts) {
      return this.companyChangeEmailPostWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Metodo para que la empresa pueda logar
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyLogin} opts.companyLogin 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LoginResponse} and HTTP response
     */
    companyLoginPostWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['companyLogin'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = LoginResponse;
      return this.apiClient.callApi(
        '/company/login', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Metodo para que la empresa pueda logar
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyLogin} opts.companyLogin 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LoginResponse}
     */
    companyLoginPost(opts) {
      return this.companyLoginPostWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Metodo para que la empresa pueda deslogar
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LoginResponse} and HTTP response
     */
    companyLogoutPostWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = LoginResponse;
      return this.apiClient.callApi(
        '/company/logout', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Metodo para que la empresa pueda deslogar
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LoginResponse}
     */
    companyLogoutPost() {
      return this.companyLogoutPostWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Password olvidado
     * @param {String} email Email company
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DefaultResponse} and HTTP response
     */
    companyLostPasswordPostWithHttpInfo(email) {
      let postBody = null;
      // verify the required parameter 'email' is set
      if (email === undefined || email === null) {
        throw new Error("Missing the required parameter 'email' when calling companyLostPasswordPost");
      }

      let pathParams = {
      };
      let queryParams = {
        'email': email
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = DefaultResponse;
      return this.apiClient.callApi(
        '/company/lost_password', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Password olvidado
     * @param {String} email Email company
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DefaultResponse}
     */
    companyLostPasswordPost(email) {
      return this.companyLostPasswordPostWithHttpInfo(email)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Cambiar contraseña de un compañia ya logada
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyChangePassword} opts.companyChangePassword 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DefaultResponse} and HTTP response
     */
    companyPasswordPostWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['companyChangePassword'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DefaultResponse;
      return this.apiClient.callApi(
        '/company/password', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Cambiar contraseña de un compañia ya logada
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyChangePassword} opts.companyChangePassword 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DefaultResponse}
     */
    companyPasswordPost(opts) {
      return this.companyPasswordPostWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Password olvidado
     * @param {String} token Token
     * @param {String} newPassword New Password
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DefaultResponse} and HTTP response
     */
    companyResetPasswordTokenPostWithHttpInfo(token, newPassword) {
      let postBody = null;
      // verify the required parameter 'token' is set
      if (token === undefined || token === null) {
        throw new Error("Missing the required parameter 'token' when calling companyResetPasswordTokenPost");
      }
      // verify the required parameter 'newPassword' is set
      if (newPassword === undefined || newPassword === null) {
        throw new Error("Missing the required parameter 'newPassword' when calling companyResetPasswordTokenPost");
      }

      let pathParams = {
        'token': token
      };
      let queryParams = {
        'new_password': newPassword
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = DefaultResponse;
      return this.apiClient.callApi(
        '/company/reset_password/{token}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Password olvidado
     * @param {String} token Token
     * @param {String} newPassword New Password
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DefaultResponse}
     */
    companyResetPasswordTokenPost(token, newPassword) {
      return this.companyResetPasswordTokenPostWithHttpInfo(token, newPassword)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
