import React, {Component, Fragment, h} from "preact";
import {AppPage} from "../../_common/components/app-page";
import {t} from "../../_i18n";
import {connect} from "react-redux";
import {
    fetchAcademicPrograms,
    fetchCandidate,
    fetchOffer,
    clearOffer,
    fetchSchedules,
    fetchStates,
    saveEnroll, fetchContractTypes
} from "../../_rx/jobs/actions";
import {Card, CF, E, FR, R, RE} from "../../_common/layout";
import {Link} from "react-router-dom";
import {formatCurrency, formatDDMMYYYY, rndStr} from "../../_common/common";
import {OfferRequirements} from "../components/offer_requirements";
import {ModalLink} from "../../_common/components/modal";
import {ModalEnroll} from "../components/modal_enroll";
import {candidate} from "../../_rx/jobs/reducers";
import {fixtures} from "../../_rx/student/reducers";
import AlertBox from "../../_common/components/AlertBox/AlertBox";
import Badge from "../../_common/components/Badge";


const lengths = {
    "Indefinido": "Contrato indefinido ",
    "Bruto al año": "Bruto/año",
}

const statuses = {
    "applied": t("Te has inscrito a la oferta."),
    "selected": t("La empresa ha seleccionado tu CV."),
    "accepted": t("La empresa ha preseleccionado tu CV."),
    "discarded": t("Tu CV no ha sido seleccionado."),
    "is_remote_process": t("Proceso Online")
}

const statuses_text = {
    "applied": "icon-color-green",
    "selected": "icon-color-green",
    "accepted": "icon-color-green",
    "discarded": "icon-color-red"
}

const icons_statuses = {
    "applied": "fal fa-check-circle icon-color-green",
    "selected": "fal fa-check-circle icon-color-green",
    "accepted": "fal fa-check-circle icon-color-green",
    "discarded": "fal fa-times-circle icon-color-red"
}

const statuses_badges = {
    "applied": "badge-info",
    "selected": "badge-success",
    "accepted": "badge-notice",
    "discarded": "badge-danger",
    "is_remote_process": "badge-proceso"
}

let OfferView = class extends Component {

    id1 = rndStr();

    async componentDidMount() {
        this.props.fetchOffer(this.props.match.params.id);

        if(!this.props.candidate)
            this.props.fetchCandidate();

        if(!this.props.offers_fixtures.academic_programs)
            this.props.fetchAcademicPrograms();

        if(!this.props.offers_fixtures.schedules)
            this.props.fetchSchedules();

        if(!this.props.offers_fixtures.states)
            this.props.fetchStates();

        if(!this.props.offers_fixtures.contract_types)
            this.props.fetchContractTypes();
    }

    async onApply(data){

        const application = {
            offer: this.props.match.id,
            letter: data.letter,
            questions: Object.keys(data.answers).map(questionId=>{
                const value = data.answers[questionId]
                const q = this.props.offer.questions.find(q=>q.id==questionId);
                const answer_text = q.type=='closed'? null : value;
                const answer_id = q.type=='closed'? value : null;
                return {id:questionId, answer_text, answer_id}
            })
        }

        await this.props.saveEnroll(this.props.match.params.id, application).then(()=> history.back()).catch(err=> ({'error': true}));
    }

    componentWillUnmount() {
        this.props.clearOffer()
    }

    render = ({offer, offers_fixtures, candidate, history})  => {
        const IS_REMOTE_JOB = offer?.presence === 'remote'


        return offer && candidate &&
            <Fragment>
                <CF className="max-width-980 p-x2-bottom">
                    <nav className="breadcrumb-wrapper p-x2-bottom">
                        <div className="back breadcrumb-item cursor-pointer" onClick={() => history.goBack()}>← {t('Volver')}</div>
                    </nav>
                    {(!offer || !offers_fixtures.academic_programs || !offers_fixtures.schedules || !offers_fixtures.states || !offers_fixtures.contract_types) ?
                        <p className="t-center">
                            <em className="disabled-aspect-gray"> {t('Cargando')}. . . </em>
                        </p> :
                        <Fragment>
                            {/*<pre>{JSON.stringify(offer, null, '  ')}</pre>*/}
                            <Card className="sm-p-bottom sm-m-bottom">
                                <FR>
                                    <div className="col-70 md-col-80">
                                        <h1 className="no-margin font-medium m-bottom">{offer.title}</h1>
                                        <div className="card-text">
                                            { offer.display_company_name ?
                                                <Link to={`/jobs/company/${offer.company.id}`} className="no-decoration text-info t-upper">{offer.company.name} </Link>
                                                : <span>{offer.company_name} </span>}
                                            <span className="translucent"> - {IS_REMOTE_JOB ? t('Teletrabajo') : `${offer.city} (${offers_fixtures.states[offer.state]})` }</span>
                                            <p>
                                                <span className="font-medium"> {offers_fixtures.contract_types[offer.contract_type]} <span className="translucent"> - </span></span>
                                                {offer.length && <span>{offer.length} <span className="translucent"> - </span></span>}
                                                <span className="translucent"> {offers_fixtures.schedules[offer.schedule]} </span>
                                                {
                                                    offer.salary_min && offer.salary_max ?
                                                        <span> - {formatCurrency(offer.salary_min, 0)} - {formatCurrency(offer.salary_max, 0)} </span> :
                                                        offer.salary_min ?
                                                            ' - ' + formatCurrency(offer.salary_min, 0) :
                                                            offer.salary_max ?
                                                                ' - ' + formatCurrency(offer.salary_max, 0) :
                                                                ''
                                                }
                                                {offer.salary_type && <span> { offer.salary_type}</span>}
                                                {offer.max_jobs && <div className='d-inline'> - <span className='translucent'>{`${offer.max_jobs} ${(offer.max_jobs > 1) ? t('vacantes') : t('vacante')}`}</span></div>}
                                                <div>
                                                    {(offer.modality === 'fct') && <span className='m-r m-top-half sm-m-r no-margin-bottom font-medium'>{t('Formación en centro de trabajo')}</span>}
                                                    {offer.is_remote_process && <Badge type={statuses_badges["is_remote_process"]} className="m-r sm-m-r m-top-half" >{statuses["is_remote_process"]}</Badge>}
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-30 md-col-20 d-flex flex-column flex-bottom">
                                        {offer.company.logo
                                          ? <div className="card m-bottom image-squared" style={`background-image:url('${window.micuentaenv.JOBS_API_basePath}/company/view-logo/${offer.company.logo}');`} />
                                          : <div className="card image-squared d-flex flex-center-x flex-center-y" ><i className="fal fa-building font-color-b8c7cc font-30"/></div>
                                        }
                                    </div>
                                </FR>
                                {!offer.events.length ?
                                    <Fragment>
                                        <R className="flex-right full-width d-none sm-d-flex no-margin-left">
                                            { candidate.has_filled_cv && offer.academic_matching
                                                ? <ModalLink id={this.id1}><button className={'btn btn-primary'}>{t('Inscribirme en esta oferta')}</button></ModalLink>
                                                : <button className={'btn btn-primary'} type="button" disabled={true}>{t('Inscribirme en esta oferta')}</button>
                                            }
                                        </R>
                                    </Fragment>
                                    : offer.events.map(event =>
                                        <Fragment>
                                            <hr className="separator-xxs d-none md-d-block"/>
                                            <R className="row-2-el d-none sm-d-flex">
                                                <E>
                                                    <i className={icons_statuses[event.type]} />
                                                    <span className={statuses_text[event.type] + " m-l"}>{statuses[event.type]}</span>
                                                </E>
                                                <E className="d-flex flex-right">
                                                    <span className="translucent">{formatDDMMYYYY(event.date)}</span>
                                                </E>
                                            </R>
                                            <hr className="separator-xxs d-block md-d-none no-margin"/>
                                            <div className="d-flex sm-d-none col-100 flex-baseline-y no-margin">
                                                <div className="col-10">
                                                    <i className={icons_statuses[event.type]} />
                                                </div>
                                                <div className="col-90">
                                                    <R>
                                                        <E>
                                                            <span className={statuses_text[event.type]}>{statuses[event.type]}</span>
                                                        </E>
                                                    </R>
                                                    <R>
                                                        <E>
                                                            <span className="translucent">{formatDDMMYYYY(event.date)}</span>
                                                        </E>
                                                    </R>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}
                            </Card>
                            {!candidate.has_filled_cv && <AlertBox type='warning' className="m-bottom-half">{t("Para poder inscribirte en la oferta debes ")}<Link to={"/jobs/candidate"}>{t('adjuntar tu CV, o indicar tus perfiles de InfoJobs o LinkedIn.')}</Link></AlertBox>}
                            <OfferRequirements modalId={this.id1} offer={offer} academic_programs={offers_fixtures.academic_programs} candidate={candidate} onEnroll={data => this.onApply(data)}/>
                            {!candidate.has_filled_cv && <AlertBox type='warning' className="m-top-half">{t("Para poder inscribirte en la oferta debes ")}<Link to={"/jobs/candidate"}>{t('adjuntar tu CV, o indicar tus perfiles de InfoJobs o LinkedIn.')}</Link></AlertBox>}
                            <div className="p-top flex-right d-none sm-d-flex">
                                <span>{t('Oferta publicada:')} {formatDDMMYYYY(offer.date)}</span>
                            </div>
                            <div className="p-top flex-left d-flex sm-d-none">
                                <span>{t('Oferta publicada:')} {formatDDMMYYYY(offer.date)}</span>
                            </div>
                        </Fragment>
                    }
                </CF>
                <ModalEnroll id={this.id1} offer={offer} candidate={candidate} onEnroll={data => this.onApply(data)}/>
                {/*<pre>{JSON.stringify(offer, null, '  ')}</pre>*/}
                {!offer.events.length ?
                        candidate.has_filled_cv && offer.academic_matching ?
                        <Fragment>
                            <div className="d-block cta-mobile">
                                <ModalLink id={this.id1}>
                                    <button className={'btn btn-primary btn-cta sm-d-none'} type="button">{t('Inscribirme en esta oferta')}</button>
                                </ModalLink>
                            </div>
                        </Fragment>
                        :
                        <Fragment>
                            <div className="d-block cta-mobile background-white">
                                <button className={'btn btn-primary btn-cta sm-d-none'} type="button" disabled={true}>{t('Inscribirme en esta oferta')}</button>
                            </div>
                        </Fragment>
                    : ''
                }
            </Fragment>
    }


}

OfferView = connect(
    ({offer, candidate, offers_fixtures, enroll})=>({offer, candidate, offers_fixtures, enroll}), {fetchOffer, clearOffer, fetchAcademicPrograms, fetchContractTypes, fetchCandidate, fetchSchedules, fetchStates, saveEnroll})(OfferView);


const OfferPage = ({history, match}) =>
    <AppPage
        name="offer"
        title=""
        subtitle=""
        className="max-width-980">
        <OfferView match={match} history={history}/>
    </AppPage>
;

export default OfferPage;
