import { h } from "preact";
import AlertCollapse from '../../../_common/components/AlertCollapse/AlertCollapse'
import { ButtonPrimary } from '../../../_common/components/buttons/buttons'
import { Fragment } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import {downloadFileFtp, fetchAllDocuments} from "../../../_rx/fct/actions";
import {useEffect, useState} from "preact/hooks";
import { useParams } from 'react-router'
import { t } from '../../../_i18n'


export default function AlertSurvey ({ className }) {
  const { pathname } = useLocation()
  const { id } = useParams()
  const [data, setData] = useState(null)

    useEffect(() => {
        fetchAllDocuments(id).then((data) => setData(data))
    }, [])

  return (
    <Fragment>
      <AlertCollapse title={t('👋 Nos tienes que enviar las valoraciones finales de las prácticas')} className={className}>
        <li style="margin-bottom: 18px;">
            {t('Descárgate la plantilla de valoraciones finales:')}
            <span
                className='ftp-link font-bold cursor-pointer'
                onClick={() => downloadFileFtp(
                    data.blocks.other_docs.documents.final_evaluation_template.id,
                    data.blocks.other_docs.documents.final_evaluation_template.file_name
                    )
                }>
                ‘Valoraciones finales.pdf’
            </span>
        </li>
        <Link
            to={`${pathname}/encuesta`}>
            <ButtonPrimary>
                {t('Subir valoraciones finales')}
            </ButtonPrimary>
        </Link>
      </AlertCollapse>
    </Fragment>
  )
}
