import {FR} from "../../../_common/layout";
import {connect} from "react-redux";
import {t} from "../../../_i18n";
import {Fragment, h} from "preact";
import Modal from "../../../_common/components/Modal";
import {setEnrollPacs, setExamSelected} from "../../../_rx/enrollment/exams/actions";
import '../../styles/modal-pacs-styles.scss'

function ModalPACs({ enrollPacs, examSelected, setEnrollPacs, setExamSelected }) {
    const visible = !!enrollPacs;

    let isMobile = window.innerWidth < 768;

    const closeModal = () => {
        setEnrollPacs(null);
        setExamSelected(null);
    }

    const getRecomendation = ({percentage_presented_pacs, ce_grade, all_pacs_available}) => {

        if (!all_pacs_available || !ce_grade) {
            return '-'
        } else {
            if (percentage_presented_pacs >= 80 && ce_grade >= 5) {
                return 'recomendacion_80_aprobado_ec'
            } else if (percentage_presented_pacs >= 80 && ce_grade < 5) {
                return 'recomendacion_80_suspenso_ec'
            } else if (percentage_presented_pacs < 80 && ce_grade >= 5) {
                return 'recomendacion_no_80_aprobado_ec'
            } else if (percentage_presented_pacs < 80 && ce_grade < 5) {
                return 'recomendacion_no_80_suspenso_ec'
            } else {
                return '-'
            }
        }
    }

    return (
        <Modal visible={visible} closeModal={closeModal} bodyClass='sm-m-top'>
            <div className='modal-pacs'>
                <div className='modal-pacs__header'>
                    <FR className={'modal-title-padding row-2-el p-x2-top p-x2-left p-x2-right'}>
                        <div className='col-90'>
                            <p className="t-left f-w-700 font-32 no-margin"><b>{t('recomendaciones_ec_titulo')}</b></p>
                        </div>
                        <div className={'t-right col-10'}>
                            <a onClick={closeModal} className="modal-close-jobs modal-link"> <i className="fal fa-times font-20" /> </a>
                        </div>
                    </FR>
                </div>
                <div className='modal-pacs__body'>
                    <div>
                        <p>{t('recomendaciones_ec_desc')}</p>
                    </div>
                    <div>
                        <p className='f-w-700 font-20'>{examSelected?.formative_action_title}</p>
                    </div>
                    { isMobile ?
                    <div className='full-width'>
                        <hr className="separator-xxs no-margin-top"/>
                        { enrollPacs && enrollPacs.map( (uf, index) =>
                            <div className={`d-block p-top p-bottom-half  no-padding-left no-padding-top border-radius-5 ${ index % 2 === 0 ? 'background-soft-gray' : ''}`}>
                                { uf.UF !== null &&
                                <div className='col-100 no-margin-bottom no-margin-top font-18'>
                                    <p className='bold f-w-700 no-margin-bottom no-margin-top'>{`${uf.UF}${uf.all_pacs_available ? '' : '*'}`}</p>
                                </div>
                                }
                                <div className='full-width d-flex'>
                                    <div className='col-50 no-margin-bottom'>
                                        <div className='font-12'>
                                            <p className='bold no-margin-bottom no-margin-top'>{t('porcentaje_pacs')}</p>
                                        </div>
                                        <div className='d-flex no-margin-top font-18'>
                                            <p className='bold f-w-700 m-r-half no-margin-top no-margin-bottom'>{uf.percentage_presented_pacs}%</p>
                                            <p className='font-color-soft-gray font-14 no-margin-top no-margin-bottom d-flex flex-align-self-end'>({uf.presented_pacs} de {uf.total_pacs})</p>
                                        </div>
                                    </div>
                                    <div className='col-50 no-margin-bottom'>
                                        <div className='d-flex font-12'>
                                            <p className='bold m-r-half no-margin-bottom no-margin-top'>{t('nota_ec')}</p>
                                        </div>
                                        <div className='no-margin-top font-18'>
                                            <p className='bold f-w-700 no-margin-top no-margin-bottom'>{uf.ce_grade ?? '-'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-100'>
                                    <p className='no-margin-top no-margin-bottom'>{t(getRecomendation(uf))}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    :
                    <div className='full-width table'>
                        <div className='sm-d-flex flex-space-around table__header'>
                            { enrollPacs && enrollPacs[0].UF &&
                            <div className='max-width-sm uf'>
                                <p className='bold'>{t('UF')}</p>
                            </div>
                            }
                            <div className='max-width-sm percentage'>
                                <p className='bold'>{t('porcentaje_pacs')}</p>
                            </div>
                            <div className='max-width-sm grade'>
                                <p className='bold'>{t('nota_ec')}</p>
                            </div>
                            <div className='max-width-sm grade-recomendation'>
                                <p className='bold'>{t('recomendacion_segun_nota_y_pacs_entregadas')}</p>
                            </div>
                        </div>
                        <hr className="separator-xxs no-margin-top"/>
                        { enrollPacs && enrollPacs.map( (uf, index) =>
                            <div className={`sm-d-flex border-radius-5 flex-space-around ${index % 2 === 0 ? 'background-soft-gray' : ''}`}>
                                { uf.UF !== null &&
                                <div className='max-width-sm uf'>
                                    <p className='bold f-w-700'>{`${uf.UF}${uf.all_pacs_available ? '' : '*'}`}</p>
                                </div>
                                }
                                <div className='max-width-sm percentage'>
                                    <p className='bold f-w-700 m-r-half'>{uf.percentage_presented_pacs}%</p>
                                    <p className='font-color-soft-gray'>({uf.presented_pacs} de {uf.total_pacs})</p>
                                </div>
                                <div className='max-width-sm grade'>
                                    <p className='bold f-w-700'>{uf.ce_grade ?? '-'}</p>
                                </div>
                                <div className='max-width-sm grade-recomendation__body'>
                                    <p>{t(getRecomendation(uf))}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    }
                    <hr className="separator-xxs no-margin-top"/>
                </div>
                <div className='modal-pacs__footer'>
                    <div className='m-bottom d-flex full-width flex-left'>
                        <p>*{t('faltan_pacs_en_uf')}</p>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

ModalPACs = connect(
    ({enrollmentExams, auth}) => ({
        studentId: auth.loggedUser.id,
        enrollPacs: enrollmentExams.enrollPacs,
        examSelected: enrollmentExams.examSelected
    }),
    { setEnrollPacs, setExamSelected } )(ModalPACs);

export default ({...rest}) => <ModalPACs {...rest}/>
