import { h } from "preact";
import './AlertBox.scss'

interface Props {
    className?: string,
    children: JSX.Element,
    type: 'info' | 'warning' | 'danger' | 'default' | 'success' | 'dashed' | 'shadow' | 'commitment-fct';
}

export default function AlertBox ({type = 'info', className = '', children}: Props){

    return (
        <div className={`alert alert-${type} ${className}`}>{children}</div>
    )
}
