import React, {Component, Fragment, h} from "preact";
import {useHistory} from "react-router";
import {t} from "../../../_i18n";
import { ShowGrade, ShowGradeCae, ShowTerms } from "./gradeCalculation";
import {useEffect, useState} from "preact/hooks";
import {
    fetchSubjectOfferDetails,
    setReviewParams
} from "../../../_rx/expediente/actions";
import {connect} from "react-redux";
import {EDUCATION_LAW_LOE, EDUCATION_LAW_LOGSE} from "../../expedientConstants";
import GradeBox from "../../../_common/components/noticeModule/gradeBox";
import {Tooltip} from "../../../_common/components/Tooltip/Tooltip";
import ButtonReview from "../buttonReview";
import AlertMessage from './AlertMessage.js'
import AlertBox from "../../../_common/components/AlertBox/AlertBox";


let GradeDetail = ({module, studentId, educationLaw, formativeZone, ...props}) => {
    const [subjectOfferDetails, setSubjectOfferDetails] = useState(null)

    useEffect(async () => {
        const response = await props.fetchSubjectOfferDetails(studentId, module.formative_action_id, module.id, Number(module.hidden_grade))
        setSubjectOfferDetails(response);
        if (educationLaw === EDUCATION_LAW_LOGSE){
            const paramsReview = {enroll_id: response.enroll_id, exam_convocation_id: response.last_exam_convocation?.id}
            props.setReviewParams(paramsReview)
        }
    }, [])

    return subjectOfferDetails &&
        <Fragment>
            <div className='font-14'>{subjectOfferDetails.abbr}</div>
            <h2 className='font-24 line-height-28 black-weight m-bottom m-top-half'>
                {subjectOfferDetails.title}
            </h2>
            <div className='card p-top p-left p-right'>
                <ModuleRow
                    label={subjectOfferDetails.grade}
                    approved={subjectOfferDetails.approved}
                    gradeBox
                    //gradeTooltip={subjectOfferDetails.recognized && t('Convalidada')}
                    >
                    {t('Nota final')}
                </ModuleRow>
                <hr className="separator-xxs"/>
                <Fragment>
                {
                    (subjectOfferDetails.has_compulsory_classes && subjectOfferDetails.in_class_grade) &&
                  <Fragment>
                    <ModuleRow grade={subjectOfferDetails.in_class_grade.grade}>
                        {t('Clases presenciales realizadas')}
                        {subjectOfferDetails.in_class_grade && <span className='translucent'> {subjectOfferDetails.in_class_grade.grade_factor}%</span>}
                    </ModuleRow>
                    <hr className="separator-xxs"/>
                  </Fragment>
                }
                {
                    (subjectOfferDetails.has_done_compulsory_classes && !subjectOfferDetails.in_class_grade ) &&
                    <Fragment>
                        <ModuleRow showCheck check={subjectOfferDetails.has_done_compulsory_classes}>
                            {t('Clases presenciales realizadas')}
                            {subjectOfferDetails.in_class_grade && <span className='translucent'> {subjectOfferDetails.in_class_grade.grade_factor}%</span>}
                        </ModuleRow>
                        <hr className="separator-xxs"/>
                    </Fragment>
                }
                </Fragment>
                {
                    subjectOfferDetails.grades &&
                    subjectOfferDetails.grades.map((sbGrade, index) => <div key={index}>
                        <ModuleRow grade={sbGrade.grade}>{sbGrade.name} {sbGrade.grade_factor && <span className='translucent'>{sbGrade.grade_factor}%</span>}</ModuleRow>
                        {subjectOfferDetails.grades.length -1 !== index && <hr className="separator-xxs"/>}
                    </div>)
                }
                {(educationLaw === EDUCATION_LAW_LOGSE) && <ButtonReview allowRevision={subjectOfferDetails.allow_revision} revisionSubmitted={subjectOfferDetails.revision_submitted} />}
                {subjectOfferDetails.grades && <hr className="separator-xxs"/>}
                <div className='d-flex flex-space-between sm-flex-center-y flex-column sm-no-flex-column p-bottom'>
                    <div><span className='translucent'>{subjectOfferDetails.enrollment_academic_section_title}</span></div>
                    <div className={'black-weight m-top sm-no-m-top'}>
                        {subjectOfferDetails.last_exam_convocation && <div className='badge badge-warning '>{subjectOfferDetails.last_exam_convocation.exam_convocation_type_title}</div>}
                    </div>
                </div>
            </div>
            {!subjectOfferDetails.approved && !module.hidden_grade && <AlertMessage subjectOffer={subjectOfferDetails} />}
            {module.hidden_grade &&
                <AlertBox type='info' className='m-top'>
                    {module.is_training_work_center && t('fct_grade_missing_project_grade_tooltip')}
                    {!module.is_training_work_center && t('project_grade_missing_fct_grade_tooltip')}
                </AlertBox>
            }
            <div className='m-top'>
                <ShowTerms educationLaw={educationLaw} formative_zone={formativeZone} />
            </div>
        </Fragment>
}


const ModuleRow = ({children, label, grade, gradeBox, approved, showCheck, check, gradeTooltip = ''}) => {
    const gradeStyle = grade >= 5 ? 'font-color-09b072' : 'font-color-ac2002'

    const renderGrade = () => {
        return (gradeTooltip === '') ? <GradeBox grade={label} approved={approved} />
            : <Tooltip text={gradeTooltip}>
                <GradeBox grade={label} approved={approved} />
            </Tooltip>
    }

    return (
        <div className='d-flex flex-space-between flex-center-y'>
            <div className='font-18'>{children}</div>
            {gradeBox
                ? renderGrade()
                : <div className={'notice-module__notice nobg d-flex flex-center-y flex-center-x black-weight '}>
                    {
                        showCheck
                            ? check ? <i className="far fa-check font-color-09b072 font-20"/> :
                                <i className="far fa-times font-color-ac2002 font-20"/>
                            : <div className={gradeStyle}>{grade}</div>
                    }
                </div>
            }

        </div>
    )
}


GradeDetail = connect(
    ({auth: {loggedUser}, expedient: {educationLaw}}) => ({educationLaw, studentId: loggedUser.id}),
    {fetchSubjectOfferDetails, setReviewParams})(GradeDetail);

let GradeDetailContainer = ({...props}) => <GradeDetail {...props}/>


export default GradeDetailContainer;
