import React, { Fragment, h} from "preact";
import {Route} from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux'
import FctHome from './pages/home'
import { cleanStatus, fetchMessages, fetchStatus, setMessages } from '../_rx/fct/actions'
import { useEffect } from 'preact/hooks'
import { Documentation } from './pages/documentation/documentation'
import { Terms } from './pages/Terms/terms'
import { Redirect } from 'react-router'
import ContributeCompany from './pages/ContributeCompany'
import RequestPostponement from './pages/RequestPostponement'
import Survey from './pages/Survey'
import useFctMeta from './hooks/useFctMeta'
import {DateTime} from "luxon";

function FctRoutes ({ match }) {
  const contactId = match.params.id
  const dispatch = useDispatch()
  const meta = useFctMeta(contactId)

  const { state, screenStatus } = useSelector(state => ({
    state: state.fct.state,
    screenStatus: state.fct.screenState
  }))

  const dateHardLimit = DateTime.fromISO(screenStatus?.screen_data?.document_limits?.hard_limit_date);
  const isOpenDocumentation = screenStatus?.screen_data?.oppened_docs && DateTime.now() <= dateHardLimit;

  useEffect(() => {
    dispatch(fetchMessages(contactId))
    dispatch(fetchStatus(contactId))
    return () => dispatch(cleanStatus())
  }, [contactId])

  if (!state) return <div />
  return <Fragment>
    {
      meta.accepted
        ? <Fragment>
            <Route path={match.path} component={FctHome} exact/>
            {
              isOpenDocumentation
                ? <Route path={`${match.path}/documentacion`} component={Documentation} exact/>
                : <Route path={`${match.path}/documentacion`} exact><Redirect to={`/fct/${contactId}`} /></Route>
            }
            <Route path={`${match.path}/normativa`} component={Terms} exact/>
            <Route path={`${match.path}/aportar-empresa`} component={ContributeCompany} exact/>
            <Route path={`${match.path}/solicitar-aplazamiento`} component={RequestPostponement} exact/>
            <Route path={`${match.path}/encuesta`} component={Survey} exact/>
          </Fragment>
        : <Fragment>
            <Route path={match.path} exact><Redirect to={`/fct/${contactId}/normativa`} /></Route>
            <Route path={`${match.path}/documentacion`} exact><Redirect to={`/fct/${contactId}/normativa`} /></Route>
            <Route path={`${match.path}/aportar-empresa`} exact><Redirect to={`/fct/${contactId}/normativa`} /></Route>
            <Route path={`${match.path}/solicitar-aplazamiento`} exact><Redirect to={`/fct/${contactId}/normativa`} /></Route>
            <Route path={`${match.path}/encuesta`} exact><Redirect to={`/fct/${contactId}/normativa`} /></Route>
            <Route path={`${match.path}/normativa`} component={Terms} exact/>
          </Fragment>
    }
  </Fragment>
}

FctRoutes = connect(
  ({}) => ({}),
  {})(FctRoutes);

let Fct = ({match, ...props}) => <FctRoutes match={match} {...props}/>

export default Fct;
