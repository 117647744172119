/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The UserResponse model module.
 * @module model/UserResponse
 * @version 1.0
 */
class UserResponse {
    /**
     * Constructs a new <code>UserResponse</code>.
     * @alias module:model/UserResponse
     */
    constructor() { 
        
        UserResponse.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>UserResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserResponse} obj Optional instance to populate.
     * @return {module:model/UserResponse} The populated <code>UserResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserResponse();

            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('body')) {
                obj['body'] = ApiClient.convertToType(data['body'], 'String');
            }
            if (data.hasOwnProperty('number_attachments')) {
                obj['number_attachments'] = ApiClient.convertToType(data['number_attachments'], 'Number');
            }
            if (data.hasOwnProperty('first_open')) {
                obj['first_open'] = ApiClient.convertToType(data['first_open'], 'String');
            }
            if (data.hasOwnProperty('received_at')) {
                obj['received_at'] = ApiClient.convertToType(data['received_at'], 'String');
            }
        }
        return obj;
    }


}

/**
 * Class UserResponse
 * @member {String} uuid
 */
UserResponse.prototype['uuid'] = undefined;

/**
 * Class UserResponse
 * @member {String} title
 */
UserResponse.prototype['title'] = undefined;

/**
 * Class UserResponse
 * @member {String} body
 */
UserResponse.prototype['body'] = undefined;

/**
 * Class UserResponse
 * @member {Number} number_attachments
 */
UserResponse.prototype['number_attachments'] = undefined;

/**
 * Class UserResponse
 * @member {String} first_open
 */
UserResponse.prototype['first_open'] = undefined;

/**
 * Class UserResponse
 * @member {String} received_at
 */
UserResponse.prototype['received_at'] = undefined;






export default UserResponse;

