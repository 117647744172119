/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AcademicProgram from './AcademicProgram';
import State from './State';

/**
 * The CompanyApplicationFilers model module.
 * @module model/CompanyApplicationFilers
 * @version v1
 */
class CompanyApplicationFilers {
    /**
     * Constructs a new <code>CompanyApplicationFilers</code>.
     * Item que muestra los filtros a mostrar
     * @alias module:model/CompanyApplicationFilers
     */
    constructor() { 
        
        CompanyApplicationFilers.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CompanyApplicationFilers</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CompanyApplicationFilers} obj Optional instance to populate.
     * @return {module:model/CompanyApplicationFilers} The populated <code>CompanyApplicationFilers</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CompanyApplicationFilers();

            if (data.hasOwnProperty('show_general')) {
                obj['show_general'] = ApiClient.convertToType(data['show_general'], 'Boolean');
            }
            if (data.hasOwnProperty('show_academic_program_scopes')) {
                obj['show_academic_program_scopes'] = ApiClient.convertToType(data['show_academic_program_scopes'], 'Boolean');
            }
            if (data.hasOwnProperty('academic_program_scope_list')) {
                obj['academic_program_scope_list'] = ApiClient.convertToType(data['academic_program_scope_list'], [AcademicProgram]);
            }
            if (data.hasOwnProperty('show_finished_percent')) {
                obj['show_finished_percent'] = ApiClient.convertToType(data['show_finished_percent'], 'Boolean');
            }
            if (data.hasOwnProperty('show_killer_grade')) {
                obj['show_killer_grade'] = ApiClient.convertToType(data['show_killer_grade'], 'Boolean');
            }
            if (data.hasOwnProperty('show_states')) {
                obj['show_states'] = ApiClient.convertToType(data['show_states'], 'Boolean');
            }
            if (data.hasOwnProperty('state_list')) {
                obj['state_list'] = ApiClient.convertToType(data['state_list'], [State]);
            }
        }
        return obj;
    }


}

/**
 * @member {Boolean} show_general
 */
CompanyApplicationFilers.prototype['show_general'] = undefined;

/**
 * @member {Boolean} show_academic_program_scopes
 */
CompanyApplicationFilers.prototype['show_academic_program_scopes'] = undefined;

/**
 * @member {Array.<module:model/AcademicProgram>} academic_program_scope_list
 */
CompanyApplicationFilers.prototype['academic_program_scope_list'] = undefined;

/**
 * @member {Boolean} show_finished_percent
 */
CompanyApplicationFilers.prototype['show_finished_percent'] = undefined;

/**
 * @member {Boolean} show_killer_grade
 */
CompanyApplicationFilers.prototype['show_killer_grade'] = undefined;

/**
 * @member {Boolean} show_states
 */
CompanyApplicationFilers.prototype['show_states'] = undefined;

/**
 * @member {Array.<module:model/State>} state_list
 */
CompanyApplicationFilers.prototype['state_list'] = undefined;






export default CompanyApplicationFilers;

