/**
 * New Expedient
 * Expedient for Student Account
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from './ApiClient';
import AcademicProgram from './model/AcademicProgram';
import AcademicProgramItem from './model/AcademicProgramItem';
import AcademicProgramSchoolCenter from './model/AcademicProgramSchoolCenter';
import ArrayOfFormativeActionsWithParent from './model/ArrayOfFormativeActionsWithParent';
import ArrayOfSubjectOfferItemsWithParent from './model/ArrayOfSubjectOfferItemsWithParent';
import Certificate from './model/Certificate';
import Error from './model/Error';
import FormativeAction from './model/FormativeAction';
import RecognizedNew from './model/RecognizedNew';
import SubjectOffer from './model/SubjectOffer';
import SubjectOfferGrade from './model/SubjectOfferGrade';
import SubjectOfferInClassGrade from './model/SubjectOfferInClassGrade';
import SubjectOfferItem from './model/SubjectOfferItem';
import SubjectOfferLastExamConvocation from './model/SubjectOfferLastExamConvocation';
import SubjectOfferwithParent from './model/SubjectOfferwithParent';
import AcademicProgramsApi from './api/AcademicProgramsApi';
import FormativeActionsApi from './api/FormativeActionsApi';
import SubjectOffersApi from './api/SubjectOffersApi';


/**
* Expedient_for_Student_Account.<br>
* The <code>index</code> module provides access to constructors for all the classes which comprise the public API.
* <p>
* An AMD (recommended!) or CommonJS application will generally do something equivalent to the following:
* <pre>
* var NewExpedient = require('index'); // See note below*.
* var xxxSvc = new NewExpedient.XxxApi(); // Allocate the API class we're going to use.
* var yyyModel = new NewExpedient.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* <em>*NOTE: For a top-level AMD script, use require(['index'], function(){...})
* and put the application logic within the callback function.</em>
* </p>
* <p>
* A non-AMD browser application (discouraged) might do something like this:
* <pre>
* var xxxSvc = new NewExpedient.XxxApi(); // Allocate the API class we're going to use.
* var yyy = new NewExpedient.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* </p>
* @module index
* @version 1.0
*/
export {
    /**
     * The ApiClient constructor.
     * @property {module:ApiClient}
     */
    ApiClient,

    /**
     * The AcademicProgram model constructor.
     * @property {module:model/AcademicProgram}
     */
    AcademicProgram,

    /**
     * The AcademicProgramItem model constructor.
     * @property {module:model/AcademicProgramItem}
     */
    AcademicProgramItem,

    /**
     * The AcademicProgramSchoolCenter model constructor.
     * @property {module:model/AcademicProgramSchoolCenter}
     */
    AcademicProgramSchoolCenter,

    /**
     * The ArrayOfFormativeActionsWithParent model constructor.
     * @property {module:model/ArrayOfFormativeActionsWithParent}
     */
    ArrayOfFormativeActionsWithParent,

    /**
     * The ArrayOfSubjectOfferItemsWithParent model constructor.
     * @property {module:model/ArrayOfSubjectOfferItemsWithParent}
     */
    ArrayOfSubjectOfferItemsWithParent,

    /**
     * The Certificate model constructor.
     * @property {module:model/Certificate}
     */
    Certificate,

    /**
     * The Error model constructor.
     * @property {module:model/Error}
     */
    Error,

    /**
     * The FormativeAction model constructor.
     * @property {module:model/FormativeAction}
     */
    FormativeAction,

    /**
     * The RecognizedNew model constructor.
     * @property {module:model/RecognizedNew}
     */
    RecognizedNew,

    /**
     * The SubjectOffer model constructor.
     * @property {module:model/SubjectOffer}
     */
    SubjectOffer,

    /**
     * The SubjectOfferGrade model constructor.
     * @property {module:model/SubjectOfferGrade}
     */
    SubjectOfferGrade,

    /**
     * The SubjectOfferInClassGrade model constructor.
     * @property {module:model/SubjectOfferInClassGrade}
     */
    SubjectOfferInClassGrade,

    /**
     * The SubjectOfferItem model constructor.
     * @property {module:model/SubjectOfferItem}
     */
    SubjectOfferItem,

    /**
     * The SubjectOfferLastExamConvocation model constructor.
     * @property {module:model/SubjectOfferLastExamConvocation}
     */
    SubjectOfferLastExamConvocation,

    /**
     * The SubjectOfferwithParent model constructor.
     * @property {module:model/SubjectOfferwithParent}
     */
    SubjectOfferwithParent,

    /**
    * The AcademicProgramsApi service constructor.
    * @property {module:api/AcademicProgramsApi}
    */
    AcademicProgramsApi,

    /**
    * The FormativeActionsApi service constructor.
    * @property {module:api/FormativeActionsApi}
    */
    FormativeActionsApi,

    /**
    * The SubjectOffersApi service constructor.
    * @property {module:api/SubjectOffersApi}
    */
    SubjectOffersApi
};
