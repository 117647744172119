import {createReducer} from "../../../../_common/common.ts";
import {
  SET_CLASS_SELECTED,
  SET_HISTORICAL_CLASSES,
  SET_CANCEL_ENROLL_CLASS,
  SET_LOADING_HISTORICAL_CLASSES,
  SET_CLASSES_STATUS,
  SET_CLASS_ATTENDANCE_GRADES_CLASS
} from '../actions/constants'

const initialState = {
  classSelected: null,
  status: null,
  historicalClasses: null,
  loadingHistoricalClasses: false,
  enrollToCancel: null
}

export const enrollmentClasses = createReducer(initialState, {
  [SET_CLASS_SELECTED]: (state, classSelected)=>{
    state.classSelected = classSelected;
    return state
  },
  [SET_CLASSES_STATUS]: (state, status)=>{
    state.status = status;
    return state
  },
  [SET_HISTORICAL_CLASSES]: (state, historicalClasses)=>{
    state.historicalClasses = historicalClasses;
    return state
  },
  [SET_LOADING_HISTORICAL_CLASSES]: (state, loadingHistoricalClasses)=>{
    state.loadingHistoricalClasses = loadingHistoricalClasses;
    return state
  },
  [SET_CANCEL_ENROLL_CLASS]: (state, enrollToCancel)=>{
    state.enrollToCancel = enrollToCancel;
    return state
  },
  [SET_CLASS_ATTENDANCE_GRADES_CLASS]: (state, classAttendanceGrades)=>{
    state.classAttendanceGrades = classAttendanceGrades;
    return state
  },
});
