import React, { Fragment, Component, h} from "preact";
import {useEffect} from "preact/hooks";
import './styles.scss'

export interface ModalProps {
    closeModal: () => void,
    children: JSX.Element | string,
    overlayClass: string,
    bodyClass: string,
}

export const ModalComponent = ({ closeModal, children, overlayClass, bodyClass }: ModalProps) => {
    const assignOverlayClass = overlayClass ? overlayClass : 'modal-overlay'
    const assignBodyClass = bodyClass ? bodyClass : 'modal-body__default'

    useEffect(() => {
        const close = (e: KeyboardEvent) => {
            if (e.code === 'Escape') {
                closeModal && closeModal()
            }
        }

        window.addEventListener('keydown', close)
        document.body.style.overflowY = 'hidden'
        return () => {
            window.removeEventListener('keydown', close)
            document.body.style.overflowY = 'auto'
        }
    }, [])

    const handleBodyModal = (e: MouseEvent) => {
        e.stopPropagation()
    }

    return (
        <div>
            <div onClick={closeModal} className={assignOverlayClass}>
                <div onClick={handleBodyModal} className={`modal-body ${assignBodyClass}`}>
                    {children}
                </div>
            </div>
        </div>
    )
}