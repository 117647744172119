import {useState} from 'preact/hooks';

export default function useSidebarLogic () {
  const [buildings, setBuildings] = useState(null)
  const [schedules, setSchedules] = useState([])
  const [citySelected, setCitySelected] = useState(null)
  const [dateSelected, setDateSelected] = useState(null)
  const disableSubmit = (!(dateSelected && citySelected))

  const fetchBuildings = async (callback) => {
    if (typeof callback === 'function') {
      setBuildings(await callback());
    } else {
      setBuildings(callback);
    }
  }

  const fetchSchedules = async (callback) => {
    const schedules = await callback();
    setSchedules(schedules)
  }

  return { buildings, setBuildings, schedules, setSchedules, citySelected, setCitySelected, dateSelected, setDateSelected, disableSubmit, fetchBuildings, fetchSchedules }
}
