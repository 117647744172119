import { Fragment, h } from "preact";
import { AppPage } from '../../../_common/components/app-page'
import {C, CF, F} from '../../../_common/layout'
import { RadioCheckbox } from '../../../_common/forms/components/components'
import { useState } from 'preact/hooks'
import { acceptTerms, fetchStatus } from '../../../_rx/fct/actions'
import {fetchStatus as fetchStatusAccount} from "../../../_rx/student/actions";
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import ButtonSpinner from '../../../_common/components/buttons/buttonSpinner/buttonSpinner'
import useFctMeta from '../../hooks/useFctMeta'
import { t } from '../../../_i18n'
import { isSpanishUser } from "../../../_common/utils/getLang";
import {Checkbox} from "../../../_common/forms/components/Checkbox/Checkbox";

export function Terms () {
  const [check, setCheck] = useState(false)
  const [checkRGPD, setCheckRGPD] = useState(false)
  const [loading, setLoading] = useState(false)
  const userId = useSelector(state => state.auth.loggedUser.id)
  const shopId = useSelector(state => state.status.student_meta.shop_id)
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  const meta = useFctMeta(id)

  const handleSubmit = () => {
    setLoading(true)
    acceptTerms(id)
      .then( async () => {
        await dispatch(fetchStatus(id))
        await dispatch(fetchStatusAccount(userId))
        history.push(`/fct/${id}`)
      })
      .catch(() => setLoading(false))
  }

  return (
    <AppPage className="max-width-980">
      <F className="max-width-980">
        <C>
          <div className="back breadcrumb-item cursor-pointer d-none sm-d-block width-fit-content" onClick={history.goBack}>← {t('Volver')}</div>
          <h1 className='hero m-bottom m-top'>{t('Normativa Formación en Centros de Trabajo')}</h1>

          <CF className="card m-bottom">
            <div className={"p-x2-top p-x2-bottom t-justify"}>
              {(() => {
                if (isSpanishUser(shopId) && meta.formative_zone === 'CAT') {
                    return (
                        <div>
                            <h2 className='hero m-bottom font-heavy'>{t('Normativa')}</h2>
                            <span>
                                <a href='https://storage.googleapis.com/fct_documents/FCT_Normativa_Online_2024.pdf'
                                  target='_blank' className='font-color-149BAB'>Normativa FCT 2024</a> (1.5 MB PDF)
                            </span>
                        </div>
                    );
                } else if(shopId == 24 || shopId == 44) {
                    return (
                        <div dangerouslySetInnerHTML={{__html: t(meta.formative_zone === 'MAD' ? 'fct_terms_mad' : 'fct_terms_cat')}} />
                    );
                } else {
                  return (
                    <div>
                        <h2 className='hero m-bottom font-heavy'>{t('Normativa')}</h2>
                        <span>
                            <a href='https://soporte.ilerna.es/hc/es-es/articles/16319986715037-Normativa-Formaci%C3%B3n-en-Centros-de-Trabajo-MAD'
                              target='_blank' className='font-color-149BAB'>Normativa FCT 2024</a>
                        </span>
                    </div>
                );                    
                }
              })()}

              {!meta.accepted && (
                  <Fragment>
                    <RadioCheckbox type='checkbox' className='m-top'
                                   label={t('He leído la normativa de la Formación en centros de trabajo y acepto las condiciones.')}
                                   defaultValue={check} onChange={(e) => setCheck(e)} />
                  </Fragment>
              )}
            </div>
          </CF>

          <CF className="card m-bottom">
            <div className={"p-x2-top p-x2-bottom t-justify"}>
              <div>
                <h2 className='hero m-bottom font-heavy'>{t('fct_rgpd_title')}</h2>
                <div dangerouslySetInnerHTML={{__html: t('fct_rgpd_info')}}/>
              </div>

              {!meta.rgpd_accepted && (
                  <Fragment>
                    <RadioCheckbox type='checkbox' className='m-top'
                                   label={t('fct_rgpd_checkbox')}
                                   defaultValue={checkRGPD} onChange={(e) => setCheckRGPD(e)}/>
                  </Fragment>
              )}
            </div>
          </CF>

          <ButtonSpinner classNameContainer='m-top' disabled={!check || !checkRGPD} onClick={handleSubmit}
                         showSpinner={loading}>{t('Aceptar y enviar')}</ButtonSpinner>

        </C>
      </F>
    </AppPage>
  )
}

