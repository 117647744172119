/**
 * New Expedient
 * Expedient for Student Account
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AcademicProgramSchoolCenter model module.
 * @module model/AcademicProgramSchoolCenter
 * @version 1.0
 */
class AcademicProgramSchoolCenter {
    /**
     * Constructs a new <code>AcademicProgramSchoolCenter</code>.
     * @alias module:model/AcademicProgramSchoolCenter
     */
    constructor() { 
        
        AcademicProgramSchoolCenter.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AcademicProgramSchoolCenter</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AcademicProgramSchoolCenter} obj Optional instance to populate.
     * @return {module:model/AcademicProgramSchoolCenter} The populated <code>AcademicProgramSchoolCenter</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AcademicProgramSchoolCenter();

            if (data.hasOwnProperty('educationCenterCode')) {
                obj['educationCenterCode'] = ApiClient.convertToType(data['educationCenterCode'], 'String');
            }
            if (data.hasOwnProperty('onlineTitle')) {
                obj['onlineTitle'] = ApiClient.convertToType(data['onlineTitle'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} educationCenterCode
 */
AcademicProgramSchoolCenter.prototype['educationCenterCode'] = undefined;

/**
 * @member {String} onlineTitle
 */
AcademicProgramSchoolCenter.prototype['onlineTitle'] = undefined;






export default AcademicProgramSchoolCenter;

