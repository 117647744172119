import React, {Component, Fragment, h} from "preact";
import {t} from '../../../_i18n';
import ProgressCard from './ProgressCard'
import { E, FR } from '../../../_common/layout'

export default function ProgressSection ({ academicProgram }) {
  return (
    <FR className='m-top flex-column sm-no-reverse'>
      <E>
        {academicProgram.done_hours !== undefined && academicProgram.total_hours !== undefined && academicProgram.average !== undefined &&
          <ProgressCard
            title={t('Horas totales realizadas')}
            subtitle={t('expedient_hours_done')
              .replace('{hours_done}', academicProgram.done_hours)
              .replace('{hours_total}', academicProgram.total_hours)}
            percent={academicProgram.average}
          />
        }
      </E>
      <E>
        {academicProgram.done_hours_without_FCT !== undefined && academicProgram.total_hours_without_FCT !== undefined && academicProgram.average_without_FCT !== undefined &&
          <ProgressCard
            title={t('Horas realizadas sin FCT')}
            subtitle={t('expedient_hours_done')
              .replace('{hours_done}', academicProgram.done_hours_without_FCT)
              .replace('{hours_total}', academicProgram.total_hours_without_FCT)}
            percent={academicProgram.average_without_FCT}
          />
        }
      </E>
    </FR>
  )
}
