import {AcademicProgramsApi, SubjectOffersApi, FormativeActionsApi} from "../_api/expedient/src";
import {asyncLazySingleton} from "../_common/common";

export const academicProgramsApi = new asyncLazySingleton(async ()=>{

    return new AcademicProgramsApi();
}) ;

export const formativeActionsApi = new asyncLazySingleton(async ()=>{

    return new FormativeActionsApi();
}) ;

export const subjectOffersApi = new asyncLazySingleton(async ()=>{

    return new SubjectOffersApi();
}) ;
