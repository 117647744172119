/**
 * Student Account
 * Student Account Api - Validation flow
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dmartinez@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Country from './Country';
import CourseAccessType from './CourseAccessType';
import DocumentType from './DocumentType';
import GenderType from './GenderType';
import LastCourseType from './LastCourseType';
import Nee from './Nee';
import Province from './Province';
import ViaType from './ViaType';

/**
 * The Fixture model module.
 * @module model/Fixture
 * @version 1.0.0
 */
class Fixture {
    /**
     * Constructs a new <code>Fixture</code>.
     * Initial data to load in application
     * @alias module:model/Fixture
     */
    constructor() { 
        
        Fixture.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Fixture</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Fixture} obj Optional instance to populate.
     * @return {module:model/Fixture} The populated <code>Fixture</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Fixture();

            if (data.hasOwnProperty('nee')) {
                obj['nee'] = ApiClient.convertToType(data['nee'], [Nee]);
            }
            if (data.hasOwnProperty('via_type')) {
                obj['via_type'] = ApiClient.convertToType(data['via_type'], [ViaType]);
            }
            if (data.hasOwnProperty('gender_type')) {
                obj['gender_type'] = ApiClient.convertToType(data['gender_type'], [GenderType]);
            }
            if (data.hasOwnProperty('last_course_type')) {
                obj['last_course_type'] = ApiClient.convertToType(data['last_course_type'], [LastCourseType]);
            }
            if (data.hasOwnProperty('course_access_type')) {
                obj['course_access_type'] = ApiClient.convertToType(data['course_access_type'], [CourseAccessType]);
            }
            if (data.hasOwnProperty('country')) {
                obj['country'] = ApiClient.convertToType(data['country'], [Country]);
            }
            if (data.hasOwnProperty('province')) {
                obj['province'] = ApiClient.convertToType(data['province'], [Province]);
            }
            if (data.hasOwnProperty('document_type')) {
                obj['document_type'] = ApiClient.convertToType(data['document_type'], [DocumentType]);
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<module:model/Nee>} nee
 */
Fixture.prototype['nee'] = undefined;

/**
 * @member {Array.<module:model/ViaType>} via_type
 */
Fixture.prototype['via_type'] = undefined;

/**
 * @member {Array.<module:model/GenderType>} gender_type
 */
Fixture.prototype['gender_type'] = undefined;

/**
 * @member {Array.<module:model/LastCourseType>} last_course_type
 */
Fixture.prototype['last_course_type'] = undefined;

/**
 * @member {Array.<module:model/CourseAccessType>} course_access_type
 */
Fixture.prototype['course_access_type'] = undefined;

/**
 * @member {Array.<module:model/Country>} country
 */
Fixture.prototype['country'] = undefined;

/**
 * @member {Array.<module:model/Province>} province
 */
Fixture.prototype['province'] = undefined;

/**
 * @member {Array.<module:model/DocumentType>} document_type
 */
Fixture.prototype['document_type'] = undefined;






export default Fixture;

