import {Component, Fragment, h} from "preact";
import {AppPage} from "../../_common/components/app-page";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {t} from '../../_i18n';
import {CF, CFR, E, R, RE} from "../../_common/layout";
import './enrollment-process-page.scss';
import {rndStr} from "../../_common/common.ts";
import {
    STATUS_ID_ERROR,
    STATUS_ID_PENDING,
    STATUS_ID_SENT,
    STATUS_ID_VALIDATED,
    STATUS_ID_VALIDATING
} from "../../constants";
import AlertBox from "../../_common/components/AlertBox/AlertBox";
import Badge from "../../_common/components/Badge";
import { STUDENT_NO_TITLE } from '../../app/getUserRol'


const statusBadgeTextDic = {[STATUS_ID_PENDING]: 'Pendiente', [STATUS_ID_SENT]: 'Enviado', [STATUS_ID_VALIDATING]: 'Validando', [STATUS_ID_ERROR]: 'Revisar', [STATUS_ID_VALIDATED]: 'Validado'};
const statusBadgeClassDic = {[STATUS_ID_PENDING]: 'badge-warning', [STATUS_ID_SENT]: 'd-none', [STATUS_ID_VALIDATING]: 'd-none', [STATUS_ID_ERROR]: 'badge-warning', [STATUS_ID_VALIDATED]: 'badge-success translucent'};

const BadgeComponent = ({status})=><Badge className={`t-upper badge font-small ${statusBadgeClassDic[status] || 'd-none'}`}>{t(statusBadgeTextDic[status])}</Badge>

let EnrollmentProcessView = class extends Component {

    idModal = rndStr();

    isLinkDisabled(status){
        return status == STATUS_ID_VALIDATED;
    }

    isValidatingState(){
        const blocks = this.props.status.blocks;
        return blocks['1'] &&
            !( // todos validated, no
                blocks['1'].status.id == STATUS_ID_VALIDATED &&
                blocks['2'].status.id == STATUS_ID_VALIDATED &&
                blocks['3'].status.id == STATUS_ID_VALIDATED &&
                (!blocks['6'] || blocks['6'].status.id == STATUS_ID_VALIDATED)
            ) &&
            (blocks['1'].status.id == STATUS_ID_VALIDATING || blocks['1'].status.id == STATUS_ID_VALIDATED) &&
            (blocks['2'].status.id == STATUS_ID_VALIDATING || blocks['2'].status.id == STATUS_ID_VALIDATED) &&
            (blocks['3'].status.id == STATUS_ID_VALIDATING || blocks['3'].status.id == STATUS_ID_VALIDATED) &&
            (!blocks['6'] || blocks['6'].status.id == STATUS_ID_VALIDATING || blocks['6'].status.id == STATUS_ID_VALIDATED);
    }

    getBadgeClass(id) {
        return statusBadgeClassDic[this.props.status.blocks[id].status.id];
    }

    getBadgeText(id) {
        return t(statusBadgeTextDic[this.props.status.blocks[id].status.id]);
    }

    render({ match, status, student_access}) {

        let statuses = [
            null,                       // 0
            status.blocks[1].status.id, // 1
            status.blocks[2].status.id, // 2
            status.blocks[3].status.id,  // 3
            status.blocks[6] ? status.blocks[6].status.id : STATUS_ID_VALIDATED // 4
        ];

        // manipulamos el estado validando
        // no podemos mostrar validando si hay alguno pendiente
        if(statuses.includes(STATUS_ID_PENDING)){
            //cualquier VALIDATING se pasa a SENT
            for(let i in statuses){
                if(statuses[i] == STATUS_ID_VALIDATING)
                    statuses[i] = STATUS_ID_SENT;
            }
        }

        const needSign = !status.student_meta.has_signed_condition;

        return status &&
            <Fragment>

                <CF className={"desktop-white max-width-580 " + (needSign?'disabledBlock':'')}>
                    <Link id="enroll-process-form" to="/student/form" className={this.isLinkDisabled(statuses[1])?'disabled-link':''}>
                        <p className="badge--text">
                            <span class={'text-big ' + (this.isLinkDisabled(statuses[1])?'disabled-aspect-gray':'')}>
                                {t('Formulario de matrícula')}
                            </span>
                            <span class="t-right nowrap">
                                <BadgeComponent status={statuses[1]} />
                                {this.isLinkDisabled(statuses[1])?'':<i className="far fa-angle-right" />}
                            </span>
                        </p>
                    </Link>

                    <hr className="separator-xxs"/>

                    <Link id="enroll-process-access" to="/student/course-access" className={this.isLinkDisabled(statuses[3])?'disabled-link':''}>
                        <p className="badge--text">
                            <span class={'text-big ' + (this.isLinkDisabled(statuses[3])?'disabled-aspect-gray':'')}>
                                {t('Información de acceso al ciclo')}
                            </span>
                            <span class="t-right nowrap">
                                <BadgeComponent status={statuses[3]} />
                                {this.isLinkDisabled(statuses[3])?'':<i className="far fa-angle-right" />}
                            </span>
                        </p>
                    </Link>

                    <hr className="separator-xxs"/>

                    <Link id="enroll-process-nif" to="/student/nif" className={this.isLinkDisabled(statuses[2])?'disabled-link':''}>
                        <p className="badge--text">
                            <span class={'text-big ' + (this.isLinkDisabled(statuses[2])?'disabled-aspect-gray':'')}>
                                {t('Documento de identidad')}
                            </span>
                            <span class="t-right nowrap">
                                <BadgeComponent status={statuses[2]} />
                                {this.isLinkDisabled(statuses[2])?'':<i className="far fa-angle-right" />}
                            </span>
                        </p>
                    </Link>



                    {status.student_meta.has_academic_program_document_types && <Fragment>
                        <hr className="separator-xxs"/>
                        <Link id="enroll-process-nif" to="/student/other-docs"
                              className={this.isLinkDisabled(statuses[4]) ? 'disabled-link' : ''}>
                            <p className="badge--text">
                                <span className={'text-big ' + (this.isLinkDisabled(statuses[4]) ? 'disabled-aspect-gray' : '')}>
                                    {t('Otros documentos')}
                                </span>
                                <span className="t-right nowrap">
                                    <BadgeComponent status={statuses[4]}/>
                                    {this.isLinkDisabled(statuses[4]) ? '' : <i className="far fa-angle-right"/>}
                                </span>
                            </p>
                        </Link>
                    </Fragment>}
                    {student_access !== STUDENT_NO_TITLE ? '': <Fragment>
                        <hr className="separator-xxs"/>
                        <Link to="/student/send-copy">
                            <p className="badge--text">
                                <span>{t('Enviar copia de titulación')}</span>
                                <span class="t-right nowrap">
                                    <BadgeComponent status={status.blocks[4].status.id} />
                                    <i className="far fa-angle-right" />
                                </span>
                            </p>
                        </Link>
                    </Fragment>}
            </CF>

            {this.isValidatingState() && <AlertBox type='info' className="max-width-580 margin-auto m-top-x2">
                {t('estamos validando')}
            </AlertBox>}

            </Fragment>;
    }
};


EnrollmentProcessView = connect(
    ({auth, status, student_access})=>({auth, status, student_access}), {})(EnrollmentProcessView);

const EnrollmentProcessPage = ({history}) =>
    <AppPage
        name="completa-matriculacion"
        title={t('Completa tu matriculación')}
        subtitle=""
        className="max-width-580"
    >
        <EnrollmentProcessView />

    </AppPage>
;

export default EnrollmentProcessPage;
