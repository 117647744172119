
// Flujo de subida de documentación
export const STATE_DOCUMENTATION = 'STATE_DOCUMENTATION'
export const ARRAY_STATES_DOCUMENTATION = ['1.1', '1.2', '1.3', '1.4']

// Flujo de asignación de empresa
export const STATE_COMPANY_ASSIGNMENT = 'STATE_COMPANY_ASSIGNMENT'
export const ARRAY_STATES_COMPANY_ASSIGNMENT = ['2.1', '2.2', '2.3', '2.4', '2.5', '2.6']

// Flujo de validación de convenio
export const STATE_CONVENTION = 'STATE_CONVENTION'
export const ARRAY_STATES_CONVENTION = ['3.1', '3.2', '3.3']

// Inicio de la formación
export const STATE_FCT_STARTED = 'STATE_FCT_STARTED'
export const ARRAY_STATES_FCT_STARTED = ['4.1', '4.2', '4.3', '4.4']

export const STATE_REQUEST_DEFERRAL_MAD = 'STATE_REQUEST_DEFERRAL_MAD';
export const ARRAY_STATES_REQUEST_DEFERRAL_MAD = ['7.1'];

// Flujo de exención del 100%
export const STATE_EXEMPTION_100 = 'STATE_EXEMPTION_100'
export const ARRAY_STATES_EXEMPTION_100 = ['5.1', '5.2', '5.3']

// Fin de la formación
export const STATE_FCT_END = 'STATE_FCT_END'
export const ARRAY_STATES_FCT_END = ['6.1']


export const FILE_SIZE_MAX = 5000000
export const FILE_ACCEPT_FILES = 'image/jpeg,image/gif,image/png,application/pdf, .doc, .docx'
