import { createReducer } from "../../../_common/common.ts";
import {SET_LOGIN_FAILED, SET_USER} from "../actions/constants";


const initialState = {
    authFailed: null,
    loggedUser: null
};

const reducer = createReducer(initialState, {

    [SET_USER]: (state, payload)=>{
        // payload == null -> logout
        state.loggedUser = payload;
        state.authFailed = payload === null ? null : false;
    },

    [SET_LOGIN_FAILED]: (state)=>{
        state.loggedUser = null;
        state.authFailed = true;
    }



});

export default reducer;
