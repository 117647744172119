/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Answer model module.
 * @module model/Answer
 * @version v1
 */
class Answer {
    /**
     * Constructs a new <code>Answer</code>.
     * Respuestas de las preguntas
     * @alias module:model/Answer
     */
    constructor() { 
        
        Answer.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Answer</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Answer} obj Optional instance to populate.
     * @return {module:model/Answer} The populated <code>Answer</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Answer();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('text')) {
                obj['text'] = ApiClient.convertToType(data['text'], 'String');
            }
            if (data.hasOwnProperty('grade')) {
                obj['grade'] = ApiClient.convertToType(data['grade'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
Answer.prototype['id'] = undefined;

/**
 * @member {String} text
 */
Answer.prototype['text'] = undefined;

/**
 * Nota de la respuesta. -1 significa respuesta killer
 * @member {Number} grade
 */
Answer.prototype['grade'] = undefined;






export default Answer;

