import {Component, Fragment, h} from "preact";
import {AppPage} from "../../_common/components/app-page";
import {t} from '../../_i18n';
import {connect} from "react-redux";
import { fetchCertifiedCourseAccess } from "../../_rx/student/actions";
import {flat, formatDDMMYYYY} from "../../_common/common.ts";
import {CF, E, R, RE} from "../../_common/layout";

import "./send-copy.scss";
import {LANG_FR, LANG_IT, PS_SHOPID_ES} from "../../constants";
import AlertBox from "../../_common/components/AlertBox/AlertBox";
import {isSpanishUser} from "../../_common/utils/getLang";

let SendCopy = class extends Component {

    componentDidMount() {
        this.props.fetchCertifiedCourseAccess(this.props.auth.loggedUser.id);
    }

    docErrors(){
        if(!this.props.cert || !this.props.cert.files.length) return [];
        let messages = flat(this.props.cert.files.map(f=>f.messages));
        messages = messages.map(m=>m.related_messages);
        messages = flat(messages);
        messages = messages.map(m=>m.description);
        return messages;
    }

    render({cert, status, lang}){
       const isSpanish = isSpanishUser(status.student_meta.shop_id)
        return <CF className="max-width">
            {this.docErrors().map(err=>
                <AlertBox type='danger'>
                    <span>{err}</span>
                </AlertBox>)}

            <p className="documentation-text p-bottom"
               innerHTML={t('copia compulsada')}/>


            { lang !== LANG_FR && lang !== LANG_IT && (
              <ul className="documentation-needed">
                  {cert && cert.docs.filter(f=>f.need_certify).map(f=><li key={f.type}>{t(f.type)}</li>)}
              </ul>
            )}
            <hr className="separator-xs"/>

            <h2>{t('Dirección de envío')}</h2>
            <p><strong> {t('ILERNA Online')}</strong><br/>
            {t('Turó de Gardeny')}<br/>
            {t('Edificio')}</p>

            <AlertBox type='info' className="p-left p-right sm-p-x2-left sm-x2-right">

                {isSpanish
                    ? <Fragment>
                        <p>Compulsar un documento significa dar validez a la fotocopia de un documento original.</p>
                        <p><strong>Quién puede compulsar las fotocopias:</strong></p>
                        <ul>
                            <li>El organismo  que ha expedido el documento original.</li>
                            <li>Una notaría.</li>
                            <li>Cualquier organismo de administración pública</li>
                        </ul>
                        <p>De esta forma se hará constar que la fotocopia del documento és idéntica a la original.</p>

                        <hr className="separator-xxs"/>

                        <strong>Importante:</strong>
                        <ul>
                            <li>Todas las páginas que contengan datos importantes deberán tener compulsa (datos alumnos, sello emisor, cualificación, datos centro de estudio,...)</li>
                            <li>No son válidas las compulsas realizadas por las Oficinas de Correos.</li>
                            <li>No se pueden enviar por correo electrónico las compulsas, se tiene que enviar el documento sellado por correo postal.</li>
                        </ul>
                    </Fragment>
                    : <Fragment>
                        <div dangerouslySetInnerHTML={{__html: t('send_copy_1')}}/>
                        <hr className="separator-xxs"/>
                        <div dangerouslySetInnerHTML={{__html: t('send_copy_2')}}/>
                    </Fragment>}

            </AlertBox>

        </CF>;
    }
}


const SendCopyPage =({history}) =>
    <AppPage
        name="send-copy"
        title={t('Enviar copia de titulación')}
        back={history.goBack}
        className="max-width"
    >
        <SendCopy/>
    </AppPage>
;

SendCopy = connect((state) => ({
        status: state.status,
        auth: state.auth,
        cert: state.certified_course_access,
        lang: state.lang
    }),
    {
        fetchCertifiedCourseAccess
    })(SendCopy);


export default SendCopyPage;
