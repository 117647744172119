import React, {Component, Fragment, h} from "preact";
import {Link} from "react-router-dom";
import {CF} from "../../_common/layout";
import {t} from '../../_i18n';



export const TabsCompany = ({company, match})=> {

    return <div className="tabs-container">
        <div className="inline">
            <Link to={'/jobs/company/' + company.toString()} className={match.path.endsWith(`:id`)?'selected':''}>{t('Información')}</Link>
        </div>
        <div className="inline">
            <Link to={'/jobs/company/' + company.toString()+ '/offers'} className={match.path.split('/')[4] === 'offers' ?'selected':''}>{t('Ofertas')}</Link>
        </div>
    </div>;
}
