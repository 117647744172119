import { h } from "preact";
import AlertBox from '../../../_common/components/AlertBox/AlertBox'
import { t } from '../../../_i18n'
import {Fragment} from "react";
import './alert-commitment-fct.scss';

export default function AlertCommitmentFCT ({ data }) {
  return (
      <Fragment>
          <AlertBox type='commitment-fct'>
              <div className='p-left'>
                  <p>{t('Si entregas tu documentación correcta antes del ')} <strong>{data.period_ending}:</strong></p>
                  <ul className='m-l commitment-list'>
                      <li>{t('La validaremos antes del ')} <strong>{data.docu_validation}.</strong></li>
                      <li>{t('Nuestro objetivo será tener el convenio cerrado antes del ')}<strong>{data.contract_validation}.</strong></li>
                      <li>{t('Plazas disponibles para el periodo: ')}<strong>{data.slots}</strong></li>
                  </ul>
              </div>
          </AlertBox>
      </Fragment>
  )
}
