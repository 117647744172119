/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AttachedDocument from './AttachedDocument';

/**
 * The ActivityItem model module.
 * @module model/ActivityItem
 * @version 1.0.0
 */
class ActivityItem {
    /**
     * Constructs a new <code>ActivityItem</code>.
     * @alias module:model/ActivityItem
     */
    constructor() { 
        
        ActivityItem.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ActivityItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ActivityItem} obj Optional instance to populate.
     * @return {module:model/ActivityItem} The populated <code>ActivityItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ActivityItem();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('header')) {
                obj['header'] = ApiClient.convertToType(data['header'], 'String');
            }
            if (data.hasOwnProperty('message_direction')) {
                obj['message_direction'] = ApiClient.convertToType(data['message_direction'], 'String');
            }
            if (data.hasOwnProperty('content')) {
                obj['content'] = ApiClient.convertToType(data['content'], 'String');
            }
            if (data.hasOwnProperty('attached_docs')) {
                obj['attached_docs'] = ApiClient.convertToType(data['attached_docs'], [AttachedDocument]);
            }
            if (data.hasOwnProperty('send_at')) {
                obj['send_at'] = ApiClient.convertToType(data['send_at'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} id
 */
ActivityItem.prototype['id'] = undefined;

/**
 * @member {module:model/ActivityItem.TypeEnum} type
 */
ActivityItem.prototype['type'] = undefined;

/**
 * @member {String} header
 */
ActivityItem.prototype['header'] = undefined;

/**
 * @member {module:model/ActivityItem.MessageDirectionEnum} message_direction
 */
ActivityItem.prototype['message_direction'] = undefined;

/**
 * @member {String} content
 */
ActivityItem.prototype['content'] = undefined;

/**
 * @member {Array.<module:model/AttachedDocument>} attached_docs
 */
ActivityItem.prototype['attached_docs'] = undefined;

/**
 * @member {String} send_at
 */
ActivityItem.prototype['send_at'] = undefined;





/**
 * Allowed values for the <code>type</code> property.
 * @enum {String}
 * @readonly
 */
ActivityItem['TypeEnum'] = {

    /**
     * value: "event"
     * @const
     */
    "event": "event",

    /**
     * value: "message"
     * @const
     */
    "message": "message"
};


/**
 * Allowed values for the <code>message_direction</code> property.
 * @enum {String}
 * @readonly
 */
ActivityItem['MessageDirectionEnum'] = {

    /**
     * value: "received"
     * @const
     */
    "received": "received",

    /**
     * value: "sended"
     * @const
     */
    "sended": "sended"
};



export default ActivityItem;

