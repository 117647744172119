import {LANG_ES} from "../constants";
import { isSpanishUser } from '../_common/utils/getLang'


export function getLinksStudentNoSignedConditions({ headerOptions }) {
    const links = []

    links.push(headerOptions.optHome);
    links.push(headerOptions.optSep);
    links.push(headerOptions.optShop);
    links.push(headerOptions.optSep);
    links.push(headerOptions.optCerrar);

    return links;
}

export function getLinksStudentIsOnSite({ studentMeta, headerOptions, lang }) {
    const links = []

    if (studentMeta.canvas_access) {
        links.push(headerOptions.optHome);
        links.push(headerOptions.optAccesoCampus);    // <- Raro: No se mostrará en el menú sino en el header, pero se pasa así a las apps ios / android
        links.push(headerOptions.optSep);
    }
    links.push(headerOptions.optHistorialMatriculas);
    links.push(headerOptions.optDirEnvio);
    links.push(headerOptions.optSep);
    if (!studentMeta.hideJobsMenu && lang === LANG_ES) links.push(headerOptions.optJobs);
    links.push(headerOptions.optShop);
    links.push(headerOptions.optSep);
    links.push(headerOptions.optCerrar);

    return links;
}

export function getLinksStudentEmpty({ studentMeta, headerOptions }) {
    const links = []

    links.push(headerOptions.optHome);
    links.push({...headerOptions.optPedidos, disabled: !studentMeta.has_any_order});
    links.push(headerOptions.optSep);
    links.push(headerOptions.optShop);
    links.push(headerOptions.optSep);
    links.push(headerOptions.optCerrar);

    return links;
}

export function getLinksStudentNoValidated({ studentMeta, headerOptions }) {
    const links = [];

    if (studentMeta.canvas_access) links.push(headerOptions.optAccesoCampus);
    links.push(headerOptions.optCompletaMatricula);
    if (studentMeta.has_whatsapp) links.push(headerOptions.notices);
    links.push(headerOptions.optSep);
    links.push(headerOptions.optAccesoCampusMenu);
    links.push(headerOptions.optHistorialMatriculas);
    links.push(headerOptions.optDirEnvio);
    links.push(headerOptions.optSep);
    links.push(headerOptions.optShop);
    links.push(headerOptions.optSep);
    links.push(headerOptions.optCerrar);

    return links;
}

// Es como NoValidated, excepto porque, en este estado, los alumnos ya deberían poder inscribirse a exámenes y/o clases
export function getLinksStudentNoTitle({ studentMeta, headerOptions, lang }) {
    const links = [];

    links.push(headerOptions.optHome);
    if (studentMeta.canvas_access) links.push(headerOptions.optAccesoCampus);
    links.push(headerOptions.optCompletaMatricula);
    if (lang === LANG_ES) links.push(headerOptions.optSecretary)
    if (lang === LANG_ES) links.push(headerOptions.optOnlinerArea)
    links.push(headerOptions.optSep);
    links.push(headerOptions.notices);
    links.push(headerOptions.optAccesoCampusMenu);
    links.push(headerOptions.optClasesPresenciales);
    links.push(headerOptions.optExamenes);
    links.push(headerOptions.optExpediente);
    links.push(headerOptions.optHistorialMatriculas);
    links.push(headerOptions.optDirEnvio);
    links.push(headerOptions.optSep);
    if (!micuentaenv.HideJobsMenu && lang === LANG_ES) links.push(headerOptions.optJobs);
    headerOptions.optFct.forEach(link => links.push(link));
    links.push(headerOptions.optShop);
    links.push(headerOptions.optSep);
    links.push(headerOptions.optCerrar);

    return links;
}



export function getLinksStudentValidated({ studentMeta, headerOptions, showInviteFriend, showGift, lang }) {
    const links = [];

    if (showInviteFriend) links.push(headerOptions.inviteFriend);
    if (showGift) links.push(headerOptions.gift);

    if (studentMeta.canvas_access) {
        links.push(headerOptions.optHome);
        links.push(headerOptions.optAccesoCampus);    // <- Raro: No se mostrará en el menú sino en el header, pero se pasa así a las apps ios / android
    }
    if (lang === LANG_ES) links.push(headerOptions.optSecretary)
    if (lang === LANG_ES) links.push(headerOptions.optOnlinerArea)
    links.push(headerOptions.optSep);
    links.push(headerOptions.optAccesoCampusMenu);
    links.push(headerOptions.optClasesPresenciales);
    links.push(headerOptions.optExamenes);
    links.push(headerOptions.optExpediente);
    links.push(headerOptions.optCompletaMatricula);
    links.push(headerOptions.optHistorialMatriculas);
    links.push(headerOptions.optDirEnvio);
    links.push(headerOptions.optSep);
    if (!micuentaenv.HideJobsMenu && lang === LANG_ES) links.push(headerOptions.optJobs);
    headerOptions.optFct.forEach(link => links.push(link));
    links.push(headerOptions.optShop);
    links.push(headerOptions.optSep);
    links.push(headerOptions.optCerrar);

    return links
}
