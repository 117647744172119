import React, {Component, h } from "preact";
import {AppPage} from "../../_common/components/app-page";
import { Form as FinalForm} from 'react-final-form'
import {FormField, SubmitButtonRow} from "../../_common/forms/components/components";
import {connect} from "react-redux";
import {fetchStudent, saveStudent} from "../../_rx/student/actions";
import {Card, CF, E, FR, R} from "../../_common/layout";
import {t} from '../../_i18n';
import {Redirect} from "react-router";
import {ButtonLink, ButtonPrimary} from "../../_common/components/buttons/buttons";

let PhoneView = class extends Component {

    componentDidMount() {
        const {auth} = this.props;
        this.props.fetchStudent(auth.loggedUser.id);
    }

    onSubmit(data){
        return this.props.saveStudent(data).catch(err=> ({'error': true}));
    }

    handleCancel(e){
        e.preventDefault()
        this.props.history.goBack()
    }

    render({student, history}) {
        return student &&
            <CF className="max-width m-top-header-xs-sm">
                <Card className="p-bottom">
                <h1>{t('Cambiar nombre sentido')}</h1>
                <FinalForm onSubmit={data=>this.onSubmit(data)} initialValues={student} render = {
                    ({handleSubmit,  submitting, errors, submitSucceeded})=>
                    <form className='form' action="" onSubmit={handleSubmit} noValidate>
                        {submitSucceeded && history.goBack()}
                        <FR><E className='m-top sm-no-m-top col-100 sm-col-60'>
                            <FormField name="data.nickname" label={t('Nombre sentido')} type="text" required={true} />
                        </E></FR>
                        <FR className='m-top'>
                            <E className='full-width sm-no-full-width'>
                                <ButtonPrimary className="full-width sm-no-full-width" type='submit'>{t('Guardar')}</ButtonPrimary>
                            </E>
                            <E>
                                <ButtonLink className="full-width sm-no-full-width sm-d-block d-none" onClick={(e) => this.handleCancel(e)}>{t('Cancelar')}</ButtonLink>
                            </E>
                        </FR>
                    </form>
                }
                />
                </Card>
            </CF>;
    }
};


PhoneView = connect(
    ({auth, student}) => ({auth, student}),
    {
        fetchStudent,
        saveStudent
    })(PhoneView);

const Nickname = ({history}) =>
    <AppPage
        name="telefono"
        className="max-width"
    >
        <PhoneView history={history}/>
    </AppPage>
;

export default Nickname;
