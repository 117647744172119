import React, { Fragment, h } from "preact";
import {connect} from "react-redux";
import {AppPage} from "../_common/components/app-page";
import GiftPending from "./pages/GiftPending";
import GiftCompleted from "./pages/GiftCompleted";
import {CF} from "../_common/layout";
import {t} from "../_i18n";
import {useHistory} from "react-router-dom";
import {useEffect} from "preact/hooks";
import {fetchGiftStatus} from "../_rx/gift/actions";


function ChristmasGift ({fetchGiftStatus, studentId, presentConfirmed}) {
    const history = useHistory()
    const showBackButton = history?.action !== 'POP'

    useEffect(() => {
        fetchGiftStatus(studentId)
    }, [])

    return ((presentConfirmed !== null) &&
        <CF className="max-width">
            {showBackButton && <div className="back breadcrumb-item cursor-pointer d-none sm-d-block" onClick={history.goBack}>
                ← {t('Volver')}
            </div>}
            <h1 className='hero'>{t('Solicitar regalo')}</h1>
            {(presentConfirmed === false) ? <GiftPending /> : <GiftCompleted />}
        </CF>
    )
}


ChristmasGift = connect(
    ({auth: {loggedUser}, giftStatus: {present_confirmed}}) =>
    ({studentId: loggedUser.id, presentConfirmed: present_confirmed}),
    {fetchGiftStatus})(ChristmasGift);


export default ({...rest}) => (
    <AppPage
        className="max-width">
        <ChristmasGift {...rest}/>
    </AppPage>
)
