
export const MAX_IMAGE_PIXELS = 2000;

export const DOCTYPE_ID_PASSPORT = 1;
export const DOCTYPE_ID_DNI = 2;
export const DOCTYPE_SUB_ID_DNI_FRONT = 13;
export const DOCTYPE_SUB_ID_DNI_BACK = 14;
export const DOCTYPE_ID_NIE = 3;
export const DOCTYPE_SUB_ID_NIE_FRONT = 15;
export const DOCTYPE_SUB_ID_NIE_BACK = 16;

export const COUNTRY_SIS_ID_ES = 61;
export const COUNTRY_SIS_ID_IT = 112;
export const COUNTRY_SIS_ID_FR = 68;

export const COUNTRY_PS_ID_ES = 6;
export const COUNTRY_PS_ID_IT = 10;
export const COUNTRY_PS_ID_FR = 8;

export const PS_SHOPID_ES = 1;
export const PS_SHOPID_IT = 24;
export const PS_SHOPID_FR = 44;

export const LANG_ES = 'es';
export const LANG_IT = 'it';
export const LANG_FR = 'fr';

export const langObj = {
    24: LANG_IT,
    1: LANG_ES,
    44: LANG_FR
}

export const ilernaWebPage = {
    [LANG_IT]: 'https://www.ilernaonline.it/',
    [LANG_ES]: 'https://www.ilerna.es',
    [LANG_FR]: 'https://www.ilerna.com/fr/'
}

export const ilernaWeb = 'https://www.ilerna.es'

export const SHOP_SIS_COUNTRY_MAPPING = {
    //[PS_SHOPID_ES]: COUNTRY_SIS_ID_ES,
    [PS_SHOPID_IT] : COUNTRY_SIS_ID_IT,
    [PS_SHOPID_FR] : COUNTRY_SIS_ID_FR
};

export const SHOP_PS_COUNTRY_MAPPING = {
    //[PS_SHOPID_ES]: COUNTRY_PS_ID_ES,
    [PS_SHOPID_IT] : COUNTRY_PS_ID_IT,
    [PS_SHOPID_FR] : COUNTRY_PS_ID_FR
};

export const ZIP_PATTERNS = {
    "6": '\\d{5}',  // espana presta
    "61": '\\d{5}', // espana sis
    "10": '\\d{5}', // italia presta
    "112": '\\d{5}' // italia sis
};

export const STATUS_ID_PENDING = 1;
export const STATUS_ID_SENT = 2;
export const STATUS_ID_VALIDATING = 3;
export const STATUS_ID_ERROR = 4;
export const STATUS_ID_VALIDATED = 5;

export const STATUS_CARD_ID_UNAVALIABLE = 0;
export const STATUS_CARD_ID_PENDING1 = 1;
export const STATUS_CARD_ID_PAID = 2;
export const STATUS_CARD_ID_PENDING2 = 4;
export const STATUS_CARD_ID_DELIVERED = 6;

// 1: 'Pendiente', 2: 'Enviado', 3: 'Validando', 4: 'Revisar', 5: 'Validado'

export const DOC_TITULO = 9;
export const DOC_RESGUARDO_Y_NOTAS = 10;
export const DOC_RESGUARDO = 11;
export const DOC_NOTAS = 12;

// ITALIA
export const DOC_TITULO_Y_NOTAS = 60;
export const DOC_TITULO_ITALIA = 61;
export const DOC_NOTAS_ITALIA = 62;

export const USER_TYPE_FULL = 'full';
export const USER_TYPE_MIXED = 'mixed';
export const USER_TYPE_TRIAL = 'trial';

//Pagination
export const OFFERS_LIMIT_PAGES = 20;

export const TOKEN_EXPEDIENT = '$2y$10$OknEtCuYRc4E/D17sCLBXOFFbdzERHekH1.UkBks4nV.eTiP4QQHi'


// son diferentes en cada entorno, estan en micuentaenv.Hardcoded_ids.xxxxx
// export const ACADEMIC_PROGRAM_DOC_VIDA_LABORAL = 41;
// export const ACADEMIC_PROGRAM_DOC_CERT_EMPADRONAMIENTO = 42;
// export const ACADEMIC_PROGRAM_DOC_CERT_DESEMPLEO = 43;
