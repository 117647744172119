/**
 * New Expedient
 * Expedient for Student Account
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import FormativeAction from './FormativeAction';
import SubjectOffer from './SubjectOffer';

/**
 * The SubjectOfferwithParent model module.
 * @module model/SubjectOfferwithParent
 * @version 1.0
 */
class SubjectOfferwithParent {
    /**
     * Constructs a new <code>SubjectOfferwithParent</code>.
     * @alias module:model/SubjectOfferwithParent
     */
    constructor() { 
        
        SubjectOfferwithParent.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SubjectOfferwithParent</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SubjectOfferwithParent} obj Optional instance to populate.
     * @return {module:model/SubjectOfferwithParent} The populated <code>SubjectOfferwithParent</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SubjectOfferwithParent();

            if (data.hasOwnProperty('parent')) {
                obj['parent'] = FormativeAction.constructFromObject(data['parent']);
            }
            if (data.hasOwnProperty('children')) {
                obj['children'] = SubjectOffer.constructFromObject(data['children']);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/FormativeAction} parent
 */
SubjectOfferwithParent.prototype['parent'] = undefined;

/**
 * @member {module:model/SubjectOffer} children
 */
SubjectOfferwithParent.prototype['children'] = undefined;






export default SubjectOfferwithParent;

