import React, {Component, h } from "preact";

import { Form as FinalForm } from 'react-final-form'

import {
    FormField,
    FormFieldSplittedDate,
    FormFieldRadioCheckbox,
    FormFieldSelect,
    SubmitButtonRow, FormFieldPoblacion, GotoError
} from "../../_common/forms/components/components";
import {Card, CF, CFR, E, FR, R, RE} from "../../_common/layout";
import {Prompt, Redirect} from "react-router";
import {connect} from "react-redux";
import {fetchFixture, fetchStudent, saveStudent} from "../../_rx/student/actions";
import {AppPage} from "../../_common/components/app-page";
import {HomeAddress} from "../components/home-address";
import {fetchPoblaciones} from "../../_rx/localizaciones/actions";
import {t} from '../../_i18n';
import {range, treatCountriesList} from "../../_common/common.ts";
import {COUNTRY_SIS_ID_ES, PS_SHOPID_ES, SHOP_SIS_COUNTRY_MAPPING} from "../../constants";
import AlertBox from "../../_common/components/AlertBox/AlertBox";
import getLangByShopId from "../../_common/utils/getLang";

const lastAcademicYears = range(
    new Date().getFullYear()-40,
    new Date().getFullYear()-1
    ).reverse().map(y=> (
        {
        key:`${y.toString().substr(2)}${(y+1).toString().substr(2)}`,
        name:`${y} / ${y+1}`
        }));

let EnrollForm = class extends Component {

    componentDidMount() {
        const {auth} = this.props;
        if(!this.props.fixtures)
            this.props.fetchFixture(getLangByShopId(this.props.status.student_meta.shop_id));

        if(!this.props.poblaciones)
            this.props.fetchPoblaciones();

        this.props.fetchStudent(auth.loggedUser.id);
    }

    async onSubmit(data){
        await this.props.saveStudent(data)
            .catch(err=> ({'error': true}));

    }

    render({fixtures, student, poblaciones, status, history}) {



        return fixtures && poblaciones && student && // hasta que no haya fixtures no se muestra el form
            <CF className="max-width-sm"><FinalForm onSubmit={data=>this.onSubmit(data)} initialValues={student} render={
                ({handleSubmit, pristine, reset, submitting, errors, submitSucceeded, submitFailed, values, dirtyFields})=>

                    <form className='form' action="" onSubmit={handleSubmit} noValidate>
                        {submitSucceeded && <Redirect to={'/student'}/>}
                        <GotoError submitFailed={submitFailed} />

                        <AlertBox type='info'>{t('formalizar matricula alert')}</AlertBox>

                        <Card>

                            <FR>
                                <div class="col-100 sm-col-66">
                                    <FormField name="data.name" label={t('Nombre')} required={true} title="Texto sin números ni caracteres especiales" />
                                </div>
                                <div className="col-100 sm-col-66">
                                    <FormField name="data.first_name" label={t('Primer apellido')} required={true} />
                                </div>
                                <div className="col-100 sm-col-66">
                                    {status.student_meta.shop_id == PS_SHOPID_ES && <FormField name="data.last_name" label={t('Segundo apellido')} />}
                                </div>
                            </FR>

                            <RE> <h4 className="no-margin">{t('Fecha nacimiento')}</h4></RE>

                            <FR>
                                <div class="col-100 sm-col-66">
                                    <FormFieldSplittedDate name="data.birth_date" required={true} />
                                </div>
                            </FR>

                            {/*<R className="row-1-el helper-field-wrapper">*/}
                            {/*    <E className="block-center-x fecha-nacimiento-padding">*/}
                            {/*        */}
                            {/*    </E>*/}
                            {/*</R>*/}

                            <br/>{/* espacio entre pais i fecha */}

                            <FR>
                                <div class="col-100 sm-col-66">
                                    <FormFieldSelect name="data.birth_country.id" label={t('País nacimiento')} required={true} options={treatCountriesList(fixtures.country, SHOP_SIS_COUNTRY_MAPPING[status.student_meta.shop_id]||COUNTRY_SIS_ID_ES)} />
                                </div>
                                {values.data.birth_country.id == COUNTRY_SIS_ID_ES &&
                                <div className="col-100 sm-col-66">
                                    <FormFieldPoblacion name="data.birth_city.id" label={t('Población nacimiento')} required={true} list={poblaciones} pattern=".{2,}" country={values.data.birth_country.id} />
                                </div>
                                }
                                {values.data.birth_country.id != COUNTRY_SIS_ID_ES &&
                                <div className="col-100 sm-col-66">
                                    <FormField name="data.birth_city_name" label={t('Población nacimiento')} required={true}/>
                                </div>
                                }
                            </FR>

                            <RE> <h4 className="no-margin">{t('Género')}</h4> </RE>
                            <FR>
                                <E>
                                    <FormFieldRadioCheckbox type="radio" name="data.gender.id" label={t('Masculino')} value="1" required={true} />
                                </E>
                                <E>
                                    <FormFieldRadioCheckbox type="radio" name="data.gender.id" label={t('Femenino')} value="2" required={true} />
                                </E>
                            </FR>

                        </Card>


                        <div className="p-top" />

                        <Card className="sm-p-bottom">

                            <RE>
                                <h2>{t('Lugar residencia')}</h2>
                            </RE>
                            <HomeAddress fixtures={fixtures} values={values} poblaciones={poblaciones} shop={status.student_meta.shop_id} />
                            <hr className="separator-xxs"/>
                            <FR>
                                <div className="col-100 sm-col-66">
                                    <FormField name="address.phone" label={t('Teléfono contacto')} type="tel" pattern="[0-9]{9,15}" required={true} />
                                </div>

                            </FR>

                        </Card>

                        <div className="p-top" />

                        <Card className="sm-p-bottom">
                            <RE>
                                <h2>{t('Nee')}</h2>
                            </RE>

                            <FR>
                                <div className="col-100 sm-col-66">
                                    <FormFieldSelect
                                        name="data.nee.id"
                                        label={t("Selecciona una necesidad educativa")}
                                        options={fixtures.nee} required={true} />
                                </div>
                            </FR>

                        </Card>

                        <div className="p-top" />

                        <Card className="sm-p-bottom">

                            <RE>
                                    <h2>{t('Últimos estudios')}</h2>
                                    <p>{t('Indica últimos estudios')}</p>
                            </RE>
                            <FR>
                                <div className="col-100 sm-col-66">
                                    <FormFieldSelect name="last_course.id" label={t('Tipo estudios')}
                                                     required={true}
                                                     options={fixtures.last_course_type} />
                                </div>
                            </FR>
                            <FR>
                                <div className="col-100 sm-col-66">
                                    {/*<FormField name="last_course.academic_year" label={t('Último año académico')} required={true} placeholder="18/19" pattern="[0-9\/]{4,}" />*/}
                                    <FormFieldSelect name="last_course.academic_year" label={t('Último año académico')}
                                                     required={true}
                                                     options={lastAcademicYears} />
                                </div>
                            </FR>

                        </Card>

                        <Prompt message={t('Seguro pregunta')} when={!pristine && !submitSucceeded}/>

                        {Object.keys(dirtyFields).length > 0 ? <SubmitButtonRow disabled={submitting} onCancel={() => history.push('/student')}/>
                            : <SubmitButtonRow disabled onCancel={() => history.push('/student')}/>
                        }

                    </form>}

                    /></CF>;

                    }
                    };

EnrollForm = connect(
({auth, fixtures, student, poblaciones, status})=>({auth, fixtures, student, poblaciones, status}),
    {
        fetchStudent,
        fetchFixture,
        fetchPoblaciones,
        saveStudent
})(EnrollForm);


const FormPage = ({history}) =>
<AppPage
    name="matricula"
    title={t('Formulario de matrícula')}
    back="/student"
    className="max-width-sm"
    >
    <EnrollForm history={history}/>
</AppPage>
;


export default FormPage;
