import {SET_POBLACIONES} from "./constants";
import {SET_PAISES_PRESTA} from "./constants";
import { svcPsService} from "../../../_services/ps-service";
import {globalCatch} from "../../../_services/global-catch";

export const setPoblaciones = payload => ({type: SET_POBLACIONES, payload});
export const setPaisesPresta = payload => ({type: SET_PAISES_PRESTA, payload});

export const fetchPoblaciones = () =>
    globalCatch.catch(async (dispatch) => {

        // la cache del navegador sirve de singleton, en ralidad solo se consulta una vez
        const poblaciones = await (await fetch('/municipios/municipios-61.json')).json();

        dispatch(setPoblaciones(poblaciones));
    })
;

export const fetchPaises = () =>
    globalCatch.catch(async (dispatch) => {
        const paises = await (await svcPsService()).GetPaises();
        dispatch(setPaisesPresta(paises));
    })
;

