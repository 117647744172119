/**
 * LTI Enroll Exam
 * LTI Enroll Exam for Students
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';
import SubjectOffer from '../model/SubjectOffer';

/**
* StudentGrades service.
* @module api/StudentGradesApi
* @version 2.0.0
*/
export default class StudentGradesApi {

    /**
    * Constructs a new StudentGradesApi. 
    * @alias module:api/StudentGradesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get pac grades
     * @param {String} studentId 
     * @param {String} examConvocationId 
     * @param {String} formativeActionId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/SubjectOffer>} and HTTP response
     */
    studentGradesControllerGetPacGradesWithHttpInfo(studentId, examConvocationId, formativeActionId) {
      let postBody = null;
      // verify the required parameter 'studentId' is set
      if (studentId === undefined || studentId === null) {
        throw new Error("Missing the required parameter 'studentId' when calling studentGradesControllerGetPacGrades");
      }
      // verify the required parameter 'examConvocationId' is set
      if (examConvocationId === undefined || examConvocationId === null) {
        throw new Error("Missing the required parameter 'examConvocationId' when calling studentGradesControllerGetPacGrades");
      }
      // verify the required parameter 'formativeActionId' is set
      if (formativeActionId === undefined || formativeActionId === null) {
        throw new Error("Missing the required parameter 'formativeActionId' when calling studentGradesControllerGetPacGrades");
      }

      let pathParams = {
        'student_id': studentId,
        'exam_convocation_id': examConvocationId,
        'formative_action_id': formativeActionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [SubjectOffer];
      return this.apiClient.callApi(
        '/lti/student-grades/student/{student_id}/exam-convocation/{exam_convocation_id}/formative_action/{formative_action_id}/grades', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get pac grades
     * @param {String} studentId 
     * @param {String} examConvocationId 
     * @param {String} formativeActionId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/SubjectOffer>}
     */
    studentGradesControllerGetPacGrades(studentId, examConvocationId, formativeActionId) {
      return this.studentGradesControllerGetPacGradesWithHttpInfo(studentId, examConvocationId, formativeActionId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
