/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Availability from './Availability';
import ExemptionMeta from './ExemptionMeta';
import FCTInOtherCity from './FCTInOtherCity';
import MaterialAddress from './MaterialAddress';
import Size from './Size';

/**
 * The DocumentMetadata model module.
 * @module model/DocumentMetadata
 * @version 1.0.0
 */
class DocumentMetadata {
    /**
     * Constructs a new <code>DocumentMetadata</code>.
     * @alias module:model/DocumentMetadata
     */
    constructor() { 
        
        DocumentMetadata.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>DocumentMetadata</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DocumentMetadata} obj Optional instance to populate.
     * @return {module:model/DocumentMetadata} The populated <code>DocumentMetadata</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DocumentMetadata();

            if (data.hasOwnProperty('health_card_number')) {
                obj['health_card_number'] = ApiClient.convertToType(data['health_card_number'], 'String');
            }
            if (data.hasOwnProperty('social_welfare_number')) {
                obj['social_welfare_number'] = ApiClient.convertToType(data['social_welfare_number'], 'String');
            }
            if (data.hasOwnProperty('iban')) {
                obj['iban'] = ApiClient.convertToType(data['iban'], 'String');
            }
            if (data.hasOwnProperty('give_company')) {
                obj['give_company'] = ApiClient.convertToType(data['give_company'], 'Boolean');
            }
            if (data.hasOwnProperty('fct_in_other_city')) {
                obj['fct_in_other_city'] = FCTInOtherCity.constructFromObject(data['fct_in_other_city']);
            }
            if (data.hasOwnProperty('availability')) {
                obj['availability'] = Availability.constructFromObject(data['availability']);
            }
            if (data.hasOwnProperty('size')) {
                obj['size'] = Size.constructFromObject(data['size']);
            }
            if (data.hasOwnProperty('material_address')) {
                obj['material_address'] = MaterialAddress.constructFromObject(data['material_address']);
            }
            if (data.hasOwnProperty('exemption')) {
                obj['exemption'] = ExemptionMeta.constructFromObject(data['exemption']);
            }
        }
        return obj;
    }


}

/**
 * Numero tarjeta sanitaria
 * @member {String} health_card_number
 */
DocumentMetadata.prototype['health_card_number'] = undefined;

/**
 * Numero de seguridad social
 * @member {String} social_welfare_number
 */
DocumentMetadata.prototype['social_welfare_number'] = undefined;

/**
 * Cuenta IBAN
 * @member {String} iban
 */
DocumentMetadata.prototype['iban'] = undefined;

/**
 * Indica si se proporciona información de la empresa
 * @member {Boolean} give_company
 */
DocumentMetadata.prototype['give_company'] = undefined;

/**
 * @member {module:model/FCTInOtherCity} fct_in_other_city
 */
DocumentMetadata.prototype['fct_in_other_city'] = undefined;

/**
 * @member {module:model/Availability} availability
 */
DocumentMetadata.prototype['availability'] = undefined;

/**
 * @member {module:model/Size} size
 */
DocumentMetadata.prototype['size'] = undefined;

/**
 * @member {module:model/MaterialAddress} material_address
 */
DocumentMetadata.prototype['material_address'] = undefined;

/**
 * @member {module:model/ExemptionMeta} exemption
 */
DocumentMetadata.prototype['exemption'] = undefined;






export default DocumentMetadata;

