import { h } from "preact";
import '../UploadDocument/upload-document-styles.scss'
import { deleteDocument, fetchStatus } from '../../../_rx/fct/actions'
import { useDispatch } from 'react-redux'
import { t } from '../../../_i18n'

export default function WeeklyFile ({ id, name, url, contractId }) {
  const dispatch = useDispatch()

  const handleRemove = () => {
    deleteDocument(id).then(() => dispatch(fetchStatus(contractId)))
  }

  return (
    <div className='fct-upload-document__value m-bottom-half'>
      <div className='font-bold t-underline'><a className='cursor-pointer' href={url+'?download=true'} >{name}</a></div>
      <div className='font-bold t-underline cursor-pointer font-color-149BAB' onClick={handleRemove}>{t('Eliminar')}</div>
    </div>
  )
}
