import React, {Component, h} from "preact";
import {FormField, SubmitButtonRow, FormFieldPoblacion, showErrorPwd} from "../../_common/forms/components/components";
import {C, Card, CF, E, FR, R} from "../../_common/layout";
import {Prompt, Redirect} from "react-router";
import {connect} from "react-redux";
import {savePassword} from "../../_rx/cambiar-contrasena/actions";
import {t} from '../../_i18n';
import {Form} from "react-final-form";
import {PS_SHOPID_ES} from "../../constants";
import {AppPage} from "../../_common/components/app-page";
import AlertBox from "../../_common/components/AlertBox/AlertBox";
import ButtonSpinner, {ButtonSpinnerRow} from "../../_common/components/buttons/buttonSpinner/buttonSpinner";
import {ButtonLink, ButtonPrimary} from "../../_common/components/buttons/buttons";


const Password = ({shop, history, savePassword}) => {
    const url = micuentaenv.PASSWORD_RECOVERY_URL[shop]||micuentaenv.PASSWORD_RECOVERY_URL[PS_SHOPID_ES];

    const onValidate = (values) => {
        if (values.new_password && values.new_password_conf && values.new_password_conf !== values.new_password) {
            return {"equals" : true};
        }
    }

    const onSubmit = (data) => {
        return savePassword(data).catch(err=> ({'error': true}));
    }

    const handleCancel = (e) => {
        e.preventDefault()
        history.goBack()
    }

    return  <C className="max-width m-top-header-xs-sm">
        <Card className='p-bottom'>
            <div className='p-left p-right sm-no-p-right sm-no-p-left'>
                <h1>{t('Cambiar contraseña')}</h1>
                <AlertBox type='warning' className='m-bottom-half'>{t('La nueva contraseña debe tener al menos 8 caracteres')}.</AlertBox>
                <Form onSubmit={data=> onSubmit(data)}
                      validate={ onValidate }
                      render={
                          ({handleSubmit, pristine, reset, submitting, errors, submitSucceeded, dirtyFields, ...rest})=>
                              <form className='form' action="" onSubmit={handleSubmit}  noValidate>
                                  {submitSucceeded && history.goBack()}

                                  <FR>
                                      <div className="col-100 md-col-60">
                                          <FormField id="pwd1" name="password" type="password" label={t('Contraseña actual')} required={true} />
                                      </div>
                                      <div className="col-100 md-col-100 d-flex flex-bottom flex-left">
                                          <a className="font-14 m-bottom txt-color-primary" target="_blank" href={url}>{t('¿Has olvidado la contraseña?')}</a>
                                      </div>
                                  </FR>
                                  <FR>
                                      <div className="col-100 md-col-60">
                                          <FormField id="pwd2" name="new_password" type="password" label={t('Nueva contraseña')} required pattern={'.{8,}'}/>
                                      </div>
                                      <div className="col-100 md-col-60">
                                          <FormField id="pwd3" name="new_password_conf" type="password" label={t('Repite nueva contraseña')} onBlur={showErrorPwd} required pattern={'.{8,}'} />
                                      </div>
                                      <div className="col-100">
                                          <div id="new_password_conf" className="error-msg error-margin-top">{t('contraseña no coincide')}</div>
                                      </div>
                                      <Prompt message={t('Seguro pregunta')} when={!pristine && !submitSucceeded}/>
                                  </FR>
                                  <FR>
                                      <E className='full-width sm-no-full-width'>
                                          <ButtonPrimary className="full-width sm-no-full-width"
                                                         disabled={submitting || !Object.keys(dirtyFields).length > 0}
                                                         type='submit'>
                                              {t('Guardar')}
                                          </ButtonPrimary>
                                      </E>
                                      <E>
                                          <ButtonLink className="full-width sm-no-full-width sm-d-block d-none" onClick={handleCancel}>{t('Cancelar')}</ButtonLink>
                                      </E>
                                  </FR>
                              </form>}/>
            </div>
        </Card>
    </C>;


};


const PasswordView = connect((state)=>({
    auth: state.auth,
    shop: state.status.student_meta.shop_id
}), {
    savePassword
})(Password);

export default ({history}) =>
    <AppPage
        name="student page"
        className="max-width">
        <PasswordView history={history}/>
    </AppPage>;
