/**
 * LTI Enroll Exam
 * LTI Enroll Exam for Students
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';

/**
* EnrollmentActions service.
* @module api/EnrollmentActionsApi
* @version 2.0.0
*/
export default class EnrollmentActionsApi {

    /**
    * Constructs a new EnrollmentActionsApi. 
    * @alias module:api/EnrollmentActionsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Cancel enrollment exam student
     * @param {String} studentId 
     * @param {Number} eventId 
     * @param {Number} formativeActionId 
     * @param {Number} eventCalendarId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    enrollExamControllerCancelEnrollmentStudentWithHttpInfo(studentId, eventId, formativeActionId, eventCalendarId) {
      let postBody = null;
      // verify the required parameter 'studentId' is set
      if (studentId === undefined || studentId === null) {
        throw new Error("Missing the required parameter 'studentId' when calling enrollExamControllerCancelEnrollmentStudent");
      }
      // verify the required parameter 'eventId' is set
      if (eventId === undefined || eventId === null) {
        throw new Error("Missing the required parameter 'eventId' when calling enrollExamControllerCancelEnrollmentStudent");
      }
      // verify the required parameter 'formativeActionId' is set
      if (formativeActionId === undefined || formativeActionId === null) {
        throw new Error("Missing the required parameter 'formativeActionId' when calling enrollExamControllerCancelEnrollmentStudent");
      }
      // verify the required parameter 'eventCalendarId' is set
      if (eventCalendarId === undefined || eventCalendarId === null) {
        throw new Error("Missing the required parameter 'eventCalendarId' when calling enrollExamControllerCancelEnrollmentStudent");
      }

      let pathParams = {
        'student_id': studentId,
        'event_id': eventId,
        'formative_action_id': formativeActionId,
        'event_calendar_id': eventCalendarId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/lti/enrollExam/cancel_enrollment_exam/{student_id}/event/{event_id}/formative_action/{formative_action_id}/event_calendar_id/{event_calendar_id}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Cancel enrollment exam student
     * @param {String} studentId 
     * @param {Number} eventId 
     * @param {Number} formativeActionId 
     * @param {Number} eventCalendarId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    enrollExamControllerCancelEnrollmentStudent(studentId, eventId, formativeActionId, eventCalendarId) {
      return this.enrollExamControllerCancelEnrollmentStudentWithHttpInfo(studentId, eventId, formativeActionId, eventCalendarId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Enroll exam student
     * @param {String} studentId 
     * @param {Number} eventId 
     * @param {Number} formativeActionId 
     * @param {Number} eventCalendarId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    enrollExamControllerEnrollStudentWithHttpInfo(studentId, eventId, formativeActionId, eventCalendarId) {
      let postBody = null;
      // verify the required parameter 'studentId' is set
      if (studentId === undefined || studentId === null) {
        throw new Error("Missing the required parameter 'studentId' when calling enrollExamControllerEnrollStudent");
      }
      // verify the required parameter 'eventId' is set
      if (eventId === undefined || eventId === null) {
        throw new Error("Missing the required parameter 'eventId' when calling enrollExamControllerEnrollStudent");
      }
      // verify the required parameter 'formativeActionId' is set
      if (formativeActionId === undefined || formativeActionId === null) {
        throw new Error("Missing the required parameter 'formativeActionId' when calling enrollExamControllerEnrollStudent");
      }
      // verify the required parameter 'eventCalendarId' is set
      if (eventCalendarId === undefined || eventCalendarId === null) {
        throw new Error("Missing the required parameter 'eventCalendarId' when calling enrollExamControllerEnrollStudent");
      }

      let pathParams = {
        'student_id': studentId,
        'event_id': eventId,
        'formative_action_id': formativeActionId,
        'event_calendar_id': eventCalendarId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/lti/enrollExam/enroll_exam/{student_id}/event/{event_id}/formative_action/{formative_action_id}/event_calendar_id/{event_calendar_id}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Enroll exam student
     * @param {String} studentId 
     * @param {Number} eventId 
     * @param {Number} formativeActionId 
     * @param {Number} eventCalendarId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    enrollExamControllerEnrollStudent(studentId, eventId, formativeActionId, eventCalendarId) {
      return this.enrollExamControllerEnrollStudentWithHttpInfo(studentId, eventId, formativeActionId, eventCalendarId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Cancel enrollment exam student
     * @param {String} studentId 
     * @param {Number} eventId 
     * @param {Number} formativeActionId 
     * @param {Number} eventIdOld 
     * @param {Number} eventCalendarId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    enrollExamControllerUpdateEnrollmentStudentWithHttpInfo(studentId, eventId, formativeActionId, eventIdOld, eventCalendarId) {
      let postBody = null;
      // verify the required parameter 'studentId' is set
      if (studentId === undefined || studentId === null) {
        throw new Error("Missing the required parameter 'studentId' when calling enrollExamControllerUpdateEnrollmentStudent");
      }
      // verify the required parameter 'eventId' is set
      if (eventId === undefined || eventId === null) {
        throw new Error("Missing the required parameter 'eventId' when calling enrollExamControllerUpdateEnrollmentStudent");
      }
      // verify the required parameter 'formativeActionId' is set
      if (formativeActionId === undefined || formativeActionId === null) {
        throw new Error("Missing the required parameter 'formativeActionId' when calling enrollExamControllerUpdateEnrollmentStudent");
      }
      // verify the required parameter 'eventIdOld' is set
      if (eventIdOld === undefined || eventIdOld === null) {
        throw new Error("Missing the required parameter 'eventIdOld' when calling enrollExamControllerUpdateEnrollmentStudent");
      }
      // verify the required parameter 'eventCalendarId' is set
      if (eventCalendarId === undefined || eventCalendarId === null) {
        throw new Error("Missing the required parameter 'eventCalendarId' when calling enrollExamControllerUpdateEnrollmentStudent");
      }

      let pathParams = {
        'student_id': studentId,
        'event_id': eventId,
        'formative_action_id': formativeActionId,
        'event_id_old': eventIdOld,
        'event_calendar_id': eventCalendarId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/lti/enrollExam/update_enrollment_exam/{student_id}/event/{event_id}/formative_action/{formative_action_id}/event_cancel_id/{event_id_old}/event_calendar_id/{event_calendar_id}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Cancel enrollment exam student
     * @param {String} studentId 
     * @param {Number} eventId 
     * @param {Number} formativeActionId 
     * @param {Number} eventIdOld 
     * @param {Number} eventCalendarId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    enrollExamControllerUpdateEnrollmentStudent(studentId, eventId, formativeActionId, eventIdOld, eventCalendarId) {
      return this.enrollExamControllerUpdateEnrollmentStudentWithHttpInfo(studentId, eventId, formativeActionId, eventIdOld, eventCalendarId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
