/**
 * Student Account
 * Student Account Api - Validation flow
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dmartinez@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The StudentMetaCoursingAcademicPrograms model module.
 * @module model/StudentMetaCoursingAcademicPrograms
 * @version 1.0.0
 */
class StudentMetaCoursingAcademicPrograms {
    /**
     * Constructs a new <code>StudentMetaCoursingAcademicPrograms</code>.
     * @alias module:model/StudentMetaCoursingAcademicPrograms
     */
    constructor() { 
        
        StudentMetaCoursingAcademicPrograms.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>StudentMetaCoursingAcademicPrograms</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StudentMetaCoursingAcademicPrograms} obj Optional instance to populate.
     * @return {module:model/StudentMetaCoursingAcademicPrograms} The populated <code>StudentMetaCoursingAcademicPrograms</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StudentMetaCoursingAcademicPrograms();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('formative_zone')) {
                obj['formative_zone'] = ApiClient.convertToType(data['formative_zone'], 'String');
            }
            if (data.hasOwnProperty('formative_zone_code')) {
                obj['formative_zone_code'] = ApiClient.convertToType(data['formative_zone_code'], 'String');
            }
            if (data.hasOwnProperty('modality_id')) {
                obj['modality_id'] = ApiClient.convertToType(data['modality_id'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
StudentMetaCoursingAcademicPrograms.prototype['id'] = undefined;

/**
 * @member {String} title
 */
StudentMetaCoursingAcademicPrograms.prototype['title'] = undefined;

/**
 * @member {String} formative_zone
 */
StudentMetaCoursingAcademicPrograms.prototype['formative_zone'] = undefined;

/**
 * @member {String} formative_zone_code
 */
StudentMetaCoursingAcademicPrograms.prototype['formative_zone_code'] = undefined;

/**
 * @member {Number} modality_id
 */
StudentMetaCoursingAcademicPrograms.prototype['modality_id'] = undefined;






export default StudentMetaCoursingAcademicPrograms;

