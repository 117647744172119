import { h } from 'preact'
import {Form as FinalForm} from "react-final-form";
import { FormFieldSelect } from '../../../_common/forms/components/components'
import { alertTypes, getAlertType, getTopics } from './constants.jsx'
import { ButtonPrimary } from '../../../_common/components/buttons/buttons'
import { useSelector } from 'react-redux'

export default function StepSelectTopic ({ onSubmit }) {
  const { has_fct, has_convas, formative_zone } = useSelector(state => state.status.student_meta)

  return (
    <div>
      <FinalForm onSubmit={onSubmit}>
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className='step-container'>
              <FormFieldSelect name='topic' label='¿Sobre qué quieres hablar con nosotros?' required options={getTopics(has_fct, has_convas)} specificError='Por favor, selecciona un motivo.' />
              { values.topic && getAlertType(parseInt(values.topic), formative_zone)}
              <div className='m-top d-flex flex-right'>
                <ButtonPrimary className='full-width sm-auto-width'>Seleccionar día y hora</ButtonPrimary>
              </div>
            </div>
          </form>
        )}
      </FinalForm>
    </div>
  )
}
