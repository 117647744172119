import {h, Component, Fragment} from "preact";
import {rndStr} from "../../_common/common.ts";
import {Card, CF} from "../../_common/layout";
import {t} from "../../_i18n";
import {ModalLink} from "../../_common/components/modal.tsx";
import {Accordion, AccordionElement} from "../../_common/components/accordion";
import {ModalExenciones} from "./modal-exenciones";
import {connect} from "react-redux";
import { fetchTitleCart } from "../../_rx/student/actions";
import {PS_SHOPID_ES} from "../../constants";
import ServicePolicy from "./servicePolicy";
import {isSpanishUser} from "../../_common/utils/getLang";

export let Payment = class extends Component {
    idModal = rndStr();

    async componentDidMount() {
        await this.props.fetchTitleCart(this.props.auth.loggedUser.id)
    }

    render({title_cart, status}) {

        const isSpain = isSpanishUser(status.student_meta.shop_id)

        return <Fragment>
            <CF className="max-width">
                <Card smOnly={false}>
                    {!isSpain
                        ? <div className={'m-bottom'}>
                            {t('payment_tax')}
                          </div>
                        : <Fragment>
                            <p dangerouslySetInnerHTML={{__html: t('Para solicitar tu título es necesario <strong>pagar las tasas de expedición del título</strong>')}}>.</p>
                            <div className='card background-info p-left p-right p-top p-bottom'>
                                {t('Antes de realizar el pago')}: <br/><br/>
                                <ModalLink id={this.idModal} className="t-underline black-weight cursor-pointer">{t('Consulta las exenciones al pago de tasas')}</ModalLink>.<br /><br />
                                <div dangerouslySetInnerHTML={{__html: t(`Si estás exento/a debes hacernos llegar la documentación que acredite tu situación. La exención de pago, total o parcial, <strong>no exime del pago de los gastos asociados a la gestión.</strong> Cuando recibamos la documentación, te enviaremos un enlace para hacer el pago con el importe total. aplicadas las exenciones que te correspondan.`)}} />
                            </div>
                            <p dangerouslySetInnerHTML={{__html: t('<strong>En el caso de que no estés exento/a del pago de tasas</strong>, debes realizar el pago aquí:')}} />
                          </Fragment>

                    }
                    <button className="btn btn-primary m-bottom no-margin-top full-width sm-no-full-width" type="button"
                            disabled={!title_cart || !title_cart.cart}
                            onClick={() => location.href = title_cart.cart}>{t('Pagar tasas')}
                    </button>
                </Card>
                <ServicePolicy />
                <ModalExenciones id={this.idModal}/>
            </CF>
        </Fragment>;
    }
}


Payment = connect(
    ({auth, status, title_cart})=>({auth, status, title_cart}),
    {fetchTitleCart})(Payment);
