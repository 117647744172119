import {Fragment, h} from "preact";
import {Card, CF} from "../../_common/layout";
import {t} from '../../_i18n'
import ServicePolicy from "./servicePolicy";

export const PaymentSuccess =()=><Fragment>
    <CF className="max-width">
        <Card smOnly={false}>
            <p className='bold'>{t('Pago de tasas realizado correctamente')}.</p>
            <div className='background-info card p-left p-right m-x2-bottom'>
                <p>{t('En un plazo máximo de 15 días desde la fecha del pago de tasas, recibirás por correo certificado urgente la documentación que acredita que has finalizado tu formación')}.</p>
                <p className='bold'>{t('Los documentos que recibirás tienen la misma validez que el título oficial que nos llegará a nuestra sede en un plazo aproximado de dos años')}.</p>
                <p>{t('Podrás seguir el envío a través de un enlace que te proporcionará la empresa de correos GLS')}.</p>
            </div>
        </Card>
        <ServicePolicy />
    </CF>
</Fragment>;
