import {t} from "../../_i18n";


export function showErrorClosed(meta) {
    return (meta.invalid && (meta.submitFailed));
}

export const validateMail = (text) => !text?.match(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/g)

export const validateSameMail = (objectMails) => {
    if (!objectMails) return false
    let error = false;
    if (objectMails.mail1 !== objectMails.mail2)
        error = t('El e-mail no coincide. Por favor, revísalo y escribe el e-mail correctamente.')

    return error
}
