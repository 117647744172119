import { h } from "preact";
import SignAddress from '../SignAddress/SignAddress'
import AlertCollapse from '../../../_common/components/AlertCollapse/AlertCollapse'
import { t } from '../../../_i18n'


export default function AlertContractCat ({ date, className }) {
  const titleText = t('👋 Nos tienes que enviar el convenio firmado antes del {date}').replace('{date}', date)

  return (
    <AlertCollapse title={titleText} className={className}>
      <ol>
        <li>{t('Descárgate el convenio e imprime 3 copias, una para la empresa, otra para ti, y otra para ILERNA.')}</li>
        <li dangerouslySetInnerHTML={{ __html: t('alert_contract_cat') }} />
        <li>{t('Posteriormente fírmalos tú también y envíanos una de las copias a la siguiente dirección postal:')}</li>
      </ol>
      <SignAddress className='m-top' />
    </AlertCollapse>
  )
}
