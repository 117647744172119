/**
 * New Expedient
 * Expedient for Student Account
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AcademicProgram from '../model/AcademicProgram';
import AcademicProgramItem from '../model/AcademicProgramItem';
import Error from '../model/Error';

/**
* AcademicPrograms service.
* @module api/AcademicProgramsApi
* @version 1.0
*/
export default class AcademicProgramsApi {

    /**
    * Constructs a new AcademicProgramsApi. 
    * @alias module:api/AcademicProgramsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get Student AcademicProgram first level expedient data
     * @param {Number} id 
     * @param {Number} academicProgramId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AcademicProgram} and HTTP response
     */
    studentExpedientControllerGetAcademicProgramExpedientWithHttpInfo(id, academicProgramId) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling studentExpedientControllerGetAcademicProgramExpedient");
      }
      // verify the required parameter 'academicProgramId' is set
      if (academicProgramId === undefined || academicProgramId === null) {
        throw new Error("Missing the required parameter 'academicProgramId' when calling studentExpedientControllerGetAcademicProgramExpedient");
      }

      let pathParams = {
        'id': id,
        'academicProgramId': academicProgramId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['BearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = AcademicProgram;
      return this.apiClient.callApi(
        '/account/students/{id}/expedient/academic-programs/{academicProgramId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get Student AcademicProgram first level expedient data
     * @param {Number} id 
     * @param {Number} academicProgramId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AcademicProgram}
     */
    studentExpedientControllerGetAcademicProgramExpedient(id, academicProgramId) {
      return this.studentExpedientControllerGetAcademicProgramExpedientWithHttpInfo(id, academicProgramId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get Student AcademicPrograms enrolled list
     * @param {Number} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AcademicProgramItem>} and HTTP response
     */
    studentExpedientControllerListAcademicProgramsWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling studentExpedientControllerListAcademicPrograms");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['BearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [AcademicProgramItem];
      return this.apiClient.callApi(
        '/account/students/{id}/expedient/academic-programs', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get Student AcademicPrograms enrolled list
     * @param {Number} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AcademicProgramItem>}
     */
    studentExpedientControllerListAcademicPrograms(id) {
      return this.studentExpedientControllerListAcademicProgramsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
