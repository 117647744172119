import {
    SET_FIXTURES,
    SET_MATRICULA,
    SET_DOCUMENT,
    SET_STATUS,
    SET_COURSE_ACCESS,
    SET_STUDENT,
    SET_CERTIFIED_COURSE_ACCESS,
    SET_TITLE_CART,
    SET_TITLE_PLACE,
    SET_ACADEMIC_PROGRAM_DOCUMENT,
    SET_LANGUAGE,
    SET_USER_GROUP_TYPE, SET_STUDENT_ACCESS, SET_BANNER
} from "../actions/constants";
import { createReducer } from "../../../_common/common.ts";

export const matricula = createReducer(null, {
    [SET_MATRICULA]: (state, matricula)=> matricula,
});

export const fixtures = createReducer(null, {
    [SET_FIXTURES]: (state, fixtures)=> fixtures,
});

export const course_access = createReducer(null, {
    [SET_COURSE_ACCESS]: (state, course_access)=> course_access,
});

export const certified_course_access = createReducer(null, {
    [SET_CERTIFIED_COURSE_ACCESS]: (state, certified_course_access)=> certified_course_access,
});

export const student = createReducer(null, {
    [SET_STUDENT]: (state, student)=> student,
});

export const student_access = createReducer(null, {
    [SET_STUDENT_ACCESS]: (state, type)=> type,
});

export const lang = createReducer(null, {
    [SET_LANGUAGE]: (state, lang)=> lang,
});

export const student_document = createReducer(null, {
    [SET_DOCUMENT]: (state, document)=> document,
});

export const academic_program_document = createReducer(null, {
    [SET_ACADEMIC_PROGRAM_DOCUMENT]: (state, document)=> document,
});

export const status = createReducer(null, {
    [SET_STATUS]: (state, status)=> status,
});

export const title_cart = createReducer(null, {
    [SET_TITLE_CART]: (state, title_cart)=> title_cart,
});
export const title_place = createReducer(null, {
    [SET_TITLE_PLACE]: (state, title_place)=> title_place,
});

export const banner = createReducer(null, {
    [SET_BANNER]: (state, banner)=> banner,
});
