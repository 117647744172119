import { createReducer } from "../../../_common/common";
import { SET_FCT_STATE, SET_FCT_SCREEN_STATE, SET_FCT_MESSAGES, SET_FCT_STATIC_DOCUMENTS } from "../actions/constants";

export const fct = createReducer({state: null, screenState: null, messages: [], static_documents: null}, {

  [SET_FCT_STATE]: (state, { val })=>{
    state.state = val;
    return state
  },

  [SET_FCT_SCREEN_STATE]: (state, { screenState })=>{
    state.screenState = screenState;
    return state
  },

  [SET_FCT_MESSAGES]: (state, { messages })=>{
    state.messages = messages;
    return state
  },

  [SET_FCT_STATIC_DOCUMENTS]: (state, { static_documents })=>{
    state.static_documents = static_documents;
    return state
  },

});
