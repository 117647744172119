/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CompanyCompany model module.
 * @module model/CompanyCompany
 * @version v1
 */
class CompanyCompany {
    /**
     * Constructs a new <code>CompanyCompany</code>.
     * Ficha de empresa [P_E_16]
     * @alias module:model/CompanyCompany
     */
    constructor() { 
        
        CompanyCompany.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CompanyCompany</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CompanyCompany} obj Optional instance to populate.
     * @return {module:model/CompanyCompany} The populated <code>CompanyCompany</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CompanyCompany();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('nif')) {
                obj['nif'] = ApiClient.convertToType(data['nif'], 'String');
            }
            if (data.hasOwnProperty('sector')) {
                obj['sector'] = ApiClient.convertToType(data['sector'], 'String');
            }
            if (data.hasOwnProperty('text')) {
                obj['text'] = ApiClient.convertToType(data['text'], 'String');
            }
            if (data.hasOwnProperty('subtitle')) {
                obj['subtitle'] = ApiClient.convertToType(data['subtitle'], 'String');
            }
            if (data.hasOwnProperty('logo')) {
                obj['logo'] = ApiClient.convertToType(data['logo'], 'String');
            }
            if (data.hasOwnProperty('web')) {
                obj['web'] = ApiClient.convertToType(data['web'], 'String');
            }
            if (data.hasOwnProperty('location')) {
                obj['location'] = ApiClient.convertToType(data['location'], 'String');
            }
            if (data.hasOwnProperty('empleados')) {
                obj['empleados'] = ApiClient.convertToType(data['empleados'], 'Number');
            }
            if (data.hasOwnProperty('video')) {
                obj['video'] = ApiClient.convertToType(data['video'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
CompanyCompany.prototype['id'] = undefined;

/**
 * @member {String} name
 */
CompanyCompany.prototype['name'] = undefined;

/**
 * @member {String} nif
 */
CompanyCompany.prototype['nif'] = undefined;

/**
 * @member {String} sector
 */
CompanyCompany.prototype['sector'] = undefined;

/**
 * @member {String} text
 */
CompanyCompany.prototype['text'] = undefined;

/**
 * @member {String} subtitle
 */
CompanyCompany.prototype['subtitle'] = undefined;

/**
 * Este campo solo viene en GET. Contiene url final para GET de logo
 * @member {String} logo
 */
CompanyCompany.prototype['logo'] = undefined;

/**
 * @member {String} web
 */
CompanyCompany.prototype['web'] = undefined;

/**
 * @member {String} location
 */
CompanyCompany.prototype['location'] = undefined;

/**
 * @member {Number} empleados
 */
CompanyCompany.prototype['empleados'] = undefined;

/**
 * @member {String} video
 */
CompanyCompany.prototype['video'] = undefined;






export default CompanyCompany;

