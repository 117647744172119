/**
 * Student Account
 * Student Account Api - Validation flow
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dmartinez@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Doc from './Doc';
import Document from './Document';
import DocumentType from './DocumentType';

/**
 * The StudentCertifiedCourseAccessDocument model module.
 * @module model/StudentCertifiedCourseAccessDocument
 * @version 1.0.0
 */
class StudentCertifiedCourseAccessDocument {
    /**
     * Constructs a new <code>StudentCertifiedCourseAccessDocument</code>.
     * @alias module:model/StudentCertifiedCourseAccessDocument
     */
    constructor() { 
        
        StudentCertifiedCourseAccessDocument.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>StudentCertifiedCourseAccessDocument</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StudentCertifiedCourseAccessDocument} obj Optional instance to populate.
     * @return {module:model/StudentCertifiedCourseAccessDocument} The populated <code>StudentCertifiedCourseAccessDocument</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StudentCertifiedCourseAccessDocument();

            if (data.hasOwnProperty('block_id')) {
                obj['block_id'] = ApiClient.convertToType(data['block_id'], 'Number');
            }
            if (data.hasOwnProperty('docs')) {
                obj['docs'] = ApiClient.convertToType(data['docs'], [Doc]);
            }
            if (data.hasOwnProperty('student_id')) {
                obj['student_id'] = ApiClient.convertToType(data['student_id'], 'Number');
            }
            if (data.hasOwnProperty('expired')) {
                obj['expired'] = ApiClient.convertToType(data['expired'], 'Boolean');
            }
            if (data.hasOwnProperty('expires_at')) {
                obj['expires_at'] = ApiClient.convertToType(data['expires_at'], 'String');
            }
            if (data.hasOwnProperty('document_type')) {
                obj['document_type'] = DocumentType.constructFromObject(data['document_type']);
            }
            if (data.hasOwnProperty('files')) {
                obj['files'] = ApiClient.convertToType(data['files'], [Document]);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} block_id
 */
StudentCertifiedCourseAccessDocument.prototype['block_id'] = undefined;

/**
 * @member {Array.<module:model/Doc>} docs
 */
StudentCertifiedCourseAccessDocument.prototype['docs'] = undefined;

/**
 * @member {Number} student_id
 */
StudentCertifiedCourseAccessDocument.prototype['student_id'] = undefined;

/**
 * @member {Boolean} expired
 */
StudentCertifiedCourseAccessDocument.prototype['expired'] = undefined;

/**
 * @member {String} expires_at
 */
StudentCertifiedCourseAccessDocument.prototype['expires_at'] = undefined;

/**
 * @member {module:model/DocumentType} document_type
 */
StudentCertifiedCourseAccessDocument.prototype['document_type'] = undefined;

/**
 * @member {Array.<module:model/Document>} files
 */
StudentCertifiedCourseAccessDocument.prototype['files'] = undefined;






export default StudentCertifiedCourseAccessDocument;

