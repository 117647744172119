import React, {Component, Fragment, h} from "preact";
import {t} from "../../../_i18n";
import {E, FR} from "../../../_common/layout";
import {Select} from "../../../_common/forms/components/components";
import {connect} from "react-redux";
import {downloadCertificate} from "../../../_rx/expediente/actions";
import Modal from "../../../_common/components/Modal/index";
import {ButtonLink, ButtonPrimary} from "../../../_common/components/buttons/buttons";
import {UseModalConf} from "./useModalConf";
import ButtonSpinner from "../../../_common/components/buttons/buttonSpinner/buttonSpinner";

const selectOptions = [
    {key: 'es', name:t('Castellano')},
    {key: 'ca', name:t('Catalán')}
]

export let ModalGradeCertificate = ({academicProgram, studentId, visible, closeModal}) => {
    const {opt, setOpt, loading, handleCloseModal, handleSubmit } = UseModalConf({closeModal, academicProgram, studentId, actionFn: downloadCertificate,})

    return <Modal visible={visible} closeModal={handleCloseModal}>
        <div>
            <FR className={'modal-title-padding row-2-el p-all p-x2-left p-x2-right'}>
                <div className='col-80'>
                    <p className="t-left font-18 no-margin">{t('Exportar certificado')}</p>
                </div>
                <div className={'t-right col-20'}>
                    <a onClick={handleCloseModal} className="modal-close-jobs modal-link"> <i className="fal fa-times" /> </a>
                </div>
            </FR>
            <hr className="separator-xxs no-margin-top"/>
            <FR className='row-1-el p-x2-left p-x2-right p-top p-x2-bottom'>
                <div className='sm-col-70 col-100'>
                    <Select
                        required
                        label={t('Selecciona el idioma del certificado')}
                        onChange={(e) => setOpt(e.target.value)}
                        options={selectOptions}
                    />
                </div>
                <FR className='m-x2-top col-100 sm-col-70'>
                    <div className='full-width sm-no-full-width'>
                        <ButtonSpinner className='full-width sm-no-full-width' disabled={!opt} showSpinner={loading} onClick={handleSubmit}>{t('Descargar')}</ButtonSpinner>
                    </div>
                    <div className='full-width sm-no-full-width m-top sm-no-m-top'>
                        <ButtonLink className='sm-m-l full-width sm-no-full-width' onClick={handleCloseModal}>{t('Cancelar')}</ButtonLink>
                    </div>
                </FR>
            </FR>
        </div>
    </Modal>
}


ModalGradeCertificate = connect(
    ({auth: {loggedUser}}) => ({ studentId : loggedUser.id}),
    {})(ModalGradeCertificate);

let ModalGradeCertificateContainer = ({...props}) => <ModalGradeCertificate {...props}/>

export default ModalGradeCertificateContainer;
