import React, {h} from "preact";
import {t} from "../../_i18n";
import {connect} from "react-redux";
import Modal from "../../_common/components/Modal/index";
import {CF, FR} from "../../_common/layout";
import {useEffect, useState} from "preact/hooks";
import ButtonSpinner from "../../_common/components/buttons/buttonSpinner/buttonSpinner";
import "./modalFinishedAPCertificate.scss"
import {downloadFile} from "../../utils";
import {TOKEN_EXPEDIENT} from "../../constants";
import axios from "axios";
import {ButtonGhost} from "../../_common/components/buttons/buttons";
import {toastService} from "../../_services/toast-service";
import Loading from "../../_common/components/loading/Loading";

export let ModalFinishedAPCertificate = ({academicProgram, visible, closeModal}) => {
    const [selectedOption, setSelectedOption] = useState(null)
    const [loading, setLoading] = useState(false)
    const [sendingEmail, setSendingEmail] = useState(false)
    const [options, setOptions] = useState([])

    useEffect(async () => {
        setSendingEmail(false);
        setLoading(false);
        setSelectedOption(null)
        if (academicProgram.certificates)
            setOptions(academicProgram.certificates)
        else
            setOptions([])
    }, [academicProgram])

    const getName = (certificate) => {
        if (certificate.digitally_signed && certificate.name.includes('univers')) {
            return t('Certificado de ciclo formativo finalizado para acceder a la universidad (firma digital)')
        } else if (!certificate.digitally_signed && certificate.name.includes('univers')) {
            return t('Certificado de ciclo formativo finalizado para acceder a la universidad')
        } else if (certificate.digitally_signed && !certificate.name.includes('univers')) {
            return t('Certificado de ciclo formativo finalizado (firma digital)')
        } else if (!certificate.digitally_signed && !certificate.name.includes('univers')) {
            return t('Certificado de ciclo formativo finalizado')
        }
    }

    const getHint = (certificate) => {
        if (certificate.digitally_signed && certificate.name.includes('univers')) {
            return t('Obtén tu certificado de notas compulsado digitalmente. Podrás solicitarlo una única vez y lo recibirás por correo electrónico.')
        } else if (!certificate.digitally_signed && certificate.name.includes('univers')) {
            return t('Descarga tu certificado de notas para la universidad con sello y firma.')
        } else if (certificate.digitally_signed && !certificate.name.includes('univers')) {
            return t('Obtén tu certificado de notas compulsado digitalmente. Podrás solicitarlo una única vez y lo recibirás por correo electrónico.')
        } else if (!certificate.digitally_signed && !certificate.name.includes('univers')) {
            return t('Descarga tu certificado de notas con sello y firma.')
        }
    }

    const handleSubmit = async () => {
        if (selectedOption.downloable === true) {
            setLoading(true);
            downloadFile({
                url: selectedOption.link,
                fileName: 'finished_grade_certificate',
                TOKEN: TOKEN_EXPEDIENT,
                method: 'POST'
            }).then(() => setLoading(false));
        } else {
            setSendingEmail(true);
            const axiosOptions = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + TOKEN_EXPEDIENT,
                }
            }
            try {
                const resp = await axios.post(selectedOption.link, {}, axiosOptions)
                if (resp.data) {
                    if (resp.data[0].includes('error')) {
                        toastService.showToast({message: t("Se ha producido un error en el envío"), type: 'danger'});
                    } else {
                        toastService.showToast({message: t("Email enviado correctamente"), type: 'info'});
                    }
                }


                const updatedOptions = options.map(option => {
                    if (option === selectedOption) {
                        return {...option, disabled: true, link: null};
                    }
                    return option;
                });
                setOptions(updatedOptions);

            } catch (error) {
                toastService.showToast({message: t("Se ha producido un error en el envío"), type: 'danger'});
            }
            setSendingEmail(false);
        }
    }

    return <Modal visible={visible} closeModal={closeModal}>
        <div className={'modal-certificate'}>
            <FR className={'modal-title-padding row-1-el p-all p-x2-left p-x2-right'}>
                <h1 className="t-left m-left m-right no-margin-bottom">{t('Solicitar certificado de ciclo finalizado')}</h1>
            </FR>
            {sendingEmail &&
                <CF className="max-width d-flex flex-center-x flex-center-y"><Loading label={`${t('Enviando email')}. . . `}/></CF>
            }
            {!sendingEmail &&
                <FR className='p-x2-left p-x2-right p-x2-bottom d-flex flex-column'>
                    <span className='p-bottom-half'>Selecciona el tipo de certificado:</span>
                    <form onSubmit={(event) => event.preventDefault()}>
                        <div className='p-left-half'>
                            {options && options.map((certificate, index) => (
                                <FR className='certificate-option m-left m-right'>
                                    <label className={`p-all ${certificate.disabled ? 'disabled-aspect-gray' : ''}`}>
                                        <div className='row-2-el p-bottom-half'>
                                            <input
                                                type="radio"
                                                name='certificate'
                                                className='col-10'
                                                disabled={certificate.disabled}
                                                id={index.toString()}
                                                onChange={() => setSelectedOption(certificate)}
                                            />
                                            <span className='font-18 col-90'>{getName(certificate)}</span>
                                        </div>
                                        <span className='font-12'>{getHint(certificate)}</span>
                                    </label>
                                </FR>
                            ))}
                        </div>
                    </form>
                    <div className={'full-width sm-d-flex flex-right m-top sm-no-m-top'}>
                        <ButtonGhost
                            className={'full-width sm-no-full-width m-bottom sm-no-m-bottom'}
                            onClick={closeModal}
                        >
                            {t('Cancelar')}
                        </ButtonGhost>
                        <ButtonSpinner
                            className='full-width sm-no-full-width'
                            classNameContainer={'full-width sm-no-full-width sm-m-left'}
                            disabled={selectedOption === null}
                            showSpinner={loading}
                            onClick={handleSubmit}
                        >
                            {t('Solicitar certificado')}
                        </ButtonSpinner>
                    </div>
                </FR>
            }
        </div>
    </Modal>
}


ModalFinishedAPCertificate = connect(
    ({auth: {loggedUser}}) => ({studentId: loggedUser.id}),
    {})(ModalFinishedAPCertificate);

let ModalFinishedAPCertificateContainer = ({...props}) => <ModalFinishedAPCertificate {...props}/>

export default ModalFinishedAPCertificateContainer;
