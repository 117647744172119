/**
 * LTI Enroll Exam
 * LTI Enroll Exam for Students
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The SubjectOffer model module.
 * @module model/SubjectOffer
 * @version 2.0.0
 */
class SubjectOffer {
    /**
     * Constructs a new <code>SubjectOffer</code>.
     * @alias module:model/SubjectOffer
     */
    constructor() { 
        
        SubjectOffer.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SubjectOffer</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SubjectOffer} obj Optional instance to populate.
     * @return {module:model/SubjectOffer} The populated <code>SubjectOffer</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SubjectOffer();

            if (data.hasOwnProperty('UF')) {
                obj['UF'] = ApiClient.convertToType(data['UF'], 'String');
            }
            if (data.hasOwnProperty('total_pacs')) {
                obj['total_pacs'] = ApiClient.convertToType(data['total_pacs'], 'Number');
            }
            if (data.hasOwnProperty('presented_pacs')) {
                obj['presented_pacs'] = ApiClient.convertToType(data['presented_pacs'], 'Number');
            }
            if (data.hasOwnProperty('percentage_presented_pacs')) {
                obj['percentage_presented_pacs'] = ApiClient.convertToType(data['percentage_presented_pacs'], 'Number');
            }
            if (data.hasOwnProperty('ce_grade')) {
                obj['ce_grade'] = ApiClient.convertToType(data['ce_grade'], 'Number');
            }
            if (data.hasOwnProperty('all_pacs_available')) {
                obj['all_pacs_available'] = ApiClient.convertToType(data['all_pacs_available'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {String} UF
 */
SubjectOffer.prototype['UF'] = undefined;

/**
 * @member {Number} total_pacs
 */
SubjectOffer.prototype['total_pacs'] = undefined;

/**
 * @member {Number} presented_pacs
 */
SubjectOffer.prototype['presented_pacs'] = undefined;

/**
 * @member {Number} percentage_presented_pacs
 */
SubjectOffer.prototype['percentage_presented_pacs'] = undefined;

/**
 * @member {Number} ce_grade
 */
SubjectOffer.prototype['ce_grade'] = undefined;

/**
 * @member {Boolean} all_pacs_available
 */
SubjectOffer.prototype['all_pacs_available'] = undefined;






export default SubjectOffer;

