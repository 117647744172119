import React, {Component, Fragment, h} from "preact";
import {connect} from "react-redux";
import '../_common/css/components/header.scss';
import Logo from '../_common/components/img/ilerna-rebranding.svg';
import {Link} from "react-router-dom";
import * as consts from "../constants.js";
import {ilernaWeb, ilernaWebPage, PS_SHOPID_FR, PS_SHOPID_IT} from "../constants.js";
import {logout} from "../_rx/_auth/actions";
import {t} from '../_i18n';
import {replaceHash, setClass} from "../_common/common.ts";
import {closeMenu} from '../_common/common';
import "./header-component.scss";
import {withRouter} from "react-router";
import {useEffect, useState} from "preact/hooks";
import AuthService from "../_services/auth-service";
import {
    getLinksStudentEmpty,
    getLinksStudentIsOnSite,
    getLinksStudentNoTitle,
    getLinksStudentNoValidated,
    getLinksStudentValidated,
    getLinksStudentNoSignedConditions
} from "./user-roles-header";
import Badge from "../_common/components/Badge";
import {isSpanishUser} from "../_common/utils/getLang";
import {
    STUDENT_IS_ON_SITE,
    STUDENT_NO_ENROLLS,
    STUDENT_NO_TITLE,
    STUDENT_NO_VALIDATED,
    STUDENT_TRIAL,
    STUDENT_VALIDATED,
    STUDENT_NO_SIGNED_CONDITIONS
} from './getUserRol'


const Links = ({logout, appLinks}) => <div>

    {appLinks && appLinks.filter(link => link.className != 'campus').map(link => {
        if (link.sep)
            return <hr/>
        if (link.disabled)
            return <a href="#" className="disabled-link disabled-aspect-gray">{link.text} {link.extra}</a>;
        if (link.dispatch.startsWith('https:'))
            return <a href={link.dispatch} target="_blank">{link.text}</a>;
        if (link.dispatch.startsWith('url:'))
            return <Link key={link.text} to={link.dispatch.substring(4)}>{link.text} {link.extra}</Link>;
        if (link.dispatch === 'exec:logout')
            return <a key={link.dispatch} href="#" onClick={e => {
                e.preventDefault();
                logout();
            }} className="close-sesion">{link.text} {link.extra}</a>;
    })}
</div>;


export const LinkCampus = (path, shop_id) => isWebApp ?
    micuentaenv.Campus_BaseUrl + path :
    AuthService.instance().generateAutologgedUrl(shop_id, micuentaenv.Campus_BaseUrl + path);

/**
 * TODO: Considerar utilizar redux selectors (reselect - https://github.com/reduxjs/reselect) para este tipo de cosas (v2)
 * @param status Objeto status (redux store)
 * @param comStatus Objeto comunicados_status (redux store)
 * @returns {boolean}
 */
export function comunicadosAvailable(status, comStatus) {
    let mat_pendiente = Object.values(status.blocks).filter(st => st.block_name !== 'certified_course_access_document').filter(st => st.status && st.status.id === consts.STATUS_ID_PENDING).length > 0;
    let mat_revisar = Object.values(status.blocks).filter(st => st.block_name !== 'certified_course_access_document').filter(st => st.status && st.status.id === consts.STATUS_ID_ERROR).length > 0
    return !mat_pendiente && !mat_revisar && comStatus && comStatus.unread > 0
}


export const computeAppLinks = (status, auth, student_access, coms, status_presta, lang, showGift) => {
    const headerOptions = {};
    const showInviteFriend = status_presta?.InviteFriendStatus?.status !== 'Disabled';
    const isInitialStudent = student_access === STUDENT_NO_VALIDATED || student_access === STUDENT_NO_TITLE

    const {canvas_id, canvas_access, shop_id, has_coursing_enroll, has_any_order, is_on_site} = status.student_meta;

    let mat_pendiente = Object.values(status.blocks).filter(st => st.block_name !== 'student_academic_program').some(st => st.status && st.status.id == consts.STATUS_ID_PENDING);
    let mat_revisar = Object.values(status.blocks).filter(st => st.block_name !== 'student_academic_program').some(st => st.status && st.status.id == consts.STATUS_ID_ERROR);

    //solicitud de titulo, mostrar el punto de menu durante los 2 anyos de proceso
    let titulo = status.blocks['5'] && ![consts.STATUS_CARD_ID_UNAVALIABLE, consts.STATUS_CARD_ID_DELIVERED].includes(status.blocks['5'].status.id)
    // mostrar el badge de pendiente cuando se requiere accion
    let titulo_pendiente = status.blocks['5'] && [consts.STATUS_CARD_ID_PENDING1, consts.STATUS_CARD_ID_PENDING2].includes(status.blocks['5'].status.id)

    let coms_extra = isWebApp && coms && coms.unread > 0 &&
        <Badge className='badge-menu badge-notice font-small'>{coms.unread}</Badge>
    const cPendiente = <Badge className="badge-menu badge-warning font-small">{t('Pendiente')}</Badge>
    const cError = <Badge className="badge-menu badge-warning font-small">{t('Revisar')}</Badge>

    let extra_mat = isWebApp && mat_pendiente ? cPendiente : '';
    extra_mat = isWebApp && mat_revisar ? cError : extra_mat;

    // Definir las opciones:
    headerOptions.optSep = {sep: true}

    //let optExpTitulo = {text: t('Expedición del título'), dispatch:"url:/academic-program-card"}
    //if(titulo && titulo_pendiente) optExpTitulo = {...optExpTitulo, extra: cPendiente};

    headerOptions.optHome = {text: t('Inicio'), dispatch: "url:/"}
    headerOptions.optSecretary = {text: t('Secretaría académica'), dispatch: "url:/secretaria"}
    headerOptions.optOnlinerArea = {text: t('Área Onliner'), dispatch: "url:/online-area"}
    headerOptions.optAccesoCampus = {
        text: t('Accede al campus'),
        dispatch: student_access === STUDENT_IS_ON_SITE ? status.student_meta.presential_campus_url : status.student_meta.online_campus_url,
        className: "campus",
        inAppBrowser: true,
        disabled: !canvas_access
    }
    headerOptions.optInicio = {text: t('Inicio'), dispatch: "url:/notices", extra: coms_extra, disabled: !canvas_access}
    headerOptions.optCompletaMatricula = {
        text: t(isInitialStudent ? 'Completa tu matricula' : 'Configuración de la cuenta'),
        dispatch: "url:/student",
        extra: extra_mat
    }
    headerOptions.optHistorialMatriculas = {text: t('Historial de matrículas'), dispatch: "url:/enrollments"}
    headerOptions.optPedidos = {text: t('Tus pedidos'), dispatch: "url:/enrollments"}
    headerOptions.optJobs = {text: t('Bolsa de trabajo'), dispatch: "url:/jobs"}
    headerOptions.inviteFriend = {text: t('Invitar a un amigo') + ' 🙂', dispatch: "url:/invite"}
    headerOptions.notices = {text: t('Comunicados'), dispatch: "url:/comunicados"}

    headerOptions.optDirEnvio = {text: t('Dirección de envío'), dispatch: "url:/address"}
    headerOptions.optCerrar = {text: t('Cerrar sesión'), className: "logout", dispatch: "exec:logout"}
    //let optExamenes = {text: t('Exámenes'), dispatch:"url:/examenes"}
    headerOptions.gift = {text: t('Solicitar regalo'), dispatch: "url:/gift"}
    headerOptions.optShop = {
        text: t('Página web de ILERNA'),
        dispatch: ilernaWebPage[lang] ? ilernaWebPage[lang] : ilernaWeb
    }
    headerOptions.optExpediente = {text: t('Expediente'), dispatch: "url:/expediente"}

    // LTIs (futuras opciones a integrar)
    headerOptions.optAccesoCampusMenu = {
        text: t('Accede al campus'),
        dispatch: student_access === STUDENT_IS_ON_SITE ? status.student_meta.presential_campus_url : status.student_meta.online_campus_url,
        inAppBrowser: true,
        disabled: !canvas_access
    } // por decreto ley se quiere el link al campus dentro del menu tambien
    headerOptions.optExamenes = {text: t('Exámenes'), dispatch: "url:/examenes", disabled: !canvas_access}
    headerOptions.optClasesPresenciales = {
        text: t('Clases presenciales'),
        dispatch: "url:/clases",
        disabled: !canvas_access
    }
    //headerOptions.optExpediente = {text: t('Expediente'), dispatch:LinkCampus(`/users/${canvas_id}/external_tools/34`, status.student_meta.shop_id), inAppBrowser: true, disabled: !canvas_access}
    headerOptions.optFct = status.student_meta?.fct_contracts.map(fct => ({
        text: `FCT - ${fct.program}`,
        dispatch: `url:/fct/${fct.contract_id}`
    }))
    /*headerOptions.optFct = {text: t('FCT'), dispatch:"url:/fct"}*/

    if (student_access === STUDENT_TRIAL) return getLinksStudentEmpty({studentMeta: status.student_meta, headerOptions})
    if (student_access === STUDENT_IS_ON_SITE) return getLinksStudentIsOnSite({
        studentMeta: status.student_meta,
        headerOptions,
        lang
    })
    if (student_access === STUDENT_NO_SIGNED_CONDITIONS) return getLinksStudentNoSignedConditions({
        headerOptions
    })    
    if (student_access === STUDENT_NO_ENROLLS) return getLinksStudentEmpty({
        studentMeta: status.student_meta,
        headerOptions
    })    
    if (student_access === STUDENT_NO_VALIDATED) return getLinksStudentNoValidated({
        studentMeta: status.student_meta,
        headerOptions
    })
    if (student_access === STUDENT_NO_TITLE) return getLinksStudentNoTitle({
        studentMeta: status.student_meta,
        headerOptions,
        lang
    })
    if (student_access === STUDENT_VALIDATED) return getLinksStudentValidated({
        studentMeta: status.student_meta,
        headerOptions,
        showGift,
        showInviteFriend,
        lang
    })
    
};


const HeaderWide = (props) => {
    const {student_access, status: {student_meta}, banner} = props
    const isValidated = (student_access === STUDENT_IS_ON_SITE || student_access === STUDENT_VALIDATED)

    const campusLink = props.appLinks && props.appLinks.find(lnk => lnk.className == 'campus');

    return <div className="d-none md-d-block">
        {/*<div class="over-header">Llamanos GRATIS <strong>900 730 222</strong></div>*/}
        <header className="menu d-flex">
            <div className="menu-item-left">
                <MenuDesktopLeft {...props} />
            </div>
            <div className="right-menu">
                {campusLink && props.status.student_meta.canvas_access && !isValidated &&
                    <div className="menu-item-right">
                        <a href={student_meta.online_campus_url} target="_blank"
                           className={'btn btn-primary btn-inverse m-r' + (campusLink.disabled ? 'disabled-link' : '')}>
                            {t('Accede al campus')}
                        </a>

                    </div>}
                {student_access === STUDENT_VALIDATED &&
                    <div className="menu-item-right">
                        <Link to='/expediente'
                              style='margin-right: 16px'
                              className='font-color-149BAB t-underline cursor-pointer'>
                            {t('Expediente')}
                        </Link>
                    </div>
                }

                {isSpanishUser(props.status.student_meta.shop_id) && <div className="menu-item-right">
                    <a className="nav-help" href="#" onclick={(e) => window.showZendeskWidget(e)} target="_blank">
                        <i className="fal fa-question-circle"/>
                    </a>
                </div>
                }

                <div className="menu-item-right">
                    <MenuDesktopRight {...props} />
                </div>
            </div>
            {banner && <div className='menu-banner' dangerouslySetInnerHTML={{__html: banner}}/>}
        </header>
    </div>
};


const MenuDesktopLeft = (props) => {
    const [shown, setShown] = useState(true);

    useEffect(() => {
        function docClick(event) {
            if (event.path && event.path.filter(ele => ele.id === 'label-menu-left').length > 0)
                return;
            if (!shown) {
                setShown(true)
            }
        }

        document.addEventListener("click", docClick);
        return () => {
            document.removeEventListener("click", docClick);
        };
    }, [shown]);

    return <section className="dropdown">
        <div className="dropdown-element">
            <label onClick={() => setShown(!shown)} id="label-menu-left">
                <div>
                    <Link to='/'><img src={Logo} className="logo"/></Link>
                </div>
            </label>
        </div>
    </section>
}


const MenuDesktopRight = (props) => {
    const [shown, setShown] = useState(true);

    useEffect(() => {
        function docClick(event) {
            if (event.path && event.path.filter(ele => ele.id === 'label-menu-right').length > 0)
                return;
            if (!shown) {
                setShown(true)
            }
        }

        document.addEventListener("click", docClick);
        return () => {
            document.removeEventListener("click", docClick);
        };
    }, [shown]);


    const comunicadosActivos = comunicadosAvailable(props.status, props.comunicados_status);
    return <section className="dropdown">
        <div className="dropdown-element">
            <label onClick={() => setShown(!shown)} id="label-menu-right">
                {comunicadosActivos && props.status.student_meta && props.status.student_meta.canvas_access &&
                    <i className="fas fa-circle bubble fa-xs" aria-hidden="true"></i>}
                <i className="fal fa-user-circle fa-lg"/>
                {shown ? <i className="fas fa-chevron-down fa-xs"/> : <i className="fas fa-chevron-up fa-xs"/>}
            </label>
            {!shown && <article className="right menu-handler">
                <a className="nombre-alumno">{props.status.student_meta.student_name}</a>
                <Links {...props} />
            </article>}
        </div>
    </section>
}


const HeaderMobile = (props) => {
    const {student_access, status: {student_meta}} = props
    const campusLink = props.appLinks && props.appLinks.find(lnk => lnk.className == 'campus');
    const esItalia = props.status.student_meta.shop_id == PS_SHOPID_IT;
    const esFrancia = props.status.student_meta.shop_id == PS_SHOPID_FR;
    const isSpain = isSpanishUser(props.status.student_meta.shop_id);
    const isValidated = (student_access === STUDENT_IS_ON_SITE || student_access === STUDENT_VALIDATED)

    const comunicadosActivos = comunicadosAvailable(props.status, props.comunicados_status);
    return <header className="menu-mobile d-flex md-d-none">
        <div className="left-menu">
            <div className="menu-item-left">
                {comunicadosActivos && props.status.student_meta.canvas_access &&
                    <i className="fas fa-circle bubble fa-xs"></i>}
                <PopMenu id="mainmenu" button={() =>
                    <Fragment>
                        <i className="fal fa-bars fa-lg"/>
                    </Fragment>}>
                    <span className="nombre-alumno">{props.status.student_meta.student_name}</span>
                    <Links {...props} />
                    <section>
                        {esItalia && <a target="_blank" href="https://www.ilernaonline.it"> ILERNA </a>}
                        {esFrancia && <a target="_blank" href="https://www.ilerna.com/fr/"> ILERNA </a>}
                        {isSpain && <Fragment>
                            <a target="_blank" href="https://www.ilerna.es"> ILERNA </a>
                            <a target="_blank" href="https://www.ilerna.es/fp-lleida/es/"> ILERNA Lleida </a>
                            <a target="_blank" href="https://www.ilerna.es/fp-madrid/"> ILERNA Madrid </a>
                            <a target="_blank" href="https://www.ilerna.es/fp-sevilla/"> ILERNA Sevilla </a>
                            <a target="_blank" href="https://www.ilerna.es/es/fp-a-distancia"> ILERNA Online </a>
                            <a target="_blank" href="https://www.ilerna.es/es/fp-universidad"> FP+Universidad </a>
                        </Fragment>}
                    </section>
                </PopMenu>
            </div>
            <div className="logo">
                <Link to='/'><img src={Logo} alt="ILERNA"/></Link>
            </div>
        </div>
        <div className="right-menu">
            {student_access === STUDENT_VALIDATED &&
                <div className="menu-item-right">
                    <Link to='/expediente'
                          className='font-color-149BAB cursor-pointer'
                          style='margin-right: 24px; text-decoration: underline !important'>
                        {t('Expediente')}
                    </Link>
                </div>
            }

            {isSpanishUser(props.status.student_meta.shop_id) &&
                <div className="menu-item-right">
                    <a className="nav-help" href="#" onclick={(e) => window.showZendeskWidget(e)} target="_blank">
                        <i className="fal fa-question-circle fa-lg"/>
                    </a>
                </div>
            }
            {/*{campusLink && props.status.student_meta && props.status.student_meta.canvas_access && !isValidated &&*/}
            {/*    <a href={student_meta.online_campus_url} target="_blank"*/}
            {/*       className={"menu-item-right btn btn-inverse " + (campusLink.disabled ? 'disabled-link' : '')}>{t('Campus')}</a>*/}
            {/*}*/}
        </div>
    </header>
}

const PopMenu = withRouter(class extends Component {
    state = {open: location.hash.endsWith(this.props.id)};

    componentDidUpdate() {
        setClass(document.body, 'modal-visible', this.state.open);
    }

    componentDidMount() {
        this.props.history.listen((location) => {
            this.setState({open: location.hash.endsWith(this.props.id)});
        });
        closeMenu();
    }

    open() {
        replaceHash(this.props.id);
        this.setState({open: true});
    }

    render({children, button}) {
        return <Fragment>
            <span onclick={() => this.open()} htmlFor={this.id}>{button()}</span>
            <article style={{display: this.state.open ? 'block' : 'none'}}>
                <header>
                    <span onclick={() => history.back()}><i className="fal fa-times fa-lg"/></span>
                </header>
                {children}
            </article>
        </Fragment>;
    }

});


export let Header = class extends Component {
    render(props) {
        return <Fragment>
            <HeaderWide {...props} />
            <HeaderMobile {...props} />
        </Fragment>
    }
}

Header = connect((state) => ({
        status: state.status,
        comunicados_status: state.comunicados_status,
        banner: state.banner
    }),
    {
        logout
    })(Header);
