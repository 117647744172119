import { h } from "preact";
import AlertCollapse from '../../../_common/components/AlertCollapse/AlertCollapse'
import { t } from '../../../_i18n'

export default function AlertLimitDateWarning ({ date }) {
  const textTitle = t('alert_limit_date_warning').replace('{date}', date.toFormat('dd/MM/yyyy'))
  const textBody = t('alert_limit_date_warning_body').replace('{date}', date.toFormat('dd/MM/yyyy'))

  return (
    <AlertCollapse title={textTitle} type='danger'>
      <div>{textBody}</div>
    </AlertCollapse>
  )
}
