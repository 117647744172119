/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import InlineResponse2001 from '../model/InlineResponse2001';
import InlineResponse2002 from '../model/InlineResponse2002';
import InlineResponse2003 from '../model/InlineResponse2003';
import InlineResponse404 from '../model/InlineResponse404';
import Notice from '../model/Notice';

/**
* Notice service.
* @module api/NoticeApi
* @version 1.0
*/
export default class NoticeApi {

    /**
    * Constructs a new NoticeApi. 
    * @alias module:api/NoticeApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Change the status of the notice from draft to confirmed
     * @param {Number} id Id of the notice
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Notice} and HTTP response
     */
    appHttpControllersNoticeControllerConfirmWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersNoticeControllerConfirm");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Notice;
      return this.apiClient.callApi(
        '/api/v1/notice/{id}/confirm', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Change the status of the notice from draft to confirmed
     * @param {Number} id Id of the notice
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Notice}
     */
    appHttpControllersNoticeControllerConfirm(id) {
      return this.appHttpControllersNoticeControllerConfirmWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Calculate the number of recipients of the notice
     * @param {Number} id Id of the notice
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2003} and HTTP response
     */
    appHttpControllersNoticeControllerCountWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersNoticeControllerCount");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2003;
      return this.apiClient.callApi(
        '/api/v1/noticeCount/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Calculate the number of recipients of the notice
     * @param {Number} id Id of the notice
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2003}
     */
    appHttpControllersNoticeControllerCount(id) {
      return this.appHttpControllersNoticeControllerCountWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a new a new notice
     * @param {String} title title
     * @param {String} body body
     * @param {Boolean} hasNotification has notification
     * @param {String} segmentOperator segment join operator (or/and)
     * @param {Object} opts Optional parameters
     * @param {String} opts.programmedAt programmed at
     * @param {Boolean} opts.hasEmail has email
     * @param {String} opts.notificationTitle title of the notification
     * @param {String} opts.notificationText text of the notification
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Notice} and HTTP response
     */
    appHttpControllersNoticeControllerCreateWithHttpInfo(title, body, hasNotification, segmentOperator, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'title' is set
      if (title === undefined || title === null) {
        throw new Error("Missing the required parameter 'title' when calling appHttpControllersNoticeControllerCreate");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling appHttpControllersNoticeControllerCreate");
      }
      // verify the required parameter 'hasNotification' is set
      if (hasNotification === undefined || hasNotification === null) {
        throw new Error("Missing the required parameter 'hasNotification' when calling appHttpControllersNoticeControllerCreate");
      }
      // verify the required parameter 'segmentOperator' is set
      if (segmentOperator === undefined || segmentOperator === null) {
        throw new Error("Missing the required parameter 'segmentOperator' when calling appHttpControllersNoticeControllerCreate");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'title': title,
        'body': body,
        'programmed_at': opts['programmedAt'],
        'has_email': opts['hasEmail'],
        'has_notification': hasNotification,
        'notification_title': opts['notificationTitle'],
        'notification_text': opts['notificationText'],
        'segment_operator': segmentOperator
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = Notice;
      return this.apiClient.callApi(
        '/api/v1/notice', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create a new a new notice
     * @param {String} title title
     * @param {String} body body
     * @param {Boolean} hasNotification has notification
     * @param {String} segmentOperator segment join operator (or/and)
     * @param {Object} opts Optional parameters
     * @param {String} opts.programmedAt programmed at
     * @param {Boolean} opts.hasEmail has email
     * @param {String} opts.notificationTitle title of the notification
     * @param {String} opts.notificationText text of the notification
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Notice}
     */
    appHttpControllersNoticeControllerCreate(title, body, hasNotification, segmentOperator, opts) {
      return this.appHttpControllersNoticeControllerCreateWithHttpInfo(title, body, hasNotification, segmentOperator, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a notice
     * @param {Number} id Id of the notice
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Notice} and HTTP response
     */
    appHttpControllersNoticeControllerDeleteWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersNoticeControllerDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Notice;
      return this.apiClient.callApi(
        '/api/v1/notice/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Delete a notice
     * @param {Number} id Id of the notice
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Notice}
     */
    appHttpControllersNoticeControllerDelete(id) {
      return this.appHttpControllersNoticeControllerDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get notice details
     * @param {Number} id Id of the notice
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Notice} and HTTP response
     */
    appHttpControllersNoticeControllerDetailsWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersNoticeControllerDetails");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Notice;
      return this.apiClient.callApi(
        '/api/v1/notice/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get notice details
     * @param {Number} id Id of the notice
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Notice}
     */
    appHttpControllersNoticeControllerDetails(id) {
      return this.appHttpControllersNoticeControllerDetailsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List all notices
     * @param {Object} opts Optional parameters
     * @param {String} opts.search title and body search filter
     * @param {String} opts.status Status of the notice (draft/confirmed)
     * @param {Number} opts.page Page number
     * @param {Number} opts.limit Number of elements per page
     * @param {module:model/String} opts.sort Sort by field
     * @param {module:model/String} opts.sortOrder sort order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2002} and HTTP response
     */
    appHttpControllersNoticeControllerIndexWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'search': opts['search'],
        'status': opts['status'],
        'page': opts['page'],
        'limit': opts['limit'],
        'sort': opts['sort'],
        'sort_order': opts['sortOrder']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2002;
      return this.apiClient.callApi(
        '/api/v1/notices', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List all notices
     * @param {Object} opts Optional parameters
     * @param {String} opts.search title and body search filter
     * @param {String} opts.status Status of the notice (draft/confirmed)
     * @param {Number} opts.page Page number
     * @param {Number} opts.limit Number of elements per page
     * @param {module:model/String} opts.sort Sort by field
     * @param {module:model/String} opts.sortOrder sort order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2002}
     */
    appHttpControllersNoticeControllerIndex(opts) {
      return this.appHttpControllersNoticeControllerIndexWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Calculate a csv with the recipients of the notice
     * @param {Number} id Id of the notice
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
     */
    appHttpControllersNoticeControllerPreviewWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersNoticeControllerPreview");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/csv', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/api/v1/noticePreview/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Calculate a csv with the recipients of the notice
     * @param {Number} id Id of the notice
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
     */
    appHttpControllersNoticeControllerPreview(id) {
      return this.appHttpControllersNoticeControllerPreviewWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a notice
     * @param {Number} id Notice id
     * @param {Object} opts Optional parameters
     * @param {String} opts.title title
     * @param {String} opts.body body
     * @param {String} opts.programmedAt programmed at
     * @param {Boolean} opts.hasEmail has email
     * @param {Boolean} opts.hasNotification has notification
     * @param {String} opts.notificationTitle title of the notification
     * @param {String} opts.notificationText text of the notification
     * @param {String} opts.segmentOperator segment join operator (or/and)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2001} and HTTP response
     */
    appHttpControllersNoticeControllerUpdateWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersNoticeControllerUpdate");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'title': opts['title'],
        'body': opts['body'],
        'programmed_at': opts['programmedAt'],
        'has_email': opts['hasEmail'],
        'has_notification': opts['hasNotification'],
        'notification_title': opts['notificationTitle'],
        'notification_text': opts['notificationText'],
        'segment_operator': opts['segmentOperator']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2001;
      return this.apiClient.callApi(
        '/api/v1/notice/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Update a notice
     * @param {Number} id Notice id
     * @param {Object} opts Optional parameters
     * @param {String} opts.title title
     * @param {String} opts.body body
     * @param {String} opts.programmedAt programmed at
     * @param {Boolean} opts.hasEmail has email
     * @param {Boolean} opts.hasNotification has notification
     * @param {String} opts.notificationTitle title of the notification
     * @param {String} opts.notificationText text of the notification
     * @param {String} opts.segmentOperator segment join operator (or/and)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2001}
     */
    appHttpControllersNoticeControllerUpdate(id, opts) {
      return this.appHttpControllersNoticeControllerUpdateWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
