import React, { Fragment, Component, h} from "preact";
import {Checkbox} from "../../../_common/forms/components/Checkbox/Checkbox";
import {FR} from "../../../_common/layout";

export default function NotificationRow({valueMail, valueApp, onClickMail, onClickApp, title, detail}) {

    return (
        <FR>
            <div className='sm-col-60 col-40 d-flex flex-column m-bottom'>
                <span className='font-big-size'><b>{title}</b></span>
                <span className='font-14 translucent sm-d-block d-none'>{detail}</span>
            </div>
            <div className='sm-col-20 col-30 d-flex flex-center-y flex-center-x'>
                <Checkbox value={valueMail} onChange={onClickMail} />
            </div>
            <div className='sm-col-20 col-30 d-flex flex-center-y flex-center-x'>
                <Checkbox value={valueApp} onChange={onClickApp} />
            </div>
        </FR>
    )
}
