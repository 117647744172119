import React, {Component, h} from "preact";
import { Form as FinalForm} from 'react-final-form'
import {
    RadioCheckbox,
    SubmitButtonRow
} from "../../_common/forms/components/components";
import {Card, CF, E, FR} from "../../_common/layout";
import {connect} from "react-redux";
import {
    fetchAcademicProgramDocument,
    uploadAcademicProgramDocument
} from "../../_rx/student/actions";
import {AppPage} from "../../_common/components/app-page";
import {t} from '../../_i18n';
import {isImageFile, resizeImage,} from "../../_common/common.ts";
import {AddImage} from "../../_common/components/add-image";
import {
    MAX_IMAGE_PIXELS
} from "../../constants";
import AlertBox from "../../_common/components/AlertBox/AlertBox";




let OtherDocsForm = class extends Component {

    state = {desempleo:false};

    constructor(props) {
        super(props);

        const {ACADEMIC_PROGRAM_DOC_CERT_DESEMPLEO, ACADEMIC_PROGRAM_DOC_VIDA_LABORAL, ACADEMIC_PROGRAM_DOC_CERT_EMPADRONAMIENTO} = window.micuentaenv.Hardcoded_ids

        this.titles = {
            [ACADEMIC_PROGRAM_DOC_CERT_DESEMPLEO]: "Certificado de demanda de empleo",
            [ACADEMIC_PROGRAM_DOC_VIDA_LABORAL]: "Informe de vida laboral",
            [ACADEMIC_PROGRAM_DOC_CERT_EMPADRONAMIENTO]: "Certificado de empadronamiento"
        }
    }

    async componentDidMount() {

        const { has_academic_program_document_types} = this.props.status.student_meta;
        const {ACADEMIC_PROGRAM_DOC_CERT_DESEMPLEO} = window.micuentaenv.Hardcoded_ids;

        if(has_academic_program_document_types) {
            await this.props.fetchAcademicProgramDocument(this.props.auth.loggedUser.id);
            this.setState({
                desempleo: this.props.academic_program_document.documents.some(doc=>doc.type.id===ACADEMIC_PROGRAM_DOC_CERT_DESEMPLEO)
            })
        }

    }

    imagePropsAcademicProgram(id){

        const doc = this.props.academic_program_document.documents &&
            this.props.academic_program_document.documents.find(doc=>doc.type.id==id);

        const userId = this.props.auth.loggedUser.id;

        return {
            image: doc && micuentaenv.SIS_IMG_URL + `/${userId}/documents/${doc.id}`,
            imageName: doc && doc.name,
            title: this.titles[id],
            uploadCb: img=>this.uploadAcademicProgramDoc(img, id)
        }
    }

    handleCheckBox = (checked) => {
        this.setState({
            desempleo:checked,
            documents:{}
        })
    }

    async uploadAcademicProgramDoc(img, docId){
        if(isImageFile(img)) img = await resizeImage(img, MAX_IMAGE_PIXELS);
        await this.props.uploadAcademicProgramDocument(this.props.auth.loggedUser.id, img, docId);
    }

    getDocErrors(typeId){
        const {documents} = this.props.academic_program_document;

        // const type = documentTypes && documentTypes.find(dt=>dt.id==typeId);
        const document = documents.find(doc=>doc.type.id==typeId);
        return document && document.messages &&
            document.messages.map(msg=>msg.related_messages).flat().map(msg=>msg.description);

    }

    render({ academic_program_document , history}) {

        const {ACADEMIC_PROGRAM_DOC_CERT_DESEMPLEO, ACADEMIC_PROGRAM_DOC_VIDA_LABORAL, ACADEMIC_PROGRAM_DOC_CERT_EMPADRONAMIENTO} = window.micuentaenv.Hardcoded_ids

        if(!academic_program_document) return;

        const errVidaLaboral = this.getDocErrors(ACADEMIC_PROGRAM_DOC_VIDA_LABORAL);
        const errCertDesempleo = this.getDocErrors(ACADEMIC_PROGRAM_DOC_CERT_DESEMPLEO);
        const errCertEmpadronamiento = this.getDocErrors(ACADEMIC_PROGRAM_DOC_CERT_EMPADRONAMIENTO);

        return <CF className="max-width-sm">
                <FinalForm onSubmit={()=>history.push('/student')} initialValues={{}} render={({handleSubmit})=>
                    <form className='form' action="" onSubmit={handleSubmit} noValidate >


                        <Card className="m-top p-bottom">
                            <h3>{t('Informe de vida laboral')}</h3>
                            <p>{t('Adjunta una copia de tu informe de vida laboral. Si te encuentras en situación de desempleo, adjunta una copia de tu certificado de demanda de empleo')}.</p>
                            <p>{t('Si todavía no te has dado de alta en la Seguridad Social, no deberás aportar esta documentación')}.</p>

                            <RadioCheckbox label={t('Estoy en situación de desempleo')}
                                           defaultValue={this.state.desempleo}
                                           onChange={checked=>this.setState({desempleo:checked})}
                                           name="desempleo" type="checkbox" />

                            {this.state.desempleo
                                ? <p dangerouslySetInnerHTML={{__html: t('other_docs_1')}}/>
                                : <p dangerouslySetInnerHTML={{__html: t('other_docs_2')}}/>
                            }

                            {errVidaLaboral && errVidaLaboral.map(msg=><AlertBox type='danger'>{msg}</AlertBox>)}
                            {errCertDesempleo && errCertDesempleo.map(msg=><AlertBox type='danger'>{msg}</AlertBox>)}

                            <AddImage
                                className="img-responsive"
                                {...this.imagePropsAcademicProgram(this.state.desempleo ? ACADEMIC_PROGRAM_DOC_CERT_DESEMPLEO : ACADEMIC_PROGRAM_DOC_VIDA_LABORAL)} />

                        </Card>


                        <Card className="m-top p-bottom">
                            <h3>{t('Certificado de empadronamiento')}</h3>
                            <p dangerouslySetInnerHTML={{__html: `${t('other_docs_3')}.`}}/>

                            {errCertEmpadronamiento && errCertEmpadronamiento.map(msg=><AlertBox type='danger'>{msg}</AlertBox>)}

                            <AddImage
                                required={true}
                                className="img-responsive"
                                {...this.imagePropsAcademicProgram(ACADEMIC_PROGRAM_DOC_CERT_EMPADRONAMIENTO)} />

                        </Card>

                        <SubmitButtonRow onCancel={()=>history.push('/student')}>{t('Guardar')}</SubmitButtonRow>
                    </form>
                }/>
            </CF>;

    }
};

OtherDocsForm = connect(
    ({auth, fixtures, course_access, status, academic_program_document})=>({auth, fixtures, course_access, status, academic_program_document}),
    {
        fetchAcademicProgramDocument,
        uploadAcademicProgramDocument
    })(OtherDocsForm);


const OtherDocsPage = ({history}) =>
    <AppPage
        name="other-docs"
        title={t('Otros documentos')}
        back="/student"
        className="max-width-sm"
    >
        <OtherDocsForm history={history}/>
    </AppPage>
;

export default OtherDocsPage;
