/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MaterialAddress model module.
 * @module model/MaterialAddress
 * @version 1.0.0
 */
class MaterialAddress {
    /**
     * Constructs a new <code>MaterialAddress</code>.
     * @alias module:model/MaterialAddress
     */
    constructor() { 
        
        MaterialAddress.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MaterialAddress</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MaterialAddress} obj Optional instance to populate.
     * @return {module:model/MaterialAddress} The populated <code>MaterialAddress</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MaterialAddress();

            if (data.hasOwnProperty('check')) {
                obj['check'] = ApiClient.convertToType(data['check'], 'Boolean');
            }
            if (data.hasOwnProperty('address')) {
                obj['address'] = ApiClient.convertToType(data['address'], 'String');
            }
            if (data.hasOwnProperty('cp')) {
                obj['cp'] = ApiClient.convertToType(data['cp'], 'String');
            }
            if (data.hasOwnProperty('city')) {
                obj['city'] = ApiClient.convertToType(data['city'], 'String');
            }
            if (data.hasOwnProperty('province_id')) {
                obj['province_id'] = ApiClient.convertToType(data['province_id'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * Indica si se proporciona una dirección de material
 * @member {Boolean} check
 */
MaterialAddress.prototype['check'] = undefined;

/**
 * Dirección de material
 * @member {String} address
 */
MaterialAddress.prototype['address'] = undefined;

/**
 * Código postal
 * @member {String} cp
 */
MaterialAddress.prototype['cp'] = undefined;

/**
 * Ciudad
 * @member {String} city
 */
MaterialAddress.prototype['city'] = undefined;

/**
 * ID de la provincia
 * @member {Number} province_id
 */
MaterialAddress.prototype['province_id'] = undefined;






export default MaterialAddress;

