import React, {Component, Fragment, h} from "preact";
import {useEffect, useRef, useState} from "preact/hooks";
import AlertBox from "../AlertBox/AlertBox";
import './dropdownBuildings.scss';

export default function DropdownBuildings ({ citySelectedId , items = [], onClick, alertDescription, defaultTitleSelector, onOpenTransitionEnd, showAddress }) {
    const [openBox, setOpenBox] = useState(false)
    const citySelected = items.find(item => item.id === citySelectedId)
    const [selectItems, setSelectItems] = useState(items)
    const element = useRef()

    useEffect(() => {
        setSelectItems(items)
        return () => setOpenBox(false)
    }, [items])

    useEffect(() => {
        const closeDropdownKeyboard = (e) => {if (e.code === 'Escape') setOpenBox(false)}

        window.addEventListener('keydown', closeDropdownKeyboard)
        return () => {
            window.removeEventListener('keydown', closeDropdownKeyboard)
        }
    }, [])

    const handleOpen = () => {
        setOpenBox(!openBox)
    }

    const handleItemSelected = (buildingSelected) => {
        setOpenBox(false)
        onClick(buildingSelected.id)
    }

    const handleFilter = (e) =>{
        const inputText = e.target.value

        const itemsFiltered = items.filter(item => {
            const regex = new RegExp(inputText, 'gi');
            return item.city.match(regex) || item.address?.match(regex)
        })

        setSelectItems(itemsFiltered)
    }

    const handleTransitionEnd = () => {
        if (openBox && onOpenTransitionEnd) onOpenTransitionEnd()
    }

    return (
        <div className={`dropdownBuildings ${citySelected && 'dropdownBuildings-checked'}`} ref={element}>
            <div onClick={handleOpen} className='dropdownBuildings-header'>
                <div className={`dropdownBuildings-header__title ${!citySelected && 'city-selected'} `}>
                    { !citySelected
                        ? defaultTitleSelector
                        : citySelected.address
                            ? <div className='dropdownBuildings-header__title selected'>
                                <div className={`${citySelected && 'dropdownBuildings-text__checked'}`}><strong>{citySelected.city}</strong></div>
                                {showAddress && <div className={`${citySelected && 'dropdownBuildings-text__checked'} dropdownBuildings-text__address_title`}>{citySelected.address}</div>}
                            </div>
                            : <strong className='dropdownBuildings-text__checked'>{citySelected.city}</strong>
                    }
                </div>
                <div className='dropdownBuildings-header__icon'><i className={`far ${openBox ? 'fa-chevron-up' : 'fa-chevron-down'} `} /></div>
            </div>
            <div className={`dropdownBuildings-box ${openBox ? 'dropdownBuildings-box__visible' : 'dropdownBuildings-box__noVisible'}`} onTransitionEnd={handleTransitionEnd}>
                { openBox &&
                  <Fragment>
                    <div className={`dropdownBuildings-input`}>
                        <input type="text" className='dropdownBuildings-search' placeholder='Buscar' onChange={handleFilter}/>
                        <i className="fal fa-search" />
                    </div>
                    <div className='dropdownBuildings-content'>
                        { !showAddress && <AlertBox type='info' className='dropdownBuildings-alert'>{alertDescription}</AlertBox>}
                        <div>
                            {selectItems.map(item =>
                              <div className='dropdownBuildings-item' onClick={() => handleItemSelected(item)}>
                                  <div>
                                      <strong>{item.city}</strong>
                                      {showAddress && <div>{item.address}</div>}
                                  </div>
                              </div>)}
                        </div>
                    </div>
                  </Fragment> }
            </div>
        </div>
    )
}

