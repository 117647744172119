import { h } from "preact";
import { FormField, InputField } from '../../../_common/forms/components/components'
import Modal from '../../../_common/components/Modal/index'
import { useMemo } from 'preact/hooks'
import { ButtonPrimary } from '../../../_common/components/buttons/buttons'
import { t } from '../../../_i18n'

export default function ModalSchedule ({ visible, onClose, input, onBlur }) {
  const oldValues = useMemo(() => ({
    monday: {...input.value.monday},
    tuesday: {...input.value.tuesday},
    wednesday: {...input.value.wednesday},
    thursday: {...input.value.thursday},
    friday: {...input.value.friday},
  }), [])

  const clearText = (val, day, input) => {
    if (!val) {
      const newValue = {...input.value}
      const dayToChange = input.value[day]
      dayToChange.value = ''
      newValue[day] = dayToChange
      input.onChange(newValue)
    }
  }

  const saveDay = (val, day, input) => {
    const newValue = {...input.value}
    const dayToChange = input.value[day]
    dayToChange.value = val
    newValue[day] = dayToChange
    input.onChange(newValue)
  }

  const handleClose = () => {
    input.onChange(oldValues)
    onClose()
  }

  return (
    <Modal visible={visible} closeModal={handleClose}>
      <div className='fct-modal'>
        <div className='fct-modal-header'>
          <i className="fal fa-times" onClick={handleClose} />
        </div>
        <div className='fct-modal-body__title font-bold m-bottom'>{t('Disponibilidad horaria')}</div>
        <div className='fct-modal-body'>
          <div className='input-schedule-body'>
            <div className='row header font-bold'>
              <div className='day'>{t('Dia')}</div>
              <div className='hour'>{t('Horario')}</div>
            </div>
            <div className='row'>
              <div className='day'><FormField name='meta.availability.monday.check' label={t('Lunes')} type='checkbox' onChange={(val) => clearText(val, 'monday', input) }/></div>
              <div className='hour'><InputField type='text' disabled={!input.value.monday.check} onChange={(val) => saveDay(val.target.value, 'monday', input) } defaultValue={input.value.monday.value} required placeholder={t('Por ejemplo: 9:00 - 19:00')}/></div>
            </div>
            <div className='row'>
              <div className='day'><FormField name='meta.availability.tuesday.check' label={t('Martes')} type='checkbox' onChange={(val) => clearText(val, 'tuesday', input) }/></div>
              <div className='hour'><InputField type='text' disabled={!input.value.tuesday.check} onChange={(val) => saveDay(val.target.value, 'tuesday', input) } defaultValue={input.value.tuesday.value} required placeholder={t('Por ejemplo: 9:00 - 19:00')}/></div>
            </div>
            <div className='row'>
              <div className='day'><FormField name='meta.availability.wednesday.check' label={t('Miércoles')} type='checkbox' onChange={(val) => clearText(val, 'wednesday', input) }/></div>
              <div className='hour'><InputField type='text' disabled={!input.value.wednesday.check} onChange={(val) => saveDay(val.target.value, 'wednesday', input) } defaultValue={input.value.wednesday.value} required placeholder={t('Por ejemplo: 9:00 - 19:00')}/></div>
            </div>
            <div className='row'>
              <div className='day'><FormField name='meta.availability.thursday.check' label={t('Jueves')} type='checkbox' onChange={(val) => clearText(val, 'thursday', input) }/></div>
              <div className='hour'><InputField type='text' disabled={!input.value.thursday.check} onChange={(val) => saveDay(val.target.value, 'thursday', input) } defaultValue={input.value.thursday.value} required placeholder={t('Por ejemplo: 9:00 - 19:00')}/></div>
            </div>
            <div className='row'>
              <div className='day'><FormField name='meta.availability.friday.check' label={t('Viernes')} type='checkbox' onChange={(val) => clearText(val, 'friday', input) }/></div>
              <div className='hour'><InputField type='text' disabled={!input.value.friday.check} onChange={(val) => saveDay(val.target.value, 'friday', input) } defaultValue={input.value.friday.value} required placeholder={t('Por ejemplo: 9:00 - 19:00')}/></div>
            </div>
          </div>
          <div className='m-top-x2 d-flex flex-right'>
            <ButtonPrimary type='button' onClick={() => {
              onBlur()
              onClose()
            }} >{t('Guardar')}</ButtonPrimary>
          </div>
        </div>
      </div>
    </Modal>
  )
}
