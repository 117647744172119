/**
 * LTI Enrollment Classes
 * LTI Enroll Class for Students
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The EventCalendarGrades model module.
 * @module model/EventCalendarGrades
 * @version 2.0.0
 */
class EventCalendarGrades {
    /**
     * Constructs a new <code>EventCalendarGrades</code>.
     * @alias module:model/EventCalendarGrades
     */
    constructor() { 
        
        EventCalendarGrades.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>EventCalendarGrades</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/EventCalendarGrades} obj Optional instance to populate.
     * @return {module:model/EventCalendarGrades} The populated <code>EventCalendarGrades</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new EventCalendarGrades();

            if (data.hasOwnProperty('attendance')) {
                obj['attendance'] = ApiClient.convertToType(data['attendance'], 'Boolean');
            }
            if (data.hasOwnProperty('attendance_date_time')) {
                obj['attendance_date_time'] = ApiClient.convertToType(data['attendance_date_time'], 'String');
            }
            if (data.hasOwnProperty('attendance_observations')) {
                obj['attendance_observations'] = ApiClient.convertToType(data['attendance_observations'], 'String');
            }
            if (data.hasOwnProperty('grade_uf1')) {
                obj['grade_uf1'] = ApiClient.convertToType(data['grade_uf1'], 'Number');
            }
            if (data.hasOwnProperty('grade_uf2')) {
                obj['grade_uf2'] = ApiClient.convertToType(data['grade_uf2'], 'Number');
            }
            if (data.hasOwnProperty('grade_uf3')) {
                obj['grade_uf3'] = ApiClient.convertToType(data['grade_uf3'], 'Number');
            }
            if (data.hasOwnProperty('grade_uf4')) {
                obj['grade_uf4'] = ApiClient.convertToType(data['grade_uf4'], 'Number');
            }
            if (data.hasOwnProperty('grade_uf5')) {
                obj['grade_uf5'] = ApiClient.convertToType(data['grade_uf5'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Boolean} attendance
 */
EventCalendarGrades.prototype['attendance'] = undefined;

/**
 * DateTime string
 * @member {String} attendance_date_time
 */
EventCalendarGrades.prototype['attendance_date_time'] = undefined;

/**
 * @member {String} attendance_observations
 */
EventCalendarGrades.prototype['attendance_observations'] = undefined;

/**
 * @member {Number} grade_uf1
 */
EventCalendarGrades.prototype['grade_uf1'] = undefined;

/**
 * @member {Number} grade_uf2
 */
EventCalendarGrades.prototype['grade_uf2'] = undefined;

/**
 * @member {Number} grade_uf3
 */
EventCalendarGrades.prototype['grade_uf3'] = undefined;

/**
 * @member {Number} grade_uf4
 */
EventCalendarGrades.prototype['grade_uf4'] = undefined;

/**
 * @member {Number} grade_uf5
 */
EventCalendarGrades.prototype['grade_uf5'] = undefined;






export default EventCalendarGrades;

