export function isRequired(value:any){
    if(value !== 0 && !value && value !== false) return true; // error true
    else return false; // error false
}

export function isRequiredWithZero(value:any){
    if(!value && value !== false) return true; // error true
    else return false; // error false
}

export function matchesPattern(value:any, pattern:any){
    if(typeof value !== 'string' || !value.match(`^${pattern}$`)) return true; // error true
    return false; // error false
}
