/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CompanyApplicationItem model module.
 * @module model/CompanyApplicationItem
 * @version v1
 */
class CompanyApplicationItem {
    /**
     * Constructs a new <code>CompanyApplicationItem</code>.
     * Item ligero de listado de candidaturas [P_E_05]
     * @alias module:model/CompanyApplicationItem
     */
    constructor() { 
        
        CompanyApplicationItem.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CompanyApplicationItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CompanyApplicationItem} obj Optional instance to populate.
     * @return {module:model/CompanyApplicationItem} The populated <code>CompanyApplicationItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CompanyApplicationItem();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('application_date')) {
                obj['application_date'] = ApiClient.convertToType(data['application_date'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('age')) {
                obj['age'] = ApiClient.convertToType(data['age'], 'Number');
            }
            if (data.hasOwnProperty('killer_grade')) {
                obj['killer_grade'] = ApiClient.convertToType(data['killer_grade'], 'Number');
            }
            if (data.hasOwnProperty('has_killer_grade')) {
                obj['has_killer_grade'] = ApiClient.convertToType(data['has_killer_grade'], 'Boolean');
            }
            if (data.hasOwnProperty('academic_program')) {
                obj['academic_program'] = ApiClient.convertToType(data['academic_program'], 'Number');
            }
            if (data.hasOwnProperty('academic_program_percent')) {
                obj['academic_program_percent'] = ApiClient.convertToType(data['academic_program_percent'], 'Number');
            }
            if (data.hasOwnProperty('has_questions')) {
                obj['has_questions'] = ApiClient.convertToType(data['has_questions'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
CompanyApplicationItem.prototype['id'] = undefined;

/**
 * applied, accepted, selected, discarded
 * @member {String} status
 */
CompanyApplicationItem.prototype['status'] = undefined;

/**
 * fecha de inscripcion
 * @member {String} application_date
 */
CompanyApplicationItem.prototype['application_date'] = undefined;

/**
 * Nombre del candidato
 * @member {String} name
 */
CompanyApplicationItem.prototype['name'] = undefined;

/**
 * Edad del candidato
 * @member {Number} age
 */
CompanyApplicationItem.prototype['age'] = undefined;

/**
 * Nota de las respuestas, -1 killer
 * @member {Number} killer_grade
 */
CompanyApplicationItem.prototype['killer_grade'] = undefined;

/**
 * Flag indicando si las respuestas tienen puntuacion
 * @member {Boolean} has_killer_grade
 */
CompanyApplicationItem.prototype['has_killer_grade'] = undefined;

/**
 * @member {Number} academic_program
 */
CompanyApplicationItem.prototype['academic_program'] = undefined;

/**
 * Porcentaje de academic program finalizado. Averiguar como calcularlo
 * @member {Number} academic_program_percent
 */
CompanyApplicationItem.prototype['academic_program_percent'] = undefined;

/**
 * Especifica si hay preguntas
 * @member {Boolean} has_questions
 */
CompanyApplicationItem.prototype['has_questions'] = undefined;






export default CompanyApplicationItem;

