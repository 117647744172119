import AlertBox from '../../../_common/components/AlertBox/AlertBox'
import { h } from 'preact'
import { Link } from 'react-router-dom'

export const STEP_INFO = 'STEP_INFO'
export const STEP_SELECT_TOPIC = 'STEP_SELECT_TOPIC'
export const STEP_SCHEDULE = 'STEP_SCHEDULE'

export const TITLE = {
  [STEP_INFO]: 'Ayuda y soporte',
  [STEP_SELECT_TOPIC]: 'Reservar cita',
  [STEP_SCHEDULE]: 'Reservar cita'
}

export const SCHEDULE_SECRETARY = 'SCHEDULE_SECRETARY'
export const SCHEDULE_FIDE = 'SCHEDULE_FIDE'
export const SCHEDULE_FCT = 'SCHEDULE_FCT'
export const SCHEDULE_TECHNICAL_SUPPORT = 'SCHEDULE_TECHNICAL_SUPPORT'

const validationsRequested = { key: 1, name: 'Convalidaciones (no aplica a FCT)', type: SCHEDULE_SECRETARY }
const titleRequest = { key: 2, name: 'Solicitud de título', type: SCHEDULE_SECRETARY }
const certificates = { key: 3, name: 'Certificados', type: SCHEDULE_SECRETARY }
const documentation = { key: 4, name: 'Documentación académica', type: SCHEDULE_SECRETARY }
const registrations = { key: 5, name: 'Matriculaciones y pagos', type: SCHEDULE_FIDE }
const examsClasses = { key: 7, name: 'Inscripción a clases presenciales y exámenes', type: SCHEDULE_FIDE }
const expedient = { key: 8, name: 'Expediente académico y notas', type: SCHEDULE_FIDE }
const validations = { key: 9, name: 'Información sobre convalidaciones', type: SCHEDULE_FIDE }
const other = { key: 10, name: 'Otro motivo', type: SCHEDULE_FIDE }
const noFct = { key: 12, name: 'Información sobre formación en centros de trabajo (FCT)', type: SCHEDULE_FIDE }
const technicalSupport = { key: 13, name: 'Soporte técnico', type: SCHEDULE_TECHNICAL_SUPPORT }
const fctDocs = { key: 14, name: 'FCT: Documentación para prácticas en empresa', type: SCHEDULE_FCT }
const fctExemption = { key: 15, name: 'FCT: Exención/convalidación de prácticas en empresa', type: SCHEDULE_FCT }
const fct = { key: 11, name: 'FCT: Información general', type: SCHEDULE_FCT }


export const FORMATIVE_ZONE_CAT = 'CAT'
export const FORMATIVE_ZONE_MAD = 'MAD'

export const LINK_ENROLL_MAD = 'https://soporte.ilerna.es/hc/es-es/articles/11905055261853'
export const LINK_ENROLL_CAT= 'https://soporte.ilerna.es/hc/es-es/articles/11905018188317'
export const LINK_VALIDATIONS_MAD = 'https://soporte.ilerna.es/hc/es-es/articles/11906594935453'
export const LINK_VALIDATIONS_CAT = 'https://soporte.ilerna.es/hc/es-es/articles/11906562209437'
export const LINK_FCT_MAD = 'https://soporte.ilerna.es/hc/es-es/articles/11548454656029'
export const LINK_FCT_CAT = 'https://soporte.ilerna.es/hc/es-es/articles/11547333260573'
export const LINK_TITLE_REQUEST_MAD = 'https://soporte.ilerna.es/hc/es-es/articles/11544563152029'
export const LINK_TITLE_REQUEST_CAT = 'https://soporte.ilerna.es/hc/es-es/articles/11544516664349'
export const LINK_CERTIFICATES = 'https://soporte.ilerna.es/hc/es-es/articles/11904889593629'


export const getTopics = (has_fct, has_convas) => {
  const options = []
  options.push(registrations)
  options.push(examsClasses)
  options.push(expedient)
  if (!has_convas) options.push(validations)
  if (has_fct) {
    options.push(fctDocs)
    options.push(fctExemption)
    options.push(fct)
  } else options.push(noFct)
  options.push(documentation)
  if (has_convas) options.push(validationsRequested)
  options.push(titleRequest)
  options.push(certificates)
  options.push(technicalSupport)
  options.push(other)
  return options
}

export const getAlertType = (key, formative_zone) => {
  if (key === validationsRequested.key) return <AlertBox type='warning' className='m-top'>👋 Antes de reservar, ¿has consultado la <a href={formative_zone === FORMATIVE_ZONE_CAT ? LINK_VALIDATIONS_CAT: LINK_VALIDATIONS_MAD}>guía de ayuda sobre las convalidaciones</a>?</AlertBox>
  if (key === validations.key) return <AlertBox type='warning' className='m-top'>👋 Antes de reservar cita, ¿has consultado la <a href={formative_zone === FORMATIVE_ZONE_CAT ? LINK_VALIDATIONS_CAT: LINK_VALIDATIONS_MAD}>guía de ayuda sobre las convalidaciones</a>?</AlertBox>
  if (key === fct.key) return <AlertBox type='warning' className='m-top'>👋 Antes de reservar cita, ¿has consultado la <a href={formative_zone === FORMATIVE_ZONE_CAT ? LINK_FCT_CAT: LINK_FCT_MAD}>guía de ayuda sobre Formación en Centros de Trabajo</a>?</AlertBox>
  if (key === noFct.key) return <AlertBox type='warning' className='m-top'>👋 Antes de reservar cita, ¿has consultado la <a href={formative_zone === FORMATIVE_ZONE_CAT ? LINK_FCT_CAT: LINK_FCT_MAD}>guía de ayuda sobre Formación en Centros de Trabajo</a>?</AlertBox>
  if (key === fctDocs.key) return <AlertBox type='warning' className='m-top'>👋 Antes de reservar cita, ¿has consultado la <a href={formative_zone === FORMATIVE_ZONE_CAT ? LINK_FCT_CAT: LINK_FCT_MAD}>guía de ayuda sobre Formación en Centros de Trabajo</a>?</AlertBox>
  if (key === fctExemption.key) return <AlertBox type='warning' className='m-top'>👋 Antes de reservar cita, ¿has consultado la <a href={formative_zone === FORMATIVE_ZONE_CAT ? LINK_FCT_CAT: LINK_FCT_MAD}>guía de ayuda sobre Formación en Centros de Trabajo</a>?</AlertBox>
  if (key === titleRequest.key) return <AlertBox type='warning' className='m-top'>👋 Antes de reservar cita, ¿has consultado la <a href={formative_zone === FORMATIVE_ZONE_CAT ? LINK_TITLE_REQUEST_CAT: LINK_TITLE_REQUEST_MAD}>guía de solicitud de título</a>?</AlertBox>
  if (key === certificates.key) return <AlertBox type='warning' className='m-top'>👋 Antes de reservar cita, ¿has consultado la <a href={LINK_CERTIFICATES}>guía de solicitud de certificados</a>?</AlertBox>
}

export const CALENDAR_OWNER = 19018317
export const CALENDAR_FCT_KEY = 5288352
export const CALENDAR_SECRETARY_KEY = 8027236
export const CALENDAR_FIDE_KEY = 8027234
export const CALENDAR_TECHNICAL_SUPPORT_KEY = 8956525

export const scheduleApiKey = {
  [SCHEDULE_SECRETARY]: CALENDAR_SECRETARY_KEY,
  [SCHEDULE_FIDE]: CALENDAR_FIDE_KEY,
  [SCHEDULE_FCT]: CALENDAR_FCT_KEY,
  [SCHEDULE_TECHNICAL_SUPPORT]: CALENDAR_TECHNICAL_SUPPORT_KEY,
}


