import {store} from '../_rx/store';
import {asyncLazySingleton, getHttp, wait} from "../_common/common.ts";

export class PsService {

    async login() {
        let result = await this.doWhateverOperation('Login');

        if (!result) {
            result = await getHttp(micuentaenv.SSO_BaseUrl + '/get_st?service=' + micuentaenv.BASE_URL, null, true);

            //no hay cookie gt del cas
            if (!result || result.st == false) window.location.href = micuentaenv.SSO_BaseUrl + '/login?service=' + encodeURIComponent(window.origin)

            while (store.getState().status == null) await wait(100);
            const {status: {student_meta: {shop_id: shop}}} = store.getState();

            const success = await this.doWhateverOperation('Login', {st: result.st, shop});
            if (!success) throw new Error('Shop Login failed');
        }
    }

    async logout() {
        await this.doWhateverOperation('Logout');
    }

    async doWhateverOperation(OpName, BodyObject) {

        const settings = {
            method: 'POST',
            body: JSON.stringify(BodyObject),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        };

        // micuentaenv.PS_API_BaseUrl = 'https://localhost.ilerna.es/WsMyAccount/endpoint.php';
        const data = await getHttp(`${micuentaenv.PS_API_BaseUrl}?op=${OpName}`, settings, true);

        const error = data.custom_error || data.general_error || data.internal_error;
        if (error) throw new Error(error);

        return data;
    }

    async getInviteFriendStatus() {
        return await this.doWhateverOperation('InvitarAmigoGetStatus')
    }

    async getStatusPresta() {
        return await this.doWhateverOperation('GetStatusPresta')
    }

    async GetDireccionEnvio() {
        return await this.doWhateverOperation('GetDireccionEnvio');
    }

    async SaveDireccionEnvio(address) {
        return await this.doWhateverOperation('SaveDireccionEnvio', address);
    }

    async GetHistorialMatriculas() {
        const data = await this.doWhateverOperation('GetMatriculas')
        data.forEach(m => {
            const parts = m.Fecha.split('-').map(p => parseInt(p));
            m.Fecha = new Date(parts[2], parts[1] - 1, parts[0]);
        });
        return data
    }

    async GetMatricula(id_matricula) {
        const data = await this.doWhateverOperation('GetMatricula', {id_matricula})
        const parts = data.Fecha.split('-').map(p => parseInt(p));
        data.Fecha = new Date(parts[2], parts[1] - 1, parts[0]);
        return data
    }

    async GetPoblaciones() {
        return await this.doWhateverOperation('GetPoblaciones')
    }

    async GetPaises() {
        return await this.doWhateverOperation('GetPaises')
    }

    async ChangePassword(old, newp) {
        return await this.doWhateverOperation('ChangePassword', {old: old, new: newp});
    }

    async getCertificateTypes() {
        return await this.doWhateverOperation('getCertificateTypes');
    }

    async createCertificateCart(academic_program_id, product_attribute_id) {
        return await this.doWhateverOperation('createCertificateCart', {
            academic_program_id: academic_program_id,
            product_attribute_id: product_attribute_id
        });
    }
}

export const svcPsService = asyncLazySingleton(async () => {
    const svc = new PsService();
    await svc.login();
    return svc;
}); // singleton
