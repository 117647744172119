import {
    CLEAR_NOTIFICATION,
    NTYPE_INFO,
    NTYPE_DANGER,
    NTYPE_SUCCESS,
    SET_NOTIFICATION,
    SET_STATUS_MESSAGES, NTYPE_WARNING
} from "./constants";

export const setNotification = (payload) => ({type: SET_NOTIFICATION, payload});

// aliases
export const setNotificationDanger = (message, action, route, code) => ({type: SET_NOTIFICATION, payload: {type: NTYPE_DANGER, message, action, route, code }});
export const setNotificationInfo = (message, action, route, code) => ({type: SET_NOTIFICATION, payload: {type: NTYPE_INFO, message, action, route, code }});
export const setNotificationWarning = (message, action, route, code) => ({type: SET_NOTIFICATION, payload: {type: NTYPE_WARNING, message, action, route, code }});
export const setNotificationSuccess = (message, action, route, code) => ({type: SET_NOTIFICATION, payload: {type: NTYPE_SUCCESS, message, action, route, code }});

export const clearNotification = (payload) => ({type: CLEAR_NOTIFICATION, payload});

export const setStatusMessages = (payload) => ({type: SET_STATUS_MESSAGES, payload});
