import React, { Fragment, Component, h} from "preact";
import {Card, FR} from "../../../_common/layout";
import Badge from "../../../_common/components/Badge";
import {ButtonGhost} from "../../../_common/components/buttons/buttons";
import {t} from "../../../_i18n";
import {connect} from "react-redux";
import {setClassSelected} from '../../../_rx/enrollment/classes/actions';
import {useHistory} from 'react-router-dom';

function CardUpcomingClassesView ({ event, setClassSelected }) {
  const history = useHistory()

  const handleClick = () => {
    history.push(`/clases/apply`)
    setClassSelected(event)
  }

  return (
    <Card className='m-top' smOnly={false}>
      <div className='d-flex flex-space-between flex-column sm-no-flex-column'>
        <div className='sm-col-70'>
          <div className='t-upper font-color-879CA1 font-14'>{event.formative_action_code}</div>
          <div className='font-20 m-top-half'>{event.formative_action_title}</div>
        </div>
        <div className='sm-col-30 d-flex flex-center-y m-top sm-no-m-top'>
          <ButtonGhost className='t-upper full-width' onClick={handleClick}>{t('Reservar Plaza')}</ButtonGhost>
        </div>
      </div>
    </Card>
  )
}

CardUpcomingClassesView = connect(
  ({})=>({}),
  { setClassSelected })(CardUpcomingClassesView);


export default ({...rest}) => <CardUpcomingClassesView {...rest} />
