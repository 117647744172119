import {Link} from "react-router-dom";
import {E} from "../../_common/layout";
import {formatCurrency, formatDDMMYYYY, stripTags} from "../../_common/common";
import React, {h, Fragment} from "preact";
import {t} from "../../_i18n";
import Badge from "../../_common/components/Badge";

const lengths = {
    "Indefinido": "Contrato indefinido ",
    "Bruto al año": "Bruto/año",
}

const statuses = {
    "applied": t("Inscrito"),
    "selected": t("Seleccionado"),
    "accepted": t("En proceso"),
    "discarded": t("CV no seleccionado"),
    "is_remote_process": t("Proceso Online")
}

const statuses_badges = {
    "applied": "info",
    "selected": "success",
    "accepted": "notice",
    "discarded": "danger",
    "is_remote_process": "proceso"
}

export const OffersList = ({offers, schedules, contract_types, loadingOffers})=> <Fragment>
    {offers.data.map((offer)=> {
        const IS_REMOTE_JOB = offer?.presence === 'remote'
        return (
            <div className='offerCard'>
                {loadingOffers && <div className='loading-card'/>}
                <Link key={offer.id} to={'/jobs/offer/' + offer.id} className="no-decoration">
                    <div className="card m-bottom">
                        <div className="flexrow p-x2-all">
                            <div className="col-30 sm-col-15">
                                {offer.company.logo
                                  ? <div className="card image-squared" style={`background-image:url('${window.micuentaenv.JOBS_API_basePath}/company/view-logo/${offer.company.logo}');`} />
                                  : <div className="card image-squared d-flex flex-center-x flex-center-y" ><i className="fal fa-building font-color-b8c7cc font-30"/></div>
                                }
                            </div>

                            <div className="col-70 sm-col-85">
                                <div className="flexrow full-width flex-center-x flex-center-y">
                                    <div className="col-100 sm-col-70 ">
                                        <h1 className="no-margin font-medium">{offer.title}</h1>
                                    </div>
                                    {offer.status && (
                                        <E className="sm-col-30 t-right d-none sm-d-block">
                                            {!!offer.events.length && (
                                                <div>
                                                    <Badge type={statuses_badges[offer.events[offer.events.length - 1].type]}>{statuses[offer.events[offer.events.length - 1].type]}</Badge>
                                                </div>
                                            )}
                                        </E>
                                    )}
                                </div>

                                <div className="card-text">
                                    {offer.display_company_name ?
                                        <Link to={`/jobs/company/${offer.company.id}`} className="no-decoration t-upper m-top-half">{offer.company.name} <span className="d-none sm-d-iblock">&nbsp;</span> </Link>
                                        : <span>{offer.company_name} </span>}
                                    {offer.city && offer.state  ? <span className="translucent">- {IS_REMOTE_JOB ? t('Teletrabajo') : `${offer.city} (${offer.state})`} &nbsp;</span> : '' }
                                    <span className="sm-d-iblock d-none">| &nbsp; {formatDDMMYYYY(offer.date)}</span>
                                    <p className="d-block sm-d-none no-margin-top m-bottom-half m-top-half"> {formatDDMMYYYY(offer.date)}</p>
                                    <p className="d-none sm-d-block ellipsis">{stripTags(offer.text)}</p>
                                    <p className="no-margin-top m-bottom-half">
                                        <span className="font-medium"> {contract_types[offer.contract_type]} <span className="translucent"> - </span></span>
                                        {offer.length && <span className="m-bottom-half"> {offer.length} <span className="translucent"> - </span></span>}
                                        <span className="translucent"> {schedules[offer.schedule]} </span>
                                        {
                                            offer.salary_min && offer.salary_max ?
                                                <span className="m-bottom-half"> - {formatCurrency(offer.salary_min, 0)} - {formatCurrency(offer.salary_max, 0)} </span> :
                                                offer.salary_min ?
                                                    ' - ' + formatCurrency(offer.salary_min, 0) :
                                                    offer.salary_max ?
                                                        ' - ' + formatCurrency(offer.salary_max, 0) :
                                                        ''
                                        }
                                        {offer.salary_type && <span className="m-bottom-half m-r sm-m-r"> {offer.salary_type}</span>}
                                        {(offer.modality === 'fct') && <span className='m-r m-top-half sm-m-r no-margin-bottom font-medium'>{t('Formación en centro de trabajo')}</span>}
                                        {offer.is_remote_process && <Badge type={statuses_badges["is_remote_process"]} className='m-r sm-m-r m-top-half'>{statuses["is_remote_process"]}</Badge>}
                                    </p>
                                    {!!offer.events.length && <E className="sm-d-none no-margin-left">
                                        <Badge type={statuses_badges[offer.events[offer.events.length - 1].type]}>{statuses[offer.events[offer.events.length - 1].type]}</Badge>
                                    </E>}
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>)
    })}
</Fragment>
