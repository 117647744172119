import { combineReducers } from 'redux';
status
import auth from './_auth/reducers'
import {
    matricula,
    fixtures,
    student_document,
    status,
    course_access,
    student,
    certified_course_access, title_cart, title_place, academic_program_document, lang, student_access,
  banner
} from './student/reducers';
import {direccion_envio} from './direccion-envio/reducers';
import {historial_matriculas, matricula_detalle} from './historial-matriculas/reducers';
import notify from './_notify/reducers';
import {poblaciones, paises_presta} from "./localizaciones/reducers";
import {comunicados, comunicado, comunicado_adjuntos, comunicados_status} from './comunicados/reducers';
import {
    candidate,
    offers,
    fixturesReducer,
    offer,
    company,
    offersCompany,
    offersFilterReducer,
    offersCountReducer, jobFilter
} from "./jobs/reducers";
import { expedient } from './expediente/reducers'
import {profile_settings} from "./profileSettings/reducers"
import loadingReducer from 'redux-loading-middleware/loadingReducer';
import {enrollmentClasses} from "./enrollment/classes/reducers/index";
import {enrollmentExams} from "./enrollment/exams/reducers";
import {inviteFriendStatus} from './inviteFriend/reducers/index'
import {status_presta} from './statusPresta/reducers/index'
import {giftStatus} from './gift/reducers/index'
import { fct } from './fct/reducers'
import {certificates} from "./certificates/reducer";


export default combineReducers({
    banner,
    notify,
    loading: loadingReducer,
    auth,
    lang,
    matricula,
    status,
    fixtures,
    course_access,
    certified_course_access,
    student,
    student_access,
    student_document,
    academic_program_document,
    direccion_envio,
    historial_matriculas,
    matricula_detalle,
    status_presta,
    poblaciones,
    paises_presta,
    title_cart,
    title_place,
    comunicados,
    comunicado,
    comunicado_adjuntos,
    comunicados_status,
    candidate,
    offers,
    jobFilter,
    offersCompany,
    offer,
    company,
    offers_fixtures: fixturesReducer,
    offersFilter: offersFilterReducer,
    offersCount: offersCountReducer,
    profile_settings,
    inviteFriendStatus,
    giftStatus,
    expedient,
    enrollmentExams,
    enrollmentClasses,
    fct,
    certificates
});
