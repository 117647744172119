import {h, Fragment} from "preact";
import {Modal} from "../_common/components/modal";
import {t} from '../_i18n';
import new_android from "./img/new_android.png";
import new_iphone from "./img/new_iphone.png";
import qr_icon from "./img/qr_icon.png";
import qr from "./img/qr.png";

export const ModalWhatsapp = ({id})=><Fragment>
    <Modal id={id} title={t('Contacta con tu gestor a través de WhatsApp')} className="modal-whatsapp" >
        <div class="max-width">
            <p>{t('Para añadir la información de contacto de tu gestor Premium a WhatsApp puedes escanear el código QR de WhatsApp.')}</p>

            <h3>{t('Si tu móvil es Android:')}</h3>
            <ul>
                <li innerHTML={t('whatsapp-nuevo-chat')}> <img src={new_android}></img></li>
                <li innerHTML={t('Pulsa el icono de QR junto a Nuevo contacto:')}> <img src={qr_icon}></img></li>
                <li innerHTML={t('Selecciona <b>ESCANEAR CÓDIGO</b> y coloca tu dispositivo sobre el código QR para escanearlo.')}></li>
                <li innerHTML={t('Pulsa <strong>AÑADIR.</strong>')}></li>
            </ul>
            <hr/>
            <h3>{t('Si tu móvil es iPhone:')}</h3>
            <ul>
                <li innerHTML={t('whatsapp-nuevo-chat')}> <img src={new_iphone}></img></li>
                <li innerHTML={t('Pulsa <strong>Nuevo contacto > Añadir a través de código QR</strong>')}> <img src={qr_icon}></img></li>
                <li innerHTML={t('Coloca tu dispositivo sobre el código QR para escanearlo o pulsa el icono de <strong>Fotos</strong> en la parte inferior de la pantalla y selecciona el código QR de WhatsApp desde tus <strong>Fotos</strong>')}></li>
                <li innerHTML={t('Pulsa <strong>Añadir contactos.</strong>')}></li>
            </ul>
            <div class="qr-code">
                <h3>{t('Código QR')}</h3>
                <img src={qr}></img>
            </div>
        </div>
    </Modal>
</Fragment>;
