import React, {Fragment, h} from "preact";
import {Link} from "react-router-dom";
import {t} from '../../_i18n';
import {Card, E, FR, R} from "../../_common/layout";

export const PresenciaProfesional = ({candidate}) =>
    <Fragment>
        <Card smOnly={false}>
            <FR className="flex-center-y">
                {candidate.links.length > 0 &&
                <Fragment>
                    <div className="col-100 sm-col-65">
                        <h1>{t('Tu presencia profesional en internet')}</h1>
                    </div>

                    <div className="d-none sm-col-35 sm-d-flex flex-right">
                        <Link to={`./candidate/profile`} className="no-decoration text-info t-upper">
                            <button className={'btn btn-ghost'}>{t('Editar Perfiles')}</button>
                        </Link>
                    </div>
                </Fragment>
                }
                {candidate.links.map((element) =>
                    <E className="full-width">
                        <a href={element.url} target="_blank" className={element.url ? '' : 'disabled-link disabled-aspect-gray'}>{t('Perfil de')} {element.code}</a>
                        &nbsp;
                        <i className="fal fa-external-link"></i>
                    </E>
                )}
                {candidate.links.length > 0 &&
                <div className="sm-d-none col-100 d-flex flex-left">
                    <Link to={`./candidate/profile`} className="no-decoration text-info t-upper">
                        <button className={'btn btn-ghost'}>{t('Editar Perfiles')}</button>
                    </Link>
                </div>
                }
                {!candidate.links.length &&
                <Fragment>
                    <div className="col-100 sm-col-100">
                        <h1 className="no-margin">{t('Tu presencia profesional en internet')}</h1>
                    </div>
                    <div className="col-100 sm-col-100">
                        <p className="m-bottom-half m-top-half">{t('Indica tus perfiles de InfoJobs o LinkedIn.')}</p>
                    </div>
                    <div className="col-100 sm-col-100">
                        <Link to={`./candidate/profile`} className="no-decoration text-info t-upper">
                            <button className={'btn btn-primary'}>{t('Añadir Perfiles')}</button>
                        </Link>
                    </div>
                </Fragment>
                }
            </FR>
        </Card>
        <div className="p-bottom" />
    </Fragment>
