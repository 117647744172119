import React, { h } from "preact";
import {connect} from "react-redux";
import {t} from "../../_i18n";
import {AppPage} from "../../_common/components/app-page";
import {fetchFixture, fetchStudent, saveStudent} from "../../_rx/student/actions";
import {C, Card, E, FR} from "../../_common/layout";
import {Redirect} from "react-router";
import {fetchPoblaciones} from "../../_rx/localizaciones/actions";
import {Form as FinalForm} from "react-final-form";
import {useEffect} from "preact/compat";
import {HomeAddress} from "../../student/components/home-address";
import {ButtonLink, ButtonPrimary} from "../../_common/components/buttons/buttons";
import getLangByShopId from "../../_common/utils/getLang";


const Address = ({fetchFixture, fetchStudent, fetchPoblaciones,saveStudent, student, fixtures, shopId, history, studentId, poblaciones}) => {

    useEffect(() => {
        const shopLang = getLangByShopId(shopId)

        fetchStudent(studentId)

        if (!fixtures) fetchFixture(shopLang)
        if (!poblaciones) fetchPoblaciones(studentId)
    }, [])

    const onSubmit = async (data) => {
        await saveStudent(data)
    }

    const handleCancel = (e) => {
        e.preventDefault()
        history.goBack()
    }

    return (
        <C className={'max-width'}>
            <Card smOnly className='p-bottom p-left p-right'>
                <h1>{t('Cambiar dirección')}</h1>
                {fixtures && poblaciones && student && <FinalForm onSubmit={data=>onSubmit(data)} initialValues={student} render = {
                    ({handleSubmit,  submitting, errors, values, submitSucceeded})=>
                        <form className='form' action="" onSubmit={handleSubmit} noValidate>
                            {submitSucceeded && history.goBack()}
                            <HomeAddress fixtures={fixtures} values={values} poblaciones={poblaciones} shop={shopId} />
                            <FR className='m-top'>
                                <E className='full-width sm-no-full-width'>
                                    <ButtonPrimary className="full-width sm-no-full-width" type='submit'>{t('Guardar')}</ButtonPrimary>
                                </E>
                                <E>
                                    <ButtonLink className="full-width sm-no-full-width sm-d-block d-none" onClick={handleCancel}>{t('Cancelar')}</ButtonLink>
                                </E>
                            </FR>
                        </form>
                    }
                    />}
            </Card>
        </C>
    )
}

const AddressViewContainer = connect(
    ({auth, student, fixtures, poblaciones, status})=>({
        studentId: auth.loggedUser.id,
        shopId: status.student_meta.shop_id,
        student,
        fixtures,
        poblaciones,
    }), {
        fetchFixture,
        fetchStudent,
        fetchPoblaciones,
        saveStudent,
    })(Address);

export default ({history}) =>
    <AppPage
        name="student page"
        className="max-width">
        <AddressViewContainer history={history}/>
    </AppPage>
