/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CandidateCompany from './CandidateCompany';

/**
 * The CandidatePublicOfferItem model module.
 * @module model/CandidatePublicOfferItem
 * @version v1
 */
class CandidatePublicOfferItem {
    /**
     * Constructs a new <code>CandidatePublicOfferItem</code>.
     * Item ligero de oferta para listados vista candidato [P_A_02] [P_A_11]
     * @alias module:model/CandidatePublicOfferItem
     */
    constructor() { 
        
        CandidatePublicOfferItem.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CandidatePublicOfferItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CandidatePublicOfferItem} obj Optional instance to populate.
     * @return {module:model/CandidatePublicOfferItem} The populated <code>CandidatePublicOfferItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CandidatePublicOfferItem();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('display_company_name')) {
                obj['display_company_name'] = ApiClient.convertToType(data['display_company_name'], 'Boolean');
            }
            if (data.hasOwnProperty('company')) {
                obj['company'] = CandidateCompany.constructFromObject(data['company']);
            }
            if (data.hasOwnProperty('company_name')) {
                obj['company_name'] = ApiClient.convertToType(data['company_name'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('text')) {
                obj['text'] = ApiClient.convertToType(data['text'], 'String');
            }
            if (data.hasOwnProperty('modality')) {
                obj['modality'] = ApiClient.convertToType(data['modality'], 'String');
            }
            if (data.hasOwnProperty('date')) {
                obj['date'] = ApiClient.convertToType(data['date'], 'String');
            }
            if (data.hasOwnProperty('contract_type')) {
                obj['contract_type'] = ApiClient.convertToType(data['contract_type'], 'Number');
            }
            if (data.hasOwnProperty('length')) {
                obj['length'] = ApiClient.convertToType(data['length'], 'String');
            }
            if (data.hasOwnProperty('salary_type')) {
                obj['salary_type'] = ApiClient.convertToType(data['salary_type'], 'String');
            }
            if (data.hasOwnProperty('salary_min')) {
                obj['salary_min'] = ApiClient.convertToType(data['salary_min'], 'Number');
            }
            if (data.hasOwnProperty('salary_max')) {
                obj['salary_max'] = ApiClient.convertToType(data['salary_max'], 'Number');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('schedule')) {
                obj['schedule'] = ApiClient.convertToType(data['schedule'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
CandidatePublicOfferItem.prototype['id'] = undefined;

/**
 * true: viene el company, false: viene el company_name
 * @member {Boolean} display_company_name
 */
CandidatePublicOfferItem.prototype['display_company_name'] = undefined;

/**
 * @member {module:model/CandidateCompany} company
 */
CandidatePublicOfferItem.prototype['company'] = undefined;

/**
 * @member {String} company_name
 */
CandidatePublicOfferItem.prototype['company_name'] = undefined;

/**
 * @member {String} title
 */
CandidatePublicOfferItem.prototype['title'] = undefined;

/**
 * @member {String} text
 */
CandidatePublicOfferItem.prototype['text'] = undefined;

/**
 * normal, fct, dual
 * @member {String} modality
 */
CandidatePublicOfferItem.prototype['modality'] = undefined;

/**
 * @member {String} date
 */
CandidatePublicOfferItem.prototype['date'] = undefined;

/**
 * id tipo de contrato
 * @member {Number} contract_type
 */
CandidatePublicOfferItem.prototype['contract_type'] = undefined;

/**
 * duración del trabajo
 * @member {String} length
 */
CandidatePublicOfferItem.prototype['length'] = undefined;

/**
 * bruto año, neto al mes, ...
 * @member {String} salary_type
 */
CandidatePublicOfferItem.prototype['salary_type'] = undefined;

/**
 * @member {Number} salary_min
 */
CandidatePublicOfferItem.prototype['salary_min'] = undefined;

/**
 * @member {Number} salary_max
 */
CandidatePublicOfferItem.prototype['salary_max'] = undefined;

/**
 * Estado actual de la candidatura, si no esta inscrito viene null
 * @member {String} status
 */
CandidatePublicOfferItem.prototype['status'] = undefined;

/**
 * tipo de horario
 * @member {Number} schedule
 */
CandidatePublicOfferItem.prototype['schedule'] = undefined;






export default CandidatePublicOfferItem;

