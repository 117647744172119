import { h } from "preact";
import SignAddress from '../SignAddress/SignAddress'
import AlertCollapse from '../../../_common/components/AlertCollapse/AlertCollapse'
import { useSelector } from 'react-redux'
import { t } from '../../../_i18n'
import {isSpanishUser} from "../../../_common/utils/getLang";
import AlertBox from "../../../_common/components/AlertBox/AlertBox";


export default function AlertAnnexed ({ className }) {
  const static_documents = useSelector(state => state.fct.static_documents)
  const shopId = useSelector(state => state.status.student_meta.shop_id)

  return (
    <AlertCollapse title={t('👋 Nos tienes que enviar por correo postal los ‘Anexos 7’ originales y el ‘Anexo 8’')} className={className}>
      <ol>
        <li>
            {t('Descárgate el ')} <a href={static_documents.tutor.url}>{t('‘Informe del tutor del centro de trabajo (Anexo 8)’')}</a> {t('e imprímelo.')}
            {!isSpanishUser(shopId) &&
                <AlertBox type={"dashed"}>
                    {t('fct_international_help')}
                    <a href={t('fct_anexo_8_tutor_help_document_link')}>{t('fct_anexo_8_tutor_international_help_document')}.</a>
                </AlertBox>
            }
        </li>
        <li>{t('Tenéis que rellenarlo y firmarlo, tú y tu tutor de prácticas.')}</li>
        <li>{t('Una vez rellenado y firmado, envíalo, junto a los ‘Anexos 7’ originales a la siguiente dirección:')}</li>
      </ol>
      <SignAddress className='m-top' />
    </AlertCollapse>
  )
}
