import React, {Component, Fragment, h} from "preact";
import {AppPage} from "../../_common/components/app-page";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {t} from '../../_i18n';
import {CF, E, RE} from "../../_common/layout";

import {deleteDocument, fetchCandidate, saveDocument} from "../../_rx/jobs/actions";
import {Tabs} from "../components/tabs";
import {CV} from "../components/cv";
import {PresenciaProfesional} from "../components/presencia_profesional";
import {CartaPresentacion} from "../components/carta_presentacion";
import {InformacionContacto} from "../components/informacion_contacto";
import {student} from "../../_rx/student/reducers";
import {fetchStudent} from "../../_rx/student/actions";
import AlertBox from "../../_common/components/AlertBox/AlertBox";

let CandidateView = class extends Component {

    async componentDidMount() {
        const {auth} = this.props;
        this.props.fetchStudent(auth.loggedUser.id).catch(err=>console.error(err));
        this.props.fetchCandidate();
    }

    render({candidate, student, saveDocument, deleteDocument}) {
        return <CF className="max-width-980">
            {/*<pre>{JSON.stringify(candidate,null,'  ')}</pre>*/}
            {(!candidate || !student) ?
                <p className="t-center">
                    <em className="disabled-aspect-gray"> {t('Cargando')}. . . </em>
                </p> :
                <Fragment>
                    {!candidate.has_filled_cv && <AlertBox type='warning'>{t("Para poder inscribirte en las ofertas debes ")}<Link to={"/jobs/candidate"}>{t('adjuntar tu CV, o indicar tus perfiles de InfoJobs o LinkedIn.')}</Link></AlertBox>}
                    <Tabs candidate={candidate}/>
                    <CV candidate={candidate} saveDocument={saveDocument} deleteDocument={deleteDocument} />
                    <PresenciaProfesional candidate={candidate} />
                    <CartaPresentacion candidate={candidate} />
                    <InformacionContacto student={student} />
                </Fragment>
            }
        </CF>;
    }
};


CandidateView = connect(
    ({auth, candidate, student})=>({auth, candidate, student}), {fetchCandidate, fetchStudent, saveDocument, deleteDocument})(CandidateView);

const CandidatePage = ({history}) =>
    <AppPage
        name="candidate"
        title={t('Bolsa de trabajo')}
        subtitle=""
        className="max-width-980">
        <CandidateView />
    </AppPage>
;

export default CandidatePage;
