import { h } from "preact";
import SignAddress from '../SignAddress/SignAddress'
import AlertCollapse from '../../../_common/components/AlertCollapse/AlertCollapse'
import { t } from '../../../_i18n'


export default function AlertExemption ({ exemption, className }) {
  const titleText = t('alert_exemption').replace('{exemption}', exemption)
  const text = t('alert_exemption_body').replaceAll('{exemption}', exemption)

  return (
    <AlertCollapse title={titleText} className={className}>
      <div>{text}</div>
      <div className='m-top'>{t('Para que podamos tramitar tu exención, necesitamos que envíes por correo postal el certificado de tareas con firma y sello originales a la siguiente dirección:')}</div>
      <SignAddress className='m-top' />
    </AlertCollapse>
  )
}
