import React, { Fragment, Component, h} from "preact";
import AlertBox from "../../../../_common/components/AlertBox/AlertBox";
import {t} from '../../../../_i18n';
import './InputTime.scss'

export default function InputTime({ date, checked, onClick, isThisScheduleSelected }) {
    const classChecked = checked ? 'checked' : ''
    const eventDisabled = (date?.subscribed_event || date?.enrollment_full) && !isThisScheduleSelected
    const classDisabled = eventDisabled ? 'disabled' : ''

    const handleClick = () => {
        if (checked) onClick(null)
        else if (!eventDisabled) onClick(date)
    }

    return (
        <div onClick={handleClick} className={`inputTimeExams ${classChecked} ${classDisabled}`}>
            <div className={`inputTimeExams-container`}>
                <div className='inputTimeExams-left'>
                    <div className={`inputTimeExams__day ${classDisabled}`}>{date.formatted_day}</div>
                    <div className={`inputTimeExams__hours ${classDisabled}`}>{date.start_date_hour} - {date.end_date_hour}</div>
                </div>
                <div className='inputTimeExams-right'>
                    <div className={`inputTimeExams-checkbox ${classDisabled}`} >
                        <input type="checkbox" checked={checked}/>
                        <div onClick={handleClick} className={classDisabled}>
                            <i className="fas fa-check" onClick={handleClick}/>
                        </div>
                    </div>
                </div>
            </div>
            { date?.subscribed_event && !isThisScheduleSelected &&
                <AlertBox type='info' className='inputTimeExams-alert'>{t('Ya te has inscrito a un examen en esta franja horaria')}.</AlertBox>
            }
            { date?.enrollment_full && !date?.subscribed_event && !isThisScheduleSelected &&
              <AlertBox type='info' className='inputTimeExams-alert'>{t('Lo sentimos. no quedan plazas para esta sesión')}.</AlertBox>
            }
        </div>
    )
}
