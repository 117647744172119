import { h } from "preact";
import { AppPage } from '../../_common/components/app-page'
import { ButtonGhost, ButtonPrimary } from '../../_common/components/buttons/buttons'
import { Field, Form as FinalForm } from 'react-final-form'
import { C, F } from '../../_common/layout'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { useEffect, useState } from 'preact/hooks'
import { downloadFileFtp, fetchAllDocuments, fetchStatus, putDocument } from '../../_rx/fct/actions'
import UploadDocument from '../components/UploadDocument/UploadDocument'
import { validationDocumentFCT } from '../components/UploadDocument/validationDocumentFCT'
import { Link } from 'react-router-dom'
import ButtonSpinner from '../../_common/components/buttons/buttonSpinner/buttonSpinner'
import { t } from '../../_i18n'

export default function Survey () {
  const static_documents = useSelector(state => state.fct.static_documents)
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    fetchAllDocuments(id).then((data) => setData(data))
  }, [])

  const handleSubmit = (values) => {
    setLoading(true)
    putDocument(values.blocks.other_docs.documents.final_evaluation.id)
      .then(() => {
        setLoading(false)
        dispatch(fetchStatus(id))
        history.push(`/fct/${id}`)
      })
      .catch(() => setLoading(false))
  }

  if (!data) return <div />
  return (
    <AppPage title={t('Subir valoraciones finales')} back={history.goBack} className="max-width-980">
      <F className="max-width-980">
        <FinalForm
          onSubmit={handleSubmit}
          initialValues={data}
          render={(formEvents)=> (
            <form action="" onSubmit={formEvents.handleSubmit} noValidate>
              <C className='m-top-x2'>
                <ol className='m-top-x2'>
                  <li>{t('Descárgate la plantilla de valoraciones finales:')} <span className='ftp-link font-bold cursor-pointer' onClick={() => downloadFileFtp(data.blocks.other_docs.documents.final_evaluation_template.id, data.blocks.other_docs.documents.final_evaluation_template.file_name)}>‘{t('Valoraciones finales.pdf')}’</span></li>
                  <li>{t('Rellena los apartados')} <span className='font-bold'>‘{t('REF10/Encuesta de satisfacción’ y ‘REF15/Valoración Final')}’.</span></li>
                  <li>{t('Adjunta el documento a continuación:')}</li>
                </ol>
                <Field
                  name='blocks.other_docs.documents.final_evaluation'
                  label={t('Documento de valoraciones finales')}
                  component={UploadDocument}
                  typeDocument='final_evaluation'
                  contractId={id}
                  className='m-top'
                  specificError={t('Por favor, agrega el documento de valoraciones finales.')}
                  required
                  validate={validationDocumentFCT}
                />
                <div className='m-top-x2'><hr className="separator-xxs"/></div>
                <div className='ftp-button-bar'>
                  <Link to={`/fct/${id}`}><ButtonGhost type='button'>{t('Cancelar')}</ButtonGhost></Link>
                  <ButtonSpinner type='submit' showSpinner={loading}> {t('Enviar solicitud')}</ButtonSpinner>
                </div>
              </C>
            </form>
          )}/>
      </F>
    </AppPage>
  )
}
