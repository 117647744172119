import {Component, Fragment, h} from "preact";
import {t} from "../../../_i18n";
import Summary from "../../../_common/components/Summary/summary";

export const ShowTerms = ({formative_zone, educationLaw, main}) => {
  if (formative_zone === 'CAT') {
    if (educationLaw === 'LOE') return <TermsLoeCat main={main} />
    else return <TermsLogseCat main={main} />
  }
  else return <TermsMad main={main} />
}

const TermsLoeCat = ({main = false}) => (
  <div className='m-top t-justify'>
    <Summary title={t('Cálculo de la nota de las Unidades Formativas (LOE)')} classNameTitle={main ? 'font-24' : 'font-18'}>
      <div dangerouslySetInnerHTML={{__html: t('terms_loe_cat_1')}}/>
    </Summary>
    <hr className="separator-xxs"/>
    <Summary title={t('Cálculo de la nota de los Módulos profesionales (LOE)')} classNameTitle={main ? 'font-24' : 'font-18'}>
      <div dangerouslySetInnerHTML={{__html: t('terms_loe_cat_2')}}/>
    </Summary>
    <hr className="separator-xxs"/>
    <Summary title={t('Cálculo de la nota final del ciclo formativo (LOE)')} classNameTitle={main ? 'font-24' : 'font-18'}>
      <div dangerouslySetInnerHTML={{__html: t('terms_loe_cat_3')}}/>
    </Summary>
    <hr className="separator-xxs"/>
  </div>
)

export const TermsLogseCat = ({main = false}) => (
  <div className='m-top t-justify'>
    <Summary title={t('Cálculo de la nota final de los módulos/créditos')} classNameTitle={main ? 'font-24' : 'font-18'}>
      <div dangerouslySetInnerHTML={{__html: t('terms_logse_cat_1')}}/>
    </Summary>
    <hr className="separator-xxs"/>
    <Summary title={t('Cálculo de la nota final del ciclo formativo (LOGSE)')} classNameTitle={main ? 'font-24' : 'font-18'}>
      <div dangerouslySetInnerHTML={{__html: t('terms_logse_cat_2')}}/>
    </Summary>
    <hr className="separator-xxs"/>
  </div>
)

export const TermsMad = ({main = false}) => (
  <div className='m-top t-justify'>
    <Summary title={t('Cálculo de la nota final de los módulos/créditos')} classNameTitle={main ? 'font-24' : 'font-18'}>
      <div dangerouslySetInnerHTML={{__html: t('terms_mad_1')}}/>
    </Summary>
    <hr className="separator-xxs"/>
    <Summary title={t('Cálculo de la nota final del ciclo formativo')} classNameTitle={main ? 'font-24' : 'font-18'}>
      <div dangerouslySetInnerHTML={{__html: t('terms_mad_2')}}/>
    </Summary>
    <hr className="separator-xxs"/>
  </div>
)
