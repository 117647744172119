import {SET_USER, FETCH_USER, SET_LOGIN_FAILED} from "./constants";
import AuthService from "../../../_services/auth-service";
import {globalCatch} from "../../../_services/global-catch";
import {svcPsService} from "../../../_services/ps-service";
import {svcNoticeService} from "../../../_services/notice-service";
import AppDispatch from "../../../_services/app-dispatch";
import {getHttp} from "../../../_common/common";



export const fetchUser = () => {
    return globalCatch.catch(async dispatch => {
        const user = await AuthService.instance().authenticate();

        if(user) {
            dispatch(setUser(user));
        }
        else {
            dispatch(setLoginFailed());
        }
    });
};


export const logout = () => {
    return globalCatch.catch(async dispatch => {

        dispatch(setUser(null));

        try{await (await svcPsService()).logout();}catch(e){console.log(e)}
        try{await (await svcNoticeService()).logout();}catch(e){console.log(e)}
        try{await AuthService.instance().signout();}catch(e){console.log(e)}
        await getHttp(micuentaenv.JOBS_API_basePath + '/cas/logout');

        if(isMobileApp) {
            const app_dispatch = new AppDispatch();
            app_dispatch.dispatchToApp('setLinks', JSON.stringify([]));
            app_dispatch.dispatchToApp('setPermanentNotifications', JSON.stringify({message:''}));
        }

    });
};

export const setLoginFailed = () => ({type: SET_LOGIN_FAILED});

export const setUser = (payload) => ({type: SET_USER, payload});
