import {Component, Fragment, h} from "preact";

const statusClassname = {
    'approved': 'approved',
    'failed': 'failed',
    'indifferent': 'gray'
}

const GradeBox = ({grade, status, className = '', hide = false}) => {
    const noticeStyle = hide ? 'transparent' : statusClassname[status]

    return <div className={'notice-module__notice ' + noticeStyle + ' ' + className}>
        <span>{!hide && grade}</span>
    </div>
}


export default GradeBox;
