import React, {Component, Fragment, h} from "preact";
import './progressCard-styles.scss'

export default function ProgressCard ({ title, percent, grade, subtitle }) {
  return (
    <div className='expedient-progressCard'>
      <div dangerouslySetInnerHTML={{__html: title}}/>
      {grade !== undefined && <div className='font-32 font-heavy m-top-half m-bottom-half font-color-149BAB'>{grade}</div>}
      {percent !== undefined && <div className='font-32 font-heavy m-top-half m-bottom-half font-color-149BAB'>{percent}%</div>}
      {subtitle !== undefined && <div className='font-12'>{subtitle}</div>}
      {percent !== undefined && <div className='border-progress' style={{ width: `${percent}%` }}/>}
    </div>
  )
}
