import {Fragment, h} from "preact";

import {Card, CF} from "../../_common/layout";
import {t} from "../../_i18n";

export const DocuSent = ()=><Fragment>
    <CF className="max-width">
        <div className='card p-top-half p-left p-right sm-p-x2-all'>
            <p className='m-top sm-no-m-top bold'>{t('Documentación provisional enviada')}</p>
            <div dangerouslySetInnerHTML={{__html: t('titulo-docu-sent')}}/>
            <p className="background-info card p-left p-bottom-half sm-no-m-bottom p-right">
                <p dangerouslySetInnerHTML={{__html: t('titulo-docu-sent-warning')}} />
            </p>
        </div>

        <div>
            <p className='m-x2-top' dangerouslySetInnerHTML={{__html: t('titulo-docu-sent-pedir-encuesta')}}/>
        </div>

        <button className="btn btn-primary m-top full-width sm-no-full-width" type="button"
                onClick={() => window.open(micuentaenv.Survey)}>
            {t('Valoración del servicio recibido')}
        </button>

    </CF>
</Fragment>;




