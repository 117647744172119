/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DocumentInfo from './DocumentInfo';

/**
 * The StudentInfoDocuments model module.
 * @module model/StudentInfoDocuments
 * @version 1.0.0
 */
class StudentInfoDocuments {
    /**
     * Constructs a new <code>StudentInfoDocuments</code>.
     * @alias module:model/StudentInfoDocuments
     */
    constructor() { 
        
        StudentInfoDocuments.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>StudentInfoDocuments</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StudentInfoDocuments} obj Optional instance to populate.
     * @return {module:model/StudentInfoDocuments} The populated <code>StudentInfoDocuments</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StudentInfoDocuments();

            if (data.hasOwnProperty('resume')) {
                obj['resume'] = DocumentInfo.constructFromObject(data['resume']);
            }
            if (data.hasOwnProperty('no_sexual_offences')) {
                obj['no_sexual_offences'] = DocumentInfo.constructFromObject(data['no_sexual_offences']);
            }
            if (data.hasOwnProperty('vaccination')) {
                obj['vaccination'] = DocumentInfo.constructFromObject(data['vaccination']);
            }
            if (data.hasOwnProperty('census')) {
                obj['census'] = DocumentInfo.constructFromObject(data['census']);
            }
            if (data.hasOwnProperty('motivation')) {
                obj['motivation'] = DocumentInfo.constructFromObject(data['motivation']);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/DocumentInfo} resume
 */
StudentInfoDocuments.prototype['resume'] = undefined;

/**
 * @member {module:model/DocumentInfo} no_sexual_offences
 */
StudentInfoDocuments.prototype['no_sexual_offences'] = undefined;

/**
 * @member {module:model/DocumentInfo} vaccination
 */
StudentInfoDocuments.prototype['vaccination'] = undefined;

/**
 * @member {module:model/DocumentInfo} census
 */
StudentInfoDocuments.prototype['census'] = undefined;

/**
 * @member {module:model/DocumentInfo} motivation
 */
StudentInfoDocuments.prototype['motivation'] = undefined;






export default StudentInfoDocuments;

