/**
 * Student Account
 * Student Account Api - Validation flow
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dmartinez@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import InlineResponse200 from '../model/InlineResponse200';
import ProfileSetChannels from '../model/ProfileSetChannels';
import ProfileSetItem from '../model/ProfileSetItem';
import ProfileSetTypes from '../model/ProfileSetTypes';
import ProfileValue from '../model/ProfileValue';

/**
* ProfileSet service.
* @module api/ProfileSetApi
* @version 1.0.0
*/
export default class ProfileSetApi {

    /**
    * Constructs a new ProfileSetApi. 
    * @alias module:api/ProfileSetApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get all profileset by user id
     * Get all profileset by user id
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ProfileSetItem>} and HTTP response
     */
    allProfileSetsWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling allProfileSets");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProfileSetItem];
      return this.apiClient.callApi(
        '/students/{id}/all_profile_sets', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get all profileset by user id
     * Get all profileset by user id
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ProfileSetItem>}
     */
    allProfileSets(id) {
      return this.allProfileSetsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get profileset type list
     * Get profileset type list
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ProfileSetTypes>} and HTTP response
     */
    getAllTypesWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProfileSetTypes];
      return this.apiClient.callApi(
        '/students/profile_set/getAllTypes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get profileset type list
     * Get profileset type list
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ProfileSetTypes>}
     */
    getAllTypes() {
      return this.getAllTypesWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get profileset data of a student
     * Get profileset data of a student
     * @param {Number} id Student Id
     * @param {String} channel ProfileSet channel tag (EMAIL/APP)
     * @param {String} type ProfileSet type tag (ACADEMIC/PROMO/JOB/JOB_UPDATE and more in future)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProfileValue} and HTTP response
     */
    getProfileSetWithHttpInfo(id, channel, type) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getProfileSet");
      }
      // verify the required parameter 'channel' is set
      if (channel === undefined || channel === null) {
        throw new Error("Missing the required parameter 'channel' when calling getProfileSet");
      }
      // verify the required parameter 'type' is set
      if (type === undefined || type === null) {
        throw new Error("Missing the required parameter 'type' when calling getProfileSet");
      }

      let pathParams = {
        'id': id,
        'channel': channel,
        'type': type
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProfileValue;
      return this.apiClient.callApi(
        '/students/{id}/profile_set/{channel}/{type}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get profileset data of a student
     * Get profileset data of a student
     * @param {Number} id Student Id
     * @param {String} channel ProfileSet channel tag (EMAIL/APP)
     * @param {String} type ProfileSet type tag (ACADEMIC/PROMO/JOB/JOB_UPDATE and more in future)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProfileValue}
     */
    getProfileSet(id, channel, type) {
      return this.getProfileSetWithHttpInfo(id, channel, type)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get profileset channel list
     * Get profileset channel list
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ProfileSetChannels>} and HTTP response
     */
    getlAllChannelsWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProfileSetChannels];
      return this.apiClient.callApi(
        '/students/profile_set/getAllChannels', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get profileset channel list
     * Get profileset channel list
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ProfileSetChannels>}
     */
    getlAllChannels() {
      return this.getlAllChannelsWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Set profileset by user id
     * Get multiple profileset by user id at once
     * @param {Number} id Student Id
     * @param {Object} opts Optional parameters
     * @param {String} opts.content json content
     * @param {Array.<module:model/ProfileSetItem>} opts.profileSetItem 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse200} and HTTP response
     */
    postAllProfileSetsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['profileSetItem'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling postAllProfileSets");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'content': opts['content']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse200;
      return this.apiClient.callApi(
        '/students/{id}/all_profile_sets', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Set profileset by user id
     * Get multiple profileset by user id at once
     * @param {Number} id Student Id
     * @param {Object} opts Optional parameters
     * @param {String} opts.content json content
     * @param {Array.<module:model/ProfileSetItem>} opts.profileSetItem 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse200}
     */
    postAllProfileSets(id, opts) {
      return this.postAllProfileSetsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get profileset data of a student
     * Get profileset data of a student
     * @param {Number} id Student Id
     * @param {String} channel ProfileSet channel tag (EMAIL/APP)
     * @param {String} type ProfileSet type tag (ACADEMIC/PROMO/JOB/JOB_UPDATE and more in future)
     * @param {String} profileSet Profileset value (true/false)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProfileValue} and HTTP response
     */
    setProfileSetWithHttpInfo(id, channel, type, profileSet) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling setProfileSet");
      }
      // verify the required parameter 'channel' is set
      if (channel === undefined || channel === null) {
        throw new Error("Missing the required parameter 'channel' when calling setProfileSet");
      }
      // verify the required parameter 'type' is set
      if (type === undefined || type === null) {
        throw new Error("Missing the required parameter 'type' when calling setProfileSet");
      }
      // verify the required parameter 'profileSet' is set
      if (profileSet === undefined || profileSet === null) {
        throw new Error("Missing the required parameter 'profileSet' when calling setProfileSet");
      }

      let pathParams = {
        'id': id,
        'channel': channel,
        'type': type,
        'profile_set': profileSet
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProfileValue;
      return this.apiClient.callApi(
        '/students/{id}/profile_set/{channel}/{type}/{profile_set}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get profileset data of a student
     * Get profileset data of a student
     * @param {Number} id Student Id
     * @param {String} channel ProfileSet channel tag (EMAIL/APP)
     * @param {String} type ProfileSet type tag (ACADEMIC/PROMO/JOB/JOB_UPDATE and more in future)
     * @param {String} profileSet Profileset value (true/false)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProfileValue}
     */
    setProfileSet(id, channel, type, profileSet) {
      return this.setProfileSetWithHttpInfo(id, channel, type, profileSet)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
