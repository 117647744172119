import React, { Fragment, Component, h} from "preact";
import './styles.scss'

export default function Spinner (){

    return(
        <div className="spinner">
            <div className="spinner-right">
                <div className="spinner-border" />
            </div>
            <div className="spinner-left">
                <div className="spinner-border" />
            </div>
        </div>
    )
}
