import React, {h} from "preact";
import {Card, FR} from "../../../_common/layout";
import Badge from "../../../_common/components/Badge";
import {ButtonGhost} from "../../../_common/components/buttons/buttons";
import {t} from "../../../_i18n";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {setExamSelected, openCartLink} from "../../../_rx/enrollment/exams/actions";
import ButtonSpinner from "../../../_common/components/buttons/buttonSpinner/buttonSpinner";
import {useState} from "preact/hooks";
import {toastService} from "../../../_services/toast-service";
import axios from "axios";

function CardUpcomingExamView({event, setExamSelected, isNextTerm}) {
    const history = useHistory()
    const [showSpinner, setShowSpinner] = useState(false)

    const handleClick = () => {
        history.push(`/examenes/apply`)
        setExamSelected(event)
    }

    const handleCreateCart = async () => {
        setShowSpinner(true);
        try {
            const generateCartResp = await axios.get(event.cart_url);

            if (generateCartResp.data && generateCartResp.data.cart_url) {
                window.open(generateCartResp.data.cart_url, '_blank');
            } else {
                toastService.showToast({message: t('error_cart_link'), type: 'danger'});
            }
        } catch (err) {
            toastService.showToast({message: t('error_cart_link'), type: 'danger'});
        }

        setShowSpinner(false);
    }

    return (
        <Card className='m-top' smOnly={false}>
            <div className='d-flex flex-space-between flex-column sm-no-flex-column'>
                <div className='sm-col-70'>
                    <div className='t-upper font-color-879CA1 font-14'>{event.formative_action_code}</div>
                    <div className='font-20 m-top-half'>{event.formative_action_title}</div>
                    {!isNextTerm && <Badge type='warning' className='m-top-half'>{event.exam_convocation_name}</Badge>}
                </div>
                <div className='sm-col-30 d-flex flex-center-y m-top sm-no-m-top'>
                    { event.has_exam_credit &&
                        <ButtonGhost className='t-upper full-width font-14' onClick={handleClick}>
                            {t('Reservar Plaza')}
                        </ButtonGhost>
                    }
                    { !event.has_exam_credit && event.can_buy_credit && !event.disable_cart_for_b2b &&
                        <ButtonSpinner className='full-width t-upper font-14'
                                       classNameContainer='full-width'
                                       showSpinner={showSpinner}
                                       onClick={handleCreateCart}
                                       disabled={!event.cart_url  || showSpinner}>
                            {t('Comprar derechos de examen')}
                        </ButtonSpinner>
                    }
                </div>
            </div>
            {!event.has_exam_credit &&
                <div>
                    <hr className='separator-xxs'/>
                    {event.can_buy_credit && !event.disable_cart_for_b2b &&
                        <div className="font-14 sm-col-70" dangerouslySetInnerHTML={{__html: t('exams_credit_text')}}/>
                    }
                    {event.can_buy_credit && event.is_b2b_enroll && event.disable_cart_for_b2b &&
                        <div className="font-14 sm-col-70" dangerouslySetInnerHTML={{__html: t('rematricula_disabled_b2b')}}/>
                    }
                    {event.can_buy_credit && event.is_b2b_enroll && !event.disable_cart_for_b2b &&
                        <div className="font-14 sm-col-70" dangerouslySetInnerHTML={{__html: t('rematricula_b2b_online')}}/>
                    }
                    {!event.can_buy_credit && event.is_last_credit &&
                        <div className="font-14 sm-col-70" dangerouslySetInnerHTML={{__html: t('exams_no_credits')}}/>
                    }
                </div>
            }
        </Card>
    )
}

CardUpcomingExamView = connect(
    ({}) => ({}),
    {setExamSelected})(CardUpcomingExamView);


export default ({...rest}) => {
    return (<CardUpcomingExamView {...rest} />);
}
