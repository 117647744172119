import React, {Fragment, Component, h} from "preact";
import {t} from '../../../_i18n';
import {connect} from 'react-redux';
import Dropdown from '../../../_common/components/dropdown';
import {useState} from 'preact/hooks'
import ModalCertificate from './ModalCertificate'

function HeaderExams({studentMail, examsStatus, locale}) {
  const allowDownloadClassCertificate = examsStatus.current?.exam_certificate || examsStatus.next?.exam_certificate || examsStatus.certificates_academic_agreements?.length > 0
  const allowDownloadJoinedCertificate = examsStatus.current?.joined_certificate || examsStatus.next?.joined_certificate || examsStatus.certificates_academic_agreements?.length > 0
  const showDropdown = allowDownloadClassCertificate || allowDownloadJoinedCertificate
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')

  const openModal = (type) => {
    setModalType(type)
    setShowModal(true)
  }

  const handleClose = () => {
    setShowModal(false)
    setModalType('')
  }

  return (
    <Fragment>
      <div className='d-flex flex-center-y flex-space-between'>
        <h1 className='hero'>{t('Exámenes')}</h1>
        { showDropdown &&
          <Dropdown icon='fas fa-ellipsis-v font-color-486268' position='left' justIcon>
            {allowDownloadClassCertificate && <div className='dropdown-item' onClick={() => openModal('exam')}>{t('Descargar justificante de asistencia')}</div>}
            {allowDownloadJoinedCertificate && <div className='dropdown-item' onClick={() => openModal('joined')}>{t('Descargar justificante de inscripción')}</div>}
          </Dropdown>
        }
      </div>
      <ModalCertificate showModal={showModal} onClose={handleClose} type={modalType} studentMail={studentMail} locale={locale} academicSections={examsStatus.certificates_academic_agreements}/>
    </Fragment>
  )
}

export default connect(
  ({status, enrollmentExams, lang})=>({
    studentMail: status.student_meta.student_email,
    examsStatus: enrollmentExams.examsStatus,
    locale: lang
  }),
  {})(HeaderExams);
