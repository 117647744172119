export default function jobFilterPrepare ({ actualFilter, jobFilter, statesApi, academicProgramsApi }){
  const statesArray = [];
  const academicProgramsArray = [];
  const modalityArray= [];
  const presenceTypeArray = [];

  let presenceType = jobFilter.presenceType;
  let modality = jobFilter.modality;
  let states = jobFilter.states;
  let academicPrograms = jobFilter.academicPrograms;

  if (actualFilter) {
    modality = actualFilter.modality;
    states = actualFilter.states;
    academicPrograms = actualFilter.academicPrograms;
    presenceType = actualFilter.presenceType;
  }

  Object.keys(modality).filter(modalityItem => (modality[modalityItem])
    && modalityArray.push(modalityItem));

  Object.keys(states).filter(statesItem => (states[statesItem])
    && Object.keys(statesApi).find((key) => (statesApi[key] === statesItem) && statesArray.push(key)));
  Object.keys(academicPrograms).filter(academicProgramsItem => (academicPrograms[academicProgramsItem])
    && Object.keys(academicProgramsApi).find((key) => (academicProgramsApi[key] === academicProgramsItem) && academicProgramsArray.push(key)));
  Object.keys(presenceType).filter(presenceTypeItem => (presenceType[presenceTypeItem])
    && presenceTypeArray.push(presenceTypeItem));

  let objectToSend = {}
  if (modalityArray.length > 0){
    objectToSend = {...objectToSend, modalities: modalityArray.join(",")}
  }
  if (statesArray.length > 0){
    objectToSend = {...objectToSend, states: statesArray.join(",")}
  }
  if (academicProgramsArray.length > 0){
    objectToSend = {...objectToSend, academicPrograms: academicProgramsArray.join(",")}
  }
  if (presenceTypeArray.length > 0){
    objectToSend = {...objectToSend, presences: presenceTypeArray.join(",")}
  }
  return objectToSend;
}
