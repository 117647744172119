import React, { Fragment, Component, h} from "preact";
import './sideBarModal.scss'

export default function SideBarModal({children, visible = false, onClose}){
    const visibleClass = visible ? 'visible' : 'no-visible';
    document.body.style.overflowY = (visible) ? "hidden" : 'visible';


    const handleOutClick = () => onClose && onClose()

    const handleInsideClick = (e) => {
        e.stopPropagation()
    }

    return (
        <div>
            <div
                className={`side-bar-modal__background ${visibleClass}`}
                onClick={handleOutClick}
            />

            <div className={`side-bar-modal__container ${visibleClass}`} onClick={handleInsideClick}>
                  {children}
            </div>
        </div>
    )
}
