import { h } from "preact";
import './modal-size-guide.scss'
import { t } from '../../../_i18n'

export default function Table ({ sizes, className }) {
  const width = 80/(sizes.header.length - 1)

  return (
    <div className={`fct-uniform-table ${className}`}>
      <div className='fct-uniform-table__title'>{t(sizes.title)}</div>
      <div className='fct-uniform-table__header'>
        {sizes.header.map(data => <div style={{width: `${width}%`}}>{t(data)}</div>)}
      </div>
      <div className='fct-uniform-table__body'>
        {sizes.content.map(data => (
          <div className='fct-uniform-table__row'>
            {data.map(size => <div style={{width: `${width}%`}}>{t(size)}</div>)}
          </div>
        ))}
      </div>
    </div>
  )
}
