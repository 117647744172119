import React, {Component, createRef, Fragment, h} from "preact";
import {isValidDni, rndStr, setClass, strHash} from '../../common';
import {isRequired} from "../validators";
import AutoComplete from "./Autocomplete";
import {E, FR, R, RE} from "../../layout";
import {t} from '../../../_i18n';

import {Field} from 'react-final-form';
import { OnChange, OnFocus, OnBlur, ExternallyChanged } from "react-final-form-listeners";
import {matchesPattern} from "../validators/isRequired";

function formatDate(s:any){
    return s && (new Date(s)).toISOString().substr(0, 10);
}

function getDefaultPattern(inputType:any) {
    let e = inputType == "text" ? "['A-Za-z0-9 \u00C0-\u00FF\\-]{2,}" : inputType == "number" ? "[0-9]{1,}" : inputType == "tel" ?  "[+0-9]{9,15}" : inputType == "password" ? ".{8,}" : inputType == "email" ?  "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$" :  "";
    return e;
}

function showPwd(el:any, id:any) {
    const x:any = document.getElementById(id);
    if (x.type === "password") {
        x.type = "text";
        el.className = 'fa fa-eye showpwd';
    } else {
        x.type = "password";
        el.className = 'fa fa-eye-slash showpwd';
    }
}

export const isValidDniInForm = (dni: string) => !isValidDni(dni)

const capitalize = (str:any, lower = false) =>
    str && (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match:any) => match.toUpperCase());
;

export function showError(meta:any) {
    return (meta.invalid && (meta.submitFailed));
}

export const validateString = (text: any) => {
    if (text === undefined || text === null || (text && typeof(text) === "string") && text.trim() === "" || text === "") return true
    return false
}

export function showErrorPwd() {
    const newPwd:any = document.querySelector("input[name='new_password']");
    const newPwdConfirm:any = document.querySelector("input[name='new_password_conf']");

    if (newPwd !== null && newPwdConfirm !== null) {
        if (newPwd.value !== newPwdConfirm.value) {
            const element:any = document.getElementById('new_password_conf');
            element.style.display = 'block';
            element.style.opacity = 1;
        } else {
            const element:any = document.getElementById('new_password_conf');
            element.style.display = 'none';
            element.style.opacity = 0;
        }
    }
}

export function showErrorRadio(name:any) {
    if (document.getElementById(name) !== null) {
        if (document.querySelector("input[name='" + name + "']:checked") === null) {
            const element:any = document.getElementById(name);
            element.style.display = 'block';
            element.style.opacity = 1;
        } else {
            const element:any = document.getElementById(name);
            element.style.display = 'none';
            element.style.opacity = 0;
        }
    }
}

//
// export const Form = ({children, onSubmit, initialValues}) =>
//     <FinalForm onSubmit={onSubmit} initialValues={initialValues} render={({handleSubmit})=>
//         <form className='form' action="" onSubmit={handleSubmit}>{children}</form>}
//     />
// ;

// export const FormSection = ({children, name})=>
//     <article className="container-fluid">
//         {children}
//     </article>
// ;

export let FormFieldContainer = ({children, id, name, label, required, error, className, specificError, classNameCheck = '', showOptional = true}:any)=> {
    if(!children || !children.props) return null;
    const errorText = (typeof error === 'string') ? error : (t(name) || 'Error');
    const showError = (error) && (specificError ? specificError : errorText)
    className = className || '';
    let el;
    // if (children.props.component === 'select'){
    //     el = <div className={`form-group ${error?'error':''}`}>
    //             {children}
    //             <span className="error-msg">{error ? error : t('Select error')}</span>
    //          </div>;
    // } else
    if (children.props.type == 'radio') {
        el = <label className={`radio-container form-control ${error? 'error' : ''} ${className}`} htmlFor={id} data-name={name}>
                {label}
                {children}
                <span className="checkmark"> </span>
             </label>;
    } else if (children.props.type == 'checkbox') {
        el = <label className={`checkbox-container form-control ${error? 'error' : ''} ${className}`} htmlFor={id} data-name={name}>
            {label}
            {children}
            <span className={`checkmark ${classNameCheck}`}> </span>
        </label>;
    } else if (children.props.type == 'password') {
        el = <div className={`form-group ${error?'error':''} ${className}`} data-name={name}>
                <label className="form-control-label" htmlFor={id}>{label}{!required && ` (${t('opcional')})`}</label>
                {children}
                <span className="error-msg">{specificError ? specificError : t('Rango contraseña')}</span>
                <i className="fa fa-eye-slash showpwd" onClick={(e)=>showPwd(e.target, id)}/>
             </div>;
    } else {

        el = <div className={`form-group d-inline ${showError?'error':''} ${className}`} data-name={name}>
                <label className="form-control-label" htmlFor={id}>{label}{!required && showOptional && ` (${t('opcional')})`}</label>
                {children}
                {children.type === 'select'? <i className="far fa-angle-right" /> :''}
                {showError && <span className="error-msg">{showError}</span>}
             </div>;
    }

    return el;
};


function validateHelper(value:any, allValues:any, meta:any, required:any, pattern:any, validateFn:any){
    if(validateFn) return validateFn(value, allValues, meta);
    let res = false; // error false
    if(required) res = isRequired(value);
    if(res) return true; //error true;
    if(!res && pattern) res = matchesPattern(value, pattern);
    return res; //error false
}


export const FormField = ({name, idForced, type='text', label, disabled, placeholder, min, max, step, pattern, required, format, onChange, onFocus, onBlur, ExternallyChanged, title, validate, showOptional, specificError, ...props}:any)=> {
    const id = idForced ? idForced : strHash(name+type+label+title);
    let pat = getDefaultPattern(type);

    return <Field
        name={name}
        type={type}
        validate={(value, allValues, meta)=>validateHelper(value, allValues, meta, required, pattern, validate)}
        format={format}
        {...props}>{({input, meta})=>{
        return <Fragment><FormFieldContainer id={id} name={name} label={label} required={required} error={showError(meta) && meta.error} showOptional={showOptional} specificError={specificError}>
            <input
                    id={id}
                    placeholder={placeholder}
                    className={"form-control" + (showError(meta) ? " error" : "")}
                    required={required}
                    pattern={pattern ? pattern : pat}
                    type={type}
                    title={title}
                    min={min}
                    max={max}
                    step={step}
                    disabled={disabled}
                    {...(input as any)}/>
                </FormFieldContainer>
            {(onChange || onFocus || onBlur || ExternallyChanged) &&
                <FormFieldEventListeners name={name} onChange={onChange} onFocus={onFocus} onBlur={onBlur} ExternallyChanged={ExternallyChanged} />}
            </Fragment>;
        }
    }</Field>;
};

export const TextArea = ({name,label, placeholder, min, max, step, pattern, required, format, onChange, onFocus, onBlur, ExternallyChanged, title, validate, rows, maxlength, ...props}:any)=> {
    const type='textarea';
    const id = strHash(name+type+label+title);

    return <FormFieldContainer id={id} name={name} label={label} required={required} >
            <textarea
                id={id}
                placeholder={placeholder}
                maxlength={maxlength}
                onChange={onChange}
                className={"form-control"}
                // required={required}
                title={title}
                rows={rows}
                {...(props as any)}/>
        </FormFieldContainer>
};

export const FormFieldTextArea = ({name,label, placeholder, min, max, step, pattern, required, format, onChange, onFocus, onBlur, ExternallyChanged, title, validate, rows, maxlength, minlength, specificError, ...props}:any)=> {
    const type='textarea';
    const id = strHash(name+type+label+title);

    return <Field
        name={name}
        type={type}
        validate={(value, allValues, meta)=>validateHelper(value, allValues, meta, required, pattern, validate)}
        format={format}
        {...props}>{({input, meta})=>{
        return <Fragment><FormFieldContainer id={id} name={name} label={label} required={required} error={showError(meta) && meta.error} specificError={specificError}  {...(props as any)} >
            <textarea
                id={id}
                placeholder={placeholder}
                maxlength={maxlength}
                minlength={minlength}
                className={"form-control" + (showError(meta) ? " error" : "")}
                // required={required}
                specificError={specificError}
                title={title}
                rows={rows}
                {...(input as any)}/>
        </FormFieldContainer>
            {(onChange || onFocus || onBlur || ExternallyChanged) &&
            <FormFieldEventListeners name={name} onChange={onChange} onFocus={onFocus} onBlur={onBlur} ExternallyChanged={ExternallyChanged} />}
        </Fragment>;
    }
    }</Field>;
};

export const InputField = ({name, type='text', label, placeholder, min, max, step, pattern, required, format, onFocus, onBlur, ExternallyChanged, defaultValue, title, validate, showOptional, disabled, ...props}:any)=> {
    const id = strHash(name+type+label+title);
    let pat = getDefaultPattern(type);

    return <FormFieldContainer id={id} name={name} label={label} required={required} showOptional={showOptional}>
                <input
                    id={id}
                    placeholder={placeholder}
                    className={"form-control"}
                    pattern={pattern ? pattern : pat}
                    onBlur={onBlur}
                    type={type}
                    title={title}
                    value={defaultValue}
                    min={min}
                    max={max}
                    step={step}
                    disabled={disabled}
                    {...props}
                />
    </FormFieldContainer>


};


export const FormFieldDate = (props:any)=> {
    return FormField({...props, format:formatDate});
};

export const FormFieldRadioCheckboxList = ({ name, type='radio', label, options, required, pattern, validate, specificError, className, onChange }: any) => {
    return (
      <Field
        name={name}
        validate={(value, allValues, meta)=>validateHelper(value, allValues, meta, required, pattern, validate)}
        >
          {({input, meta})=> (
            <div className={`form-group d-inline ${showError(meta) && meta.error ? 'error' : ''} ${className || ''}`}>
                <label className="form-control-label" >{label}</label>
                <div className='d-flex width-fit-content'>
                    {options.map((radio: any) => (
                      <RadioCheckbox
                        label={t(radio.name)}
                        type={type}
                        defaultValue={radio.key === input.value}
                        onChange={() => {
                            onChange && onChange(radio.key)
                            input.onChange(radio.key)
                        }}
                      />))}
                </div>
                {showError(meta) && meta.error && <span className="error-form">{specificError}</span>}
            </div>
          )}
      </Field>
    )
}

export const RadioCheckbox = ({id, name, type, label,  error, pattern, required, className, classNameCheck, onChange, defaultValue, ...props}:any)=> {
    return <FormFieldContainer id={id} name={name} label={label} required={required} className={className} classNameCheck={classNameCheck}>
        <input
            id={id}
            className={"form-control" + (error?' error':'')}
            type={type}
            checked={defaultValue}
            required={required}
            {...props}
            onChange={(val: any) => onChange(val.target.checked)}
        />
    </FormFieldContainer>
};

export const UploadFile = ({children, classNameButton, accept, ...props}: any) => {
    const id = rndStr();
    return (
        <div className='simple-upload-container'>
            <input className="simple-upload-file__input" id={id} type="file" accept={accept} {...props}/>
            <label className={`simple-upload-file__label ${classNameButton}`}  for={id}>
                {children}
            </label>
        </div>)
}


export const FormFieldRadioCheckbox = ({name, type, label,  error, pattern, required, format, onChange, onFocus, onBlur, ExternallyChanged, className, ...props}:any)=> {
    const id = strHash(name+label);
    return <Field
        name={name}
        type={type}
        validate={required && isRequired}
        format={format}
        {...props}>{({input, meta})=>{

            if (meta.submitFailed) {
                showErrorRadio(name);
            }

            return <Fragment><FormFieldContainer id={id} name={name} label={label} required={required} error={showError(meta)} className={className}>
                <input
                    id={id}
                    className={"form-control" + (showError(meta) ? " error" : "")}
                    required={required}
                    type={type}
                    {...(input as any)}/>
                </FormFieldContainer>
                {(onChange || onFocus || onBlur || ExternallyChanged) &&
                <FormFieldEventListeners name={name} onChange={onChange} onFocus={onFocus} onBlur={onBlur} ExternallyChanged={ExternallyChanged} />}
            </Fragment>;
        }}
    </Field>;
};

export class FormFieldSelect extends Component<any,any> {
    id = rndStr();
    // ref = createRef();
    state = {hasValue: false};
    select:any = null;

    componentDidMount() {
        this.select = document.getElementById(this.id);
        document.getElementById(this.id)?.addEventListener('change', ()=>this.updateHasValue());
        this.updateHasValue();
    }

    componentDidUpdate() {
        this.updateHasValue();
    }

    updateHasValue(){
        const hasValue = !!this.select.value && this.select.value != '';
        if(this.state.hasValue != hasValue) {
            this.setState({hasValue});
        }
        //
        // const refProps = this.ref.current.props;
        // const cName = ' placeholder-shown';
        // if(!this.select.value){
        //     if(!refProps.className.includes(cName))
        //         refProps.className += cName;
        // }
        // else{
        //     if(refProps.className.includes(cName))
        //         refProps.className = refProps.className.replace(cName, '');
        // }
    }

    changed(prevValue:any, value:any){
        try {
            console.log('changed', prevValue, value);
        }
        catch(e){
            console.log(e);
        }
    }

    render({name, label, required, error, options, options_groups, labels_groups, onChange, onFocus, onBlur, ExternallyChanged, disabled, specificError}:any) {
        return <Field
            id={this.id}
            name={name}
            validate={required && isRequired}
            {...this.props}>{({input, meta}) => {
                // if (name === 'last_course.academic_year') {
                //     console.log('meta', JSON.stringify(meta));
                // }
                return <Fragment>
                    <FormFieldContainer id={this.id} name={name} label={label} required={required} error={showError(meta) } specificError={specificError}>
                        <select
                            id={this.id}
                            className={"form-control" + (this.state.hasValue?' placeholder-hidden':'') + (showError(meta) ? " error" : "")}
                            required={required}
                            disabled={disabled}
                            {...(input as any)}>
                                <option />
                                {options_groups ? (
                                    options_groups.map((grp:any, index:any) =>
                                        <optgroup label={labels_groups[index]}>
                                            {grp.map((o:any) => <option value={o.key} key={o.key}>{(name === "data.birth_country.id" || name === "address.city.state.country.id") ? capitalize(o.name, true) : o.name}</option>)}
                                        </optgroup>
                                    )
                                ) :
                                    (options.map((o:any) => <option value={o.key} key={o.key}>{(name === "data.birth_country.id" || name === "address.city.state.country.id") ? capitalize(o.name, true): o.name}</option>))}
                        </select>
                    </FormFieldContainer>
                    {(onChange || onFocus || onBlur || ExternallyChanged) &&
                    <FormFieldEventListeners name={name} onChange={onChange} onFocus={onFocus} onBlur={onBlur}
                                         ExternallyChanged={ExternallyChanged}/>}
                </Fragment>;
            }}
        </Field>;
    }
}


export class Select extends Component<any,any> {
    id = rndStr();
    // ref = createRef();
    state = {hasValue: false};
    select:any = null;

    componentDidMount() {
        this.select = document.getElementById(this.id);
        document.getElementById(this.id)?.addEventListener('change', ()=>this.updateHasValue());
        this.updateHasValue();
    }

    componentDidUpdate() {
        this.updateHasValue();
    }

    updateHasValue(){
        const hasValue = !!this.select.value && this.select.value != '';
        if(this.state.hasValue != hasValue) {
            this.setState({hasValue});
        }
    }

    changed(prevValue:any, value:any){
        try {
            console.log('changed', prevValue, value);
        }
        catch(e){
            console.log(e);
        }
    }

    render({name, label, required, error, options, options_groups, labels_groups, onChange, onFocus, onBlur, disabled, value, ...rest}:any) {
        return <Fragment>
                <FormFieldContainer id={this.id} name={name} label={label} required={required} >
                    <select
                        id={this.id}
                        className={"form-control" + (this.state.hasValue?' placeholder-hidden':'')}
                        required={required}
                        disabled={disabled}
                        onChange={onChange}
                        {...(rest as any)}>
                            {!this.props.noEmptyOption && <option />}
                            {options_groups ? (
                                options_groups.map((grp:any, index:any) =>
                                    <optgroup label={labels_groups[index]}>
                                        {grp.map((o:any) => <option value={o.key} key={o.key} selected={value==o.key}>{(name === "data.birth_country.id" || name === "address.city.state.country.id") ? capitalize(o.name, true) : o.name}</option>)}
                                    </optgroup>
                                )
                            ) :
                            (options.map((o:any) => <option value={o.key} key={o.key} selected={value==o.key}>{(name === "data.birth_country.id" || name === "address.city.state.country.id") ? capitalize(o.name, true): o.name}</option>))}
                    </select>
                </FormFieldContainer>
            </Fragment>;
    }
}

export const SubmitButtonRow = ({children, disabled, className, onClick, onCancel}:any)=>
    <FR>
        <E className={className ? className  : ''}>
            <button className={'btn btn-primary submit sm-auto-width'} style="margin-bottom:8px" type='submit' disabled={disabled} onClick={onClick}>{children || t('Guardar')}</button>
        </E>
        {onCancel &&
        <E>
             <button className={'btn btn-link submit sm-auto-width'} style="margin-bottom:8px" type='button' onClick={onCancel}>{t('Cancelar')}</button>
        </E>
        }
    </FR>
;

export let FormFieldPoblacion = ({ name, label, required, list, country, onChange, onFocus, onBlur, es_country=61, map_keys_as_names=false}:any) => {
    if (country != es_country) {
        return <FormField name={name} label={label} required={required} />
    }

    let suggestions:any = []
    if (list) {
        suggestions = list.map((ele:any) => {
            return {key: (map_keys_as_names ? ele.name : ele.id), value: ele.name}
        });
    }

    const id = strHash(name+label);

    return suggestions.length && [
        <Field
            id={id}
            name={name}
            className="form-control"
            validate={required && isRequired}>
            {props => (
                <div className={"form-group" + (showError(props.meta) ? " error" : "")} data-name={name}>
                    {list &&
                    <AutoComplete
                        userInput={props.input.value}
                        suggestions={suggestions}
                        id={id}
                        name={name}
                        onValueChanged={props.input.onChange}
                        required={required}
                        label={label}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        map_keys_as_names={map_keys_as_names}
                        input={props.input}
                        meta={props.meta}
                        />
                    }
                    { props.meta.error && <span className="error-msg">{t('Escriba una población válida')}</span> }
                </div>
            )}
        </Field>,
        <FormFieldEventListeners name={name} onChange={onChange} />
    ];
}

export const FormFieldSplittedDate = ({ name, required, onChange }:any)=> {

    return <Field name={name} className="form-control" validate={required && isRequired}>
        {props => {
            let v = null;
            // Hack para forzar el formato string aaaa-mm-dd que requiere el Back en el guardado. En próximas vesiones estaría
            // bien mcambiarlo para trabajar siempre con Date()
            if (typeof props.input.value === 'string') {
                v = props.input.value.substr(0,10);
            } else {
                v = formatDate(props.input.value);
            }

            const [aaaa, mm, dd] = v.split('-');

            const idAAAA = strHash(name+'aaaa');
            const idMM = strHash(name+'mm');
            const idDD = strHash(name+'dd');
            const required = true;

            const dateBlur = () => {
                const dd = document.getElementById(idDD) as any;
                let ndd = dd.value;
                let nmm = (document.getElementById(idMM) as any).value;
                const naaaa = (document.getElementById(idAAAA) as any).value;

                const iday = parseInt(ndd);
                const imonth = parseInt(nmm);
                const iyear = parseInt(naaaa);

                if ([1,3,5,7,8,10,12].includes(imonth)) {
                    if (iday > 31) ndd = "31";
                }
                if ([4,6,9,11].includes(imonth)) {
                    if (iday > 30) ndd = "30";
                }
                if (imonth == 2) {
                    if (iyear % 4 == 0 && (iyear % 100 != 0 || iyear % 400 == 0)) {
                        if (iday > 29) ndd = "29";

                    } else {
                        if (iday > 28) ndd = "28";
                    }
                }

                dd.value = ndd;

                if (nmm != '') {
                    nmm = nmm.padLeft(2, '0');
                }
                if (ndd != '') {
                    ndd = ndd.padLeft(2, '0');
                }
                // Forma parte del hack para convertir de Date() a string porque el back requiere string en el guardado. Sin embargo, el swagger lo define como tipo Date
                const v = (naaaa && nmm && ndd) ? naaaa + '-' + nmm + '-' + ndd : null;
                props.input.onChange(v);
                if (onChange)
                    onChange()

            }

            const dateFocusOut = () => {
                const dayElement:any = document.querySelector('input[name="dd"]');
                const monthElement:any = document.querySelector('input[name="mm"]');
                const yearElement:any = document.querySelector('input[name="aaaa"]');
                const errorElement:any = document.getElementById("fieldSplittedDateError");

                let ndd = dayElement.value;
                let nmm = monthElement.value;
                const naaaa = yearElement.value;

                const iday = parseInt(ndd);
                const imonth = parseInt(nmm);
                const iyear = parseInt(naaaa);

                if (isNaN(iday) && isNaN(imonth) && isNaN(iyear)) {
                    dayElement.classList.add('error');
                    monthElement.classList.add('error');
                    yearElement.classList.add('error');
                    errorElement.style.display = "block";
                    errorElement.style.opacity = 1;
                    errorElement.innerText = t(name);

                } else if (isNaN(iday) && !props.meta.pristine) {
                    dayElement.classList.add('error');
                    (isNaN(imonth) && !props.meta.pristine) ? monthElement.classList.add('error') : monthElement.classList.remove('error');
                    (isNaN(iyear) && !props.meta.pristine) ? yearElement.classList.add('error') : yearElement.classList.remove('error');
                    errorElement.style.display = "block";
                    errorElement.style.opacity = 1;
                    errorElement.innerText = t(name + '_day');

                } else if (isNaN(imonth) && !props.meta.pristine) {
                    monthElement.classList.add('error');
                    (isNaN(iday) && !props.meta.pristine) ? dayElement.classList.add('error') : dayElement.classList.remove('error');
                    (isNaN(iyear) && !props.meta.pristine) ? yearElement.classList.add('error') : yearElement.classList.remove('error');
                    errorElement.style.display = "block";
                    errorElement.style.opacity = 1;
                    errorElement.innerText = t(name + '_month');

                } else if (isNaN(iyear) && !props.meta.pristine) {
                    yearElement.classList.add('error');
                    (isNaN(iday) && !props.meta.pristine) ? dayElement.classList.add('error') : dayElement.classList.remove('error');
                    (isNaN(imonth) && !props.meta.pristine) ? monthElement.classList.add('error') : monthElement.classList.remove('error');
                    errorElement.style.display = "block";
                    errorElement.style.opacity = 1;
                    errorElement.innerText = t(name + '_year');

                } else if (!isNaN(iday) && !isNaN(imonth) && !isNaN(iyear)) {
                    dayElement.classList.remove('error');
                    monthElement.classList.remove('error');
                    yearElement.classList.remove('error');
                    errorElement.style.display = "none";
                    errorElement.style.opacity = 0;
                }
            }

            if (props.meta.submitFailed) {
                dateFocusOut();
            }

            return <Fragment>
                <R className="row-3-el">
                    <E className="no-margin-bottom">
                        <div className="form-group" data-name={name}>
                            <label className="form-control-label" htmlFor={idDD}>{t('Día')}</label>
                            <input
                                placeholder="DD"
                                className={"form-control " + (showError(props.meta) ? "error" : "")}
                                name="dd"
                                type="number"
                                min="1"
                                max="31"
                                value={dd}
                                id={idDD}
                                onBlur={dateBlur}
                                {...{onfocusout:dateFocusOut}as any}
                                // pattern=".{2,}"
                                required={required} />

                        </div>
                    </E>
                    <E className="no-margin-bottom">
                        <div className="form-group">
                            <label className="form-control-label" htmlFor={idMM}>{t('Mes')}</label>
                            <input
                                placeholder="MM"
                                className={"form-control " + (showError(props.meta) ? "error" : "")}
                                name="mm"
                                type="number"
                                min="1"
                                max="12"
                                value={mm}
                                id={idMM}
                                onBlur={dateBlur}
                                {...{onfocusout:dateFocusOut}as any}
                                required={required} />

                        </div>
                    </E>
                    <E className="no-margin-bottom">
                        <div className="form-group">
                            <label className="form-control-label" htmlFor={idAAAA}>{t('Año')}</label>
                            <input
                                placeholder="AAAA"
                                className={"form-control " + (showError(props.meta) ? "error" : "")}
                                name="aaaa"
                                type="number"
                                min="1900"
                                max="2060"
                                value={aaaa}
                                id={idAAAA}
                                onBlur={dateBlur}
                                {...{onfocusout:dateFocusOut}as any}
                                required={required} />
                        </div>
                    </E>
                </R>
                <RE className="no-margin-top">
                    <small className="helper-field-color">{t('Fecha nacimiento exemple')}</small>
                    <span id="fieldSplittedDateError" className="error-msg error-margin-top"></span>
                </RE>

            </Fragment>
        }}
    </Field>
};

export const GotoError = ({submitFailed}:any)=> {
    submitFailed ?
        setTimeout(() => {
            const el = document.querySelector('.error');
            el && el.scrollIntoView({ behavior: 'smooth' })
        }):
        null

}

/**
 * https://github.com/final-form/react-final-form-listeners
 */
export const FormFieldEventListeners = ({ name, onChange, onFocus, onBlur, onKeyDown, externallyChanged }:any) => {
    return  <Fragment>
        {onChange && <OnChange name={name}>
        {(value:any, previous:any) => {
            onChange(value, previous)
        }}
        </OnChange>}
        {onFocus && <OnFocus name={name}>
            {() => onFocus()}
        </OnFocus>}
        {onBlur && <OnBlur name={name}>
            {() => onBlur()}
        </OnBlur>}
        {externallyChanged && <ExternallyChanged name={name}>
            {(changed:any) => externallyChanged(changed)}
        </ExternallyChanged>}
    </Fragment>

};
