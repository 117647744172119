import React, { Fragment, Component, h} from "preact";
import {t} from '../../../_i18n';
import {Link} from "react-router-dom";
import {useState} from "preact/hooks";


export default function Tabs({onClick}) {
    const [ status, setStatus ] = useState('next')

    const handleChangeStatus = (newStatus) => {
        if(status !== newStatus){
            setStatus(newStatus)
            onClick(newStatus)
        }
    }

    return (
        <div className="tabs-container">
            <div className="inline">
                <Link to="#" className={status === 'next'?'selected':''} onClick={() => handleChangeStatus('next')}>{t('Próximos')}</Link>
            </div>
            <div className="inline">
                <Link to="#" className={status === 'past'?'selected':''} onClick={() => handleChangeStatus('past')}>{t('Pasados')}</Link>
            </div>
        </div>
    )
}
