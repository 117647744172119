import React, {Component, Fragment, h} from "preact";
import './DropdownBadge-styles.scss';
import {useEffect, useState} from "preact/hooks";
import Badge from '../Badge'

export default function DropdownBadge ({ children, title }: any){
  const [visible, setVisible] = useState(false)

  const handleDropdown = () => setVisible(!visible)

  useEffect(() => {
    if (visible){
      document.addEventListener("click", handleDropdown);
      return () => document.removeEventListener("click", handleDropdown);
    }
  }, [visible])

  return (
    <div className='dropdown-badge'>
      <div className='dropdown-badge__header' onClick={handleDropdown}>
        <Badge type={`default${visible ? '-opacity' : ''}`}>{title} <i className={`fas fa-caret-down`} /></Badge>
      </div>
      { visible &&
        <div className='dropdown-badge__body'>
          {children}
        </div>
      }
    </div>
  )
}
