import React, { h } from "preact";
import {t} from "../../_i18n";
import {Card} from "../../_common/layout";
import {ButtonGhost} from "../../_common/components/buttons/buttons";
import {Section} from "./components";
import {Link} from "react-router-dom";

export default function Notifications({buttonLink}){


    return (
        <div className='p-left-half sm-no-p-left p-right-half sm-no-p-right'>
            <Card className='m-top sm-p-x2-bottom p-bottom'>
                <div className='font-24 font-medium m-top'>{t('Configurar comunicaciones')}</div>
                <Section className='m-top'>
                    <span>{t('notifications_subtitle')}.</span>
                    <Link to={buttonLink}><ButtonGhost className='m-top-half sm-no-m-top'>{t('Configurar')}</ButtonGhost></Link>
                </Section>
            </Card>
        </div>
    )
}