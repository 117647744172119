import { h } from 'preact'
import './steps-styles.scss'
import { Link } from 'react-router-dom'
import { ButtonPrimary } from '../../../_common/components/buttons/buttons'
import {
  FORMATIVE_ZONE_CAT,
  LINK_CERTIFICATES, LINK_ENROLL_CAT, LINK_ENROLL_MAD,
  LINK_FCT, LINK_FCT_CAT, LINK_FCT_MAD,
  LINK_TITLE_REQUEST, LINK_TITLE_REQUEST_CAT, LINK_TITLE_REQUEST_MAD, LINK_VALIDATIONS_CAT, LINK_VALIDATIONS_MAD,
  LINK_VALIDATIONS_REQUESTED
} from './constants'

export default function StepInfo ({ onSubmit, formative_zone }) {
  return (
    <div className='step-container'>
      <div className='font-20 bold'>Guías de ayuda</div>
      <div className='m-top'>
        <div className='m-top-half'><a href={ formative_zone === FORMATIVE_ZONE_CAT ? LINK_VALIDATIONS_CAT: LINK_VALIDATIONS_MAD}>Convalidaciones</a></div>
        <div className='m-top-half'><a href={ formative_zone === FORMATIVE_ZONE_CAT ? LINK_FCT_CAT: LINK_FCT_MAD}>Formación en centros de trabajo</a></div>
        <div className='m-top-half'><a href={ LINK_CERTIFICATES}>Solicitar certificados</a></div>
        <div className='m-top-half'><a href={ formative_zone === FORMATIVE_ZONE_CAT ? LINK_TITLE_REQUEST_CAT: LINK_TITLE_REQUEST_MAD}>Solicitar título</a></div>
      </div>
      <div className='m-top bold font-20'>Atención al alumno</div>
      <div className='m-top'>Puedes reservar una cita telefónica con un agente para resolver tus dudas.</div>
      <ButtonPrimary className='m-top btn-inverse full-width' onClick={onSubmit}>Reservar cita</ButtonPrimary>
    </div>
  )
}
