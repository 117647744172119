import React, { Fragment, h} from "preact";
import {Redirect, Route} from "react-router-dom";
import OffersPage from "./pages/offers";
import CandidatePage from "./pages/candidate";
import OfferPage from "./pages/offer";
import ProfilePage from "./pages/profile";
import LetterPage from "./pages/letter";
import CompanyProfilePage from "./pages/company";
import './jobs.scss';
import {useEffect} from 'preact/hooks';
import {connect} from 'react-redux';
import {resetJobFilter} from '../_rx/jobs/actions';

function JobsRoutes ({match,resetJobFilter}){
    useEffect(()=>()=>{ resetJobFilter()}, []);
    return <Fragment>
        <Route path={`${match.url}`} exact><Redirect to={`${match.url}/offers`}/></Route>
        <Route path={`${match.url}/offers/:page?`} component={OffersPage} exact/>
        <Route path={`${match.url}/offer/:id`} component={OfferPage}/>
        <Route path={`${match.url}/candidate`} component={CandidatePage} exact/>
        <Route path={`${match.url}/company/:id`} component={CompanyProfilePage} exact/>
        <Route path={`${match.url}/company/:id/offers/:page?`} component={CompanyProfilePage}/>
        <Route path={`${match.url}/candidate/profile`} component={ProfilePage}/>
        <Route path={`${match.url}/candidate/letter/:id`} component={LetterPage}/>
        <Route path={`${match.url}/applications/:page?`}  component={OffersPage} exact/>
    </Fragment>
}

JobsRoutes = connect(
  ({}) => ({}),
  {resetJobFilter})(JobsRoutes);

let Jobs = ({match, ...props}) => <JobsRoutes match={match} {...props}/>

export default Jobs;
