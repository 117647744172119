
import {FixtureApi} from '../_api/ilerna-StudentAccount/src';
import {toKeyName} from "../_common/common.ts";

export default class FixtureService{

    constructor() {
        this._api = new FixtureApi();
        this._cache = null;
    }

    async getFixture(locale){
        if(this._cache) return this._cache;

        const fixtures = await this._api.getFixture(locale);

        fixtures.nee = toKeyName(fixtures.nee);
        fixtures.via_type = toKeyName(fixtures.via_type);
        fixtures.gender_type = toKeyName(fixtures.gender_type);
        fixtures.last_course_type = toKeyName(fixtures.last_course_type);
        fixtures.course_access_type = toKeyName(fixtures.course_access_type);
        fixtures.country = toKeyName(fixtures.country);
        // fixtures.document_type = toKeyName(fixtures.document_type);

        return this._cache = fixtures;
    }

}
