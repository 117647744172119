import { STATUS_ID_VALIDATED, USER_TYPE_TRIAL } from '../constants'

export const STUDENT_TRIAL = 'STUDENT_TRIAL'
export const STUDENT_NO_VALIDATED = 'STUDENT_NO_VALIDATED'
export const STUDENT_NO_TITLE = 'STUDENT_NO_TITLE'
export const STUDENT_VALIDATED = 'STUDENT_VALIDATED'
export const STUDENT_IS_ON_SITE = 'STUDENT_IS_ON_SITE'
export const STUDENT_FINISHED = 'STUDENT_FINISHED'
export const STUDENT_NO_ENROLLS = 'STUDENT_NO_ENROLLS'
export const STUDENT_NO_SIGNED_CONDITIONS = 'STUDENT_NO_SIGNED_CONDITIONS'

// Este código pertenece a la primera gestión de roles de mi cuenta
const sendTitleStatus = (blocks) => (!blocks['1'] || blocks['1'].status.id != STATUS_ID_VALIDATED) ||
  blocks['2'].status.id != STATUS_ID_VALIDATED ||
  blocks['3'].status.id != STATUS_ID_VALIDATED ||
  //blocks['4'].status.id != STATUS_ID_VALIDATED ||
  (blocks['6'] && blocks['6'].status.id != STATUS_ID_VALIDATED)

export default function getUserRol(status) {
  const { student_meta, blocks } = status
  const blocksToCompare = (student_meta.has_academic_program_document_types) ? ['1', '2', '3', '4', '6'] : ['1', '2', '3', '4']
  const isInitialState = blocksToCompare.find(s => status.blocks[s].status.id < STATUS_ID_VALIDATED)

  if (student_meta.student_type === USER_TYPE_TRIAL) return STUDENT_TRIAL
  if (student_meta.is_on_site) return STUDENT_IS_ON_SITE
  if (!student_meta.has_signed_condition && !student_meta.is_blended) return STUDENT_NO_SIGNED_CONDITIONS
  if (isInitialState) {
    if (sendTitleStatus(blocks)) return STUDENT_NO_VALIDATED
    else return STUDENT_NO_TITLE
  } else if (!student_meta.has_coursing_enroll) return STUDENT_NO_ENROLLS
    else return STUDENT_VALIDATED
}
