import {Fragment, h} from "preact";
import {t} from "../../_i18n";

export const EsRequirements = () =>
<Fragment>
    <h2>REQUISITOS TÉCNICOS PARA CURSAR LA FORMACIÓN ONLINE</h2>
    <p className="section-explanation">
        Para poder desarrollar correctamente tu formación en ILERNA Online será obligatorio que tu ordenador cumpla los
        siguientes requisitos técnicos:
    </p>
    <div className="doted-list">
        <h2>Ordenadores compatibles</h2>
        <ul>
            <li>Ordenador con una antigüedad inferior a 5 años.</li>
            <li>1GB de RAM.</li>
            <li>Procesador de 2GHz.</li>
        </ul>

        <h2>Sistema operativo</h2>
        <ul>
            <li>Windows 7 o más actual.</li>
            <li>MAC OS X 10.6 o más actual.</li>
        </ul>

        <h2>Navegadores compatibles</h2>
        <ul>
            <li>Chrome 68 y 69.</li>
            <li>Firefox 61 y 62 (no admite algunas funciones del Campus Virtual).</li>
            <li>Internet Explorer 11 (sólo para Windows).</li>
            <li>Edge 41 y 42 (sólo para Windows).</li>
            <li>Safari 11 y 12 (sólo para Windows).</li>
        </ul>

        <h2>Periféricos de entrada</h2>
        <ul>
            <li>Webcam y micrófono.</li>
        </ul>
    </div>
</Fragment>
