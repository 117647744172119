/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AdminCompany from './AdminCompany';

/**
 * The InlineResponse200 model module.
 * @module model/InlineResponse200
 * @version v1
 */
class InlineResponse200 {
    /**
     * Constructs a new <code>InlineResponse200</code>.
     * @alias module:model/InlineResponse200
     */
    constructor() { 
        
        InlineResponse200.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>InlineResponse200</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/InlineResponse200} obj Optional instance to populate.
     * @return {module:model/InlineResponse200} The populated <code>InlineResponse200</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InlineResponse200();

            if (data.hasOwnProperty('current_page')) {
                obj['current_page'] = ApiClient.convertToType(data['current_page'], 'Number');
            }
            if (data.hasOwnProperty('data')) {
                obj['data'] = ApiClient.convertToType(data['data'], [AdminCompany]);
            }
            if (data.hasOwnProperty('first_page_url')) {
                obj['first_page_url'] = ApiClient.convertToType(data['first_page_url'], 'String');
            }
            if (data.hasOwnProperty('from')) {
                obj['from'] = ApiClient.convertToType(data['from'], 'Number');
            }
            if (data.hasOwnProperty('last_page')) {
                obj['last_page'] = ApiClient.convertToType(data['last_page'], 'Number');
            }
            if (data.hasOwnProperty('last_page_url')) {
                obj['last_page_url'] = ApiClient.convertToType(data['last_page_url'], 'String');
            }
            if (data.hasOwnProperty('next_page_url')) {
                obj['next_page_url'] = ApiClient.convertToType(data['next_page_url'], 'String');
            }
            if (data.hasOwnProperty('path')) {
                obj['path'] = ApiClient.convertToType(data['path'], 'String');
            }
            if (data.hasOwnProperty('per_page')) {
                obj['per_page'] = ApiClient.convertToType(data['per_page'], 'Number');
            }
            if (data.hasOwnProperty('prev_page_url')) {
                obj['prev_page_url'] = ApiClient.convertToType(data['prev_page_url'], 'String');
            }
            if (data.hasOwnProperty('to')) {
                obj['to'] = ApiClient.convertToType(data['to'], 'Number');
            }
            if (data.hasOwnProperty('total')) {
                obj['total'] = ApiClient.convertToType(data['total'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} current_page
 */
InlineResponse200.prototype['current_page'] = undefined;

/**
 * @member {Array.<module:model/AdminCompany>} data
 */
InlineResponse200.prototype['data'] = undefined;

/**
 * @member {String} first_page_url
 */
InlineResponse200.prototype['first_page_url'] = undefined;

/**
 * @member {Number} from
 */
InlineResponse200.prototype['from'] = undefined;

/**
 * @member {Number} last_page
 */
InlineResponse200.prototype['last_page'] = undefined;

/**
 * @member {String} last_page_url
 */
InlineResponse200.prototype['last_page_url'] = undefined;

/**
 * @member {String} next_page_url
 */
InlineResponse200.prototype['next_page_url'] = undefined;

/**
 * @member {String} path
 */
InlineResponse200.prototype['path'] = undefined;

/**
 * @member {Number} per_page
 */
InlineResponse200.prototype['per_page'] = undefined;

/**
 * @member {String} prev_page_url
 */
InlineResponse200.prototype['prev_page_url'] = undefined;

/**
 * @member {Number} to
 */
InlineResponse200.prototype['to'] = undefined;

/**
 * @member {Number} total
 */
InlineResponse200.prototype['total'] = undefined;






export default InlineResponse200;

