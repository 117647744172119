/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import InlineResponse2007 from '../model/InlineResponse2007';
import NoticeCount from '../model/NoticeCount';
import UserResponse from '../model/UserResponse';
import UserResponseAttachment from '../model/UserResponseAttachment';

/**
* User service.
* @module api/UserApi
* @version 1.0
*/
export default class UserApi {

    /**
    * Constructs a new UserApi. 
    * @alias module:api/UserApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Return total notices to user and unread
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/NoticeCount} and HTTP response
     */
    appHttpControllersUserControllerCountNoticesWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = NoticeCount;
      return this.apiClient.callApi(
        '/api/v1/user/countNotices', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Return total notices to user and unread
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/NoticeCount}
     */
    appHttpControllersUserControllerCountNotices() {
      return this.appHttpControllersUserControllerCountNoticesWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Return a download with the attachment with uuid
     * @param {String} uuid uuid of the attachment
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
     */
    appHttpControllersUserControllerDownloadAttachmentWithHttpInfo(uuid) {
      let postBody = null;
      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling appHttpControllersUserControllerDownloadAttachment");
      }

      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/octet-stream'];
      let returnType = File;
      return this.apiClient.callApi(
        '/api/v1/user/downloadAttachment/{uuid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Return a download with the attachment with uuid
     * @param {String} uuid uuid of the attachment
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
     */
    appHttpControllersUserControllerDownloadAttachment(uuid) {
      return this.appHttpControllersUserControllerDownloadAttachmentWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List all notices of the user
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page Page number
     * @param {Number} opts.limit Number of elements per page
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2007} and HTTP response
     */
    appHttpControllersUserControllerGetAllNoticesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'limit': opts['limit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2007;
      return this.apiClient.callApi(
        '/api/v1/user/getAllNotices', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List all notices of the user
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page Page number
     * @param {Number} opts.limit Number of elements per page
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2007}
     */
    appHttpControllersUserControllerGetAllNotices(opts) {
      return this.appHttpControllersUserControllerGetAllNoticesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Move old notices on email change
     * @param {String} from from or old email address
     * @param {String} to to or new email address
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    appHttpControllersUserControllerMoveMessagesWithHttpInfo(from, to) {
      let postBody = null;
      // verify the required parameter 'from' is set
      if (from === undefined || from === null) {
        throw new Error("Missing the required parameter 'from' when calling appHttpControllersUserControllerMoveMessages");
      }
      // verify the required parameter 'to' is set
      if (to === undefined || to === null) {
        throw new Error("Missing the required parameter 'to' when calling appHttpControllersUserControllerMoveMessages");
      }

      let pathParams = {
        'from': from,
        'to': to
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/user/moveNotices/{from}/to/{to}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Move old notices on email change
     * @param {String} from from or old email address
     * @param {String} to to or new email address
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    appHttpControllersUserControllerMoveMessages(from, to) {
      return this.appHttpControllersUserControllerMoveMessagesWithHttpInfo(from, to)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Return notice content and mark as read
     * @param {String} uuid uuid of the send
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserResponse} and HTTP response
     */
    appHttpControllersUserControllerReadNoticeWithHttpInfo(uuid) {
      let postBody = null;
      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling appHttpControllersUserControllerReadNotice");
      }

      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UserResponse;
      return this.apiClient.callApi(
        '/api/v1/user/readNotice/{uuid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Return notice content and mark as read
     * @param {String} uuid uuid of the send
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserResponse}
     */
    appHttpControllersUserControllerReadNotice(uuid) {
      return this.appHttpControllersUserControllerReadNoticeWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get a list of the attachments of a send
     * @param {String} uuid uuid of the send
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/UserResponseAttachment>} and HTTP response
     */
    appHttpControllersUserControllerReadNoticeAttachmentsWithHttpInfo(uuid) {
      let postBody = null;
      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling appHttpControllersUserControllerReadNoticeAttachments");
      }

      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [UserResponseAttachment];
      return this.apiClient.callApi(
        '/api/v1/user/getNoticeAttachments/{uuid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get a list of the attachments of a send
     * @param {String} uuid uuid of the send
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/UserResponseAttachment>}
     */
    appHttpControllersUserControllerReadNoticeAttachments(uuid) {
      return this.appHttpControllersUserControllerReadNoticeAttachmentsWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
