import React, { Fragment, h } from "preact";
import {connect} from "react-redux";
import {Card} from "../../_common/layout";
import AlertBox from "../../_common/components/AlertBox/AlertBox";
import {ButtonPrimary} from "../../_common/components/buttons/buttons";
import {t} from "../../_i18n";
import {Link} from "react-router-dom";
import {pushGiftStatus} from "../../_rx/gift/actions";
import {useState} from "preact/compat";


function GiftPending ({studentId, prestaAddress, pushGiftStatus}) {
    const {address, cp, state, city} = prestaAddress;
    const [ submitButton, setSubmitButton ] = useState(false)

    const handleSubmit = () => {
        setSubmitButton(true)
        pushGiftStatus(studentId)
            .catch(() => setSubmitButton(false))
    }

    return (
        <Card className='m-top sm-no-m-top p-bottom'>
            <div>Desde ILERNA Online nos gusta premiar el esfuerzo de nuestr@s alumn@s ¡Por eso, queremos hacerte llegar un regalo!</div>
            <div className='m-top font-black'>👉 Antes de solicitarlo, revisa la dirección dónde quieres recibirlo.</div>
            <div className='m-x2-top font-medium'>Dirección de envío:</div>
            <AlertBox type='info' className='d-flex sm-flex-bottom flex-align-start full-width flex-space-between m-top-half flex-column sm-no-reverse'>
                <div className='d-flex flex-column'>
                    <div>{address}</div>
                    <div>{`${cp} ${city} - ${state}`}</div>
                </div>
                <Link to={{pathname: `/address`, query: {urlToBack: '/gift'}}} className='t-upper font-14 txt-color-primary t-underline font-medium'>Actualizar dirección</Link>
            </AlertBox>
            <div className='m-x2-top m-x2-bottom'>
                <ButtonPrimary onClick={handleSubmit} disabled={submitButton}>Solicitar Regalo</ButtonPrimary>
            </div>
            <hr className='separator-xs'/>
            <div className='t-upper font-14 font-medium font-color-002129'>Condiciones de la solicitud</div>
            <ul className='font-14 m-l-half'>
                <li>Si no quieres el regalo no es necesario que hagas nada.</li>
                <li>Tienes hasta el 29 de junio, inclusive, para solicitar el regalo.</li>
                <li>Si aceptas nuestro regalo, ILERNA tratará la información que nos facilite con el único fin de entregarte un regalo por ser nuestro alumno. Los datos proporcionados se conservarán exclusivamente durante los trámites de envío del regalo. Los datos no se cederán a terceros, salvo a las empresas encargadas de la logística de entrega. Tienes derecho a solicitar el acceso, rectificación, supresión, oposición, limitación y portabilidad de sus datos personales, dirigiéndote a online@ilerna.com. En caso de divergencias, puede presentar una reclamación ante la Agencia de Protección de Datos (www.agpd.es).</li>
            </ul>

        </Card>
    )
}


GiftPending = connect(
    ({auth: {loggedUser}, status: {student_meta: {presta_address}}}) =>
    ({studentId: loggedUser.id, prestaAddress: presta_address}),
    {pushGiftStatus})(GiftPending);


export default ({}) => <GiftPending />

