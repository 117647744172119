import React, {Component, Fragment, h} from "preact";
import NoticeModule from "../../_common/components/noticeModule/noticeModule";
import {t} from "../../_i18n";
import { ShowGrade, ShowGradeCae, ShowTerms } from "./moduleModalDetail/gradeCalculation";
import {connect, useSelector} from "react-redux";
import {fetchFormativeActionsChildren, fetchSubjectOffers} from "../../_rx/expediente/actions";
import {EDUCATION_LAW_LOE, EDUCATION_LAW_LOGSE} from "../expedientConstants";
import ButtonReview from "./buttonReview";
import {DateTime} from "luxon";
import AlertBox from "../../_common/components/AlertBox/AlertBox";
import {useEffect, useState} from "preact/hooks";

function RecursiveModules({modules, module, onClick, title, subModule, educationLaw, moduleParent, formativeZone}){
    const wrapperTitle = educationLaw === EDUCATION_LAW_LOE ? t('unidades formativas') : t('créditos')
    const [recognitionTime, setRecognitionTime] = useState('')
    const { lang } = useSelector(state => state);

    useEffect(() => {
        if (moduleParent.recognized_new) {
            const timeLimitDate = DateTime.fromFormat(module.recognized_new.timelimit, 'yyyy-MM-dd hh:mm:ss')
            const locale = lang === 'fr' && 'fr-FR' || lang === 'it' && 'it-IT' || 'es-ES'
            const dateString = timeLimitDate.setLocale(locale).toLocaleString({month: 'long', year: 'numeric'})
            setRecognitionTime(dateString)
        } else {
            setRecognitionTime('')
        }
    }, [moduleParent]);

    const getRecognitionText = (module) => {
        if (!module.recognized_new || module.recognized_new.status === 0)
            return ''

        const statusKey = module.recognized_new.status === 2 ? 'recognition_denied' : 'recognition_requested'
        const statusKeyPrefix = module.recognized_new.type === 0 ? 'partial_' : ''

        return `<span class='font-bold'>${t(statusKeyPrefix + statusKey)} </span>`
    }

    const getRecognitionStatusKey = (recognitionObject) => {
        let key = ''
        let keyPrefix = ''

        if ( recognitionObject.status === 2 ) {
            key = 'recognition_denied_details_title'
            keyPrefix = module.recognized_new.type === 0 ? 'partial_' : ''

        } else if ( recognitionObject.status === 1 && recognitionObject.entity === 0 ) {
            key = 'recognition_requested_details_title'
            keyPrefix = module.recognized_new.type === 0 ? 'partial_' : ''
        } else {
            key = 'recognition_management_started_details_title'
            keyPrefix = module.recognized_new.type === 0 ? 'partial_' : ''
        }

        return keyPrefix + key;
    }

    const getRecognitionEntityKey = (recognitionObject) => {
        switch (recognitionObject.entity) {
            case 1:
                return 'recognition_entity_center'
            case 2:
                return 'recognition_entity_generalitat'
            case 3:
                return 'recognition_entity_consejeria_mad'
            case 4:
                return 'recognition_entity_ministerio'
            default:
                return ''
        }
    }

    const getRecognitionDeniedKey = (recognitionObject) => {
        const key = 'recognition_denied_details'
        let keyPrefix = recognitionObject.type === 0 ? 'partial_' : ''
        let keySuffix = '_' + formativeZone.toLowerCase();

        return keyPrefix + key + keySuffix
    }

    const getMustCourseKey = (recognitionObject) => {
        let key = 'must_course'
        let keyPrefix = ''
        let keySuffix = '_' + formativeZone.toLowerCase();

        if ( recognitionObject.status === 1 && recognitionObject.entity === 0 ) {
            keyPrefix = 'recognition_requested_'
        } else if ( recognitionObject.status === 1 && recognitionObject.entity !== 0 ) {
            keyPrefix = 'recognition_management_start_'
        }

        return keyPrefix + key + keySuffix
    }

    const renderModule = (submoduleItem) => {
        let enableClick = true

        if(!submoduleItem.has_children)
            enableClick = !!(submoduleItem.is_enrolled)

        const teachingHours = submoduleItem.teaching_hours ? `- ${submoduleItem.teaching_hours} ${t('horas')}` : '';

        return <NoticeModule
            subTitle={`${submoduleItem.abbr} ${teachingHours}`}
            title={submoduleItem.title}
            grade={submoduleItem.grade}
            approved={submoduleItem.approved}
            onClick={() => onClick(submoduleItem)}
            isEnrolled={submoduleItem.is_enrolled}
            isEnabled={enableClick}
            enrolledStatus={submoduleItem.enroll_status}
            textInfo={getRecognitionText(submoduleItem)}
            //gradeTooltip={submoduleItem.recognized && t('Convalidada')}
        />
    }

    return (
        <Fragment>
            <div className='font-14'>{subModule}</div>
            <h2>
                {title}
            </h2>
            <div className='font-14'>{`${moduleParent.teaching_hours ?  `${moduleParent.teaching_hours} horas` : ''}`}</div>
            <hr className="separator-xxs"/>
            { moduleParent.recognized_new && moduleParent.recognized_new.status !== 0 &&
                <AlertBox type='info'>
                    <div>
                        <p className='font-bold'>{t(getRecognitionStatusKey(moduleParent.recognized_new))}</p>
                        <ul>
                            <li>Inicio de tramitación: {recognitionTime}</li>
                            {moduleParent.recognized_new.entity !== 0 &&
                                <li>Organismo responsable: <strong>{t(getRecognitionEntityKey(moduleParent.recognized_new))}</strong></li>
                            }
                        </ul>
                        {moduleParent.recognized_new.status === 1 ?
                            <Fragment>
                                <p dangerouslySetInnerHTML={{__html: t('recognition_max_management_time_limit')}} />
                                {moduleParent.recognized_new.type === 0 &&
                                    <p dangerouslySetInnerHTML={{__html: t(getMustCourseKey(moduleParent.recognized_new))}} />
                                }
                            </Fragment>
                            :
                            <p dangerouslySetInnerHTML={{__html: t(getRecognitionDeniedKey(moduleParent.recognized_new))}} />
                        }
                    </div>
                </AlertBox>
            }
            <div className='expedient-grades-header'>
                <div className='expedient-grades-header__title font-14 t-upper'>{wrapperTitle}</div>
                <div className='expedient-grades-header__grad font-14 t-upper'>{t('nota')}</div>
            </div>
            <div>
                {
                    modules && modules.map((submoduleItem) => renderModule(submoduleItem))
                }
            </div>
            { module.hidden_grade &&
                <AlertBox type='info' className='m-top'>
                    {module.is_training_work_center && t('fct_grade_missing_project_grade_tooltip')}
                    {module.is_project && t('project_grade_missing_fct_grade_tooltip')}
                </AlertBox>
            }
            {(educationLaw === EDUCATION_LAW_LOE) &&
                <ButtonReview allowRevision={moduleParent.allow_revision && moduleParent.is_enrollable}
                              revisionSubmitted={moduleParent.revision_submitted}/>}
            <ShowTerms educationLaw={educationLaw} formative_zone={formativeZone}/>
        </Fragment>
    )


}

RecursiveModules = connect(
    ({expedient: {educationLaw}}) => ({educationLaw}),
    {fetchFormativeActionsChildren, fetchSubjectOffers})(RecursiveModules);

let RecursiveModulesContainer = ({...props}) => <RecursiveModules {...props}/>


export default RecursiveModulesContainer;
