/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The InlineObject6 model module.
 * @module model/InlineObject6
 * @version 1.0
 */
class InlineObject6 {
    /**
     * Constructs a new <code>InlineObject6</code>.
     * @alias module:model/InlineObject6
     * @param name {String} name
     */
    constructor(name) { 
        
        InlineObject6.initialize(this, name);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, name) { 
        obj['name'] = name;
    }

    /**
     * Constructs a <code>InlineObject6</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/InlineObject6} obj Optional instance to populate.
     * @return {module:model/InlineObject6} The populated <code>InlineObject6</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InlineObject6();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('academic_section_id')) {
                obj['academic_section_id'] = ApiClient.convertToType(data['academic_section_id'], 'String');
            }
            if (data.hasOwnProperty('academic_program_id')) {
                obj['academic_program_id'] = ApiClient.convertToType(data['academic_program_id'], 'String');
            }
            if (data.hasOwnProperty('formative_action_id')) {
                obj['formative_action_id'] = ApiClient.convertToType(data['formative_action_id'], 'String');
            }
            if (data.hasOwnProperty('shop_id')) {
                obj['shop_id'] = ApiClient.convertToType(data['shop_id'], 'String');
            }
            if (data.hasOwnProperty('is_new_student')) {
                obj['is_new_student'] = ApiClient.convertToType(data['is_new_student'], 'Boolean');
            }
            if (data.hasOwnProperty('is_semi_new_student')) {
                obj['is_semi_new_student'] = ApiClient.convertToType(data['is_semi_new_student'], 'Boolean');
            }
            if (data.hasOwnProperty('is_premium')) {
                obj['is_premium'] = ApiClient.convertToType(data['is_premium'], 'Boolean');
            }
            if (data.hasOwnProperty('family_id')) {
                obj['family_id'] = ApiClient.convertToType(data['family_id'], 'String');
            }
            if (data.hasOwnProperty('has_presential_inscribed')) {
                obj['has_presential_inscribed'] = ApiClient.convertToType(data['has_presential_inscribed'], 'Boolean');
            }
            if (data.hasOwnProperty('has_presential_assisted')) {
                obj['has_presential_assisted'] = ApiClient.convertToType(data['has_presential_assisted'], 'Boolean');
            }
            if (data.hasOwnProperty('exam_inscribed_status')) {
                obj['exam_inscribed_status'] = ApiClient.convertToType(data['exam_inscribed_status'], 'Boolean');
            }
            if (data.hasOwnProperty('has_extraordinary_pending')) {
                obj['has_extraordinary_pending'] = ApiClient.convertToType(data['has_extraordinary_pending'], 'Boolean');
            }
            if (data.hasOwnProperty('has_convas')) {
                obj['has_convas'] = ApiClient.convertToType(data['has_convas'], 'Boolean');
            }
            if (data.hasOwnProperty('has_fct')) {
                obj['has_fct'] = ApiClient.convertToType(data['has_fct'], 'Boolean');
            }
            if (data.hasOwnProperty('is_old_student')) {
                obj['is_old_student'] = ApiClient.convertToType(data['is_old_student'], 'Boolean');
            }
            if (data.hasOwnProperty('has_presential')) {
                obj['has_presential'] = ApiClient.convertToType(data['has_presential'], 'Boolean');
            }
            if (data.hasOwnProperty('province')) {
                obj['province'] = ApiClient.convertToType(data['province'], 'Number');
            }
            if (data.hasOwnProperty('has_finished')) {
                obj['has_finished'] = ApiClient.convertToType(data['has_finished'], 'Boolean');
            }
            if (data.hasOwnProperty('student_email')) {
                obj['student_email'] = ApiClient.convertToType(data['student_email'], 'String');
            }
        }
        return obj;
    }


}

/**
 * name
 * @member {String} name
 */
InlineObject6.prototype['name'] = undefined;

/**
 * academic section id
 * @member {String} academic_section_id
 */
InlineObject6.prototype['academic_section_id'] = undefined;

/**
 * academic program id
 * @member {String} academic_program_id
 */
InlineObject6.prototype['academic_program_id'] = undefined;

/**
 * formative action id
 * @member {String} formative_action_id
 */
InlineObject6.prototype['formative_action_id'] = undefined;

/**
 * shop id
 * @member {String} shop_id
 */
InlineObject6.prototype['shop_id'] = undefined;

/**
 * is new student
 * @member {Boolean} is_new_student
 */
InlineObject6.prototype['is_new_student'] = undefined;

/**
 * is semi new student
 * @member {Boolean} is_semi_new_student
 */
InlineObject6.prototype['is_semi_new_student'] = undefined;

/**
 * is premium
 * @member {Boolean} is_premium
 */
InlineObject6.prototype['is_premium'] = undefined;

/**
 * family id
 * @member {String} family_id
 */
InlineObject6.prototype['family_id'] = undefined;

/**
 * has presential inscribed
 * @member {Boolean} has_presential_inscribed
 */
InlineObject6.prototype['has_presential_inscribed'] = undefined;

/**
 * has presential assisted
 * @member {Boolean} has_presential_assisted
 */
InlineObject6.prototype['has_presential_assisted'] = undefined;

/**
 * exam incribed status id
 * @member {Boolean} exam_inscribed_status
 */
InlineObject6.prototype['exam_inscribed_status'] = undefined;

/**
 * has extraordinary pending
 * @member {Boolean} has_extraordinary_pending
 */
InlineObject6.prototype['has_extraordinary_pending'] = undefined;

/**
 * has extraordinary pending
 * @member {Boolean} has_convas
 */
InlineObject6.prototype['has_convas'] = undefined;

/**
 * has extraordinary pending
 * @member {Boolean} has_fct
 */
InlineObject6.prototype['has_fct'] = undefined;

/**
 * is old student
 * @member {Boolean} is_old_student
 */
InlineObject6.prototype['is_old_student'] = undefined;

/**
 * has presential
 * @member {Boolean} has_presential
 */
InlineObject6.prototype['has_presential'] = undefined;

/**
 * province
 * @member {Number} province
 */
InlineObject6.prototype['province'] = undefined;

/**
 * has finished
 * @member {Boolean} has_finished
 */
InlineObject6.prototype['has_finished'] = undefined;

/**
 * student_email
 * @member {String} student_email
 */
InlineObject6.prototype['student_email'] = undefined;






export default InlineObject6;

