import {Component, Fragment, h} from "preact";

import {CF, Card} from "../../_common/layout";
import {t} from "../../_i18n";
import {Link} from "react-router-dom";
import ServicePolicy from "./servicePolicy";


export const Address = ({direccion_envio, address_ok})=><CF className="max-width">

    <Card>
        <p innerHTML={t('Antes de abonar las tasas,<strong> debes verificar la dirección de envío</strong> y actualizarla, si es necesario:')} className='font-16' />
        <div className='background-info card p-all d-flex flex-column sm-no-flex-column'>
            <div className='sm-col-65 col-100'>
                {direccion_envio && direccion_envio.address_name} <br/>
                {direccion_envio && direccion_envio.address_zip_code} &nbsp;
                {direccion_envio && direccion_envio.address_city_name}
            </div>
            <div className='sm-col-35 col-100 d-flex flex-bottom'>
                <Link to={'/address/?back=' + location.pathname } className='t-upper'>{t('Actualizar dirección')}</Link>
            </div>
        </div>

        <button className="btn btn-primary m-top m-bottom sm-no-full-width full-width"  type="button" onClick={address_ok}>{t('Aceptar y continuar')}</button>
    </Card>
    <ServicePolicy />
</CF>;




