import {Component, h, Fragment} from "preact";
import {useEffect, useState} from "preact/hooks";


const Dropdown = (props:any) => {
    const [ shown, setShown ] = useState(true);

    useEffect(() => {
        function docClick(event:any) {
            if (event.path && event.path.filter((ele:any) => ele.id === 'label-menu-left').length > 0)
                return;
            if (!shown) {
                setShown(true)
            }
        }
        document.addEventListener("click", docClick);
        return () => {
        document.removeEventListener("click", docClick);
        };
    }, [shown]);

    return <section className="dropdown">
        <div className="dropdown-element dropdown-area">
            {(props.justIcon)
              ? <div  onClick={() => setShown(!shown)} id="dropdown"
                      className={`dropdown-selector dropdown-selector-icon ${props.isGrey ? 'dropdown-selector-icon__grey' : 'dropdown-selector-icon__white'}`}>
                    <i className={props.icon} />
                </div>
              : <div  onClick={() => setShown(!shown)} id="dropdown" className='dropdown-selector'>
                    {(props.text) && <span className={`m-r ${props.textClassName}`}>{props.text}</span>}
                    <i className={props.icon} />
                </div>}

            {!shown && <article className={`menu-handler dropdown-menu dropdown-menu__${props.position}`}>
                <div className='p-top p-bottom'>
                    {props.children}
                </div>
            </article>}
        </div>
    </section>


};

export default Dropdown;
