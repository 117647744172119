import { h } from "preact";
import ShippingAddressForm from "./form-page";
import {AppPage} from "../../_common/components/app-page";
import {t} from '../../_i18n';


let IndexPage = ({history, ...props}) =>
    <AppPage
        name="direccion-envio"
        className="max-width"
    >
        <ShippingAddressForm history={history} {...props}/>
    </AppPage>
;

export default IndexPage;
