import React, { Fragment, Component, h} from "preact";
import {useHistory} from "react-router-dom";
import {t} from "../../_i18n";
import AlertBox from "../../_common/components/AlertBox/AlertBox";

export default function ButtonReview({allowRevision, revisionSubmitted}){
    const history = useHistory()

    const handleClick = () => {
        const ids = history.location.pathname.split('/')[2]
        const newUrl = `/expediente/${ids}/review`;
        history.push(newUrl)
    }

    return (
        <Fragment>
            {revisionSubmitted ?
                <AlertBox type='info' className='m-top'>
                    {<span dangerouslySetInnerHTML={{__html: t('Review_submitted_text')}}/>}
                </AlertBox>
            :
            allowRevision &&
                <button className='btn btn-ghost m-top t-upper' onClick={handleClick}>
                    {t('Solicitar revisión')}
                </button>
            }
        </Fragment>
    )
}
