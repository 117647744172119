/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CandidateLink model module.
 * @module model/CandidateLink
 * @version v1
 */
class CandidateLink {
    /**
     * Constructs a new <code>CandidateLink</code>.
     * El curriculum admite incluir dos enlaces a perfiles de redes sociales (Linkedin y InfoJobs) [P_A_23]
     * @alias module:model/CandidateLink
     */
    constructor() { 
        
        CandidateLink.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CandidateLink</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CandidateLink} obj Optional instance to populate.
     * @return {module:model/CandidateLink} The populated <code>CandidateLink</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CandidateLink();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
CandidateLink.prototype['id'] = undefined;

/**
 * infojobs, linkedin
 * @member {String} code
 */
CandidateLink.prototype['code'] = undefined;

/**
 * @member {String} url
 */
CandidateLink.prototype['url'] = undefined;






export default CandidateLink;

