import { h } from 'preact'
import './help-section.scss'
import { ButtonPrimary } from '../../_common/components/buttons/buttons'
import { useState } from 'preact/hooks'
import ModalHelp from './ModalHelp/ModalHelp'
import {
  FORMATIVE_ZONE_CAT,
  LINK_CERTIFICATES, LINK_ENROLL_CAT, LINK_ENROLL_MAD,
  LINK_FCT, LINK_FCT_CAT, LINK_FCT_MAD,
  LINK_TITLE_REQUEST, LINK_TITLE_REQUEST_CAT, LINK_TITLE_REQUEST_MAD, LINK_VALIDATIONS_CAT, LINK_VALIDATIONS_MAD,
  LINK_VALIDATIONS_REQUESTED
} from './ModalHelp/constants'
import { useSelector } from 'react-redux'

export default function HelpSection () {
  const { has_fct, has_convas, formative_zone } = useSelector(state => state.status.student_meta)
  const [openGuides, setOpenGuides] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  return (
    <div className='help-section'>
      <div className='sm-d-none'>
        <ButtonPrimary className='m-top btn-inverse full-width' onClick={() => setOpenModal(true)}>Ayuda y soporte</ButtonPrimary>
      </div>
      <div className='d-none sm-d-block'>
        <div className='help-section-container'>
          <div className='font-24'>Ayuda y soporte</div>
          <div className='font-20 m-top guides bold' onClick={() => setOpenGuides(!openGuides)}>
            <span className='t-underline'>Guías de ayuda</span>
            <span><i className={`fal fa-chevron-${ openGuides ? 'up' : 'down' }`}/></span>
          </div>
          { openGuides && (
            <div>
              <div className='m-top-half'><a href={ formative_zone === FORMATIVE_ZONE_CAT ? LINK_ENROLL_CAT: LINK_ENROLL_MAD}>Formalización de matrícula</a></div>
              <div className='m-top-half'><a href={ formative_zone === FORMATIVE_ZONE_CAT ? LINK_VALIDATIONS_CAT: LINK_VALIDATIONS_MAD}>Convalidaciones</a></div>
              <div className='m-top-half'><a href={ formative_zone === FORMATIVE_ZONE_CAT ? LINK_FCT_CAT: LINK_FCT_MAD}>Formación en centros de trabajo</a></div>
              <div className='m-top-half'><a href={LINK_CERTIFICATES}>Solicitar certificados</a></div>
              <div className='m-top-half'><a href={formative_zone === FORMATIVE_ZONE_CAT ? LINK_TITLE_REQUEST_CAT: LINK_TITLE_REQUEST_MAD}>Solicitar título</a></div>
            </div>
          )}
          <hr className="separator-xxs-gray" />
          <div className='font-20 bold'>Atención al alumno</div>
          <div className='m-top-half'>Puedes reservar una cita telefónica con un agente para resolver tus dudas.</div>
          <ButtonPrimary className='m-top btn-inverse full-width' onClick={() => setOpenModal(true)}>Reservar cita</ButtonPrimary>
        </div>
      </div>
      {openModal && <ModalHelp visible={openModal} onClose={() => setOpenModal(false)} />}
    </div>
  )
}
