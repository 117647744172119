/**
 * New Expedient
 * Expedient for Student Account
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ArrayOfSubjectOfferItemsWithParent from '../model/ArrayOfSubjectOfferItemsWithParent';
import Error from '../model/Error';
import SubjectOffer from '../model/SubjectOffer';

/**
* SubjectOffers service.
* @module api/SubjectOffersApi
* @version 1.0
*/
export default class SubjectOffersApi {

    /**
    * Constructs a new SubjectOffersApi. 
    * @alias module:api/SubjectOffersApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get Student SubjectOffer expedient data
     * @param {Number} id 
     * @param {Number} formativeActionId 
     * @param {Number} subjectOfferId 
     * @param {Boolean} hiddenGrade 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubjectOffer} and HTTP response
     */
    studentExpedientControllerGetSubjectOfferExpedientWithHttpInfo(id, formativeActionId, subjectOfferId, hiddenGrade) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling studentExpedientControllerGetSubjectOfferExpedient");
      }
      // verify the required parameter 'formativeActionId' is set
      if (formativeActionId === undefined || formativeActionId === null) {
        throw new Error("Missing the required parameter 'formativeActionId' when calling studentExpedientControllerGetSubjectOfferExpedient");
      }
      // verify the required parameter 'subjectOfferId' is set
      if (subjectOfferId === undefined || subjectOfferId === null) {
        throw new Error("Missing the required parameter 'subjectOfferId' when calling studentExpedientControllerGetSubjectOfferExpedient");
      }
      // verify the required parameter 'hiddenGrade' is set
      if (hiddenGrade === undefined || hiddenGrade === null) {
        throw new Error("Missing the required parameter 'hiddenGrade' when calling studentExpedientControllerGetSubjectOfferExpedient");
      }

      let pathParams = {
        'id': id,
        'formativeActionId': formativeActionId,
        'subjectOfferId': subjectOfferId,
        'hiddenGrade': hiddenGrade
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['BearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = SubjectOffer;
      return this.apiClient.callApi(
        '/account/students/{id}/expedient/formative-actions/{formativeActionId}/subject-offers/{subjectOfferId}/hidden-grade/{hiddenGrade}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get Student SubjectOffer expedient data
     * @param {Number} id 
     * @param {Number} formativeActionId 
     * @param {Number} subjectOfferId 
     * @param {Boolean} hiddenGrade 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubjectOffer}
     */
    studentExpedientControllerGetSubjectOfferExpedient(id, formativeActionId, subjectOfferId, hiddenGrade) {
      return this.studentExpedientControllerGetSubjectOfferExpedientWithHttpInfo(id, formativeActionId, subjectOfferId, hiddenGrade)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get Student FormativeAction SubjectOffers expedient data
     * @param {Number} id 
     * @param {Number} formativeActionId 
     * @param {Boolean} hiddenGrade 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ArrayOfSubjectOfferItemsWithParent} and HTTP response
     */
    studentExpedientControllerGetSubjectOffersFromFormativeActionWithHttpInfo(id, formativeActionId, hiddenGrade) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling studentExpedientControllerGetSubjectOffersFromFormativeAction");
      }
      // verify the required parameter 'formativeActionId' is set
      if (formativeActionId === undefined || formativeActionId === null) {
        throw new Error("Missing the required parameter 'formativeActionId' when calling studentExpedientControllerGetSubjectOffersFromFormativeAction");
      }
      // verify the required parameter 'hiddenGrade' is set
      if (hiddenGrade === undefined || hiddenGrade === null) {
        throw new Error("Missing the required parameter 'hiddenGrade' when calling studentExpedientControllerGetSubjectOffersFromFormativeAction");
      }

      let pathParams = {
        'id': id,
        'formativeActionId': formativeActionId,
        'hiddenGrade': hiddenGrade
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['BearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ArrayOfSubjectOfferItemsWithParent;
      return this.apiClient.callApi(
        '/account/students/{id}/expedient/formative-actions/{formativeActionId}/subject-offers/hidden-grade/{hiddenGrade}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get Student FormativeAction SubjectOffers expedient data
     * @param {Number} id 
     * @param {Number} formativeActionId 
     * @param {Boolean} hiddenGrade 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ArrayOfSubjectOfferItemsWithParent}
     */
    studentExpedientControllerGetSubjectOffersFromFormativeAction(id, formativeActionId, hiddenGrade) {
      return this.studentExpedientControllerGetSubjectOffersFromFormativeActionWithHttpInfo(id, formativeActionId, hiddenGrade)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
