/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CompanyOfferItem model module.
 * @module model/CompanyOfferItem
 * @version v1
 */
class CompanyOfferItem {
    /**
     * Constructs a new <code>CompanyOfferItem</code>.
     * Item ligero de lista de ofertas [P_E_01] [P_E_02] [P_E_03]
     * @alias module:model/CompanyOfferItem
     */
    constructor() { 
        
        CompanyOfferItem.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CompanyOfferItem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CompanyOfferItem} obj Optional instance to populate.
     * @return {module:model/CompanyOfferItem} The populated <code>CompanyOfferItem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CompanyOfferItem();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('modality')) {
                obj['modality'] = ApiClient.convertToType(data['modality'], 'String');
            }
            if (data.hasOwnProperty('date')) {
                obj['date'] = ApiClient.convertToType(data['date'], 'String');
            }
            if (data.hasOwnProperty('city')) {
                obj['city'] = ApiClient.convertToType(data['city'], Object);
            }
            if (data.hasOwnProperty('state')) {
                obj['state'] = ApiClient.convertToType(data['state'], 'Number');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'Number');
            }
            if (data.hasOwnProperty('presence')) {
                obj['presence'] = ApiClient.convertToType(data['presence'], 'String');
            }
            if (data.hasOwnProperty('is_remote_process')) {
                obj['is_remote_process'] = ApiClient.convertToType(data['is_remote_process'], 'Boolean');
            }
            if (data.hasOwnProperty('academic_programs')) {
                obj['academic_programs'] = ApiClient.convertToType(data['academic_programs'], ['Number']);
            }
            if (data.hasOwnProperty('counts')) {
                obj['counts'] = ApiClient.convertToType(data['counts'], [Object]);
            }
            if (data.hasOwnProperty('has_questions')) {
                obj['has_questions'] = ApiClient.convertToType(data['has_questions'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
CompanyOfferItem.prototype['id'] = undefined;

/**
 * @member {String} title
 */
CompanyOfferItem.prototype['title'] = undefined;

/**
 * normal, fct, dual
 * @member {String} modality
 */
CompanyOfferItem.prototype['modality'] = undefined;

/**
 * @member {String} date
 */
CompanyOfferItem.prototype['date'] = undefined;

/**
 * @member {Object} city
 */
CompanyOfferItem.prototype['city'] = undefined;

/**
 * Id Provincia
 * @member {Number} state
 */
CompanyOfferItem.prototype['state'] = undefined;

/**
 * Offer status
 * @member {Number} status
 */
CompanyOfferItem.prototype['status'] = undefined;

/**
 * on_site, semi, remote
 * @member {String} presence
 */
CompanyOfferItem.prototype['presence'] = undefined;

/**
 * Indica si el proceso es remoto
 * @member {Boolean} is_remote_process
 */
CompanyOfferItem.prototype['is_remote_process'] = undefined;

/**
 * lista de ids
 * @member {Array.<Number>} academic_programs
 */
CompanyOfferItem.prototype['academic_programs'] = undefined;

/**
 * count applications group by status
 * @member {Array.<Object>} counts
 */
CompanyOfferItem.prototype['counts'] = undefined;

/**
 * Indica si la oferta tiene preguntas para incribirse
 * @member {Boolean} has_questions
 */
CompanyOfferItem.prototype['has_questions'] = undefined;






export default CompanyOfferItem;

