import { h } from "preact";
import './step-styles.scss'

export default function Step ({ done , title, active }) {

  return (
    <div className='fct-step'>
      <div className={`fct-step__status ${done ? 'done' : ''} ${active ? 'active' : ''}`}>{done && <i className="far fa-check" />}</div>
      <div className='fct-step__title font-bold'>{title}</div>
    </div>
  )
}
