import {
    SET_EXAM_SELECTED,
    SET_HISTORICAL_EXAMS,
    SET_EXAMS_STATUS,
    SET_LOADING_HISTORICAL_EXAMS,
    SET_CANCEL_ENROLL,
    SET_ENROLL_PACS
} from "./constants";
import {enrollmentApi, eventDetailApi, eventsApi, studentGradesApi, evauApi} from "../../../../_services/exams-service";
import {globalCatch} from "../../../../_services/global-catch";
import {downloadFile} from '../../../../utils';
import { TOKEN_EXPEDIENT } from '../../../../constants'
import {toastService} from "../../../../_services/toast-service";
import {t} from "../../../../_i18n";
import {EVAUApi} from "../../../../_api/exams/src";

export const setExamSelected = params => ({type: SET_EXAM_SELECTED, payload: params});
export const setExamsStatus = payload => ({type: SET_EXAMS_STATUS, payload: payload});
export const setHistoricalExams = payload => ({type: SET_HISTORICAL_EXAMS, payload: payload});
export const setLoadingHistorical = payload => ({type: SET_LOADING_HISTORICAL_EXAMS, payload: payload});
export const setCancelEnroll = payload => ({type: SET_CANCEL_ENROLL, payload: payload});
export const setEnrollPacs = payload => ({type: SET_ENROLL_PACS, payload: payload});


export const getStatusExams = (studentId) => globalCatch.catch( async dispatch => {
    const upcomingExams = await(await eventsApi()).enrollExamControllerGetFormativeActions(studentId)
    dispatch(setExamsStatus(upcomingExams))
})

export const getPastExams = (studentId) => globalCatch.catch( async dispatch => {
    dispatch(setLoadingHistorical(true))
    dispatch(setHistoricalExams(null))
    const historicalExams = await(await eventsApi()).enrollExamControllerGetPastEvents(studentId)
    dispatch(setHistoricalExams(historicalExams))
    dispatch(setLoadingHistorical(false))
})

export const getNextExams = (studentId) => globalCatch.catch( async dispatch => {
    dispatch(setLoadingHistorical(true))
    dispatch(setHistoricalExams(null))
    const historicalExams = await(await eventsApi()).enrollExamControllerGetNextEvents(studentId)
    dispatch(setHistoricalExams(historicalExams))
    dispatch(setLoadingHistorical(false))
})

export const getBuildings = async ({examConvocationId, studentId, formativeActionId}) => {
    try {
        const eventDetail = await (await eventDetailApi()).enrollExamControllerExamBuildings(examConvocationId, studentId, formativeActionId)
        const buildings = (eventDetail.buildings || eventDetail).map(building => ({
            id: building.building_id,
            city: building.state,
            address: `${building.building_name ? `${building.building_name}. ` : ''}${building.address_name} ${building.city ? `(${building.state})` : ''}`
        }));

        return { exam_convocation_id: eventDetail.exam_convocation_id, buildings };
    } catch (e) {
        return []
    }
}

export const getSchedules = async ({studentId, examConvocationId, buildingId, formativeActionId}) => {
    try {
        return await (await eventDetailApi()).enrollExamControllerGetShedulesExam(studentId, examConvocationId, buildingId, formativeActionId)
    } catch (e) {
        return []
    }
}

export const enrollExam = async ({studentId, eventId, eventCalendarId, formativeActionId}) => {
    const response = {error: null, data: null}
    try{
        response.data = await (await enrollmentApi()).enrollExamControllerEnrollStudent( studentId, eventId, formativeActionId, eventCalendarId )
    }catch (e) {
        response.error = e
    }
    return response
}

export const cancelExam = async ({studentId, eventId, formativeActionId, eventCalendarId}) => {
    const response = {error: null, data: null}
    try{
        response.data = await (await enrollmentApi()).enrollExamControllerCancelEnrollmentStudent( studentId, eventId, formativeActionId, eventCalendarId )
    }catch (e) {
        response.error = e
    }
    return response
}

export const modifyEnroll = async ({studentId, eventId, formativeActionId, eventCalendarId, oldEventId}) => {
    const response = {error: null, data: null}
    try{
        response.data = await (await enrollmentApi()).enrollExamControllerUpdateEnrollmentStudent(studentId, eventId, formativeActionId, oldEventId, eventCalendarId)
    }catch (e) {
        response.error = e
    }
    return response
}

export const getExamCertificate = async (studentMail, locale = 'es', academicSection) => {
    await downloadFile({
        url: `${micuentaenv.STUDENT_ACCOUNT_API_basePath}/lti/enrollExam/students/${studentMail}/exam-certificate?academic_section=${academicSection}&locale=${locale}`,
        fileName:'Certificado_asistencia',
        TOKEN: TOKEN_EXPEDIENT
    })
}

export const getJoinedCertificate = async (studentMail, locale = 'es', academicSection) => {
    await downloadFile({
        url: `${micuentaenv.STUDENT_ACCOUNT_API_basePath}/lti/enrollExam/students/${studentMail}/joined-certificate?academic_section=${academicSection}&locale=${locale}`,
        fileName:'Justificante_inscripción',
        TOKEN: TOKEN_EXPEDIENT
    })
}

export const getEnrollPacs = (studentId, examConvocationId, formativeActionId) => globalCatch.catch( async dispatch => {
    const data = await (await studentGradesApi()).studentGradesControllerGetPacGrades(studentId, examConvocationId, formativeActionId);
    dispatch(setEnrollPacs(data))
})

export const uploadEvauDocument = async (studentId, file) => {
    const response = {error: null, data: null};

    try {
        response.data = await (await evauApi()).ebauInscriptionsControllerStore(studentId, { file: file, documentTypeId: '67' })
    } catch (e) {
        if (e?.body?.message) {
            response.error = e.body.message
            toastService.showToast({message: e.body.message, type: 'danger'})
        } else if (e?.body?.errors) {
            const errorObject = e.body.errors;
            const errorKeys = Object.keys(errorObject);
            const message = errorObject[errorKeys[0]][0];
            toastService.showToast({message: message , type: 'danger'})
        } else {
            toastService.showToast({message: t("Ha ocurrido un error"), type: 'danger'})
        }
    }

    return response
}

export const checkEvauDocumentationStatus = async (studentId) => {
    const response = {error: null, data: null};

    try {
        response.data = await (await evauApi()).ebauInscriptionsControllerGetStatus(studentId);
    } catch (e) {
        response.error = e
    }

    return response
}

const getEvauDocumentation = async (studentId) => {
    const response = {error: null, data: null};

    try {
        await fetch(`${micuentaenv.STUDENT_ACCOUNT_API_basePath}/lms/students/${studentId}/ebau-inscriptions/show`, {
            headers: {
                "accept": "application/json"
            },
            method: "GET"
        }).then(
            response => response.json()
        ).then(
            data => {
                if (data.code && data.code !== 200) {
                    response.error = data.message || 'Ha ocurrido un error'
                } else {
                    response.data = data
                }
            }
        ).catch(
            error => response.error = error
        );
    } catch (e) {
        response.error = e
    }

    return response;
}

export const getEvauDocumentationStatus = async (studentId) => {
    const evauDocumentationStatus = {documents: [], hasValidatedDocuments: false, errors: null}

    const currentDocumentationStatus = await checkEvauDocumentationStatus(studentId);

    if (currentDocumentationStatus.data) {
        evauDocumentationStatus.hasValidatedDocuments = currentDocumentationStatus.data.has_validated_ebau_inscription;

        if (evauDocumentationStatus.hasValidatedDocuments === false) {
            const currentDocumentation = await getEvauDocumentation(studentId);
            if (currentDocumentation.data) {
                evauDocumentationStatus.documents = currentDocumentation.data.documents;
            }
        }

    } else if (currentDocumentationStatus.error) {
        evauDocumentationStatus.errors = currentDocumentationStatus.error
    }

    return evauDocumentationStatus;
}
