import React, { Fragment, Component, h} from "preact";
import './InputTime-styles.scss'
import AlertBox from '../../../../_common/components/AlertBox/AlertBox';
import {t} from '../../../../_i18n';

export default function InputTime ({ date, checked, onClick, isThisScheduleSelected  }) {
  const classChecked = checked ? 'checked' : ''
  const eventDisabled = (date?.subscribed_event || date?.enrollment_full || date?.sessions_colliding) && !isThisScheduleSelected
  const classDisabled = eventDisabled ? 'disabled' : ''

  const handleClick = () => {
    if (checked) onClick(null)
    else if (!eventDisabled) onClick(date)
  }

  return (
    <div onClick={handleClick} className={`inputTimeClasses ${classChecked} ${classDisabled}`}>
      <div className={`inputTimeClasses-header font-light ${classDisabled}`}>
        <div className='inputTimeClasses-sessions'>
          Sesiones
        </div>
        <div className='inputTimeClasses-schedule'>
          Horario

        </div>
        <div className='inputTimeClasses-input'>
          <div className={`inputTimeClasses-checkbox ${classDisabled}`}>
            <input type="checkbox" checked={checked}/>
            <div onClick={handleClick} className={`${classDisabled}`}>
              <i className="fas fa-check" onClick={handleClick}/>
            </div>
          </div>
        </div>
      </div>
      { date.sessions.map((date) => <div className={`inputTimeClasses-date ${classDisabled}`}>
          <div className='inputTimeClasses-sessions'>{date.formatted_day}</div>
          <div className='inputTimeClasses-schedule'>{date.start_date_hour} - {date.end_date_hour}</div>
      </div>
      )}
      { date?.sessions_colliding && !isThisScheduleSelected &&
        <AlertBox type='info' className='inputTimeExams-alert'>{t('Estas sesiones coinciden con otras clases que tienes')}.</AlertBox>
      }
      { date?.enrollment_full && !date?.subscribed_event && !isThisScheduleSelected &&
        <AlertBox type='info' className='inputTimeExams-alert'>{t('Lo sentimos. no quedan plazas para esta sesión')}.</AlertBox>
      }
    </div>
  )
}
