import { createReducer } from "../../../_common/common";
import {SET_EDUCATION_LAW, SET_REVIEW_PARAMS} from "../actions/constants";

export const expedient = createReducer({educationLaw: null, reviewParams:{}}, {

    [SET_EDUCATION_LAW]: (state, {educationLaw})=>{
        state.educationLaw = educationLaw;
        return state
    },

    [SET_REVIEW_PARAMS]: (state, {reviewParams})=>{
        state.reviewParams = reviewParams;
        return state
    },
});