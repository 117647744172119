import {h} from 'preact'
import {AppPage} from '../_common/components/app-page'
import {C, F} from '../_common/layout'
import {t} from '../_i18n'
import './secretary-styles.scss'
import {ButtonPrimary} from '../_common/components/buttons/buttons'
import {useHistory} from 'react-router'
import {useDispatch, useSelector} from "react-redux";
import {Select} from '../_common/forms/components/components'
import {useEffect} from "preact/hooks";
import {createCertificateCart, fetchCertificateTypes} from "../_rx/certificates/actions";


export default function RequestCertificate() {
    const history = useHistory()
    const academicPrograms = useSelector(state => (state.status.student_meta.coursing_academic_programs))
    const certificates = useSelector(state => (state.certificates))
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCertificateTypes());
    }, []);

    // Bad practice. It should use state instead
    let selectedCertificate;
    let selectedCourse;
    // Bad practice. Better using implicit binding
    const handleCourseSelect = (e) => {
        selectedCourse = e.target.value
    }
    const handleCertSelect = (e) => {
        selectedCertificate = e.target.value
    }

    async function clickButton() {
        if (!selectedCourse)
            selectedCourse = academicPrograms[0].id;
        if (!selectedCertificate)
            selectedCertificate = certificates[0].id_product_attribute;
        dispatch(createCertificateCart(selectedCourse, selectedCertificate));
    }

    return certificates && (
        <AppPage title={t('Solicitar certificado')} back={history.goBack} className="max-width-980">
            <F className="max-width-980">
                <C>
                    <p>Puedes solicitar un certificado seleccionando el tipo de certificado y abonando la tasa
                        correspondiente</p>
                    <p>Entre <b>5 y 7 días hábiles</b> recibirás el certificado en tu correo electrónico</p>
                    <p><b>IMPORTANTE:</b> En caso de solicitar un certificado de notas para presentarlo ante las Administraciones de la <b>Comunidad Valenciana</b> o <b>Galicia</b>, debes contactar con Secretaría Académica e indicárselo.</p>
                    <div className="m-top-x2">
                        {academicPrograms.length > 1 && <div className="max-width-580 m-top-x1">
                            <span>Ciclo:</span>
                            <Select onChange={handleCourseSelect} noEmptyOption={true}
                                    options={academicPrograms.map((element, index) => ({
                                        key: element.id,
                                        id: index,
                                        name: element.title
                                    }))} required/>
                        </div>}
                        <div className="max-width-580 m-top">
                            <span>Certificado:</span>
                            <Select onChange={handleCertSelect} noEmptyOption={true}
                                    options={certificates.map((element, index) => ({
                                        key: element.id_product_attribute,
                                        id: element.id_product_attribute,
                                        name: element.name
                                    }))} required/>
                        </div>
                    </div>

                    <div className="m-top-x2">
                        <ButtonPrimary className='btn-inverse' onClick={() => clickButton()}>Realizar
                            pago</ButtonPrimary>
                    </div>
                </C>
            </F>
        </AppPage>
    )
}
