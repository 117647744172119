/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ContractDetails from './ContractDetails';
import ContributedDays from './ContributedDays';
import DocumentLimits from './DocumentLimits';
import PeriodDetails from './PeriodDetails';
import StudentDetails from './StudentDetails';
import WeeklyDoc from './WeeklyDoc';

/**
 * The ScreenData model module.
 * @module model/ScreenData
 * @version 1.0.0
 */
class ScreenData {
    /**
     * Constructs a new <code>ScreenData</code>.
     * @alias module:model/ScreenData
     */
    constructor() { 
        
        ScreenData.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ScreenData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ScreenData} obj Optional instance to populate.
     * @return {module:model/ScreenData} The populated <code>ScreenData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ScreenData();

            if (data.hasOwnProperty('document_limits')) {
                obj['document_limits'] = DocumentLimits.constructFromObject(data['document_limits']);
            }
            if (data.hasOwnProperty('oppened_docs')) {
                obj['oppened_docs'] = ApiClient.convertToType(data['oppened_docs'], 'Boolean');
            }
            if (data.hasOwnProperty('period')) {
                obj['period'] = PeriodDetails.constructFromObject(data['period']);
            }
            if (data.hasOwnProperty('oppened_comments')) {
                obj['oppened_comments'] = ApiClient.convertToType(data['oppened_comments'], 'Boolean');
            }
            if (data.hasOwnProperty('student_details')) {
                obj['student_details'] = StudentDetails.constructFromObject(data['student_details']);
            }
            if (data.hasOwnProperty('contract_details')) {
                obj['contract_details'] = ContractDetails.constructFromObject(data['contract_details']);
            }
            if (data.hasOwnProperty('weekly_docs')) {
                obj['weekly_docs'] = ApiClient.convertToType(data['weekly_docs'], [WeeklyDoc]);
            }
            if (data.hasOwnProperty('exemption_percent')) {
                obj['exemption_percent'] = ApiClient.convertToType(data['exemption_percent'], 'Number');
            }
            if (data.hasOwnProperty('real_contract_limit')) {
                obj['real_contract_limit'] = ApiClient.convertToType(data['real_contract_limit'], 'String');
            }
            if (data.hasOwnProperty('give_company_contract_limit')) {
                obj['give_company_contract_limit'] = ApiClient.convertToType(data['give_company_contract_limit'], 'String');
            }
            if (data.hasOwnProperty('proposals')) {
                obj['proposals'] = ApiClient.convertToType(data['proposals'], 'Number');
            }
            if (data.hasOwnProperty('document_errors')) {
                obj['document_errors'] = ApiClient.convertToType(data['document_errors'], ['String']);
            }
            if (data.hasOwnProperty('contributed_days')) {
                obj['contributed_days'] = ContributedDays.constructFromObject(data['contributed_days']);
            }
            if (data.hasOwnProperty('final_docs_received')) {
                obj['final_docs_received'] = ApiClient.convertToType(data['final_docs_received'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/DocumentLimits} document_limits
 */
ScreenData.prototype['document_limits'] = undefined;

/**
 * @member {Boolean} oppened_docs
 */
ScreenData.prototype['oppened_docs'] = undefined;

/**
 * @member {module:model/PeriodDetails} period
 */
ScreenData.prototype['period'] = undefined;

/**
 * @member {Boolean} oppened_comments
 */
ScreenData.prototype['oppened_comments'] = undefined;

/**
 * @member {module:model/StudentDetails} student_details
 */
ScreenData.prototype['student_details'] = undefined;

/**
 * @member {module:model/ContractDetails} contract_details
 */
ScreenData.prototype['contract_details'] = undefined;

/**
 * @member {Array.<module:model/WeeklyDoc>} weekly_docs
 */
ScreenData.prototype['weekly_docs'] = undefined;

/**
 * The exemption percentage
 * @member {Number} exemption_percent
 */
ScreenData.prototype['exemption_percent'] = undefined;

/**
 * Date of commitment contract closing
 * @member {String} real_contract_limit
 */
ScreenData.prototype['real_contract_limit'] = undefined;

/**
 * Date of commitment if give company
 * @member {String} give_company_contract_limit
 */
ScreenData.prototype['give_company_contract_limit'] = undefined;

/**
 * The number of proposals
 * @member {Number} proposals
 */
ScreenData.prototype['proposals'] = undefined;

/**
 * Errors related to documents
 * @member {Array.<String>} document_errors
 */
ScreenData.prototype['document_errors'] = undefined;

/**
 * @member {module:model/ContributedDays} contributed_days
 */
ScreenData.prototype['contributed_days'] = undefined;

/**
 * Final doc received
 * @member {Boolean} final_docs_received
 */
ScreenData.prototype['final_docs_received'] = undefined;






export default ScreenData;

