import React, {Component, Fragment, h} from "preact";
import {Card, CF, RE} from "../../_common/layout";
import {connect} from "react-redux";
import {AppPage} from "../../_common/components/app-page";
import {t} from "../../_i18n";
import {acceptPrivacyPolicy, fetchCandidate} from "../../_rx/jobs/actions";
import {Checkbox} from "../../_common/forms/components/Checkbox/Checkbox";


let TermsView = class extends Component {

    state = {
        checked : false,
        buttonDisable: false
    }

    handleChecked = (value) => {
        this.setState({checked: value})
    }

    handleSubmit = async () => {
        this.setState({buttonDisable: true})
        const userId = this.props.auth.loggedUser.id
        const {history} = this.props
        await this.props.acceptPrivacyPolicy()
        await this.props.fetchCandidate()
        history.push('/jobs')
    }

    render() {
        return <CF className='max-width'>
            <Card smOnly={false} class='m-x2-top'>
                <span className='font-18'>Para empezar a utilizar la bolsa de trabajo de ILERNA, lee y acepta la Política de Privacidad.</span>
                <Card className='m-top background-info font-18 p-all'>
                    <span className='font-medium'>INFORMACIÓN PROTECCIÓN DE DATOS DE ILERNA</span>
                    <ul className='commitment-list'>
                        <li>
                            <span className='font-medium'>RESPONSABLE:</span> GRUPO ILERNA (www.ilerna.es),
                            formado por las mercantiles: Ilerna Online, S.L. (B25774720), Centre Integral de FP Ilerna,
                            S.L. (B25026428),
                            Ilerna Tarragona, S.L. (B42835900), Formación Profesional Albor, S.L. (B11944261) CDP Albor
                            Madrid, S.L. (B42970079) y CDP Albor Córdoba,
                            S.L. (B56078033), Turo de Gardeny, Complejo Magical Media, Edif 5 (35003), Lleida
                            España, <span className='t-underline'>ilerna@delegado-datos.com</span>.
                        </li>

                        <li className='m-top'>
                            <span className='font-medium'>FINALIDAD:</span> Participar en los procesos de selección de
                            personal, presentes y futuros,
                            de nuestras empresas/entidades colaboradoras y mantenerle informado de estos. Enviarle
                            información de ofertas puedan ser de interés.
                        </li>
                        <li className='m-top'>
                            <span className='font-medium'>LEGITIMACIÓN:</span> Acuerdo con el usuario registrado y
                            consentimiento para informarle de ofertas de interés.
                        </li>
                        <li className='m-top'>
                            <span className='font-medium'>CESIONES:</span> A las empresas/entidades colaboradoras
                            durante los procesos de selección para presentar su candidatura.
                        </li>
                        <li className='m-top'>
                            <span className='font-medium'>CONSERVACIÓN:</span> Sus datos profesionales serán conservados
                            mientras esté dado de alta como candidato en la bolsa de trabajo.
                            El envío de información sobre ofertas se realizará hasta que el interesado solicite la baja comercial.
                        </li>
                        <li className='m-top'>
                            <span className='font-medium'>DERECHOS:</span> Puede ejercer su derecho de acceso, rectificación,
                            supresión, portabilidad, limitación y oposición dirigiéndose a los datos del responsable.
                            En caso de divergencias, puede presentar una reclamación ante la Agencia de Protección de Datos (www.aepd.es).
                        </li>
                    </ul>

                    {/*<span className='m-top'>Información Adicional: <a*/}
                    {/*    href='https://www.ilerna.es/es/content/politica-de-privacidad-19' className='t-underline'>Política de Privacidad</a>.</span>*/}
                </Card>

                <div className='m-top-x2 d-flex m-bottom font-18'>
                    <Checkbox
                        onChange={this.handleChecked}
                        value={this.state.checked}
                        label={
                            <span>
                                He leído y acepto la <a href='https://www.ilerna.es/es/content/politica-de-privacidad-19' >Política de Privacidad</a>.
                            </span>
                        }
                    />

                </div>
                <button className='btn btn-primary m-top full-width sm-no-full-width' disabled={!this.state.checked || this.state.buttonDisable} onClick={this.handleSubmit}>ACEPTAR Y EMPEZAR</button>
            </Card>
        </CF>
    }
}

TermsView = connect(
    (props)=>({...props}), {acceptPrivacyPolicy, fetchCandidate})(TermsView);

const TermsPage = (props) =>
    <AppPage
        name="terms"
        title={t('Bolsa de trabajo')}
        className="max-width">
        <TermsView {...props}/>
    </AppPage>
;

export default TermsPage;
