import {connect} from "react-redux";
import {
    clearCompany,
    fetchCompany,
    fetchContractTypes,
    fetchOffersCompany,
    fetchSchedules
} from "../../_rx/jobs/actions";
import {AppPage} from "../../_common/components/app-page";
import {t} from "../../_i18n";
import React, {Component, Fragment, h} from "preact";
import {Card, CF, E, FR, R} from "../../_common/layout";
import {TabsCompany} from "../components/tabs_company";
import {Link} from "react-router-dom";
import {OffersList} from "../components/offers_list";
import Pagination from "../../_common/components/pagination";
import {OFFERS_LIMIT_PAGES} from "../../constants";

const PUBLISHED_STATUS = '1'

const youtubeEmbedUrl = (url)=>{
    const match = url.match(/\?v=([^&]+)/);
    if(!match) return "";
    return "https://www.youtube.com/embed/"+match[1]+"?rel=0&amp;modestbranding=1&amp;autohide=1&amp;mute=0&amp;showinfo=0&amp;controls=1"
}

let CompanyView = class extends Component {

    state = {
        loadingNewOffers: false
    }

    async componentDidMount() {
        const page = this.props.match.params.page ? this.props.match.params.page : 1

        this.props.fetchCompany(this.props.match.params.id);

        if (!this.props.offersCompany || !this.props.match.params.page || !this.props.company)
            this.props.fetchOffersCompany({
                company: this.props.match.params.id,
                limit: OFFERS_LIMIT_PAGES,
                status: PUBLISHED_STATUS,
                page
            });
        this.props.fetchSchedules();
        this.props.fetchContractTypes();
    }

    async componentDidUpdate(previousProps, previousState, snapshot) {
        const page = this.props.match.params.page
        const prevPage = previousProps.match.params.page

        if(page !== prevPage){
            await this.props.fetchOffersCompany({company: this.props.match.params.id, limit: OFFERS_LIMIT_PAGES, status: PUBLISHED_STATUS, page});
            this.setState({loadingNewOffers: false})
        }

    }


    handleChangePage(newPage){
        const {match, history} = this.props
        const actualPage = parseInt(match.params.page)
        const isSamePage = (actualPage === newPage) || (isNaN(actualPage) && newPage === 1)
        if (isSamePage) return null

        history.push('/jobs/company/'+match.url.split('/')[3] +'/offers/'+ newPage)
        this.setState({loadingNewOffers: true})

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    render({company, offersCompany, schedules, contract_types, match}) {
        const isOffers = match.path.split('/')[4] === 'offers';
        return <CF className="container-fluid max-width-980">
            {(!company || (isOffers && !offersCompany)) ?
                <p className="t-center">
                    <em className="disabled-aspect-gray"> {t('Cargando')}. . . </em>
                </p> :
                    <Fragment>
                        <nav className="breadcrumb-wrapper p-x2-bottom">
                            <Link to='/jobs/offers' className="back breadcrumb-item">← {t('Volver')}</Link>
                        </nav>
                        <FR className="flex-center-x d-none sm-d-flex">
                            <div className="sm-col-65">
                                <h1 className="no-margin m-bottom">{company.name}</h1>

                                {company.location}
                                {company.empleados && ` | ${company.empleados} empleados`}
                                {company.web && <Fragment> | <a href={'https://'+company.web} target="_blank">{company.web}</a></Fragment>}

                            </div>
                            <div className="sm-col-35 d-flex flex-right">
                                {company.logo
                                  ? <div className="card m-bottom image-squared-company" style={`background-image:url('${window.micuentaenv.JOBS_API_basePath}/company/view-logo/${company.logo}');`} />
                                  : <div className="card m-bottom image-squared-company d-flex flex-center-x flex-center-y" > <i className="fal fa-building font-color-b8c7cc font-48"/> </div>
                                }
                            </div>
                        </FR>
                        <FR className="sm-d-none d-flex">
                            <div className="col-100 d-flex flex-center-x">
                                {company.logo
                                  ? <div className="card m-bottom image-squared-company" style={`background-image:url('${window.micuentaenv.JOBS_API_basePath}/company/view-logo/${company.logo}');`} />
                                  : <div className="card m-bottom image-squared-company d-flex flex-center-x flex-center-y" > <i className="fal fa-building font-color-b8c7cc font-48"/> </div>
                                }
                            </div>
                            <div className="col-100 d-flex flex-center-x">
                                <h1 className="no-margin">{company.name}</h1>
                            </div>
                            <div className="col-100 d-flex flex-center-x">
                                <span>{company.location} | {company.empleados} empleados</span>
                            </div>
                        </FR>
                        <TabsCompany company={company.id} match={match}/>
                        {(!isOffers) ?
                            <Card smOnly={false}>
                                <div className="d-flex">
                                    <p>{company.text}</p>
                                </div>
                                {company.video && <div className="youtube-responsive">
                                    <iframe className="embed-responsive-item" src={youtubeEmbedUrl(company.video)} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                </div>}
                            </Card>
                        :   <Fragment>
                                <OffersList offers={offersCompany} contract_types={contract_types} schedules={schedules} loadingOffers={this.state.loadingNewOffers}/>
                                <div className='m-x2-top'>
                                    <Pagination
                                        page={parseInt((match.params.page === undefined) ? 1 : match.params.page)}
                                        pageMax={offersCompany.pagination.total_pages}
                                        onClick={(page) => this.handleChangePage(page)}/>
                                </div>
                            </Fragment>
                        }
                    </Fragment>
            }
        </CF>
    }
}

CompanyView = connect(
    ({company, offersCompany, offers_fixtures: {schedules, contract_types}})=>({company, offersCompany, schedules, contract_types}), {fetchCompany, fetchOffersCompany, fetchSchedules, fetchContractTypes, clearCompany})(CompanyView);

const CompanyProfilePage = ({history, match}) =>
    <AppPage
        name="CompanyOffer"
        title=""
        subtitle=""
        className="max-width">
        <CompanyView match={match} history={history}/>
    </AppPage>
;

export default CompanyProfilePage;
