import { h, Fragment } from "preact";
import Modal from '../../../_common/components/Modal/index'
import './modal-size-guide.scss'
import Table from './Table'
import { t } from '../../../_i18n'

const sizes_forro_polar = {
  title: 'Forro polar',
  header: ['Talla', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'],
  content: [
    ['Ancho (cm)', '-', '55', '57', '60', '64', '68', '72'],
    ['Largo (cm)', '-', '73', '74', '75', '76', '77', '78'],
  ]
}

const sizes_polo = {
  title: 'Polo',
  header: ['Talla', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'],
  content: [
    ['Ancho (cm)', '-', '51', '54', '57', '60', '64', '67'],
    ['Largo (cm)', '-', '70', '72', '74', '76', '79', '82'],
  ]
}

const sizes_pantalon = {
  title: 'Pantalón',
  header: ['Talla', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'],
  content: [
    ['Talla europea', '34-36', '38-40', '42-44', '46-48', '50-52', '54-56', '58-60'],
    ['Cintura (cm)', '66-73', '74-81', '82-89', '90-97', '98-105', '106-113', '114-121'],
  ]
}

const sizes_chaleco = {
  title: 'Chaleco',
  header: ['Talla', 'S/M', 'L/XL', '2XL/3XL'],
  content: [
    ['Ancho (cm)', '58', '64', '72']
  ]
}

const sizes_pijama = {
  title: 'Pijama sanitario',
  header: ['Talla', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'],
  content: [
    ['1. Pecho', '52', '56', '60', '64', '68', '72', '76'],
    ['2. Largo espalda', '71', '72,5', '74', '75,5', '77', '78,5', '80'],
    ['3. Cintura a su asiento / estirada', '21,4 / 46', '33,6 / 50', '35,8 / 54', '38 / 58', '40,2 / 62', '43 / 67', '45,8 / 72'],
    ['4. Altura de cadera', '20', '21', '22', '23', '24', '25', '26'],
    ['5. Cadera', '48', '52', '56', '60', '64', '68,5', '73'],
    ['6. Largo por costado', '108', '109', '110', '111', '112', '113', '114'],
    ['7. Largo entrepierna', '82', '82', '82', '82', '82', '82', '82'],
    ['8. Ancho de bajo', '16,4', '17,2', '18', '18,8', '19,6', '20,4', '21,2'],
    ['9. Elástico cintura', '59', '63', '67', '71', '75', '80,4', '85,8'],
    ['10. Cintura una vez puesto el elástico y cerrado', '31,4', '33,6', '35,8', '38', '40,2', '43', '45,8'],
  ]
}

const sizes_bata = {
  title: 'Bata sanitaria',
  header: ['Talla', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'],
  content: [
    ['1. Pecho', '52', '56', '60', '64', '68', '72', '76'],
    ['2. Cintura', '49', '53', '57', '61', '65', '69', '73'],
    ['3. Cadera', '54', '58', '62', '66', '70', '74', '78'],
    ['4. Largo espalda', '99,7', '101,3', '103', '104,6', '106,3', '107,9', '109,6'],
    ['5. Largo manga', '63,6', '64,3', '65', '65,9', '66,8', '67,7', '68,6'],
    ['6. Hombro a hombro', '42,5', '44,5', '46,5', '48,5', '50,5', '52,5', '54,5'],
  ]
}


export default function ModalSizeGuide ({values, visible, onClose }) {
  return (
    <Modal visible={visible} closeModal={onClose}>
      <div className='fct-modal'>
        <div className='fct-modal-header'>
          <i className="fal fa-times" onClick={onClose} />
        </div>
        <div className='fct-modal-body__title font-bold m-bottom'>{t('Guía de tallas')}</div>
          <div className='fct-modal-body'>
            { values.is_dress_uniform && (
              <Fragment>
                <Table sizes={sizes_forro_polar} className='m-top-x2' />
                <Table sizes={sizes_polo} className='m-top-x2' />
                <Table sizes={sizes_pantalon} className='m-top-x2' />
                <Table sizes={sizes_chaleco} className='m-top-x2' />
              </Fragment>
            ) }
            { values.is_dress_pijama &&  <Table sizes={sizes_pijama} className='m-top-x2' /> }
            { values.is_dress_coat &&  <Table sizes={sizes_bata} className='m-top-x2' />}
        </div>
      </div>
    </Modal>
  )
}
