import { h } from "preact";
import SignAddress from '../SignAddress/SignAddress'
import AlertCollapse from '../../../_common/components/AlertCollapse/AlertCollapse'
import { t } from '../../../_i18n'


export default function AlertContractCatLegacy ({ className }) {
  return (
    <AlertCollapse title={t('👋 Si aún no nos has enviado el convenio firmado, por favor, recuerda que es necesario hacerlo.')} className={className}>
      <ol>
        <li>{t('Imprime 3 copias, una para la empresa, otra para ti, y otra para ILERNA.')}</li>
        <li dangerouslySetInnerHTML={{ __html: t('alert_contract_cat_legacy') }} />
        <li>{t('Posteriormente, fírmalos tú también, y envíanos una de las copias originales a la siguiente dirección postal:')}</li>
      </ol>
      <SignAddress className='m-top' />
    </AlertCollapse>
  )
}
