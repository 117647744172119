import {SET_INVITE_FRIEND_STATUS, SET_INVITE_FRIEND_ADDRESS} from '../actions/constants'
import {createReducer} from "../../../_common/common.ts";


export const inviteFriendStatus = createReducer({status: null, address: null}, {
    [SET_INVITE_FRIEND_STATUS]: (state, status)=> {
        state.status = status;
        return state;
    },
    [SET_INVITE_FRIEND_ADDRESS]: (state, address)=>{
        state.address = address;
        return state
    },
});
