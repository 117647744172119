import { h } from "preact";
import '../UploadDocument/upload-document-styles.scss'
import { strHash } from '../../../_common/common'
import { fetchStatus, saveDocument } from '../../../_rx/fct/actions'
import { useDispatch } from 'react-redux'
import { toastService } from '../../../_services/toast-service'
import { FILE_ACCEPT_FILES, FILE_SIZE_MAX } from '../../constants'
import { t } from '../../../_i18n'

export default function UploadDocumentWeekly ({ disabled, typeDocument, className, contractId,...rest }) {
  const id = strHash('upload-weekly');
  const dispatch = useDispatch()

  const handleChange = (e) => {
    const file = e.target.files[0]
    if (file.size < FILE_SIZE_MAX) {
      saveDocument(contractId, file, typeDocument)
        .then(() => {
          dispatch(fetchStatus(contractId))
        })
    } else toastService.showToast({message: t('El archivo debe ocupar menos de 5 MB'), type:'danger'})
  }

  return (
    <div className={`fct-upload-document ${className}`}>
      <div className='m-top'><label className='btn btn-primary width-fit-content' htmlFor={!disabled && id}>{t('Subir ficha semanal')}</label></div>
      <input type="file" id={id} onChange={handleChange} accept={FILE_ACCEPT_FILES} value='' />
    </div>
  )
}
