import { h } from "preact";
import { AppPage } from '../../_common/components/app-page'
import { ButtonGhost, ButtonPrimary } from '../../_common/components/buttons/buttons'
import { Field, Form as FinalForm } from 'react-final-form'
import { C, F } from '../../_common/layout'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { useEffect, useState } from 'preact/hooks'
import { fetchAllDocuments, fetchStatus, putDocument } from '../../_rx/fct/actions'
import UploadDocument from '../components/UploadDocument/UploadDocument'
import { validationDocumentFCT } from '../components/UploadDocument/validationDocumentFCT'
import { Link } from 'react-router-dom'
import ButtonSpinner from '../../_common/components/buttons/buttonSpinner/buttonSpinner'
import { t } from '../../_i18n'
import { isSpanishUser } from '../../_common/utils/getLang'
import AlertBox from "../../_common/components/AlertBox/AlertBox";

export default function ContributeCompany () {
  const static_documents = useSelector(state => state.fct.static_documents)
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const [data, setData] = useState(null)
  const shopId = useSelector(state => state.status.student_meta.shop_id)

  useEffect(() => {
    fetchAllDocuments(id).then((data) => setData(data))
  }, [])

  const handleSubmit = (values) => {
    setLoading(true)
    putDocument(values.blocks.company_info.documents.company_details.id)
      .then(() => {
        setLoading(false)
        dispatch(fetchStatus(id))
        history.push(`/fct/${id}`)
      })
      .catch(() => setLoading(false))
  }

  return (
    <AppPage title={t('Aportar empresa')} back={history.goBack} className="max-width-980">
      <F className="max-width-980">
        <FinalForm
          onSubmit={handleSubmit}
          initialValues={data}
          render={(formEvents)=> (
            <form action="" onSubmit={formEvents.handleSubmit} noValidate>
              <C className='m-top-x2'>
                <p>{t('Si has encontrado empresa por tu cuenta, por favor, sube aquí la documentación correspondiente.')}</p>
                <p>{t('Nos pondremos en contacto con la empresa para detallar tus prácticas.')}</p>
                <p>{t('Cuando empieces las prácticas te enviaremos el bono de 40€.')}</p>
                <ol className='m-top-x4'>
                  <li>{t('Descárgate el documento de empresa:')} <a href={static_documents.company.url}>‘Datos de empresa.pdf’</a>.
                  { !isSpanishUser(shopId) &&
                      <AlertBox type={"info"}>
                        {t('fct_international_help')}
                        <a href={t('fct_contribute_comp_international_help_document_link')}>{t('fct_contribute_comp_international_help_document')}.</a>
                      </AlertBox>
                  }
                  </li>
                  <li>{t('Rellena el documento con los datos de la empresa.')}</li>
                  <li>{t('Adjunta el documento a continuación:')}</li>
                </ol>
                <Field
                  name='blocks.company_info.documents.company_details'
                  label={t('Documento de empresa')}
                  component={UploadDocument}
                  typeDocument='company_details'
                  contractId={id}
                  className='m-top'
                  specificError={t('Por favor, agrega el documento con información de la empresa.')}
                  required
                  validate={validationDocumentFCT}
                />
                <div className='m-top-x2'><hr className="separator-xxs"/></div>
                <div className='ftp-button-bar'>
                  <Link to={`/fct/${id}`}><ButtonGhost type='button'>{t('Cancelar')}</ButtonGhost></Link>
                  <ButtonSpinner type='submit' showSpinner={loading}>{t('Enviar solicitud')}</ButtonSpinner>
                </div>
              </C>
            </form>
        )}/>
      </F>
    </AppPage>
  )
}
