/**
 * LTI Enrollment Classes
 * LTI Enroll Class for Students
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The FormativeActions model module.
 * @module model/FormativeActions
 * @version 2.0.0
 */
class FormativeActions {
    /**
     * Constructs a new <code>FormativeActions</code>.
     * @alias module:model/FormativeActions
     */
    constructor() { 
        
        FormativeActions.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>FormativeActions</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/FormativeActions} obj Optional instance to populate.
     * @return {module:model/FormativeActions} The populated <code>FormativeActions</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new FormativeActions();

            if (data.hasOwnProperty('end_date_join')) {
                obj['end_date_join'] = ApiClient.convertToType(data['end_date_join'], 'String');
            }
            if (data.hasOwnProperty('ended_enroll_period')) {
                obj['ended_enroll_period'] = ApiClient.convertToType(data['ended_enroll_period'], 'Boolean');
            }
            if (data.hasOwnProperty('class_certificate')) {
                obj['class_certificate'] = ApiClient.convertToType(data['class_certificate'], 'Boolean');
            }
            if (data.hasOwnProperty('joined_certificate')) {
                obj['joined_certificate'] = ApiClient.convertToType(data['joined_certificate'], 'Boolean');
            }
            if (data.hasOwnProperty('academic_program_title')) {
                obj['academic_program_title'] = ApiClient.convertToType(data['academic_program_title'], 'String');
            }
            if (data.hasOwnProperty('is_blended')) {
                obj['is_blended'] = ApiClient.convertToType(data['is_blended'], 'Boolean');
            }
            if (data.hasOwnProperty('is_extraordinary')) {
                obj['is_extraordinary'] = ApiClient.convertToType(data['is_extraordinary'], 'Boolean');
            }
            if (data.hasOwnProperty('formative_actions')) {
                obj['formative_actions'] = ApiClient.convertToType(data['formative_actions'], Object);
            }
        }
        return obj;
    }


}

/**
 * @member {String} end_date_join
 */
FormativeActions.prototype['end_date_join'] = undefined;

/**
 * @member {Boolean} ended_enroll_period
 */
FormativeActions.prototype['ended_enroll_period'] = undefined;

/**
 * @member {Boolean} class_certificate
 */
FormativeActions.prototype['class_certificate'] = undefined;

/**
 * @member {Boolean} joined_certificate
 */
FormativeActions.prototype['joined_certificate'] = undefined;

/**
 * @member {String} academic_program_title
 */
FormativeActions.prototype['academic_program_title'] = undefined;

/**
 * @member {Boolean} is_blended
 */
FormativeActions.prototype['is_blended'] = undefined;

/**
 * @member {Boolean} is_extraordinary
 */
FormativeActions.prototype['is_extraordinary'] = undefined;

/**
 * @member {Object} formative_actions
 */
FormativeActions.prototype['formative_actions'] = undefined;






export default FormativeActions;

