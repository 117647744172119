import { h } from 'preact'
import './steps-styles.scss'
import { useSelector } from 'react-redux'
import { CALENDAR_OWNER, scheduleApiKey } from './constants'

export default function StepSchedule ({ schedule }) {
  const student = useSelector(state => state.student)
  const firstName = student.data.name ? `&firstName=${student.data.name}` : ''
  const lastName = (student.data.first_name && student.data.last_name) ? `&lastName=${student.data.first_name} ${student.data.last_name}` : student.data.first_name ? `&lastName=${student.data.first_name}` : ''
  const phone = student.address.phone ? `&phone=${student.address.phone}` : ''
  const email = student.data.email ? `&email=${student.data.email}` : ''
  const url = `https://app.acuityscheduling.com/schedule.php?owner=${CALENDAR_OWNER}&calendarID=${scheduleApiKey[schedule]}${firstName}${lastName}${phone}${email}`

  return (
    <div className='step-container-schedule'>
      <iframe src={url} width="100%" height="100%" />
    </div>
  )
}
