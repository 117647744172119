/**
 * Student Account
 * Student Account Api - Validation flow
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dmartinez@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from './ApiClient';
import AcademicProgramDocumentType from './model/AcademicProgramDocumentType';
import AddressType from './model/AddressType';
import City from './model/City';
import Country from './model/Country';
import CourseAccessType from './model/CourseAccessType';
import Doc from './model/Doc';
import Document from './model/Document';
import DocumentType from './model/DocumentType';
import Error from './model/Error';
import Fixture from './model/Fixture';
import GenderType from './model/GenderType';
import InlineObject from './model/InlineObject';
import InlineObject1 from './model/InlineObject1';
import InlineObject2 from './model/InlineObject2';
import InlineObject3 from './model/InlineObject3';
import InlineResponse200 from './model/InlineResponse200';
import InlineResponse2001 from './model/InlineResponse2001';
import InlineResponse2002 from './model/InlineResponse2002';
import InlineResponse2003 from './model/InlineResponse2003';
import InlineResponse2004 from './model/InlineResponse2004';
import LastCourseType from './model/LastCourseType';
import Message from './model/Message';
import MessageStatus from './model/MessageStatus';
import MessageType from './model/MessageType';
import Nee from './model/Nee';
import NotificationsStatus from './model/NotificationsStatus';
import PresentAddressSelected from './model/PresentAddressSelected';
import PrestaAddress from './model/PrestaAddress';
import ProfileSetChannels from './model/ProfileSetChannels';
import ProfileSetItem from './model/ProfileSetItem';
import ProfileSetItemStatus from './model/ProfileSetItemStatus';
import ProfileSetTypes from './model/ProfileSetTypes';
import ProfileValue from './model/ProfileValue';
import Province from './model/Province';
import Shop from './model/Shop';
import State from './model/State';
import Student from './model/Student';
import StudentAddress from './model/StudentAddress';
import StudentCertifiedCourseAccessDocument from './model/StudentCertifiedCourseAccessDocument';
import StudentCourseAccess from './model/StudentCourseAccess';
import StudentData from './model/StudentData';
import StudentIdentityDocument from './model/StudentIdentityDocument';
import StudentLastCourse from './model/StudentLastCourse';
import StudentMeta from './model/StudentMeta';
import StudentMetaCoursingAcademicPrograms from './model/StudentMetaCoursingAcademicPrograms';
import StudentMetaFctContracts from './model/StudentMetaFctContracts';
import StudentPersonalData from './model/StudentPersonalData';
import ValidationBlockStatus from './model/ValidationBlockStatus';
import ValidationStatus from './model/ValidationStatus';
import ViaType from './model/ViaType';
import AddressApi from './api/AddressApi';
import FixtureApi from './api/FixtureApi';
import ProfileSetApi from './api/ProfileSetApi';
import StudentApi from './api/StudentApi';


/**
* Student_Account_Api___Validation_flow.<br>
* The <code>index</code> module provides access to constructors for all the classes which comprise the public API.
* <p>
* An AMD (recommended!) or CommonJS application will generally do something equivalent to the following:
* <pre>
* var StudentAccount = require('index'); // See note below*.
* var xxxSvc = new StudentAccount.XxxApi(); // Allocate the API class we're going to use.
* var yyyModel = new StudentAccount.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* <em>*NOTE: For a top-level AMD script, use require(['index'], function(){...})
* and put the application logic within the callback function.</em>
* </p>
* <p>
* A non-AMD browser application (discouraged) might do something like this:
* <pre>
* var xxxSvc = new StudentAccount.XxxApi(); // Allocate the API class we're going to use.
* var yyy = new StudentAccount.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* </p>
* @module index
* @version 1.0.0
*/
export {
    /**
     * The ApiClient constructor.
     * @property {module:ApiClient}
     */
    ApiClient,

    /**
     * The AcademicProgramDocumentType model constructor.
     * @property {module:model/AcademicProgramDocumentType}
     */
    AcademicProgramDocumentType,

    /**
     * The AddressType model constructor.
     * @property {module:model/AddressType}
     */
    AddressType,

    /**
     * The City model constructor.
     * @property {module:model/City}
     */
    City,

    /**
     * The Country model constructor.
     * @property {module:model/Country}
     */
    Country,

    /**
     * The CourseAccessType model constructor.
     * @property {module:model/CourseAccessType}
     */
    CourseAccessType,

    /**
     * The Doc model constructor.
     * @property {module:model/Doc}
     */
    Doc,

    /**
     * The Document model constructor.
     * @property {module:model/Document}
     */
    Document,

    /**
     * The DocumentType model constructor.
     * @property {module:model/DocumentType}
     */
    DocumentType,

    /**
     * The Error model constructor.
     * @property {module:model/Error}
     */
    Error,

    /**
     * The Fixture model constructor.
     * @property {module:model/Fixture}
     */
    Fixture,

    /**
     * The GenderType model constructor.
     * @property {module:model/GenderType}
     */
    GenderType,

    /**
     * The InlineObject model constructor.
     * @property {module:model/InlineObject}
     */
    InlineObject,

    /**
     * The InlineObject1 model constructor.
     * @property {module:model/InlineObject1}
     */
    InlineObject1,

    /**
     * The InlineObject2 model constructor.
     * @property {module:model/InlineObject2}
     */
    InlineObject2,

    /**
     * The InlineObject3 model constructor.
     * @property {module:model/InlineObject3}
     */
    InlineObject3,

    /**
     * The InlineResponse200 model constructor.
     * @property {module:model/InlineResponse200}
     */
    InlineResponse200,

    /**
     * The InlineResponse2001 model constructor.
     * @property {module:model/InlineResponse2001}
     */
    InlineResponse2001,

    /**
     * The InlineResponse2002 model constructor.
     * @property {module:model/InlineResponse2002}
     */
    InlineResponse2002,

    /**
     * The InlineResponse2003 model constructor.
     * @property {module:model/InlineResponse2003}
     */
    InlineResponse2003,

    /**
     * The InlineResponse2004 model constructor.
     * @property {module:model/InlineResponse2004}
     */
    InlineResponse2004,

    /**
     * The LastCourseType model constructor.
     * @property {module:model/LastCourseType}
     */
    LastCourseType,

    /**
     * The Message model constructor.
     * @property {module:model/Message}
     */
    Message,

    /**
     * The MessageStatus model constructor.
     * @property {module:model/MessageStatus}
     */
    MessageStatus,

    /**
     * The MessageType model constructor.
     * @property {module:model/MessageType}
     */
    MessageType,

    /**
     * The Nee model constructor.
     * @property {module:model/Nee}
     */
    Nee,

    /**
     * The NotificationsStatus model constructor.
     * @property {module:model/NotificationsStatus}
     */
    NotificationsStatus,

    /**
     * The PresentAddressSelected model constructor.
     * @property {module:model/PresentAddressSelected}
     */
    PresentAddressSelected,

    /**
     * The PrestaAddress model constructor.
     * @property {module:model/PrestaAddress}
     */
    PrestaAddress,

    /**
     * The ProfileSetChannels model constructor.
     * @property {module:model/ProfileSetChannels}
     */
    ProfileSetChannels,

    /**
     * The ProfileSetItem model constructor.
     * @property {module:model/ProfileSetItem}
     */
    ProfileSetItem,

    /**
     * The ProfileSetItemStatus model constructor.
     * @property {module:model/ProfileSetItemStatus}
     */
    ProfileSetItemStatus,

    /**
     * The ProfileSetTypes model constructor.
     * @property {module:model/ProfileSetTypes}
     */
    ProfileSetTypes,

    /**
     * The ProfileValue model constructor.
     * @property {module:model/ProfileValue}
     */
    ProfileValue,

    /**
     * The Province model constructor.
     * @property {module:model/Province}
     */
    Province,

    /**
     * The Shop model constructor.
     * @property {module:model/Shop}
     */
    Shop,

    /**
     * The State model constructor.
     * @property {module:model/State}
     */
    State,

    /**
     * The Student model constructor.
     * @property {module:model/Student}
     */
    Student,

    /**
     * The StudentAddress model constructor.
     * @property {module:model/StudentAddress}
     */
    StudentAddress,

    /**
     * The StudentCertifiedCourseAccessDocument model constructor.
     * @property {module:model/StudentCertifiedCourseAccessDocument}
     */
    StudentCertifiedCourseAccessDocument,

    /**
     * The StudentCourseAccess model constructor.
     * @property {module:model/StudentCourseAccess}
     */
    StudentCourseAccess,

    /**
     * The StudentData model constructor.
     * @property {module:model/StudentData}
     */
    StudentData,

    /**
     * The StudentIdentityDocument model constructor.
     * @property {module:model/StudentIdentityDocument}
     */
    StudentIdentityDocument,

    /**
     * The StudentLastCourse model constructor.
     * @property {module:model/StudentLastCourse}
     */
    StudentLastCourse,

    /**
     * The StudentMeta model constructor.
     * @property {module:model/StudentMeta}
     */
    StudentMeta,

    /**
     * The StudentMetaCoursingAcademicPrograms model constructor.
     * @property {module:model/StudentMetaCoursingAcademicPrograms}
     */
    StudentMetaCoursingAcademicPrograms,

    /**
     * The StudentMetaFctContracts model constructor.
     * @property {module:model/StudentMetaFctContracts}
     */
    StudentMetaFctContracts,

    /**
     * The StudentPersonalData model constructor.
     * @property {module:model/StudentPersonalData}
     */
    StudentPersonalData,

    /**
     * The ValidationBlockStatus model constructor.
     * @property {module:model/ValidationBlockStatus}
     */
    ValidationBlockStatus,

    /**
     * The ValidationStatus model constructor.
     * @property {module:model/ValidationStatus}
     */
    ValidationStatus,

    /**
     * The ViaType model constructor.
     * @property {module:model/ViaType}
     */
    ViaType,

    /**
    * The AddressApi service constructor.
    * @property {module:api/AddressApi}
    */
    AddressApi,

    /**
    * The FixtureApi service constructor.
    * @property {module:api/FixtureApi}
    */
    FixtureApi,

    /**
    * The ProfileSetApi service constructor.
    * @property {module:api/ProfileSetApi}
    */
    ProfileSetApi,

    /**
    * The StudentApi service constructor.
    * @property {module:api/StudentApi}
    */
    StudentApi
};
