/**
 * New Expedient
 * Expedient for Student Account
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import RecognizedNew from './RecognizedNew';

/**
 * The FormativeAction model module.
 * @module model/FormativeAction
 * @version 1.0
 */
class FormativeAction {
    /**
     * Constructs a new <code>FormativeAction</code>.
     * @alias module:model/FormativeAction
     */
    constructor() { 
        
        FormativeAction.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>FormativeAction</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/FormativeAction} obj Optional instance to populate.
     * @return {module:model/FormativeAction} The populated <code>FormativeAction</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new FormativeAction();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('abbr')) {
                obj['abbr'] = ApiClient.convertToType(data['abbr'], 'String');
            }
            if (data.hasOwnProperty('order')) {
                obj['order'] = ApiClient.convertToType(data['order'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('is_enrolled')) {
                obj['is_enrolled'] = ApiClient.convertToType(data['is_enrolled'], 'Boolean');
            }
            if (data.hasOwnProperty('is_enrollable')) {
                obj['is_enrollable'] = ApiClient.convertToType(data['is_enrollable'], 'Boolean');
            }
            if (data.hasOwnProperty('enroll_id')) {
                obj['enroll_id'] = ApiClient.convertToType(data['enroll_id'], 'Number');
            }
            if (data.hasOwnProperty('enroll_status')) {
                obj['enroll_status'] = ApiClient.convertToType(data['enroll_status'], 'Number');
            }
            if (data.hasOwnProperty('grade')) {
                obj['grade'] = ApiClient.convertToType(data['grade'], 'String');
            }
            if (data.hasOwnProperty('recognized')) {
                obj['recognized'] = ApiClient.convertToType(data['recognized'], 'Boolean');
            }
            if (data.hasOwnProperty('approved')) {
                obj['approved'] = ApiClient.convertToType(data['approved'], 'Boolean');
            }
            if (data.hasOwnProperty('allow_revision')) {
                obj['allow_revision'] = ApiClient.convertToType(data['allow_revision'], 'Boolean');
            }
            if (data.hasOwnProperty('revision_submitted')) {
                obj['revision_submitted'] = ApiClient.convertToType(data['revision_submitted'], 'Boolean');
            }
            if (data.hasOwnProperty('last_exam_convocation_id')) {
                obj['last_exam_convocation_id'] = ApiClient.convertToType(data['last_exam_convocation_id'], 'Number');
            }
            if (data.hasOwnProperty('has_children')) {
                obj['has_children'] = ApiClient.convertToType(data['has_children'], 'Boolean');
            }
            if (data.hasOwnProperty('is_training_work_center')) {
                obj['is_training_work_center'] = ApiClient.convertToType(data['is_training_work_center'], 'Boolean');
            }
            if (data.hasOwnProperty('teaching_hours')) {
                obj['teaching_hours'] = ApiClient.convertToType(data['teaching_hours'], 'Number');
            }
            if (data.hasOwnProperty('recognized_new')) {
                obj['recognized_new'] = RecognizedNew.constructFromObject(data['recognized_new']);
            }
            if (data.hasOwnProperty('hidden_grade')) {
                obj['hidden_grade'] = ApiClient.convertToType(data['hidden_grade'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
FormativeAction.prototype['id'] = undefined;

/**
 * @member {String} abbr
 */
FormativeAction.prototype['abbr'] = undefined;

/**
 * @member {Number} order
 */
FormativeAction.prototype['order'] = undefined;

/**
 * @member {String} title
 */
FormativeAction.prototype['title'] = undefined;

/**
 * @member {Boolean} is_enrolled
 */
FormativeAction.prototype['is_enrolled'] = undefined;

/**
 * @member {Boolean} is_enrollable
 */
FormativeAction.prototype['is_enrollable'] = undefined;

/**
 * @member {Number} enroll_id
 */
FormativeAction.prototype['enroll_id'] = undefined;

/**
 * integer value 0 value if student isn't enrolled
 * @member {Number} enroll_status
 */
FormativeAction.prototype['enroll_status'] = undefined;

/**
 * @member {String} grade
 */
FormativeAction.prototype['grade'] = undefined;

/**
 * @member {Boolean} recognized
 */
FormativeAction.prototype['recognized'] = undefined;

/**
 * @member {Boolean} approved
 */
FormativeAction.prototype['approved'] = undefined;

/**
 * @member {Boolean} allow_revision
 */
FormativeAction.prototype['allow_revision'] = undefined;

/**
 * @member {Boolean} revision_submitted
 */
FormativeAction.prototype['revision_submitted'] = undefined;

/**
 * @member {Number} last_exam_convocation_id
 */
FormativeAction.prototype['last_exam_convocation_id'] = undefined;

/**
 * @member {Boolean} has_children
 */
FormativeAction.prototype['has_children'] = undefined;

/**
 * @member {Boolean} is_training_work_center
 */
FormativeAction.prototype['is_training_work_center'] = undefined;

/**
 * @member {Number} teaching_hours
 */
FormativeAction.prototype['teaching_hours'] = undefined;

/**
 * @member {module:model/RecognizedNew} recognized_new
 */
FormativeAction.prototype['recognized_new'] = undefined;

/**
 * @member {Boolean} hidden_grade
 */
FormativeAction.prototype['hidden_grade'] = undefined;






export default FormativeAction;

