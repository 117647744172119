/**
 * Student Account
 * Student Account Api - Validation flow
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dmartinez@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The StudentMetaFctContracts model module.
 * @module model/StudentMetaFctContracts
 * @version 1.0.0
 */
class StudentMetaFctContracts {
    /**
     * Constructs a new <code>StudentMetaFctContracts</code>.
     * @alias module:model/StudentMetaFctContracts
     */
    constructor() { 
        
        StudentMetaFctContracts.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>StudentMetaFctContracts</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StudentMetaFctContracts} obj Optional instance to populate.
     * @return {module:model/StudentMetaFctContracts} The populated <code>StudentMetaFctContracts</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StudentMetaFctContracts();

            if (data.hasOwnProperty('program')) {
                obj['program'] = ApiClient.convertToType(data['program'], 'String');
            }
            if (data.hasOwnProperty('contract_id')) {
                obj['contract_id'] = ApiClient.convertToType(data['contract_id'], 'Number');
            }
            if (data.hasOwnProperty('formative_zone')) {
                obj['formative_zone'] = ApiClient.convertToType(data['formative_zone'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('accepted')) {
                obj['accepted'] = ApiClient.convertToType(data['accepted'], 'Boolean');
            }
            if (data.hasOwnProperty('rgpd_accepted')) {
                obj['rgpd_accepted'] = ApiClient.convertToType(data['rgpd_accepted'], 'Boolean');
            }            
        }
        return obj;
    }


}

/**
 * @member {String} program
 */
StudentMetaFctContracts.prototype['program'] = undefined;

/**
 * @member {Number} contract_id
 */
StudentMetaFctContracts.prototype['contract_id'] = undefined;

/**
 * @member {String} formative_zone
 */
StudentMetaFctContracts.prototype['formative_zone'] = undefined;

/**
 * @member {String} status
 */
StudentMetaFctContracts.prototype['status'] = undefined;

/**
 * @member {Boolean} accepted
 */
StudentMetaFctContracts.prototype['accepted'] = undefined;






export default StudentMetaFctContracts;

