/**
 * Student Account
 * Student Account Api - Validation flow
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dmartinez@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import MessageType from './MessageType';
import StudentAddress from './StudentAddress';
import StudentLastCourse from './StudentLastCourse';
import StudentPersonalData from './StudentPersonalData';

/**
 * The StudentData model module.
 * @module model/StudentData
 * @version 1.0.0
 */
class StudentData {
    /**
     * Constructs a new <code>StudentData</code>.
     * Student personal data and address
     * @alias module:model/StudentData
     * @param data {module:model/StudentPersonalData} 
     * @param address {module:model/StudentAddress} 
     * @param lastCourse {module:model/StudentLastCourse} 
     */
    constructor(data, address, lastCourse) { 
        
        StudentData.initialize(this, data, address, lastCourse);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, data, address, lastCourse) { 
        obj['data'] = data;
        obj['address'] = address;
        obj['last_course'] = lastCourse;
    }

    /**
     * Constructs a <code>StudentData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StudentData} obj Optional instance to populate.
     * @return {module:model/StudentData} The populated <code>StudentData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StudentData();

            if (data.hasOwnProperty('block_id')) {
                obj['block_id'] = ApiClient.convertToType(data['block_id'], 'Number');
            }
            if (data.hasOwnProperty('data')) {
                obj['data'] = StudentPersonalData.constructFromObject(data['data']);
            }
            if (data.hasOwnProperty('address')) {
                obj['address'] = StudentAddress.constructFromObject(data['address']);
            }
            if (data.hasOwnProperty('last_course')) {
                obj['last_course'] = StudentLastCourse.constructFromObject(data['last_course']);
            }
            if (data.hasOwnProperty('messages')) {
                obj['messages'] = ApiClient.convertToType(data['messages'], [MessageType]);
            }
        }
        return obj;
    }


}

/**
 * View block identifier
 * @member {Number} block_id
 */
StudentData.prototype['block_id'] = undefined;

/**
 * @member {module:model/StudentPersonalData} data
 */
StudentData.prototype['data'] = undefined;

/**
 * @member {module:model/StudentAddress} address
 */
StudentData.prototype['address'] = undefined;

/**
 * @member {module:model/StudentLastCourse} last_course
 */
StudentData.prototype['last_course'] = undefined;

/**
 * Message types array with related messages
 * @member {Array.<module:model/MessageType>} messages
 */
StudentData.prototype['messages'] = undefined;






export default StudentData;

