/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import InlineObject4 from '../model/InlineObject4';
import InlineResponse2004 from '../model/InlineResponse2004';
import InlineResponse404 from '../model/InlineResponse404';

/**
* ProfileSetting service.
* @module api/ProfileSettingApi
* @version 1.0
*/
export default class ProfileSettingApi {

    /**
    * Constructs a new ProfileSettingApi. 
    * @alias module:api/ProfileSettingApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get profile detail of a user
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2004} and HTTP response
     */
    appHttpControllersProfileSetControllerIndexWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2004;
      return this.apiClient.callApi(
        '/api/v1/profile', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get profile detail of a user
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2004}
     */
    appHttpControllersProfileSetControllerIndex() {
      return this.appHttpControllersProfileSetControllerIndexWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Post a profile detail for a user
     * @param {module:model/InlineObject4} inlineObject4 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse404} and HTTP response
     */
    appHttpControllersProfileSetControllerUpdateWithHttpInfo(inlineObject4) {
      let postBody = inlineObject4;
      // verify the required parameter 'inlineObject4' is set
      if (inlineObject4 === undefined || inlineObject4 === null) {
        throw new Error("Missing the required parameter 'inlineObject4' when calling appHttpControllersProfileSetControllerUpdate");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse404;
      return this.apiClient.callApi(
        '/api/v1/profile', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Post a profile detail for a user
     * @param {module:model/InlineObject4} inlineObject4 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse404}
     */
    appHttpControllersProfileSetControllerUpdate(inlineObject4) {
      return this.appHttpControllersProfileSetControllerUpdateWithHttpInfo(inlineObject4)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
