/**
 * LTI Enrollment Classes
 * LTI Enroll Class for Students
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from './ApiClient';
import ArrayOfAcademicProgramsItem from './model/ArrayOfAcademicProgramsItem';
import Error from './model/Error';
import EventCalendarGrades from './model/EventCalendarGrades';
import FormativeActions from './model/FormativeActions';
import FormativeActionsPerPeriod from './model/FormativeActionsPerPeriod';
import DownloadsApi from './api/DownloadsApi';
import EnrollmentActionsApi from './api/EnrollmentActionsApi';
import EventDetailApi from './api/EventDetailApi';
import EventsApi from './api/EventsApi';
import GradesApi from './api/GradesApi';


/**
* LTI_Enroll_Class_for_Students.<br>
* The <code>index</code> module provides access to constructors for all the classes which comprise the public API.
* <p>
* An AMD (recommended!) or CommonJS application will generally do something equivalent to the following:
* <pre>
* var LtiEnrollmentClasses = require('index'); // See note below*.
* var xxxSvc = new LtiEnrollmentClasses.XxxApi(); // Allocate the API class we're going to use.
* var yyyModel = new LtiEnrollmentClasses.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* <em>*NOTE: For a top-level AMD script, use require(['index'], function(){...})
* and put the application logic within the callback function.</em>
* </p>
* <p>
* A non-AMD browser application (discouraged) might do something like this:
* <pre>
* var xxxSvc = new LtiEnrollmentClasses.XxxApi(); // Allocate the API class we're going to use.
* var yyy = new LtiEnrollmentClasses.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* </p>
* @module index
* @version 2.0.0
*/
export {
    /**
     * The ApiClient constructor.
     * @property {module:ApiClient}
     */
    ApiClient,

    /**
     * The ArrayOfAcademicProgramsItem model constructor.
     * @property {module:model/ArrayOfAcademicProgramsItem}
     */
    ArrayOfAcademicProgramsItem,

    /**
     * The Error model constructor.
     * @property {module:model/Error}
     */
    Error,

    /**
     * The EventCalendarGrades model constructor.
     * @property {module:model/EventCalendarGrades}
     */
    EventCalendarGrades,

    /**
     * The FormativeActions model constructor.
     * @property {module:model/FormativeActions}
     */
    FormativeActions,

    /**
     * The FormativeActionsPerPeriod model constructor.
     * @property {module:model/FormativeActionsPerPeriod}
     */
    FormativeActionsPerPeriod,

    /**
    * The DownloadsApi service constructor.
    * @property {module:api/DownloadsApi}
    */
    DownloadsApi,

    /**
    * The EnrollmentActionsApi service constructor.
    * @property {module:api/EnrollmentActionsApi}
    */
    EnrollmentActionsApi,

    /**
    * The EventDetailApi service constructor.
    * @property {module:api/EventDetailApi}
    */
    EventDetailApi,

    /**
    * The EventsApi service constructor.
    * @property {module:api/EventsApi}
    */
    EventsApi,

    /**
    * The GradesApi service constructor.
    * @property {module:api/GradesApi}
    */
    GradesApi
};
