import { h } from "preact";
import './timeline-styles.scss'
import Message from './Message'
import { ButtonGhost } from '../../../_common/components/buttons/buttons'
import ModalNewMessage from './ModalNewMessage'
import { useState } from 'preact/hooks'
import { useSelector } from 'react-redux'
import { t } from '../../../_i18n'

export default function Timeline () {
  const [openModal, setOpenModal] = useState(false)
  const messages = useSelector(state => state.fct.messages)

  return (
    <div className='fct-timeline'>
      <p className='font-32'>{t('Comentarios')}</p>
      <ModalNewMessage visible={openModal} onClose={() => setOpenModal(false)} />
      <ButtonGhost className='m-top button' onClick={() => setOpenModal(true)}>{t('Añadir comentario nuevo')}</ButtonGhost>
      {messages.map(({ id, type, content, message_direction, send_at, attached_docs }) => <Message id={id} type={type} title={type === 'event' ? content : message_direction === 'received' ? t('Gestor de FCT') : t('Yo')} date={send_at} body={type === 'message' && content}  attached={attached_docs} />)}
    </div>
  )
}
