import React, { h } from "preact";
import {connect} from "react-redux";
import {t} from "../../_i18n";
import {AppPage} from "../../_common/components/app-page";
import {C, MobileBackgroundContainer} from "../../_common/layout";
import ProfileData from "../components/profileData";
import DocumentData from "../components/documentData";
import AccessData from "../components/accessData";
import Notifications from "../components/notifications";
import {fetchDocument, fetchFixture, fetchStudent} from "../../_rx/student/actions";
import {useEffect} from "preact/hooks";
import {formatPhoneByThree} from "../../utils";
import {useHistory} from "react-router-dom";
import getLangByShopId from "../../_common/utils/getLang";

const Student = ({fetchStudent, fetchFixture, fetchDocument, studentId, shopId, student, fixtures, document}) => {
    const expiresDate = document && document.expires_at && `${document.expires_at.getDate()}-${document.expires_at.getMonth() + 1}-${document.expires_at.getFullYear()}`
    const documentReference = document && document.reference.match(/[a-zA-Z]+|[0-9]+(?:\.[0-9]+|)/g);
    const documentNumber = documentReference && `${documentReference[0]} ${documentReference[1]}`
    const neeText = student?.data.nee?.name ? student.data.nee.name : t('Ninguna')
    const documentExpired = document && document.expired && document.expires_at
    const history = useHistory()

    useEffect(() => {
        const shopLang =  getLangByShopId(shopId)

        fetchStudent(studentId)
        if (!fixtures) fetchFixture(shopLang)
        if (!document) fetchDocument(studentId)

    }, [])

    return (
        <C className={'max-width'}>
                <div className='p-left-half p-right-half sm-no-p-right sm-no-p-left'>
                    <div className="back breadcrumb-item cursor-pointer d-none sm-d-block" onClick={() => history.goBack()}>
                        ← {t('Volver')}
                    </div>
                    <h1 className='hero'>{t('Configuración de la cuenta')}</h1>
                </div>
                {student && fixtures && document
                    ? <MobileBackgroundContainer>
                        <ProfileData
                            fullName={student.data.full_name}
                            address={student.address.full_address}
                            phone={formatPhoneByThree(student.address.phone)}
                            nickname={student.data.nickname}
                            nee={neeText}
                            linkAddress='/student/address'
                            linkTelephone='/student/phone'
                            linkNee='/student/nee'
                            linkNickname='/student/nickname'
                        />
                        <hr className="separator-xs d-block sm-d-none"/>
                        <DocumentData
                            isExpired={documentExpired}
                            documentType={document.document_type.name}
                            documentNumber={documentNumber}
                            valid={expiresDate}
                            updateButton='/student/id-document'
                        />
                        <hr className="separator-xs d-block sm-d-none"/>
                        <AccessData
                            email={student.data.email}
                            changePasswordLink='/student/password'
                        />
                        <hr className="separator-xs d-block sm-d-none"/>
                        <Notifications
                            buttonLink='/notifications'
                        />
                    </MobileBackgroundContainer>
                    : <div />
                }

        </C>
    )
}

const StudentViewContainer = connect(
    ({auth, student, status, fixtures, student_document})=>({
        studentId: auth.loggedUser.id,
        shopId: status.student_meta.shop_id,
        document: student_document,
        student,
        fixtures
    }), {
        fetchStudent,
        fetchFixture,
        fetchDocument,
    })(Student);

export default ({history}) =>
    <AppPage
        name="student page"
        className="max-width">
        <StudentViewContainer history={history}/>
    </AppPage>
