/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CompanyInfo from './CompanyInfo';
import Exemption from './Exemption';
import OtherDocs from './OtherDocs';
import StudentInfo from './StudentInfo';

/**
 * The DocumentResponseBlocks model module.
 * @module model/DocumentResponseBlocks
 * @version 1.0.0
 */
class DocumentResponseBlocks {
    /**
     * Constructs a new <code>DocumentResponseBlocks</code>.
     * @alias module:model/DocumentResponseBlocks
     */
    constructor() { 
        
        DocumentResponseBlocks.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>DocumentResponseBlocks</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DocumentResponseBlocks} obj Optional instance to populate.
     * @return {module:model/DocumentResponseBlocks} The populated <code>DocumentResponseBlocks</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DocumentResponseBlocks();

            if (data.hasOwnProperty('student_info')) {
                obj['student_info'] = StudentInfo.constructFromObject(data['student_info']);
            }
            if (data.hasOwnProperty('company_info')) {
                obj['company_info'] = CompanyInfo.constructFromObject(data['company_info']);
            }
            if (data.hasOwnProperty('exemption')) {
                obj['exemption'] = Exemption.constructFromObject(data['exemption']);
            }
            if (data.hasOwnProperty('other_docs')) {
                obj['other_docs'] = OtherDocs.constructFromObject(data['other_docs']);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/StudentInfo} student_info
 */
DocumentResponseBlocks.prototype['student_info'] = undefined;

/**
 * @member {module:model/CompanyInfo} company_info
 */
DocumentResponseBlocks.prototype['company_info'] = undefined;

/**
 * @member {module:model/Exemption} exemption
 */
DocumentResponseBlocks.prototype['exemption'] = undefined;

/**
 * @member {module:model/OtherDocs} other_docs
 */
DocumentResponseBlocks.prototype['other_docs'] = undefined;






export default DocumentResponseBlocks;

