import { Fragment, h } from "preact";
import ModalSizeGuide from './ModalSizeGuide'
import { useState } from 'preact/compat'
import { FormFieldSelect } from '../../../_common/forms/components/components'
import { t } from '../../../_i18n'



const sizes_generic = [
  {key: 'XS', name: 'XS'},
  {key: 'S', name: 'S'},
  {key: 'M', name: 'M'},
  {key: 'L', name: 'L'},
  {key: 'XL', name: 'XL'},
  {key: '2XL', name: '2XL'},
  {key: '3XL', name: '3XL'},
]

const sizes_chaleco = [
  {key: 'S/M', name: 'S/M'},
  {key: 'L/XL', name: 'L/XL'},
  {key: '2XL/3XL', name: '2XL/3XL'},
]

export default function Uniform ({ values, onSaveDraft }) {
  const [showModal, setShowModal] = useState(false)

  return (
    <div>
      <h1 className='m-top-x4'>{t('Material para las prácticas')}</h1>
      <h2>{t('Uniforme')}</h2>
      <p>{t('Te proporcionaremos un uniforme para que puedas utilizar durante tu periodo de prácticas. El precio del uniforme está incluido en la matrícula. Una vez recibas el uniforme no podrás cambiarlo. Indica tu talla y valida la dirección de envío:')}</p>
      <p><span className='m-top font-color-149BAB font-bold t-underline cursor-pointer user-select-none' onClick={() => setShowModal(true)}>{t('Guía de tallas')}</span></p>
      <ModalSizeGuide onClose={() => setShowModal(false)} visible={showModal} values={values} />
      <div className='m-top'>
        {values.is_dress_uniform && (
          <Fragment>
            <div className='m-top'>
              <FormFieldSelect
                name='meta.size.size_one'
                label={<span dangerouslySetInnerHTML={{ __html: t('forro_polar_label') }} />}
                required
                options={sizes_generic}
                specificError={t('Este campo es obligatorio')}
                onChange={() => onSaveDraft()}
              />
            </div>
            <div className='m-top'>
              <FormFieldSelect
                name='meta.size.size_two'
                label={<span dangerouslySetInnerHTML={{ __html: t('polo_label') }} />}
                required
                options={sizes_generic}
                specificError={t('Este campo es obligatorio')}
                onChange={() => onSaveDraft()}
              />
            </div>
            <div className='m-top'>
              <FormFieldSelect
                name='meta.size.size_three'
                label={<span dangerouslySetInnerHTML={{__html: t('pantalon_label')}}/>}
                required
                options={sizes_generic}
                specificError={t('Este campo es obligatorio')}
                onChange={() => onSaveDraft()}
              />
            </div>
            <div className='m-top'>
              <FormFieldSelect
                name='meta.size.size_four'
                label={<span dangerouslySetInnerHTML={{__html: t('chaleco_label')}}/>}
                required
                options={sizes_chaleco}
                specificError={t('Este campo es obligatorio')}
                onChange={() => onSaveDraft()}
              />
            </div>
          </Fragment>
        )}
        { values.is_dress_pijama && (
          <div className='m-top'>
            <FormFieldSelect
              name='meta.size.size_one'
              required
              label={<span dangerouslySetInnerHTML={{__html: t('pijama_label')}}/>}
              options={sizes_generic}
              specificError={t('Este campo es obligatorio')}
              onChange={() => onSaveDraft()}
            />
          </div>
        )}
        { values.is_dress_coat && (
          <div className='m-top'>
            <FormFieldSelect
              name='meta.size.size_one'
              label={<span dangerouslySetInnerHTML={{__html: t('bata_sanitaria_label')}}/>}
              required
              options={sizes_generic}
              specificError={t('Este campo es obligatorio')}
              onChange={() => onSaveDraft()}
            />
          </div>
        )}
      </div>
    </div>
  )
}
