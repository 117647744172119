import {fragment, h} from "preact";
import {AppPage} from '../_common/components/app-page'
import {C, F} from '../_common/layout'
import {t} from '../_i18n'
import {Select} from '../_common/forms/components/components'
import {useState} from 'preact/hooks'
import CardHelp from '../_common/components/CardDashboard/CardHelp'
import {useSelector} from 'react-redux'
import {isSpanishUser} from '../_common/utils/getLang'
import {useHistory} from 'react-router'
import {LANG_ES} from '../constants'
import AlertBox from "../_common/components/AlertBox/AlertBox";

const isSameFormativeZone = (formativeCicles) => {
    let formativeZone = formativeCicles[0].formative_zone
    formativeCicles.forEach(cicle => {
        if (formativeZone !== cicle.formative_zone) formativeZone = false
    })
    return !!(formativeZone)
}

const academicInfo = [
    {
        title: `${t('Normativa de las clases presenciales')} (PDF)`,
        CATOLD: 'https://storage.googleapis.com/area-onliner/Normativa%20de%20asistencia%20a%20clases%20presenciales%20CAT%20mayo%202023.pdf',
        MADOLD:  null,
        CATNEW: 'https://soporte.ilerna.es/hc/es-es/articles/16280681566749-Normativa-de-las-clases-presenciales',
        MADNEW: 'https://soporte.ilerna.es/hc/es-es/articles/16280681566749-Normativa-de-las-clases-presenciales',
        fr: '#',
        it: '#'
    },
    {
        title: `${t('Normas de convivencia')} (PDF)`,
        CATOLD: 'https://storage.googleapis.com/area-onliner/Normas%20de%20convivencia%20Campus%20Virtual%20CAT%20mayo%202023.pdf',
        MADOLD: 'https://storage.googleapis.com/area-onliner/Normas%20de%20convivencia%20Campus%20Virtual%20MAD%20junio%202023.pdf',
        CATNEW: 'https://soporte.ilerna.es/hc/es-es/articles/19333086500125-Normas-de-convivencia',
        MADNEW: 'https://soporte.ilerna.es/hc/es-es/articles/19333086500125-Normas-de-convivencia',
        fr: '#',
        it: '#'
    },
    {
        title: `${t('Guía de orientación para el alumno')} | 24/25(PDF)`,
        CATOLD: 'https://storage.googleapis.com/area-onliner/GOA%20CAT%202023-2024.pdf',
        MADOLD: 'https://storage.googleapis.com/area-onliner/GOA%20MAD%202023-2024.pdf',
        CATNEW: 'https://soporte.ilerna.es/hc/es-es/articles/19339870903709-Gu%C3%ADa-de-Orientaci%C3%B3n-para-el-Alumnado',
        MADNEW: 'https://soporte.ilerna.es/hc/es-es/articles/18932255136029-Gu%C3%ADa-de-Orientaci%C3%B3n-para-el-Alumnado-Curso-2024-2025',
        fr: '#',
        it: '#'
    },
    {
        title: `${t('Calendario académico')} | 24/25 (PDF)`,
        CATOLD: 'https://drive.google.com/file/d/1GfmIp-u42AudNopQsswTmsz85LzHiJAv/view?usp=sharing',
        MADOLD: 'https://drive.google.com/file/d/1PBA2-Gdq-BLZJsiUnG0nqYmPHF7NKaMG/view?usp=sharing',
        CATNEW: 'https://drive.google.com/file/d/1GfmIp-u42AudNopQsswTmsz85LzHiJAv/view?usp=sharing',
        MADNEW: 'https://drive.google.com/file/d/1PBA2-Gdq-BLZJsiUnG0nqYmPHF7NKaMG/view?usp=sharing',
        fr: '#',
        it: '#'
    },
    // This next item was for 2nd semester, it doesn't apply for now (we don't have links)...
    // {
    //     title: `${t('Calendario académico')} | 24/25 (PDF)`,
    //     CATOLD: 'https://storage.googleapis.com/area-onliner/Calendario%202s2324%20Espa%C3%B1a.pdf',
    //     MADOLD: null,
    //     CATNEW: '#',
    //     MADNEW: null,
    //     fr: '#',
    //     it: '#'
    // },
]

const methodology = [
    {
        title: t('Metodología'),
        CATOLD: 'https://soporte.ilerna.es/hc/es-es/articles/13277356252445',
        MADOLD: 'https://soporte.ilerna.es/hc/es-es/articles/13277380662813',
        CATNEW: 'https://soporte.ilerna.es/hc/es-es/articles/17960874762013-Metodolog%C3%ADa',
        MADNEW: 'https://soporte.ilerna.es/hc/es-es/articles/17961758349341-Metodolog%C3%ADa',
        fr: '#',
        it: '#'
    },
    {
        title: t('Exámenes'),
        CATOLD: 'https://soporte.ilerna.es/hc/es-es/articles/13277459926429',
        MADOLD: 'https://soporte.ilerna.es/hc/es-es/articles/13277427152541',
        CATNEW: 'https://soporte.ilerna.es/hc/es-es/articles/17961435661213-Ex%C3%A1menes',
        MADNEW: 'https://soporte.ilerna.es/hc/es-es/articles/17961490973597-Ex%C3%A1menes',
        fr: '#',
        it: '#'
    },
    {
        title: t('Clases presenciales'),
        CATOLD: 'https://soporte.ilerna.es/hc/es-es/articles/13277515101981',
        MADOLD: 'https://soporte.ilerna.es/hc/es-es/articles/13277493769245',
        CATNEW: 'https://soporte.ilerna.es/hc/es-es/articles/17961603359517-Clases-presenciales',
        MADNEW: 'https://soporte.ilerna.es/hc/es-es/articles/17961693227933-Clases-presenciales',
        fr: '#',
        it: '#'
    },
    {
        title: t('Cálculo de notas'),
        CATOLD: 'https://soporte.ilerna.es/hc/es-es/articles/13282238585757',
        MADOLD: 'https://soporte.ilerna.es/hc/es-es/articles/13277504252317',
        CATNEW: 'https://soporte.ilerna.es/hc/es-es/articles/13282238585757',
        MADNEW: 'https://soporte.ilerna.es/hc/es-es/articles/13277504252317',
        fr: '#',
        it: '#'
    },
    {
        title: t('Solicitud de revisión de examen'),
        CATOLD: 'https://soporte.ilerna.es/hc/es-es/articles/13277510936605',
        MADOLD: 'https://soporte.ilerna.es/hc/es-es/articles/13277524857885',
        CATNEW: 'https://soporte.ilerna.es/hc/es-es/articles/13277510936605',
        MADNEW: 'https://soporte.ilerna.es/hc/es-es/articles/13277524857885',
        fr: '#',
        it: '#'
    },
    {
        title: t('Convocatorias'),
        CATOLD: 'https://soporte.ilerna.es/hc/es-es/articles/13277607590813',
        MADOLD: 'https://soporte.ilerna.es/hc/es-es/articles/13277610500893',
        CATNEW: 'https://soporte.ilerna.es/hc/es-es/articles/17962012332317-Convocatorias',
        MADNEW: 'https://soporte.ilerna.es/hc/es-es/articles/17962118687005-Convocatorias',
        fr: '#',
        it: '#'
    },
    {
        title: t('Trabajo final de ciclo'),
        CATOLD: 'https://soporte.ilerna.es/hc/es-es/articles/13277613471389',
        MADOLD: null,
        CATNEW: 'https://soporte.ilerna.es/hc/es-es/articles/13277613471389',
        MADNEW: null,
        fr: '#',
        it: '#'
    },
]

const campus = [
    {
        title: t('El campus de ILERNA Online'),
        CATOLD: 'https://youtu.be/i4P6Q-iGM_k',
        MADOLD: 'https://youtu.be/i4P6Q-iGM_k',
        CATNEW: 'https://youtu.be/i4P6Q-iGM_k',
        MADNEW: 'https://youtu.be/i4P6Q-iGM_k',
        fr: '#',
        it: '#'
    },
    /*{ title: t('Cómo acceder a una videotutoría'), CATOLD: null, MADOLD: null, fr: null, it: null },
    { title: t('Cómo navegar por la asignatura'), CATOLD: null, MADOLD: null, fr: null, it: null },
    { title: t('Cómo descargar el Material Didáctico y otros recursos'), CATOLD: 'https://storage.googleapis.com/area-onliner/C%C3%B3mo%20descargar%20el%20Material%20Did%C3%A1ctico%20y%20otros%20Recursos.pdf', MADOLD: 'https://storage.googleapis.com/area-onliner/C%C3%B3mo%20descargar%20el%20Material%20Did%C3%A1ctico%20y%20otros%20Recursos.pdf', fr: '#', it: '#' },
    { title: t('Cómo enviar un mensaje privado al profesor'), CATOLD: 'https://storage.googleapis.com/area-onliner/C%C3%B3mo%20enviar%20un%20mensaje%20privado%20al%20profesor.pdf', MADOLD: 'https://storage.googleapis.com/area-onliner/C%C3%B3mo%20enviar%20un%20mensaje%20privado%20al%20profesor.pdf', fr: '#', it: '#' },
    { title: t('Cómo participar en los foros'), CATOLD: 'https://storage.googleapis.com/area-onliner/C%C3%B3mo%20participar%20en%20los%20foros.pdf', MADOLD: 'https://storage.googleapis.com/area-onliner/C%C3%B3mo%20participar%20en%20los%20foros.pdf', fr: '#', it: '#' },
    { title: t('Cómo entregar una PAC autoevaluable'), CATOLD: 'https://storage.googleapis.com/area-onliner/C%C3%B3mo%20entregar%20una%20PAC%20Autoevaluable.pdf', MADOLD: 'https://storage.googleapis.com/area-onliner/C%C3%B3mo%20entregar%20una%20PAC%20Autoevaluable.pdf', fr: '#', it: '#' },
    { title: t('Cómo entregar una PAC de desarrollo'), CATOLD: 'https://storage.googleapis.com/area-onliner/C%C3%B3mo%20entregar%20una%20PAC%20de%20desarrollo.pdf', MADOLD: 'https://storage.googleapis.com/area-onliner/C%C3%B3mo%20entregar%20una%20PAC%20de%20desarrollo.pdf', fr: '#', it: '#' },*/
    {
        title: t('Mi Cuenta'),
        CATOLD: 'https://youtu.be/aaqjVln1dX4',
        MADOLD: 'https://youtu.be/aaqjVln1dX4',
        CATNEW: 'https://youtu.be/aaqjVln1dX4',
        MADNEW: 'https://youtu.be/aaqjVln1dX4',
        fr: '#',
        it: '#'
    },
    // { title: t('FCT'), CATOLD: 'https://storage.googleapis.com/area-onliner/Tutorial%20FCT.pdf', MADOLD: 'https://storage.googleapis.com/area-onliner/Tutorial%20FCT.pdf', fr: '#', it: '#' },
]

const fct = [
    {
        title: t('Requisitos Formación en Centros de Trabajo'),
        CATOLD: 'https://soporte.ilerna.es/hc/es-es/articles/11547333260573',
        MADOLD: 'https://soporte.ilerna.es/hc/es-es/articles/11548454656029',
        CATNEW: 'https://soporte.ilerna.es/hc/es-es/articles/16319986715037-Normativa-Formaci%C3%B3n-en-Centros-de-Trabajo-MAD',
        MADNEW: 'https://soporte.ilerna.es/hc/es-es/articles/16319986715037-Normativa-Formaci%C3%B3n-en-Centros-de-Trabajo-MAD',
        fr: '#',
        it: '#'
    }
]

const secretary = [
    {
        title: t('Formalización de matrícula'),
        CATOLD: 'https://soporte.ilerna.es/hc/es-es/articles/11905018188317',
        MADOLD: 'https://soporte.ilerna.es/hc/es-es/articles/11905055261853',
        CATNEW: 'https://soporte.ilerna.es/hc/es-es/articles/11905018188317',
        MADNEW: 'https://soporte.ilerna.es/hc/es-es/articles/11905055261853',
        fr: '#',
        it: '#'
    },
    {
        title: t('Convalidaciones'),
        CATOLD: 'https://soporte.ilerna.es/hc/es-es/articles/11906562209437',
        MADOLD: 'https://soporte.ilerna.es/hc/es-es/articles/11906594935453',
        CATNEW: 'https://soporte.ilerna.es/hc/es-es/articles/17961885533725-Convalidaciones',
        MADNEW: 'https://soporte.ilerna.es/hc/es-es/articles/17961953980317-Convalidaciones',
        fr: '#',
        it: '#'
    },
    {
        title: t('Certificados'),
        CATOLD: 'https://soporte.ilerna.es/hc/es-es/articles/11904889593629',
        MADOLD: 'https://soporte.ilerna.es/hc/es-es/articles/11904889593629',
        CATNEW: 'https://soporte.ilerna.es/hc/es-es/articles/11904889593629',
        MADNEW: 'https://soporte.ilerna.es/hc/es-es/articles/11904889593629',
        fr: '#',
        it: '#'
    },
    {
        title: t('Solicitud de título'),
        CATOLD: 'https://soporte.ilerna.es/hc/es-es/articles/11544516664349',
        MADOLD: 'https://soporte.ilerna.es/hc/es-es/articles/11544563152029',
        CATNEW: 'https://soporte.ilerna.es/hc/es-es/articles/11544516664349',
        MADNEW: 'https://soporte.ilerna.es/hc/es-es/articles/11544563152029',
        fr: '#',
        it: '#'
    },
]

const Link = ({to, title}) => <div className='m-top-half'><a href={to} target='_blank'
                                                             className='font-color-149BAB'>{title}</a></div>

const printLinks = (links, formativeZone, lang) => {
    if (lang === LANG_ES) return links.map(item => (item[formativeZone]) &&
        <Link title={item.title} to={item[formativeZone]}/>)
    else links.map(item => (item[lang]) && <Link title={item.title} to={item[lang]}/>)
}

export default function OnlineArea() {
    const {student_meta, lang} = useSelector(state => ({
        student_meta: state.status.student_meta,
        lang: state.lang
    }))
    const history = useHistory()
    const academicCicles = student_meta.coursing_academic_programs?.map(cicle => ({
        name: cicle.title,
        key: cicle.id,
        formative_zone: cicle.formative_zone,
        formative_zone_code: cicle.formative_zone_code
    }))
    const showMultipleCicles = academicCicles.length > 1 && !isSameFormativeZone(academicCicles)
    const [cicle, setCicle] = useState(academicCicles[0].key)
    const formativeZone = academicCicles.find(academicProgram => academicProgram.key === parseInt(cicle)).formative_zone
    const formativeZonePlan = academicCicles.find(academicProgram => academicProgram.key === parseInt(cicle)).formative_zone_code.includes('2425') ? 'NEW' : 'OLD'

    const handleCicleSelect = (e) => {
        if (e.target.value) setCicle(e.target.value)
    }

    return (
        <AppPage
            name="OnlineArea"
            title={t('Área Onliner')}
            back={history.goBack}
            className="max-width-980"
        >
            <F className="max-width-980">
                <C>
                    {showMultipleCicles && (
                        <fragment>
                            <div
                                className='font-18'>{t('Estás estudiando ciclos formativos que están sujetos a diferente normativa educativa.')}</div>
                            <div className='font-18 m-top-half'>{t('Selecciona el ciclo que desees consultar')}:</div>
                            <div className='m-top'>
                                <Select options={academicCicles} value={cicle} onChange={handleCicleSelect} required/>
                            </div>
                        </fragment>
                    )}
                    <div className='m-top-x2'>
                        <h2 className='font-32'>{t('Información Académica')}</h2>
                        {printLinks(academicInfo, formativeZone+formativeZonePlan, lang)}
                    </div>
                    <div className='m-top-x4'>
                        <h2 className='font-32'>{t('Metodología, evaluación y pruebas finales')}</h2>
                        {printLinks(methodology, formativeZone+formativeZonePlan, lang)}
                    </div>
                    <div className='m-top-x4'>
                        <h2 className='font-32'>{t('El campus virtual')}</h2>
                        {printLinks(campus, formativeZone+formativeZonePlan, lang)}
                    </div>
                    <div className='m-top-x4'>
                        <h2 className='font-32'>{t('Formación en centros de trabajo')}</h2>
                        {printLinks(fct, formativeZone+formativeZonePlan, lang)}
                    </div>
                    <div className='m-top-x4'>
                        <h2 className='font-32'>{t('Secretaría académica')}</h2>
                        {printLinks(secretary, formativeZone+formativeZonePlan, lang)}
                    </div>
                    {/*{formativeZonePlan === 'NEW' &&*/}
                    {/*    <div className='m-top-x4'>*/}
                    {/*        <AlertBox type='warning'>*/}
                    {/*            {t('onliner_area_comming_soon')}*/}
                    {/*        </AlertBox>*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {isSpanishUser(student_meta.shop_id) &&
                        <div className='m-top-x4 fit-content'>
                            <CardHelp/>
                        </div>
                    }
                </C>
            </F>
        </AppPage>
    )
}
