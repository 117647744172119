/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import DefaultApiResponse from '../model/DefaultApiResponse';
import DocumentMetadata from '../model/DocumentMetadata';

/**
* DocumentMeta service.
* @module api/DocumentMetaApi
* @version 1.0.0
*/
export default class DocumentMetaApi {

    /**
    * Constructs a new DocumentMetaApi. 
    * @alias module:api/DocumentMetaApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Obtener metadatos de documentos por ID
     * Devuelve los metadatos de documentos para un ID específico.
     * @param {Number} contractId ID del documento
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DocumentMetadata} and HTTP response
     */
    apiV1MyaccountDocumentMetaContractIdGetWithHttpInfo(contractId) {
      let postBody = null;
      // verify the required parameter 'contractId' is set
      if (contractId === undefined || contractId === null) {
        throw new Error("Missing the required parameter 'contractId' when calling apiV1MyaccountDocumentMetaContractIdGet");
      }

      let pathParams = {
        'contract_id': contractId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = DocumentMetadata;
      return this.apiClient.callApi(
        '/api/v1/myaccount/document_meta/{contract_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Obtener metadatos de documentos por ID
     * Devuelve los metadatos de documentos para un ID específico.
     * @param {Number} contractId ID del documento
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DocumentMetadata}
     */
    apiV1MyaccountDocumentMetaContractIdGet(contractId) {
      return this.apiV1MyaccountDocumentMetaContractIdGetWithHttpInfo(contractId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Actualizar metadatos de documentos
     * Actualiza los metadatos de documentos.
     * @param {Number} contractId ID del documento
     * @param {module:model/DocumentMetadata} documentMetadata 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DefaultApiResponse} and HTTP response
     */
    apiV1MyaccountDocumentMetaContractIdPostWithHttpInfo(contractId, documentMetadata) {
      let postBody = documentMetadata;
      // verify the required parameter 'contractId' is set
      if (contractId === undefined || contractId === null) {
        throw new Error("Missing the required parameter 'contractId' when calling apiV1MyaccountDocumentMetaContractIdPost");
      }
      // verify the required parameter 'documentMetadata' is set
      if (documentMetadata === undefined || documentMetadata === null) {
        throw new Error("Missing the required parameter 'documentMetadata' when calling apiV1MyaccountDocumentMetaContractIdPost");
      }

      let pathParams = {
        'contract_id': contractId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DefaultApiResponse;
      return this.apiClient.callApi(
        '/api/v1/myaccount/document_meta/{contract_id}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Actualizar metadatos de documentos
     * Actualiza los metadatos de documentos.
     * @param {Number} contractId ID del documento
     * @param {module:model/DocumentMetadata} documentMetadata 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DefaultApiResponse}
     */
    apiV1MyaccountDocumentMetaContractIdPost(contractId, documentMetadata) {
      return this.apiV1MyaccountDocumentMetaContractIdPostWithHttpInfo(contractId, documentMetadata)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
