import React, { h } from "preact";
import {t} from "../../_i18n";
import {Card} from "../../_common/layout";
import {ButtonGhost} from "../../_common/components/buttons/buttons";
import {Section, TextLabel} from "./components";
import {Link} from "react-router-dom";

export default function ProfileData({fullName, address, phone, nickname, nee, linkAddress, linkTelephone, linkNee, linkNickname}) {

    return (
        <div className='p-left-half sm-no-p-left p-right-half sm-no-p-right'>
            <Card className='sm-p-x2-bottom '>
                <div className='font-24 font-medium m-top'>{fullName}</div>
                <Section className='m-x2-top'>
                    <TextLabel label={t('Dirección')} value={address}/>
                    <Link to={linkAddress}>
                        <ButtonGhost
                            className='m-top sm-no-m-top full-width sm-no-full-width'>{t('Cambiar')}</ButtonGhost>
                    </Link>
                </Section>
                <hr className="separator-xxs"/>
                <Section>
                    <TextLabel label={t('Teléfono')} value={phone}/>
                    <Link to={linkTelephone}>
                        <ButtonGhost
                            className='m-top sm-no-m-top full-width sm-no-full-width'>{t('Cambiar')}</ButtonGhost>
                    </Link>
                </Section>
                <hr className="separator-xxs"/>
                <Section>
                    <TextLabel label={t('Necesidades educativas especiales')} value={nee}/>
                    <Link to={linkNee}>
                        <ButtonGhost
                            className='m-top sm-no-m-top full-width sm-no-full-width'>{t('Cambiar')}</ButtonGhost>
                    </Link>
                </Section>
                <hr className="separator-xxs"/>
                <Section>
                    <TextLabel label={t('Nombre sentido')} value={nickname}/>
                    <Link to={linkNickname}>
                        <ButtonGhost
                            className='m-top sm-no-m-top full-width sm-no-full-width'>{t('Cambiar')}</ButtonGhost>
                    </Link>
                </Section>
            </Card>
        </div>
    )
}
