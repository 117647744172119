/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CandidateDocument from './CandidateDocument';
import CandidateLetter from './CandidateLetter';
import CandidateLink from './CandidateLink';

/**
 * The CandidateCandidate model module.
 * @module model/CandidateCandidate
 * @version v1
 */
class CandidateCandidate {
    /**
     * Constructs a new <code>CandidateCandidate</code>.
     * [P_A_21] [P_A_22]
     * @alias module:model/CandidateCandidate
     */
    constructor() { 
        
        CandidateCandidate.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CandidateCandidate</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CandidateCandidate} obj Optional instance to populate.
     * @return {module:model/CandidateCandidate} The populated <code>CandidateCandidate</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CandidateCandidate();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('has_filled_cv')) {
                obj['has_filled_cv'] = ApiClient.convertToType(data['has_filled_cv'], 'Boolean');
            }
            if (data.hasOwnProperty('document')) {
                obj['document'] = CandidateDocument.constructFromObject(data['document']);
            }
            if (data.hasOwnProperty('accept_privacy_policy')) {
                obj['accept_privacy_policy'] = ApiClient.convertToType(data['accept_privacy_policy'], 'Boolean');
            }
            if (data.hasOwnProperty('links')) {
                obj['links'] = ApiClient.convertToType(data['links'], [CandidateLink]);
            }
            if (data.hasOwnProperty('letters')) {
                obj['letters'] = ApiClient.convertToType(data['letters'], [CandidateLetter]);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
CandidateCandidate.prototype['id'] = undefined;

/**
 * @member {Boolean} has_filled_cv
 */
CandidateCandidate.prototype['has_filled_cv'] = undefined;

/**
 * @member {module:model/CandidateDocument} document
 */
CandidateCandidate.prototype['document'] = undefined;

/**
 * @member {Boolean} accept_privacy_policy
 */
CandidateCandidate.prototype['accept_privacy_policy'] = undefined;

/**
 * @member {Array.<module:model/CandidateLink>} links
 */
CandidateCandidate.prototype['links'] = undefined;

/**
 * @member {Array.<module:model/CandidateLetter>} letters
 */
CandidateCandidate.prototype['letters'] = undefined;






export default CandidateCandidate;

