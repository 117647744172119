/**
 * New Expedient
 * Expedient for Student Account
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ArrayOfFormativeActionsWithParent from '../model/ArrayOfFormativeActionsWithParent';
import Error from '../model/Error';
import FormativeAction from '../model/FormativeAction';

/**
* FormativeActions service.
* @module api/FormativeActionsApi
* @version 1.0
*/
export default class FormativeActionsApi {

    /**
    * Constructs a new FormativeActionsApi. 
    * @alias module:api/FormativeActionsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get Student FormativeAction children expedient data
     * @param {Number} id 
     * @param {Number} formativeActionId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ArrayOfFormativeActionsWithParent} and HTTP response
     */
    studentExpedientControllerGetFormativeActionChildrenExpedientWithHttpInfo(id, formativeActionId) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling studentExpedientControllerGetFormativeActionChildrenExpedient");
      }
      // verify the required parameter 'formativeActionId' is set
      if (formativeActionId === undefined || formativeActionId === null) {
        throw new Error("Missing the required parameter 'formativeActionId' when calling studentExpedientControllerGetFormativeActionChildrenExpedient");
      }

      let pathParams = {
        'id': id,
        'formativeActionId': formativeActionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['BearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ArrayOfFormativeActionsWithParent;
      return this.apiClient.callApi(
        '/account/students/{id}/expedient/formative-actions/{formativeActionId}/children', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get Student FormativeAction children expedient data
     * @param {Number} id 
     * @param {Number} formativeActionId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ArrayOfFormativeActionsWithParent}
     */
    studentExpedientControllerGetFormativeActionChildrenExpedient(id, formativeActionId) {
      return this.studentExpedientControllerGetFormativeActionChildrenExpedientWithHttpInfo(id, formativeActionId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get FormativeAction ExpedientData
     * @param {Number} id 
     * @param {Number} formativeActionId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/FormativeAction} and HTTP response
     */
    studentExpedientControllerGetFormativeActionExpedientWithHttpInfo(id, formativeActionId) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling studentExpedientControllerGetFormativeActionExpedient");
      }
      // verify the required parameter 'formativeActionId' is set
      if (formativeActionId === undefined || formativeActionId === null) {
        throw new Error("Missing the required parameter 'formativeActionId' when calling studentExpedientControllerGetFormativeActionExpedient");
      }

      let pathParams = {
        'id': id,
        'formativeActionId': formativeActionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['BearerAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = FormativeAction;
      return this.apiClient.callApi(
        '/account/students/{id}/expedient/formative-actions/{formativeActionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get FormativeAction ExpedientData
     * @param {Number} id 
     * @param {Number} formativeActionId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/FormativeAction}
     */
    studentExpedientControllerGetFormativeActionExpedient(id, formativeActionId) {
      return this.studentExpedientControllerGetFormativeActionExpedientWithHttpInfo(id, formativeActionId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
