/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import DocumentResponse from '../model/DocumentResponse';
import DownloadResponse from '../model/DownloadResponse';
import FileRemovedResponse from '../model/FileRemovedResponse';
import SuccessResponse from '../model/SuccessResponse';
import UploadResponse from '../model/UploadResponse';

/**
* Documents service.
* @module api/DocumentsApi
* @version 1.0.0
*/
export default class DocumentsApi {

    /**
    * Constructs a new DocumentsApi. 
    * @alias module:api/DocumentsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Subir documento del contrato
     * Sube un documento relacionado con un contrato específico.
     * @param {Number} contractId ID del contrato
     * @param {Object} opts Optional parameters
     * @param {File} opts.file 
     * @param {String} opts.fileName 
     * @param {module:model/String} opts.documentType 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UploadResponse} and HTTP response
     */
    apiV1MyaccountDocumentContractIdPostWithHttpInfo(contractId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'contractId' is set
      if (contractId === undefined || contractId === null) {
        throw new Error("Missing the required parameter 'contractId' when calling apiV1MyaccountDocumentContractIdPost");
      }

      let pathParams = {
        'contract_id': contractId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': opts['file'],
        'file_name': opts['fileName'],
        'document_type': opts['documentType']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = UploadResponse;
      return this.apiClient.callApi(
        '/api/v1/myaccount/document/{contract_id}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Subir documento del contrato
     * Sube un documento relacionado con un contrato específico.
     * @param {Number} contractId ID del contrato
     * @param {Object} opts Optional parameters
     * @param {File} opts.file 
     * @param {String} opts.fileName 
     * @param {module:model/String} opts.documentType 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UploadResponse}
     */
    apiV1MyaccountDocumentContractIdPost(contractId, opts) {
      return this.apiV1MyaccountDocumentContractIdPostWithHttpInfo(contractId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Elimina un documento
     * Elimina un documento previamente subido
     * @param {Number} documentId ID del documento a descargar
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/FileRemovedResponse} and HTTP response
     */
    apiV1MyaccountDocumentDeleteDocumentIdDeleteWithHttpInfo(documentId) {
      let postBody = null;
      // verify the required parameter 'documentId' is set
      if (documentId === undefined || documentId === null) {
        throw new Error("Missing the required parameter 'documentId' when calling apiV1MyaccountDocumentDeleteDocumentIdDelete");
      }

      let pathParams = {
        'document_id': documentId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = FileRemovedResponse;
      return this.apiClient.callApi(
        '/api/v1/myaccount/document_delete/{document_id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Elimina un documento
     * Elimina un documento previamente subido
     * @param {Number} documentId ID del documento a descargar
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/FileRemovedResponse}
     */
    apiV1MyaccountDocumentDeleteDocumentIdDelete(documentId) {
      return this.apiV1MyaccountDocumentDeleteDocumentIdDeleteWithHttpInfo(documentId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Descargar documento
     * Descarga un documento almacenado según su ID.
     * @param {Number} documentId ID del documento a descargar
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DownloadResponse} and HTTP response
     */
    apiV1MyaccountDocumentDocumentIdGetWithHttpInfo(documentId) {
      let postBody = null;
      // verify the required parameter 'documentId' is set
      if (documentId === undefined || documentId === null) {
        throw new Error("Missing the required parameter 'documentId' when calling apiV1MyaccountDocumentDocumentIdGet");
      }

      let pathParams = {
        'document_id': documentId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/octet-stream'];
      let returnType = DownloadResponse;
      return this.apiClient.callApi(
        '/api/v1/myaccount/document/{document_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Descargar documento
     * Descarga un documento almacenado según su ID.
     * @param {Number} documentId ID del documento a descargar
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DownloadResponse}
     */
    apiV1MyaccountDocumentDocumentIdGet(documentId) {
      return this.apiV1MyaccountDocumentDocumentIdGetWithHttpInfo(documentId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Actualizar el estado del documento a validando
     * Endpoint para actualizar el estado de un documento en Mi Cuenta.
     * @param {Number} documentId ID del documento a descargar
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SuccessResponse} and HTTP response
     */
    apiV1MyaccountDocumentDocumentIdPutWithHttpInfo(documentId) {
      let postBody = null;
      // verify the required parameter 'documentId' is set
      if (documentId === undefined || documentId === null) {
        throw new Error("Missing the required parameter 'documentId' when calling apiV1MyaccountDocumentDocumentIdPut");
      }

      let pathParams = {
        'document_id': documentId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = SuccessResponse;
      return this.apiClient.callApi(
        '/api/v1/myaccount/document/{document_id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Actualizar el estado del documento a validando
     * Endpoint para actualizar el estado de un documento en Mi Cuenta.
     * @param {Number} documentId ID del documento a descargar
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SuccessResponse}
     */
    apiV1MyaccountDocumentDocumentIdPut(documentId) {
      return this.apiV1MyaccountDocumentDocumentIdPutWithHttpInfo(documentId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Obtener documentos del contrato
     * Obtiene la información y estado de los documentos relacionados con un contrato específico.
     * @param {Number} contractId ID del contrato
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DocumentResponse} and HTTP response
     */
    apiV1MyaccountDocumentsContractIdGetWithHttpInfo(contractId) {
      let postBody = null;
      // verify the required parameter 'contractId' is set
      if (contractId === undefined || contractId === null) {
        throw new Error("Missing the required parameter 'contractId' when calling apiV1MyaccountDocumentsContractIdGet");
      }

      let pathParams = {
        'contract_id': contractId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = DocumentResponse;
      return this.apiClient.callApi(
        '/api/v1/myaccount/documents/{contract_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Obtener documentos del contrato
     * Obtiene la información y estado de los documentos relacionados con un contrato específico.
     * @param {Number} contractId ID del contrato
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DocumentResponse}
     */
    apiV1MyaccountDocumentsContractIdGet(contractId) {
      return this.apiV1MyaccountDocumentsContractIdGetWithHttpInfo(contractId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Actualizar documentos del contrato y marcar como validando
     * Actualiza los documentos relacionados con un contrato específico.
     * @param {Number} contractId ID del contrato
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DocumentResponse} and HTTP response
     */
    apiV1MyaccountDocumentsContractIdPutWithHttpInfo(contractId) {
      let postBody = null;
      // verify the required parameter 'contractId' is set
      if (contractId === undefined || contractId === null) {
        throw new Error("Missing the required parameter 'contractId' when calling apiV1MyaccountDocumentsContractIdPut");
      }

      let pathParams = {
        'contract_id': contractId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = DocumentResponse;
      return this.apiClient.callApi(
        '/api/v1/myaccount/documents/{contract_id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Actualizar documentos del contrato y marcar como validando
     * Actualiza los documentos relacionados con un contrato específico.
     * @param {Number} contractId ID del contrato
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DocumentResponse}
     */
    apiV1MyaccountDocumentsContractIdPut(contractId) {
      return this.apiV1MyaccountDocumentsContractIdPutWithHttpInfo(contractId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
