import { h } from "preact";
import './steper-styles.scss'
import Step from './Step/Step'
import { useSelector } from 'react-redux'
import {
  STATE_COMPANY_ASSIGNMENT,
  STATE_CONVENTION, STATE_DOCUMENTATION,
  STATE_EXEMPTION_100,
  STATE_FCT_END,
  STATE_FCT_STARTED
} from '../../constants'
import { Fragment } from 'react'
import { t } from '../../../_i18n'

const STATE_EXEMPTION_END = '5.3'

export default function Steper () {
  const state = useSelector(state => state.fct.state)
  const { screen } = useSelector(state => state.fct.screenState)

  return (
    <div className='fct-steper'>
      <Step title={t('Documentación')}
            active={state === STATE_DOCUMENTATION}
            done={state === STATE_COMPANY_ASSIGNMENT || state === STATE_CONVENTION || state === STATE_FCT_STARTED || state === STATE_EXEMPTION_100 || state === STATE_FCT_END}
      />
      { state !== STATE_EXEMPTION_100 && (
        <Fragment>
          <Step title={t('Asignación empresa')}
                active={state === STATE_COMPANY_ASSIGNMENT}
                done={state === STATE_CONVENTION || state === STATE_FCT_STARTED || state === STATE_FCT_END}
          />
          <Step title={t('Validación convenio')}
                active={state === STATE_CONVENTION}
                done={state === STATE_FCT_STARTED || state === STATE_FCT_END}
          />
          <Step title={t('Inicio de la formación')}
                active={state === STATE_FCT_STARTED}
                done={state === STATE_FCT_END}
          />
          <Step title='Fin FCT' done={state === STATE_FCT_END} />
        </Fragment>
      )}
      { state === STATE_EXEMPTION_100 && (
        <Fragment>
          <Step title='Exención en tramitación'
                active={state === STATE_EXEMPTION_100 && screen !== STATE_EXEMPTION_END}
                done={screen === STATE_EXEMPTION_END}
          />
          <Step title='Excención tramitada' done={screen === STATE_EXEMPTION_END} />
        </Fragment>

      )}
    </div>
  )
}
