import { h } from "preact";

import OrderHistory from "./pages/order-history";
import {AppPage} from "../_common/components/app-page";
import {t} from '../_i18n';

import './index.scss';


let HistorialMatriculas = () =>
    <AppPage
        name="historial-matriculas"
        title={t('Historial de matrículas')}
        back="/"
        class="max-width">
        <OrderHistory />
    </AppPage>
;

export default HistorialMatriculas;
