import {AddressApi, ProfileSetApi, StudentApi} from '../_api/ilerna-StudentAccount/src';
import City from "../_api/ilerna-StudentAccount/src/model/City";
import Country from "../_api/ilerna-StudentAccount/src/model/Country";


export default class StudentAccountService{

    constructor() {
        this._api = new StudentApi();
        this._api_address = new AddressApi();
        this._api_profile = new ProfileSetApi()

    }

    async show(id){
        const data = await this._api.show(id);
        this.transformStudentFromLoad(data);
        return data;
    }

    async update(data){
        await this.transformStudentToSave(data);
        return await this._api.update(data.data.id, data);
    }

    async getStudentShop(id){
        return await this._api.getStudentShop(id);
    }

    async getStudentData(id){
        const data = await this._api.getStudentData(id);
        this.transformStudentFromLoad(data);
        return data;
    }

    async getPresentStatus(id){
        return await this._api.getPresentAddress(id);
    }

    async setPresentStatus(id){
        return await this._api.setPresentAddress(id);
    }

    async getStudentCourseAccess(id){
        const data = await this._api.getStudentCourseAccess(id);
        // inyectamos las urls de los documentos para facilitar la vida a la vista
        data.urls = {};
        data.files.forEach(f=> data.urls[f.type.id] = micuentaenv.SIS_IMG_URL + `/${id}/documents/${f.id}`);
        if(data.document_type) data.document_type.id = data.document_type.id.toString();
        return data;
    }

    async getStudentCertifiedCourseAccess(id){
        return await this._api.getStudentCertifiedCourseAccess(id);
    }

    async getAllProfilesSets(id){
        return await this._api_profile.allProfileSets(id)
    }

    async setAllProfileData(id, body){
        const query = {content: body}
        return await this._api_profile.postAllProfileSets(id, query)
    }


    async updateCourseAccess(data) {
        data = {...data};
        delete data.urls;
        data.files = null;
        return await this._api.updateStudentCourseAccess(data.student_id, data);
    }

    async getStudentDocument(student_id) {
        const data = await this._api.getStudentIdentityDocument(student_id);
        this.transformDocumentFromLoad(data, student_id);
        return data;
    }

    async saveStudentDocument(document) {
        const documentTransformed = this.transformDocumentToSave(document);

        // tenemos que respetar los files existentes
        const data = await this._api.getStudentIdentityDocument(documentTransformed.student_id);
        documentTransformed.files = data.files;

        await this._api.updateStudentIdentityDocument(documentTransformed.student_id, documentTransformed);
    }

    async getStatus(id) {
        //truco has_signed_condition
        const status = await this._api.getStatus(id);
        status.student_meta.has_signed_conditions = false;
        return status;
    }

    async uploadDocument(studentId, file, doctype){
        return await this._api.uploadDocument(studentId, doctype, {file});
    }

    async uploadSignature(student_id, image, useOfImages, refuseCommercialInfo){
        return await this._api.studentSignatureControllerProcessSignature(student_id, {"inlineObject2":{ image, student_id, allow_use_images: useOfImages, refuse_commercial_info: refuseCommercialInfo}});
    }

    transformStudentFromLoad(data){
        if (!data.data.birth_city) {
            data.data.birth_city = new City();
        }
        if (!data.data.birth_country) {
            data.data.birth_country = new Country();
        }
        if(data.data.gender)
            data.data.gender.id = data.data.gender.id.toString();
        if(data.data.birth_country.id)
            data.data.birth_country.id = data.data.birth_country.id.toString();
        if(data.data.birth_city.id)
            data.data.birth_city.id = data.data.birth_city.id.toString();
    }

    async transformStudentToSave(data) {
        if (data.data.birth_country.id == 61) {
            data.data.birth_city_name = '';
        }
        if (data.address.city.state.country.id == 61) {
            data.address.city_name = '';
        } else {
            const country_id = data.address.city.state.country.id;
            data.address.city = await this.getDefaultCityByCountryId(country_id);
        }
        if (!data.data.birth_city.id) {
            const country_id = data.data.birth_country.id;
            data.data.birth_city = await this.getDefaultCityByCountryId(country_id);
        }
        if (!data.data.nee || !data.data.nee.id) {
            data.data.nee = {id:0};
        }
    }

    async getDefaultCityByCountryId(country_id) {
        const cities = await this._api_address.getCitiesByCountry(country_id);
        return cities[0];
    }

    async getStudentAcademicProgramCart(studentId){
        return await this._api.getStudentAcademicProgramCart(studentId);
    }

    async setAcademicTitlePlace(studentId, place){
        return await this._api.setAcademicTitleDeliveryPlace(studentId, place);
    }

    async getAcademicTitlePlace(studentId){
        return await this._api.getAcademicTitleDeliveryPlace(studentId);
        // return {place:'Madrid', set_at:'2020-10-07 00:00:00'};
    }

    async getAcademicProgramDocument(studentId){
        return await this._api.studentControllerGetStudentAcademicProgramDocument(studentId)
    }

    async getAcademicProgramDocumentTypes(studentId){
        return await this._api.studentControllerGetStudentAcademicProgramDocumentTypes(studentId)
    }

    async uploadAcademicProgramDocument(studentId, file, doctype){
        return await this._api.studentControllerUploadAcademicProgramDocument(studentId, doctype, {file});
    }

    async getCommercialInfo(id){
        return await this._api.studentControllerGetCommercialInfo(id);
    }

    async setCommercialInfo(id,refuse_commercial_info){
        return await this._api.studentControllerSetCommercialInfo(id, {"inlineObject3":{ refuse_commercial_info: refuse_commercial_info }});
    }

    transformDocumentFromLoad(data, userId) {
        if(!data) return;

        if(data.country)
            data.country.id = data.country.id.toString();

        // inyecatamos las urls de los documentos para facilitar la vida a la vista
        data.urls = {};
        data.files.forEach(f=> data.urls[f.type.id] = micuentaenv.SIS_IMG_URL + `/${userId}/documents/${f.id}`);

        if(data.document_type)
            data.document_type.id = data.document_type.id.toString();
    }

    formatDate(s) {
        return s && (new Date(s)).toISOString().substr(0, 10);
    }


    transformDocumentToSave(data) {
        if (data) {
            data = {...data};
            delete data.urls;

            // en el guardado del document no queremos guardar los files, no los mantenemos correctamente en la vista
            data.files = null;

            if (typeof data.expires_at !== 'string') {
                data.expires_at = this.formatDate(data.expires_at);
            }
        }
        return data;
    }



}
