import {Fragment, h} from "preact";
import {useHistory} from "react-router-dom";
import './card-dashboard-styles.scss'


export default function Card({title, content, to, external, inverse}) {
    const history = useHistory();

    const handleClick = () => {
        if (external) window.open(to, '_blank').focus()
        else history.push(to);
    }

    return (
        <div className={`card-dashboard ${inverse ? 'inverse' : ''}`} onClick={handleClick}>
            <div className='card-dashboard__header'>
                {title && <p>{title}</p>}
                <i className='far fa-arrow-right'/>
            </div>
            <div>
                {content && <div className='card-dashboard__content'>{content}</div>}
            </div>
        </div>
    )
}
