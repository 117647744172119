import React, { Fragment, Component, h} from "preact";
import {connect} from "react-redux";
import Modal from "../../../_common/components/Modal/index";
import {FR} from "../../../_common/layout";
import {t} from "../../../_i18n";
import {cancelExam, setCancelEnroll, getNextExams, getStatusExams} from "../../../_rx/enrollment/exams/actions";
import {ButtonGhost, ButtonPrimary} from '../../../_common/components/buttons/buttons';
import {toastService} from '../../../_services/toast-service';
import {useState} from 'preact/hooks';
import TextBox from '../../../_common/components/textBox/textBox';

function ModalCancel({ enrollToCancel, setCancelEnroll, studentId, getNextExams, getStatusExams }){
    const [loadingCancelSubmit, setLoadingCancelSubmit] = useState(false)
    const visible = !!enrollToCancel

    const closeModal = () => {
        setCancelEnroll(null)
    }

    const handleCancel = async () => {
        setLoadingCancelSubmit(true)
        const {error} = await cancelExam({
            studentId,
            eventId: enrollToCancel.dateSelected.event_id,
            formativeActionId: enrollToCancel.formative_action_id,
            eventCalendarId: enrollToCancel.dateSelected.event_calendar_id
        })
        if (error) {
            console.error(error)
            toastService.showToast({message:t('La cancelación de la inscripción no se ha podido realizar. Por favor, vuelve a intentarlo.'), type:'danger'});
        }
        else {
            await getStatusExams(studentId)
            await getNextExams(studentId)
            toastService.showToast({message:t('La inscripción se ha cancelado correctamente.'), type:'info'});
            setCancelEnroll(null)
        }
        setLoadingCancelSubmit(false)
    }

    return enrollToCancel && <Modal visible={visible} closeModal={closeModal}>
        <div>
            <FR className={'modal-title-padding row-2-el p-all p-x2-left p-x2-right'}>
                <div className='col-80'>
                    <p className="t-left font-18 no-margin">{t('Cancelar inscripción a examen')}</p>
                </div>
                <div className={'t-right col-20'}>
                    <a onClick={closeModal} className="modal-close-jobs modal-link"> <i className="fal fa-times font-20" /> </a>
                </div>
            </FR>
            <hr className="separator-xxs no-margin-top"/>
            <div className='p-x2-left p-x2-right p-top'>
                <div className='d-flex flex-column sm-no-flex-column'>
                    <TextBox className='full-width sm-fit-width'>
                        <div className='p-all-half d-flex flex-baseline-y'>
                            <i className="fal fa-calendar-alt m-l-half font-color-879CA1" />
                            <div className='m-l-half'>{enrollToCancel.dateSelected.formatted_day}</div>
                        </div>
                    </TextBox>
                    <TextBox className='sm-m-left m-top sm-no-m-top full-width sm-fit-width'>
                        <div className='p-all-half d-flex flex-baseline-y'>
                            <i className="fal fa-clock  m-l-half font-color-879CA1" />
                            <div className='m-l-half'>{enrollToCancel.dateSelected.start_date_hour} - {enrollToCancel.dateSelected.end_date_hour}</div>
                        </div>
                    </TextBox>
                </div>
                <div className='font-color-486268 m-top'>
                    <div className='font-medium'>{enrollToCancel.buildingSelected.city}</div>
                    <div>{enrollToCancel.buildingSelected.address}</div>
                </div>
                <hr className="separator-xs"/>
                <div dangerouslySetInnerHTML={{__html: t('exam_enrollment_cancel_text')}} className='font-18 m-x2-bottom'/>
                <div className='d-flex f-right m-x2-bottom flex-column sm-no-flex-column full-width sm-fit-width'>
                    <ButtonGhost onClick={closeModal}>{t('MANTENER INSCRIPCIÓN')}</ButtonGhost>
                    <ButtonPrimary className='discard t-no-upper sm-m-l m-top sm-no-m-top' onClick={handleCancel} disabled={loadingCancelSubmit}>{t('SÍ, CANCELAR INSCRIPCIÓN')}</ButtonPrimary>
                </div>
            </div>
        </div>
    </Modal>
}

ModalCancel = connect(
    ({enrollmentExams, auth})=>({
        studentId: auth.loggedUser.id,
        enrollToCancel: enrollmentExams.enrollToCancel
    }),
    {setCancelEnroll, getNextExams, getStatusExams})(ModalCancel);


export default ({...rest}) =>  <ModalCancel {...rest}/>
