import { datadogLogs } from '@datadog/browser-logs'

//if(window.location.hostname=='account.ilerna.com')
    datadogLogs.init({
        clientToken: 'pub3ca558ab8f7f93908769b8a821258e23',
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        sampleRate: 100,
        service:window.location.hostname,
        beforeSend: e =>{
            e.message = '{"user":"' + (window.loggedUser && window.loggedUser.id) + '"} ' + e.message;
        }
    });

import ApiClient from "./_api/ilerna-StudentAccount/src/ApiClient";
import JobsApiClient from "./_api/jobs/src/ApiClient";
import NoticeApiClient from "./_api/notice/src/ApiClient";
import ExpedientApiClient from "./_api/expedient/src/ApiClient"
import FctApiClient from "./_api/fct/src/ApiClient"
import ExamsApiClient from "./_api/exams/src/ApiClient"
import ClassesApiClient from "./_api/classes/src/ApiClient"

if (process.env.NODE_ENV !== 'production') require('preact/debug');

import { h, render } from 'preact';
import App from './app/app-component';
import config from './config';
import {globalCatch} from "./_services/global-catch";
import {toastService} from "./_services/toast-service";

// establece un catcher global
// para que se llame este catcher global hay que crear las async actions con
// globalCatch.catch(...)
globalCatch.setGlobalCatch(error=>{

    console.log('GlobalCatch', error);

    error = error.body || error;

    //errores de validacion de formulario, cada key del diccionario es un campo con un array de errores
    if(error.errors && Object.values(error.errors).some(v=>Array.isArray(v))){
        error = Object.values(error.errors).map(v => v.join(' ')).join(' ');
    }

    error = error.errors || error;
    error = error.error || error;
    error = error.message || error;
    error = error.code || error;
    error = error.msg || error;

    if(typeof error !== "string")
        error = JSON.stringify(error);

    toastService.showToast({message:error, type:'danger'});
});

config().then(()=> { // se carga primero la configuracion de env.json

    // ahora micuentaenv esta cargado

    ApiClient.instance.basePath = micuentaenv.STUDENT_ACCOUNT_API_basePath + '/account';
    ApiClient.instance.enableCookies = true;

    JobsApiClient.instance.basePath = micuentaenv.JOBS_API_basePath;
    JobsApiClient.instance.enableCookies = true;

    NoticeApiClient.instance.basePath = micuentaenv.Notice_BaseUrl;
    NoticeApiClient.instance.enableCookies = true;

    ExpedientApiClient.instance.basePath = micuentaenv.STUDENT_ACCOUNT_API_basePath;
    ExpedientApiClient.instance.enableCookies = true;

    FctApiClient.instance.basePath = micuentaenv.Fct_BasePath ;
    FctApiClient.instance.enableCookies = true;

    ExamsApiClient.instance.basePath = micuentaenv.STUDENT_ACCOUNT_API_basePath;
    ExamsApiClient.instance.enableCookies = true;

    ClassesApiClient.instance.basePath = micuentaenv.STUDENT_ACCOUNT_API_basePath;
    ClassesApiClient.instance.enableCookies = true;

    render(
        <App/>
        , document.getElementById('mi-cuenta-container'))

});



// Hot Module Replacement
module.hot && module.hot.accept();
