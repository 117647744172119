import {CF, E, R, RE} from "../../_common/layout";
import {t} from "../../_i18n";
import {
    RadioCheckbox, TextArea
} from "../../_common/forms/components/components";
import {Modal} from "../../_common/components/modal.tsx";
import React, {Component, Fragment, h} from "preact";

export class ModalEnroll extends Component{

    state = {answers:{}, letter: null, errors:{}}

    changeAnswer(question, answer){
        const answers = {...this.state.answers, [question.id]: answer};
        this.setState({answers});
    }

    onSubmit(){
        const emptyQuestions = this.props.offer.questions.filter(q=>!this.state.answers[q.id]);

        if(emptyQuestions.length){
            const errors = {}
            emptyQuestions.forEach(q=>errors[q.id]=true);
            this.setState({errors});
        }
        else{
            this.props.onEnroll(this.state)
        }

    }

    letterChange(letter){
        this.setState({letter:letter && letter.id});
    }

    render ({id, offer, candidate}){

        if(!candidate || !offer) return null;
        const isQuestionary = offer.questions.length >= 1

        return <Fragment>
            <Modal id={id} title={t('Inscripción a la oferta')} className="sm-no-fullscreen">
                <hr className="separator-xxs"/>
                <div className="in-modal-scroll sm-p-left sm-p-right">
                    {
                        isQuestionary && <Fragment>
                            <h3 className="m-bottom m-top">{t('Cuestionario de inscripción')}</h3>

                            {offer.questions.map((question,i) => <Fragment key={question.id}>
                                    <p>
                                        <span className="bold">{i+1}.</span> &nbsp;
                                        <span>{question.text} &nbsp;</span>
                                    </p>

                                    {question.type === 'closed' ?
                                        <div className="d-flex flex-wrap">{question.answers.map(answer =>
                                            <RadioCheckbox
                                                className="col-100"
                                                error={this.state.errors[question.id]}
                                                name={'q_'+question.id}
                                                key={answer.id} type="radio"
                                                defaultValue={this.state.answers[question.id]==answer.id}
                                                onChange={(checked)=> checked && this.changeAnswer(question, answer.id)}
                                                label={answer.text} />
                                        )}</div>:
                                        <TextArea required onChange={(event)=> this.changeAnswer(question, event.target.value)} rows={5} maxlength={4000}  />

                                    }
                                </Fragment>
                            )}

                            <hr className="separator-xxs"/>
                        </Fragment>
                    }


                    <h3 className="m-bottom">{t('Carta de presentación')}</h3>

                    <CF>
                        <RE><RadioCheckbox
                                name="letter" type="radio" label={t('No incluir carta')}
                                defaultValue={this.state.letter===null}
                                onChange={(checked)=>checked && this.letterChange(null)} /></RE>

                    {candidate.letters.map(letter =>
                        <RE><RadioCheckbox
                            className="col-100 md-col-20 nie"
                            name="letter" type="radio" label={letter.name}
                            defaultValue={this.state.letter===letter.id}
                            onChange={(checked)=>checked && this.letterChange(letter)} /></RE>)}
                    </CF>
                </div>
                <hr className="separator-xxs"/>
                <R className="flex-right d-none sm-d-flex p-right-half p-left-half">
                    <E>
                        <button onClick={()=>this.onSubmit()} className="btn btn-primary" type='button'>{t('Confirmar inscripción')}</button>
                    </E>
                </R>
                <R className="flex-right d-flex sm-d-none">
                    <E className="full-width">
                        <button onClick={()=>this.onSubmit()} className="btn btn-primary full-width" type='button'>{t('Confirmar inscripción')}</button>
                    </E>
                </R>
            </Modal>
        </Fragment>

    }
}
