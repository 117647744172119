/**
 * LTI Enrollment Classes
 * LTI Enroll Class for Students
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';
import EventCalendarGrades from '../model/EventCalendarGrades';

/**
* Grades service.
* @module api/GradesApi
* @version 2.0.0
*/
export default class GradesApi {

    /**
    * Constructs a new GradesApi. 
    * @alias module:api/GradesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get class attendance grades
     * @param {Number} studentId 
     * @param {Number} eventCalendarId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EventCalendarGrades} and HTTP response
     */
    classAttendanceControllerGetClassAttendanceGradeWithHttpInfo(studentId, eventCalendarId) {
      let postBody = null;
      // verify the required parameter 'studentId' is set
      if (studentId === undefined || studentId === null) {
        throw new Error("Missing the required parameter 'studentId' when calling classAttendanceControllerGetClassAttendanceGrade");
      }
      // verify the required parameter 'eventCalendarId' is set
      if (eventCalendarId === undefined || eventCalendarId === null) {
        throw new Error("Missing the required parameter 'eventCalendarId' when calling classAttendanceControllerGetClassAttendanceGrade");
      }

      let pathParams = {
        'student_id': studentId,
        'event_calendar_id': eventCalendarId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = EventCalendarGrades;
      return this.apiClient.callApi(
        '/lti/classes/students/{student_id}/event-calendar-id/{event_calendar_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get class attendance grades
     * @param {Number} studentId 
     * @param {Number} eventCalendarId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EventCalendarGrades}
     */
    classAttendanceControllerGetClassAttendanceGrade(studentId, eventCalendarId) {
      return this.classAttendanceControllerGetClassAttendanceGradeWithHttpInfo(studentId, eventCalendarId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
