import React, {Component, Fragment, h} from "preact";
import './textbox.scss'

const TextBox = ({children, icon, classNameText, className}) => <Fragment>
    <div className={`textbox-container ${className}`}>
        <div className={'textbox-icon'}>{icon}</div>
        <div className={`textbox-text ${classNameText}`}>{children}</div>
    </div>
</Fragment>


export default TextBox;
