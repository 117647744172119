/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The DocumentRequest model module.
 * @module model/DocumentRequest
 * @version 1.0.0
 */
class DocumentRequest {
    /**
     * Constructs a new <code>DocumentRequest</code>.
     * @alias module:model/DocumentRequest
     */
    constructor() { 
        
        DocumentRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>DocumentRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DocumentRequest} obj Optional instance to populate.
     * @return {module:model/DocumentRequest} The populated <code>DocumentRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DocumentRequest();

            if (data.hasOwnProperty('file')) {
                obj['file'] = ApiClient.convertToType(data['file'], File);
            }
            if (data.hasOwnProperty('file_name')) {
                obj['file_name'] = ApiClient.convertToType(data['file_name'], 'String');
            }
            if (data.hasOwnProperty('document_type')) {
                obj['document_type'] = ApiClient.convertToType(data['document_type'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {File} file
 */
DocumentRequest.prototype['file'] = undefined;

/**
 * @member {String} file_name
 */
DocumentRequest.prototype['file_name'] = undefined;

/**
 * @member {module:model/DocumentRequest.DocumentTypeEnum} document_type
 */
DocumentRequest.prototype['document_type'] = undefined;





/**
 * Allowed values for the <code>document_type</code> property.
 * @enum {String}
 * @readonly
 */
DocumentRequest['DocumentTypeEnum'] = {

    /**
     * value: "resume"
     * @const
     */
    "resume": "resume",

    /**
     * value: "no_sexual_offences"
     * @const
     */
    "no_sexual_offences": "no_sexual_offences",

    /**
     * value: "vaccination"
     * @const
     */
    "vaccination": "vaccination",

    /**
     * value: "census"
     * @const
     */
    "census": "census",

    /**
     * value: "company_details"
     * @const
     */
    "company_details": "company_details",

    /**
     * value: "exemption_request"
     * @const
     */
    "exemption_request": "exemption_request",

    /**
     * value: "work_life"
     * @const
     */
    "work_life": "work_life",

    /**
     * value: "task"
     * @const
     */
    "task": "task",

    /**
     * value: "weekly"
     * @const
     */
    "weekly": "weekly",

    /**
     * value: "final_evaluation"
     * @const
     */
    "final_evaluation": "final_evaluation",

    /**
     * value: "motivation"
     * @const
     */
    "motivation": "motivation"
};



export default DocumentRequest;

