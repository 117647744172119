import React, {Component, Fragment, h} from "preact";
import {AppPage} from "../../_common/components/app-page";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {t} from '../../_i18n';
import {CF, E, FR, R, RE} from "../../_common/layout";

import {
    fetchAcademicPrograms,
    fetchCandidate, fetchCandidateOffersCount, fetchCandidateOffersFilter,
    fetchContractTypes,
    fetchOffers,
    fetchSchedules,
    fetchStates
} from "../../_rx/jobs/actions";
import {Tabs} from "../components/tabs";
import {rndStr} from "../../_common/common";
import ModalFilter from "../components/modal_filter/modal_filter";
import {OffersList} from "../components/offers_list";
import Pagination from "../../_common/components/pagination";
import {OFFERS_LIMIT_PAGES} from "../../constants";
import AlertBox from "../../_common/components/AlertBox/AlertBox";
import jobFilterPrepare from '../functions/JobFilterPrepare';

let OffersView = class extends Component {

    id1 = rndStr();
    state ={
        filter:{},
        loading: false,
        is_applications : this.props.match.url.split('/')[2] === 'applications' || undefined,
        showModal: false,
        loadingNewOffers: false
    }

    async componentDidMount() {
        const status = 1;
        const page = this.props.match.params.page ? this.props.match.params.page : 1
        const jobFilter = this.props.jobFilter;
        const filter = jobFilterPrepare({
            jobFilter,
            statesApi: this.props.states,
            academicProgramsApi: this.props.academic_programs
        })
        if (!this.props.offers || !this.props.match.params.page){
            this.setState({loading: true});
            this.props.fetchOffers({
                applied: this.state.is_applications,
                status,
                limit: OFFERS_LIMIT_PAGES,
                page,
                ...filter
            })
                .finally(()=>this.setState({loading: false}));

        }
        if(!this.props.candidate) this.props.fetchCandidate();
        if(!this.props.schedules) this.props.fetchSchedules();
        if(!this.props.academic_programs_kn) this.props.fetchAcademicPrograms();
        if(!this.props.states_kn) this.props.fetchStates();
        if(!this.props.contract_types) this.props.fetchContractTypes();
        this.props.fetchCandidateOffersFilter({status: status});
        this.props.fetchCandidateOffersCount({status: status, applied: this.state.is_applications, ...filter});
    }

    async componentDidUpdate(previousProps, previousState, snapshot) {
        const page = this.props.match.params.page
        const prevPage = previousProps.match.params.page
        const status = 1;
        const jobFilter = this.props.jobFilter;
        if(page !== prevPage){
            await this.props.fetchOffers({
                status: status,
                applied: this.state.is_applications,
                limit: OFFERS_LIMIT_PAGES,
                page,
                ...jobFilterPrepare({
                    jobFilter,
                    statesApi: this.props.states,
                    academicProgramsApi: this.props.academic_programs}
                )});

                this.setState({loadingNewOffers: false})
        }

    }

    async setFilter(){
        const {match, history} = this.props
        this.setState({showModal: false})
        const page = this.props.match.params.page
        const jobFilter = this.props.jobFilter;
        const status = 1;
        await this.props.fetchOffers({
            status: status,
            applied: this.state.is_applications,
            limit: OFFERS_LIMIT_PAGES,
            page,
            ...jobFilterPrepare({
                jobFilter,
                statesApi: this.props.states,
                academicProgramsApi: this.props.academic_programs}
            )});
        history.push('/jobs/'+match.url.split('/')[2])

    }

    closeFilter = () => this.setState({showModal: false})

    async setNumOffers(objectToSend){
        const status = 1;
        await this.props.fetchCandidateOffersCount({status: status, limit: OFFERS_LIMIT_PAGES, applied: this.state.is_applications, ...objectToSend});
    }


    handlePagChanged = (newPage) => {
        const {match, history} = this.props
        const actualPage = parseInt(match.params.page)
        const isSamePage = (actualPage === newPage) || (isNaN(actualPage) && newPage === 1)
        if (isSamePage) return null

        history.push('/jobs/'+match.url.split('/')[2] +'/'+ newPage)
        this.setState({loadingNewOffers: true})

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    render({ offers, candidate, schedules, academic_programs, offersFilter, contract_types, states, match, offersCount, jobFilter}) {
        const jobFilterRdy = jobFilterPrepare({
            jobFilter,
            statesApi: this.props.states,
            academicProgramsApi: this.props.academic_programs}
        )
        return <CF className="max-width-980">

            {(!offers || !candidate || !schedules || !offersFilter || !offersCount || this.state.loading) ?
                <p className="t-center">
                    <em className="disabled-aspect-gray"> {t('Cargando')}. . . </em>
                </p> :
                <Fragment>
                    {!candidate.has_filled_cv && <AlertBox type='warning'>{t("Para poder inscribirte en las ofertas debes ")}<Link to={"/jobs/candidate"}>{t('adjuntar tu CV, o indicar tus perfiles de InfoJobs o LinkedIn.')}</Link></AlertBox>}

                    <Tabs candidate={candidate} />
                    <FR className="row-2-el m-bottom">
                        <E>
                            <span className="offer">{offers.pagination.total_results} {this.state.is_applications ? 'candidaturas' : 'ofertas'} </span>
                        </E>
                        <E className="t-right">
                            {offersFilter.show_general &&
                            <div className="cursor-pointer">
                                <span className='t-upper'  onClick={() => this.setState({showModal: true})}>{t('FILTRAR')}
                                {(jobFilterRdy !== null && Object.keys(jobFilterRdy).length !== 0) && <span className="m-l-half sm-m-l-half">{"(" + Object.values(jobFilterRdy).join(",").split(",").length + ")"}</span>}
                                <i className="fal fa-sliders-h m-l-half sm-m-l-half" /></span>
                                <ModalFilter
                                             academicPrograms={academic_programs}
                                             offersFilter={offersFilter}
                                             offersCount={offersCount}
                                             setNumOffers={(objectToSend) => this.setNumOffers(objectToSend)}
                                             states={states}
                                             filter={this.state.filter}
                                             showModal={this.state.showModal}
                                             setFilter={(filter) => this.setFilter(filter)}
                                             closeFilter={this.closeFilter}
                                />
                            </div>}
                        </E>
                    </FR>
                    <OffersList schedules={schedules} offers={offers} contract_types={contract_types} loadingOffers={this.state.loadingNewOffers}/>
                    <div className='m-x2-top'>
                        <Pagination
                            pageMax={offers.pagination.total_pages}
                            page={parseInt((match.params.page === undefined) ? 1 : match.params.page)}
                            onClick={(page) => this.handlePagChanged(page)}
                        />
                    </div>
                </Fragment>
            }
        </CF>;
    }
};


OffersView = connect(
    ({offers, candidate, offers_fixtures:{states, academic_programs, schedules, contract_types }, offersFilter, offersCount, jobFilter})=>({offers, candidate, states, academic_programs, contract_types, schedules, offersFilter, offersCount, jobFilter}),
    {fetchOffers, fetchCandidate, fetchSchedules, fetchAcademicPrograms, fetchStates, fetchContractTypes, fetchCandidateOffersFilter, fetchCandidateOffersCount})(OffersView);

const OffersPage = ({history, match}) =>
    <AppPage
        name="offers"
        title={t('Bolsa de trabajo')}
        subtitle=""
        className="max-width-980">
        <OffersView history={history} match={match}/>
    </AppPage>
;

export default OffersPage;
