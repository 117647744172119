/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Notice model module.
 * @module model/Notice
 * @version 1.0
 */
class Notice {
    /**
     * Constructs a new <code>Notice</code>.
     * @alias module:model/Notice
     */
    constructor() { 
        
        Notice.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Notice</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Notice} obj Optional instance to populate.
     * @return {module:model/Notice} The populated <code>Notice</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Notice();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('body')) {
                obj['body'] = ApiClient.convertToType(data['body'], 'String');
            }
            if (data.hasOwnProperty('programmed_at')) {
                obj['programmed_at'] = ApiClient.convertToType(data['programmed_at'], 'String');
            }
            if (data.hasOwnProperty('has_notification')) {
                obj['has_notification'] = ApiClient.convertToType(data['has_notification'], 'Boolean');
            }
            if (data.hasOwnProperty('has_email')) {
                obj['has_email'] = ApiClient.convertToType(data['has_email'], 'Boolean');
            }
            if (data.hasOwnProperty('notification_title')) {
                obj['notification_title'] = ApiClient.convertToType(data['notification_title'], 'String');
            }
            if (data.hasOwnProperty('notification_text')) {
                obj['notification_text'] = ApiClient.convertToType(data['notification_text'], 'String');
            }
            if (data.hasOwnProperty('executed_at')) {
                obj['executed_at'] = ApiClient.convertToType(data['executed_at'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('segment_operator')) {
                obj['segment_operator'] = ApiClient.convertToType(data['segment_operator'], 'String');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'String');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'String');
            }
        }
        return obj;
    }


}

/**
 * Class Notice
 * @member {Number} id
 */
Notice.prototype['id'] = undefined;

/**
 * Class Notice
 * @member {String} title
 */
Notice.prototype['title'] = undefined;

/**
 * Class Notice
 * @member {String} body
 */
Notice.prototype['body'] = undefined;

/**
 * Class Notice
 * @member {String} programmed_at
 */
Notice.prototype['programmed_at'] = undefined;

/**
 * Class Notice
 * @member {Boolean} has_notification
 */
Notice.prototype['has_notification'] = undefined;

/**
 * Class Notice
 * @member {Boolean} has_email
 */
Notice.prototype['has_email'] = undefined;

/**
 * Class Notice
 * @member {String} notification_title
 */
Notice.prototype['notification_title'] = undefined;

/**
 * Class Notice
 * @member {String} notification_text
 */
Notice.prototype['notification_text'] = undefined;

/**
 * Class Notice
 * @member {String} executed_at
 */
Notice.prototype['executed_at'] = undefined;

/**
 * Class Notice
 * @member {String} status
 */
Notice.prototype['status'] = undefined;

/**
 * Class Notice
 * @member {String} segment_operator
 */
Notice.prototype['segment_operator'] = undefined;

/**
 * Class Notice
 * @member {String} created_at
 */
Notice.prototype['created_at'] = undefined;

/**
 * Class Notice
 * @member {String} updated_at
 */
Notice.prototype['updated_at'] = undefined;






export default Notice;

