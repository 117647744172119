/**
 * LTI Enrollment Classes
 * LTI Enroll Class for Students
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';

/**
* Downloads service.
* @module api/DownloadsApi
* @version 2.0.0
*/
export default class DownloadsApi {

    /**
    * Constructs a new DownloadsApi. 
    * @alias module:api/DownloadsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * download class attendance certificate
     * @param {String} email 
     * @param {Object} opts Optional parameters
     * @param {String} opts.academicSection 
     * @param {String} opts.lang 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    enrollmentClassesControllerGetClassesCertificateWithHttpInfo(email, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'email' is set
      if (email === undefined || email === null) {
        throw new Error("Missing the required parameter 'email' when calling enrollmentClassesControllerGetClassesCertificate");
      }

      let pathParams = {
        'email': email
      };
      let queryParams = {
        'academic_section': opts['academicSection'],
        'lang': opts['lang']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/lti/enrollmentClasses/students/{email}/class-certificate', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * download class attendance certificate
     * @param {String} email 
     * @param {Object} opts Optional parameters
     * @param {String} opts.academicSection 
     * @param {String} opts.lang 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    enrollmentClassesControllerGetClassesCertificate(email, opts) {
      return this.enrollmentClassesControllerGetClassesCertificateWithHttpInfo(email, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * download classes join certificate
     * @param {String} email 
     * @param {Object} opts Optional parameters
     * @param {String} opts.academicSection 
     * @param {String} opts.lang 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    enrollmentClassesControllerGetClassesJoinCertificatesWithHttpInfo(email, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'email' is set
      if (email === undefined || email === null) {
        throw new Error("Missing the required parameter 'email' when calling enrollmentClassesControllerGetClassesJoinCertificates");
      }

      let pathParams = {
        'email': email
      };
      let queryParams = {
        'academic_section': opts['academicSection'],
        'lang': opts['lang']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/lti/enrollmentClasses/students/{email}/joined-certificate', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * download classes join certificate
     * @param {String} email 
     * @param {Object} opts Optional parameters
     * @param {String} opts.academicSection 
     * @param {String} opts.lang 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    enrollmentClassesControllerGetClassesJoinCertificates(email, opts) {
      return this.enrollmentClassesControllerGetClassesJoinCertificatesWithHttpInfo(email, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
