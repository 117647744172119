import React, { Fragment, Component, h} from "preact";
import {AppPage} from "../../../_common/components/app-page";
import {connect} from "react-redux";
import {Card, CF, FR} from "../../../_common/layout";
import {t} from "../../../_i18n";
import {useEffect, useState} from "preact/hooks";
import {fetchProfileSettings, sendProfileSettings} from "../../../_rx/profileSettings/actions";
import {fetchCommercialInfo, saveCommercialInfo} from "../../../_rx/student/actions";
import {ButtonLink, ButtonPrimary} from "../../../_common/components/buttons/buttons";
import NotificationRow from "./NotificationRow";
import ButtonSpinner from "../../../_common/components/buttons/buttonSpinner/buttonSpinner";
import {Checkbox} from "../../../_common/forms/components/Checkbox/Checkbox";

const initialStateData = {
    email: false,
    app: false,
}

function NotificationView({fetchProfileSettings, studentId, sendProfileSettings, fetchCommercialInfo, saveCommercialInfo, history}) {
    const [academicData, setAcademicData] = useState(initialStateData)
    const [commercialInfoData, setCommercialInfo] = useState({commercialInfo: false})
    const [loaded, setLoaded] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    /*const [promo, setPromo] = useState(initialStateData)
    //const [job, setJob] = useState(initialStateData)
    //const [jobUpdate, setJobUpdate] = useState(initialStateData)*/

    useEffect(async () => {
        const profileSettings = await fetchProfileSettings(studentId)
        const commercialInfo = await fetchCommercialInfo(studentId)
        setLoaded(true)
        setAcademicData({email: profileSettings.ACADEMIC.email, app: profileSettings.ACADEMIC.app})
        setCommercialInfo({commercialInfo: commercialInfo.refuse_commercial_info})
    //    setPromo({email: profileSettings.PROMO.email, app: profileSettings.PROMO.app})
    //    setJob({email: profileSettings.JOB.email, app: profileSettings.JOB.app})
    //    setJobUpdate({email: profileSettings.JOB_UPDATE.email, app: profileSettings.JOB_UPDATE.app})
    }, [])


    const handleSave = async () => {
        setShowSpinner(true)
        const saveData = {
            ACADEMIC: {...academicData},
        //    PROMO: {...promo},
        //    JOB: {...job},
        //    JOB_UPDATE: {...jobUpdate}
        }

        await sendProfileSettings(studentId, saveData);
        await saveCommercialInfo(studentId, commercialInfoData.commercialInfo);
        history.goBack()
    }

   return (loaded && <CF className="max-width">
            <Card>
                <h1 className='m-bottom'>{t('Configurar comunicaciones')}</h1>

                <FR>
                    <h2 className='col-100'>{t('academic_info')}</h2>
                    {academicData.email}
                    <span className='col-100 flex-column font-14 translucent sm-d-block d-none m-bottom'>{t('academic_info_subtitle')}</span>
                    <span className='col-100 flex-column m-bottom font-medium'>{t('academic_info_subtext')}</span>

                    <div className='col-100 d-flex flex-center-y m-bottom'>
                        <Checkbox 
                        value={academicData.email} 
                        labelIsClicked={true}
                        label={
                            <span>
                                {t('academic_info_checkbox_mail')}
                            </span>
                        }
                        onChange={value => setAcademicData({...academicData, email: value})} />
                    </div>
                    <div className='col-100 d-flex flex-center-y m-bottom'>
                        <Checkbox 
                        value={academicData.app} 
                        labelIsClicked={true}
                        label={
                            <span>
                                {t('academic_info_checkbox_app')}
                            </span>
                        }
                        onChange={value => setAcademicData({...academicData, app: value})} />
                    </div>
                </FR>

                <hr className="separator-xxs"/>

                <FR>
                    <h2 className='col-100'>{t('commercial_info')}</h2>
                    <span className='col-100 flex-column font-14 translucent sm-d-block d-none m-bottom'>{t('commercial_info_subtitle')}</span>
                    <div className='col-100 d-flex flex-center-y m-bottom'>
                        <Checkbox 
                        value={!commercialInfoData.commercialInfo} 
                        labelIsClicked={true}
                        label={
                            <span>
                                {t('commercial_info_checkbox_receive')}
                            </span>
                        }
                        onChange={value => setCommercialInfo({...commercialInfoData, commercialInfo: !value})} />
                    </div>
                </FR> 

                <hr className="separator-xxs"/>

                {/*
                <NotificationRow
                    title={t('Información Académica')} detail={t('Recibe todas la novedades relativas a tu ciclo: inscripción a exámenes, publicación de notas, comunicados oficiales, etc.')}
                    valueMail={academicData.email} valueApp={academicData.app}
                    onClickMail={value => setAcademicData({...academicData, email: value})}
                    onClickApp={value => setAcademicData({...academicData, app: value})}
                />
                <NotificationRow
                    title={t('Promociones y Descuentos')} detail={t('Entérate del lanzamiento de nueva formación y ofertas en tu matrícula.')}
                    valueMail={promo.email} valueApp={promo.app}
                    onClickMail={value => setPromo({...promo, email: value})}
                    onClickApp={value => setPromo({...promo, app: value})}
                />
                <hr className="separator-xxs"/>
                <NotificationRow
                    title={t('Novedades en la bolsa de trabajo')} detail={t('Estarás al día de las nuevas ofertas de empleo.')}
                    valueMail={job.email} valueApp={job.app}
                    onClickMail={value => setJob({...job, email: value})}
                    onClickApp={value => setJob({...job, app: value})}
                />
                <hr className="separator-xxs"/>
                <NotificationRow
                    title={t('Actualizaciones en tus candidaturas')} detail={t('Recibe un aviso cuando tus candidaturas de ofertas de trabajo cambien de estado')}
                    valueMail={jobUpdate.email} valueApp={jobUpdate.app}
                    onClickMail={value => setJobUpdate({...jobUpdate, email: value})}
                    onClickApp={value => setJobUpdate({...jobUpdate, app: value})}
                />*/}
                <FR className='m-x2-bottom'>

                    <ButtonSpinner className="full-width sm-no-full-width" type='submit' onClick={handleSave} showSpinner={showSpinner}>{t('Guardar')}</ButtonSpinner>
                    <ButtonLink className="full-width sm-no-full-width sm-m-l sm-d-block d-none" type='submit' onClick={() => history.goBack()}>{t('Cancelar')}</ButtonLink>
                </FR>
            </Card>

    </CF>
   )
}

NotificationView = connect(
    ({auth: {loggedUser} })=>({studentId: loggedUser.id,}),
    {fetchProfileSettings, sendProfileSettings, fetchCommercialInfo, saveCommercialInfo})(NotificationView);


export default function ConfigureNotifications({...rest}) {
    return (
        <AppPage
            className="max-width">
            <NotificationView {...rest}/>

        </AppPage>
    );
}
