/**
 * Student Account
 * Student Account Api - Validation flow
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dmartinez@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AddressType from './AddressType';
import City from './City';
import Country from './Country';
import ViaType from './ViaType';

/**
 * The StudentAddress model module.
 * @module model/StudentAddress
 * @version 1.0.0
 */
class StudentAddress {
    /**
     * Constructs a new <code>StudentAddress</code>.
     * @alias module:model/StudentAddress
     * @param id {Number} 
     * @param type {module:model/AddressType} 
     * @param country {module:model/Country} 
     * @param viaType {module:model/ViaType} 
     * @param name {String} 
     * @param numberAddress {String} 
     * @param zipCode {String} 
     * @param phone {String} 
     */
    constructor(id, type, country, viaType, name, numberAddress, zipCode, phone) { 
        
        StudentAddress.initialize(this, id, type, country, viaType, name, numberAddress, zipCode, phone);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, type, country, viaType, name, numberAddress, zipCode, phone) { 
        obj['id'] = id;
        obj['type'] = type;
        obj['country'] = country;
        obj['via_type'] = viaType;
        obj['name'] = name;
        obj['number_address'] = numberAddress;
        obj['zip_code'] = zipCode;
        obj['phone'] = phone;
    }

    /**
     * Constructs a <code>StudentAddress</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StudentAddress} obj Optional instance to populate.
     * @return {module:model/StudentAddress} The populated <code>StudentAddress</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StudentAddress();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = AddressType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('country')) {
                obj['country'] = Country.constructFromObject(data['country']);
            }
            if (data.hasOwnProperty('via_type')) {
                obj['via_type'] = ViaType.constructFromObject(data['via_type']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('number_address')) {
                obj['number_address'] = ApiClient.convertToType(data['number_address'], 'String');
            }
            if (data.hasOwnProperty('address_block')) {
                obj['address_block'] = ApiClient.convertToType(data['address_block'], 'String');
            }
            if (data.hasOwnProperty('address_flat')) {
                obj['address_flat'] = ApiClient.convertToType(data['address_flat'], 'String');
            }
            if (data.hasOwnProperty('address_door')) {
                obj['address_door'] = ApiClient.convertToType(data['address_door'], 'String');
            }
            if (data.hasOwnProperty('address_stairs')) {
                obj['address_stairs'] = ApiClient.convertToType(data['address_stairs'], 'String');
            }
            if (data.hasOwnProperty('zip_code')) {
                obj['zip_code'] = ApiClient.convertToType(data['zip_code'], 'String');
            }
            if (data.hasOwnProperty('city')) {
                obj['city'] = City.constructFromObject(data['city']);
            }
            if (data.hasOwnProperty('city_name')) {
                obj['city_name'] = ApiClient.convertToType(data['city_name'], 'String');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('full_address')) {
                obj['full_address'] = ApiClient.convertToType(data['full_address'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
StudentAddress.prototype['id'] = undefined;

/**
 * @member {module:model/AddressType} type
 */
StudentAddress.prototype['type'] = undefined;

/**
 * @member {module:model/Country} country
 */
StudentAddress.prototype['country'] = undefined;

/**
 * @member {module:model/ViaType} via_type
 */
StudentAddress.prototype['via_type'] = undefined;

/**
 * @member {String} name
 */
StudentAddress.prototype['name'] = undefined;

/**
 * @member {String} number_address
 */
StudentAddress.prototype['number_address'] = undefined;

/**
 * @member {String} address_block
 */
StudentAddress.prototype['address_block'] = undefined;

/**
 * @member {String} address_flat
 */
StudentAddress.prototype['address_flat'] = undefined;

/**
 * @member {String} address_door
 */
StudentAddress.prototype['address_door'] = undefined;

/**
 * @member {String} address_stairs
 */
StudentAddress.prototype['address_stairs'] = undefined;

/**
 * @member {String} zip_code
 */
StudentAddress.prototype['zip_code'] = undefined;

/**
 * @member {module:model/City} city
 */
StudentAddress.prototype['city'] = undefined;

/**
 * @member {String} city_name
 */
StudentAddress.prototype['city_name'] = undefined;

/**
 * @member {String} phone
 */
StudentAddress.prototype['phone'] = undefined;

/**
 * @member {String} full_address
 */
StudentAddress.prototype['full_address'] = undefined;






export default StudentAddress;

