/**
 * Student Account
 * Student Account Api - Validation flow
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dmartinez@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AcademicProgramDocumentType from '../model/AcademicProgramDocumentType';
import Document from '../model/Document';
import Error from '../model/Error';
import InlineObject2 from '../model/InlineObject2';
import InlineObject3 from '../model/InlineObject3';
import InlineResponse2001 from '../model/InlineResponse2001';
import InlineResponse2002 from '../model/InlineResponse2002';
import InlineResponse2003 from '../model/InlineResponse2003';
import InlineResponse2004 from '../model/InlineResponse2004';
import Message from '../model/Message';
import PresentAddressSelected from '../model/PresentAddressSelected';
import Shop from '../model/Shop';
import Student from '../model/Student';
import StudentCertifiedCourseAccessDocument from '../model/StudentCertifiedCourseAccessDocument';
import StudentCourseAccess from '../model/StudentCourseAccess';
import StudentData from '../model/StudentData';
import StudentIdentityDocument from '../model/StudentIdentityDocument';

/**
* Student service.
* @module api/StudentApi
* @version 1.0.0
*/
export default class StudentApi {

    /**
    * Constructs a new StudentApi. 
    * @alias module:api/StudentApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get status of the student including academic title
     * Get status of the student including academic title
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object.<String, {String: Object}>} and HTTP response
     */
    getAcademicProgramStatusWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getAcademicProgramStatus");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = {'String': Object};
      return this.apiClient.callApi(
        '/students/{id}/academicprogramstatus', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get status of the student including academic title
     * Get status of the student including academic title
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object.<String, {String: Object}>}
     */
    getAcademicProgramStatus(id) {
      return this.getAcademicProgramStatusWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get delivery place
     * Get delivery place
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2003} and HTTP response
     */
    getAcademicTitleDeliveryPlaceWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getAcademicTitleDeliveryPlace");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2003;
      return this.apiClient.callApi(
        '/students/{id}/academicprogramdeliveryplace', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get delivery place
     * Get delivery place
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2003}
     */
    getAcademicTitleDeliveryPlace(id) {
      return this.getAcademicTitleDeliveryPlaceWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get student document by document id and student id
     * Get student document by document Id and student Id
     * @param {Number} id Student Id
     * @param {Number} idDocument Document Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
     */
    getDocumentByIdWithHttpInfo(id, idDocument) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getDocumentById");
      }
      // verify the required parameter 'idDocument' is set
      if (idDocument === undefined || idDocument === null) {
        throw new Error("Missing the required parameter 'idDocument' when calling getDocumentById");
      }

      let pathParams = {
        'id': id,
        'id_document': idDocument
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/students/{id}/documents/{id_document}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get student document by document id and student id
     * Get student document by document Id and student Id
     * @param {Number} id Student Id
     * @param {Number} idDocument Document Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
     */
    getDocumentById(id, idDocument) {
      return this.getDocumentByIdWithHttpInfo(id, idDocument)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Student app messages
     * Get student app messages
     * @param {Number} id Student Id
     * @param {Object} opts Optional parameters
     * @param {Number} opts.status Message's status id (default to 3)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Message>} and HTTP response
     */
    getPendingMessagesWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getPendingMessages");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'status': opts['status']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Message];
      return this.apiClient.callApi(
        '/students/{id}/messages', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Student app messages
     * Get student app messages
     * @param {Number} id Student Id
     * @param {Object} opts Optional parameters
     * @param {Number} opts.status Message's status id (default to 3)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Message>}
     */
    getPendingMessages(id, opts) {
      return this.getPendingMessagesWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get setted present confirmation
     * Get information about setted present confirmation
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PresentAddressSelected} and HTTP response
     */
    getPresentAddressWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getPresentAddress");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PresentAddressSelected;
      return this.apiClient.callApi(
        '/students/{id}/present_status', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get setted present confirmation
     * Get information about setted present confirmation
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PresentAddressSelected}
     */
    getPresentAddress(id) {
      return this.getPresentAddressWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Retrieves all status blocks
     * Get all status blocks by student id
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2002} and HTTP response
     */
    getStatusWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getStatus");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2002;
      return this.apiClient.callApi(
        '/students/{id}/status', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Retrieves all status blocks
     * Get all status blocks by student id
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2002}
     */
    getStatus(id) {
      return this.getStatusWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the price and the cart of the academic title
     * Get the price and the cart of the academic title
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    getStudentAcademicProgramCartWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getStudentAcademicProgramCart");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/students/{id}/studentacademicprogramcart', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get the price and the cart of the academic title
     * Get the price and the cart of the academic title
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    getStudentAcademicProgramCart(id) {
      return this.getStudentAcademicProgramCartWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * get student certified course access data
     * Get student certified course access data from student id
     * @param {Number} id Student id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/StudentCertifiedCourseAccessDocument} and HTTP response
     */
    getStudentCertifiedCourseAccessWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getStudentCertifiedCourseAccess");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = StudentCertifiedCourseAccessDocument;
      return this.apiClient.callApi(
        '/students/{id}/certifiedcourseaccess', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * get student certified course access data
     * Get student certified course access data from student id
     * @param {Number} id Student id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/StudentCertifiedCourseAccessDocument}
     */
    getStudentCertifiedCourseAccess(id) {
      return this.getStudentCertifiedCourseAccessWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * get student course access data
     * Get student course access data from student id
     * @param {Number} id Student id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/StudentCourseAccess} and HTTP response
     */
    getStudentCourseAccessWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getStudentCourseAccess");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = StudentCourseAccess;
      return this.apiClient.callApi(
        '/students/{id}/courseaccess', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * get student course access data
     * Get student course access data from student id
     * @param {Number} id Student id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/StudentCourseAccess}
     */
    getStudentCourseAccess(id) {
      return this.getStudentCourseAccessWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * get student personal data
     * Get student personal data from student id
     * @param {Number} id Student id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/StudentData} and HTTP response
     */
    getStudentDataWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getStudentData");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = StudentData;
      return this.apiClient.callApi(
        '/students/{id}/data', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * get student personal data
     * Get student personal data from student id
     * @param {Number} id Student id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/StudentData}
     */
    getStudentData(id) {
      return this.getStudentDataWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * get student identity document data
     * Get student identity document data from student id
     * @param {Number} id Student id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/StudentIdentityDocument} and HTTP response
     */
    getStudentIdentityDocumentWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getStudentIdentityDocument");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = StudentIdentityDocument;
      return this.apiClient.callApi(
        '/students/{id}/identitydocument', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * get student identity document data
     * Get student identity document data from student id
     * @param {Number} id Student id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/StudentIdentityDocument}
     */
    getStudentIdentityDocument(id) {
      return this.getStudentIdentityDocumentWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get student shop data
     * Get student shop data
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Shop} and HTTP response
     */
    getStudentShopWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getStudentShop");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Shop;
      return this.apiClient.callApi(
        '/students/{id}/shop', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get student shop data
     * Get student shop data
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Shop}
     */
    getStudentShop(id) {
      return this.getStudentShopWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Set delivery place
     * Set delivery place
     * @param {Number} id Student Id
     * @param {String} place Delivery place
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Error} and HTTP response
     */
    setAcademicTitleDeliveryPlaceWithHttpInfo(id, place) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling setAcademicTitleDeliveryPlace");
      }
      // verify the required parameter 'place' is set
      if (place === undefined || place === null) {
        throw new Error("Missing the required parameter 'place' when calling setAcademicTitleDeliveryPlace");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'place': place
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Error;
      return this.apiClient.callApi(
        '/students/{id}/academicprogramdeliveryplace', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Set delivery place
     * Set delivery place
     * @param {Number} id Student Id
     * @param {String} place Delivery place
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Error}
     */
    setAcademicTitleDeliveryPlace(id, place) {
      return this.setAcademicTitleDeliveryPlaceWithHttpInfo(id, place)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Set student present confirmation
     * Set value of the present confirmation
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2001} and HTTP response
     */
    setPresentAddressWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling setPresentAddress");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2001;
      return this.apiClient.callApi(
        '/students/{id}/present_status', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Set student present confirmation
     * Set value of the present confirmation
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2001}
     */
    setPresentAddress(id) {
      return this.setPresentAddressWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all student data by Id
     * Get all student data blocks (personal data, identityDocument, courseaccess,...)
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Student} and HTTP response
     */
    showWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling show");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Student;
      return this.apiClient.callApi(
        '/students/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get all student data by Id
     * Get all student data blocks (personal data, identityDocument, courseaccess,...)
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Student}
     */
    show(id) {
      return this.showWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get Commercial Info
     * Get Commercial Info
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2004} and HTTP response
     */
    studentControllerGetCommercialInfoWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling studentControllerGetCommercialInfo");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2004;
      return this.apiClient.callApi(
        '/students/{id}/commercial-info', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get Commercial Info
     * Get Commercial Info
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2004}
     */
    studentControllerGetCommercialInfo(id) {
      return this.studentControllerGetCommercialInfoWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get student AcademicProgram documents
     * Like a validation Block - Get student AcademicProgram documents
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Document>} and HTTP response
     */
    studentControllerGetStudentAcademicProgramDocumentWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling studentControllerGetStudentAcademicProgramDocument");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Document];
      return this.apiClient.callApi(
        '/students/{id}/academic-program/documents', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get student AcademicProgram documents
     * Like a validation Block - Get student AcademicProgram documents
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Document>}
     */
    studentControllerGetStudentAcademicProgramDocument(id) {
      return this.studentControllerGetStudentAcademicProgramDocumentWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get student AcademicProgram documentTypes
     * Get student AcademicProgram documentTypes
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AcademicProgramDocumentType>} and HTTP response
     */
    studentControllerGetStudentAcademicProgramDocumentTypesWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling studentControllerGetStudentAcademicProgramDocumentTypes");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [AcademicProgramDocumentType];
      return this.apiClient.callApi(
        '/students/{id}/academic-program-document-types', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get student AcademicProgram documentTypes
     * Get student AcademicProgram documentTypes
     * @param {Number} id Student Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AcademicProgramDocumentType>}
     */
    studentControllerGetStudentAcademicProgramDocumentTypes(id) {
      return this.studentControllerGetStudentAcademicProgramDocumentTypesWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Set Commercial Info
     * Set Commercial Info
     * @param {Number} id Student Id
     * @param {Object} opts Optional parameters
     * @param {module:model/InlineObject3} opts.inlineObject3 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Error} and HTTP response
     */
    studentControllerSetCommercialInfoWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['inlineObject3'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling studentControllerSetCommercialInfo");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Error;
      return this.apiClient.callApi(
        '/students/{id}/commercial-info', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Set Commercial Info
     * Set Commercial Info
     * @param {Number} id Student Id
     * @param {Object} opts Optional parameters
     * @param {module:model/InlineObject3} opts.inlineObject3 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Error}
     */
    studentControllerSetCommercialInfo(id, opts) {
      return this.studentControllerSetCommercialInfoWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Upload student Academic Program document
     * Uploads a student Academic Program document (example DIETC)
     * @param {Number} id Student Id
     * @param {Number} typeId Document type Id
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.additionalMetadata Additional metadata
     * @param {File} opts.file 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Document} and HTTP response
     */
    studentControllerUploadAcademicProgramDocumentWithHttpInfo(id, typeId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling studentControllerUploadAcademicProgramDocument");
      }
      // verify the required parameter 'typeId' is set
      if (typeId === undefined || typeId === null) {
        throw new Error("Missing the required parameter 'typeId' when calling studentControllerUploadAcademicProgramDocument");
      }

      let pathParams = {
        'id': id,
        'type_id': typeId
      };
      let queryParams = {
        'additionalMetadata': this.apiClient.buildCollectionParam(opts['additionalMetadata'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
        'file': opts['file']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = Document;
      return this.apiClient.callApi(
        '/students/{id}/academic-program-documents/{type_id}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Upload student Academic Program document
     * Uploads a student Academic Program document (example DIETC)
     * @param {Number} id Student Id
     * @param {Number} typeId Document type Id
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.additionalMetadata Additional metadata
     * @param {File} opts.file 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Document}
     */
    studentControllerUploadAcademicProgramDocument(id, typeId, opts) {
      return this.studentControllerUploadAcademicProgramDocumentWithHttpInfo(id, typeId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Sends and store accepted conditions signed document
     * Sends and store accepted conditions signed document
     * @param {Number} id Student Id
     * @param {Object} opts Optional parameters
     * @param {module:model/InlineObject2} opts.inlineObject2 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Document} and HTTP response
     */
    studentSignatureControllerProcessSignatureWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['inlineObject2'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling studentSignatureControllerProcessSignature");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Document;
      return this.apiClient.callApi(
        '/students/{id}/signature', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Sends and store accepted conditions signed document
     * Sends and store accepted conditions signed document
     * @param {Number} id Student Id
     * @param {Object} opts Optional parameters
     * @param {module:model/InlineObject2} opts.inlineObject2 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Document}
     */
    studentSignatureControllerProcessSignature(id, opts) {
      return this.studentSignatureControllerProcessSignatureWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update student personal data
     * @param {Number} id Student Id
     * @param {module:model/StudentData} studentData Student schema
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/StudentData} and HTTP response
     */
    updateWithHttpInfo(id, studentData) {
      let postBody = studentData;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling update");
      }
      // verify the required parameter 'studentData' is set
      if (studentData === undefined || studentData === null) {
        throw new Error("Missing the required parameter 'studentData' when calling update");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = StudentData;
      return this.apiClient.callApi(
        '/students/{id}/data', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Update student personal data
     * @param {Number} id Student Id
     * @param {module:model/StudentData} studentData Student schema
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/StudentData}
     */
    update(id, studentData) {
      return this.updateWithHttpInfo(id, studentData)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update student course access data
     * Update student course data from student id
     * @param {Number} id Student Id
     * @param {module:model/StudentCourseAccess} studentCourseAccess Student course access schema
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/StudentCourseAccess} and HTTP response
     */
    updateStudentCourseAccessWithHttpInfo(id, studentCourseAccess) {
      let postBody = studentCourseAccess;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateStudentCourseAccess");
      }
      // verify the required parameter 'studentCourseAccess' is set
      if (studentCourseAccess === undefined || studentCourseAccess === null) {
        throw new Error("Missing the required parameter 'studentCourseAccess' when calling updateStudentCourseAccess");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = StudentCourseAccess;
      return this.apiClient.callApi(
        '/students/{id}/courseaccess', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Update student course access data
     * Update student course data from student id
     * @param {Number} id Student Id
     * @param {module:model/StudentCourseAccess} studentCourseAccess Student course access schema
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/StudentCourseAccess}
     */
    updateStudentCourseAccess(id, studentCourseAccess) {
      return this.updateStudentCourseAccessWithHttpInfo(id, studentCourseAccess)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update student identity document data
     * Update student identity document data from student id
     * @param {Number} id Student Id
     * @param {module:model/StudentIdentityDocument} studentIdentityDocument Student identity document schema
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/StudentIdentityDocument} and HTTP response
     */
    updateStudentIdentityDocumentWithHttpInfo(id, studentIdentityDocument) {
      let postBody = studentIdentityDocument;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateStudentIdentityDocument");
      }
      // verify the required parameter 'studentIdentityDocument' is set
      if (studentIdentityDocument === undefined || studentIdentityDocument === null) {
        throw new Error("Missing the required parameter 'studentIdentityDocument' when calling updateStudentIdentityDocument");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = StudentIdentityDocument;
      return this.apiClient.callApi(
        '/students/{id}/identitydocument', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Update student identity document data
     * Update student identity document data from student id
     * @param {Number} id Student Id
     * @param {module:model/StudentIdentityDocument} studentIdentityDocument Student identity document schema
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/StudentIdentityDocument}
     */
    updateStudentIdentityDocument(id, studentIdentityDocument) {
      return this.updateStudentIdentityDocumentWithHttpInfo(id, studentIdentityDocument)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Upload student document
     * Uploads a student document like identity document, last course document,...
     * @param {Number} id Student Id
     * @param {Number} typeId Document type Id
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.additionalMetadata Additional metadata
     * @param {File} opts.file 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Document} and HTTP response
     */
    uploadDocumentWithHttpInfo(id, typeId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling uploadDocument");
      }
      // verify the required parameter 'typeId' is set
      if (typeId === undefined || typeId === null) {
        throw new Error("Missing the required parameter 'typeId' when calling uploadDocument");
      }

      let pathParams = {
        'id': id,
        'type_id': typeId
      };
      let queryParams = {
        'additionalMetadata': this.apiClient.buildCollectionParam(opts['additionalMetadata'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
        'file': opts['file']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = Document;
      return this.apiClient.callApi(
        '/students/{id}/documents/{type_id}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Upload student document
     * Uploads a student document like identity document, last course document,...
     * @param {Number} id Student Id
     * @param {Number} typeId Document type Id
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.additionalMetadata Additional metadata
     * @param {File} opts.file 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Document}
     */
    uploadDocument(id, typeId, opts) {
      return this.uploadDocumentWithHttpInfo(id, typeId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
