import {getHttp, getUrlParams, lazySingleton, parseJwt} from "../_common/common.ts";

import AppDispatch from "../_services/app-dispatch";

export default class AuthService{

    // singleton de auth service
    static instance = lazySingleton(()=>new AuthService());

    constructor() {
        this.micuentaenv = window.micuentaenv;
        this.app_dispatch = new AppDispatch();
    }

    async authenticate(micuentaenv = null) {

        try {
            const userData = await this.loginService(this.micuentaenv.STUDENT_ACCOUNT_API_basePath);
            if(!userData) return null;
            window.loggedUser = userData;

            try {

                if(!isWebApp) {
                    await Promise.all([
                        this.getJWT().then(token=>this.app_dispatch.dispatchToApp('setFirebaseToken', token)),
                        this.getAutologinToken().then(token=>this.autologin_token = token)
                    ]);
                }
            }catch (e){
                console.warn('error handling messaging', e);
            }

            if(window.dataLayer){
                window.dataLayer.push({'user_id': userData.id});
            }

            return userData;
        }
        catch(err){
            console.error(err);
            throw new Error('Sesión no valida');
        }
    }

    inServiceWorker(){
        return typeof ServiceWorkerGlobalScope !== 'undefined';
    }

    async loginService(svcUrl){
        let url = svcUrl + '/login?service=IlernaOnlineMyAccount';

        // tomamos los parametros
        const params = getUrlParams(location.href);

        if (params.ticket) {
            // quitamos los parametros de la url
            history.pushState(null, "", location.pathname);
            url += '&ticket=' + params.ticket;
        }

        const response = await getHttp(url);

        if(response.redirect) {
            if(this.inServiceWorker()) return false;
            setTimeout(() => location.href = response.redirect, 0);
            return false;
        }

        if (response.token) {
            return parseJwt(response.token);
        }

        return false;
    }

    async signout() {
        let url = this.logoutUrl();
        console.log('signout url', url);
        const response = await getHttp(url);
        console.log('signout response', response);
        setTimeout(()=>location.href = response.redirect, 300);
    }

    logoutUrl(){
        return  this.micuentaenv.STUDENT_ACCOUNT_API_basePath + '/logout?service=IlernaOnlineMyAccount';
    }

    async getJWT(){
        const {ilerna_token, custom_token} = await getHttp(this.micuentaenv.SSO_BaseUrl + '/firebase_jwt');
        this.ilerna_token = ilerna_token;
        return custom_token;
    }

    async getAutologinToken(){
        return await getHttp(this.micuentaenv.SSO_BaseUrl + '/get_autologin_token');
    }

    async sendDeviceToken(device_token){
        while(!this.ilerna_token) await wait(500);
        await getHttp(this.micuentaenv.Push_BaseUrl + `/api/v1/firebase/registerFirebaseDeviceToken?token=${this.ilerna_token}&device_token=${device_token}`);
    }

    generateAutologgedUrl(id_shop,url){
        return `${this.micuentaenv.SSO_BaseUrl}/api/login/${encodeURIComponent(this.autologin_token)}?s=${id_shop}&url=${encodeURIComponent(url)}`;
    }

}
