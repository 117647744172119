/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CompanyNewCompany from '../model/CompanyNewCompany';

/**
* Landing service.
* @module api/LandingApi
* @version v1
*/
export default class LandingApi {

    /**
    * Constructs a new LandingApi. 
    * @alias module:api/LandingApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * POST sin autenticacion desde landing. De la propia empresa para registrarse
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyNewCompany} opts.companyNewCompany 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Boolean} and HTTP response
     */
    newCompanyPostWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['companyNewCompany'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/new_company', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * POST sin autenticacion desde landing. De la propia empresa para registrarse
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyNewCompany} opts.companyNewCompany 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Boolean}
     */
    newCompanyPost(opts) {
      return this.newCompanyPostWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
