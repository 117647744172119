import {globalCatch} from "../../../_services/global-catch";
import {svcPsService} from "../../../_services/ps-service";
import {SET_CERTIFICATE_TYPES} from "./constants";

export const setCertificateTypes = payload => ({type: SET_CERTIFICATE_TYPES, payload});

export const fetchCertificateTypes = () =>
    globalCatch.catch(async (dispatch) => {
        const certTypes = await (await svcPsService()).getCertificateTypes();
        dispatch(setCertificateTypes(certTypes));
    })

export const createCertificateCart = (academic_program_id, product_attribute_id) =>
    globalCatch.catch(async (dispatch) => {
        let url = await (await svcPsService()).createCertificateCart(academic_program_id, product_attribute_id);
        window.open(url, '_self').focus()
    })
;

