import React, {Component, Fragment, h} from "preact";
import {Form as FinalForm} from 'react-final-form'
import {FormField, FormFieldRadioCheckbox, FormFieldSelect, FormFieldSplittedDate, SubmitButtonRow} from "../../../_common/forms/components/components";
import {connect} from "react-redux";
import {
    fetchFixture,
    fetchDocument,
    saveDocument,
    uploadDocument,
    saveStudentDocument
} from "../../../_rx/student/actions";
import {Card, CF, E, R, RE} from "../../../_common/layout";
import {AddImage} from "../../../_common/components/add-image";
import nie_front from "../img/nie_front.png";
import nie_back from "../img/nie_back.png";
import dni_front from "../img/dni_front.png";
import dni_back from "../img/dni_back.png";
import passport from "../img/passport.png";
import {t} from '../../../_i18n';
import {Prompt, Redirect} from "react-router";
import {
    COUNTRY_SIS_ID_ES,
    COUNTRY_SIS_ID_IT,
    DOCTYPE_ID_DNI,
    DOCTYPE_ID_NIE,
    DOCTYPE_ID_PASSPORT,
    DOCTYPE_SUB_ID_DNI_BACK,
    DOCTYPE_SUB_ID_DNI_FRONT,
    DOCTYPE_SUB_ID_NIE_BACK,
    DOCTYPE_SUB_ID_NIE_FRONT, MAX_IMAGE_PIXELS,
    PS_SHOPID_ES, PS_SHOPID_FR, SHOP_SIS_COUNTRY_MAPPING, STATUS_ID_ERROR, STATUS_ID_PENDING, STATUS_ID_SENT
} from "../../../constants";
import {isImageFile, resizeImage} from "../../../_common/common";
import AlertBox from "../../../_common/components/AlertBox/AlertBox";
import getLangByShopId, {isSpanishUser} from "../../../_common/utils/getLang";

const docPlaceholders = {
    1: { 0: passport },
    2: { [DOCTYPE_SUB_ID_DNI_FRONT]: dni_front, [DOCTYPE_SUB_ID_DNI_BACK]: dni_back },
    3: { [DOCTYPE_SUB_ID_NIE_FRONT]: nie_front, [DOCTYPE_SUB_ID_NIE_BACK]: nie_back },
    93: { [DOCTYPE_SUB_ID_DNI_FRONT]: dni_front, [DOCTYPE_SUB_ID_DNI_BACK]: dni_back }
};

const messagesPending = {
    [DOCTYPE_ID_PASSPORT]: 'Completa la información de tu pasaporte',
    [DOCTYPE_ID_DNI]: 'Completa la información de tu DNI',
    [DOCTYPE_ID_NIE]: 'Completa la información de tu NIE',
}

const messagesHelp = {
    [DOCTYPE_ID_DNI]: 'DNI ejemplo',
    [DOCTYPE_ID_NIE]: 'NIE ejemplo'
}

const patterns = {
    [DOCTYPE_ID_DNI]: "[0-9]{8}[A-Za-z]{1}",
    [DOCTYPE_ID_NIE]: "[XYZxyz]{1}[0-9]{7}[A-Za-z]{1}"
}

let Nif = class extends Component {

    componentDidMount() {
        if(!this.props.fixtures)
            this.props.fetchFixture(getLangByShopId(this.props.status.student_meta.shop_id));

        this.props.fetchDocument(this.props.auth.loggedUser.id);
    }

    hasImage(id, subdoc){
        if(!id) return false;
        id = parseInt(id);
        let dt = this.props.fixtures.document_type.find(dt=> dt.id === id);
        return subdoc === 0 || dt.children.length;
    }

    imageProps(id, subdoc){
        if(!id) return;
        id = parseInt(id);
        let doctype = this.props.fixtures.document_type.find(d=> d.id === id);
        doctype = !doctype.children.length ? doctype : doctype.children[subdoc];
        if(!doctype) return;

        const file = this.props.doc && this.props.doc.files && this.props.doc.files.find(f=>f.type.id == doctype.id);

        return {
            image: this.props.doc && this.props.doc.urls[doctype.id],
            imageName: file && file.name,
            placeholder: docPlaceholders[id][subdoc],
            title: t('Añadir foto imagen') + doctype.name,
            uploadCb: img=>this.upload(img, doctype.id)
        }
    }

    async upload(img, docId){
        if(isImageFile(img)) img = await resizeImage(img, MAX_IMAGE_PIXELS);
        await this.props.uploadDocument(this.props.auth.loggedUser.id, img, docId);
    }

    getDocumentPattern(values, props){
        if (!values.document_type || values.document_type.id == DOCTYPE_ID_PASSPORT || !isSpanishUser(props.status.student_meta.shop_id))  return ".*";
        return values.document_type? patterns[values.document_type.id] : '.*';
    }

    getDocumentHelpInfo(values, props){
        if (!isSpanishUser(props.status.student_meta.shop_id))  return '';
        return values.document_type ? messagesHelp[values.document_type.id] : '';
    }

    estadoDocumentoPendiente() {
        const s = this.props.status.blocks["2"].status.id;
        return s == STATUS_ID_PENDING || s == STATUS_ID_SENT;
    }

    msgDocumentoPendiente(values) {
        if(!values.document_type) return "";
        return messagesPending[values.document_type.id];
    }

    async onSubmit(data){
        data = this.updateCountryName(data);
        try {
            await this.props.saveStudentDocument(data, this.props.auth.loggedUser.id);
        }
        catch(err) {
            return err.body && err.body.errors
        }

    }

    docMessages() {
        const messages = [];
        this.props.doc && this.props.doc.files.forEach(f => {
            if (f.messages) {
                f.messages.forEach(m => {
                    if (m.name === 'errors') {
                        m.related_messages.forEach(rm => messages.push(rm.description))
                    }
                })
            }
        })
        return messages;
    }

    isCountryForced(values){
        return values && values.document_type && values.document_type.id == DOCTYPE_ID_DNI;
    }

    validDNI(value){
        return ((/^\d+[a-z]$/i).test(value) &&
            value.toLowerCase().substr(-1) === 'trwagmyfpdxbnjzsqvhlcke'[value.substring(0, value.length-1)%23]) ?
            '' : t('El DNI no es valido');
    }

    forceCountry(args, state, utils){
        if(!state) return;
        if(this.isCountryForced(state.formState.values))
            utils.changeValue(state, 'country.id', ()=>SHOP_SIS_COUNTRY_MAPPING[this.props.status.student_meta.shop_id]||COUNTRY_SIS_ID_ES);
    }

    initialForceCountry(doc){
        if(doc && this.isCountryForced(doc)) {
            doc.country = doc.country || {id:0};
            doc.country.id = SHOP_SIS_COUNTRY_MAPPING[this.props.status.student_meta.shop_id]||COUNTRY_SIS_ID_ES;
        }
    }

    updateCountryName(data) {
        let selectEl = document.querySelector('select[name="country.id"]');
        data.country.name = selectEl.options[selectEl.selectedIndex].text;
        return data;
    }

    render({doc, fixtures, auth, status, history, title}) {

        this.initialForceCountry(doc);

        return fixtures &&
            <CF className="max-width m-top-header-xs-sm"><FinalForm onSubmit={data=>this.onSubmit(data)} initialValues={doc}
                mutators={{forceCountry:this.forceCountry.bind(this)}}
                render={
                ({handleSubmit,  submitting, values, pristine, submitSucceeded, form:{mutators:{forceCountry}}})=>
                    <form className='form' action="" onSubmit={handleSubmit}  noValidate>
                        {submitSucceeded && history.goBack()}

                        <Card smOnly={false}>
                            {title && <h1>{title}</h1>}
                            <h2 className="m-top">{t('Tipo de documento')}:</h2>


                            <div className="d-flex flex-wrap">
                                { isSpanishUser(status.student_meta.shop_id) &&
                                    <FormFieldRadioCheckbox name="document_type.id" type="radio" label={t('DNI')} value="2" className="col-100 md-col-20 nie" onChange={forceCountry} />
                                }
                                { status.student_meta.shop_id === PS_SHOPID_ES &&
                                    <FormFieldRadioCheckbox name="document_type.id" type="radio" label={t('NIE')} value="3" className="col-100 md-col-20 nie" onChange={forceCountry} />
                                }
                                { status.student_meta.shop_id === PS_SHOPID_FR &&
                                    <FormFieldRadioCheckbox name="document_type.id" type="radio" label={t('DNI')} value="93" className="col-100 md-col-20 nie" onChange={forceCountry} />
                                }
                                <FormFieldRadioCheckbox name="document_type.id" type="radio" label={t('Pasaporte')} value="1" className="col-100 md-col-20 nie" onChange={forceCountry} />
                            </div>

                            <hr className="separator-xxs" />

                            <h2 className="p-top m-bottom">{t(this.msgDocumentoPendiente(values))}</h2>

                            <R>
                                <div class="col-100 md-col-60">
                                    <FormField name="reference"
                                       label={t('Número documento')} required={true}
                                       validate={values.document_type && values.document_type.id == DOCTYPE_ID_DNI && status.student_meta.shop_id == PS_SHOPID_ES ? value=>this.validDNI(value) : null}
                                       pattern={this.getDocumentPattern(values, this.props)}
                                       title={this.getDocumentHelpInfo(values, this.props)} />
                                </div>
                                <div className="col-100 md-col-60">
                                    <FormFieldSelect name="country.id" label={t('Nacionalidad')} required={true} options={fixtures.country} disabled={this.isCountryForced(values)} />
                                </div>
                            </R>

                            <h2 className="m-top m-bottom">{t('Documento válido')}</h2>

                            <R>
                                <div className="col-100 md-col-60">
                                    <FormFieldSplittedDate name="expires_at" required={true} />
                                </div>
                            </R>

                            <hr className="separator-xxs" />

                            <p className="p-top p-bottom" dangerouslySetInnerHTML={{__html:t('Puedes subir PDF')}} />

                            {this.docMessages().map(message =><AlertBox type='danger' className="p-top p-bottom">{message}</AlertBox>)}
                            { values.document_type &&
                            <Fragment>
                                { this.hasImage(values.document_type.id, 0) &&
                                    <div className="m-top">
                                        <h2>{t('Cara frontal')}</h2>
                                        <AddImage
                                            className="img-responsive"
                                            {...this.imageProps(values.document_type.id, 0)} />
                                    </div>
                                }
                                { this.hasImage(values.document_type.id, 1) &&
                                    <div className="m-top p-top">
                                        <h2>{t('Cara trasera')}</h2>
                                        <AddImage
                                            className="img-responsive"
                                            {...this.imageProps(values.document_type.id, 1)} />
                                    </div>
                                }
                            </Fragment>
                            }

                            <Prompt
                                message={location=> document.location.pathname != location.pathname && t('Seguro pregunta')}
                                when={!pristine && !submitSucceeded}/>

                            <SubmitButtonRow disabled={submitting} onCancel={()=>history.goBack()} />

                        </Card>

                    </form>

            }

            /></CF>;
    }
};

Nif = connect((state) => ({
        auth: state.auth,
        doc: state.student_document,
        fixtures: state.fixtures,
        status: state.status
    }),
    {
        fetchDocument,
        fetchFixture,
        saveDocument,
        uploadDocument,
        saveStudentDocument
    })(Nif);


const NifPage = ({history, title}) => <Nif history={history} title={title}/>


export default NifPage;
