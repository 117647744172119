import {SET_HISTORIAL_MATRICULAS, SET_MATRICULA_DETALLE} from "./constants";
import {svcPsService} from "../../../_services/ps-service";
import {globalCatch} from "../../../_services/global-catch";

export const setHistorialMatriculas = payload => ({type: SET_HISTORIAL_MATRICULAS, payload});
export const setMatriculaDetalle = payload => ({type: SET_MATRICULA_DETALLE, payload});

export const fetchHistorialMatriculas = () =>
    globalCatch.catch(async (dispatch) => {
        const historico = await (await svcPsService()).GetHistorialMatriculas();
        dispatch(setHistorialMatriculas(historico));
    })

export const fetchMatriculaDetalle = ref =>
    globalCatch.catch(async (dispatch) => {
        const matricula_detalle = await (await svcPsService()).GetMatricula(ref);
        dispatch(setMatriculaDetalle(matricula_detalle));
    })

