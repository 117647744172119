import {h} from "preact";

export const CfgmRequirements = ({id}) =><div className=" margin-auto" id={id}>
        <div className="innerText">
            <p>Para matricularte al ciclo de Grado Medio, debes tener <strong>más de 18 años o cumplirlos durante el año natural del comienzo del ciclo</strong>.
            Además, tienes que cumplir al menos uno de los siguientes requisitos académicos:</p>
        </div>
        <ul>
            <li>Tener el título de Graduado en Educación Secundaria Obligatoria (ESO).</li>
            <li>Tener el Graduado en Educación Secundaria (GES).</li>
            <li>Tener el título de Técnico/a o de Técnico/a auxiliar.</li>
            <li>Haber superado el segundo curso del Bachillerato Unificado y Polivalente (BUP).</li>
            <li>Si no se ha superado el Bachillerato Unificado y Polivalente (BUP), tener un máximo de dos materias pendientes en el conjunto de los dos cursos.</li>
            <li>Tener el título de Bachiller.</li>
            <li>Haber superado la Prueba de Acceso a la Universidad para mayores de 25 años.</li>
            <li>Haber superado la prueba o el curso específico para el acceso a los ciclos formativos de Grado Medio o Grado Superior.</li>
            <li>Haber superado los módulos obligatorios de un programa de cualificación profesional inicial (PCPI).</li>
            <li>Tener un título de Grado Medio o de Grado Superior de cualquier ciclo formativo. </li>
            <li>Tener un título para acceder a un ciclo formativo de Grado Superior.</li>
            <li>Tener otros estudios equivalentes a efectos académicos.</li>
        </ul>
        <div className="warning-text">
            <span>Las personas con <strong>titulaciones extranjeras</strong> deben presentar la resolución positiva de la solicitud de homologación o convalidación del título que puede darles acceso al Grado Medio. Si este procedimiento aún no está resuelto, deben presentar el volante de inscripción condicional vigente para el curso en que se hayan matriculado. Cuando se resuelva, deberán remitir al centro la correspondiente resolución de homologación.</span>
        </div>
        <div className="innerText">
            <p><strong>No permiten el acceso</strong> al ciclo de Grado Medio los siguientes documentos:</p>
        </div>
        <ul>
            <li>Tener un certificado de profesionalidad.</li>
            <li>Tener el título de Graduado Escolar (EGB).</li>
            <li>Tener el título de Bachillerato elemental.</li>
        </ul>
    </div>;
