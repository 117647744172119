/**
 * New Expedient
 * Expedient for Student Account
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import RecognizedNew from './RecognizedNew';
import SubjectOfferGrade from './SubjectOfferGrade';
import SubjectOfferInClassGrade from './SubjectOfferInClassGrade';
import SubjectOfferLastExamConvocation from './SubjectOfferLastExamConvocation';

/**
 * The SubjectOffer model module.
 * @module model/SubjectOffer
 * @version 1.0
 */
class SubjectOffer {
    /**
     * Constructs a new <code>SubjectOffer</code>.
     * @alias module:model/SubjectOffer
     */
    constructor() { 
        
        SubjectOffer.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SubjectOffer</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SubjectOffer} obj Optional instance to populate.
     * @return {module:model/SubjectOffer} The populated <code>SubjectOffer</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SubjectOffer();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('formative_action_id')) {
                obj['formative_action_id'] = ApiClient.convertToType(data['formative_action_id'], 'Number');
            }
            if (data.hasOwnProperty('abbr')) {
                obj['abbr'] = ApiClient.convertToType(data['abbr'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('enroll_id')) {
                obj['enroll_id'] = ApiClient.convertToType(data['enroll_id'], 'Number');
            }
            if (data.hasOwnProperty('is_enrolled')) {
                obj['is_enrolled'] = ApiClient.convertToType(data['is_enrolled'], 'Boolean');
            }
            if (data.hasOwnProperty('grade')) {
                obj['grade'] = ApiClient.convertToType(data['grade'], 'String');
            }
            if (data.hasOwnProperty('recognized')) {
                obj['recognized'] = ApiClient.convertToType(data['recognized'], 'Boolean');
            }
            if (data.hasOwnProperty('approved')) {
                obj['approved'] = ApiClient.convertToType(data['approved'], 'Boolean');
            }
            if (data.hasOwnProperty('is_training_work_center')) {
                obj['is_training_work_center'] = ApiClient.convertToType(data['is_training_work_center'], 'Boolean');
            }
            if (data.hasOwnProperty('has_compulsory_classes')) {
                obj['has_compulsory_classes'] = ApiClient.convertToType(data['has_compulsory_classes'], 'Boolean');
            }
            if (data.hasOwnProperty('mandatory_compulsory_classes')) {
                obj['mandatory_compulsory_classes'] = ApiClient.convertToType(data['mandatory_compulsory_classes'], 'Boolean');
            }
            if (data.hasOwnProperty('has_done_compulsory_classes')) {
                obj['has_done_compulsory_classes'] = ApiClient.convertToType(data['has_done_compulsory_classes'], 'Boolean');
            }
            if (data.hasOwnProperty('over_percentage_pac')) {
                obj['over_percentage_pac'] = ApiClient.convertToType(data['over_percentage_pac'], 'Boolean');
            }
            if (data.hasOwnProperty('allowed_to_join')) {
                obj['allowed_to_join'] = ApiClient.convertToType(data['allowed_to_join'], 'Boolean');
            }
            if (data.hasOwnProperty('in_class_grade')) {
                obj['in_class_grade'] = SubjectOfferInClassGrade.constructFromObject(data['in_class_grade']);
            }
            if (data.hasOwnProperty('allow_revision')) {
                obj['allow_revision'] = ApiClient.convertToType(data['allow_revision'], 'Boolean');
            }
            if (data.hasOwnProperty('revision_submitted')) {
                obj['revision_submitted'] = ApiClient.convertToType(data['revision_submitted'], 'Boolean');
            }
            if (data.hasOwnProperty('exam_attendance')) {
                obj['exam_attendance'] = ApiClient.convertToType(data['exam_attendance'], 'Boolean');
            }
            if (data.hasOwnProperty('last_exam_convocation')) {
                obj['last_exam_convocation'] = SubjectOfferLastExamConvocation.constructFromObject(data['last_exam_convocation']);
            }
            if (data.hasOwnProperty('enrollment_academic_section_title')) {
                obj['enrollment_academic_section_title'] = ApiClient.convertToType(data['enrollment_academic_section_title'], 'String');
            }
            if (data.hasOwnProperty('grades')) {
                obj['grades'] = ApiClient.convertToType(data['grades'], [SubjectOfferGrade]);
            }
            if (data.hasOwnProperty('recognized_new')) {
                obj['recognized_new'] = RecognizedNew.constructFromObject(data['recognized_new']);
            }
            if (data.hasOwnProperty('hidden-grade')) {
                obj['hidden-grade'] = ApiClient.convertToType(data['hidden-grade'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
SubjectOffer.prototype['id'] = undefined;

/**
 * @member {Number} formative_action_id
 */
SubjectOffer.prototype['formative_action_id'] = undefined;

/**
 * @member {String} abbr
 */
SubjectOffer.prototype['abbr'] = undefined;

/**
 * @member {String} title
 */
SubjectOffer.prototype['title'] = undefined;

/**
 * @member {Number} enroll_id
 */
SubjectOffer.prototype['enroll_id'] = undefined;

/**
 * @member {Boolean} is_enrolled
 */
SubjectOffer.prototype['is_enrolled'] = undefined;

/**
 * @member {String} grade
 */
SubjectOffer.prototype['grade'] = undefined;

/**
 * @member {Boolean} recognized
 */
SubjectOffer.prototype['recognized'] = undefined;

/**
 * @member {Boolean} approved
 */
SubjectOffer.prototype['approved'] = undefined;

/**
 * @member {Boolean} is_training_work_center
 */
SubjectOffer.prototype['is_training_work_center'] = undefined;

/**
 * @member {Boolean} has_compulsory_classes
 */
SubjectOffer.prototype['has_compulsory_classes'] = undefined;

/**
 * @member {Boolean} mandatory_compulsory_classes
 */
SubjectOffer.prototype['mandatory_compulsory_classes'] = undefined;

/**
 * @member {Boolean} has_done_compulsory_classes
 */
SubjectOffer.prototype['has_done_compulsory_classes'] = undefined;

/**
 * @member {Boolean} over_percentage_pac
 */
SubjectOffer.prototype['over_percentage_pac'] = undefined;

/**
 * @member {Boolean} allowed_to_join
 */
SubjectOffer.prototype['allowed_to_join'] = undefined;

/**
 * @member {module:model/SubjectOfferInClassGrade} in_class_grade
 */
SubjectOffer.prototype['in_class_grade'] = undefined;

/**
 * @member {Boolean} allow_revision
 */
SubjectOffer.prototype['allow_revision'] = undefined;

/**
 * @member {Boolean} revision_submitted
 */
SubjectOffer.prototype['revision_submitted'] = undefined;

/**
 * @member {Boolean} exam_attendance
 */
SubjectOffer.prototype['exam_attendance'] = undefined;

/**
 * @member {module:model/SubjectOfferLastExamConvocation} last_exam_convocation
 */
SubjectOffer.prototype['last_exam_convocation'] = undefined;

/**
 * @member {String} enrollment_academic_section_title
 */
SubjectOffer.prototype['enrollment_academic_section_title'] = undefined;

/**
 * @member {Array.<module:model/SubjectOfferGrade>} grades
 */
SubjectOffer.prototype['grades'] = undefined;

/**
 * @member {module:model/RecognizedNew} recognized_new
 */
SubjectOffer.prototype['recognized_new'] = undefined;

/**
 * @member {Boolean} hidden-grade
 */
SubjectOffer.prototype['hidden-grade'] = undefined;






export default SubjectOffer;

