/**
 * LTI Enroll Exam
 * LTI Enroll Exam for Students
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from './ApiClient';
import ArrayOfAcademicProgramsItem from './model/ArrayOfAcademicProgramsItem';
import Document from './model/Document';
import DocumentType from './model/DocumentType';
import Error from './model/Error';
import EvauFileUploadDates from './model/EvauFileUploadDates';
import EvauInscriptionStatus from './model/EvauInscriptionStatus';
import FormativeActions from './model/FormativeActions';
import FormativeActionsPerPeriod from './model/FormativeActionsPerPeriod';
import InlineObject from './model/InlineObject';
import InlineObject1 from './model/InlineObject1';
import InlineResponse200 from './model/InlineResponse200';
import InlineResponse2001 from './model/InlineResponse2001';
import InlineResponse400 from './model/InlineResponse400';
import SubjectOffer from './model/SubjectOffer';
import DownloadsApi from './api/DownloadsApi';
import EVAUApi from './api/EVAUApi';
import EnrollmentActionsApi from './api/EnrollmentActionsApi';
import EventDetailApi from './api/EventDetailApi';
import EventsApi from './api/EventsApi';
import GraceExamConvocationsApi from './api/GraceExamConvocationsApi';
import StudentGradesApi from './api/StudentGradesApi';


/**
* LTI_Enroll_Exam_for_Students.<br>
* The <code>index</code> module provides access to constructors for all the classes which comprise the public API.
* <p>
* An AMD (recommended!) or CommonJS application will generally do something equivalent to the following:
* <pre>
* var LtiEnrollExam = require('index'); // See note below*.
* var xxxSvc = new LtiEnrollExam.XxxApi(); // Allocate the API class we're going to use.
* var yyyModel = new LtiEnrollExam.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* <em>*NOTE: For a top-level AMD script, use require(['index'], function(){...})
* and put the application logic within the callback function.</em>
* </p>
* <p>
* A non-AMD browser application (discouraged) might do something like this:
* <pre>
* var xxxSvc = new LtiEnrollExam.XxxApi(); // Allocate the API class we're going to use.
* var yyy = new LtiEnrollExam.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* </p>
* @module index
* @version 2.0.0
*/
export {
    /**
     * The ApiClient constructor.
     * @property {module:ApiClient}
     */
    ApiClient,

    /**
     * The ArrayOfAcademicProgramsItem model constructor.
     * @property {module:model/ArrayOfAcademicProgramsItem}
     */
    ArrayOfAcademicProgramsItem,

    /**
     * The Document model constructor.
     * @property {module:model/Document}
     */
    Document,

    /**
     * The DocumentType model constructor.
     * @property {module:model/DocumentType}
     */
    DocumentType,

    /**
     * The Error model constructor.
     * @property {module:model/Error}
     */
    Error,

    /**
     * The EvauFileUploadDates model constructor.
     * @property {module:model/EvauFileUploadDates}
     */
    EvauFileUploadDates,

    /**
     * The EvauInscriptionStatus model constructor.
     * @property {module:model/EvauInscriptionStatus}
     */
    EvauInscriptionStatus,

    /**
     * The FormativeActions model constructor.
     * @property {module:model/FormativeActions}
     */
    FormativeActions,

    /**
     * The FormativeActionsPerPeriod model constructor.
     * @property {module:model/FormativeActionsPerPeriod}
     */
    FormativeActionsPerPeriod,

    /**
     * The InlineObject model constructor.
     * @property {module:model/InlineObject}
     */
    InlineObject,

    /**
     * The InlineObject1 model constructor.
     * @property {module:model/InlineObject1}
     */
    InlineObject1,

    /**
     * The InlineResponse200 model constructor.
     * @property {module:model/InlineResponse200}
     */
    InlineResponse200,

    /**
     * The InlineResponse2001 model constructor.
     * @property {module:model/InlineResponse2001}
     */
    InlineResponse2001,

    /**
     * The InlineResponse400 model constructor.
     * @property {module:model/InlineResponse400}
     */
    InlineResponse400,

    /**
     * The SubjectOffer model constructor.
     * @property {module:model/SubjectOffer}
     */
    SubjectOffer,

    /**
    * The DownloadsApi service constructor.
    * @property {module:api/DownloadsApi}
    */
    DownloadsApi,

    /**
    * The EVAUApi service constructor.
    * @property {module:api/EVAUApi}
    */
    EVAUApi,

    /**
    * The EnrollmentActionsApi service constructor.
    * @property {module:api/EnrollmentActionsApi}
    */
    EnrollmentActionsApi,

    /**
    * The EventDetailApi service constructor.
    * @property {module:api/EventDetailApi}
    */
    EventDetailApi,

    /**
    * The EventsApi service constructor.
    * @property {module:api/EventsApi}
    */
    EventsApi,

    /**
    * The GraceExamConvocationsApi service constructor.
    * @property {module:api/GraceExamConvocationsApi}
    */
    GraceExamConvocationsApi,

    /**
    * The StudentGradesApi service constructor.
    * @property {module:api/StudentGradesApi}
    */
    StudentGradesApi
};
