import React, { h, Fragment } from "preact";
import {useEffect, useState} from 'preact/hooks';
import {t} from '../_i18n';
import { C, CF, E, F, R, RE } from "../_common/layout";
import {useSelector, useDispatch, connect} from 'react-redux'
import {AppPage} from "../_common/components/app-page";
import {fetchComunicados} from '../_rx/comunicados/actions'
import {formatDDMMYYYY, isMobileAgent, rndStr} from "../_common/common";
import './comunicados.scss';
import {Link} from "react-router-dom";
import {ModalWhatsapp} from "./modal-whatsapp";
import {Modal, ModalLink} from "../_common/components/modal";
import {fetchStatus} from "../_rx/student/actions";
import {ButtonGhost} from "../_common/components/buttons/buttons.tsx";
import {formatDate} from "../utils";


export let Comunicados = ({studentType, userId, user, studentMeta, countNotices, isolateComponent}) => {
    const id_modal = rndStr();
    const dispatch = useDispatch();
    const comunicados = useSelector( state => state.comunicados );
    const IS_PREMIUM_USER = studentMeta.has_whatsapp && studentMeta.shop_id !== 24;

    useEffect(() => {
        // Trigger your effect
        dispatch(fetchComunicados());
        dispatch(fetchStatus(userId))
        // return () => {
        //     // Optional: Any cleanup code
        // };
    }, []);

    return <div className="comunicados">
        {!isolateComponent && <h1 className='hero m-top m-bottom '>{t('Comunicados')}</h1>}
        {IS_PREMIUM_USER && <Fragment>
            <h2 className='font-24'>Premium</h2>
            <div className='d-flex m-x2-bottom m-top '>
                { isMobileAgent()
                    ? <div className='full-width d-flex flex-center-x'>
                        <div className='full-width flex-column m-r-half m-l-half'>
                            <div className="wa-message-us"
                                 number="34639214799"
                                 label={t('GESTOR PREMIUM')}
                                 border_radius="4px">
                                <script src="https://cdn.smooch.io/whatsapp/message-us-btn.min.js" type="text/javascript" />
                            </div>
                            {/*<ModalLink id={id_modal}>*/}
                            {/*    <ButtonGhost className='d-flex flex-center-y full-width m-top-half background-transparent'>*/}
                            {/*        <i className="fal fa-calendar-alt font-24 m-r" />{t('RESERVA UNA CITA')}*/}
                            {/*    </ButtonGhost>*/}
                            {/*</ModalLink>*/}
                            {/*<div className="modal-acuity">*/}
                            {/*    <Modal id={id_modal} className="sm-no-fullscreen">*/}
                            {/*        <iframe src="https://app.acuityscheduling.com/schedule.php?owner=19018317&calendarID=5667307" frameborder="0" allowtransparency="true" id="acuity_iframe" ></iframe>*/}
                            {/*    </Modal>*/}
                            {/*</div>*/}
                          </div>
                        </div>
                    : <Fragment>
                        <ModalLink id='whatsapp'>
                            <ButtonGhost className='d-flex flex-center-y sm-m-right background-transparent  sm-auto-width'>
                                <i className="fab fa-whatsapp font-24 m-r" />{t('GESTOR PREMIUM')}
                            </ButtonGhost>
                        </ModalLink>
                        <ModalWhatsapp id='whatsapp'/>
                        {/*<ModalLink id={id_modal}>*/}
                        {/*    <ButtonGhost className='d-flex flex-center-y background-transparent  sm-auto-width'>*/}
                        {/*        <i className="fal fa-calendar-alt font-24 m-r" />{t('RESERVA UNA CITA')}*/}
                        {/*    </ButtonGhost>*/}
                        {/*</ModalLink>*/}
                        {/*<div className="modal-acuity">*/}
                        {/*    <Modal id={id_modal} className="sm-no-fullscreen">*/}
                        {/*        <iframe src="https://app.acuityscheduling.com/schedule.php?owner=19018317&calendarID=5667307" frameborder="0" allowtransparency="true" id="acuity_iframe"  />*/}
                        {/*    </Modal>*/}
                        {/*</div>*/}
                      </Fragment>
                }
            </div>
        </Fragment>
        }

        <h2 className='font-24 m-bottom'>{t('Comunicados')}</h2>
        { !isolateComponent && countNotices?.unread > 0 && <div className='font-18 m-bottom'>
            {(countNotices.unread === 1)
                ? `${countNotices.unread} ${t('comunicado sin leer')}.`
                : `${countNotices.unread} ${t('comunicados sin leer')}.`
            }
        </div>}

        {!comunicados
            ? <p className="t-center">
                <em className="disabled-aspect-gray"> {t('Cargando')}. . . </em>
            </p>
            :
            comunicados.length
                ? (<Fragment>
                    {comunicados.map((comItem) => (
                        <E className="wrapped-list no-margin-bottom sm-m-bottom" key={comItem.uuid}>
                            <Link to={"/notices/" + comItem.uuid} className='full-width'>
                                <ComunicadoItemList com={comItem} />
                            </Link>
                        </E>)
                    )}
                </Fragment>)
                :  <div className='m-l-half m-r-half sm-no-m-l sm-no-m-r'>
                    <p className="t-center">
                        <em className="disabled-aspect-gray"> {t('No hay mensajes todavía ...')} </em>
                    </p>
                </div>
        }
    </div>
}

const ComunicadoItemList = props => {
    const com = props.com;
    const trimmedBody = body => {
        const maxBodyLengt = 220;
        if (body.length > maxBodyLengt) {
            const words = body.split(' ').slice(0,23);
            return words.join(' ') + '...';
        }
        return body;
    }
    return <R className="card p-all" key={com.uuid}>
        <div className="comunicado-item">
            <div>
                {!com.first_open && <i className="fas fa-circle disabled font-11 m-r-half" />}
                <span className="font-18 bold">{com.title}</span>
            </div>
            <div className="m-top-half">
                {trimmedBody(com.body.replace(/(<([^>]+)>)/gi, " ").replace('&nbsp;', ' ').replace("\r\n", ' '))}
            </div>
            <div className='m-top-half font-color-486268 font-14'>
                {formatDate(com.received_at)}
            </div>
        </div>
    </R>
}

const ComunicadosContainer = connect(
    ({status, auth, comunicados_status})=>({
        studentType: status.student_meta.student_type,
        studentMeta: status.student_meta,
        userId: auth.loggedUser.id,
        user: auth.loggedUser,
        countNotices: comunicados_status
    }), {})(Comunicados);

export default ({ ...rest }) =>
    <AppPage
        name="comunicados"
        subtitle=""
    >
        <F className="max-width-980">
            <ComunicadosContainer {...rest} />
        </F>
    </AppPage>
;

