import React, {h} from "preact";
import {Card} from "../../_common/layout";
import {t} from '../../_i18n';
import {connect} from "react-redux";
import {sendInviteFriend} from "../../_rx/inviteFriend/actions";
import AlertBox from "../../_common/components/AlertBox/AlertBox";


function SentInviteComponent({inviteFriendStatus}) {
    // const usedPremium = !inviteFriendStatus.gifts.includes('PremiumDto');
    const string_email = t('INVITE_FRIEND_SENT_EMAIL').replace('{FRIEND_EMAIL}', inviteFriendStatus?.email_friend)
    // const string_discount = t('INVITE_FRIEND_SENT_DTO_PREMIUM')
    //     .replace('{DTO_PERCENTAGE}', inviteFriendStatus?.premium_discount_percentage)
    //     .replace('{OLD_PRICE}', inviteFriendStatus?.premium_price)
    //     .replace('{NEW_PRICE}', inviteFriendStatus?.premium_final_price)
    const message = "En cuanto tu amig@, conocid@ o familiar se matricule ambos recibiréis el bono de 50€ de descuento.";

    return (
        <Card className='m-top p-bottom animation-appear'>
            <div className='m-top' dangerouslySetInnerHTML={{__html: string_email}}/>
            <AlertBox type='info' className='m-top'>
                <div dangerouslySetInnerHTML={{__html: message}}/>
            </AlertBox>
            <div className='m-top m-bottom'>
                <p>{t('¡Muchas gracias por habernos recomendado!')}</p>
            </div>

        </Card>
    )
}


SentInviteComponent = connect(
    ({inviteFriendStatus}) => ({inviteFriendStatus: inviteFriendStatus.status}),
    {sendInviteFriend})(SentInviteComponent);


export default ({...rest}) => <SentInviteComponent {...rest}/>
