import {globalCatch} from "../../../_services/global-catch";
import {SET_CHRISTMAS_GIFT} from "./constants";
import StudentAccountService from "../../../_services/student-account-service";
import {lazySingleton} from "../../../_common/common";

const svcStudent = lazySingleton(()=>new StudentAccountService());

export const setGiftStatus = payload => ({type: SET_CHRISTMAS_GIFT, payload});

export const fetchGiftStatus = (studentId) => globalCatch.catch(async (dispatch) => {
    const giftStatus = await svcStudent().getPresentStatus(studentId)
    dispatch(setGiftStatus(giftStatus));
});

export const pushGiftStatus = (studentId) => globalCatch.catch(async (dispatch) => {
    await svcStudent().setPresentStatus(studentId)
    dispatch(fetchGiftStatus(studentId))
});
