import { SET_FCT_STATE, SET_FCT_SCREEN_STATE, SET_FCT_MESSAGES, SET_FCT_STATIC_DOCUMENTS } from './constants'
import { globalCatch } from '../../../_services/global-catch'
import { getState } from '../../../fct/utils'
import {
  messagingApi,
  statusApi,
  documentApi,
  adressApi,
  documentMetaApi,
  contractManagementApi, staticDocumentApi, loginApi
} from '../../../_services/fct-service'
import { ContractManagementApi, DocumentsApi } from '../../../_api/fct/src'

export const setState = val => ({type: SET_FCT_STATE, payload:{val: val}});
export const setScreenState = val => ({type: SET_FCT_SCREEN_STATE, payload:{screenState: val}});
export const setMessages = val => ({type: SET_FCT_MESSAGES, payload:{messages: val}});
export const setStaticDocuments = val => ({type: SET_FCT_STATIC_DOCUMENTS, payload:{static_documents: val}});

export const loginFct = () => globalCatch.catch(async () => {
  await loginApi()
});

export const fetchStatus = (contractId) => globalCatch.catch(async (dispatch) => {
  const state = await (await statusApi()).apiV1MyaccountStatusContractIdGet(contractId)
  dispatch(setState(getState(state.screen)))
  dispatch(setScreenState(state))
});

export const acceptTerms = async (contractId) => {
  return (await contractManagementApi()).apiV1MyaccountSigncontractContractIdPost(contractId)
}

export const rejectCompany = async (contractId) => {
  return (await contractManagementApi()).apiV1MyaccountRefusecompanyContractIdPost(contractId)
}

export const fetchMessages = (contractId) => globalCatch.catch(async (dispatch) => {
  const messages = await (await messagingApi()).apiV1MyaccountActivityContractIdGet(contractId)
  dispatch(setMessages(messages))
})

export const cleanStatus = () => globalCatch.catch(async (dispatch) => {
  dispatch(setMessages([]))
  dispatch(setState(null))
  dispatch(setScreenState(null))
})

export const getStaticDocuments = () => globalCatch.catch(async (dispatch) => {
  const static_documents = await (await staticDocumentApi()).apiV1MyaccountStaticsGet()
  dispatch(setStaticDocuments(static_documents.templates))
})

export const sendMessage = async (contractId, body) => {
  return (await messagingApi()).apiV1MyaccountSendMessageContractIdPost(contractId, body)
}

export const fetchAllDocuments = async (contractId) => {
  return (await documentApi()).apiV1MyaccountDocumentsContractIdGet(contractId)
    .then(async (documents) => {
      const meta = await (await documentMetaApi()).apiV1MyaccountDocumentMetaContractIdGet(contractId)
        // if (!meta.iban) {
        //     meta.iban = '';
        // }
      return {...documents, meta}
    })
}

export const postDocumentsForm = async (contractId) => {
  return (await documentApi()).apiV1MyaccountDocumentsContractIdPut(contractId)
}

export const putDocument = async (documentId) => {
  return (await documentApi()).apiV1MyaccountDocumentDocumentIdPut(documentId)
}

export const postDocumentsMetaDraft = async (contractId, meta) => {
  return (await documentMetaApi()).apiV1MyaccountDocumentMetaContractIdPost(contractId, meta)
}

export const fetchProvinces = async () => {
  return (await adressApi()).apiV1MyaccountProvincesGet()
}

export const fetchCities = async (idProvince) => {
  return (await adressApi()).apiV1MyaccountCitiesProvinceIdGet(idProvince)
}

export const saveDocument = async (contractId, file, type) => {
  return (await documentApi()).apiV1MyaccountDocumentContractIdPost(contractId, {file, fileName: file.name, documentType: type})
}

export const deleteDocument = async (id) => {
  return (await documentApi()).apiV1MyaccountDocumentDeleteDocumentIdDelete(id)
}

export const downloadFileFtp = async (id, filename ) =>{
  new DocumentsApi().apiV1MyaccountDocumentDocumentIdGet(id)
    .then(async (res) => {
      const base64Response = await fetch(`data:${res.type};base64,${res.data}`);
      const blob = await base64Response.blob();
      const input = document.createElement("a");
      input.href = URL.createObjectURL(blob);
      input.setAttribute("download", filename.split('.')[0]);
      input.click();
    })
}


