import {Fragment, h} from "preact";
import {ButtonGhost, ButtonPrimaryHighlight} from '../buttons/buttons'
import ModalHelp from '../../../home/HelpSection/ModalHelp/ModalHelp'
import { useState } from 'preact/hooks'
import './card-dashboard-styles.scss'

export default function CardHelp ({}) {
  const [openModal, setOpenModal] = useState(false)
  return (
    <div className='card-dashboard card-dashboard-help inverse'>
      {openModal && <ModalHelp visible={openModal} onClose={() => setOpenModal(false)} />}
      <div>
        <div className='card-dashboard__header'><p>¿Necesitas ayuda?</p></div>
        <div className='card-dashboard__content desktop-only'>Reserva una cita y te llamaremos. ¡Estamos a tu disposición!</div>
        <ButtonPrimaryHighlight className='full-width m-top-half' onClick={() => setOpenModal(true)}>Reservar Cita</ButtonPrimaryHighlight>
      </div>
    </div>
  )
}
