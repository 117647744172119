
import es from './es'

let language = es;

// export const t = window.location.hostname.includes('pre') || window.location.hostname.includes('localhost') ?
//         key=>language[key]||('·'+key+'·'):
//         key=>language[key]||key;

export const t = (key)=>language[key]||key;

export const setLanguage = async code=>{
    // no se puede usar un import con variable,
    // asi parcel detecta los imports lazy
    if(code === 'es') language = es;
    else if(code === 'it') language = (await import('./it')).default;
    else if(code === 'fr') language = (await import('./fr')).default;
    else if(code === 'ca') language = (await import('./ca')).default;
}

