import { Fragment, h } from "preact";
import { useSelector } from 'react-redux'
import AlertContractCat from '../../components/Alerts/AlertContractCat'
import { DateTime } from 'luxon'
import { downloadFileFtp } from '../../../_rx/fct/actions'
import AlertSurvey from '../../components/Alerts/AlertSurvey'
import AlertAnnexed from '../../components/Alerts/AlertAnnexed'
import { useParams } from 'react-router'
import UploadDocumentWeekly from '../../components/UploadDocumentWeekly/UploadDocumentWeekly'
import WeeklyFile from '../../components/UploadDocumentWeekly/WeeklyFile'
import AlertContractCatLegacy from '../../components/Alerts/AlertContractCatLegacy'
import { t } from '../../../_i18n'
import {isSpanishUser} from "../../../_common/utils/getLang";
import AlertBox from "../../../_common/components/AlertBox/AlertBox";

const STATE_1 = '4.1'
const STATE_2 = '4.2'
const STATE_3 = '4.3'
const STATE_4 = '4.4'

export default function StartedFct () {
  const { id } = useParams()
  const {screenStatus, static_documents} = useSelector(state => ({
    screenStatus: state.fct.screenState,
    static_documents:  state.fct.static_documents
  }))
  const screenState = screenStatus.screen
  const isCat = screenState === STATE_1 || screenState === STATE_2
  const shopId = useSelector(state => state.status.student_meta.shop_id)
  const disableSendFinalEvaluationAlert = screenStatus.screen_data.final_docs_received

  return (
    <div>
      { isCat
       ? <Fragment>
          {screenState === STATE_1 && !screenStatus.legacy && <p>{t('Convenio de prácticas:')} <span className='ftp-link font-bold' onClick={() => downloadFileFtp(parseInt(screenStatus.screen_data.contract_details.contract_download_link.split('/').slice(-1)[0]), screenStatus.screen_data.contract_details.contract_pdf_name)}>{screenStatus.screen_data.contract_details.contract_pdf_name}</span></p>}
          {screenState === STATE_1 && !screenStatus.legacy && !(screenStatus.screen_data.contract_details.contract_received) && <AlertContractCat className='m-top' date={DateTime.fromISO(screenStatus.screen_data.contract_details.start_date).plus({days: 15}).toFormat('dd/MM/yyyy')} />}
          {screenState === STATE_1 && screenStatus.legacy && <AlertContractCatLegacy />}
          {screenState === STATE_2 && !disableSendFinalEvaluationAlert && <AlertSurvey />}
           <div className='m-top'>
             <ul>
               <li className='m-top'>{t('Fecha de inicio:')} {DateTime.fromISO(screenStatus.screen_data.contract_details.start_date).toFormat('dd/MM/yyyy')}</li>
               <li className='m-top'>{t('Fecha de fin:')} {DateTime.fromISO(screenStatus.screen_data.contract_details.final_date).toFormat('dd/MM/yyyy')}</li>
               <li className='m-top'>
                 {t('Durante la realización de las prácticas, tienes que acceder al aplicativo Qbid y registrar las horas y las tareas que vas realizando cada día')}
                 {isSpanishUser(shopId) &&
                     (
                         <Fragment>
                           <span> (</span>
                           <a href={static_documents.qbid.url} target='_blank'>{t('Guía de uso Qbid')}</a>
                           <span> | </span>
                           <a href="https://www.youtube.com/watch?v=B48EOukOVSI"
                              target='_blank'>{t('Vídeo explicativo')}</a>
                           <span>)</span>
                         </Fragment>
                     )}
                 {!isSpanishUser(shopId) && shopId === 24 && (<a href="https://www.youtube.com/watch?v=F7zSxbk1fkA" target='_blank'>({t('Vídeo explicativo')})</a>)}
                 {!isSpanishUser(shopId) && shopId === 44 && (<a href={static_documents.qbid.url + '_fr'} target='_blank'>({t('Guía de uso Qbid')})</a>)}
               </li>
               { screenState === STATE_1 && <li className='m-top'>{t('Cuando queden 15 días para finalizar tus prácticas, tendrás que subir las')} <span className='font-bold'>{t('valoraciones finales')}.</span></li> }
             </ul>
           </div>
         </Fragment>
       : <Fragment>
          {screenState === STATE_4 && <AlertAnnexed />}
          <ul>
            <li className='m-top'>{t('Fecha de inicio:')} {DateTime.fromISO(screenStatus.screen_data.contract_details.start_date).toFormat('dd/MM/yyyy')}</li>
            <li className='m-top'>{t('Fecha de fin:')} {DateTime.fromISO(screenStatus.screen_data.contract_details.final_date).toFormat('dd/MM/yyyy')}</li>
            <li className='m-top'>{t('Cada 15 días tendrás que enviarnos la ‘Ficha semanal del alumno (Anexo 7)’ firmado por tu tutor, para hacer seguimiento.')}</li>
            { screenState === STATE_3 && <li className='m-top'>{t('Cuando queden 10 días para finalizar tus prácticas, tendrás que enviarnos por correo postal el')} <a href={static_documents.tutor.url}>‘Anexo 8 (Informe del tutor del centro de trabajo)’</a> {t('firmado por tu tutor.')}</li> }
            { screenState === STATE_3 && !isSpanishUser(shopId) &&
                <AlertBox type={"info"}>
                  {t('fct_international_help')}
                  <a href={t('fct_anexo_8_tutor_help_document_link')}>{t('fct_anexo_8_tutor_international_help_document')}.</a>
                </AlertBox>
            }
          </ul>
          <div className='m-top-x2'>
            <hr className='separator-xxs'/>
            <h1 className='m-top'>{t('Fichas semanales')}</h1>
            <ol>
              <li>
                {t('Descárgate la')} <a href={static_documents.weekly.url}>‘{t('Ficha semana del alumno (Anexo 7)')}’</a> {t('e imprímela.')}
                { !isSpanishUser(shopId) &&
                    <AlertBox type={"info"}>
                      {t('fct_international_help')}
                      <a href={t('fct_anexo_7_semana_alumno_tutor_help_document_link')}>{t('fct_anexo_7_semana_alumno_international_help_document')}.</a>
                    </AlertBox>
                }
              </li>
              <li>{t('Junto a tu tutor de prácticas tenéis que rellenarla y firmarla.')}</li>
              <li>{t('Escanéala de nuevo y súbela aquí:')}</li>
            </ol>
            <UploadDocumentWeekly contractId={id} typeDocument='weekly' className='m-top'/>
            <p className='font-bold m-top-x2'>{t('Fichas semanales subidas:')}</p>
            {screenStatus.screen_data.weekly_docs.length
              ? <div> {screenStatus.screen_data.weekly_docs.map(file => <WeeklyFile contractId={id} id={file.id} name={file.file_name} url={file.download_link} />)} </div>
              : <p>{t('Todavía no has subido ninguna ficha semanal')}</p>
            }
          </div>
        </Fragment>
      }
    </div>
  )
}
