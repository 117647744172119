/**
 * New Expedient
 * Expedient for Student Account
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AcademicProgramSchoolCenter from './AcademicProgramSchoolCenter';
import Certificate from './Certificate';

/**
 * The AcademicProgram model module.
 * @module model/AcademicProgram
 * @version 1.0
 */
class AcademicProgram {
    /**
     * Constructs a new <code>AcademicProgram</code>.
     * @alias module:model/AcademicProgram
     * @param id {Number} 
     * @param title {String} 
     * @param educationLawId {Number} 
     * @param educationLawTitle {String} 
     * @param approved {Boolean} 
     * @param grade {String} 
     * @param totalHours {Number} 
     * @param doneHours {Number} 
     * @param average {Number} 
     * @param allowToDownloadAttendance {Boolean} 
     * @param allowToDownloadGradeCertificate {Boolean} 
     * @param modules {Array.<Object>} 
     */
    constructor(id, title, educationLawId, educationLawTitle, approved, grade, totalHours, doneHours, average, allowToDownloadAttendance, allowToDownloadGradeCertificate, modules) { 
        
        AcademicProgram.initialize(this, id, title, educationLawId, educationLawTitle, approved, grade, totalHours, doneHours, average, allowToDownloadAttendance, allowToDownloadGradeCertificate, modules);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, title, educationLawId, educationLawTitle, approved, grade, totalHours, doneHours, average, allowToDownloadAttendance, allowToDownloadGradeCertificate, modules) { 
        obj['id'] = id;
        obj['title'] = title;
        obj['education_law_id'] = educationLawId;
        obj['education_law_title'] = educationLawTitle;
        obj['approved'] = approved;
        obj['grade'] = grade;
        obj['total_hours'] = totalHours;
        obj['done_hours'] = doneHours;
        obj['average'] = average;
        obj['allow_to_download_attendance'] = allowToDownloadAttendance;
        obj['allow_to_download_grade_certificate'] = allowToDownloadGradeCertificate;
        obj['modules'] = modules;
    }

    /**
     * Constructs a <code>AcademicProgram</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AcademicProgram} obj Optional instance to populate.
     * @return {module:model/AcademicProgram} The populated <code>AcademicProgram</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AcademicProgram();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('education_law_id')) {
                obj['education_law_id'] = ApiClient.convertToType(data['education_law_id'], 'Number');
            }
            if (data.hasOwnProperty('education_law_title')) {
                obj['education_law_title'] = ApiClient.convertToType(data['education_law_title'], 'String');
            }
            if (data.hasOwnProperty('formative_zone')) {
                obj['formative_zone'] = ApiClient.convertToType(data['formative_zone'], 'String');
            }
            if (data.hasOwnProperty('approved')) {
                obj['approved'] = ApiClient.convertToType(data['approved'], 'Boolean');
            }
            if (data.hasOwnProperty('grade')) {
                obj['grade'] = ApiClient.convertToType(data['grade'], 'String');
            }
            if (data.hasOwnProperty('total_hours')) {
                obj['total_hours'] = ApiClient.convertToType(data['total_hours'], 'Number');
            }
            if (data.hasOwnProperty('done_hours')) {
                obj['done_hours'] = ApiClient.convertToType(data['done_hours'], 'Number');
            }
            if (data.hasOwnProperty('average')) {
                obj['average'] = ApiClient.convertToType(data['average'], 'Number');
            }
            if (data.hasOwnProperty('total_hours_without_FCT')) {
                obj['total_hours_without_FCT'] = ApiClient.convertToType(data['total_hours_without_FCT'], 'Number');
            }
            if (data.hasOwnProperty('done_hours_without_FCT')) {
                obj['done_hours_without_FCT'] = ApiClient.convertToType(data['done_hours_without_FCT'], 'Number');
            }
            if (data.hasOwnProperty('average_without_FCT')) {
                obj['average_without_FCT'] = ApiClient.convertToType(data['average_without_FCT'], 'Number');
            }
            if (data.hasOwnProperty('allow_to_download_attendance')) {
                obj['allow_to_download_attendance'] = ApiClient.convertToType(data['allow_to_download_attendance'], 'Boolean');
            }
            if (data.hasOwnProperty('allow_to_download_grade_certificate')) {
                obj['allow_to_download_grade_certificate'] = ApiClient.convertToType(data['allow_to_download_grade_certificate'], 'Boolean');
            }
            if (data.hasOwnProperty('is_blendelrd')) {
                obj['is_blendelrd'] = ApiClient.convertToType(data['is_blendelrd'], 'Boolean');
            }
            if (data.hasOwnProperty('schoolCenter')) {
                obj['schoolCenter'] = AcademicProgramSchoolCenter.constructFromObject(data['schoolCenter']);
            }
            if (data.hasOwnProperty('allow_buy_grade_certificate_taxes')) {
                obj['allow_buy_grade_certificate_taxes'] = ApiClient.convertToType(data['allow_buy_grade_certificate_taxes'], 'Boolean');
            }
            if (data.hasOwnProperty('certificates')) {
                obj['certificates'] = ApiClient.convertToType(data['certificates'], [Certificate]);
            }
            if (data.hasOwnProperty('modules')) {
                obj['modules'] = ApiClient.convertToType(data['modules'], [Object]);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
AcademicProgram.prototype['id'] = undefined;

/**
 * @member {String} title
 */
AcademicProgram.prototype['title'] = undefined;

/**
 * @member {Number} education_law_id
 */
AcademicProgram.prototype['education_law_id'] = undefined;

/**
 * @member {String} education_law_title
 */
AcademicProgram.prototype['education_law_title'] = undefined;

/**
 * @member {String} formative_zone
 */
AcademicProgram.prototype['formative_zone'] = undefined;

/**
 * @member {Boolean} approved
 */
AcademicProgram.prototype['approved'] = undefined;

/**
 * @member {String} grade
 */
AcademicProgram.prototype['grade'] = undefined;

/**
 * @member {Number} total_hours
 */
AcademicProgram.prototype['total_hours'] = undefined;

/**
 * @member {Number} done_hours
 */
AcademicProgram.prototype['done_hours'] = undefined;

/**
 * @member {Number} average
 */
AcademicProgram.prototype['average'] = undefined;

/**
 * @member {Number} total_hours_without_FCT
 */
AcademicProgram.prototype['total_hours_without_FCT'] = undefined;

/**
 * @member {Number} done_hours_without_FCT
 */
AcademicProgram.prototype['done_hours_without_FCT'] = undefined;

/**
 * @member {Number} average_without_FCT
 */
AcademicProgram.prototype['average_without_FCT'] = undefined;

/**
 * @member {Boolean} allow_to_download_attendance
 */
AcademicProgram.prototype['allow_to_download_attendance'] = undefined;

/**
 * @member {Boolean} allow_to_download_grade_certificate
 */
AcademicProgram.prototype['allow_to_download_grade_certificate'] = undefined;

/**
 * @member {Boolean} is_blendelrd
 */
AcademicProgram.prototype['is_blendelrd'] = undefined;

/**
 * @member {module:model/AcademicProgramSchoolCenter} schoolCenter
 */
AcademicProgram.prototype['schoolCenter'] = undefined;

/**
 * @member {Boolean} allow_buy_grade_certificate_taxes
 */
AcademicProgram.prototype['allow_buy_grade_certificate_taxes'] = undefined;

/**
 * @member {Array.<module:model/Certificate>} certificates
 */
AcademicProgram.prototype['certificates'] = undefined;

/**
 * @member {Array.<Object>} modules
 */
AcademicProgram.prototype['modules'] = undefined;






export default AcademicProgram;

