/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Segment model module.
 * @module model/Segment
 * @version 1.0
 */
class Segment {
    /**
     * Constructs a new <code>Segment</code>.
     * @alias module:model/Segment
     */
    constructor() { 
        
        Segment.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Segment</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Segment} obj Optional instance to populate.
     * @return {module:model/Segment} The populated <code>Segment</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Segment();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('count')) {
                obj['count'] = ApiClient.convertToType(data['count'], 'Number');
            }
            if (data.hasOwnProperty('academic_section_id')) {
                obj['academic_section_id'] = ApiClient.convertToType(data['academic_section_id'], ['Number']);
            }
            if (data.hasOwnProperty('academic_program_id')) {
                obj['academic_program_id'] = ApiClient.convertToType(data['academic_program_id'], ['Number']);
            }
            if (data.hasOwnProperty('formative_action_id')) {
                obj['formative_action_id'] = ApiClient.convertToType(data['formative_action_id'], ['Number']);
            }
            if (data.hasOwnProperty('shop_id')) {
                obj['shop_id'] = ApiClient.convertToType(data['shop_id'], ['Number']);
            }
            if (data.hasOwnProperty('is_new_student')) {
                obj['is_new_student'] = ApiClient.convertToType(data['is_new_student'], ['Number']);
            }
            if (data.hasOwnProperty('is_semi_new_student')) {
                obj['is_semi_new_student'] = ApiClient.convertToType(data['is_semi_new_student'], ['Number']);
            }
            if (data.hasOwnProperty('is_premium')) {
                obj['is_premium'] = ApiClient.convertToType(data['is_premium'], ['Number']);
            }
            if (data.hasOwnProperty('family_id')) {
                obj['family_id'] = ApiClient.convertToType(data['family_id'], ['Number']);
            }
            if (data.hasOwnProperty('is_presential_inscribed')) {
                obj['is_presential_inscribed'] = ApiClient.convertToType(data['is_presential_inscribed'], ['Number']);
            }
            if (data.hasOwnProperty('is_presential_assisted')) {
                obj['is_presential_assisted'] = ApiClient.convertToType(data['is_presential_assisted'], ['Number']);
            }
            if (data.hasOwnProperty('exam_inscribed_status')) {
                obj['exam_inscribed_status'] = ApiClient.convertToType(data['exam_inscribed_status'], ['Number']);
            }
            if (data.hasOwnProperty('has_extraordinary_pending')) {
                obj['has_extraordinary_pending'] = ApiClient.convertToType(data['has_extraordinary_pending'], ['Number']);
            }
            if (data.hasOwnProperty('has_convas')) {
                obj['has_convas'] = ApiClient.convertToType(data['has_convas'], ['Number']);
            }
            if (data.hasOwnProperty('has_fct')) {
                obj['has_fct'] = ApiClient.convertToType(data['has_fct'], ['Number']);
            }
            if (data.hasOwnProperty('is_old_student')) {
                obj['is_old_student'] = ApiClient.convertToType(data['is_old_student'], ['Number']);
            }
            if (data.hasOwnProperty('has_presential')) {
                obj['has_presential'] = ApiClient.convertToType(data['has_presential'], ['Number']);
            }
            if (data.hasOwnProperty('province')) {
                obj['province'] = ApiClient.convertToType(data['province'], ['Number']);
            }
            if (data.hasOwnProperty('has_finished')) {
                obj['has_finished'] = ApiClient.convertToType(data['has_finished'], ['Number']);
            }
            if (data.hasOwnProperty('emails')) {
                obj['emails'] = ApiClient.convertToType(data['emails'], 'String');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'String');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'String');
            }
        }
        return obj;
    }


}

/**
 * Class Segment
 * @member {Number} id
 */
Segment.prototype['id'] = undefined;

/**
 * Class Segment
 * @member {String} name
 */
Segment.prototype['name'] = undefined;

/**
 * Class Segment
 * @member {Number} count
 */
Segment.prototype['count'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} academic_section_id
 */
Segment.prototype['academic_section_id'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} academic_program_id
 */
Segment.prototype['academic_program_id'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} formative_action_id
 */
Segment.prototype['formative_action_id'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} shop_id
 */
Segment.prototype['shop_id'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} is_new_student
 */
Segment.prototype['is_new_student'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} is_semi_new_student
 */
Segment.prototype['is_semi_new_student'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} is_premium
 */
Segment.prototype['is_premium'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} family_id
 */
Segment.prototype['family_id'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} is_presential_inscribed
 */
Segment.prototype['is_presential_inscribed'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} is_presential_assisted
 */
Segment.prototype['is_presential_assisted'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} exam_inscribed_status
 */
Segment.prototype['exam_inscribed_status'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} has_extraordinary_pending
 */
Segment.prototype['has_extraordinary_pending'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} has_convas
 */
Segment.prototype['has_convas'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} has_fct
 */
Segment.prototype['has_fct'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} is_old_student
 */
Segment.prototype['is_old_student'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} has_presential
 */
Segment.prototype['has_presential'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} province
 */
Segment.prototype['province'] = undefined;

/**
 * Class Segment
 * @member {Array.<Number>} has_finished
 */
Segment.prototype['has_finished'] = undefined;

/**
 * Class Segment
 * @member {String} emails
 */
Segment.prototype['emails'] = undefined;

/**
 * Class Segment
 * @member {String} created_at
 */
Segment.prototype['created_at'] = undefined;

/**
 * Class Segment
 * @member {String} updated_at
 */
Segment.prototype['updated_at'] = undefined;






export default Segment;

