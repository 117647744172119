import { Fragment, h } from 'preact'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { t } from '../../../_i18n'

export default function RequestPostponement () {
  const { pathname } = useLocation()

  return (
    <div className='m-top-x2'>
      <hr className='separator-xxs'/>
      <div>
        <Link to={`${pathname}/solicitar-aplazamiento`}><span className='t-underline cursor-pointer font-bold width-fit-content'>{t('Solicitar aplazamiento de las prácticas')}</span></Link>
      </div>
    </div>
  )
}
