/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApplicationsCount from '../model/ApplicationsCount';
import CandidateOfferItemPaginated from '../model/CandidateOfferItemPaginated';
import CompanyApplication from '../model/CompanyApplication';
import CompanyApplicationFilers from '../model/CompanyApplicationFilers';
import CompanyApplicationItem from '../model/CompanyApplicationItem';
import CompanyCompany from '../model/CompanyCompany';
import CompanyOffer from '../model/CompanyOffer';
import CompanyOfferFilters from '../model/CompanyOfferFilters';
import CompanyPaginatedOfferItems from '../model/CompanyPaginatedOfferItems';
import CompanyUser from '../model/CompanyUser';
import DefaultResponse from '../model/DefaultResponse';
import OffersCount from '../model/OffersCount';
import Status from '../model/Status';

/**
* Company service.
* @module api/CompanyApi
* @version v1
*/
export default class CompanyApi {

    /**
    * Constructs a new CompanyApi. 
    * @alias module:api/CompanyApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * [P_E_07]
     * @param {Number} id Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyApplication} and HTTP response
     */
    companyApplicationsIdGetWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling companyApplicationsIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CompanyApplication;
      return this.apiClient.callApi(
        '/company/applications/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * [P_E_07]
     * @param {Number} id Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyApplication}
     */
    companyApplicationsIdGet(id) {
      return this.companyApplicationsIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Cambia el status de la candidatura y guarda un event [P_E_07]
     * @param {Number} id Id
     * @param {Object} opts Optional parameters
     * @param {module:model/Status} opts.status 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Boolean} and HTTP response
     */
    companyApplicationsIdPutWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['status'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling companyApplicationsIdPut");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/company/applications/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Cambia el status de la candidatura y guarda un event [P_E_07]
     * @param {Number} id Id
     * @param {Object} opts Optional parameters
     * @param {module:model/Status} opts.status 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Boolean}
     */
    companyApplicationsIdPut(id, opts) {
      return this.companyApplicationsIdPutWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Perfil de la empresa logueada [P_E_16]
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyCompany} and HTTP response
     */
    companyCompanyGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CompanyCompany;
      return this.apiClient.callApi(
        '/company/company', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Perfil de la empresa logueada [P_E_16]
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyCompany}
     */
    companyCompanyGet() {
      return this.companyCompanyGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * [P_E_16]
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyCompany} opts.companyCompany 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Boolean} and HTTP response
     */
    companyCompanyPostWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['companyCompany'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/company/company', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * [P_E_16]
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyCompany} opts.companyCompany 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Boolean}
     */
    companyCompanyPost(opts) {
      return this.companyCompanyPostWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * [P_E_07]
     * @param {Number} id Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
     */
    companyDocumentIdDownloadGetWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling companyDocumentIdDownloadGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/pdf'];
      let returnType = File;
      return this.apiClient.callApi(
        '/company/document/{id}/download', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * [P_E_07]
     * @param {Number} id Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
     */
    companyDocumentIdDownloadGet(id) {
      return this.companyDocumentIdDownloadGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Subida del un nuevo logo de empresa
     * @param {Object} opts Optional parameters
     * @param {File} opts.file 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DefaultResponse} and HTTP response
     */
    companyLogoPostWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': opts['file']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = DefaultResponse;
      return this.apiClient.callApi(
        '/company/logo', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Subida del un nuevo logo de empresa
     * @param {Object} opts Optional parameters
     * @param {File} opts.file 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DefaultResponse}
     */
    companyLogoPost(opts) {
      return this.companyLogoPostWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Contador ofertas para filtro
     * @param {Object} opts Optional parameters
     * @param {String} opts.modalities tipo
     * @param {String} opts.presences tipo de presencia
     * @param {String} opts.states 
     * @param {String} opts.academicPrograms 
     * @param {Boolean} opts.archived 
     * @param {Number} opts.status 
     * @param {Number} opts.page 
     * @param {Number} opts.limit 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OffersCount} and HTTP response
     */
    companyOffersCountGetWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'modalities': opts['modalities'],
        'presences': opts['presences'],
        'states': opts['states'],
        'academic_programs': opts['academicPrograms'],
        'archived': opts['archived'],
        'status': opts['status'],
        'page': opts['page'],
        'limit': opts['limit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = OffersCount;
      return this.apiClient.callApi(
        '/company/offers_count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Contador ofertas para filtro
     * @param {Object} opts Optional parameters
     * @param {String} opts.modalities tipo
     * @param {String} opts.presences tipo de presencia
     * @param {String} opts.states 
     * @param {String} opts.academicPrograms 
     * @param {Boolean} opts.archived 
     * @param {Number} opts.status 
     * @param {Number} opts.page 
     * @param {Number} opts.limit 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OffersCount}
     */
    companyOffersCountGet(opts) {
      return this.companyOffersCountGetWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Filter data offer company
     * @param {Object} opts Optional parameters
     * @param {Number} opts.status 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyOfferFilters} and HTTP response
     */
    companyOffersFiltersGetWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'status': opts['status']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CompanyOfferFilters;
      return this.apiClient.callApi(
        '/company/offers_filters', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Filter data offer company
     * @param {Object} opts Optional parameters
     * @param {Number} opts.status 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyOfferFilters}
     */
    companyOffersFiltersGet(opts) {
      return this.companyOffersFiltersGetWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Lista de las ofertas de la empresa logueada [P_E_01] [P_E_02] [P_E_03]
     * @param {Object} opts Optional parameters
     * @param {String} opts.modalities tipo
     * @param {String} opts.presences tipo de presencia
     * @param {String} opts.states 
     * @param {String} opts.academicPrograms 
     * @param {Boolean} opts.archived 
     * @param {Number} opts.status 
     * @param {Number} opts.page 
     * @param {Number} opts.limit 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyPaginatedOfferItems} and HTTP response
     */
    companyOffersGetWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'modalities': opts['modalities'],
        'presences': opts['presences'],
        'states': opts['states'],
        'academic_programs': opts['academicPrograms'],
        'archived': opts['archived'],
        'status': opts['status'],
        'page': opts['page'],
        'limit': opts['limit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CompanyPaginatedOfferItems;
      return this.apiClient.callApi(
        '/company/offers', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Lista de las ofertas de la empresa logueada [P_E_01] [P_E_02] [P_E_03]
     * @param {Object} opts Optional parameters
     * @param {String} opts.modalities tipo
     * @param {String} opts.presences tipo de presencia
     * @param {String} opts.states 
     * @param {String} opts.academicPrograms 
     * @param {Boolean} opts.archived 
     * @param {Number} opts.status 
     * @param {Number} opts.page 
     * @param {Number} opts.limit 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyPaginatedOfferItems}
     */
    companyOffersGet(opts) {
      return this.companyOffersGetWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Contador de inscritos dentro de la oferta [P_E_05] [P_E_06]
     * @param {Number} id Id
     * @param {Object} opts Optional parameters
     * @param {String} opts.status uno de: applied, accepted, discarded, selected
     * @param {String} opts.academicPrograms 
     * @param {Number} opts.minAcademicProgramPercent 
     * @param {Number} opts.maxAcademicProgramPercent 
     * @param {Number} opts.minQuestionsPercent 
     * @param {Number} opts.maxQuestionsPercent 
     * @param {String} opts.states 
     * @param {String} opts.order Order of the offers: killer-grade, finished, inscription, surname
     * @param {String} opts.orderType asc or desc
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ApplicationsCount} and HTTP response
     */
    companyOffersIdApplicationsCountGetWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling companyOffersIdApplicationsCountGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'status': opts['status'],
        'academic_programs': opts['academicPrograms'],
        'min_academic_program_percent': opts['minAcademicProgramPercent'],
        'max_academic_program_percent': opts['maxAcademicProgramPercent'],
        'min_questions_percent': opts['minQuestionsPercent'],
        'max_questions_percent': opts['maxQuestionsPercent'],
        'states': opts['states'],
        'order': opts['order'],
        'order_type': opts['orderType']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ApplicationsCount;
      return this.apiClient.callApi(
        '/company/offers/{id}/applications_count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Contador de inscritos dentro de la oferta [P_E_05] [P_E_06]
     * @param {Number} id Id
     * @param {Object} opts Optional parameters
     * @param {String} opts.status uno de: applied, accepted, discarded, selected
     * @param {String} opts.academicPrograms 
     * @param {Number} opts.minAcademicProgramPercent 
     * @param {Number} opts.maxAcademicProgramPercent 
     * @param {Number} opts.minQuestionsPercent 
     * @param {Number} opts.maxQuestionsPercent 
     * @param {String} opts.states 
     * @param {String} opts.order Order of the offers: killer-grade, finished, inscription, surname
     * @param {String} opts.orderType asc or desc
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ApplicationsCount}
     */
    companyOffersIdApplicationsCountGet(id, opts) {
      return this.companyOffersIdApplicationsCountGetWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Información de filtro para inscripciones
     * @param {Number} id Id
     * @param {Object} opts Optional parameters
     * @param {String} opts.status 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyApplicationFilers} and HTTP response
     */
    companyOffersIdApplicationsFiltersGetWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling companyOffersIdApplicationsFiltersGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'status': opts['status']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CompanyApplicationFilers;
      return this.apiClient.callApi(
        '/company/offers/{id}/applications_filters', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Información de filtro para inscripciones
     * @param {Number} id Id
     * @param {Object} opts Optional parameters
     * @param {String} opts.status 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyApplicationFilers}
     */
    companyOffersIdApplicationsFiltersGet(id, opts) {
      return this.companyOffersIdApplicationsFiltersGetWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Listado de inscritos dentro de la oferta [P_E_05] [P_E_06]
     * @param {Number} id Id
     * @param {Object} opts Optional parameters
     * @param {String} opts.status uno de: applied, accepted, discarded, selected
     * @param {String} opts.academicPrograms 
     * @param {Number} opts.minAcademicProgramPercent 
     * @param {Number} opts.maxAcademicProgramPercent 
     * @param {Number} opts.minQuestionsPercent 
     * @param {Number} opts.maxQuestionsPercent 
     * @param {String} opts.states 
     * @param {String} opts.order Order of the offers: killer-grade, finished, inscription, surname
     * @param {String} opts.orderType asc or desc
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CompanyApplicationItem>} and HTTP response
     */
    companyOffersIdApplicationsGetWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling companyOffersIdApplicationsGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'status': opts['status'],
        'academic_programs': opts['academicPrograms'],
        'min_academic_program_percent': opts['minAcademicProgramPercent'],
        'max_academic_program_percent': opts['maxAcademicProgramPercent'],
        'min_questions_percent': opts['minQuestionsPercent'],
        'max_questions_percent': opts['maxQuestionsPercent'],
        'states': opts['states'],
        'order': opts['order'],
        'order_type': opts['orderType']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [CompanyApplicationItem];
      return this.apiClient.callApi(
        '/company/offers/{id}/applications', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Listado de inscritos dentro de la oferta [P_E_05] [P_E_06]
     * @param {Number} id Id
     * @param {Object} opts Optional parameters
     * @param {String} opts.status uno de: applied, accepted, discarded, selected
     * @param {String} opts.academicPrograms 
     * @param {Number} opts.minAcademicProgramPercent 
     * @param {Number} opts.maxAcademicProgramPercent 
     * @param {Number} opts.minQuestionsPercent 
     * @param {Number} opts.maxQuestionsPercent 
     * @param {String} opts.states 
     * @param {String} opts.order Order of the offers: killer-grade, finished, inscription, surname
     * @param {String} opts.orderType asc or desc
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CompanyApplicationItem>}
     */
    companyOffersIdApplicationsGet(id, opts) {
      return this.companyOffersIdApplicationsGetWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} id Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyOffer} and HTTP response
     */
    companyOffersIdGetWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling companyOffersIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CompanyOffer;
      return this.apiClient.callApi(
        '/company/offers/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} id Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyOffer}
     */
    companyOffersIdGet(id) {
      return this.companyOffersIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Guardar oferta
     * @param {Number} id Id
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyOffer} opts.companyOffer 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Boolean} and HTTP response
     */
    companyOffersIdPutWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['companyOffer'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling companyOffersIdPut");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/company/offers/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Guardar oferta
     * @param {Number} id Id
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyOffer} opts.companyOffer 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Boolean}
     */
    companyOffersIdPut(id, opts) {
      return this.companyOffersIdPutWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Publicar oferta
     * @param {Number} id Id
     * @param {Object} opts Optional parameters
     * @param {module:model/Status} opts.status 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Boolean} and HTTP response
     */
    companyOffersIdStatusPutWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['status'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling companyOffersIdStatusPut");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/company/offers/{id}/status', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Publicar oferta
     * @param {Number} id Id
     * @param {Object} opts Optional parameters
     * @param {module:model/Status} opts.status 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Boolean}
     */
    companyOffersIdStatusPut(id, opts) {
      return this.companyOffersIdStatusPutWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.applied Para ver solo tus candidaturas [P_A_11]
     * @param {Number} opts.company Para ver solo las ofertas de una empresa. [P_A_31]
     * @param {String} opts.modalities tipo
     * @param {String} opts.presences tipo de presencia
     * @param {String} opts.states 
     * @param {String} opts.academicPrograms 
     * @param {String} opts.status Lista númerica de estados separados por comas
     * @param {Number} opts.page Número de página
     * @param {Number} opts.limit Cantidad de items por página
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CandidateOfferItemPaginated} and HTTP response
     */
    companyOffersLikeCandidateGetWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'applied': opts['applied'],
        'company': opts['company'],
        'modalities': opts['modalities'],
        'presences': opts['presences'],
        'states': opts['states'],
        'academic_programs': opts['academicPrograms'],
        'status': opts['status'],
        'page': opts['page'],
        'limit': opts['limit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CandidateOfferItemPaginated;
      return this.apiClient.callApi(
        '/company/offers_like_candidate', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.applied Para ver solo tus candidaturas [P_A_11]
     * @param {Number} opts.company Para ver solo las ofertas de una empresa. [P_A_31]
     * @param {String} opts.modalities tipo
     * @param {String} opts.presences tipo de presencia
     * @param {String} opts.states 
     * @param {String} opts.academicPrograms 
     * @param {String} opts.status Lista númerica de estados separados por comas
     * @param {Number} opts.page Número de página
     * @param {Number} opts.limit Cantidad de items por página
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CandidateOfferItemPaginated}
     */
    companyOffersLikeCandidateGet(opts) {
      return this.companyOffersLikeCandidateGetWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Nueva oferta
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyOffer} opts.companyOffer 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Boolean} and HTTP response
     */
    companyOffersPostWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['companyOffer'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/company/offers', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Nueva oferta
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyOffer} opts.companyOffer 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Boolean}
     */
    companyOffersPost(opts) {
      return this.companyOffersPostWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Perfil del usuario logueado [P_E_15]
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyUser} and HTTP response
     */
    companyUserGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CompanyUser;
      return this.apiClient.callApi(
        '/company/user', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Perfil del usuario logueado [P_E_15]
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyUser}
     */
    companyUserGet() {
      return this.companyUserGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Perfil del usuario logueado [P_E_15]
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyUser} opts.companyUser 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DefaultResponse} and HTTP response
     */
    companyUserPostWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['companyUser'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DefaultResponse;
      return this.apiClient.callApi(
        '/company/user', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Perfil del usuario logueado [P_E_15]
     * @param {Object} opts Optional parameters
     * @param {module:model/CompanyUser} opts.companyUser 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DefaultResponse}
     */
    companyUserPost(opts) {
      return this.companyUserPostWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Ver logo
     * @param {String} logo Archivo logo a visualizar
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
     */
    companyViewLogoLogoGetWithHttpInfo(logo) {
      let postBody = null;
      // verify the required parameter 'logo' is set
      if (logo === undefined || logo === null) {
        throw new Error("Missing the required parameter 'logo' when calling companyViewLogoLogoGet");
      }

      let pathParams = {
        'logo': logo
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/octet-stream'];
      let returnType = File;
      return this.apiClient.callApi(
        '/company/view-logo/{logo}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Ver logo
     * @param {String} logo Archivo logo a visualizar
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
     */
    companyViewLogoLogoGet(logo) {
      return this.companyViewLogoLogoGetWithHttpInfo(logo)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
