import {t} from './_i18n';
import {toastService} from './_services/toast-service';

export function formatDate(date) {
    const monthKeys = {0: 'ene', 1: 'feb', 2: 'mar', 3: 'abr', 4: 'may', 5: 'jun', 6: 'jul', 7: 'ago', 8: 'sep', 9: 'oct', 10: 'nov', 11: 'dic'}
    const dateObject = (typeof date === 'string') ? new Date(date) : date;
    const day = dateObject.getDate()
    const month = t(monthKeys[dateObject.getMonth()])
    const year = dateObject.getFullYear()
    return `${day} ${month} ${year}`;
}

export function formatPhoneByThree(phoneNumber) {
    if (typeof phoneNumber !== 'string') return phoneNumber
    const rest = phoneNumber.slice(9, 20)
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, 9)} ${rest}`;
}
export const isIphone = () => /iPhone|iPod/.test(navigator.userAgent)

export const downloadFile = ({url, fileName, method = 'GET', TOKEN}) =>{
    return fetch(url, {
        method: method,
        headers: {
            'Accept': 'application/pdf',
            'Authorization': 'Bearer ' + TOKEN,
        }
    }).then( res => {
        if (res.ok) {
            return res.blob().then( blob => {
                const input = document.createElement("a");
                input.href = URL.createObjectURL(blob);
                input.setAttribute("download", fileName);
                input.click();
                return res.ok
            })
        }else {
            toastService.showToast({message: t('Ha sucedido un error con la descarga del archivo'), type: 'danger'})
            return res
        }
    }).catch(
      error => toastService.showToast({message: t(error), type: 'danger'})
    );
}
