import {Component, Fragment, h} from "preact";
import {UploadFile} from "../../../_common/forms/components/components";
import {useState} from "preact/hooks";
import {isImageFile, resizeImage} from "../../../_common/common";
import {MAX_IMAGE_PIXELS} from "../../../constants";
import {connect} from "react-redux";
import {
    downloadReviewFile,
    uploadReviewFile
} from "../../../_rx/expediente/actions";
import {t} from "../../../_i18n";
import {Checkbox} from "../../../_common/forms/components/Checkbox/Checkbox";
import AlertBox from "../../../_common/components/AlertBox/AlertBox";


let GradeReview = ({module, handleSubmit, reviewParams, educationLaw, moduleMeta, lang, ...props}) => {
    const [file, setFile] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [acceptTerms, setAcceptTerms] = useState(false)
    const formData = new FormData()

    const handleDownloadFile = () => {
        props.downloadReviewFile(lang);
    }

    const handleUploadFile = async (event) => {
        let file = event.target.files[0]
        if(isImageFile(file)) file = await resizeImage(file, MAX_IMAGE_PIXELS);
        setFile(file)
    }

    const handleRemoveFile = () => setFile(null)

    const handleCheck = () => {
        setAcceptTerms(!acceptTerms)
    }

    const handleReviewSubmit = async () => {
        if (!isSubmitting){
            const enrollId = moduleMeta?.rawModule?.enroll_id ? moduleMeta?.rawModule?.enroll_id : reviewParams.enroll_id
            const examConvocationId = moduleMeta?.rawModule?.last_exam_convocation_id ? moduleMeta?.rawModule?.last_exam_convocation_id : reviewParams.exam_convocation_id
            setIsSubmitting(true)
            formData.append('file', file);
            formData.append('locale', lang);
            formData.append('enroll id', enrollId);
            formData.append('exam_convocation_id', examConvocationId);
            await props.uploadReviewFile(formData)
            setIsSubmitting(false)
            handleSubmit()
        }
    }


    return (
        <div>
            <h2>{t('Solicitar revisión')}</h2>
            <AlertBox type='warning' className='m-top'>
                <div dangerouslySetInnerHTML={{__html: t('apply_review_01')}}/>
            </AlertBox>
            <div className='m-top'>
                <div dangerouslySetInnerHTML={{__html: t('apply_review_02')}}/>
            </div>
            <button className='btn btn-ghost m-top m-bottom t-upper' onClick={handleDownloadFile}>
                {t('Descargar plantilla')}
            </button>
            <div className='card p-top p-left p-right'>
                <div className='translucent font-14'>{moduleMeta.abbr}</div>
                <div className='font-14'>{moduleMeta.title}</div>
                <hr className="separator-xxs"/>
                <div className='m-top font-14'><strong>{t('Documento de solicitud')}:</strong></div>
                    {!file
                        ? <div className='m-top'>
                            <UploadFile classNameButton='btn btn-ghost m-top m-bottom t-upper' accept='image/jpeg,image/png,application/pdf' onChange={handleUploadFile}>
                                {t('Subir archivo')}
                            </UploadFile>
                            <span className='m-top font-14 font-color-879CA1'>{t('apply_review_types')}</span>
                        </div>
                        : <div className='m-bottom font-14'>{file.name} <span className='font-14 t-underline m-l cursor-pointer' onClick={handleRemoveFile}>{t('Eliminar')}</span></div>
                    }
                <div className='m-top d-flex m-bottom'>
                    <div>
                        <Checkbox value={acceptTerms} onChange={handleCheck} />
                    </div>
                    <div className='p-left-half cursor-pointer' onClick={handleCheck}>{t('Acepto las condiciones de la revisión de examen, así como las consecuencias que puedan derivarse de ella.')}</div>
                </div>
                <button className='btn btn-ghost m-top m-bottom t-upper' disabled={!(acceptTerms && file)} onClick={handleReviewSubmit}>
                    {t('Solicitar revisión')}
                </button>
            </div>
        </div>
    )
}

GradeReview = connect(
    ({expedient: {educationLaw, reviewParams}, lang}) => ({educationLaw, reviewParams, lang}),
    {uploadReviewFile, downloadReviewFile})(GradeReview);

let GradeReviewContainer = ({...props}) => <GradeReview {...props}/>


export default GradeReviewContainer;
