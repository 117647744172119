import './expedienteCache-styles.scss'
import React, {Component, Fragment, h} from "preact";
import {t} from '../../../_i18n';
import { Card, CF, F } from '../../../_common/layout';
import {AppPage} from '../../../_common/components/app-page';
import {connect} from 'react-redux';
import Pingu from '../../../../assets/pingu-hi.png'
import { LANG_FR } from '../../../constants'

function ExpedienteCacheView ({ lang }) {

  return (
      <CF className="max-width expediente-cache">
        <div className='d-flex flex-space-between flex-center-y'>
          <h1 className='hero'>{t('Expediente académico')}</h1>
        </div>
        <Card className='d-flex flex-column flex-center-y p-x2-bottom m-top sm-no-m-top'>
          <img src={Pingu} alt='working'/>
          { lang === LANG_FR
            ? <Fragment>
                <p>Cet espace n'est pas disponible en ce moment. Vous pourrez bientôt y accéder à nouveau.</p>
              </Fragment>
            : <Fragment>
                <h1>{t('¡Hola, Onliner!')}</h1>
                <p className='t-center'>{t('El miércoles recibirás un Comunicado con las notas finales de cada UF. Si lo estás revisando y aún no lo tienes, danos tiempo, a lo largo del día lo recibirás.')}💙</p>
                <p className='t-center'>{t('El  jueves verás aquí publicadas tus calificaciones finales. Si necesitases solicitar la revisión de algún examen, podrás hacerlo desde este apartado.')}</p>
                <p>{t('Gracias por la espera ¡Ya estás en la recta final, todo esfuerzo tiene su recompensa!')}</p>
                <strong>{t('Tu equipo de ILERNA Online')}</strong>
              </Fragment>
          }
        </Card>
      </CF>
  )
}

ExpedienteCacheView = connect(
  ({ lang }) => ({ lang }),
  {})(ExpedienteCacheView);

let ExpedienteCache = ({match, ...props}) =>
  <AppPage
    name="expediente-cache"
    class="max-width">
    <ExpedienteCacheView match={match} {...props}/>

  </AppPage>
;

export default ExpedienteCache;
