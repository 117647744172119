import {h, Component, createRef, Fragment} from "preact";
import {R, E, RE} from "../layout";
import './add-image.scss';
import {downloadFile, generateDownload, isMobileAgent, readFileToURL, rndStr} from "../common";
import {t} from '../../_i18n';

import {Field} from 'react-final-form';
import {Modal, ModalLink} from "./modal.tsx";
import {handleDragAndDrop} from "../common";
import AppDispatch from "../../_services/app-dispatch";


export class AddImage extends Component{

    id1 = rndStr();
    id2 = rndStr();
    id3 = rndStr();
    id4 = rndStr();

    rndName = rndStr();
    isMobile = isMobileAgent();
    newImage = null;
    app_dispatch = new AppDispatch();

    state = {
        loading: false,
        newImage: null,
        newImageName: null,
        hasCameraPermission: isMobileApp ? null : true
    };

    fileUrl = ()=> this.state.newImage || this.props.image || this.props.placeholder;
    fileName = ()=> this.state.newImageName || this.props.imageName || '';
    hasFile = ()=> !!this.fileName();

    async modalOpened(){
        if(this.state.hasCameraPermission === null){
            const hasCameraPermission = await this.app_dispatch.dispatchToApp('requestCameraPermission', '', true);
            this.setState({hasCameraPermission});
        }
    }

    componentDidMount() {
        handleDragAndDrop();
    }

    async fileChanged(e){
        try {
            this.setState({loading: true});
            await this.props.uploadCb(e.target.files[0]);
            this.props.onChange && this.props.onChange(e.target.files[0]);
            const newImage = e.target.files[0]; //await readFileToURL(e.target.files[0]);
            const newImageName = e.target.files[0].name;
            let actualizar = true;
            await this.setState({newImage, newImageName, actualizar});
            if(window.location.hash) window.location.hash = '';
        }
        finally {
            this.setState({loading: false});
        }
    }

    download(e){
        if(this.state.newImage) {
            e.preventDefault();
            generateDownload(this.state.newImage, this.state.newImageName);
            return;
        }
        //dejamos que funcione el link normal
    }

    validate(){
        // finalform espera undefined o string con mensaje de error
        if(this.props.required && !this.hasFile()) return 'error';
    }

    render({name, image, imageName, title, placeholder, className, canDownload=true}) {
        if(!name) name = this.rndName;
        return title &&
            <Fragment>
                <Modal id={this.id1} title={title} className="add-image">
                    {!this.state.loading ? <Fragment>
                            <E className="d-flex flex-center-x">
                                <Field name={name}>{({input, meta}) =>
                                    <label className="fileContainer" htmlFor={this.id2}>
                                        <input id={this.id2} type="file" accept="image/*" capture
                                               onChange={(e => {this.fileChanged(e)})}
                                               disabled={!this.state.hasCameraPermission} />
                                        <i className="fa fa-camera"/> {t('Toma una foto')}
                                    </label>}
                                </Field>
                            </E>
                            <E className="d-flex flex-center-x">
                                <Field name={name}>{({input, meta}) =>
                                    <label className="fileContainer" htmlFor={this.id3}>
                                        <input id={this.id3} type="file" accept="image/*,.pdf,.docx,.doc,.odt"
                                               onChange={(e => {this.fileChanged(e)})}/>
                                        <i className="fa fa-file-upload"/> {t('Selecciona archivo')}
                                    </label>}
                                </Field>
                            </E>
                        </Fragment> :
                        <E>
                            <div className="progress-bar striped animated">
                                <span className="progress-bar-blue full-width" />
                            </div>
                        </E>}
                </Modal>
                {this.hasFile()?<p className="ellipsis m-top"><a href={this.state.newImageName||!image ?'#':image} className={canDownload?'':'disabled-aspect-gray disabled-link'} onClick={e=>this.download(e)} target="_blank">{this.fileName()}</a></p>:''}
                <span className="md-d-none m-top">
                    <Field name={name} validate={()=>this.validate()}>{({ meta}) =>
                    <ModalLink id={this.id1} onClick={()=>this.modalOpened()} className="t-underline">
                        <button type="button" className={"btn btn-ghost"  + (meta.submitFailed && meta.error?' danger':'')}>{t(this.hasFile()?'Actualizar':'Añadir foto')}</button>
                    </ModalLink>}</Field>
                </span>
                <span className="d-none md-d-flex m-top">
                    <Field name={name} validate={()=>this.validate()}>{({ meta}) =>
                    <label className={'btn btn-ghost' + (meta.submitFailed && meta.error?' danger':'')} htmlFor={this.id4}>
                        <input id={this.id4} className="d-none" type="file" accept="image/*,.pdf,.docx,.doc,.odt"
                               onChange={e =>{this.fileChanged(e);}}/>
                        {t(this.hasFile()?'Actualizar':'Añadir foto')}
                    </label>}</Field>
                </span>
            </Fragment>
    }
}
