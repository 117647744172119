import { Fragment, h } from "preact";
import { ButtonPrimary } from '../../../_common/components/buttons/buttons'
import { Link } from 'react-router-dom'
import {useLocation, useParams} from 'react-router'
import { useSelector } from 'react-redux'
import AlertBox from '../../../_common/components/AlertBox/AlertBox'
import { DateTime } from 'luxon'
import AlertLimitDateWarning from '../../components/Alerts/AlertLimitDateWarning'
import AlertLimitDateDanger from '../../components/Alerts/AlertLimitDateDanger'
import { t } from '../../../_i18n'
import AlertCommitmentFCT from "../../components/Alerts/AlertCommitmentFCT";
import {isSpanishUser} from "../../../_common/utils/getLang";
import useFctMeta from "../../hooks/useFctMeta";

const STATE_1 = '1.1'
const STATE_2 = '1.2'
const STATE_3 = '1.3'
const STATE_4 = '1.4'

function formatDateString(dateStr, inputFormat, outputFormat) {
  return DateTime.fromFormat(dateStr, inputFormat).toFormat(outputFormat);
}

function initializeAlerts(screenStatus) {
  const actualDate = DateTime.now();
  const dateHardLimit = DateTime.fromISO(screenStatus.screen_data.document_limits.hard_limit_date);
  const dateSoftLimit = DateTime.fromISO(screenStatus.screen_data.document_limits.soft_limit_date);
  const showWarningAlert = screenStatus.screen_data.document_limits.exists_limit && actualDate < dateSoftLimit;
  const showDangerAlert = screenStatus.screen_data.document_limits.exists_limit && actualDate < dateHardLimit && actualDate > dateSoftLimit;

  return { actualDate, dateHardLimit, dateSoftLimit, showWarningAlert, showDangerAlert };
}

function formatPeriodData(period) {
  const formattedContractValidation = formatDateString(period.contract_validation, 'yyyy-MM-dd', 'dd/MM/yyyy');
  const formattedDocuValidation = formatDateString(period.docu_validation, 'yyyy-MM-dd', 'dd/MM/yyyy');
  const formattedPeriodEnding = formatDateString(period.period_ending, 'yyyy-MM-dd', 'dd/MM/yyyy');
  const slots = period.slots;

  return {
    contract_validation: formattedContractValidation,
    docu_validation: formattedDocuValidation,
    period_ending: formattedPeriodEnding,
    slots: slots
  };
}

function initializeData(screenStatus) {
  const { actualDate, dateHardLimit, dateSoftLimit, showWarningAlert, showDangerAlert } = initializeAlerts(screenStatus);
  const screenState = screenStatus.screen
  const period = screenStatus.screen_data.period;
  const formattedPeriodData = formatPeriodData(period);

  return {
    actualDate,
    dateHardLimit,
    dateSoftLimit,
    showWarningAlert,
    showDangerAlert,
    screenState,
    formattedPeriodData
  };
}

export default function Documentation () {
  const { pathname } = useLocation();
  const screenStatus = useSelector(state => state.fct.screenState);
  const shopId = useSelector(state => state.status.student_meta.shop_id);
  const {
    actualDate,
    dateHardLimit,
    dateSoftLimit,
    showWarningAlert,
    showDangerAlert,
    screenState,
    formattedPeriodData
  } = initializeData(screenStatus);
  const { id } = useParams();
  const meta = useFctMeta(id);
  const formative_zone = meta.formative_zone;

  return (
    <Fragment>
      {screenState === STATE_1 && (
          <Fragment>
            { formative_zone !== 'MAD' && isSpanishUser(shopId) && <AlertCommitmentFCT data={formattedPeriodData}></AlertCommitmentFCT> }
            <div className='t-justify m-top-x2'>
              <p>{t('Para poder gestionar adecuadamente tus prácticas, necesitamos que proporciones la siguiente documentación:')}</p>
              <ul>
                <li className='m-top-half'><span className='font-bold'>{t('Datos del estudiante')}</span>: {t('Por favor, verifica y confirma la información que tenemos sobre ti, así como tus preferencias de horario para llevar a cabo las prácticas.')}</li>
                <li className='m-top-half'><span className='font-bold'>{t('Datos de la empresa')}</span>{t(' (solo si ya tienes una): Si ya tienes seleccionada la empresa donde realizarás las prácticas, te pedimos que nos proporciones los detalles correspondientes. En caso de que aún no tengas una empresa asignada, no te preocupes, nosotros nos encargaremos de encontrar una que se ajuste a tus necesidades.')}</li>
                <li className='m-top-half'><span className='font-bold'>{t('Solicitud de exención')}</span>: {t('Si cuentas con experiencia laboral previa, tienes la opción de solicitar una reducción parcial o total de las horas de prácticas.')}</li>
              </ul>
              {/*{showWarningAlert && <AlertLimitDateWarning date={dateSoftLimit.minus({ days: 1 })} />}*/}
              <Link to={`${pathname}/documentacion`}><ButtonPrimary className='m-top'>{t('Subir documentación')}</ButtonPrimary></Link>
            </div>
          </Fragment>
      )}
      {screenState === STATE_2 && (
          <div>
            <p>{t('Hemos recibido la documentación que has proporcionado de manera correcta.')}</p>
            { (formative_zone === 'MAD' || !isSpanishUser(shopId)) && <p className='font-bold'>{t('En cuanto la hayamos validado y se inicie el semestre, procederemos a los trámites pertinentes a tus prácticas.')}</p> }
            { formative_zone !== 'MAD' && isSpanishUser(shopId) && (
                <Fragment>
                  <p className='m-bottom'>{t('En breve validaremos y te informaremos si todo está correcto.')}</p>
                  <AlertBox type='commitment-fct'>
                    <ul className='commitment-list m-l'>
                      <li>{t('Validaremos la documentación antes del ')}<strong>{formattedPeriodData.period_ending}.</strong>
                      </li>
                      <li>{t('En caso de que la documentación esté completa y sin errores, nuestro objetivo es tener el convenio de prácticas firmado antes del ')}<strong>{formattedPeriodData.contract_validation}</strong>.
                      </li>
                    </ul>
                  </AlertBox>
                </Fragment>
            )}
          </div>
      )}
      {screenState === STATE_3 && (
          <div>
            <p>{t('Hemos detectado los siguientes errores en la documentación que has aportado:')} </p>
            <ul>
              {screenStatus.screen_data.document_errors.map(error => <li className='m-top-half'>{error}</li>)}
            </ul>
            <p>{t('Por favor, revísalos y envía de nuevo la documentación.')}</p>
            { formative_zone !== 'MAD' && isSpanishUser(shopId) && <AlertCommitmentFCT data={formattedPeriodData}></AlertCommitmentFCT> }
            <Link to={`${pathname}/documentacion`}><ButtonPrimary className='m-x2-top'>{t('Revisar documentación')}</ButtonPrimary></Link>
        </div>
      )}
      { screenState === STATE_4 && (
        <AlertBox type='warning' className='m-x2-bottom'>
          {t('Lamentablemente, no has subido la documentación a tiempo. Por eso, no podrás empezar las prácticas el próximo semestre. Te avisaremos de nuevo cuando esté abierto el nuevo plazo de documentación.')}
        </AlertBox>
      )}
    </Fragment>
  )
}
