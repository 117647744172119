import { asyncLazySingleton, getHttp } from '../_common/common'
import { LoginApi, MessagingApi, StatusApi, DocumentsApi, AdressApi, DocumentMetaApi, ContractManagementApi, StaticsApi } from '../_api/fct/src'


export const loginApi = new asyncLazySingleton(async ()=>{
  let result = await getHttp(micuentaenv.Fct_BasePath + '/api/v1/cas/login', null, true);

  if(result === false){
    result = await getHttp(micuentaenv.SSO_BaseUrl + '/get_st?service='+ encodeURIComponent(window.origin), null, true);
    if(result.st === false) window.location.href = micuentaenv.SSO_BaseUrl + '/login?service='+ encodeURIComponent(window.origin)

    result = await getHttp(micuentaenv.Fct_BasePath + '/api/v1/cas/login?ticket='+result.st, null, true);
    if(result.status !== 'OK') throw new Error('FCT Login failed');
  }
  return new LoginApi();
}) ;

export const statusApi = new asyncLazySingleton(async ()=>{
  return new StatusApi();
}) ;

export const contractManagementApi = new asyncLazySingleton(async ()=>{
  return new ContractManagementApi();
}) ;

export const messagingApi = new asyncLazySingleton(async ()=>{
  return new MessagingApi();
}) ;

export const documentApi = new asyncLazySingleton(async ()=>{
  return new DocumentsApi();
}) ;

export const staticDocumentApi = new asyncLazySingleton(async ()=>{
  return new StaticsApi();
}) ;

export const adressApi = new asyncLazySingleton(async ()=>{
  return new AdressApi();
}) ;

export const documentMetaApi = new asyncLazySingleton(async ()=>{
  return new DocumentMetaApi();
}) ;
