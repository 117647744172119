import React, {Fragment, h} from "preact";
import {t} from '../../../_i18n';
import {connect} from "react-redux";
import SideBarModal from "../../../_common/components/sideBarModal/sideBarModal";
import Badge from "../../../_common/components/Badge";
import DropdownBuildings from "../../../_common/components/DropdownBuildings/DropdownBuildings";
import {useEffect, useState} from "preact/hooks";
import {useParams} from "react-router";
import InputTime from "../components/InputTime/InputTime";
import '../../styles/enroll-sidebar-styles.scss'
import {
    enrollExam,
    getBuildings,
    getNextExams,
    getPastExams,
    getSchedules,
    getStatusExams,
    modifyEnroll
} from "../../../_rx/enrollment/exams/actions";
import Loading from "../../../_common/components/loading/Loading";
import {toastService} from "../../../_services/toast-service";
import ButtonSpinner from "../../../_common/components/buttons/buttonSpinner/buttonSpinner";
import useScroll from '../../hooks/useScroll';
import useSidebarLogic from '../../hooks/useSidebarLogic';
import AlertBox from "../../../_common/components/AlertBox/AlertBox";

function Sidebar({
                     visible,
                     handleCloseModal,
                     examSelected,
                     historyEventsStatus,
                     studentId,
                     getStatusExams,
                     getNextExams,
                     getPastExams,
                     examsStatus
                 }) {
    const {
        buildings,
        setBuildings,
        schedules,
        setSchedules,
        citySelected,
        setCitySelected,
        dateSelected,
        setDateSelected,
        disableSubmit,
        fetchBuildings,
        fetchSchedules
    } = useSidebarLogic()
    const [loading, setLoading] = useState(false)
    const [examConvocation, setExamConvocation] = useState(null)
    const isNextEvents = historyEventsStatus === 'next'
    const funcUpdateHistoryExams = isNextEvents ? getNextExams : getPastExams
    const {action} = useParams()
    const {focusDropdown} = useScroll()
    const isNewEnroll = action === 'apply'

    useEffect(async () => {
        if (visible) {
            const buildingsResp = await getBuildings({
                examConvocationId: examSelected.exam_convocation_id,
                studentId,
                formativeActionId: examSelected.formative_action_id
            })

            console.log(buildingsResp)

            await fetchBuildings(buildingsResp.buildings)
            setExamConvocation(buildingsResp.exam_convocation_id ?? examSelected.exam_convocation_id)

            if (!isNewEnroll) {
                if (buildings && buildings.find( (building) => building.id === examSelected.buildingSelected.building_id) !== undefined) {
                    await fetchSchedules(() => getSchedules({
                        examConvocationId: examConvocation,
                        buildingId: examSelected.buildingSelected.building_id,
                        studentId,
                        formativeActionId: examSelected.formative_action_id
                    }))

                    setCitySelected(examSelected.buildingSelected.building_id)
                    setDateSelected(examSelected.dateSelected)
                }
            }

        } else {
            setCitySelected(null)
            setBuildings(null)
            setSchedules([])
            setDateSelected(null)
        }
    }, [visible])

    const handleSetCity = async (newCitySelected) => {
        if (citySelected !== newCitySelected) {
            await fetchSchedules(() => getSchedules({
                examConvocationId: examConvocation,
                buildingId: newCitySelected,
                studentId,
                formativeActionId: examSelected.formative_action_id
            }))
            setCitySelected(newCitySelected)
            setDateSelected(null)
        }
    }

    const handleSubmitNewEnroll = async () => {
        setLoading(true)
        const {error} = await enrollExam({
            formativeActionId: examSelected.formative_action_id,
            eventCalendarId: dateSelected.event_calendar_id,
            eventId: dateSelected.event_id,
            studentId
        })
        if (error) toastService.showToast({
            message: t('La inscripción no se ha podido realizar. Por favor, vuelve a intentarlo.'),
            type: 'danger'
        });
        else {
            await getStatusExams(studentId)
            await funcUpdateHistoryExams(studentId)
            toastService.showToast({message: t('La inscripción se ha realizado correctamente.'), type: 'info'});
            handleCloseModal()
        }
        setLoading(false)
    }

    const handleSubmitModifyEnroll = async () => {
        setLoading(true)
        const {error} = await modifyEnroll({
            studentId,
            eventId: dateSelected.event_id,
            formativeActionId: examSelected.formative_action_id,
            eventCalendarId: dateSelected.event_calendar_id,
            oldEventId: examSelected.dateSelected.event_id
        })
        if (error) toastService.showToast({
            message: t('La modificación de la inscripción no se ha podido realizar. Por favor, vuelve a intentarlo.'),
            type: 'danger'
        });
        else {
            await getStatusExams(studentId)
            await funcUpdateHistoryExams(studentId)
            toastService.showToast({
                message: t('La modificación de la inscripción se ha realizado correctamente.'),
                type: 'info'
            });
            handleCloseModal()
        }
        setLoading(false)
    }

    return <SideBarModal visible={visible} onClose={handleCloseModal}>
        {visible && examSelected
            ? <Fragment>
                <div className='enrollment-sidebar-header'>
                    <div className='enrollment-sidebar-header__icon' onClick={handleCloseModal}>
                        <i className="fal fa-times "/>
                    </div>
                </div>
                <div className='enrollment-sidebar-content'>
                    <div className='m-l m-r enrollment-sidebar-container'>
                        <div className='font-32 font-medium p-top'>{t('Inscripción a examen')}</div>
                        <div
                            className='t-upper font-color-879CA1 font-14 m-top'>{examSelected.formative_action_code}</div>
                        <div className='font-18'>{examSelected.formative_action_title}</div>
                        <Badge type='warning' className='m-top-half'>{examSelected.exam_convocation_name}</Badge>
                        <hr className="separator-xs"/>
                        <div className='font-medium p-bottom-half building_section_title'>{t('Sede del examen')}:</div>
                        {buildings && buildings.length > 0 &&
                            <DropdownBuildings
                            defaultTitleSelector={t('Seleccionar una sede')}
                            alertDescription={<span dangerouslySetInnerHTML={{__html: t('exams_no_building_address_2')}}/>}
                            showAddress={examsStatus.next.show_event_address}
                            onClick={handleSetCity}
                            onOpenTransitionEnd={focusDropdown}
                            citySelectedId={citySelected}
                            items={buildings || []}
                            />
                        }
                        {buildings && buildings.length < 1 &&
                            <AlertBox type='info' className='m-top-half'>{t('no_buildings_available')}</AlertBox>
                        }

                        {citySelected &&
                            <div className='m-bottom'>
                                <div className='font-medium m-x2-top'>{t('Selecciona una opción')}:</div>
                                {schedules?.length > 0 && schedules?.map(dateItem =>
                                    <InputTime
                                        checked={dateSelected?.event_calendar_id === dateItem.event_calendar_id}
                                        date={dateItem}
                                        onClick={setDateSelected}
                                        isThisScheduleSelected={examSelected.dateSelected?.event_calendar_id === dateItem.event_calendar_id}
                                    />
                                )}

                                { schedules?.length < 1 &&
                                    <AlertBox type='info' className='m-top-half'>{t('no_events_available')}</AlertBox>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className='enrollment-sidebar-button-container'>
                    <ButtonSpinner
                        classNameContainer='full-width'
                        className='full-width'
                        onClick={isNewEnroll ? handleSubmitNewEnroll : handleSubmitModifyEnroll}
                        disabled={disableSubmit}
                        showSpinner={loading}
                    >
                        {t('Guardar Selección')}
                    </ButtonSpinner>
                </div>
            </Fragment>
            : <Loading label={`${t('Cargando')}...`}/>}
    </SideBarModal>
}


Sidebar = connect(
    ({enrollmentExams, auth}) => ({
        examSelected: enrollmentExams.examSelected,
        examsStatus: enrollmentExams.examsStatus,
        studentId: auth.loggedUser.id,
    }),
    {getStatusExams, getNextExams, getPastExams})(Sidebar);


export default ({...rest}) => <Sidebar {...rest} />
