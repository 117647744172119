import {Component, Fragment, h} from "preact";
import {t} from "../../_i18n";
import Summary from "../../_common/components/Summary/summary";


const ServicePolicy = () => <Fragment>
    <div className='t-upper m-x2-top translucent'>{t('acerca del servicio')}</div>
    <hr className='separator-xxs'/>
    <Summary title={t('Plazos de tramitación')} classNameTitle={'font-24'} classNameIcon='font-24'>
        <p dangerouslySetInnerHTML={{__html: t('Los plazos de tramitación y entrega de la documentación son de <strong>10 a 15 días laborables,</strong> a partir del momento de la recepción de documentación (en el caso de exención al pago de tasas) y del pago de las tasas.')}}/>
    </Summary>
    <hr className='separator-xxs'/>
    <Summary title={t('Tarifas de solicitud del título')} classNameTitle={'font-24'} classNameIcon='font-24'>
        <div>
            <div dangerouslySetInnerHTML={{__html: t('service-policy-tax')}}/>
        </div>
    </Summary>
    <hr className='separator-xxs'/>
    <Summary title={t('Documentación que recibirás')} classNameTitle={'font-24'} classNameIcon='font-24'>
        <div>
            <p dangerouslySetInnerHTML={{__html: t('Pasados los 10 a 15 días recibirás por correo certificado la documentación que acredita que has finalizado tu formación. Recibirás el resguardo de pago de tasas, que <strong>tiene la misma validez que el título oficial,</strong> así como el certificado de notas del ciclo formativo y el justificante de pago de la tasas. Es importante no entregar nunca el original, ya que es el equivalente al titulo hasta que este llegue en un <strong>plazo aproximado de 2 años.</strong>')}} />
            <p dangerouslySetInnerHTML={{__html: t('Podrás seguir el envío a través de un enlace que te proporcionará la empresa de correos.')}}/>
        </div>
    </Summary>
    <hr className='separator-xxs'/>
</Fragment>

export default ServicePolicy;
