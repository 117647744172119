import {E, F, R, CF, FR} from "../../../_common/layout";
import {t} from "../../../_i18n";
import {RadioCheckbox} from "../../../_common/forms/components/components";
import Modal from "../../../_common/components/Modal/index";
import React, {Component, h} from "preact";
import {Fragment} from "react";
import {connect} from 'react-redux';
import {resetJobFilter, setJobFilter} from '../../../_rx/jobs/actions';
import jobFilterPrepare from '../../functions/JobFilterPrepare';
import './modal_filter_styles.scss'


const initialState = {
    modality: {},
    academicPrograms: {},
    states: {},
    presenceType: {},
}

const modality = {
    'normal': t('Oferta de trabajo'),
    'fct': t('Formación en centro de trabajo (FCT)'),
    'dual': t('Dual')
}

const presenceType = {
    'on_site': t('Presencial'),
    'semi': t('Presencial, con posiblilidad de teletrabajar'),
    'remote': t('Teletrabajo'),
}

let ModalFilterView = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prevJobFilter: this.props.jobFilter
        }
    }

    componentDidUpdate(previousProps) {
        if (previousProps.showModal !== this.props.showModal) {
            this.setState({prevJobFilter: this.props.jobFilter})
        }
    }

    renderOffersNum = (offersNum) => {
        let text = '';
        if (offersNum === 1) {
            text = offersNum + " " + t("oferta")
        } else if (offersNum > 1 || offersNum === 0){
            text = offersNum + " " + t("ofertas")
        }
        return text;
    }

     updateNumberOffers({modality, states, academicPrograms, presenceType}){
        let objectToSend = jobFilterPrepare({
            actualFilter: {modality, states, academicPrograms, presenceType},
            jobFilter: this.props.jobFilter,
            statesApi: this.props.states,
            academicProgramsApi: this.props.academicPrograms
        });
         this.props.setNumOffers(objectToSend);
    }

    submitFilter = () => {
        let objectToSend = jobFilterPrepare({
            jobFilter: this.props.jobFilter,
            statesApi: this.props.states,
            academicProgramsApi: this.props.academicPrograms
        })
        this.props.setFilter(objectToSend);
    }

    resetJobFilter = () => {
        this.props.setJobFilter(this.state.prevJobFilter)
        this.updateNumberOffers({
            modality: this.state.prevJobFilter.modality,
            states: this.state.prevJobFilter.states,
            academicPrograms: this.state.prevJobFilter.academicPrograms,
            presenceType: this.state.prevJobFilter.presenceType
        });
        this.props.closeFilter();
    }

    removeFilter = () => {
        this.props.setJobFilter(initialState)
        this.updateNumberOffers(initialState);
    }

    render({id, academicPrograms, states, filter, closeModal, handleCancel, showModal, offersFilter, offersCount, setJobFilter, closeFilter}){
        const jobFilter = this.props.jobFilter
        return showModal && <Modal visible={showModal} closeModal={this.resetJobFilter} bodyClass='modal_filter-container'>
            <div className='modal_filter-header'>
                <div className='modal_filter-header__title font-18'>{t('Filtrar ofertas')}</div>
                <div className='modal_filter-header__cross font-18' onClick={this.resetJobFilter}><i className="fal fa-times "/></div>
            </div>
            <hr className="separator-xxs"/>
            <div className="modal_filter-body sm-p-x2-left sm-p-x2-right p-right p-left">
                        {offersFilter.show_modalities &&
                            <Fragment>
                                <FR>
                                    <E className="t-left sm-p-top">
                                        <h3>{t('Tipo de oferta')}</h3>
                                    </E>
                                </FR>

                                <FR className="sm-row-2-el row-1-el">
                                    {(offersFilter.modality_list).map(Key => (Key['label'] !== "dual") && <E className='t-left'>
                                            <RadioCheckbox name={modality[Key['label']]}
                                                           type="checkbox"
                                                           defaultValue={(jobFilter.modality[Key['label']]) ? jobFilter.modality[Key['label']] : false}
                                                           label={modality[Key['label']]}
                                                           onChange={(val) => {
                                                               const modality = {...jobFilter.modality, [Key['label']]: val}
                                                               setJobFilter({...jobFilter, modality})
                                                               this.updateNumberOffers({modality: modality, states: jobFilter.states, academicPrograms: jobFilter.academicPrograms, presenceType: jobFilter.presenceType});
                                                           }}/>
                                        </E>
                                    )}
                                </FR>
                            </Fragment>
                        }

                        {offersFilter.show_presences &&
                        <Fragment>
                            {offersFilter.show_modalities && <hr className="separator-xxs"/>}
                            <FR>
                                <E className="t-left sm-p-top">
                                    <h3>{t('Modalidad de trabajo')}</h3>
                                </E>
                            </FR>

                            <FR className="sm-row-3-el row-1-el">
                                {(offersFilter.presence_list).map((key) => {
                                        return <E className='t-left sm-no-margin-bottom'>
                                            <RadioCheckbox name={presenceType[key['label']]}
                                                           type="checkbox"
                                                           label={presenceType[key['label']]}
                                                           defaultValue={(jobFilter.presenceType[key['value']]) ? jobFilter.presenceType[key['value']] : false}
                                                           onChange={(val) => {
                                                               const presenceType = {...jobFilter.presenceType, [key['value']]: val}
                                                               setJobFilter({...jobFilter, presenceType})
                                                               this.updateNumberOffers({modality: jobFilter.modality, states: jobFilter.states, academicPrograms: jobFilter.academicPrograms, presenceType: presenceType});
                                                           }}/>
                                        </E>
                                    }
                                )}
                            </FR>
                        </Fragment>
                        }

                        {offersFilter.show_states &&
                            <Fragment>
                                {(offersFilter.show_modalities || offersFilter.show_presences) && <hr className="separator-xxs"/>}
                                <FR>
                                    <E className='t-left sm-p-top'>
                                        <h3>{t('Provincia')}</h3>
                                    </E>
                                </FR>

                                <FR className="sm-row-3-el row-1-el">
                                    {(offersFilter.state_list).map((key) => {
                                            const label = key['name'];
                                            return <E className='t-left sm-no-margin-bottom'>
                                                <RadioCheckbox name={label}
                                                               type="checkbox"
                                                               disabled={jobFilter.presenceType['2']}
                                                               className={jobFilter.presenceType['2'] && 'translucent no-background-cursor cursor-default'}
                                                               label={t(label)}
                                                               defaultValue={(jobFilter.states[label]) ? jobFilter.states[label] : false}
                                                               onChange={(val) => {
                                                                   const states = {...jobFilter.states, [label]: val}
                                                                   setJobFilter({...jobFilter, states})
                                                                   this.updateNumberOffers({modality: jobFilter.modality, states: states, academicPrograms: jobFilter.academicPrograms, presenceType: jobFilter.presenceType});
                                                               }}/>
                                            </E>
                                        }
                                    )}
                                </FR>
                            </Fragment>
                        }

                        {offersFilter.show_academic_program_scopes &&
                            <Fragment>
                                {(offersFilter.show_modalities || offersFilter.show_states || offersFilter.show_presences) && <hr className="separator-xxs"/>}
                                <FR>
                                    <E className='t-left sm-p-top'>
                                        <h3>{t('Titulación')}</h3>
                                    </E>
                                </FR>
                                <R>
                                    {(offersFilter.academic_program_scope_list).map((key) => {
                                            const label = key['name'];
                                            return <E className='t-left sm-no-margin-bottom'>
                                                <RadioCheckbox name={label}
                                                               type="checkbox"
                                                               label={t(label)}
                                                               defaultValue={(jobFilter.academicPrograms[label]) ? jobFilter.academicPrograms[label] : false}
                                                               onChange={(val) => {
                                                                   const academicPrograms = {...jobFilter.academicPrograms, [label]: val}
                                                                   setJobFilter({...jobFilter, academicPrograms})
                                                                   this.updateNumberOffers({modality: jobFilter.modality, states: jobFilter.states, academicPrograms: academicPrograms, presenceType: jobFilter.presenceType});
                                                               }}/>
                                            </E>
                                        }
                                    )}
                                </R>
                            </Fragment>
                        }
                    </div>
                        <hr className="separator-xxs"/>
                    <FR className="modal_filter-footer sm-row-2-el row-1-el flex-center-y">
                        <E className="t-center sm-no-t-center">
                            <button className="btn btn-primary" type='button' onClick={this.submitFilter}>
                                {offersCount && t('Mostrar ' + this.renderOffersNum(offersCount.count))}
                            </button>
                        </E>
                        <E className='t-center sm-t-right'>
                            <a className="cursor-pointer txt-color-primary font-14 t-underline" onClick={this.removeFilter}>{t('QUITAR FILTROS')}</a>
                        </E>
                    </FR>
            </Modal>

    }
}

ModalFilterView = connect(
  ({jobFilter})=>({jobFilter}),
  {setJobFilter, resetJobFilter})(ModalFilterView);

export default ModalFilterView;
