/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CandidateCandidate from '../model/CandidateCandidate';
import CandidateCompany from '../model/CandidateCompany';
import CandidateCountOfferPerState from '../model/CandidateCountOfferPerState';
import CandidateLetter from '../model/CandidateLetter';
import CandidateLink from '../model/CandidateLink';
import CandidateOffer from '../model/CandidateOffer';
import CandidateOfferFilters from '../model/CandidateOfferFilters';
import CandidateOfferItem from '../model/CandidateOfferItem';
import CandidateOfferItemPaginated from '../model/CandidateOfferItemPaginated';
import CandidatePublicOfferItem from '../model/CandidatePublicOfferItem';
import DefaultResponse from '../model/DefaultResponse';
import InlineObject1 from '../model/InlineObject1';
import LoginResponse from '../model/LoginResponse';
import OffersCount from '../model/OffersCount';

/**
* Candidate service.
* @module api/CandidateApi
* @version v1
*/
export default class CandidateApi {

    /**
    * Constructs a new CandidateApi. 
    * @alias module:api/CandidateApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * [P_A_24]
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DefaultResponse} and HTTP response
     */
    candidateAcceptPrivacyPolicyPutWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = DefaultResponse;
      return this.apiClient.callApi(
        '/candidate/accept_privacy_policy', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * [P_A_24]
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DefaultResponse}
     */
    candidateAcceptPrivacyPolicyPut() {
      return this.candidateAcceptPrivacyPolicyPutWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Vista del candidato des sus propios datos [P_A_22]
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CandidateCandidate} and HTTP response
     */
    candidateCandidateGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CandidateCandidate;
      return this.apiClient.callApi(
        '/candidate/candidate', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Vista del candidato des sus propios datos [P_A_22]
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CandidateCandidate}
     */
    candidateCandidateGet() {
      return this.candidateCandidateGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Info de una empresa [P_A_31]
     * @param {Number} id Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CandidateCompany} and HTTP response
     */
    candidateCompanyIdGetWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling candidateCompanyIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CandidateCompany;
      return this.apiClient.callApi(
        '/candidate/company/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Info de una empresa [P_A_31]
     * @param {Number} id Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CandidateCompany}
     */
    candidateCompanyIdGet(id) {
      return this.candidateCompanyIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Eliminación del fichero subido
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Boolean} and HTTP response
     */
    candidateDocumentDeleteWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/candidate/document', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Eliminación del fichero subido
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Boolean}
     */
    candidateDocumentDelete() {
      return this.candidateDocumentDeleteWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Download del fichero [P_A_22]. No se indica id porque es 1:1 con alumno
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
     */
    candidateDocumentGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/pdf'];
      let returnType = File;
      return this.apiClient.callApi(
        '/candidate/document', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Download del fichero [P_A_22]. No se indica id porque es 1:1 con alumno
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
     */
    candidateDocumentGet() {
      return this.candidateDocumentGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Upload del fichero [P_A_22]
     * @param {Object} opts Optional parameters
     * @param {File} opts.file 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Boolean} and HTTP response
     */
    candidateDocumentPostWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': opts['file']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/candidate/document', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Upload del fichero [P_A_22]
     * @param {Object} opts Optional parameters
     * @param {File} opts.file 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Boolean}
     */
    candidateDocumentPost(opts) {
      return this.candidateDocumentPostWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * [P_A_24]
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CandidateLetter>} and HTTP response
     */
    candidateLettersGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [CandidateLetter];
      return this.apiClient.callApi(
        '/candidate/letters', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * [P_A_24]
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CandidateLetter>}
     */
    candidateLettersGet() {
      return this.candidateLettersGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * [P_A_24]
     * @param {Number} id Letter Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DefaultResponse} and HTTP response
     */
    candidateLettersIdDeleteWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling candidateLettersIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = DefaultResponse;
      return this.apiClient.callApi(
        '/candidate/letters/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * [P_A_24]
     * @param {Number} id Letter Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DefaultResponse}
     */
    candidateLettersIdDelete(id) {
      return this.candidateLettersIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * [P_A_24]
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateLetter} opts.candidateLetter 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DefaultResponse} and HTTP response
     */
    candidateLettersPostWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['candidateLetter'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DefaultResponse;
      return this.apiClient.callApi(
        '/candidate/letters', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * [P_A_24]
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateLetter} opts.candidateLetter 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DefaultResponse}
     */
    candidateLettersPost(opts) {
      return this.candidateLettersPostWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * [P_A_24]
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateLetter} opts.candidateLetter 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DefaultResponse} and HTTP response
     */
    candidateLettersPutWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['candidateLetter'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DefaultResponse;
      return this.apiClient.callApi(
        '/candidate/letters', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * [P_A_24]
     * @param {Object} opts Optional parameters
     * @param {module:model/CandidateLetter} opts.candidateLetter 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DefaultResponse}
     */
    candidateLettersPut(opts) {
      return this.candidateLettersPutWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * No hay GET, vienen en el candidate. No hay PUT, hacemos siempre post machacando todos los elementos. [P_A_23]
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CandidateLink>} opts.candidateLink 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DefaultResponse} and HTTP response
     */
    candidateLinksPostWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['candidateLink'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DefaultResponse;
      return this.apiClient.callApi(
        '/candidate/links', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * No hay GET, vienen en el candidate. No hay PUT, hacemos siempre post machacando todos los elementos. [P_A_23]
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CandidateLink>} opts.candidateLink 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DefaultResponse}
     */
    candidateLinksPost(opts) {
      return this.candidateLinksPostWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.applied Para ver solo tus candidaturas [P_A_11]
     * @param {Number} opts.company Para ver solo las ofertas de una empresa. [P_A_31]
     * @param {String} opts.modalities tipo
     * @param {String} opts.presences tipo de presencia
     * @param {String} opts.states 
     * @param {String} opts.academicPrograms 
     * @param {String} opts.status Lista númerica de estados separados por comas
     * @param {Number} opts.page 
     * @param {Number} opts.limit 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OffersCount} and HTTP response
     */
    candidateOffersCountGetWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'applied': opts['applied'],
        'company': opts['company'],
        'modalities': opts['modalities'],
        'presences': opts['presences'],
        'states': opts['states'],
        'academic_programs': opts['academicPrograms'],
        'status': opts['status'],
        'page': opts['page'],
        'limit': opts['limit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = OffersCount;
      return this.apiClient.callApi(
        '/candidate/offers_count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.applied Para ver solo tus candidaturas [P_A_11]
     * @param {Number} opts.company Para ver solo las ofertas de una empresa. [P_A_31]
     * @param {String} opts.modalities tipo
     * @param {String} opts.presences tipo de presencia
     * @param {String} opts.states 
     * @param {String} opts.academicPrograms 
     * @param {String} opts.status Lista númerica de estados separados por comas
     * @param {Number} opts.page 
     * @param {Number} opts.limit 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OffersCount}
     */
    candidateOffersCountGet(opts) {
      return this.candidateOffersCountGetWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.applied Para ver solo tus candidaturas [P_A_11]
     * @param {Number} opts.company Para ver solo las ofertas de una empresa. [P_A_31]
     * @param {String} opts.modalities tipo
     * @param {String} opts.presences tipo de presencia
     * @param {String} opts.states 
     * @param {String} opts.academicPrograms 
     * @param {String} opts.status Lista númerica de estados separados por comas
     * @param {Number} opts.page 
     * @param {Number} opts.limit 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CandidateOfferFilters} and HTTP response
     */
    candidateOffersFiltersGetWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'applied': opts['applied'],
        'company': opts['company'],
        'modalities': opts['modalities'],
        'presences': opts['presences'],
        'states': opts['states'],
        'academic_programs': opts['academicPrograms'],
        'status': opts['status'],
        'page': opts['page'],
        'limit': opts['limit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CandidateOfferFilters;
      return this.apiClient.callApi(
        '/candidate/offers_filters', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.applied Para ver solo tus candidaturas [P_A_11]
     * @param {Number} opts.company Para ver solo las ofertas de una empresa. [P_A_31]
     * @param {String} opts.modalities tipo
     * @param {String} opts.presences tipo de presencia
     * @param {String} opts.states 
     * @param {String} opts.academicPrograms 
     * @param {String} opts.status Lista númerica de estados separados por comas
     * @param {Number} opts.page 
     * @param {Number} opts.limit 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CandidateOfferFilters}
     */
    candidateOffersFiltersGet(opts) {
      return this.candidateOffersFiltersGetWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.applied Para ver solo tus candidaturas [P_A_11]
     * @param {Number} opts.company Para ver solo las ofertas de una empresa. [P_A_31]
     * @param {String} opts.modalities tipo
     * @param {String} opts.states 
     * @param {String} opts.academicPrograms 
     * @param {String} opts.status Lista númerica de estados separados por comas
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CandidateOfferItem>} and HTTP response
     */
    candidateOffersGetWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'applied': opts['applied'],
        'company': opts['company'],
        'modalities': opts['modalities'],
        'states': opts['states'],
        'academic_programs': opts['academicPrograms'],
        'status': opts['status']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [CandidateOfferItem];
      return this.apiClient.callApi(
        '/candidate/offers', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.applied Para ver solo tus candidaturas [P_A_11]
     * @param {Number} opts.company Para ver solo las ofertas de una empresa. [P_A_31]
     * @param {String} opts.modalities tipo
     * @param {String} opts.states 
     * @param {String} opts.academicPrograms 
     * @param {String} opts.status Lista númerica de estados separados por comas
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CandidateOfferItem>}
     */
    candidateOffersGet(opts) {
      return this.candidateOffersGetWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Acto del alumno de inscribirse en una oferta [P_A_05]
     * @param {Number} id Id
     * @param {Object} opts Optional parameters
     * @param {module:model/InlineObject1} opts.inlineObject1 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CandidateOffer} and HTTP response
     */
    candidateOffersIdApplyPostWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['inlineObject1'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling candidateOffersIdApplyPost");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CandidateOffer;
      return this.apiClient.callApi(
        '/candidate/offers/{id}/apply', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Acto del alumno de inscribirse en una oferta [P_A_05]
     * @param {Number} id Id
     * @param {Object} opts Optional parameters
     * @param {module:model/InlineObject1} opts.inlineObject1 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CandidateOffer}
     */
    candidateOffersIdApplyPost(id, opts) {
      return this.candidateOffersIdApplyPostWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * [P_A_03] [P_A_12] [P_A_05]
     * @param {Number} id Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CandidateOffer} and HTTP response
     */
    candidateOffersIdGetWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling candidateOffersIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CandidateOffer;
      return this.apiClient.callApi(
        '/candidate/offers/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * [P_A_03] [P_A_12] [P_A_05]
     * @param {Number} id Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CandidateOffer}
     */
    candidateOffersIdGet(id) {
      return this.candidateOffersIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.applied Para ver solo tus candidaturas [P_A_11]
     * @param {Number} opts.company Para ver solo las ofertas de una empresa. [P_A_31]
     * @param {String} opts.modalities tipo
     * @param {String} opts.presences tipo de presencia
     * @param {String} opts.states 
     * @param {String} opts.academicPrograms 
     * @param {String} opts.status Lista númerica de estados separados por comas
     * @param {Number} opts.page 
     * @param {Number} opts.limit 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CandidateOfferItemPaginated} and HTTP response
     */
    candidateOffersPagedGetWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'applied': opts['applied'],
        'company': opts['company'],
        'modalities': opts['modalities'],
        'presences': opts['presences'],
        'states': opts['states'],
        'academic_programs': opts['academicPrograms'],
        'status': opts['status'],
        'page': opts['page'],
        'limit': opts['limit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CandidateOfferItemPaginated;
      return this.apiClient.callApi(
        '/candidate/offers_paged', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.applied Para ver solo tus candidaturas [P_A_11]
     * @param {Number} opts.company Para ver solo las ofertas de una empresa. [P_A_31]
     * @param {String} opts.modalities tipo
     * @param {String} opts.presences tipo de presencia
     * @param {String} opts.states 
     * @param {String} opts.academicPrograms 
     * @param {String} opts.status Lista númerica de estados separados por comas
     * @param {Number} opts.page 
     * @param {Number} opts.limit 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CandidateOfferItemPaginated}
     */
    candidateOffersPagedGet(opts) {
      return this.candidateOffersPagedGetWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CandidateCountOfferPerState>} and HTTP response
     */
    candidateOffersPerStateGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [CandidateCountOfferPerState];
      return this.apiClient.callApi(
        '/candidate/offers_per_state', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CandidateCountOfferPerState>}
     */
    candidateOffersPerStateGet() {
      return this.candidateOffersPerStateGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.company Para ver solo las ofertas de una empresa. [P_A_31]
     * @param {String} opts.modalities tipo
     * @param {String} opts.states 
     * @param {String} opts.academicPrograms 
     * @param {String} opts.status Lista númerica de estados separados por comas
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CandidatePublicOfferItem>} and HTTP response
     */
    candidatePublicOffersGetWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'company': opts['company'],
        'modalities': opts['modalities'],
        'states': opts['states'],
        'academic_programs': opts['academicPrograms'],
        'status': opts['status']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [CandidatePublicOfferItem];
      return this.apiClient.callApi(
        '/candidate/public_offers', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Object} opts Optional parameters
     * @param {Number} opts.company Para ver solo las ofertas de una empresa. [P_A_31]
     * @param {String} opts.modalities tipo
     * @param {String} opts.states 
     * @param {String} opts.academicPrograms 
     * @param {String} opts.status Lista númerica de estados separados por comas
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CandidatePublicOfferItem>}
     */
    candidatePublicOffersGet(opts) {
      return this.candidatePublicOffersGetWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * comprueba login de CAS
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LoginResponse} and HTTP response
     */
    casLoginGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = LoginResponse;
      return this.apiClient.callApi(
        '/cas/login', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * comprueba login de CAS
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LoginResponse}
     */
    casLoginGet() {
      return this.casLoginGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
