import { h } from "preact";
import { t } from '../../../_i18n'



export default function EndFCT () {
  return (
    <div>
      <p className='font-bold'>{t('El cierre de tus prácticas se ha realizado correctamente.')}</p>
      <p>{t('Podrás ver la nota en tu expediente académico.')}</p>
      <p>{t('Si con las prácticas finalizas el ciclo formativo, tras el cierre de las actas, recibirás un correo electrónico desde Secretaría Académica de ILERNA con la información necesaria para solicitar el título oficial.')}</p>
    </div>
  )
}
