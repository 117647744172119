import {Component, Fragment, h} from "preact";

import {AppPage} from "../_common/components/app-page";

import {connect} from "react-redux";

import {fetchDireccionEnvio} from "../_rx/direccion-envio/actions";

import {t} from '../_i18n';

import './index.scss';
import {Address} from "./pages/address";
import {Payment} from "./pages/payment";
import {PaymentSuccess} from "./pages/payment-success";
import {DocuSent} from "./pages/docu-sent";
import {ChooseDeliver} from "./pages/choose-deliver";
import {PendingPickup} from "./pages/pending-pickup";
import { useHistory } from 'react-router'


let AcademicProgramCard = class extends Component {

    state = {address_reviewed: false};

    async componentDidMount() {
        await this.props.fetchDireccionEnvio();
    }

    addressOk(){
        this.setState({address_reviewed: true});
    }

    render({direccion_envio, status}) {

        // return <p>status: {JSON.stringify(status.blocks['5'])}</p>;

        if(!status.blocks['5']) return null;

        // '0' => 'unavailable',       // inicial - no hay titulo // Acción avance: terminas ciclo
        // '1' => 'payment_pending',   // titulo completado, disponible para pagar // Acción avance: pagar
        // '2' => 'payed',             // titulo pagado // Acción avance: Establecen fecha tramitado
        // '3' => 'provisional',       // validado por secretaria (disponible provisional) // Acción: Establecen recepción titulo pared
        // '4' => 'official',          // disponible titulo oficial // Acción: usuario establece lugar de entrega
        // '5' => 'delivery_pending',  // el titulo "viaja" // Acción avance: Establecen fecha entrega titulo pared
        // '6' => 'delivered'          // entregado

        switch (status.blocks['5'].status.id) {
            case 1: // pendiente pago
                return !this.state.address_reviewed ?
                    <Address direccion_envio={direccion_envio} address_ok={()=>this.addressOk()} /> :
                    <Payment />;
            case 2: // pago realizado
                return <PaymentSuccess />;
            case 3: // docu provisional enviada
                return <DocuSent />;
            case 4: // indicar opcion entrega
                return <ChooseDeliver />;
            case 5: // pendiente recogida
                return <PendingPickup />;
        }

    }
};

AcademicProgramCard = connect(
    ({direccion_envio, status})=>({direccion_envio, status}),
    { fetchDireccionEnvio })(AcademicProgramCard);

const ViewPage = () => {
  const history = useHistory()
   return <AppPage
        name="academic-program-card"
        title={t('Expedición del título')}
        back={history.goBack}
        className="max-width">
        <AcademicProgramCard />
    </AppPage>
}

export default ViewPage;
