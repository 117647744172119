/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AdminCompany from '../model/AdminCompany';
import InlineResponse200 from '../model/InlineResponse200';
import Status from '../model/Status';

/**
* Admin service.
* @module api/AdminApi
* @version v1
*/
export default class AdminApi {

    /**
    * Constructs a new AdminApi. 
    * @alias module:api/AdminApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Se llama desde el core, no hay pantalla todavia
     * @param {Object} opts Optional parameters
     * @param {String} opts.search name, phone, nif, text and location filtering
     * @param {Number} opts.status status 0 disabled / 1 enabled
     * @param {Number} opts.completed 1 yes / 0 no
     * @param {Number} opts.withOffers 1 yes / 0 no
     * @param {Number} opts.withCandidates 1 yes / 0 no
     * @param {Number} opts.page page number (default 1)
     * @param {Number} opts.limit companies per page (default 20)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse200} and HTTP response
     */
    adminCompaniesGetWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'search': opts['search'],
        'status': opts['status'],
        'completed': opts['completed'],
        'with_offers': opts['withOffers'],
        'with_candidates': opts['withCandidates'],
        'page': opts['page'],
        'limit': opts['limit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse200;
      return this.apiClient.callApi(
        '/admin/companies', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Se llama desde el core, no hay pantalla todavia
     * @param {Object} opts Optional parameters
     * @param {String} opts.search name, phone, nif, text and location filtering
     * @param {Number} opts.status status 0 disabled / 1 enabled
     * @param {Number} opts.completed 1 yes / 0 no
     * @param {Number} opts.withOffers 1 yes / 0 no
     * @param {Number} opts.withCandidates 1 yes / 0 no
     * @param {Number} opts.page page number (default 1)
     * @param {Number} opts.limit companies per page (default 20)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse200}
     */
    adminCompaniesGet(opts) {
      return this.adminCompaniesGetWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Se llama desde el core, no hay pantalla todavia
     * @param {Number} id Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/AdminCompany>} and HTTP response
     */
    adminCompaniesIdGetWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling adminCompaniesIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [AdminCompany];
      return this.apiClient.callApi(
        '/admin/companies/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Se llama desde el core, no hay pantalla todavia
     * @param {Number} id Id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/AdminCompany>}
     */
    adminCompaniesIdGet(id) {
      return this.adminCompaniesIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Para cambiar desde core el status de aprovado de la empresa
     * @param {Number} id Id
     * @param {Object} opts Optional parameters
     * @param {module:model/Status} opts.status 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Boolean} and HTTP response
     */
    adminCompaniesIdPutWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['status'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling adminCompaniesIdPut");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/admin/companies/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Para cambiar desde core el status de aprovado de la empresa
     * @param {Number} id Id
     * @param {Object} opts Optional parameters
     * @param {module:model/Status} opts.status 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Boolean}
     */
    adminCompaniesIdPut(id, opts) {
      return this.adminCompaniesIdPutWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
