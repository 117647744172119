/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CandidateAcademicProgramCompletion from './CandidateAcademicProgramCompletion';
import CandidateDocument from './CandidateDocument';
import CandidateLink from './CandidateLink';
import CompanyOfferItem from './CompanyOfferItem';
import Event from './Event';

/**
 * The CompanyApplication model module.
 * @module model/CompanyApplication
 * @version v1
 */
class CompanyApplication {
    /**
     * Constructs a new <code>CompanyApplication</code>.
     * Detalle de candidatura [P_E_07]
     * @alias module:model/CompanyApplication
     */
    constructor() { 
        
        CompanyApplication.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CompanyApplication</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CompanyApplication} obj Optional instance to populate.
     * @return {module:model/CompanyApplication} The populated <code>CompanyApplication</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CompanyApplication();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('events')) {
                obj['events'] = ApiClient.convertToType(data['events'], [Event]);
            }
            if (data.hasOwnProperty('offer')) {
                obj['offer'] = CompanyOfferItem.constructFromObject(data['offer']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('age')) {
                obj['age'] = ApiClient.convertToType(data['age'], 'Number');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('full_address')) {
                obj['full_address'] = ApiClient.convertToType(data['full_address'], 'String');
            }
            if (data.hasOwnProperty('document')) {
                obj['document'] = CandidateDocument.constructFromObject(data['document']);
            }
            if (data.hasOwnProperty('links')) {
                obj['links'] = ApiClient.convertToType(data['links'], [CandidateLink]);
            }
            if (data.hasOwnProperty('killer_grade')) {
                obj['killer_grade'] = ApiClient.convertToType(data['killer_grade'], 'Number');
            }
            if (data.hasOwnProperty('has_killer_grade')) {
                obj['has_killer_grade'] = ApiClient.convertToType(data['has_killer_grade'], 'Boolean');
            }
            if (data.hasOwnProperty('grade_percent')) {
                obj['grade_percent'] = ApiClient.convertToType(data['grade_percent'], 'Number');
            }
            if (data.hasOwnProperty('academic_programs')) {
                obj['academic_programs'] = ApiClient.convertToType(data['academic_programs'], [CandidateAcademicProgramCompletion]);
            }
            if (data.hasOwnProperty('letter')) {
                obj['letter'] = ApiClient.convertToType(data['letter'], 'String');
            }
            if (data.hasOwnProperty('questions')) {
                obj['questions'] = ApiClient.convertToType(data['questions'], [Object]);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
CompanyApplication.prototype['id'] = undefined;

/**
 * applied, accepted, selected, discarded
 * @member {String} status
 */
CompanyApplication.prototype['status'] = undefined;

/**
 * @member {Array.<module:model/Event>} events
 */
CompanyApplication.prototype['events'] = undefined;

/**
 * @member {module:model/CompanyOfferItem} offer
 */
CompanyApplication.prototype['offer'] = undefined;

/**
 * Nombre del candidato
 * @member {String} name
 */
CompanyApplication.prototype['name'] = undefined;

/**
 * Edad del candidato
 * @member {Number} age
 */
CompanyApplication.prototype['age'] = undefined;

/**
 * Telefono del candidato
 * @member {String} phone
 */
CompanyApplication.prototype['phone'] = undefined;

/**
 * Email del candidato
 * @member {String} email
 */
CompanyApplication.prototype['email'] = undefined;

/**
 * Dirección del candidato - Traida del sis
 * @member {String} full_address
 */
CompanyApplication.prototype['full_address'] = undefined;

/**
 * @member {module:model/CandidateDocument} document
 */
CompanyApplication.prototype['document'] = undefined;

/**
 * @member {Array.<module:model/CandidateLink>} links
 */
CompanyApplication.prototype['links'] = undefined;

/**
 * Nota de las respuestas, -1 killer
 * @member {Number} killer_grade
 */
CompanyApplication.prototype['killer_grade'] = undefined;

/**
 * Indica si las preguntas tienen nota
 * @member {Boolean} has_killer_grade
 */
CompanyApplication.prototype['has_killer_grade'] = undefined;

/**
 * Suma de nota de las respuestas. Porcentaje sobre la suma maxima, -1 killer
 * @member {Number} grade_percent
 */
CompanyApplication.prototype['grade_percent'] = undefined;

/**
 * @member {Array.<module:model/CandidateAcademicProgramCompletion>} academic_programs
 */
CompanyApplication.prototype['academic_programs'] = undefined;

/**
 * Copia del texto de la letter
 * @member {String} letter
 */
CompanyApplication.prototype['letter'] = undefined;

/**
 * @member {Array.<Object>} questions
 */
CompanyApplication.prototype['questions'] = undefined;






export default CompanyApplication;

