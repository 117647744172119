import { h } from "preact";
import './sign-address-styles.scss'
import { t } from '../../../_i18n'

export default function SignAddress ({ className }) {
  return (
    <div className={`fct-sign-address ${className || ''}`}>
      <div className='fct-sign-address__title'>ILERNA ONLINE S.L.</div>
      <div>{t('A/A: Departamento de Prácticas')}</div>
      <div>{t('Turó de Gardeny, Complex Magical Media, Edificio 5')}</div>
      <div>{t('25003 Lleida')}</div>
    </div>
  )
}
