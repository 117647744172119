

export const setPath = function(obj:any, path:string[], value:any){
    let o = obj;
    let i = 0;
    while(true){
        const k = path[i++]; // siguiente elemento del path
        if(i < path.length) {
            // no es final del path
            // si k es un numero es que el objeto es un array
            // si no existe el elemento lo creamos (array u objeto segun el nombre es string o indice)
            if(!o[k]) o[k] = typeof k === 'number' ? [] : {};
            o = o[k]; // entramos en el elemento
        }
        else {
            // es final del path
            o[k] = value; // asignamos valor final y salimos
            break;
        }
    }
    return obj; // retornamos objeto inicial
};

export function formatCurrency(num:any, digits=2){

    return Number(parseFloat(num)).toFixed(digits)+'€';
}

export function formatDDMMYYYY(date:any){
    const d = new Date(date);
    return `${d.getDate().toString().padLeft()}/${(d.getMonth()+1).toString().padLeft()}/${d.getFullYear()}`;
}

export function formatHHMM(date:any) {
    const d = new Date(date);
    return `${d.getHours().toString().padLeft()}:${d.getMinutes().toString().padLeft()}`;
}

export function formatFilesize(lengthBytes:number) {
    return (Math.round(lengthBytes * 100 / 1024) / 100).toString() + ' KB'
}

export function isImageFile(file: File){
    return file.name.match(/\.(jpe?g|png)$/)
}

export function isValidDni(dni: string){
    dni = dni.toUpperCase();
    let num: any, letter, lastLetter;
    const expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    if(expresion_regular_dni.test(dni)) {
        num = dni.substr(0,dni.length-1);
        // @ts-ignore
        num = num.replace('X', 0);
        // @ts-ignore
        num = num.replace('Y', 1);
        // @ts-ignore
        num = num.replace('Z', 2);
        letter = dni.substr(dni.length-1, 1);
        num = num % 23;
        lastLetter = 'TRWAGMYFPDXBNJZSQVHLCKET';
        lastLetter = lastLetter.substring(num, num+1);
        return (lastLetter === letter);
    }else{
        return false
    }
}

export async function resizeImage(image: File, max: number) {

    try {
        const bmp = await createImageBitmap(image);

        let w, h;

        // si la imagen es mas pequena no se toca
        if (Math.max(bmp.width, bmp.height) < max) return image;

        if (bmp.width > bmp.height) {
            w = max;
            h = (bmp.height / bmp.width) * max;
        } else {
            w = (bmp.height / bmp.width) * max;
            h = max;
        }

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if(!ctx) return image;

        canvas.width = w;
        canvas.height = h;

        ctx.drawImage(bmp, 0, 0, w, h);
        const blob = await new Promise<Blob | null>(r => canvas.toBlob((b) => r(b), "image/jpeg", 100));

        canvas.remove();

        if(!blob) return image;
        return new File([blob], image.name, {type:"image/jpeg"});
    }
    catch(err){
        // si hay cualquier fallo se reporta el error y se devuelve la imagen original
        console.error(err);
        return image;
    }
}

export function addDays(date:Date, days:number){
    date = new Date(date);
    date.setDate(date.getDate()+days);
    return date;
}

export function clone(obj:any):any {
    if (!obj || obj instanceof Date || typeof obj !== 'object') return obj; // valor (string, number, boolean, null ...)
    if (Array.isArray(obj)) return obj.map(clone); // clon de cada elemento del array

    const o:any = {};
    for (const k in obj) o[k] = clone(obj[k]);
    return o;
}

export function createReducer<T>(initialState:T, dicActions:{[key:string]:(state:T,payload:any)=>T}){
    return (state = initialState, action:{type:string,payload:any}) =>{
        const f = dicActions[action.type]; // busca en el diccionario de reducers como si fuera un switch case
        if(!f) return state; // no hay reducer para este action, devuelvo el state intacto

        state = clone(state); // hay reducer, clono el estado, es obligatorio
        const r = f(state, action.payload);
        return r === undefined ? state : r;
    };
}

export function rndStr() {
    return 's' + parseInt((Math.random()*Number.MAX_SAFE_INTEGER).toString()).toString(36);
}

export function strHash(str:string){
    return Math.abs(str.split('').reduce((val, curr)=>((val*32)-val+curr.charCodeAt(0))%Number.MAX_SAFE_INTEGER,0)).toString(36);
}

export function toDic(arr:{id:string,name:string}[],
                      keyFn = (i:{id:string,name:string})=>i.id,
                      valueFn = (i:{id:string,name:string})=>i.name){
    const dic: {[key:string]:any} = {};
    arr.forEach(i => dic[keyFn(i)] = valueFn(i));
    // console.log(dic)
    return dic;
}

export function toKeyName(arr:{id:string,name:string}[],
                          keyFn = (i:{id:string,name:string})=>i.id,
                          nameFn = (i:{id:string,name:string})=>i.name){
    return arr.map(i => ({...i, key:keyFn(i), name:nameFn(i)}));
}

export interface Defer<T>{
    promise: Promise<T>;
    resolve: (val:T)=>void;
    reject: (err:Error)=>void;
}

export function createDefer<T>(){
    let resolve: any;
    let reject: any;
    const promise = new Promise<T>((res, rej)=>{
        resolve = res;
        reject = rej;
    });
    return {promise, resolve, reject} as Defer<T>;
}

export function lazySingleton<T>(factory:()=>T):()=>T{
    let instance:T|null = null;
    return ()=> instance || (instance = factory());
}

/// Singleton que solo carga cuando se necesita.
// Ademas controla con un defer la concurrencia si se solicita la carga varias veces mientras se esta cargando
export function asyncLazySingleton<T>(factory:()=>Promise<T>):()=>Promise<T>{
    let instance:T;
    let defer:Defer<T>;
    return async ()=>{
        if(instance) return instance;
        if(!defer){
            defer = createDefer();
            factory().then(defer.resolve).catch(defer.reject);
        }
        instance = await defer.promise;
        return instance;
    };
}

export function setClass(element:HTMLElement, cName:string, set:boolean){
    set ?
        element.classList.add(cName):
        element.classList.remove(cName)
}


export async function getHttp(url:string, options?:any, noraise?:boolean){
    const response = await fetch(url, options || {credentials: 'include'});
    if (!noraise && response.status >= 400) throw new Error(await response.text());
    const text = await response.text();
    return text ? JSON.parse(text) : text;
}

/**
 * Esta función es un hack para descargar archivos de los EndPoints, dado que el código generado por Swagger no funciona correctamente
 * Ejemplos: Ver llamadas al método
 * @param url
 * @param filename
 */
export async function downloadFile(url:string, filename:string){
    const blob = await (await fetch(url, { "credentials": 'include'})).blob();
    await generateDownload(blob, filename);
}

export async function generateDownload(file:any, name:string){
    const element = document.createElement('a');
    element.href = await readFileToURL(file);
    element.download = name || file.name;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

export function readFileToURL(file:File): Promise<string> {
    return new Promise(resolve => {
        const fr = new FileReader();
        fr.onload = () => resolve(fr.result as string);
        fr.readAsDataURL(file);
    });
}


export function getUrlParams(url:string) {
    const params :any = {};
    const parser = document.createElement('a');
    parser.href = url;
    const query = parser.search.substring(1);
    query.split('&')
        .filter(s => !!s)
        .map(s => s.split('='))
        .forEach(p => params[p[0]] = decodeURIComponent(p[1]));
    return params;
}

export function parseJwt (token:string) {
    return JSON.parse(decodeURIComponent(atob(token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')).split('').map(c=>'%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join('')));
}

declare global { interface String { padLeft(size?: number, c?: string): string; }}

String.prototype.padLeft = function(size=2, c = '0') {
    let s = String(this);
    while(s.length<size) s=c+s;
    return s;
}

export function replaceHash(anchor:string) {
    history.pushState(null, '', location.href);
    anchor = anchor ? ('#' + anchor.replace('#','')) : '';
    location.replace(location.pathname + anchor);
}

export function flat(arr:any[], dest:any[]=[]){
    arr && arr.forEach(i=> i && i.forEach((i2:any)=>dest.push(i2))); // Array.isArray(i) ? flat(i, dest) :
    return dest;
}

export function range(start=0, end=1){
    const list = [];
    for(let i = start; i <= end; i++) list.push(i);
    return list;
}

const isDragAndDropFriendly = function() {
    let div = document.createElement('div');
    return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
}();

export function handleDragAndDrop() {
    if (document.querySelector('.add-image') != null) {
        if (isDragAndDropFriendly) {
            const e = document.querySelector('form');
            e && e.classList.add('drag-and-drop');
        }
    }
}

export function closeMenu(){
    const MenuBlock = document.querySelector('.menu-handler');
    if (!MenuBlock) return;
    for(const MenuLink of Array.from(MenuBlock.children)) {
        MenuLink.addEventListener('click', function(e:Event) {
            let check = (e.target as any).parentNode.parentNode.querySelector('input');
            check && (check.checked = false);
            check = document.getElementById('left-menu-dropdown-check');
            if (check && check.checked) {
                check.checked = false;
            }
        });
    }
}

export function isMobileAgent(){
    return !!navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|WebView/i);
}


export function treatCountriesList(lista:any[], id:string)  {
    if (!id) return lista;  // <- TODO: Al basar el parámetro id en una lista rígida de constantes, hay casos en los que no está pasando bien el id (implikas y otras tiendas varias)
    return [lista.find(c=>c.key==id), ...lista.filter(c=>c.key!=id)];
}

export const wait = (t:number)=>new Promise(r=>setTimeout(r,t));

export const stripTags = (html:string)=> html && html.replace(/<[^>]*>/g,"");
