import { Fragment, h } from "preact";
import AlertExemption100 from '../../components/Alerts/AlertExemption100'
import { useSelector } from 'react-redux'
import { t } from '../../../_i18n'

const STATE_1 = '5.1'
const STATE_2 = '5.2'
const STATE_3 = '5.3'

export default function Exemption100 () {
  const { screen } = useSelector(state => state.fct.screenState)

  return (
    <div>
      {screen === STATE_1 && <AlertExemption100 />}
      {screen === STATE_2 && (
        <Fragment>
          <p>{t('')}{t('Tras revisar tu documentación, eres susceptible a la exención del 100% de las prácticas en empresa. A lo largo del semestre se actualizará tu expediente académico con la exención y podrás verlo a través del expediente académico')}</p>
        </Fragment>
      )}
      {screen === STATE_3 && (
          <Fragment>
            <p>Tras revisar tu documentación, eres susceptible a la exención del 100% de las prácticas en empresa. A lo largo del semestre se actualizará tu expediente académico con la exención y podrás verlo a través del expediente académico</p>
            <b>En breve se actualizará tu expediente académico con la exención</b>
            <p>Si con la exención total de las prácticas finalizas tu ciclo formativo, recibirás un email (una vez se hayan cerrado actas) de la Secretaría Académica de ILERNA Online con los pasos a seguir para solicitar tu título oficial</p>
          </Fragment>
      )}
    </div>
  )
}
