import React, { Fragment, h } from "preact";
import {connect} from "react-redux";
import {AppPage} from "../_common/components/app-page";
import PendingInviteComponent from './pages/PendingInviteComponent'
import {fetchAddressInviteFriend, fetchInviteFriendStatus} from "../_rx/inviteFriend/actions";
import {useEffect} from "preact/hooks";
import SentInviteComponent from "./pages/SentInviteComponent";
import {t} from "../_i18n";
import {CF} from "../_common/layout";
import GiftPendingComponent from "./pages/GiftPendingComponent";
import DoneComponent from "./pages/DoneComponent";
import {useHistory} from "react-router-dom";

const ComponentToRender = {
    'Pending': <PendingInviteComponent />,
    'Sent': <SentInviteComponent />,
    'GiftPending': <GiftPendingComponent />,
    'Done': <DoneComponent />
}

function InviteFriend ({inviteFriendStatus, fetchInviteFriendStatus, fetchAddressInviteFriend}) {
    const history = useHistory();
    const showBackButton = history?.action !== 'POP'

    useEffect(() => {
        fetchAddressInviteFriend()
        fetchInviteFriendStatus()
    }, [])

    return (
        <CF className="max-width">
            {showBackButton && <div className="back breadcrumb-item cursor-pointer d-none sm-d-block" onClick={history.goBack}>
                ← {t('Volver')}
            </div>}
            <h1 className='hero'>{t('Invitar a un@ amigo@')}</h1>
            {inviteFriendStatus && ComponentToRender[inviteFriendStatus?.status]}
        </CF>
    )
}


InviteFriend = connect(
    ({inviteFriendStatus})=>({inviteFriendStatus: inviteFriendStatus.status}),
    {fetchInviteFriendStatus, fetchAddressInviteFriend})(InviteFriend);


export default ({...rest}) => (
    <AppPage
        className="max-width">
        <InviteFriend {...rest}/>
    </AppPage>
)
