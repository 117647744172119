/**
 * LTI Enroll Exam
 * LTI Enroll Exam for Students
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';
import FormativeActionsPerPeriod from '../model/FormativeActionsPerPeriod';

/**
* Events service.
* @module api/EventsApi
* @version 2.0.0
*/
export default class EventsApi {

    /**
    * Constructs a new EventsApi. 
    * @alias module:api/EventsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get Student Fromative actions enrolled list
     * @param {String} studentId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/FormativeActionsPerPeriod} and HTTP response
     */
    enrollExamControllerGetFormativeActionsWithHttpInfo(studentId) {
      let postBody = null;
      // verify the required parameter 'studentId' is set
      if (studentId === undefined || studentId === null) {
        throw new Error("Missing the required parameter 'studentId' when calling enrollExamControllerGetFormativeActions");
      }

      let pathParams = {
        'student_id': studentId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = FormativeActionsPerPeriod;
      return this.apiClient.callApi(
        '/lti/enrollExam/formative_actions/{student_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get Student Fromative actions enrolled list
     * @param {String} studentId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/FormativeActionsPerPeriod}
     */
    enrollExamControllerGetFormativeActions(studentId) {
      return this.enrollExamControllerGetFormativeActionsWithHttpInfo(studentId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get Next Events enroll
     * @param {String} studentId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    enrollExamControllerGetNextEventsWithHttpInfo(studentId) {
      let postBody = null;
      // verify the required parameter 'studentId' is set
      if (studentId === undefined || studentId === null) {
        throw new Error("Missing the required parameter 'studentId' when calling enrollExamControllerGetNextEvents");
      }

      let pathParams = {
        'student_id': studentId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/lti/enrollExam/next_events/{student_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get Next Events enroll
     * @param {String} studentId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    enrollExamControllerGetNextEvents(studentId) {
      return this.enrollExamControllerGetNextEventsWithHttpInfo(studentId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get Past Events enrolled
     * @param {String} studentId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    enrollExamControllerGetPastEventsWithHttpInfo(studentId) {
      let postBody = null;
      // verify the required parameter 'studentId' is set
      if (studentId === undefined || studentId === null) {
        throw new Error("Missing the required parameter 'studentId' when calling enrollExamControllerGetPastEvents");
      }

      let pathParams = {
        'student_id': studentId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/lti/enrollExam/past_events/{student_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get Past Events enrolled
     * @param {String} studentId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    enrollExamControllerGetPastEvents(studentId) {
      return this.enrollExamControllerGetPastEventsWithHttpInfo(studentId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
