import { validationDocumentFCT, validationDocumentFCTOptional } from '../../components/UploadDocument/validationDocumentFCT';
import { FormField, FormFieldRadioCheckboxList, FormFieldSelect } from '../../../_common/forms/components/components';
import ButtonSpinner from '../../../_common/components/buttons/buttonSpinner/buttonSpinner';
import { isRequiredWithZero } from '../../../_common/forms/validators/isRequired';
import UploadDocument from '../../components/UploadDocument/UploadDocument';
import IBANField from '../../components/IBAN/IBANField';
import { ButtonGhost } from '../../../_common/components/buttons/buttons';
import InputSchedule from '../../components/InputSchedule/InputSchedule';
import AlertBox from '../../../_common/components/AlertBox/AlertBox';
import AlertAddress from '../../components/Alerts/AlertAddress';
import { AppPage } from '../../../_common/components/app-page';
import {isSpanishUser} from "../../../_common/utils/getLang";
import { Field, Form as FinalForm } from 'react-final-form';
import { setBanner } from '../../../_rx/student/actions';
import Uniform from '../../components/Uniform/Uniform';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {PS_SHOPID_FR} from "../../../constants";
import { C, F } from '../../../_common/layout';
import { useEffect } from 'preact/hooks';
import { Link } from 'react-router-dom';
import './documentation-styles.scss';
import {Fragment, h} from "preact";
import useEvents from './useEvents';
import { t } from '../../../_i18n';
import {validateIBAN} from "../../components/IBAN/validateIBAN";

export function Documentation () {
  const static_documents = useSelector(state => state.fct.static_documents)
  const history = useHistory()
  const dispatch = useDispatch()
  const { handleExemptionCheck, handleChangeProvincePracticies, handlePracticiesPlaceCheck, saveDraft, submitForm, handleDossierCheck, handleChangeProvinceDossier, exemption_types, work_types, documents, provinces, cities, citiesDossier, formative_zone, isLoadingDraft, isLoadingSubmit } = useEvents()
  const { id } = useParams()
  const shopId = useSelector(state => state.status.student_meta.shop_id)

  useEffect(() => {
    if (documents) dispatch(setBanner(t('Esto es un borrador. Una vez hayas subido toda la información, por favor, envíala para que podamos proceder a su validación.')))
    return () => dispatch(setBanner(null))
  }, [documents])

  const scroll = () => {
    setTimeout(() => {
      const el = document.querySelector('.error');
      el && el.scrollIntoView({ behavior: 'smooth', block: 'center' })
    })
  }

  if (!documents) return <div />
  return (
    <AppPage back={history.goBack} className="max-width-980 m-top-x4">
      <F className="max-width-980">
        <C>
          <h1 className='hero'>{t('Documentación previa')}</h1>
          <FinalForm
            onSubmit={submitForm}
            initialValues={documents}
            render={({handleSubmit, values, form, submitFailed, ...props})=>
              <form action="" onSubmit={handleSubmit} noValidate>
                {submitFailed && !props.dirtySinceLastSubmit && scroll()}
                <div>
                  { isSpanishUser(shopId) &&
                      <div className='m-top'>
                        <FormField
                            name='meta.social_welfare_number'
                            label={<span dangerouslySetInnerHTML={{ __html: t('input_seguridad_social') }} />}
                            required
                            max={12}
                            min={12}
                            type='number'
                            specificError={t('Por favor, indica un número de Seguridad Social.')}
                            onBlur={() => saveDraft(values.meta)}
                        />
                        <div className={'m-top'}>
                          {<span dangerouslySetInnerHTML={{ __html: t('hint_seguridad_social') }} />}
                        </div>
                      </div>
                  }
                  <div className='m-top'>
                    <FormField
                      name='meta.health_card_number'
                      label={<span dangerouslySetInnerHTML={{ __html: t('ss_card_label') }} />}
                      required
                      specificError={t('Por favor, indica un número de tarjeta sanitaria.')}
                      onBlur={() => saveDraft(values.meta)}
                    />
                  </div>
                  <AlertAddress />
                  <h1 className='m-top-x4'>{t('Centro de trabajo')}</h1>
                  <p>{t('En el caso de que aportes una empresa donde realizar tus prácticas, ILERNA te obsequiará con un bono de 40€ que podrás utilizar en tu solicitud del título.')}</p>
                  <AlertBox type='shadow'>
                    <div>
                      <FormField
                          name='meta.give_company'
                          label={t('Tengo una empresa dónde realizar las prácticas')}
                          type='checkbox'
                          required
                      />
                    </div>
                    {values.meta.give_company && (
                        <div>
                          <p>{t('Es necesario que aportes el documento con información de la empresa:')}</p>
                          <ol>
                            <li>
                              {t('Descárgate e imprime el documento')} <a
                                href={static_documents.company.url}>{t('Datos de empresa.pdf')}</a>.
                              {!isSpanishUser(shopId) &&
                                  <AlertBox type={"dashed"}>
                                    {t('fct_international_help')}
                                    <a href={t('fct_contribute_comp_international_help_document_link')}>{t('fct_contribute_comp_international_help_document')}.</a>
                                  </AlertBox>
                              }
                            </li>
                            <li>{t('Proporciónaselo a la empresa para que lo rellene.')}</li>
                            <li>{t('Escanea el documento y vuelve a subirlo a continuación.')}</li>
                            <AlertBox type='dashed'>
                              <p>{t('Ten en cuenta que si entregas este documento completo al inicio del curso, podrás comenzar las prácticas durante las primeras semanas.')}</p>
                              <p>{t('Es importante que confirmes con la empresa el horario en el que realizarás las prácticas para agilizar la gestión. Además, debes indicarles que se prevé que las prácticas comiencen a partir de ')}<span
                                  className='font-capitalize'>{documents.fct_start_month}</span>.</p>
                            </AlertBox>
                          </ol>
                          <Field
                              name='blocks.company_info.documents.company_details'
                              label={t('Documento de empresa')}
                              component={UploadDocument}
                              typeDocument='company_details'
                              contractId={id}
                              className='m-top-x2'
                              specificError={t('Por favor, agrega el documento con información de la empresa.')}
                              required
                              validate={validationDocumentFCT}
                          />
                          {(values.mad_or_cat === 'MAD' && isSpanishUser(shopId)) && (
                            <Fragment>
                              <span className="m-top-x2">{t('text_IBAN')}</span>
                              <div className="m-top-x2">
                                <Field
                                    name='meta.iban'
                                    component={IBANField}
                                    label={<span dangerouslySetInnerHTML={{__html: t('input_IBAN')}}/>}
                                    required
                                    values={values}
                                    validate={validateIBAN}
                                />
                              </div>
                            </Fragment>
                          )}
                        </div>
                    )}
                  </AlertBox>
                  <p className='m-top-x2'>{t('Si no tienes una empresa disponible, no te preocupes, ya que te ofreceremos un centro adecuado donde podrás poner en práctica y desarrollar los conocimientos adquiridos durante el curso.')}</p>
                  {(isSpanishUser(shopId)) && (
                      <Fragment>
                        <h2 className='m-top-x2'>{t('Lugar de las practicas')}</h2>
                        <p>{t('Para facilitarte los desplazamientos, buscaremos un centro o empresa que quede cerca de tu domicilio. Si quieres realizar las prácticas en un lugar diferente, indícalo a continuación.')}</p>
                      <AlertBox type='shadow'>
                        <div>
                          <FormField
                            name='meta.fct_in_other_city.check'
                            label={t('Realizar las prácticas en una población distinta al domicilio actual')}
                            type='checkbox'
                            required
                            onChange={(val) => handlePracticiesPlaceCheck(val, form)} />
                        </div>
                        {values.meta.fct_in_other_city.check && (
                          <div>
                            <div className='m-top'>
                              <FormField
                                  name='meta.fct_in_other_city.cp'
                                  label={<span dangerouslySetInnerHTML={{ __html: t('postal_code_label') }} />}
                                  required
                                  specificError={t('Por favor, indica en que código postal desea recibir el material.')}
                                  onBlur={() => saveDraft(values.meta)}
                              />
                            </div>
                            <div className='m-top'>
                              <FormFieldSelect
                                name='meta.fct_in_other_city.province_id'
                                label={<span dangerouslySetInnerHTML={{ __html: t('province_label') }} />}
                                options={provinces}
                                disabled={!provinces.length}
                                required
                                specificError={t('Por favor, indica en que provincia desea hacer las prácticas.')}
                                onChange={(val) => {
                                  saveDraft(values.meta)
                                  handleChangeProvincePracticies(val, form)
                                }}
                              />
                            </div>
                            <div className='m-top'>
                              <FormFieldSelect
                                name='meta.fct_in_other_city.city_id'
                                label={<span dangerouslySetInnerHTML={{ __html: t('city_label') }} />}
                                options={cities}
                                disabled={!cities.length}
                                required
                                specificError={t('Por favor, indica en que población desea hacer las prácticas.')}
                                onChange={() => {
                                  saveDraft(values.meta)
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </AlertBox>
                    </Fragment>
                  )}
                  <h2 className='m-top-x2'>{t('Horario')}</h2>
                  <p>{t('Indica qué horario aproximado te iría bien para hacer las prácticas. Intentaremos que se ajuste con las necesidades de la empresa.')}</p>
                  <div className='m-bottom-half'><span className='font-bold'>{t('Disponibilidad horaria')}</span> <span className='font-color-red'> *</span></div>
                  <InputSchedule name='meta.availability' onSaveDraft={() => saveDraft(values.meta)} />
                  { (isSpanishUser(shopId)) && (values.is_dress_uniform || values.is_dress_pijama || values.is_dress_coat) && <Uniform values={values} onSaveDraft={() => saveDraft(values.meta)} />}
                  { (isSpanishUser(shopId)) && (values.is_dosimeter) && (
                    <Fragment>
                      <h2 className='m-top-x2'>{t('Dosímetro')}</h2>
                      <p>{t('Para llevar a cabo las prácticas, será necesario contar con un dosímetro, dependiendo de si la empresa te va a proporcionar o no. En caso de requerirlo, el coste del alquiler del dosímetro será de 75€. Si en tu caso es necesario, te facilitaremos un enlace para que puedas realizar el pago.')}</p>
                      <AlertBox type='shadow'>
                        <div>
                          <FormField
                            name='meta.material_address.check'
                            label={t('Enviar el material a una dirección distinta al domicilio actual')}
                            type='checkbox'
                            onChange={(val) => handleDossierCheck(val, form)}
                            required
                          />
                        </div>
                        {values.meta.material_address.check && (
                          <div>
                            <div className='m-top'>
                              <FormField
                                name='meta.material_address.address'
                                label={<span dangerouslySetInnerHTML={{ __html: t('address_label') }} />}
                                required
                                specificError={t('Por favor, indica en que dirección desea recibir el material.')}
                                onBlur={() => saveDraft(values.meta)}
                              />
                            </div>
                            <div className='m-top'>
                              <FormField
                                name='meta.material_address.cp'
                                label={<span dangerouslySetInnerHTML={{ __html: t('postal_code_label') }} />}
                                required
                                specificError={t('Por favor, indica en que código postal desea recibir el material.')}
                                onBlur={() => saveDraft(values.meta)}
                              />
                            </div>
                            <div className='m-top'>
                              <FormFieldSelect
                                name='meta.material_address.province_id'
                                label={<span dangerouslySetInnerHTML={{ __html: t('province_label') }} />}
                                options={provinces}
                                disabled={!provinces.length}
                                required
                                specificError={t('Por favor, indica en que provincia desea recibir el material.')}
                                onChange={(val) => {
                                  saveDraft(values.meta)
                                  handleChangeProvinceDossier(val, form)
                                }}
                              />
                            </div>
                            <div className='m-top'>
                              <FormFieldSelect
                                name='meta.material_address.city'
                                label={<span dangerouslySetInnerHTML={{ __html: t('city_label') }} />}
                                options={citiesDossier}
                                disabled={!citiesDossier.length}
                                required
                                specificError={t('Por favor, indica en que población desea recibir el material.')}
                                onChange={() => saveDraft(values.meta)}
                              />
                            </div>
                          </div>
                        )}
                      </AlertBox>
                    </Fragment>
                  )}
                  { exemption_types.length !== 0 && <Fragment>
                    <h1 className='m-top-x4'>{t('Exención')}</h1>
                    <p>{t('Si tienes experiencia laboral previa, puedes solicitar una reducción o exención total de las horas de tus prácticas en empresas. Para hacerlo, deberás demostrar una experiencia mínima relacionada con el ciclo formativo que estás cursando. El claustro de profesores evaluará la documentación que envíes y te informará sobre el tipo de exención que se te pueda conceder.')}</p>
                    <ul>
                      {exemption_types?.map(percentDate => {
                        if (percentDate.key === 25) return <li dangerouslySetInnerHTML={{ __html: t('fct_percent_exemption_no_related').replace('{percent_number}', percentDate.name).replace('{percent_days}', percentDate.days) }} />
                        else return <li dangerouslySetInnerHTML={{ __html: t('fct_percent_exemption_related').replace('{percent_number}', percentDate.name).replace('{percent_days}', percentDate.days) }} />
                      })}
                    </ul>
                    <AlertBox type='shadow'>
                      <div>
                        <FormField
                          name='meta.exemption.check'
                          label={t('Solicitar exención')}
                          type='checkbox'
                          required
                          onChange={(val) => handleExemptionCheck(val, form)}
                        />
                      </div>
                      {values.meta.exemption.check && (
                        <div>
                          <div className='m-top'>
                            <FormFieldRadioCheckboxList
                              name='meta.exemption.percent'
                              options={exemption_types}
                              required
                              label={<span dangerouslySetInnerHTML={{ __html: t('exemption_label') }}/>}
                              specificError={t('Por favor, indica un porcentaje de exención.')}
                              validate={isRequiredWithZero}
                              onChange={(val) => saveDraft({...values.meta, exemption: { ...values.meta.exemption, percent: val}})}
                            />
                          </div>
                          <div className='m-top-x2'>
                            <FormFieldRadioCheckboxList
                              name='meta.exemption.type'
                              options={work_types}
                              required
                              label={<span dangerouslySetInnerHTML={{ __html: t('exemption_work_types_label') }} />}
                              specificError={t('Por favor, indica que tipo de experiencia laboral has tenido.')}
                              onChange={(val) => saveDraft({...values.meta, exemption: { ...values.meta.exemption, type: val}})}
                            />
                          </div>
                          <AlertBox type='dashed' className='m-top'>
                            <p>{t('Deberás proporcionar los documentos que acrediten tu experiencia laboral.')}</p>
                            <p>{t('Una vez que el claustro de profesores evalúe tu caso, se te otorgará el porcentaje correspondiente.')}</p>
                          </AlertBox>
                        </div>
                      )}
                    </AlertBox>
                  </Fragment>}
                  <h1 className='m-top-x4'>{t('Documentos')}</h1>
                  <Field
                    name='blocks.student_info.documents.resume'
                    label={t('Curriculum actualizado y con foto')}
                    component={UploadDocument}
                    description={t('Es importante que el currículum esté actualizado y que incluya una fotografía tuya.')}
                    specificError={t('Por favor, agrega un curriculum actualizado.')}
                    typeDocument='resume'
                    contractId={id}
                    className='m-top'
                    required
                    validate={validationDocumentFCT}
                  />
                  { !isSpanishUser(shopId) && (
                      <Fragment>
                        <Field
                            name='blocks.student_info.documents.motivation'
                            label={t('Carta motivación')}
                            component={UploadDocument}
                            description={
                              <Fragment>
                                <span>
                                  {t('Descarga el formulario:')}
                                  <a href={shopId === PS_SHOPID_FR ? static_documents.motiv_fr_es.url : static_documents.motiv_it_es.url}
                                     target='_blank'
                                     className='font-14 txt-color-primary t-underline font-medium'
                                     download='Carta_de_motivación.pdf'
                                  >
                                    Carta_de_motivación.pdf.
                                  </a>
                                  {t('Complétalo y súbelo de nuevo.')}
                                </span>
                                <AlertBox type='warning' className="m-x2-bottom m-top">
                                  {t('Debe completar el documento en español y para facilitarle la tarea, le proporcionamos la traducción en francés del mismo:')}
                                  <span className="m-x2-top m-x2-bottom">
                                    <a href={shopId === PS_SHOPID_FR ? static_documents.motiv_fr.url : static_documents.motiv_it.url}
                                       target='_blank'
                                       className='font-bold t-underline'
                                       download={t('carta_motivacion')}
                                    >
                                      {t('carta_motivacion')}
                                    </a>
                                  </span>
                                </AlertBox>
                              </Fragment>
                            }
                            specificError={t('Por favor, agrega la carta de motivación.')}
                            typeDocument='motivation'
                            contractId={id}
                            className='m-x2-top'
                            required
                            validate={validationDocumentFCT}
                        />
                      </Fragment>
                  )}
                  {values.mad_or_cat === 'MAD' && (
                      <Field
                          name='blocks.student_info.documents.census'
                          label={t('Certificado de empadronamiento')}
                          component={UploadDocument}
                          description={t('Es un documento informativo emitido por tu ayuntamiento que certifica tu residencia y domicilio habitual.')}
                          specificError={t('Por favor, agrega el certificado de empadronamiento.')}
                          typeDocument='census'
                          contractId={id}
                          className='m-x2-top'
                          required
                      validate={validationDocumentFCT}
                    />
                  )}
                  { values.is_work_with_children && (
                    <Field
                      name='blocks.student_info.documents.no_sexual_offences'
                      label={t('Certificado de delitos de naturaleza sexual')}
                      component={UploadDocument}
                      description={isSpanishUser(shopId) && <span dangerouslySetInnerHTML={{ __html: t('no_sexual_offences_description') }} />}
                      typeDocument='no_sexual_offences'
                      specificError={t('Por favor, agrega el certificado de delitos de naturaleza sexual.')}
                      contractId={id}
                      className='m-x2-top'
                      required
                      validate={validationDocumentFCT}
                    />
                  )}
                  { values.is_health && (
                    <Field
                      name='blocks.student_info.documents.vaccination'
                      label={t('Cartilla de vacunas')}
                      component={UploadDocument}
                      description={t('Es recomendable que proporciones una fotocopia actualizada y completa de tu carné de vacunación. En caso de que no puedas obtenerlo, deberás aportar una serología (análisis de las vacunas que te han administrado hasta el momento). Estas son las vacunas que recomendamos: Las establecidas a nivel nacional, triple vírica, hepatitis B, tétanos y gripe (en época de gripe).')}
                      typeDocument='vaccination'
                      specificError={t('Por favor, agrega la cartilla de vacunas.')}
                      contractId={id}
                      className='m-x2-top'
                      validate={validationDocumentFCTOptional}
                    />
                  )}
                  { values.meta.exemption.check && (
                    <Fragment>
                      <h2 className='m-top-x2'>{t('Documentos para la exención')}</h2>
                      { values.mad_or_cat === 'MAD' && (
                        <Field
                          name='blocks.exemption.documents.exemption_request'
                          label={t('Solicitud de exención')}
                          component={UploadDocument}
                          description={
                            <span>
                              {t('Descarga la plantilla:')} <a href={static_documents.exemption.url} className='font-color-149BAB'>Solicitud de exención</a>. {t('Complétala y luego vuelve a subirla.')}
                              { !isSpanishUser(shopId) &&
                                  <AlertBox type={"info"}>
                                    {t('fct_international_help')}
                                    <a href={t('fct_exemption_international_help_document_link')}>{t('fct_exemption_international_help_document')}.</a>
                                  </AlertBox>
                              }
                            </span>
                          }
                          specificError={t('Por favor, agrega la solicitud de exención.')}
                          typeDocument='exemption_request'
                          contractId={id}
                          className='m-x2-top'
                          required
                          validate={validationDocumentFCT}
                        />
                      )}
                      { (values.meta.exemption.percent === 50 || values.meta.exemption.percent === 100) &&  values.meta.exemption.type === 'work_others' && (
                        <Field
                          name='blocks.exemption.documents.tasks'
                          label={t('Certificado de tareas')}
                          component={UploadDocument}
                          description={
                            <span>
                              {t('Descarga la plantilla:')} <a href={static_documents.tasks.url} className='font-color-149BAB'>Certificado de tareas</a>. {t('Complétala y luego vuelve a subirla.')}
                              { !isSpanishUser(shopId) &&
                                  <AlertBox type={"info"}>
                                    {t('fct_international_help')}
                                    <a href={t('fct_cert_tareas_international_help_document_link')}>{t('fct_cert_tareas_international_help_document')}.</a>
                                  </AlertBox>
                              }
                            </span>
                          }
                          specificError={t('Por favor, agrega el certificado de tareas.')}
                          typeDocument='tasks'
                          contractId={id}
                          className='m-x2-top'
                          required
                          validate={validationDocumentFCT}
                        />
                      )}
                      <Field
                        name='blocks.exemption.documents.work_life'
                        label={t('Informe de vida laboral')}
                        component={UploadDocument}
                        description={isSpanishUser(shopId) && <span dangerouslySetInnerHTML={{ __html: t('work_life_label') }} />}
                        specificError={t('Por favor, agrega el informe de vida laboral.')}
                        typeDocument='work_life'
                        contractId={id}
                        className='m-x2-top'
                        required
                        validate={validationDocumentFCT}
                      />
                      { (values.meta.exemption.percent === 50 || values.meta.exemption.percent === 100) && values.meta.exemption.type === 'freelance' && (
                        <Fragment>
                          <Field
                            name='blocks.exemption.documents.affidavit'
                            label={t('Declaración jurada')}
                            component={UploadDocument}
                            description={
                              <span>
                                {t('Descarga la plantilla:')} <a href={static_documents.affidavit.url} className='font-color-149BAB'>Declaración jurada</a>. {t('Complétala y luego vuelve a subirla.')}
                                { !isSpanishUser(shopId) &&
                                    <AlertBox type={"info"}>
                                      {t('fct_international_help')}
                                      <a href={t('fct_declaracion_jurada_help_document_link')}>{t('fct_declaracion_jurada_international_help_document')}.</a>
                                    </AlertBox>
                                }
                              </span>
                            }
                            specificError='Por favor, agrega la declaración jurada.'
                            typeDocument='affidavit'
                            contractId={id}
                            className='m-x2-top'
                            required
                            validate={validationDocumentFCT}
                          />
                          <Field
                            name='blocks.exemption.documents.economic_activity'
                            label={t('Certificado de alta de la actividad económica')}
                            component={UploadDocument}
                            specificError={t('Por favor, agrega el certificado de alta de la actividad económica.')}
                            description={isSpanishUser(shopId) && <span dangerouslySetInnerHTML={{ __html: t('economic_activity_label') }} />}
                            typeDocument='economic_activity'
                            contractId={id}
                            className='m-x2-top'
                            required
                            validate={validationDocumentFCT}
                          />
                          <Field
                            name='blocks.exemption.documents.contribution'
                            label={t('Recibo de cotización en la Seguridad Social')}
                            component={UploadDocument}
                            description={isSpanishUser(shopId) && t('Sube el recibo más actual.')}
                            specificError={t('Por favor, agrega el recibo de cotización en la seguridad social.')}
                            typeDocument='contribution'
                            contractId={id}
                            className='m-x2-top'
                            required
                            validate={validationDocumentFCT}
                          />
                          <Field
                            name='blocks.exemption.documents.fee'
                            label={t('Cuota de Régimen de Autónomo')}
                            component={UploadDocument}
                            description={isSpanishUser(shopId) && <span dangerouslySetInnerHTML={{ __html: t('fee_label') }} />}
                            specificError={t('Por favor, agrega la cuota de régimen de autónomo.')}
                            typeDocument='fee'
                            contractId={id}
                            className='m-x2-top'
                            required
                            validate={validationDocumentFCT}
                          />
                          <Field
                            name='blocks.exemption.documents.model130'
                            label={t('Declaración trimestral (modelo 130)')}
                            component={UploadDocument}
                            description={isSpanishUser(shopId) && <span dangerouslySetInnerHTML={{ __html: t('model130_label') }} />}
                            typeDocument='model130'
                            specificError={t('Por favor,agrega el modelo 130 de la declaración trimestral.')}
                            contractId={id}
                            className='m-x2-top'
                            required
                            validate={validationDocumentFCT}
                          />
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </div>
                <div className='m-top-x4'>
                  <hr className="separator-xxs"/>
                  <div className='fct-documentation-button-bar'>
                    <div className='save-draft'>
                      { isLoadingDraft
                        ? <Fragment>
                          <i className="fal fa-spinner-third loading" />
                          <span>{t('Guardando')}...</span>
                          </Fragment>
                        : <Fragment>
                            <i className="fal fa-check-circle"/>
                            <span>{t('Cambios guardados')}</span>
                          </Fragment>
                      }
                    </div>
                    <div className='button-section'>
                      <Link to={`/fct/${id}`}><ButtonGhost type='button'>{t('Salir')}</ButtonGhost></Link>
                      <div><ButtonSpinner type='submit' showSpinner={isLoadingSubmit}>{t('Enviar documentación')}</ButtonSpinner></div>
                    </div>
                  </div>
                </div>
              </form>
            }/>
        </C>
      </F>
    </AppPage>
  )
}
