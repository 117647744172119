import {globalCatch} from "../../../../_services/global-catch";
import {enrollmentApi, eventDetailApi, eventsApi, classGradesApi} from "../../../../_services/classes-service";
import {
  SET_CLASSES_STATUS,
  SET_HISTORICAL_CLASSES,
  SET_LOADING_HISTORICAL_CLASSES,
  SET_CLASS_SELECTED,
  SET_CANCEL_ENROLL_CLASS,
  SET_CLASS_ATTENDANCE_GRADES_CLASS
} from './constants';
import {downloadFile} from '../../../../utils';
import { TOKEN_EXPEDIENT } from '../../../../constants'

export const setClassSelected = params => ({type: SET_CLASS_SELECTED, payload: params});
export const setStatusClasses = payload => ({type: SET_CLASSES_STATUS, payload: payload});
export const setHistoricalClasses = payload => ({type: SET_HISTORICAL_CLASSES, payload: payload});
export const setLoadingHistorical = payload => ({type: SET_LOADING_HISTORICAL_CLASSES, payload: payload});
export const setCancelEnroll = payload => ({type: SET_CANCEL_ENROLL_CLASS, payload: payload});
export const setShowGradesEnroll = payload => ({type: SET_CLASS_ATTENDANCE_GRADES_CLASS, payload: payload});

export const getStatusClasses = (studentId) => globalCatch.catch( async dispatch => {
  const upcomingClasses = await(await eventsApi()).enrollmentClassesControllerGetFormativeActions(studentId)
  dispatch(setStatusClasses(upcomingClasses))
})

export const getPastClasses = (studentId) => globalCatch.catch( async dispatch => {
  dispatch(setLoadingHistorical(true))
  dispatch(setHistoricalClasses(null))
  const historicalClasses = await(await eventsApi()).enrollmentClassesControllerGetPastEvents(studentId)
  dispatch(setHistoricalClasses(historicalClasses))
  dispatch(setLoadingHistorical(false))
})

export const getNextClasses = (studentId) => globalCatch.catch( async dispatch => {
  dispatch(setLoadingHistorical(true))
  dispatch(setHistoricalClasses(null))
  const historicalClasses = await(await eventsApi()).enrollmentClassesControllerGetNextEvents(studentId)
  dispatch(setHistoricalClasses(historicalClasses))
  dispatch(setLoadingHistorical(false))
})

export const getBuildings = async ( formativeActionId, studentId ) => {
  try {
    const classes = await(await eventDetailApi()).enrollmentClassesControllerGetClassesBuildings(formativeActionId, studentId)
    return classes.map(building => ({
      id: building.building_id,
      city: building.state,
      address: `${building.building_name ? `${building.building_name}. ${building.address_name}` : building.address_name} ${building.city && `(${building.city})`}`
    }))
  } catch (e) {
    return []
  }

}

export const getSchedules = async ({studentId, buildingId, formativeActionId}) => {
  try {
    return await(await eventDetailApi()).enrollmentClassesControllerGetShedulesClasses(studentId, buildingId, formativeActionId)
  } catch (e) {
    return []
  }
}

export const enrollClass = async ({studentId, eventId, formativeActionId}) => {
  const response = {error: null, data: null}
  try{
    response.data = await (await enrollmentApi()).enrollmentClassesControllerEnrollmentClasses(studentId, eventId, formativeActionId)
  }catch (e) {
    response.error = e
  }
  return response
}

export const modifyEnroll = async ({studentId, eventId, formativeActionId, oldEventId}) => {
  const response = {error: null, data: null}
  try{
    response.data = await (await enrollmentApi()).enrollmentClassesControllerUpdateEnrollmentClasses(studentId, eventId, formativeActionId, oldEventId)
  }catch (e) {
    response.error = e
  }
  return response
}

export const cancelEnroll = async ({studentId, eventId, formativeActionId}) => {
  const response = {error: null, data: null}
  try{
    response.data =  await (await enrollmentApi()).enrollmentClassesControllerCancelEnrollmentClasses(studentId, eventId, formativeActionId)
  }catch (e) {
    response.error = e
  }
  return response
}

export const getClassCertificate = async (studentMail, locale = 'es', academicSection) => {
  await downloadFile({
    url: `${micuentaenv.STUDENT_ACCOUNT_API_basePath}/lti/classes/students/${studentMail}/class-attendance-certificate?academic_section=${academicSection}&locale=${locale}`,
    fileName:'Certificado_asistencia',
    TOKEN: TOKEN_EXPEDIENT
  })
}

export const getClassJoinedCertificate = async (studentMail, locale = 'es', academicSection) => {
  await downloadFile({
    url: `${micuentaenv.STUDENT_ACCOUNT_API_basePath}/account/classes/students/${studentMail}/summary-class-convocation?academic_section=${academicSection}&locale=${locale}`,
    fileName:'Justificante_inscripción',
    TOKEN: TOKEN_EXPEDIENT
  })
}

export const getClassSessionGrades = async (studentId, eventCalendarId) => {
  return await (await classGradesApi()).classAttendanceControllerGetClassAttendanceGrade(studentId, eventCalendarId)
}

