import React, { Fragment, Component, h} from "preact";
import Modal from '../../../_common/components/Modal/index'
import { FR } from '../../../_common/layout'
import { t } from '../../../_i18n'
import { getClassCertificate, getClassJoinedCertificate } from '../../../_rx/enrollment/classes/actions';
import {useEffect, useState} from 'preact/hooks'
import ButtonSpinner from '../../../_common/components/buttons/buttonSpinner/buttonSpinner'
import { ButtonGhost } from '../../../_common/components/buttons/buttons'
import './academicSectionSelector.scss'

export default function ModalCertificateClasses ({showModal, onClose, type, studentMail, locale, academicSections}) {
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(-1);
  const [isDisabled, setIsDisabled] = useState(true);
  const typeModal = type === 'class' ? 'asistencia' : 'inscripción'

  useEffect(() => {
    setOptions(academicSections)
  }, [academicSections]);

  const handleClose = () => {
    setLoading(false)
    setSelectedOption(-1)
    setIsDisabled(true)
    onClose()
  }

  const handleSelectionChange = (event) => {
    setSelectedOption(event.target.value);
    setIsDisabled(event.target.value === -1);
  };

  const downloadCertificate = async () => {
    setLoading(true)
    type === 'class' ? await getClassCertificate(studentMail, locale, selectedOption) : await getClassJoinedCertificate(studentMail, locale, selectedOption)
    setLoading(false)
  }

  return (
    <Modal visible={showModal} closeModal={handleClose}>
      <FR className={'modal-title-padding row-2-el p-all p-x2-left p-x2-right'}>
        <div className='col-80'>
          <p className="t-left font-20 no-margin">{t('Descargar justificante')}</p>
        </div>
        <div className={'t-right col-20'}>
          <a onClick={handleClose} className="modal-close-jobs modal-link"> <i className="fal fa-times font-20" /> </a>
        </div>
      </FR>
      <hr className="separator-xxs no-margin-top"/>
      <div className='p-x2-left p-x2-right p-bottom'>
        <p className='font-16'>{t(`¿Deseas descargar el justificante de ${typeModal}?`)}</p>
        <div className='m-bottom'>
          <label htmlFor="selector" className='m-r-half'>{t('Selecciona un período académico')}</label>
          <select className='dropdownAcademicSections' id="selector" value={selectedOption} onChange={handleSelectionChange}>
            <option value={-1}>{t('Selecciona una opción')}</option>
            {options.map((opt, index) =>
                <option value={opt.id}>{opt.name}</option>
            )}
          </select>
        </div>
        <div className='d-flex gap'>
          <ButtonSpinner disabled={isDisabled} showSpinner={loading} onClick={downloadCertificate}>{t('Descargar')}</ButtonSpinner>
          <ButtonGhost onClick={handleClose}>{t('Cancelar')}</ButtonGhost>
        </div>
      </div>
    </Modal>
  )
}
