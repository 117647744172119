/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Question from './Question';

/**
 * The CompanyOffer model module.
 * @module model/CompanyOffer
 * @version v1
 */
class CompanyOffer {
    /**
     * Constructs a new <code>CompanyOffer</code>.
     * Detalle de oferta [P_E_05] [P_E_12]
     * @alias module:model/CompanyOffer
     */
    constructor() { 
        
        CompanyOffer.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CompanyOffer</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CompanyOffer} obj Optional instance to populate.
     * @return {module:model/CompanyOffer} The populated <code>CompanyOffer</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CompanyOffer();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('display_company_name')) {
                obj['display_company_name'] = ApiClient.convertToType(data['display_company_name'], 'Boolean');
            }
            if (data.hasOwnProperty('company_name')) {
                obj['company_name'] = ApiClient.convertToType(data['company_name'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('text')) {
                obj['text'] = ApiClient.convertToType(data['text'], 'String');
            }
            if (data.hasOwnProperty('requirements')) {
                obj['requirements'] = ApiClient.convertToType(data['requirements'], 'String');
            }
            if (data.hasOwnProperty('max_jobs')) {
                obj['max_jobs'] = ApiClient.convertToType(data['max_jobs'], 'Number');
            }
            if (data.hasOwnProperty('dept')) {
                obj['dept'] = ApiClient.convertToType(data['dept'], 'String');
            }
            if (data.hasOwnProperty('modality')) {
                obj['modality'] = ApiClient.convertToType(data['modality'], 'String');
            }
            if (data.hasOwnProperty('presence')) {
                obj['presence'] = ApiClient.convertToType(data['presence'], 'String');
            }
            if (data.hasOwnProperty('date')) {
                obj['date'] = ApiClient.convertToType(data['date'], 'Number');
            }
            if (data.hasOwnProperty('zip')) {
                obj['zip'] = ApiClient.convertToType(data['zip'], 'String');
            }
            if (data.hasOwnProperty('city')) {
                obj['city'] = ApiClient.convertToType(data['city'], 'String');
            }
            if (data.hasOwnProperty('state')) {
                obj['state'] = ApiClient.convertToType(data['state'], 'Number');
            }
            if (data.hasOwnProperty('contract_type')) {
                obj['contract_type'] = ApiClient.convertToType(data['contract_type'], 'Number');
            }
            if (data.hasOwnProperty('length')) {
                obj['length'] = ApiClient.convertToType(data['length'], 'String');
            }
            if (data.hasOwnProperty('schedule')) {
                obj['schedule'] = ApiClient.convertToType(data['schedule'], 'Number');
            }
            if (data.hasOwnProperty('salary_type')) {
                obj['salary_type'] = ApiClient.convertToType(data['salary_type'], 'String');
            }
            if (data.hasOwnProperty('salary_min')) {
                obj['salary_min'] = ApiClient.convertToType(data['salary_min'], 'Number');
            }
            if (data.hasOwnProperty('salary_max')) {
                obj['salary_max'] = ApiClient.convertToType(data['salary_max'], 'Number');
            }
            if (data.hasOwnProperty('benefits')) {
                obj['benefits'] = ApiClient.convertToType(data['benefits'], 'String');
            }
            if (data.hasOwnProperty('benefits_text')) {
                obj['benefits_text'] = ApiClient.convertToType(data['benefits_text'], 'String');
            }
            if (data.hasOwnProperty('academic_programs')) {
                obj['academic_programs'] = ApiClient.convertToType(data['academic_programs'], ['Number']);
            }
            if (data.hasOwnProperty('accept_unfinished')) {
                obj['accept_unfinished'] = ApiClient.convertToType(data['accept_unfinished'], 'Boolean');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('is_remote_process')) {
                obj['is_remote_process'] = ApiClient.convertToType(data['is_remote_process'], 'Boolean');
            }
            if (data.hasOwnProperty('has_applications')) {
                obj['has_applications'] = ApiClient.convertToType(data['has_applications'], 'Boolean');
            }
            if (data.hasOwnProperty('questions')) {
                obj['questions'] = ApiClient.convertToType(data['questions'], [Question]);
            }
            if (data.hasOwnProperty('counts')) {
                obj['counts'] = ApiClient.convertToType(data['counts'], [Object]);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
CompanyOffer.prototype['id'] = undefined;

/**
 * @member {Boolean} display_company_name
 */
CompanyOffer.prototype['display_company_name'] = undefined;

/**
 * Nombre a mostrar en caso de que no se quiera mostrar empresa
 * @member {String} company_name
 */
CompanyOffer.prototype['company_name'] = undefined;

/**
 * @member {String} title
 */
CompanyOffer.prototype['title'] = undefined;

/**
 * @member {String} text
 */
CompanyOffer.prototype['text'] = undefined;

/**
 * @member {String} requirements
 */
CompanyOffer.prototype['requirements'] = undefined;

/**
 * @member {Number} max_jobs
 */
CompanyOffer.prototype['max_jobs'] = undefined;

/**
 * @member {String} dept
 */
CompanyOffer.prototype['dept'] = undefined;

/**
 * normal, fct, dual
 * @member {String} modality
 */
CompanyOffer.prototype['modality'] = undefined;

/**
 * on_site, semi, remote
 * @member {String} presence
 */
CompanyOffer.prototype['presence'] = undefined;

/**
 * @member {Number} date
 */
CompanyOffer.prototype['date'] = undefined;

/**
 * @member {String} zip
 */
CompanyOffer.prototype['zip'] = undefined;

/**
 * @member {String} city
 */
CompanyOffer.prototype['city'] = undefined;

/**
 * Id Provincia
 * @member {Number} state
 */
CompanyOffer.prototype['state'] = undefined;

/**
 * id tipo de contrato
 * @member {Number} contract_type
 */
CompanyOffer.prototype['contract_type'] = undefined;

/**
 * duración del trabajo
 * @member {String} length
 */
CompanyOffer.prototype['length'] = undefined;

/**
 * id del horario
 * @member {Number} schedule
 */
CompanyOffer.prototype['schedule'] = undefined;

/**
 * bruto año, neto al mes, ...
 * @member {String} salary_type
 */
CompanyOffer.prototype['salary_type'] = undefined;

/**
 * @member {Number} salary_min
 */
CompanyOffer.prototype['salary_min'] = undefined;

/**
 * @member {Number} salary_max
 */
CompanyOffer.prototype['salary_max'] = undefined;

/**
 * array de strings en json
 * @member {String} benefits
 */
CompanyOffer.prototype['benefits'] = undefined;

/**
 * otros beneficios
 * @member {String} benefits_text
 */
CompanyOffer.prototype['benefits_text'] = undefined;

/**
 * lista de ids, tiraremos de fixtures
 * @member {Array.<Number>} academic_programs
 */
CompanyOffer.prototype['academic_programs'] = undefined;

/**
 * Acepta alumnos que no hayan acabado el ciclo
 * @member {Boolean} accept_unfinished
 */
CompanyOffer.prototype['accept_unfinished'] = undefined;

/**
 * unpublished, published, closed
 * @member {String} status
 */
CompanyOffer.prototype['status'] = undefined;

/**
 * El proceso de selección es todo remoto
 * @member {Boolean} is_remote_process
 */
CompanyOffer.prototype['is_remote_process'] = undefined;

/**
 * Ya hay gente que aplico en la oferta
 * @member {Boolean} has_applications
 */
CompanyOffer.prototype['has_applications'] = undefined;

/**
 * @member {Array.<module:model/Question>} questions
 */
CompanyOffer.prototype['questions'] = undefined;

/**
 * count applications group by status
 * @member {Array.<Object>} counts
 */
CompanyOffer.prototype['counts'] = undefined;






export default CompanyOffer;

