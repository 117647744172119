import { SET_COMUNICADOS, SET_COMUNICADO, SET_COMUNICADO_ADJUNTOS, SET_COMUNICADOS_STATUS } from "../actions/constants";
import {createReducer} from "../../../_common/common.ts";

export const comunicados = createReducer(null, {
    [SET_COMUNICADOS]: (state, payload)=> payload
});

export const comunicado = createReducer(null, {
    [SET_COMUNICADO]: (state, payload)=> payload
});

export const comunicado_adjuntos = createReducer(null, {
    [SET_COMUNICADO_ADJUNTOS]: (state, payload)=> payload
});

export const comunicados_status = createReducer(null, {
    [SET_COMUNICADOS_STATUS]: (state, payload)=> payload
});
