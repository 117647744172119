import { h } from "preact";
import Modal from '../../../_common/components/Modal/index'
import { Field, Form as FinalForm } from "react-final-form"
import { FormFieldTextArea, TextArea } from '../../../_common/forms/components/components'
import { fetchMessages, sendMessage } from '../../../_rx/fct/actions'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { toastService } from '../../../_services/toast-service'
import ButtonSpinner from '../../../_common/components/buttons/buttonSpinner/buttonSpinner'
import { useState } from 'preact/hooks'
import { ButtonLink } from '../../../_common/components/buttons/buttons'
import UploadDocumentTimeline from './UploadDocumenTimeline'
import { t } from '../../../_i18n'

export default function ModalNewMessage ({ visible, onClose }) {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { id } = useParams()

  const handleSubmit = (val) => {
    setLoading(true)
    sendMessage(id, { content: val.text, attachments: val.files.map(file => file.id)})
      .then(async () => {
        await dispatch(fetchMessages(id))
        onClose()
      })
      .catch(() => {
        toastService.showToast({message: t('Ha sucedido un error al guardar el comentario'), type:'danger'});
      })
      .finally(() => setLoading(false))
  }

  return (
    <Modal visible={visible} closeModal={onClose}>
      <FinalForm
        onSubmit={handleSubmit}
        initialValues={{text: '', files: []}}
        render={(formEvents)=> (
          <form action="src/fct/components/Timeline" onSubmit={formEvents.handleSubmit} noValidate>
            <div className='fct-modal'>
              <div className='fct-modal-header'>
                <i className="fal fa-times" onClick={onClose} />
              </div>
              <div className='fct-modal-body__title font-bold m-bottom'>{t('Añadir comentario nuevo')}</div>
              <div className='fct-modal-body'>
                <p>{t('Escribe el comentario a continuación')}</p>
                <FormFieldTextArea name='text' showOptional={false} required specificError={t('Por favor, antes de enviar tu comentario, asegúrate de haber escrito algún texto.')} />
                <Field
                  name='files'
                  component={UploadDocumentTimeline}
                  className='m-top-x2'
                  contractId={id}
                />
                <div className='ftp-button-bar'>
                  <ButtonLink onClick={onClose}>{t('Cancelar')}</ButtonLink>
                  <div><ButtonSpinner type='submit' showSpinner={loading}>{t('Enviar comentario')}</ButtonSpinner></div>
                </div>
              </div>
            </div>
          </form>
        )}/>
    </Modal>
  )
}
