import {Modal} from "../../_common/components/modal.tsx";
import React, {Component, h} from "preact";
import {t} from "../../_i18n";
import "./ModalPendingInvite.scss";
import {connect} from "react-redux";

export function ModalPendingInvite({id, lang}){

    return (
        <Modal id={id} title={t('Condiciones promoción')} className="md-no-fullscreen" withHeaderTitle>
            <div id="bases-promocion" className="sm-p-x2-left sm-p-x2-right p-right p-left in-modal-scroll">
                <span dangerouslySetInnerHTML={{__html: t('INVITE_FRIEND_MODAL_PENDING_CONDITIONS')}} />
            </div>
        </Modal>
    )
}

ModalPendingInvite = connect(
    ({lang})=>({lang}),
    { })(ModalPendingInvite);


export default ({...rest}) => <ModalPendingInvite {...rest}/>
