import {h} from "preact";
import {Card, CF} from '../_common/layout'
import {t} from '../_i18n'
import Pingu from "../../assets/pingu-hi.png";

export default function DisabledPage() {
    return (
        <CF className="max-width expediente-cache">
            <Card className='d-flex flex-column flex-center-y p-x2-bottom m-top sm-no-m-top'>
                <img src={Pingu} alt='working'/>
                <div className='p-all' style='width: 75%'>
                    <h1 className='t-center'>{t('disabled_page_greeting')}</h1>
                    <p className='t-center'>{t('disabled_page_text_1')}</p>
                    <p className='t-center'>{t('disabled_page_text_2')}</p>
                    <p className='t-center'>{t('disabled_page_text_3')}</p>
                    <p className='t-center font-bold'>{t('disabled_page_farewell')}</p>
                </div>
            </Card>
        </CF>
    )
}
