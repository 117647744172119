import { t } from '../../../_i18n'

export function validationDocumentFCT (value) {
  if (!value) return t('Este campo es obligatorio')
  if (value.error_msg) return value.error_msg
}

export function validationDocumentFCTOptional (value) {
  if (value?.error_msg) return value.error_msg
}
