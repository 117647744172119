import {createReducer} from "../../../_common/common";
import {SET_CERTIFICATE_TYPES} from "../actions/constants";

export const certificates = createReducer(null, {
    [SET_CERTIFICATE_TYPES]: (state, payload) => {
        return payload
    }
});


