import React, {Component, Fragment, h} from "preact";
import SideBarModal from "../../_common/components/sideBarModal/sideBarModal";
import {useEffect, useMemo, useState} from "preact/hooks";
import RecursiveModules from "../components/recursiveModules";
import {useLocation} from "react-router";
import ModuleModalDetail from "./moduleModalDetail";
import {connect} from "react-redux";
import {
    fetchFormativeActionsChildren, fetchSubjectOffers, setReviewParams
} from "../../_rx/expediente/actions";
import "./moduleModal-styles.scss"
import {prepareFetch} from "../components/expedientFunc";
import {EDUCATION_LAW_LOE, EDUCATION_LAW_LOGSE} from "../expedientConstants";
import Loading from "../../_common/components/loading/Loading";
import {t} from "../../_i18n";
import {isIphone} from "../../utils";
import AlertBox from "../../_common/components/AlertBox/AlertBox";



function ModuleModal({module, studentId, visible, matchParams, historyRoute, educationLaw, onClick, params, formativeZone,...props}){
    const location = useLocation()
    const arrayParams = matchParams ? matchParams.split('&') : null
    const [modulesToShow, setModulesToShow] = useState(null)
    const ArrowBackAvailable = useMemo(() => !isIphone(), []) && (arrayParams?.length > 1 || params.specific)

    useEffect( async () => {
        if (arrayParams) {
            if(educationLaw === EDUCATION_LAW_LOE) {
                if (module){
                    const paramsReview = {enroll_id: module.enroll_id, exam_convocation_id: module.last_exam_convocation_id}
                    props.setReviewParams(paramsReview)
                }
            }
        }
    }, [params])

    useEffect( async () => {
        if (arrayParams){
            setModulesToShow(null)
            const dataToSend = prepareFetch(arrayParams);
            const data = (dataToSend.hasChild)
                ? await props.fetchFormativeActionsChildren(studentId, dataToSend.id)
                : await props.fetchSubjectOffers(studentId, dataToSend.id, Number(module.hidden_grade))
            setModulesToShow(data)
        }

    }, [matchParams])

    const handlePickModule = (moduleChoosed) => {
        if (moduleChoosed.has_children === false){
            historyRoute.push(location.pathname+'&sb-'+moduleChoosed.id)
        } else if (moduleChoosed.has_children === true) {
            historyRoute.push(location.pathname+'&ch-'+moduleChoosed.id)
        } else {
            historyRoute.push(location.pathname+'/dt-'+moduleChoosed.id)
        }
    }

    const handleArrowBackClick = () => {
        historyRoute.goBack();

        if(arrayParams.length === 1)
            handleOnClick()
    }

    const handleOnClick = () => {
        setModulesToShow(null)
        onClick()
    }

    const handleApplyReview = () => {
        const ids = historyRoute.location.pathname.split('/')[2]
        const newUrl = `/expediente/${ids}/review`;
        historyRoute.push(newUrl)
    }

    return (
      <SideBarModal visible={visible} onClose={handleOnClick} >
          <div className='module-modal__sidebar-header'>
              {ArrowBackAvailable &&
                <div className='module-modal__sidebar-header__icon' onClick={handleArrowBackClick}>
                    <i className="fal fa-arrow-left" />
                </div>}
              <div className='module-modal__sidebar-header__icon' onClick={handleOnClick}>
                  <i className="fal fa-times "/>
              </div>
          </div>
          {(module  && modulesToShow && arrayParams) ?
            <div className='m-l m-top m-r'>
                {
                    (!params.specific)
                      ? <RecursiveModules
                            title={modulesToShow.parent.title}
                            modules={modulesToShow.children}
                            module={module}
                            subModule={modulesToShow.parent.abbr}
                            moduleParent={modulesToShow.parent}
                            history={historyRoute}
                            onClick={handlePickModule}
                            handleApplyReview={handleApplyReview}
                            formativeZone={formativeZone}
                        />
                    : <ModuleModalDetail
                            params={params} modulePicked
                            handleSubmit={handleOnClick}
                            modulesToShow={modulesToShow}
                            parentModule={modulesToShow.parent}
                            formativeZone={formativeZone}
                      />
                }
            </div> : <div className='m-top'><Loading label={`${t('Cargando')}. . . `} /></div>
          }
        </SideBarModal>

    )
}

ModuleModal = connect(
    ({auth: {loggedUser}, expedient: {educationLaw}}) => ({educationLaw, studentId: loggedUser.id}),
    {fetchFormativeActionsChildren, fetchSubjectOffers, setReviewParams})(ModuleModal);

let ModuleModalContainer = ({...props}) => <ModuleModal {...props}/>


export default ModuleModalContainer;
