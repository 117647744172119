import React, {Component, Fragment, h} from "preact";
import {Card, CF, RE} from "../../_common/layout";
import {connect} from "react-redux";
import {fetchCandidate, saveLinks} from "../../_rx/jobs/actions";
import {AppPage} from "../../_common/components/app-page";
import {t} from "../../_i18n";
import {FormField} from "../../_common/forms/components/components";
import {Form as FinalForm} from "react-final-form";
import {Link} from "react-router-dom";
import {candidate} from "../../_rx/jobs/reducers";



const validateUrl = (url)=>{
    if(!url) return; // es opcional
    const pattern = "(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})?"
    const re = url.match(`^${pattern}$`)
    return (!re); // las validaciones de FinalForm han de devolver "truthy" cuando hay error
}


let ProfileView = class extends Component {
    async componentDidMount() {
        await this.props.fetchCandidate();
        const profile = this.props.candidate.letters.find((letter)=> letter.id == this.props.match.params.id);
        this.setState({profile});
    }

    async onSubmit(data){
        await this.props.saveLinks(data).catch(err=> ({'error': true}));
        history.back();
    }

    render({candidate, history}) {
        return <CF className="max-width-980">
            <nav className="breadcrumb-wrapper p-x2-bottom">
                <Link to='/jobs/candidate' className="back breadcrumb-item">← {t('Volver')}</Link>
            </nav>

            <FinalForm onSubmit={data=>this.onSubmit(data.links)} initialValues={candidate} render = {
                ({handleSubmit,  submitting, errors, submitSucceeded})=>
                    <form className="form profileForm" action="" onSubmit={handleSubmit} noValidate>
                        <Card className="p-bottom">
                            <h1 className="m-bottom">{t('Añadir perfiles profesionales')}</h1>
                            <RE>
                                <FormField name={"links[0].url"} type="url" validate={validateUrl} label={'Tu perfil en InfoJobs'} />
                            </RE>
                            <RE>
                                <FormField name={"links[1].url"} type="url" validate={validateUrl} label={'Tu perfil en LinkedIn'} />
                            </RE>
                        </Card>
                        <div className="d-none sm-d-flex">
                            <button disabled={submitting || this.state.loading} className="btn btn-primary m-top m-bottom" type='submit'>{t('Guardar')}</button>
                            <button className="btn btn-link m-top m-bottom" type='button' onClick={()=>history.push('/jobs/candidate')}>{t('Cancelar')}</button>
                        </div>
                        <div className="d-flex sm-d-none">
                            <button disabled={submitting || this.state.loading} className="btn btn-primary m-top m-bottom full-width" type='submit'>{t('Guardar')}</button>
                        </div>
                        <div className="d-flex sm-d-none">
                            <button className="btn btn-link m-top m-bottom full-width" type='button' onClick={()=>history.push('/jobs/candidate')}>{t('Cancelar')}</button>
                        </div>

                    </form>
            }/>

        </CF>
    }

}

ProfileView = connect(
    ({candidate, links})=>({candidate, links}), {fetchCandidate, saveLinks})(ProfileView);

const ProfilePage = ({history, match}) =>
    <AppPage
        name="profile"
        subtitle=""
        className="max-width">
        <ProfileView match={match} history={history}/>
    </AppPage>
;

export default ProfilePage;
