import {
  ARRAY_STATES_COMPANY_ASSIGNMENT,
  ARRAY_STATES_CONVENTION,
  ARRAY_STATES_DOCUMENTATION,
  ARRAY_STATES_EXEMPTION_100,
  ARRAY_STATES_FCT_END,
  ARRAY_STATES_FCT_STARTED,
  ARRAY_STATES_REQUEST_DEFERRAL_MAD,
  STATE_COMPANY_ASSIGNMENT,
  STATE_CONVENTION,
  STATE_DOCUMENTATION,
  STATE_EXEMPTION_100,
  STATE_FCT_END,
  STATE_FCT_STARTED, STATE_REQUEST_DEFERRAL_MAD
} from './constants'


export function getState ( stateNumber ) {
  if (ARRAY_STATES_DOCUMENTATION.includes(stateNumber)) return STATE_DOCUMENTATION
  if (ARRAY_STATES_COMPANY_ASSIGNMENT.includes(stateNumber)) return STATE_COMPANY_ASSIGNMENT
  if (ARRAY_STATES_CONVENTION.includes(stateNumber)) return STATE_CONVENTION
  if (ARRAY_STATES_FCT_STARTED.includes(stateNumber)) return STATE_FCT_STARTED
  if (ARRAY_STATES_REQUEST_DEFERRAL_MAD.includes(stateNumber)) return STATE_REQUEST_DEFERRAL_MAD
  if (ARRAY_STATES_EXEMPTION_100.includes(stateNumber)) return STATE_EXEMPTION_100
  if (ARRAY_STATES_FCT_END.includes(stateNumber)) return STATE_FCT_END
}
