import {createDefer, rndStr} from "../_common/common.ts";

const queue=[];
let t = null;



const waits = {};

export default class AppDispatch{

    async dispatchToApp(action, payload, wait){
        if(wait){
            wait = rndStr();
            waits[wait] = createDefer();
        }
        const d = `app-dispatch://${encodeURIComponent(action)}/${encodeURIComponent(payload)}/${wait||''}`;

        if(window.ReactNativeWebView && window.ReactNativeWebView.postMessage)
            window.ReactNativeWebView.postMessage(d);
        else
            console.log('window.ReactNativeWebView.postMessage', d)

        if(wait){
            return await waits[wait].promise;
        }
    }

    setDispatchFromAppCallback(fn){
        window.dispatchFromApp = (payload)=>{

            const action = payload.substring(0, payload.indexOf(':'));
            payload = payload.substring(payload.indexOf(':')+1);

            if(action == 'wait') {
                const waitcode = payload.substring(0, payload.indexOf(':'));
                if(!waitcode) return;
                payload = payload.substring(payload.indexOf(':')+1);
                const defer = waits[waitcode];
                delete waits[waitcode];
                defer.resolve(JSON.parse(payload));
            }
            else
                fn(action, payload);
        };
    }

}
