import React, {Fragment, h} from "preact";
import {t} from '../../../_i18n';
import {connect} from "react-redux";
import CardUpcomingExamView from "../components/CardUpcomingExamView";
import AlertBox from "../../../_common/components/AlertBox/AlertBox";
import {isSpanishUser} from "../../../_common/utils/getLang";

function UpcomingExams({examsStatus, studentMeta}) {
    const showCurrentEvents = examsStatus?.current?.formative_actions?.length > 0 && !examsStatus?.current?.ended_enroll_period
    const showNextEvents = examsStatus?.next?.formative_actions?.length > 0 && !examsStatus?.next?.ended_enroll_period
    const no_pending_inscriptions_string = t('no_pending_inscriptions')
    const exams_limit_string = t('exams_date_limit_2').replace('{EXAM_DATE_LIMIT}', examsStatus?.current?.end_date_join)

    // https://soporte.ilerna.com/issues/59125
    const is_french = studentMeta.shop_id == 44
    const is_extraordinary = examsStatus?.current?.formative_actions.some(fa => !fa.has_exam_credit);

    const current_markdown = (showCurrentEvents &&
        <div>
            <p className='m-top-x2 m-bottom font-20 font-color-486268'>{t('Exámenes de asignaturas que estás cursando')}:</p>
            { isSpanishUser(studentMeta.shop_id) &&
                <AlertBox type='info' className='m-top sm-no-m-top'>
                    <span dangerouslySetInnerHTML={{__html: exams_limit_string}}/>
                </AlertBox>
            }
            {examsStatus?.current?.formative_actions.map(event => <CardUpcomingExamView event={event}
                                                                                        isNextTerm={false}/>)}
        </div>
    )

    const next_markdown = (showNextEvents &&
        <div>
            <p className='m-top-x2 m-bottom font-20 font-color-486268'>{t('Exámenes de asignaturas del próximo periodo')}:</p>
            {examsStatus?.next?.formative_actions.map(event => <CardUpcomingExamView event={event} isNextTerm={true}/>)}
        </div>
    )

    return <Fragment>
        {!this.props.provisional_is_next &&
            <Fragment>
                {(showCurrentEvents || showNextEvents)
                    ?
                    <div className='m-bottom font-heavy font-30 font-color-486268'>{t('Inscripciones pendientes')}</div>
                    :
                    <AlertBox type='info' className='m-top sm-no-m-top'>
                        {no_pending_inscriptions_string}
                    </AlertBox>}
            </Fragment>
        }
        {!this.props.provisional_is_next && current_markdown}
        {this.props.provisional_is_next && next_markdown}
    </Fragment>
}

UpcomingExams = connect(
    ({status, enrollmentExams}) => ({
        examsStatus: enrollmentExams.examsStatus,
        studentMeta: status.student_meta,
    }),
    {})(UpcomingExams);


export default ({...rest}) => {
    return (<UpcomingExams {...rest} />);
}
