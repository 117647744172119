import React, {Fragment, h} from "preact";
import {connect} from "react-redux";
import TextBox from "../../../../_common/components/textBox/textBox";
import Dropdown from "../../../../_common/components/dropdown";
import {t} from '../../../../_i18n';
import './historicalExamsCard.scss'
import {DateTime} from "luxon";

function HistoricalExamsCard({event, isNextExam, handleCancel, handleModify, handleViewPACs, loading, examsStatus}) {
    const status = isNextExam ? examsStatus.next : examsStatus.current
    const dateLimit = DateTime.fromFormat(event.endDateJoin, 'yyyy-MM-dd hh:mm:ss')
    const currentTime = DateTime.now();

    const showPacRecomendation = (event.has_mandatory_classes === false) ||
        (event.has_mandatory_classes === true && event.has_attended_classes === true);

    return (
        <div className='card m-top'>
            <div className='p-x2-all historical-exams-card__container'>
                {loading && <div className='historical-exams-card__loading'/>}
                <div className='t-upper font-color-879CA1 font-14'>{event.formative_action_code}</div>
                <div className='font-18 font-medium'>{event.formative_action_title}</div>
                <div className='m-top d-flex flex-column sm-no-flex-column'>
                    <TextBox className='full-width sm-auto-width'>
                        <div className='p-all-half d-flex flex-baseline-y'>
                            <i className="fal fa-calendar-alt m-l-half font-color-879CA1"/>
                            <div className='m-l-half'>{event.dateSelected.formatted_day}</div>
                        </div>
                    </TextBox>
                    <TextBox className='full-width sm-auto-width sm-m-l-half m-top-half sm-no-m-top'>
                        <div className='p-all-half d-flex flex-baseline-y'>
                            <i className="fal fa-clock  m-l-half font-color-879CA1"/>
                            <div
                                className='m-l-half'>{
                                event.dateSelected.start_date_hour} - {event.dateSelected.end_date_hour}
                            </div>
                        </div>
                    </TextBox>
                </div>
                <div className='m-x2-top font-16 font-color-486268'>
                    {!isNextExam || event.show_event_address
                        ? <Fragment>
                            <div className='d-block sm-d-none'>
                                <div
                                    className='font-medium'>{event.buildingSelected.building_name} {event.buildingSelected.city}</div>
                            </div>
                            <div className='sm-d-block d-none'>
                                <div
                                    className='font-medium'>{event.buildingSelected.building_name} {event.buildingSelected.city}</div>
                            </div>
                            <div className='m-top-half'>{event.buildingSelected.address_name}</div>
                        </Fragment>
                        : <div className='m-top-half'>
                            <div>{event.buildingSelected.state}</div>
                            <div>{t('Dirección disponible próximamente')}</div>
                        </div>
                    }
                </div>
                {isNextExam && event.show_pac_modal &&
                    <div className='full-width d-flex flex-left flex-baseline-y'>
                        {/*{ showPacRecomendation ?*/}
                            <p className='m-top recomendation-link' onClick={() => handleViewPACs(event)}>{t('consulta_recomendaciones')}</p>
                        {/*    :*/}
                        {/*    <Fragment>*/}
                        {/*        <i className="far fa-exclamation-circle m-r-half" />*/}
                        {/*        <p className='font-16'>{t('faltan_clases_presenciales')}</p>*/}
                        {/*    </Fragment>*/}
                        {/*}*/}
                    </div>
                }
                {/*{!isNextExam && event.attendance === false &&*/}
                {/*    <div className='full-width d-flex flex-left flex-baseline-y'>*/}
                {/*        <i className="far fa-exclamation-circle m-r-half" />*/}
                {/*        <p className='font-16'>{t('no_asistencia_examen')}</p>*/}
                {/*    </div>*/}
                {/*}*/}
                <div className='historical-exams-card__menu'>
                    {isNextExam && (currentTime < dateLimit) &&
                        <Dropdown icon='fas fa-ellipsis-v font-color-486268' position='left' justIcon>
                            <div className='dropdown-item'
                                 onClick={() => handleModify(event)}>{t('Modificar inscripción')}</div>
                            <div className='dropdown-item' onClick={() => handleCancel(event)}>{t('Cancelar')}</div>
                        </Dropdown>
                    }
                </div>
            </div>
        </div>
    )
}

HistoricalExamsCard = connect(
    ({enrollmentExams}) => ({
        examsStatus: enrollmentExams.examsStatus
    }),
    {})(HistoricalExamsCard);


export default ({...rest}) => <HistoricalExamsCard {...rest}/>

