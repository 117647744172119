import { h } from "preact"
import './timeline-styles.scss'
import { DateTime } from 'luxon'
import { downloadFileFtp } from '../../../_rx/fct/actions'
import { t } from '../../../_i18n'
import { useSelector } from 'react-redux'
import { useEffect} from "preact/hooks";
import { Route, Switch, useLocation } from "react-router-dom";

export default function Message ({ id, type, title, date, body, attached }) {
  const { lang } = useSelector(state => state);
  const { pathname, hash, key } = useLocation();


  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView( {behavior: "smooth"} );
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <a id={id} name={id}>
      <div className='fct-message'>
        <div className='icon-container'>
          <div className='icon'>{type === 'event' ? <i className="far fa-check" /> : <i className="far fa-comment" />}</div>
          <div className='line'><div /></div>
        </div>
        <div className='content'>
          <div className="content__title bold">{title}</div>
          <div className="content__date">{DateTime.fromISO(date.split(' ')[0]).setLocale(lang).toFormat(t("fct_date_timeline"))}</div>
          {body && <div className="content__body" dangerouslySetInnerHTML={{__html: body}} />}
          {attached.length !== 0 && (
            <div className='content__attached_docs'>
              {attached.map(file => <div onClick={() => downloadFileFtp(file.id, file.file_name)}>{file.file_name}</div>)}
            </div>
          )}
        </div>
      </div>
    </a>
  )
}
