import React, {h} from "preact";
import {connect} from 'react-redux';
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import {AppPage} from "../../../_common/components/app-page";
import {CF} from '../../../_common/layout';
import HeaderClasses from '../components/HeaderClasses';
import UpcomingClasses from './UpcomingClasses';
import Tabs from '../components/Tabs';
import {useEffect, useState} from 'preact/hooks';
import HistoricalClasses from './HistoricalClasses';
import {getStatusClasses, setClassSelected} from '../../../_rx/enrollment/classes/actions';
import Loading from '../../../_common/components/loading/Loading';
import Sidebar from './sidebar';
import ModalCancel from '../components/ModalCancel';
import ModalGrades from "../components/ModalGrades";
import DisabledPage from "../../../disabled-page/disabledPage";

function Classes({studentId, classesStatus, getStatusClasses, setClassSelected}) {
    const [historyEventsStatus, setHistoryEventsStatus] = useState('next')
    const [sideBar, setSideBar] = useState(false)
    const history = useHistory()
    const {action} = useParams()

    useEffect(async () => {
        history.push('/clases')
        await getStatusClasses(studentId)
    }, [])

    useEffect(() => {
        (action) ? setSideBar(true) : setSideBar(false)
    }, [action])

    const handleCloseModal = () => {
        history.push('/clases')
        setSideBar(false)
        setClassSelected(null)
    }

    if (!classesStatus)
        return (
            <CF className="max-width-980">
                <Loading label='Cargando...'/>
            </CF>
        )

    return <CF className="max-width-980">
        <Sidebar visible={sideBar} handleCloseModal={handleCloseModal} historyEventsStatus={historyEventsStatus}/>
        <ModalCancel/>
        <ModalGrades/>
        <HeaderClasses/>
        <UpcomingClasses provisional_is_next={false}/>
        <div className='m-x2-top'>
            <Tabs onClick={setHistoryEventsStatus}/>
            <HistoricalClasses status={historyEventsStatus}/>
        </div>
        <div className='m-top-x4'>
            <UpcomingClasses provisional_is_next={true}/>
        </div>
    </CF>
}

Classes = connect(
    ({auth, enrollmentClasses}) => ({
        studentId: auth.loggedUser.id,
        classesStatus: enrollmentClasses.status
    }),
    {getStatusClasses, setClassSelected})(Classes);


export default ({...rest}) => {
    const disabled = false;
    return (
        <AppPage className="max-width">
            {disabled && <DisabledPage/>}
            {!disabled && <Classes {...rest}/>}
        </AppPage>
    );
}
