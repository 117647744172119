import React, { Fragment, Component, h} from "preact";
import {ModalComponent} from "./Modal";

export interface ModalWrapperProps {
    closeModal: () => void,
    children: JSX.Element | string,
    overlayClass: string,
    bodyClass: string,
    visible: boolean
}

export default ({ visible, ...props }: ModalWrapperProps) => visible && <ModalComponent {...props}/>

