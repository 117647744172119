/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import NoticeCount from '../model/NoticeCount';

/**
* Presta service.
* @module api/PrestaApi
* @version 1.0
*/
export default class PrestaApi {

    /**
    * Constructs a new PrestaApi. 
    * @alias module:api/PrestaApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Return total notices to user and unread (presta input)
     * @param {String} email email of the student
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/NoticeCount} and HTTP response
     */
    appHttpControllersUserControllerPrestaCountNoticesWithHttpInfo(email) {
      let postBody = null;
      // verify the required parameter 'email' is set
      if (email === undefined || email === null) {
        throw new Error("Missing the required parameter 'email' when calling appHttpControllersUserControllerPrestaCountNotices");
      }

      let pathParams = {
        'email': email
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = NoticeCount;
      return this.apiClient.callApi(
        '/api/v1/presta/countNotices/{email}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Return total notices to user and unread (presta input)
     * @param {String} email email of the student
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/NoticeCount}
     */
    appHttpControllersUserControllerPrestaCountNotices(email) {
      return this.appHttpControllersUserControllerPrestaCountNoticesWithHttpInfo(email)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
