import {Fragment, h} from "preact";
import { C, CF, F, RE } from "../_common/layout";
import './home-page.scss';
import {t} from '../_i18n';
import { useSelector } from "react-redux";
import Comunicados from "../comunicados/comunicados";
import HelpSection from './HelpSection/HelpSection'
import { isSpanishUser } from '../_common/utils/getLang'
import NotEnrolled from './pages/NotEnrolled/NotEnrolled'
import Dashboard from './pages/Dashboard/Dashboard'

export function HomePageView (){
  const student_meta = useSelector(state => state.status.student_meta)

    return (
     <F className="max-width-980 home-page">
       {student_meta.has_coursing_enroll ?  <Dashboard /> : <NotEnrolled />}
             {/*{student_meta.has_coursing_enroll ?
                     <C className='dashboard'>
                       <Comunicados />
                       {isSpanishUser(student_meta.shop_id) && <HelpSection />}
                     </C>
                 : <NotEnrolled />
             }*/}
       </F>
    )
}
