import React, { Fragment, h } from "preact";
import {rndStr} from "../../../common";
import {useEffect, useState} from "preact/hooks";
import './Checkbox.scss'

export const Checkbox = ({value = false , label, onChange, labelIsClicked, error , ...props}) => {
    const id = rndStr();
    const [valueVar, setValueVar] = useState(value)

    useEffect(() => setValueVar(value), [value])

    const handleChange = (value) => {
        setValueVar(value)
        onChange(value)
    }

    return (
        <div>
            <div className='checkbox-container'>
                <div className='checkbox-content'>
                    <input className="checkbox-component"
                           type="checkbox"
                           id={id}
                           checked={valueVar} {...props}
                           onChange={(e) => handleChange(e.target.checked)}/>
                    <label htmlFor={id} />
                </div>
                <span className={`checkbox- m-l-half ${labelIsClicked && 'cursor-pointer'}`} onClick={() => labelIsClicked && handleChange(!valueVar)}>{label}</span>
            </div>
            { error &&
            <div className='checkbox-error'>
                {error}
            </div>
            }
        </div>
    )
}

export const CheckboxFinalForm = ({input, meta, fnToShow = () => {}, ...props}) => {
    return (
        <Checkbox {...props} onChange={input.onChange} error={fnToShow(meta) && meta.error}/>
    )
}