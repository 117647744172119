import { Fragment, h } from "preact";
import AlertBox from '../../../_common/components/AlertBox/AlertBox'
import { ButtonPrimary } from '../../../_common/components/buttons/buttons'
import {useLocation, useParams} from 'react-router'
import { Link } from 'react-router-dom'
import { t } from '../../../_i18n'
import useFctMeta from "../../hooks/useFctMeta";


export default function AlertContributeCompany ({ className }) {
  const { pathname } = useLocation()
  const { id } = useParams();
  const meta = useFctMeta(id);
  const formative_zone = meta.formative_zone;

  return (
    <Fragment>
      <AlertBox type='dashed' className={className}>
        <p className='font-bold'>{t('¿Has logrado encontrar por tu cuenta una empresa para llevar a cabo tus prácticas?')}</p>
        { formative_zone === 'MAD' && <p dangerouslySetInnerHTML={{ __html: t('alert_MAD') }} /> }
        { formative_zone !== 'MAD' && <p dangerouslySetInnerHTML={{ __html: t('alert_contribute_company') }} /> }
        <Link to={`${pathname}/aportar-empresa`}><ButtonPrimary className='m-top'>{t('Aportar empresa')}</ButtonPrimary></Link>
      </AlertBox>
    </Fragment>
  )
}
