import {Fragment, h} from "preact";
import {replaceHash} from "../common";
import {E, FR, R} from "../layout";
import {t} from "../../_i18n";

export const Modal = ({children, title, className, id, footer, baseClassName='modal-window', modalClassName='', withHeaderTitle = false}:any)=>{
    modalClassName = modalClassName + (withHeaderTitle && ' no-padding');
    return <Fragment>
        <div id={id} className={baseClassName + " " + className}>
            <div className={`modal-content ${modalClassName}`}>
                {/* Modal para Jobs */}
                {withHeaderTitle ?
                    <Fragment>
                        <FR className={'modal-title-padding row-2-el'}>
                            <E>
                                {title ? <p className="t-left font-18 no-margin">{title}</p> : ''}
                            </E>
                            <E className={'t-right'}>
                                <a onClick={()=>{history.back();}}  title="Close" className="modal-close-jobs modal-link"> <i className="fal fa-times"></i> </a>
                            </E>
                        </FR>
                        <hr className="separator-xxs no-margin-top"/>
                    </Fragment>
                    :
                    /* Modal para Mi-cuenta */
                    <Fragment>
                        <a onClick={()=>history.back()} title="Close" className="modal-close modal-link"> &times; </a>
                        {title ? <h2 className="t-left p-top-half p-left">{title}</h2> : ''}
                    </Fragment>
                }
                {children}
                {footer ? <div className="modal-footer"> {footer} </div> : ''}
            </div>
        </div>
        <a className="modal-backdrop modal-link" onClick={()=>history.back()}/>
    </Fragment>
}


export const ModalLink = ({children, id, onClick, ...rest}:any)=> <a className="modal-link" onClick={()=>{replaceHash(id); onClick && onClick();}} {...rest}>{children}</a>;

export const SimpleModalLink = ({children, id, onClick, className, ...rest}:any)=> <div className={className} onClick={()=>{replaceHash(id); onClick && onClick();}} {...rest}>{children}</div>;
