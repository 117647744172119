import {h, Fragment} from "preact";
import {Modal} from "../../_common/components/modal.tsx";
import {Card} from "../../_common/layout";

export const ModalExenciones = ({id})=><Fragment>
    <Modal id={id} title={'Exenciones al pago de tasas'} className="md-no-fullscreen ">
        <div>
            <p>Están exentos de pago, total o parcialmente, los alumnos que cumplan una o varias de las siguientes condiciones:</p>
            <ul>
                <li><strong>Familia Monoparental</strong>: envíanos el carné de familia monoparental.</li><br/>
                <li><strong>Familia Numerosa General</strong>: envíanos el carné de familia numerosa con categoría general.</li><br/>
                <li><strong>Familia Numerosa Especial</strong>: envíanos el carné de familia numerosa con categoría especial.</li><br/>
                <li><strong>Grado discapacidad igual o superior al 33%</strong>: envíanos la resolución del grado de discapacidad.</li><br/>
                <li><strong>Víctimas de actos terroristas (cónyuge e hijos)</strong>: envíanos la acreditación de ser víctima de actos terroristas.</li><br/>
            </ul>
            <p className='bold m-top'>La exención de pago, total o parcial, no exime del pago de los gastos asociados a la gestión.</p>
            <p>Una vez nos hayas hecho llegar la documentación que acredite dicha condición y la hayamos verificado, te enviaremos un enlace de pago del título con los descuentos aplicados desde el departamento de Secretaría.</p>
            <Card class='background-info p-bottom'><strong>¡IMPORTANTE!</strong> No podremos iniciar los trámites hasta que no dispongamos de toda tu documentación en regla.</Card>
        </div>
    </Modal>
</Fragment>;
