/**
 * Student Account
 * Student Account Api - Validation flow
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dmartinez@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Country from './Country';
import Document from './Document';
import DocumentType from './DocumentType';
import MessageType from './MessageType';

/**
 * The StudentIdentityDocument model module.
 * @module model/StudentIdentityDocument
 * @version 1.0.0
 */
class StudentIdentityDocument {
    /**
     * Constructs a new <code>StudentIdentityDocument</code>.
     * @alias module:model/StudentIdentityDocument
     * @param studentId {Number} 
     * @param reference {String} 
     * @param documentType {module:model/DocumentType} 
     * @param files {Array.<module:model/Document>} 
     */
    constructor(studentId, reference, documentType, files) { 
        
        StudentIdentityDocument.initialize(this, studentId, reference, documentType, files);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, studentId, reference, documentType, files) { 
        obj['student_id'] = studentId;
        obj['reference'] = reference;
        obj['document_type'] = documentType;
        obj['files'] = files;
    }

    /**
     * Constructs a <code>StudentIdentityDocument</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StudentIdentityDocument} obj Optional instance to populate.
     * @return {module:model/StudentIdentityDocument} The populated <code>StudentIdentityDocument</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StudentIdentityDocument();

            if (data.hasOwnProperty('student_id')) {
                obj['student_id'] = ApiClient.convertToType(data['student_id'], 'Number');
            }
            if (data.hasOwnProperty('block_id')) {
                obj['block_id'] = ApiClient.convertToType(data['block_id'], 'Number');
            }
            if (data.hasOwnProperty('reference')) {
                obj['reference'] = ApiClient.convertToType(data['reference'], 'String');
            }
            if (data.hasOwnProperty('country')) {
                obj['country'] = Country.constructFromObject(data['country']);
            }
            if (data.hasOwnProperty('expires_at')) {
                obj['expires_at'] = ApiClient.convertToType(data['expires_at'], 'Date');
            }
            if (data.hasOwnProperty('document_type')) {
                obj['document_type'] = DocumentType.constructFromObject(data['document_type']);
            }
            if (data.hasOwnProperty('expired')) {
                obj['expired'] = ApiClient.convertToType(data['expired'], 'Boolean');
            }
            if (data.hasOwnProperty('files')) {
                obj['files'] = ApiClient.convertToType(data['files'], [Document]);
            }
            if (data.hasOwnProperty('messages')) {
                obj['messages'] = ApiClient.convertToType(data['messages'], [MessageType]);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} student_id
 */
StudentIdentityDocument.prototype['student_id'] = undefined;

/**
 * View block identifier
 * @member {Number} block_id
 */
StudentIdentityDocument.prototype['block_id'] = undefined;

/**
 * @member {String} reference
 */
StudentIdentityDocument.prototype['reference'] = undefined;

/**
 * @member {module:model/Country} country
 */
StudentIdentityDocument.prototype['country'] = undefined;

/**
 * @member {Date} expires_at
 */
StudentIdentityDocument.prototype['expires_at'] = undefined;

/**
 * @member {module:model/DocumentType} document_type
 */
StudentIdentityDocument.prototype['document_type'] = undefined;

/**
 * @member {Boolean} expired
 */
StudentIdentityDocument.prototype['expired'] = undefined;

/**
 * @member {Array.<module:model/Document>} files
 */
StudentIdentityDocument.prototype['files'] = undefined;

/**
 * Message types array with related messages
 * @member {Array.<module:model/MessageType>} messages
 */
StudentIdentityDocument.prototype['messages'] = undefined;






export default StudentIdentityDocument;

