import React, {Component, h } from "preact";
import {AppPage} from "../../_common/components/app-page";
import { Form as FinalForm, Field } from 'react-final-form'
import {FormFieldSelect, SubmitButtonRow} from "../../_common/forms/components/components";
import {Card, CF, E, FR} from "../../_common/layout";
import { Redirect} from "react-router";
import {connect} from "react-redux";
import {fetchFixture, fetchStudent, saveStudent} from "../../_rx/student/actions";
import {t} from '../../_i18n';
import {ButtonLink, ButtonPrimary} from "../../_common/components/buttons/buttons";
import getLangByShopId from "../../_common/utils/getLang";

let NeeView = class extends Component {

    componentDidMount() {
        const {auth} = this.props;
        if(!this.props.fixtures)
            this.props.fetchFixture(getLangByShopId(this.props.status.student_meta.shop_id));

        this.props.fetchStudent(auth.loggedUser.id);
    }

    onSubmit(data){
        return this.props.saveStudent(data).catch(err=> ({'error': true}));
    }

    handleCancel(e){
        e.preventDefault()
        this.props.history.goBack()
    }

    render({fixtures, student, history}) {
        return fixtures && student && // hasta que no haya fixtures no se muestra el form
                <CF className="max-width m-top-header-xs-sm">
                    <Card className="p-bottom">
                        <h1>{t('Necesidades Educativas')}</h1>
                        <FinalForm onSubmit={data=>this.onSubmit(data)} initialValues={student} render = {
                        ({handleSubmit,  submitting, errors, submitSucceeded})=>
                            <form className='form' action="" onSubmit={handleSubmit} noValidate>
                                {submitSucceeded && history.goBack()}
                                <FR><E className='m-top sm-no-m-top col-100 sm-col-60'>
                                    <FormFieldSelect
                                        name="data.nee.id"
                                        label={t('Selecciona nee')} required={true}
                                        options={fixtures.nee}
                                    />
                                </E></FR>
                                <FR className='m-top'>
                                    <E className='full-width sm-no-full-width'>
                                        <ButtonPrimary className="full-width sm-no-full-width" type='submit'>{t('Guardar')}</ButtonPrimary>
                                    </E>
                                    <E>
                                        <ButtonLink className="full-width sm-no-full-width sm-d-block d-none" onClick={(e) => this.handleCancel(e)}>{t('Cancelar')}</ButtonLink>
                                    </E>
                                </FR>
                            </form>
                        }/>
                    </Card>
                </CF>;
    }
};


NeeView = connect(
    ({auth, fixtures, student, status})=>({auth, fixtures, student, status}),
    {
        fetchFixture,
        fetchStudent,
        saveStudent
    })(NeeView);

const Nee = ({history}) =>
    <AppPage
        name="necesidades-educativas"
        className="max-width"
    >
        <NeeView history={history}/>
    </AppPage>
;

export default Nee;
