import React, { h } from "preact";


export const F = ({children, className, ...rest}:any)=> <div className={"container-fluid " + (className||'')} {...rest}>{children}</div>;

export const C = ({children, className}:any)=> <div className={"container " + (className||'')}>{children}</div>;

/**
 * Container Fluid
 * @param children
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
export const CF = ({children, className, ...rest}:any)=> <F className={className} {...rest}><C>{children}</C></F>;


/**
 * Row class flexRow
 * @param children
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
export const FR = ({children, className,...rest}:any)=> <div className={`flexrow ${className}`} {...rest} >{children}</div>;


/**
 * Row este FUERZA el rpw-1-el no es ideal, pero se mantiene por legacy
 * @param children
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
export const R = ({children, className,...rest}:any)=> <div className={`flexrow ${className || 'row-1-el'}`} {...rest} >{children}</div>;



/**
 * Element
 * @param children
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
export const E = ({children, className, ...rest}:any)=> <div className={`element ${className || ''}`} {...rest}>{children}</div>;
/**
 * Row 1 element
 * @param className
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const RE = ({className, children}:any)=> <R><E className={className}>{children}</E></R>;




/**
 * Container Fluid con flex row
 * @param children
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
export const CFR = ({children, className, ...rest}:any)=> <F {...rest}><C><FR className={className}>{children}</FR></C></F>;



export const Card = ({children, className, smOnly=true, ...rest}:any)=><div className={(smOnly ? "sm-card sm-p-x2-left sm-p-x2-right sm-p-top " : "card p-left p-right p-top sm-p-x2-left sm-p-x2-right sm-p-top p-bottom sm-p-bottom ") + className} {...rest}>{children}</div>

export const MobileBackgroundContainer = ({children, className = ''}: any) => <div className={`background-white sm-background-body border-gray-border-color border-1 sm-no-border full-height ${className}`}>{children}</div>