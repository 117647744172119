/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from './ApiClient';
import AcademicProgram from './model/AcademicProgram';
import AcademicSection from './model/AcademicSection';
import Attachment from './model/Attachment';
import AttributeType from './model/AttributeType';
import Family from './model/Family';
import FormativeAction from './model/FormativeAction';
import InlineObject from './model/InlineObject';
import InlineObject1 from './model/InlineObject1';
import InlineObject2 from './model/InlineObject2';
import InlineObject3 from './model/InlineObject3';
import InlineObject4 from './model/InlineObject4';
import InlineObject5 from './model/InlineObject5';
import InlineObject6 from './model/InlineObject6';
import InlineObject7 from './model/InlineObject7';
import InlineObject8 from './model/InlineObject8';
import InlineObject9 from './model/InlineObject9';
import InlineResponse200 from './model/InlineResponse200';
import InlineResponse2001 from './model/InlineResponse2001';
import InlineResponse2002 from './model/InlineResponse2002';
import InlineResponse2003 from './model/InlineResponse2003';
import InlineResponse2004 from './model/InlineResponse2004';
import InlineResponse2005 from './model/InlineResponse2005';
import InlineResponse2006 from './model/InlineResponse2006';
import InlineResponse2007 from './model/InlineResponse2007';
import InlineResponse404 from './model/InlineResponse404';
import Notice from './model/Notice';
import NoticeCount from './model/NoticeCount';
import ProfileSetChannel from './model/ProfileSetChannel';
import ProfileSetEntry from './model/ProfileSetEntry';
import ProfileSetType from './model/ProfileSetType';
import Province from './model/Province';
import Segment from './model/Segment';
import SegmentAttribute from './model/SegmentAttribute';
import Send from './model/Send';
import Shop from './model/Shop';
import Student from './model/Student';
import UserProfileSet from './model/UserProfileSet';
import UserResponse from './model/UserResponse';
import UserResponseAttachment from './model/UserResponseAttachment';
import AttachmentApi from './api/AttachmentApi';
import CanvasApi from './api/CanvasApi';
import NoticeApi from './api/NoticeApi';
import NoticeAttachmentsApi from './api/NoticeAttachmentsApi';
import NoticeSegmentsApi from './api/NoticeSegmentsApi';
import PrestaApi from './api/PrestaApi';
import ProfileSettingApi from './api/ProfileSettingApi';
import SISApi from './api/SISApi';
import SegmentApi from './api/SegmentApi';
import SendApi from './api/SendApi';
import UserApi from './api/UserApi';


/**
* JS API client generated by OpenAPI Generator.<br>
* The <code>index</code> module provides access to constructors for all the classes which comprise the public API.
* <p>
* An AMD (recommended!) or CommonJS application will generally do something equivalent to the following:
* <pre>
* var NoticeApi = require('index'); // See note below*.
* var xxxSvc = new NoticeApi.XxxApi(); // Allocate the API class we're going to use.
* var yyyModel = new NoticeApi.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* <em>*NOTE: For a top-level AMD script, use require(['index'], function(){...})
* and put the application logic within the callback function.</em>
* </p>
* <p>
* A non-AMD browser application (discouraged) might do something like this:
* <pre>
* var xxxSvc = new NoticeApi.XxxApi(); // Allocate the API class we're going to use.
* var yyy = new NoticeApi.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* </p>
* @module index
* @version 1.0
*/
export {
    /**
     * The ApiClient constructor.
     * @property {module:ApiClient}
     */
    ApiClient,

    /**
     * The AcademicProgram model constructor.
     * @property {module:model/AcademicProgram}
     */
    AcademicProgram,

    /**
     * The AcademicSection model constructor.
     * @property {module:model/AcademicSection}
     */
    AcademicSection,

    /**
     * The Attachment model constructor.
     * @property {module:model/Attachment}
     */
    Attachment,

    /**
     * The AttributeType model constructor.
     * @property {module:model/AttributeType}
     */
    AttributeType,

    /**
     * The Family model constructor.
     * @property {module:model/Family}
     */
    Family,

    /**
     * The FormativeAction model constructor.
     * @property {module:model/FormativeAction}
     */
    FormativeAction,

    /**
     * The InlineObject model constructor.
     * @property {module:model/InlineObject}
     */
    InlineObject,

    /**
     * The InlineObject1 model constructor.
     * @property {module:model/InlineObject1}
     */
    InlineObject1,

    /**
     * The InlineObject2 model constructor.
     * @property {module:model/InlineObject2}
     */
    InlineObject2,

    /**
     * The InlineObject3 model constructor.
     * @property {module:model/InlineObject3}
     */
    InlineObject3,

    /**
     * The InlineObject4 model constructor.
     * @property {module:model/InlineObject4}
     */
    InlineObject4,

    /**
     * The InlineObject5 model constructor.
     * @property {module:model/InlineObject5}
     */
    InlineObject5,

    /**
     * The InlineObject6 model constructor.
     * @property {module:model/InlineObject6}
     */
    InlineObject6,

    /**
     * The InlineObject7 model constructor.
     * @property {module:model/InlineObject7}
     */
    InlineObject7,

    /**
     * The InlineObject8 model constructor.
     * @property {module:model/InlineObject8}
     */
    InlineObject8,

    /**
     * The InlineObject9 model constructor.
     * @property {module:model/InlineObject9}
     */
    InlineObject9,

    /**
     * The InlineResponse200 model constructor.
     * @property {module:model/InlineResponse200}
     */
    InlineResponse200,

    /**
     * The InlineResponse2001 model constructor.
     * @property {module:model/InlineResponse2001}
     */
    InlineResponse2001,

    /**
     * The InlineResponse2002 model constructor.
     * @property {module:model/InlineResponse2002}
     */
    InlineResponse2002,

    /**
     * The InlineResponse2003 model constructor.
     * @property {module:model/InlineResponse2003}
     */
    InlineResponse2003,

    /**
     * The InlineResponse2004 model constructor.
     * @property {module:model/InlineResponse2004}
     */
    InlineResponse2004,

    /**
     * The InlineResponse2005 model constructor.
     * @property {module:model/InlineResponse2005}
     */
    InlineResponse2005,

    /**
     * The InlineResponse2006 model constructor.
     * @property {module:model/InlineResponse2006}
     */
    InlineResponse2006,

    /**
     * The InlineResponse2007 model constructor.
     * @property {module:model/InlineResponse2007}
     */
    InlineResponse2007,

    /**
     * The InlineResponse404 model constructor.
     * @property {module:model/InlineResponse404}
     */
    InlineResponse404,

    /**
     * The Notice model constructor.
     * @property {module:model/Notice}
     */
    Notice,

    /**
     * The NoticeCount model constructor.
     * @property {module:model/NoticeCount}
     */
    NoticeCount,

    /**
     * The ProfileSetChannel model constructor.
     * @property {module:model/ProfileSetChannel}
     */
    ProfileSetChannel,

    /**
     * The ProfileSetEntry model constructor.
     * @property {module:model/ProfileSetEntry}
     */
    ProfileSetEntry,

    /**
     * The ProfileSetType model constructor.
     * @property {module:model/ProfileSetType}
     */
    ProfileSetType,

    /**
     * The Province model constructor.
     * @property {module:model/Province}
     */
    Province,

    /**
     * The Segment model constructor.
     * @property {module:model/Segment}
     */
    Segment,

    /**
     * The SegmentAttribute model constructor.
     * @property {module:model/SegmentAttribute}
     */
    SegmentAttribute,

    /**
     * The Send model constructor.
     * @property {module:model/Send}
     */
    Send,

    /**
     * The Shop model constructor.
     * @property {module:model/Shop}
     */
    Shop,

    /**
     * The Student model constructor.
     * @property {module:model/Student}
     */
    Student,

    /**
     * The UserProfileSet model constructor.
     * @property {module:model/UserProfileSet}
     */
    UserProfileSet,

    /**
     * The UserResponse model constructor.
     * @property {module:model/UserResponse}
     */
    UserResponse,

    /**
     * The UserResponseAttachment model constructor.
     * @property {module:model/UserResponseAttachment}
     */
    UserResponseAttachment,

    /**
    * The AttachmentApi service constructor.
    * @property {module:api/AttachmentApi}
    */
    AttachmentApi,

    /**
    * The CanvasApi service constructor.
    * @property {module:api/CanvasApi}
    */
    CanvasApi,

    /**
    * The NoticeApi service constructor.
    * @property {module:api/NoticeApi}
    */
    NoticeApi,

    /**
    * The NoticeAttachmentsApi service constructor.
    * @property {module:api/NoticeAttachmentsApi}
    */
    NoticeAttachmentsApi,

    /**
    * The NoticeSegmentsApi service constructor.
    * @property {module:api/NoticeSegmentsApi}
    */
    NoticeSegmentsApi,

    /**
    * The PrestaApi service constructor.
    * @property {module:api/PrestaApi}
    */
    PrestaApi,

    /**
    * The ProfileSettingApi service constructor.
    * @property {module:api/ProfileSettingApi}
    */
    ProfileSettingApi,

    /**
    * The SISApi service constructor.
    * @property {module:api/SISApi}
    */
    SISApi,

    /**
    * The SegmentApi service constructor.
    * @property {module:api/SegmentApi}
    */
    SegmentApi,

    /**
    * The SendApi service constructor.
    * @property {module:api/SendApi}
    */
    SendApi,

    /**
    * The UserApi service constructor.
    * @property {module:api/UserApi}
    */
    UserApi
};
