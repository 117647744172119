/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Attachment from '../model/Attachment';
import InlineResponse404 from '../model/InlineResponse404';

/**
* NoticeAttachments service.
* @module api/NoticeAttachmentsApi
* @version 1.0
*/
export default class NoticeAttachmentsApi {

    /**
    * Constructs a new NoticeAttachmentsApi. 
    * @alias module:api/NoticeAttachmentsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Add an attachment to the notice
     * @param {Number} id id of the notice
     * @param {Number} idAttach id of the attachment
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse404} and HTTP response
     */
    appHttpControllersNoticeControllerAddNoticeAttachmentWithHttpInfo(id, idAttach) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersNoticeControllerAddNoticeAttachment");
      }
      // verify the required parameter 'idAttach' is set
      if (idAttach === undefined || idAttach === null) {
        throw new Error("Missing the required parameter 'idAttach' when calling appHttpControllersNoticeControllerAddNoticeAttachment");
      }

      let pathParams = {
        'id': id,
        'id_attach': idAttach
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse404;
      return this.apiClient.callApi(
        '/api/v1/notice_attachments/{id}/{id_attach}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Add an attachment to the notice
     * @param {Number} id id of the notice
     * @param {Number} idAttach id of the attachment
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse404}
     */
    appHttpControllersNoticeControllerAddNoticeAttachment(id, idAttach) {
      return this.appHttpControllersNoticeControllerAddNoticeAttachmentWithHttpInfo(id, idAttach)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List all attachments asociated to the notice
     * @param {Number} id id of the notice
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Attachment>} and HTTP response
     */
    appHttpControllersNoticeControllerGetNoticeAttachmentsWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersNoticeControllerGetNoticeAttachments");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Attachment];
      return this.apiClient.callApi(
        '/api/v1/notice_attachments/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List all attachments asociated to the notice
     * @param {Number} id id of the notice
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Attachment>}
     */
    appHttpControllersNoticeControllerGetNoticeAttachments(id) {
      return this.appHttpControllersNoticeControllerGetNoticeAttachmentsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete an attachment to the notice
     * @param {Number} id id of the notice
     * @param {Number} idAttach id of the attachment
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse404} and HTTP response
     */
    appHttpControllersNoticeControllerRemoveNoticeAttachmentWithHttpInfo(id, idAttach) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersNoticeControllerRemoveNoticeAttachment");
      }
      // verify the required parameter 'idAttach' is set
      if (idAttach === undefined || idAttach === null) {
        throw new Error("Missing the required parameter 'idAttach' when calling appHttpControllersNoticeControllerRemoveNoticeAttachment");
      }

      let pathParams = {
        'id': id,
        'id_attach': idAttach
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse404;
      return this.apiClient.callApi(
        '/api/v1/notice_attachments/{id}/{id_attach}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Delete an attachment to the notice
     * @param {Number} id id of the notice
     * @param {Number} idAttach id of the attachment
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse404}
     */
    appHttpControllersNoticeControllerRemoveNoticeAttachment(id, idAttach) {
      return this.appHttpControllersNoticeControllerRemoveNoticeAttachmentWithHttpInfo(id, idAttach)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
