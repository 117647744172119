import React, { Fragment, h} from "preact";
import './ftp-styles.scss'
import { AppPage } from '../../_common/components/app-page'
import { C, F } from '../../_common/layout'
import Steper from '../components/Steper/Steper'
import Timeline from '../components/Timeline/Timeline'
import { useSelector } from 'react-redux'
import {
  STATE_COMPANY_ASSIGNMENT,
  STATE_CONVENTION,
  STATE_DOCUMENTATION,
  STATE_EXEMPTION_100, STATE_FCT_END,
  STATE_FCT_STARTED, STATE_REQUEST_DEFERRAL_MAD
} from '../constants'
import Documentation from './steps/1_Documentation'
import CompanyAssignment from './steps/2_CompanyAssignment'
import Convention from './steps/3_Convention'
import StartedFct from './steps/4_StartedFct'
import Exemption100 from './steps/5_Exemption100'
import EndFCT from './steps/6_EndFct'
import RequestPostponement from '../components/RequestPostponement/RequestPostponement'
import { DateTime } from 'luxon'
import useFctMeta from '../hooks/useFctMeta'
import { useHistory, useParams } from 'react-router'
import AlertContact from '../components/Alerts/AlertContact'
import { t } from '../../_i18n'
import {isSpanishUser} from "../../_common/utils/getLang";
import { Link } from 'react-router-dom'
import RequestDeferralMAD from "./steps/7_RequestDeferralMAD";

export default function FctHome () {
  const { id } = useParams()
  const meta = useFctMeta(id)
  const history = useHistory()
  const { state, screenStatus } = useSelector(state => ({
    state: state.fct.state,
    screenStatus: state.fct.screenState
  }))
  const dateStartMinus10 = DateTime.fromISO(screenStatus?.screen_data?.contract_details?.start_date).minus({days: 10})
  const isNotFirst10Days = DateTime.now() < dateStartMinus10
  const shopId = useSelector(state => state.status.student_meta.shop_id)

  const isOpenDocumentation = screenStatus.screen_data.oppened_docs;
  const isOpenComments = screenStatus.screen_data.oppened_comments;
  const showStepper =
      (state !== STATE_DOCUMENTATION && state !== STATE_REQUEST_DEFERRAL_MAD) ||
      (state === STATE_DOCUMENTATION && isOpenDocumentation)
  ;

  return (
    <AppPage title={t('Formación en centros de trabajo')} back={history.goBack} className="max-width-980">
      <F className="max-width-980">
        <C>
          {showStepper && <Steper/>}
          {state === STATE_DOCUMENTATION && !isOpenDocumentation && (
              <Fragment>
                <div>
                  <p>{t('pre_documentation_page_1')} <b>{t('pre_documentation_page_2')}</b></p>
                  <p>{t('pre_documentation_page_3')}</p>
                </div>
              </Fragment>
          )}
          {state === STATE_DOCUMENTATION && isOpenDocumentation && <Documentation/>}
          {state === STATE_COMPANY_ASSIGNMENT && <CompanyAssignment/>}
          {state === STATE_CONVENTION && <Convention/>}
          {state === STATE_FCT_STARTED && <StartedFct/>}
          {state === STATE_EXEMPTION_100 && <Exemption100/>}
          {state === STATE_FCT_END && <EndFCT/>}
          {state === STATE_REQUEST_DEFERRAL_MAD && screenStatus.screen === '7.1' && <RequestDeferralMAD/>}
          {state !== STATE_FCT_END
              && meta.formative_zone === 'MAD'
              && screenStatus.screen !== '7.1'
              && screenStatus.screen !== '6.1'
              && screenStatus.screen !== '5.1'
              && screenStatus.screen !== '4.4'
              && !(screenStatus.screen === '4.3' && isNotFirst10Days)
              && <RequestPostponement/>}
          {isOpenComments && state !== 'STATE_REQUEST_DEFERRAL_MAD' && <AlertContact/>}
          {!isOpenComments && isSpanishUser(shopId) && meta.formative_zone === 'CAT' &&
              <p>{t('Si tienes alguna duda puedes')} <a
                  href='https://storage.googleapis.com/fct_documents/FCT_Normativa_Online_2024.pdf' target='_blank'
                  className='font-color-149BAB'>{t('consultar la normativa de FCT')}</a>.</p>
          }
          {!isOpenComments && (shopId == 24 || shopId == 44) &&
              <p>{t('Si tienes alguna duda puedes')} <Link to={{pathname: `/fct/${id}/normativa`}}
                                                           className='txt-color-primary t-underline font-medium'>{t('consultar la normativa de FCT')}</Link>.
              </p>
          }
          {!isOpenComments && meta.formative_zone === 'MAD' && !(shopId == 24 || shopId == 44) &&
              <p>{t('Si tienes alguna duda puedes')} <a
                  href='https://soporte.ilerna.es/hc/es-es/articles/16319986715037-Normativa-Formaci%C3%B3n-en-Centros-de-Trabajo-MAD' target='_blank'
                  className='font-color-149BAB'>{t('consultar la normativa de FCT')}</a>.</p>
          }
          {isOpenComments && state !== 'STATE_REQUEST_DEFERRAL_MAD' && <Timeline/>}
        </C>
      </F>
    </AppPage>
  )
}
