/**
 * Student Account
 * Student Account Api - Validation flow
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dmartinez@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import StudentAddress from './StudentAddress';
import StudentCourseAccess from './StudentCourseAccess';
import StudentIdentityDocument from './StudentIdentityDocument';
import StudentLastCourse from './StudentLastCourse';
import StudentPersonalData from './StudentPersonalData';

/**
 * The Student model module.
 * @module model/Student
 * @version 1.0.0
 */
class Student {
    /**
     * Constructs a new <code>Student</code>.
     * All student data
     * @alias module:model/Student
     */
    constructor() { 
        
        Student.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Student</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Student} obj Optional instance to populate.
     * @return {module:model/Student} The populated <code>Student</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Student();

            if (data.hasOwnProperty('data')) {
                obj['data'] = StudentPersonalData.constructFromObject(data['data']);
            }
            if (data.hasOwnProperty('last_course')) {
                obj['last_course'] = StudentLastCourse.constructFromObject(data['last_course']);
            }
            if (data.hasOwnProperty('course_access')) {
                obj['course_access'] = StudentCourseAccess.constructFromObject(data['course_access']);
            }
            if (data.hasOwnProperty('address')) {
                obj['address'] = StudentAddress.constructFromObject(data['address']);
            }
            if (data.hasOwnProperty('identity_document')) {
                obj['identity_document'] = StudentIdentityDocument.constructFromObject(data['identity_document']);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/StudentPersonalData} data
 */
Student.prototype['data'] = undefined;

/**
 * @member {module:model/StudentLastCourse} last_course
 */
Student.prototype['last_course'] = undefined;

/**
 * @member {module:model/StudentCourseAccess} course_access
 */
Student.prototype['course_access'] = undefined;

/**
 * @member {module:model/StudentAddress} address
 */
Student.prototype['address'] = undefined;

/**
 * @member {module:model/StudentIdentityDocument} identity_document
 */
Student.prototype['identity_document'] = undefined;






export default Student;

