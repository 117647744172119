/*

Nota: Antes e generaban los archivos i18n según ka hoja de cálculo del drive (ver más abajo). Nos lo petamos por estos
motivos:

1. Nadie fuera de IT está actualizando esas hojas desde hace tiempo
2. Requiere un esfuerzo extra tener que cambiar las hojas y pasar luego el script de importación
3. Tendría sentido si fuera automático (no requiriera recomplular Mi Cuenta), mas no lo es


** DEPRECATED **

--------- NO EDITAR -----------

Editar el sheet de Google docs:

https://docs.google.com/spreadsheets/d/1UJZkk-HrMMsjKUSlIDbxWpxXUVOZfH2iYgXZWmUCEqk/edit#gid=736972915

y correr despues el script con: 
yarn run import-lang

*/


import {h} from "preact";

const t = {
    "clave": `es`,
    "enroll_form_pending": `enroll_form_pending`,
    "identity_document_pending": `identity_document_pending`,
    "course_access_document_pending": `course_access_document_pending`,
    "certified_course_access_document_pending": `certified_course_access_document_pending`,
    "Volver": `Volver`,
    "Completa tu matriculación": `Completa tu matriculación`,
    "Subtitulo completa tu matriculacion": `Facilita la información pendiente para formalizar tu matrícula en el Departamento de Educación:`,
    "Información personal y académica": `Información personal y académica`,
    "Formulario de matrícula": `Formulario de matrícula`,
    "Información de acceso al ciclo": `Información de acceso al ciclo`,
    "Documento de identidad": `Documento de identidad`,
    "Documentos de acceso": `Documentos de acceso`,
    "Enviar copia de titulación": `Enviar copia de titulación`,
    "Ayuda": `Una vez validada la información personal y académica (punto anterior), deberás enviar la copia de tu titulación.`,
    "Pendiente": `Pendiente`,
    "Enviado": `Enviado`,
    "Validando": `Validando`,
    "Revisar": `Revisar`,
    "Validado": `Validado`,
    "Completa formulario información personal": `Completa el formulario con tu información personal:`,
    "Campos obligatorios": `* Campos obligatorios`,
    "Nombre": `Nombre`,
    "Primer apellido": `Primer apellido`,
    "Segundo apellido": `Segundo apellido`,
    "Género": `Género*`,
    "Femenino": `Femenino`,
    "Masculino": `Masculino`,
    "Fecha nacimiento*": `Fecha de nacimiento*`,
    "País nacimiento": `País de nacimiento`,
    "Población nacimiento": `Población de nacimiento`,
    "Lugar residencia*": `Lugar de residencia*`,
    "País residencia": `País de residencia`,
    "Tipo vía": `Tipo de vía`,
    "Nombre vía": `Nombre de la vía`,
    "Núm": `Núm.`,
    "Bloq": `Bloq.`,
    "Escal": `Escal.`,
    "Piso": `Piso`,
    "Puerta": `Puerta`,
    "Código Postal": `Código Postal`,
    "Población": `Población`,
    "Teléfono contacto": `Teléfono de contacto`,
    "Últimos estudios": `Últimos estudios realizados`,
    "Indica últimos estudios": `Indica los últimos estudios que has realizado, anteriores al ciclo de que te estás matriculando.`,
    "Tipo estudios": `Tipo de estudios`,
    "Último año académico": `Último año académico`,
    "Seguro pregunta": `Seguro que deseas salir? Se perderán los cambios`,
    "Completa información documento identidad": `Completa la información de tu documento de identidad.`,
    "Nacionalidad": `Nacionalidad`,
    "DNI": `DNI`,
    "Pasaporte": `Pasaporte`,
    "NIE": `NIE`,
    "Número documento": `Número de documento`,
    "Documento válido": `Documento válido hasta:`,
    "Fotos del DNI:": `Añade el DNI:`,
    "Cara frontal": `Cara frontal`,
    "Cara trasera": `Cara trasera`,
    "Tipo de documento": `Tipo de documento`,
    "Fotos DNI": `Documento de identidad:`,
    "Foto frontal": `Cara frontal:`,
    "Foto warning": `El texto debe ser legible. Tiene que tener buena iluminacion, estar enfocado y no tener destellos.`,
    "Foto pasaporte": `Añadir el pasaporte`,
    "Añadir foto imagen": `Añadir `,
    "Foto frontal NIE": `Añadir frontal del NIE`,
    "Foto trasera NIE": `Añadir trasera del NIE`,
    "Foto frontal NIF": `Añadir frontal del NIF`,
    "Foto trasera NIF": `Añadir trasera del NIF`,
    "Documento no correcto": `El documento de identidad que has añadido no es correcto.`,
    "Fotos no legibles danger": `Las fotos del documento que has añadido no son legibles. Por favor, vuelve a añadirlas procurando que toda la información se pueda leer bien.`,
    "DNI no correcto danger": `El documento de identidad que has añadido no es correcto.`,
    "Validación fecha danger": `Introduce la fecha de caducidad del nuevo documento, además las fotos de cada una de las caras`,
    "NIE ejemplo": `El NIE consta de una letra inicial (X, Y, Z), 7 números y una letra. Ej.: X1111111A`,
    "DNI ejemplo": `El DNI consta de 8 números y una letra. Ej.: 11111111A`,
    "Completa la información de tu NIE": `Completa la información de tu NIE`,
    "Completa la información de tu DNI": `Completa la información de tu DNI`,
    "Completa la información de tu pasaporte": `Completa la información de tu pasaporte`,
    "Completa información": `Completa la información:`,
    "Titulación accedes ciclo": `Titulación con la que accedes al ciclo`,
    "Titulación acceso": `Titulación de acceso`,
    "consulta requisitos acceso": `Consulta los requisitos de acceso`,
    "Título pregunta": `¿Tienes el título a tu disposición? *`,
    "Tiene título": `Sí`,
    "No tiene título": `No, todavía no lo tengo`,
    "Nota final": `Nota final`,
    "No nota final": `Si no tienes nota final, por favor, introduce el número 5`,
    "Dirección de envío": `Por favor, añade el título`,
    "Subir foto titulo": `Añade el título`,
    "Título añadido danger": `El título que has añadido, no se corresponde con el título de acceso que has indicado. Por favor, añade el título correcto.`,
    "Foto resguardo tasas": `Añadir foto del resguardo por tasas`,
    "Foro certificado notas": `Añadir foto del certificado de notas`,
    "Selecciona archivo": `Selecciona el archivo`,
    "<strong>Añade resguardo pago</strong>": `Añade el <strong>resguardo de pago de tasas de la solicitud de expedición del título</strong> (como máximo de 2 años de antigüedad), junto con el <strong>certificado de notas oficial:</strong> `,
    "Resguardo pago danger": `El resguardo del pago que has añadido no se corresponde con el título de acceso que has indicado. Por favor, añade el resguardo de pago de tasas correcto.`,
    "Resguardo tasas título": `Resguardo por tasas`,
    "Certificado notas título": `Certificado de notas`,
    "Añadir foto": `Añadir`,
    "Requisitos acceso": `Requisitos de acceso`,
    "Texto requisito acceso": `
    <p>Para matricularte a un ciclo de Grado Medio, debes tener más de 18 años o cumplirlos durante el año natural del comienzo del ciclo. Además, tienes que cumplir al menos uno de los siguientes requisitos académicos:</p>
    <ul>
    <li>Tener el título de Graduado de Educación Secundaria Obligatoria (ESO).</li>
    <li>Tener el Graduado en Educación Secundaria (GES).</li>
    <li>Tener el título de Técnico/a o de Técnico/a auxiliar.</li>
    <li>Haber superado el curso específico para el acceso a los ciclos formativos de Grado Medio (CAM).</li>
    <li>Haber superado el segundo curso del Bachillerato Unificado y Polivalente (BUP).</li>
    <li>Tener otros estudios equivalentes a efectos académicos.</li>
    <li>Haber superado los módulos obligatorios de un programa de cualificación profesional inicial (PCPI).</li>
    <li>Haber superado la Prueba de Acceso a la Universidad para mayores de 25 años.</li>
    <li>Tener un título para acceder a un ciclo formativo de Grado Superior.</li>
    <li>Haber superado la prueba de acceso a un ciclo de Grado Medio.</li>
    </ul>
    <p>Para matricularte a un ciclo de Grado Superior, debes tener más de 18 años o cumplirlos durante el año natural del comienzo del ciclo. Además, tienes que cumplir al menos uno de los siguientes requisitos académicos:</p>
    <ul>
    <li>Tener el título de Bachillerato.</li>
    <li>Tener el título de Técnico/a (ciclo de Grado Medio, CFGM).</li>
    <li>Tener el título de Técnico/a superior o especialista (ciclo de Grado Superior, CFGS).</li>
    <li>Tener el título Bachillerato Unificado Polivalente (BUP).</li>
    <li>Haber superado el segundo curso de Bachillerato de cualquier modalidad de Bachillerato experimental.</li>
    <li>Haber superado el Curso de Orientación Universitaria (COU) o el preuniversitario.</li>
    <li>Tener cualquier titulación universitaria o una equivalente.</li>
    <li>Haber superado la prueba de acceso en la Universidad para mayores de 25 años.</li>
    <li>Haber superado la prueba de acceso que te acredite para estudiar tu ciclo de Grado Superior deseado.</li>
    </ul>`,
    "copia compulsada": `Para finalizar el proceso de matriculación, debes enviarnos una copia compulsada del título de acceso al ciclo a nuestra sede de ILERNA Online. Mientras tanto podrás cursar tu formación con normalidad.`,
    "Resguardo de pago": `Resguardo de pago de tasas de expedición del título.`,
    "Certificado notas": `Certificado de notas oficial.`,
    "Dirección de envío": `Dirección de envío`,
    "ILERNA Online": `ILERNA Online`,
    "Turó de Gardeny": `Turó de Gardeny, Complex Magical Media`,
    "Edificio": `Edificio 5, 25003 - Lleida`,
    "Anulación matrícula": `Si antes de la fecha no hemos recibido el título, procederemos a la anulación de tu matrícula.`,
    "Condiciones venta": `Ver condiciones de venta.`,
    "Copia título alert danger": `La copia del título que nos has enviado no está compulsada. Debes enviarnos la copia compulsada para poder tramitar tu expediente con el Departamento de Educación.`,
    "Datos alumno": `Tus datos`,
    "Nombre y apellidos": `Nombre y apellidos`,
    "Sexo": `Sexo`,
    "Fecha nacimiento": `Fecha de nacimiento`,
    "Número DNI": `Número de DNI`,
    "Actualizar DNI": `Actualizar documento de identidad`,
    "Cambiar residencia": `Cambiar lugar de residencia`,
    "Cambiar teléfono": `Cambiar teléfono`,
    "Cambiar nee": `Cambiar necesidades educativas`,
    "Nee": `Necesidades educativas especiales`,
    "Lugar residencia": `Lugar de residencia`,
    "Teléfono": `Teléfono`,
    "Necesidades Educativas": `Necesidades Educativas`,
    "Selecciona nee": `Selecciona una necesidad educativa`,
    "Historial de matrículas": `Historial de matrículas`,
    "Detalle de matrícula": `Detalle de matrícula`,
    "Pedir nuevo": `Pedir de nuevo`,
    "Resumen asignaturas": `Resumen de asignaturas`,
    "Descargar factura": `Descargar factura`,
    "No referencia": `No se indicó la referencia`,
    "Fecha": `Fecha`,
    "Matrícula": `Matrícula`,
    "Importe": `Importe`,
    "Método pago": `Método de pago`,
    "Estado": `Estado`,
    "Cerrar": `cerrar`,
    "Cambiar contraseña": `Cambiar contraseña`,
    "Tu cuenta": `Tu cuenta`,
    "Dirección": `Dirección`,
    "Código postal": `Código postal`,
    "País": `País`,
    "Guardar": `Guardar`,
    "Contraseña actual": `Contraseña actual`,
    "Nueva contraseña": `Nueva contraseña`,
    "Confirmar contraseña": `Confirmar contraseña`,
    "contraseña no coincide": `Las contraseñas no coinciden`,
    "Accede al campus": `Accede al campus`,
    "Cerrar sesión": `Cerrar sesión`,
    "Select error": `Debes seleccionar un campo.`,
    "Rango contraseña": `La contraseña debe tener 8 caracteres como mínimo`,
    "Error no manejado": `Error no manejado`,
    "Error texto": `Debes introducir un texto`,
    "Error numero": `Debes introducir un numero`,
    "Error telefono": `Debes introducir un teléfono correcto`,
    "Escriba una población válida": `Escriba una población válida`,
    "Todavía no te has matriculado en ILERNA.": `Todavía no te has matriculado en ILERNA.`,
    "Matrícúlate ahora": `Matrícúlate ahora`,
    "completa matricula": `Facilita la información que está pendiente para que podamos completar tu matrícula.`,
    "estamos validando": `Estamos validando la información que has aportado. Te avisaremos de cualquier posible incidencia.`,
    "hemos detectado error": `Hemos detectado alguna incidencia en la información que has aportado. Por favor, revísala.`,
    "help_documentos_acceso": `Una vez validada la información personal y académica (punto anterior), deberás enviar la copia de tu titulación.`,
    "data.name": `Por favor, escribe tu nombre`,
    "data.first_name": `Por favor, escribe tu apellido`,
    "data.gender.id": `Por favor, selecciona uno`,
    "data.birth_date": `Por favor, indica tu fecha de nacimiento`,
    "data.birth_date_day": `Por favor, indica tu día de nacimiento`,
    "data.birth_date_month": `Por favor, indica tu mes de nacimiento`,
    "data.birth_date_year": `Por favor, indica tu año de nacimiento`,
    "data.birth_country.id": `Por favor, selecciona el país de nacimiento`,
    "data.birth_city.id": `Por favor, escribe la población de nacimiento`,
    "data.birth_city_name": `Por favor, escribe la población de nacimiento`,
    "address.via_type.id": `Por favor, selecciona el tipo de vía`,
    "address.name": `Por favor, escribe el nombre de la vía`,
    "address.number_address": `Escribe el número`,
    "address.zip_code": `Por favor, escribe el código postal`,
    "address.city.id": `Por favor, escribe la población`,
    "address.city_name": `Por favor, escribe la población`,
    "address.country_name": `Por favor, selecciona el país`,
    "address.phone": `Por favor, escribe tu teléfono de contacto`,
    "data.nee.id": `Por favor, selecciona una necesidad educativa o ninguna`,
    "last_course.id": `Por favor, selecciona el tipo de estudios`,
    "last_course.academic_year": `Por favor, selecciona el último año académico`,
    "type.id": `Por favor, selecciona el título de acceso`,
    "grade": `Por favor, escribe la nota final`,
    "reference": `Por favor, introduce el número de documento`,
    "country.id": `Por favor, selecciona tu nacionalidad`,
    "expires_at": `Por favor, indica la fecha de validez`,
    "expires_at_day": `Por favor, indica el día`,
    "expires_at_month": `Por favor, indica el mes`,
    "expires_at_year": `Por favor, indica el año`,
    "address_name": `Por favor, escribe la dirección`,
    "address_city_name": `Por favor, escribe la población`,
    "address_country_name": `Por favor, selecciona el país`,
    "address_zip_code": `Por favor, escribe el código postal`,
    "Tipos de vía frecuentes": `Tipos de vía frecuentes`,
    "Listado alfabético": `Listado alfabético`,
    "Inicio": `Inicio`,
    "Fecha nacimiento exemple": `Por ejemplo: 20 11 1984`,
    "Consulta los detalles": `Consulta los detalles`,
    "Puedes subir PDF": `Puedes escanear el documento y después añadir el archivo, o puedes subir las fotos directamente desde tu móvil.<br><br>
<ul><li>Asegúrate de que todo el texto del documento se pueda leer bien.</li>
<li>Si haces una foto, evita el flash para que se enfoque e ilumine bien.</li>
<li>Añade archivos PDF, DOC, JPEG ó PNG, de 5 MB como máximo.</li>
</ul>`,
    "Aviso compulsa": `Para formalizar tu expediente académico, necesitaremos copias compulsadas de tus documentos de acceso. Cuando hayamos verificado toda tu información de matrícula en formato digital, te pediremos que nos envíes las copias compulsadas por correo postal.<br/><br/>
                            Si aportas una copia compulsada digital de un documento, o bien el documento tiene un Código Seguro de Verificación (CSV), no será necesario que nos envíes por correo postal una copia en papel del mismo.`,
    "Se ha subido el archivo correctamente": `Se ha subido el archivo correctamente`,
    "Completa tu matricula": `Completa tu matrícula`,
    "Clases presenciales": `Clases presenciales`,
    "Exámenes": `Exámenes`,
    "Expediente": `Expediente`,
    "Información personal": `Información personal`,
    "Selecciona una necesidad educativa": `Selecciona una necesidad educativa`,
    "Actualizar": `Actualizar`,
    "Reembolsada": `Reembolsada`,
    "Congelada": `Congelada`,
    "Error": `Error`,
    "Actualizar datos": `Actualizar datos`,
    "¿Has olvidado la contraseña?": `¿Has olvidado la contraseña?`,
    "Resumen de productos": `Resumen de productos`,
    "No hay mensajes todavía ...": `No hay mensajes todavía ...`,
    "Comunicado": `Comunicado`,
    "Cargando": `Cargando`,
    "a las": `a las`,
    "min de lectura": `min de lectura`,
    "Tus datos se han guardado correctamente": `Tus datos se han guardado correctamente`,
    "La contraseña se ha cambiado correctamente": `La contraseña se ha cambiado correctamente`,
    "Tu contraseña se ha restablecido correctamente.": `Tu contraseña se ha restablecido correctamente.`,
    "access_title": `Título acceso al ciclo`,
    "payment_fees": `Pago tasas`,
    "grade_cert": `Certificado de notas`,
    "Resguardo pago tasas": `Resguardo pago tasas`,
    "Certificado de notas": `Certificado de notas`,
    "Tus pedidos": `Tus pedidos`,
    "Detalle de pedido": `Detalle de pedido`,
    "Bloque": `Bloque`,
    "Editar": `Editar`,
    "Escalera": `Escalera`,
    "Número": `Número`,
    "Ninguna": `Ninguna`,
    "El DNI no es valido": `El DNI no es válido`,
    "Toma una foto": `Toma una foto`,
    "Comunicados": `Comunicados`,
    "Campus": `Campus`,
    "formalizar matricula alert": `Los datos que introduzcas deben ser exactamente igual que los que aparecen en tu Documento de Identidad (acentos, guiones, mayúsculas, minúsculas)`,
    "Expedición del título": `Expedición del título`,
    "Pago de tasas": `Pago de tasas`,
    "Dirección de envío del título": `Dirección de envío del título`,
    "Antes de abonar las tasas,<strong> debes verificar la dirección de envío</strong> y actualizarla, si es necesario:": `Antes de abonar las tasas,<strong> debes verificar la dirección de envío</strong> y actualizarla, si es necesario:`,
    "Actualizar dirección": `Actualizar dirección`,
    "Aceptar y continuar": `Aceptar y continuar`,
    "Pagar tasas": `Pagar tasas`,
    "Para solicitar tu título es necesario <strong>pagar las tasas de expedición del título</strong>": `Para solicitar tu título es necesario <strong>pagar las tasas de expedición del título</strong>`,
    "<strong>En el caso de que no estés exento/a del pago de tasas</strong>, debes realizar el pago aquí:": `<strong>En el caso de que no estés exento/a del pago de tasas</strong>, debes realizar el pago aquí:`,
    "titulo-payment-success": `En un plazo máximo de 15 días desde la fecha del pago de tasas, recibirás por correo certificado urgente la documentación que acredita que has finalizado tu formación. <br/> <br/>
        Los documentos que recibirás tienen la misma validez que el título oficial que nos llegará a nuestra sede en un plazo aproximado de dos años. <br/> <br/>
        Podrás seguir el envío a través de un enlace que te proporcionará la empresa de correos GLS.`,
    "Documentación provisional enviada": `Documentación provisional enviada`,
    "titulo-docu-sent": `<ul>
            <li class='m-bottom-half'>Certificado de notas completo del ciclo formativo original (+ dos copias compulsadas).</li>
            <li class='m-bottom-half'>Justificante del pago de las tasas de la expedición del título (+ dos copias compulsadas).</li>
            <li class='m-bottom-half'>Resguardo de la solicitud del título oficial (+ dos copias compulsadas).</li>
        </ul>`,
    "titulo-docu-sent-warning": `La documentación enviada acredita que has finalizado tu formación. Los documentos tienen la misma validez que el título oficial, el cuál nos llegará a nuestra sede en un plazo aproximado de 2 años desde el momento de la solicitud <br/>
            <br/>
            <strong>Contactaremos contigo para indicarte el protocolo de entrega del título oficial.</strong>`,
    "titulo-docu-sent-pedir-encuesta": `Agradecemos mucho la confianza que has depositado en nosotros para formarte, por ello es <strong>IMPORTANTE</strong> que contestes esta encuesta:`,
    "Valoración del servicio recibido": `Valoración del servicio recibido`,
    "Lee las consideraciones previas para la formalización de la matrícula, y firma el documento.": `Lee las consideraciones previas para la formalización de la matrícula, y firma el documento.`,
    "Deja tu firma aquí": `Deja tu firma aquí`,
    "Borrar": `Borrar`,
    "Aceptar": `Aceptar`,
    "Consideraciones previas para la formalización de la matrícula": `Consideraciones previas para la formalización de la matrícula`,
    "Contacta con tu gestor": `Contacta con tu gestor`,
    "Contacta con tu gestor a través de WhatsApp": `Contacta con tu gestor a través de WhatsApp`,
    "Para añadir la información de contacto de tu gestor Premium a WhatsApp puedes escanear el código QR de WhatsApp.": `Para añadir la información de contacto de tu gestor Premium a WhatsApp puedes escanear el código QR de WhatsApp.`,
    "Si tu móvil es Android:": `Si tu móvil es Android:`,
    "Si tu móvil es iPhone:": `Si tu móvil es iPhone:`,
    "whatsapp-nuevo-chat": `Abre WhatsApp > pulsa "Nuevo chat":`,
    "Pulsa el icono de QR junto a Nuevo contacto:": `Pulsa el icono de QR junto a Nuevo contacto:`,
    "Selecciona <b>ESCANEAR CÓDIGO</b> y coloca tu dispositivo sobre el código QR para escanearlo.": `Selecciona <b>ESCANEAR CÓDIGO</b> y coloca tu dispositivo sobre el código QR para escanearlo.`,
    "Pulsa <strong>AÑADIR.</strong>": `Pulsa <strong>AÑADIR.</strong>`,
    "Pulsa <strong>Nuevo contacto > Añadir a través de código QR</strong>": `Pulsa <strong>Nuevo contacto > Añadir a través de código QR</strong>`,
    "Coloca tu dispositivo sobre el código QR para escanearlo o pulsa el icono de <strong>Fotos</strong> en la parte inferior de la pantalla y selecciona el código QR de WhatsApp desde tus <strong>Fotos</strong>": `Coloca tu dispositivo sobre el código QR para escanearlo o pulsa el icono de <strong>Fotos</strong> en la parte inferior de la pantalla y selecciona el código QR de WhatsApp desde tus <strong>Fotos</strong>`,
    "Pulsa <strong>Añadir contactos.</strong>": `Pulsa <strong>Añadir contactos.</strong>`,
    "Para cualquier consulta, puedes contactar con tu gestor a través de Whatsapp.": `Para cualquier consulta, puedes contactar con tu gestor a través de Whatsapp.`,
    "Código QR": `Código QR`,
    "state": `Por favor, introduce una provincia`,
    "zip": `Por favor, introduce un código postal`,
    "city": `Por favor, introduce una población`,
    "title": `Por favor, introduce un título para la oferta`,
    "maxJobs": `Por favor, introduce el número de vacantes`,
    "contractType": `Por favor, introduce el tipo de contrato`,
    "schedule": `Por favor, introduce la jornada laboral`,
    "companyName": `Por favor, introduce un nombre alternativo`,
    "presence": `Por favor, selecciona una modalidad`,
    "name": `Por favor, introduce el nombre`,
    "surname": `Por favor, introduce los apellidos`,
    "phone": `Por favor, introduce el teléfono`,
    "email": `Por favor, introduce el correo electrónico`,
    "nif_validate": `Por favor, introduce un identificador valido.`,
    "subtitle": `Por favor, introduce el sector de la empresa`,
    "empleados": `Por favor, introduce el número de trabajadores`,
    "text": `Por favor, introduce la descripción de tu empresa`,
    "location": `Por favor, introduce la ubicación`,
    "links[1].url": `Por favor, introduce un link de linkedin correcto`,
    "links[0].url": `Por favor, introduce un link de infojobs correcto`,
    "salaryType": `Por favor, introduce un tipo de salario`,
    "salaryMin": `Por favor, introduce un salario minimo`,
    "salaryMax": `Por favor, introduce un salario maximo`,
    "Informacion general": `Información general`,
    "Requisito tecnico": `Requisitos técnicos para cursar la formación online`,
    "liceale conseguita": ``,
    "Titulo de acceso": `Título de acceso:`,
    "Diploma di scuola": `Título de acceso`,
    "acerca del servicio": `acerca del servicio`,
    "Plazos de tramitación": `Plazos de tramitación`,
    "Los plazos de tramitación y entrega de la documentación son de <strong>10 a 15 días laborables,</strong> a partir del momento de la recepción de documentación (en el caso de exención al pago de tasas) y del pago de las tasas.": `Los plazos de tramitación y entrega de la documentación son de <strong>10 a 15 días laborables,</strong> a partir del momento de la recepción de documentación (en el caso de exención al pago de tasas) y del pago de las tasas.`,
    "Tarifas de solicitud del título": `Tarifas de solicitud del título`,
    "service-policy-tax": `<p>Ciclo Formativo de Grado Medio (CFGM): <strong>85€</strong></p><p>Ciclo Formativo de Grado Superior (CFGM): <strong>95€</strong></p>`,
    "Documentación que recibirás": `Documentación que recibirás`,
    "Pasados los 10 a 15 días recibirás por correo certificado la documentación que acredita que has finalizado tu formación. Recibirás el resguardo de pago de tasas, que <strong>tiene la misma validez que el título oficial,</strong> así como el certificado de notas del ciclo formativo y el justificante de pago de la tasas. Es importante no entregar nunca el original, ya que es el equivalente al titulo hasta que este llegue en un <strong>plazo aproximado de 2 años.</strong>": `Pasados los 10 a 15 días recibirás por correo certificado la documentación que acredita que has finalizado tu formación. Recibirás el resguardo de pago de tasas, que <strong>tiene la misma validez que el título oficial,</strong> así como el certificado de notas del ciclo formativo y el justificante de pago de la tasas. Es importante no entregar nunca el original, ya que es el equivalente al titulo hasta que este llegue en un <strong>plazo aproximado de 2 años.</strong>`,
    "Podrás seguir el envío a través de un enlace que te proporcionará la empresa de correos.": `Podrás seguir el envío a través de un enlace que te proporcionará la empresa de correos.`,
    "guardar cambios": `guardar cambios`,
    "cancelar": `cancelar`,
    "Actualizar dirección de envío": `Actualizar dirección de envío`,
    "Pago de tasas realizado correctamente": `Pago de tasas realizado correctamente`,
    "En un plazo máximo de 15 días desde la fecha del pago de tasas, recibirás por correo certificado urgente la documentación que acredita que has finalizado tu formación": `En un plazo máximo de 15 días desde la fecha del pago de tasas, recibirás por correo certificado urgente la documentación que acredita que has finalizado tu formación`,
    "Los documentos que recibirás tienen la misma validez que el título oficial que nos llegará a nuestra sede en un plazo aproximado de dos años": `Los documentos que recibirás tienen la misma validez que el título oficial que nos llegará a nuestra sede en un plazo aproximado de dos años`,
    "Podrás seguir el envío a través de un enlace que te proporcionará la empresa de correos GLS": `Podrás seguir el envío a través de un enlace que te proporcionará la empresa de correos GLS`,
    "Consideraciones previas para la formalización de la matrícula": `Consideraciones previas para la formalización de la matrícula`,
    "Empieza tu FP a distancia": `Empieza tu FP a distancia`,
    "Para empezar a estudiar tu FP a distancia en {MONTH} y obtener tu titulación oficial, debes matricularte. Tu Experiencia ILERNA no tiene validez académica.": `Para empezar a estudiar tu FP a distancia en {MONTH} y obtener tu titulación oficial, debes matricularte. Tu Experiencia ILERNA no tiene validez académica.`,
    "address_province_name": `Por favor, selecciona la provincia `,
    "Solicitud de revisión enviada correctamente": `Solicitud de revisión enviada correctamente`,
    "Ha sucedido un error con la subida del archivo": `Ha sucedido un error con la subida del archivo`,
    "Ha sucedido un error con la descarga del archivo": `Ha sucedido un error con la descarga del archivo`,
    "Seleccione el idioma": `Seleccione el idioma`,
    "Sector de la empresa a mostrar": `Sector de la empresa a mostrar`,
    "Datos de contacto correo": `Cambiar correo electrónico`,
    "ene": `ene`,
    "feb": `feb`,
    "mar": `mar`,
    "abr": `abr`,
    "may": `may`,
    "jun": `jun`,
    "jul": `jul`,
    "ago": `ago`,
    "sep": `sep`,
    "oct": `oct`,
    "nov": `nov`,
    "dic": `dic`,
    "Motivos seguridad": `Por motivos de seguridad, introduce la contraseña de ILERNA para continuar.`,
    "Datos de contacto password": `Cambiar contraseña`,
    "Datos de contacto": `Cambiar datos de contacto`,
    "Error password": `Contraseña incorrecta`,
    "No se ha podido cambiar la contraseña": `No se ha podido cambiar la contraseña`,
    "Teletrabajo": `Teletrabajo`,
    "vacante": `vacante`,
    "vacantes": `vacantes`,
    "Proceso Online": `PROCESO ONLINE`,
    "Página web de ILERNA": `Página web de ILERNA`,
    "Contraseña operacion": `La contraseña es obligatoria para completar la operación`,
    "Parece que el formato del video es incorrecto.": `Parece que el formato del video es incorrecto.`,
    "Parece que el formato de la web es incorrecto.": `Parece que el formato de la web es incorrecto.`,
    "Cambiar correo electrónico": `Cambiar correo electrónico`,
    "Cambiar datos de contacto": `Cambiar datos de contacto`,
    "Bolsa de trabajo | ILERNA": `Bolsa de trabajo | ILERNA`,
    "Publicar oferta | ILERNA": `Publicar oferta | ILERNA`,
    "Datos de usuario | ILERNA": `Datos de usuario | ILERNA`,
    "Cambiar correo electrónico | ILERNA": `Cambiar correo electrónico | ILERNA`,
    "Datos de contacto password | ILERNA": `Datos de contacto password | ILERNA`,
    "Cambiar datos de contacto | ILERNA": `Cambiar datos de contacto | ILERNA`,
    "Perfil de empresa | ILERNA": `Perfil de empresa | ILERNA`,
    "Registro | ILERNA": `Registro | ILERNA`,
    "Las preguntas del cuestionario no se pueden editar porque ya hay candidatos inscritos en la oferta.": `Las preguntas del cuestionario no se pueden editar porque ya hay candidatos inscritos en la oferta.`,
    "Bolsa de trabajo": `Bolsa de trabajo`,
    "Necesidades educativas especiales": `Necesidades educativas especiales`,
    "CAMBIAR": `CAMBIAR`,
    "Datos de acceso": `Datos de acceso`,
    "Correo electrónico": `Correo electrónico`,
    "Notificaciones App": `Notificaciones App`,
    "Información Académica": `Información Académica`,
    "Recibe todas la novedades relativas a tu ciclo: inscripción a exámenes, publicación de notas, comunicados oficiales, etc.": `Recibe todas la novedades relativas a tu ciclo: inscripción a exámenes, publicación de notas, comunicados oficiales, etc.`,
    "Promociones y Descuentos": `Promociones y Descuentos`,
    "Entérate del lanzamiento de nueva formación y ofertas en tu matrícula.": `Entérate del lanzamiento de nueva formación y ofertas en tu matrícula.`,
    "Novedades en la bolsa de trabajo": `Novedades en la bolsa de trabajo`,
    "Estarás al día de todas las nuevas ofertas de empleo.": `Estarás al día de todas las nuevas ofertas de empleo.`,
    "Actualizaciones en tus candidaturas": `Actualizaciones en tus candidaturas`,
    "Recibe un aviso cuando tus candidaturas de ofertas de trabajo cambien de estado.": `Recibe un aviso cuando tus candidaturas de ofertas de trabajo cambien de estado.`,
    "Otros documentos": `Otros documentos`,
    "comunicado sin leer": `comunicado sin leer`,
    "comunicados sin leer": `comunicados sin leer`,
    "Configurar notificaciones": `Configurar notificaciones`,
    "Cancelar": `Cancelar`,
    "Condiciones promoción": ` Condiciones de la promoción invitar a un amigo`,
    "INVITE_FRIEND_PENDING_FIRST_TEXT": `Si invitas a alguien a quien le pueda interesar estudiar en ILERNA Online antes del 26 de noviembre podrás llevarte una<span class='font-black'> sudadera exclusiva de ILERNA</span> o disfrutar del<span class='font-black'> {DTO_PERCENTAGE}% de descuento en el servicio Premium</span>`,
    "INVITE_FRIEND_PENDING_FIRST_TEXT_ALREADY_PREMIUM": `Si invitas a alguien a quien le pueda interesar estudiar en ILERNA Online antes del 26 de noviembre podrás llevarte una<span class='font-black'> sudadera exclusiva de ILERNA</span>`,
    "¿Conoces a alguien a quién le gustaría estudiar en ILERNA?": `¿Conoces a alguien a quién le gustaría estudiar en ILERNA?`,
    "Solo puedes enviar una invitación. Escoge bien a quien invitas.": `Solo puedes enviar una invitación. Escoge bien a quien invitas.`,
    "Consulta las condiciones completas de la promoción.": `Consulta las condiciones completas de la promoción.`,
    "Introduce el e-mail de tu amig@:": `Introduce el e-mail de tu amig@:`,
    "Repite el e-mail:": `Repite el e-mail:`,
    "He leído y acepto": `He leído y acepto`,
    "INVITE_FRIEND_PENDING_CHECKBOX_CONDITIONS": `<span class='t-underline text-primary'>las condiciones de la promoción.</span>`,
    "El e-mail no coincide. Por favor, revísalo y escribe el e-mail correctamente.": `El e-mail no coincide. Por favor, revísalo y escribe el e-mail correctamente.`,
    "El e-mail no es correcto.": `El e-mail no es correcto.`,
    "Invitar a un@ amigo@": `Invitar a un@ amigo@`,
    "INVITE_FRIEND_SENT_EMAIL": `Tu invitación a <span class="font-black">{FRIEND_EMAIL}</span> ha sido enviada correctamente.`,
    "INVITE_FRIEND_SENT_DATEBOX": `Recuerda que para poder beneficiarte de la oferta, <span class="font-black">tu amigo/a debe matricularse antes del {DATE}.</span>
`,
    "Podrás elegir uno de los dos beneficios:": `Podrás elegir uno de los dos beneficios:`,
    "INVITE_FRIEND_SENT_HOODIE": `<span class='font-black'>Sudadera</span> exclusiva ILERNA.`,
    "INVITE_FRIEND_SENT_DTO_PREMIUM": `<span class='font-black'>Descuento -{DTO_PERCENTAGE}%</span> en el servicio Premium <span class='t-line-through'>{OLD_PRICE}€</span> {NEW_PRICE}€.`,
    "INVITE_FRIEND_GIFT_PENDING_EMAIL": `Tu amigo/a <span class='font-black'>{EMAIL}</span> se ha matriculado en ILERNA. Ahora ya puedes seleccionar uno de los siguientes beneficios.`,
    "Selecciona un beneficio": `Selecciona un beneficio`,
    "Sudadera exclusiva ILERNA": `Sudadera exclusiva ILERNA`,
    "Selecciona una talla": `Selecciona una talla`,
    "Consultar la guía de tallas": `Consultar la guía de tallas`,
    "Indica la dirección de envío": `Indica la dirección de envío`,
    "Solicitar sudadera": `Solicitar sudadera`,
    "Talla": `Talla`,
    "Ancho": `Ancho`,
    "Largo": `Largo`,
    "INVITE_FRIEND_GIFT_PENDING_PREMIUM_TITLE": `Descuento -{DTO_PERCENTAGE}% en servicio Premium`,
    "Ser alumn@ Premium son todo ventajas": `Ser alumn@ Premium son todo ventajas`,
    "Libros gratis": `Libros gratis`,
    "Atención PREMIUM": `Atención PREMIUM`,
    "Recibirás gratis los libros en formato papel": `Recibirás gratis los libros en formato papel`,
    "Tendrás a tu disposición un Gestor que te hará un seguimiento personalizado durante todo tu semestre y con quien podrás contactar vía Whatsapp. Te recordará fechas importantes, te comunicará todas las novedades y podrás trasladarle cualquier duda que tengas": `Tendrás a tu disposición un Gestor que te hará un seguimiento personalizado durante todo tu semestre y con quien podrás contactar vía Whatsapp. Te recordará fechas importantes, te comunicará todas las novedades y podrás trasladarle cualquier duda que tengas`,
    "Acceso inmediato al Campus Virtual": `Acceso inmediato al Campus Virtual`,
    "Desde el momento en que te matricules en la modalidad PREMIUM, tendrás acceso directo al Campus Virtual y al material didáctico de tus asignaturas. De esta forma tendrás la posibilidad de avanzar en el estudio de tus asignaturas antes del inicio de clases": `Desde el momento en que te matricules en la modalidad PREMIUM, tendrás acceso directo al Campus Virtual y al material didáctico de tus asignaturas. De esta forma tendrás la posibilidad de avanzar en el estudio de tus asignaturas antes del inicio de clases`,
    "INVITE_FRIEND_GIFT_PENDING_PREMIUM_DISCOUNT": `<span class="font-black">Precio del servicio Premium:</span> <span class="t-line-through">{OLD_PRICE}€</span> (-{DTO_PERCENTAGE}%) <span class="font-black">{NEW_PRICE}€</span> (servicio renovable en cada semestre).`,
    "INVITE_FRIEND_GIFT_PENDING_PREMIUM_DATE": `El descuento es válido hasta el {DATE}`,
    "Solicitar descuento premium": `Solicitar descuento premium`,
    "Confirma tu solicitud": `Confirma tu solicitud`,
    "INVITE_FRIEND_GIFT_PENDING_PREMIUM_MODAL": `{DTO_PERCENTAGE}% de descuento en el servicio Premium`,
    "INVITE_FRIEND_HOODIE_TEXT": `En breve recibirás la <span class='font-black'>sudadera</span> que has solicitado en la siguiente dirección`,
    "Ya has invitado a un amig@": `Ya has invitado a un amig@`,
    "Muchas gracias": `Muchas gracias`,
    "INVITE_FRIEND_PREMIUM_PENDING": `Cuando realices la compra del servicio Premium te aplicaremos el {DTO_PERCENTAGE}% de descuento`,
    "INVITE_FRIEND_PREMIUM_PENDING_DATE": `El descuento es válido hasta el {DATE}`,
    "comprar servicio premium": `comprar servicio premium`,
    "Te has beneficiado del descuento en el servicio Premium": `Te has beneficiado del descuento en el servicio Premium`,
    "Hola,": `Hola,`,
    "Comunicados": `Comunicados`,
    "Confirmar solicitud": `Confirmar solicitud`,
    "¡INVITAR A AMIGO/A!": `¡INVITAR A AMIGO/A!`,
    "Por favor, lee y acepta las condiciones de la promoción.": `Por favor, lee y acepta las condiciones de la promoción.`,
    "Guía de tallas": `Guía de tallas`,
    "INVITE_FRIEND_MODAL_PENDING_CONDITIONS": `<h2 class="t-center"><a class="bases-underline"><span class="font-20">BASES LEGALES DEL</span></a></h2>
                        <h2 class="t-center sm-m-top"><a class="bases-underline"><span class="font-20">“BLACKFRIDAY 2021 A DISTANCIA“</span></a></h2>

                        <h3 class="font-heavy f-w-700"><span>PRIMERA.- Organizador</span></h3>
                        <p>ILERNA ONLINE, S.L. (en adelante, “ILERNA ONLINE”)</p>
                        <p><span class="font-heavy f-w-700">CIF:</span> B – 25774720</p>
                        <p><span class="font-heavy f-w-700">Domicilio social:</span> Turó de Gardeny. Complex Magical Media, Edifici 5, 25003, Lleida.</p>
                        <p><span class="font-heavy f-w-700">Correo electrónico de contacto:</span>&nbsp;<a href="mailto:online@ilerna.com">online@ilerna.com</a></p>

                        <h3 class="font-heavy f-w-700"><span>SEGUNDA.- Objeto de la promoción</span></h3>
                        <p>ILERNA ONLINE lanza una promoción bajo el nombre “<span class="font-heavy f-w-700">BLACK FRIDAY 2021 – A DISTANCIA</span>” (en adelante, “<span class="font-heavy  f-w-700">la Promoción</span>”), a través de la cual, ofrecerá a sus usuarios la posibilidad de beneficiarse de un <u>descuento directo</u> en las matrículas de los <i>ciclos FP <u>a distancia</u></i> de ILERNA ONLINE que se realicen en cumplimiento de las presentes Bases Legales.</p>
                        <p>La Promoción será de aplicación únicamente para aquellas matrículas que se realicen sobre dos o más asignaturas del mismo ciclo FP a distancia, según las condiciones establecidas en el presente documento. El descuento objeto de la Promoción será variable en función del número de asignaturas en las que el usuario se matricule, así como en función de las fechas en que se realice el pago, tal y como se describe en el apartado seis.</p>
                        <p>Quedan expresamente <u>excluidas</u> de la presente Promoción las matrículas que se realicen para el ciclo FP a distancia de <i>Farmacia y Parafarmacia</i> y <i>Emergencias Sanitarias</i>. También quedarán excluidas y no serán objeto de descuento, con independencia del <i>ciclo FP a distancia</i> de que se trate, las asignaturas relativas a la <i>Formación en Centros de Trabajo</i> (prácticas) y al <i> Trabajo Final de Ciclo <a class="font-heavy f-w-700"> (Proyecto, Síntesis o Empresa en el aula, según el ciclo FP de que se trate)</a></i>. Tampoco quedarán incluidas en la Promoción cualesquiera otros productos ofrecidos por ILERNA ONLINE, tales como, entre otros, las <i>convalidaciones de asignaturas</i> y el <i>material didáctico</i> que se pudiera adquirir.</p>
                        <p>La presente Promoción será aplicable únicamente para los ciclos FP de ILERNA ONLINE que se imparten 100% <i>a distancia</i>, de tal manera que quedan expresamente excluidas las matrículas que se realicen tanto para la <i>modalidad</i> <i>presencial, </i>como para la <i>modalidad híbrida</i> de ILERNA (centros en Lleida, <ins cite="mailto:Helena%20Tellez" dateTime="2021-11-08T15:36"> </ins> Madrid y Sevilla).</p>
                        <p>Además de lo anterior, los usuarios que, en cumplimiento de las presentes Bases Legales, resulten beneficiarios de la Promoción y, tal y como se señala en el apartado quinto, aporten a ILERNA ONLINE un alumno nuevo (no matriculado en ocasiones anteriores) podrán escoger un regalo adicional, consistente en (i) la  aplicación de un descuento del 25% en el Servicio Premium de ILERNA ONLINE o (ii) una sudadera. En adelante, este supuesto concreto de la Promoción <ins cite="mailto:Helena%20Tellez" dateTime="2021-11-08T17:08"> </ins> será llamado <span class="font-heavy f-w-700">“INVITA A UN AMIGO”.</span></p>
                        <p>La Promoción no será acumulable a cualesquiera otros descuentos, promociones u ofertas ofrecidos por ILERNA ONLINE o por cualesquiera de sus colaboradores. No obstante, ILERNA ONLINE se reserva el derecho de lanzar otras promociones durante este mismo período de tiempo, ya se encuentren dentro de su campaña global “BLACK FRIDAY21” o no. Estas promociones no serán acumulables en ningún caso, salvo que se indique lo contrario en las bases legales correspondientes.</p>

                        <h3 class="font-heavy f-w-700"><span>TERCERA.- Ámbito temporal</span></h3>
                        <p>La Promoción será válida únicamente para aquellas matrículas que, en cumplimiento de estas Bases Legales, se realicen en las siguientes fechas:</p>
                        <div class="doted-list no-padding-left no-text-indent">
                            <ul>
                                <li class="p-bottom">Viernes 12 de noviembre de 2021.</li>
                                <li class="p-bottom">Viernes 19 de noviembre de 2021.</li>
                                <li class="p-bottom">Entre el 22 y el 26 de noviembre de 2021, ambos incluidos.</li>
                            </ul>
                        </div>
                        <p>En adelante, “las Fechas Promocionales”.</p>
                        <p>ILERNA ONLINE se reserva el derecho a ampliar las Fechas Promocionales, en cuyo caso se dará dicha ampliación en la misma publicidad que a las presentes.</p>

                        <h3><span>CUARTA.- Requisitos de participación</span></h3>
                        <p>Podrán participar en la Promoción todas aquellas personas físicas, mayores de 18 años, no incapacitadas judicialmente y provistas de DNI, NIE o Pasaporte válido y en vigor, siempre y cuando cumplan con el resto de los requisitos establecidos en las presentes Bases Legales.</p>
                        <p>Podrán beneficiarse de esta promoción tanto los alumnos actuales de ILERNA ONLINE, como los que realizan por primera vez su matrícula. No obstante, no podrán participar en la Promoción aquellas personas que, durante las Fechas Promocionales se hallen y/o hayan prestado servicios mediante relación laboral para ILERNA ONLINE.</p>
                        <p>Todos aquellos participantes que cumplan los requisitos establecidos anteriormente, así como el resto de las presentes Bases Legales y las Condiciones Generales de Venta de ILERNA ONLNE, obtendrán el descuento objeto de la Promoción.</p>
                        <p>El usuario, dentro de las Fechas Promocionales, únicamente podrá participar en la Promoción una vez por semana.</p>
                        <p>En cualquier caso, el alumno se beneficiará de esta Promoción a título individual y supondrá la aceptación íntegra de las presentes Bases Legales. En el supuesto de que alguna de las personas participantes no cumpliera con los requisitos establecidos en las presentes Bases Legales, no podrá resultar beneficiaria del descuento objeto de la Promoción.</p>

                        <h3><span>QUINTA.- Mecánica de participación. </span></h3>
                        <p>Todas aquellas personas que cumplan con los requisitos de participación deberán cumplir, asimismo, las siguientes condiciones <u>acumulativas</u>, para poder beneficiarse de la Promoción:</p>
                        <ol>
                            <li>Realizar su pedido de matrícula en alguna de las Fechas Promocionales, a través de:</li>
                            <ol type="a">
                                <li>La página web de ILERNA ONLINE: <a href="http://www.ilerna.es">www.ilerna.es</a></li>
                                <li>El número de teléfono: 900 730 222, dentro del horario laboral establecido la página web de ILERNA ONLINE www.ilerna.es*</li>
                            </ol>
                            <p>* Fuera de este horario no serán atendidas las llamadas realizadas.</p>
                            <p>En relación con la presente Promoción, se informa expresamente de que las contrataciones para los ciclos FP a distancia de ILERNA ONLINE <u>no</u> pueden realizarse de forma presencial, siendo únicamente válidas las dos vías anteriores.</p>
                            <li>Incluir en el mismo pedido <u>dos (2) o más asignaturas</u> de alguno de los <i>ciclos de FP <u>a distancia</u></i> que ofrece ILERNA ONLINE. Y ello, con independencia de las asignaturas de <span class="font-heavy f-w-700">(i)</span> Formación en Centros de Trabajo (prácticas) y <span class="font-heavy f-w-700">(ii)</span> <i>Trabajo final de Ciclo (Proyecto, Síntesis o Empresa en el aula, según el ciclo FP del que se trate)</i>, que se encuentran excluidas de la Promoción y, por tanto, no serán tenidas en cuenta para este cómputo.</li>
                            <li>En cualquier caso, las asignaturas <span class="font-heavy f-w-700">deberán pertenecer al <u>mismo ciclo FP</u>, no siendo aplicable el descuento objeto de la Promoción para aquellos pedidos que incluyan asignaturas de ciclos diferentes</span>.</li>
                            <li>Independientemente de la forma en que se realice el pedido (página web o llamada telefónica), el descuento se aplicará de forma automática antes de formalizar el pago, <span class="font-heavy f-w-700">sin necesidad de que el usuario lleve a cabo ninguna acciónexpresa.</span></li>
                            <li>Cumplir con el resto de los requisitos y condiciones establecidos en las presentes Bases Legales.</li>
                            <li>Cumplir con las Condiciones Generales de Venta de ILERNA ONLINE y, en particular, con los <u>requisitos académicos</u> necesarios para cursar el ciclo de FP en el que se realice la matrícula. Para más información, CONSULTE LAS CONDICIONES DE VENTA DE ILERNA ONLINE EN <a href="https://www.ilerna.es">WWW.ILERNA.ES</a></li>
                            <p>La presente Promoción no es compatible con ningún otro descuento, promoción u oferta ofrecido por ILERNA ONLINE.</p>
                            <p>Además de lo anterior, los usuarios que, en cumplimiento de las presentes Bases Legales, resulten beneficiarios de la Promoción, podrán también beneficiarse del <span class="font-heavy f-w-700">INVITA A UN AMIGO</span> siempre y cuando (además de los requisitos anteriores) se dé la siguiente situación:</p>
                            <li>Una vez  que el usuario ya matriculado con ILERNA ONLINE, en cumplimiento de las presentes bases legales, y siempre dentro de las Fechas Promocionales, recibirá un email en el que se le planteará la opción de facilitar el correo electrónico de un amigo (en adelante, “el Amigo”) que pueda estar interesado en cursar alguno de los <i>ciclos FP a distancia ofrecidos en</i> ILERNA Online. (El usuario deberá seguir los pasos indicados en tal email para proporcionar el contacto de su amigo</li>
                            <li>A partir de dicha información, ILERNA ONLINE se pondrá en contacto con  “el Amigo” por si éste quisiera llevar a cabo la contratación de la matrícula correspondiente.</li>
                            <li>Si finalmente el Amigo formalizara con ILERNA ONLINE, todavía dentro de las Fechas Promocionales la contratación de una matrícula en uno de los ciclos <i>FP a distancia, </i>ofrecidos en virtud de las presentes Bases Legales<i>, </i>el usuario beneficiario de la Promoción será asimismo beneficiario del regalo <span class="font-heavy f-w-700">INVITA A UN AMIGO</span>. En cualquier caso, la contratación realizada por parte del Amigo deberá cumplir con los siguientes requisitos acumulativos:</li>
                            <div class="doted-list no-padding-left no-text-indent">
                                <ul>
                                    <li class="p-bottom">La contratación del Amigo deberá realizarse, también, en completo cumplimiento de las presentes Bases Legales. Es decir, únicamente serán válidas aquellas
                                        contrataciones de Amigos que se realicen <span class="font-heavy f-w-700">(i)</span> sobre alguno de los <i>cursos FP a distancia</i> admitidos en el presente documento, <span class="font-heavy f-w-700">(ii)</span> dentro de las Fechas
                                        Promocionales y <span class="font-heavy f-w-700">(iii)</span> con el respeto debido a los Requisitos y Mecánica de Participación expuestos, así como al resto de disposiciones contenidas en estas Bases Legales.</li>
                                    <li class="p-bottom">El Amigo deberá ser alumno nuevo de ILERNA ONLINE; de tal forma que no se admiten Amigos que sean o hayan sido alumnos de ILERNA ONLINE. </li>
                                </ul>
                            </div>
                            <li> Una vez el Amigo resulte matriculado de forma efectiva con ILERNA ONLINE, el usuario beneficiario de la Promoción recibirá un mensaje informándole de tal contratación, así como de las opciones ofrecidas por el <span class="font-heavy f-w-700">INVITA A UN AMIGO</span>, sus condiciones y pasos a seguir para obtenerlo. El usuario deberá seguir las instrucciones expuestas en ese mensaje y elegir una de las opciones siempre antes de la finalización de las Fechas Promocionales.  </li>
                            <p>Una vez transcurridas las Fechas Promocionales de la Promoción, la opción del regalo <span class="font-heavy f-w-700">INVITA A UN AMIGO</span> quedará asimismo finalizada.</p>
                            <li>Las condiciones y reglas concretas del regalo <span class="font-heavy f-w-700">INVITA A UN AMIGO</span> quedan reguladas en el apartado siguiente.</li>
                            <p>El regalo <span class="font-heavy f-w-700">INVITA A UN AMIGO</span> tampoco será compatible con ningún otro descuento, promoción u oferta ofrecido por ILERNA ONLINE en relación con los mismos productos y servicios que abarca el regalo ofrecido, salvo disposición expresa en contrario por parte de ILERNA ONLINE.</p>
                        </ol>

                        <h3><span>SEXTA.- Descuento</span></h3>
                        <p>La presente Promoción tiene por objeto la aplicación de un descuento directo para todas aquellas matrículas que se hayan realizado en cumplimiento de las presentes Bases Legales:</p>
                        <div class="doted-list no-padding-left no-text-indent">
                            <ul>
                                <li class="p-bottom">El viernes 12 de noviembre de 2021 se aplicará un descuento directo de <span class="font-heavy f-w-700">(i) </span>65€
                                    en la matriculación de 2 asignaturas, <span class="font-heavy f-w-700">(ii) </span>80€ en la matriculación de 3
                                    asignaturas y <span class="font-heavy f-w-700">(iii) </span>130€ en la matriculación de 4 o más asignaturas;
                                    siempre que éstas se encuentren en un mismo pedido y, entre otras condiciones,
                                    se refieran al mismo ciclo FP.
                                </li>
                                <li class="p-bottom">El viernes 19 de noviembre de 2021 se aplicará un descuento directo de <span class="font-heavy f-w-700">(i) </span>55€
                                    en la matriculación de 2 asignaturas, <span class="font-heavy f-w-700">(ii) </span>70€ en la matriculación de 3
                                    asignaturas y <span class="font-heavy f-w-700">(iii) </span>115€ en la matriculación de 4 o más asignaturas;
                                    siempre que éstas se encuentren en un mismo pedido y, entre otras condiciones,
                                    se refieran al mismo ciclo FP.
                                </li>
                                <li class="p-bottom">
                                    El periodo del 22 al 26 de noviembre de 2021 (ambos incluidos) se aplicará un
                                    descuento de <span class="font-heavy f-w-700">(i) </span>50€ en la matriculación de 2 asignaturas, <span class="font-heavy f-w-700">(ii) </span>60€
                                    en la matriculación de 3 asignaturas y <span class="font-heavy f-w-700">(iii) </span>105€ en la matriculación de
                                    4 o más asignaturas; siempre que éstas se encuentren en un mismo pedido y,
                                    entre otras condiciones, se refieran al mismo ciclo FP.
                                </li>
                            </ul>
                        </div>
                        <p class="t-right">En adelante y de manera conjunta, “los Descuentos”.</p>
                        <p>Asimismo, los Descuentos aplicables a la Promoción se regirán por las siguientes condiciones:</p>
                        <ol type="I">
                            <li>Cada uno de los Descuentos será de aplicación exclusivamente <u>sobre el importe total de las asignaturas seleccionadas</u> (con excepción de las asignaturas de <i>Formación en Centros de Trabajo</i> (prácticas) y <i>Trabajo final de Ciclo</i>, que quedan excluidas de la Promoción).</li>
                            <li>En ningún caso el descuento será válido para el resto de productos y servicios ofrecidos por ILERNA ONLINE, tales como, a mero título ejemplificativo, la solicitud de convalidaciones y la compra de material didáctico.</li>
                            <li>Los Descuentos se aplicarán de forma automática, sin necesidad de que el usuario lleve a cabo ninguna acción de manera expresa, siempre y cuando se hayan cumplido las condiciones expuestas en estas Bases Legales. En caso contrario, el descuento correspondiente no será de aplicación.</li>
                            <li>El descuento correspondiente se realizará a título individual y nominativo a aquellas personas que, en cumplimiento de las presentes Bases Legales, resulten beneficiarias de la Promoción. En ningún caso el descuento podrá ser transferido a ninguna otra persona.</li>
                            <li>Cada usuario se podrá beneficiar de alguno de los Descuentos únicamente una vez por semana dentro de las Fechas Promocionales (es decir, el día 12 de noviembre, el 19 de noviembre, o en el periodo entre el 22 y el 26 de noviembre de 2021).</li>
                            <li>El descuento aplicado en virtud de la presente Promoción <u>no podrá ser canjeable</u>, en ningún caso, por su valor en metálico, ni sustituible por cualquier otro producto o servicio de los ofrecidos por ILERNA ONLINE que excedan de lo expresamente dispuesto en las presentes Bases Legales.</li>
                            <p>En este sentido <span class="font-heavy f-w-700">, ILERNA ONLINE INFORMA EXPRESAMENTE DE QUE NO SE REALIZARÁN BONOS POR EL IMPORTE DESCONTADO PARA SU UTILIZACIÓN FUTURA</span>. En el caso excepcional de la congelación de asignaturas, se realizará un bono al alumno por el importe efectivamente pagado por esa asignatura (es decir, tras la aplicación del descuento). En ningún caso se realizará un bono por el valor del descuento.</p>
                            <li>Para que los Descuentos sean aplicables, además de lo anterior, deberán cumplir con el resto de las condiciones establecidas en las presentes Bases Legales.</li>
                            <p>Además de lo anterior, se deberá tener en cuenta asimismo el regalo <span class="font-heavy f-w-700">INVITA AUN AMIGO</span>. Este regalo, siempre y cuando se cumplan las condiciones señaladas a tal efecto en el apartado quinto, permitirá al usuario beneficiario de la Promoción escoger entre dos regalos adicionales, completamente al margen de los Descuentos de la Promoción, a saber:</p>
                            <div class="doted-list no-padding-left no-text-indent">
                                <ul>
                                    <li class="p-bottom">Un 25% de descuento en el <i>Servicio Premium</i> de ILERNA ONLINE, que beneficia al alumno con el material didáctico físico gratuito, un gestor personalizado y el acceso inmediato al Campus Virtual; en cuyo caso, serán de aplicación las mismas condiciones y reglas que las indicadas en las presentes Bases Legales para la Promoción y sus Descuentos. De esta forma, entre otras cuestiones, el descuento señalado quedará supeditado a la contratación del servicio afectado exclusivamente durante las Fechas Promocionales.</li>
                                </ul>
                            </div>
                            <p>o</p>
                            <div class="doted-list no-padding-left no-text-indent">
                                <ul>
                                    <li class="p-top p-bottom">Una sudadera, que será entregada a la dirección facilitada por el usuario beneficiario de la Promoción en su matrícula. En cualquier caso, ILERNA ONLINE, por sí misma o a través de terceros, mandará un email al beneficiario para informarle cuando el paquete esté en tránsito hacia el destino.</li>
                                </ul>
                            </div>
                        </ol>

                        <h3><span>SÉPTIMA.- Protección de datos de carácter personal</span></h3>
                        <p>De conformidad con la normativa vigente sobre Protección de datos personales, el tratamiento de los datos personales proporcionados para resultar beneficiario de la Promoción se regirá por lo dispuesto a continuación:</p>
                        <p><span class="font-heavy f-w-700">Responsable del tratamiento:</span></p>
                        <p><span class="font-heavy f-w-700">Identidad:</span> ILERNA ONLINE, S. L (B25774720)</p>
                        <p><span class="font-heavy f-w-700">Domicilio:</span> Turó de Gardeny, Complex Magical Media, Edifici 5, 25003, Lleida</p>
                        <p><span class="font-heavy f-w-700">Contacto:</span> <a href="mailto:online@ilerna.com">online@ilerna.com</a></p>
                        <p><span class="font-heavy f-w-700">Delegado de Protección de Datos:</span> Lant Abogados (Lant Advisors, S.L.P.); <span class="font-heavy f-w-700">email:</span> ilerna@delegado-datos.com</p>
                        <p><span class="font-heavy f-w-700">Categorías de datos que se van a tratar:</span></p>
                        <p>Datos identificativos, de contacto, académicos y bancarios de los participantes que realicen una matrícula en virtud de los requisitos establecidos en las presentes Bases Legales (entre otros, nombre, apellidos, correo electrónico, teléfono, dirección, DNI, certificados académicos, datos de cuenta bancaria). Todos ellos resultan indispensables para poder llevar a cabo la participación en la Promoción.</p>
                        <p><span class="font-heavy f-w-700">Finalidad del tratamiento:</span></p>
                        <ol>
                            <li>Gestionar la participación en la Promoción.</li>
                            <li>Enviar comunicaciones comerciales periódicamente por medios electrónicos, para informar de las actividades, contenidos y servicios de ILERNA ONLINE solamente en el caso de que nos haya dado su consentimiento expreso para ello.</li>
                            <li>Informar sobre los descuentos de ILERNA ONLINE en el caso de encontrarnos ante un supuesto de <span class="font-heavy f-w-700">INVITA A UN AMIGO</span>.</li>
                        </ol>
                        <p><span class="font-heavy f-w-700">Base legal del tratamiento:</span></p>
                        <p>La base legal que legitima a ILERNA ONLINE a tratar sus datos personales es el consentimiento expreso recabado al realizar el proceso de matrícula. Asimismo, ILERNA ONLINE precisa el tratamiento de los datos personales solicitados para ejecutar las finalidades descritas con anterioridad.</p>
                        <p><span class="font-heavy f-w-700">Conservación de los datos personales:</span></p>
                        <p>Los datos personales de los participantes serán conservados por ILERNA ONLINE durante el tiempo necesario para gestionar la Promoción, así como, en su caso, la ejecución del curso en que el participante se matricule. Una vez finalizado, los datos serán suprimidos de las bases de datos de ILERNA ONLINE. En caso de haber prestado su consentimiento para el envío de comunicaciones comerciales, sus datos serán conservados mientras no conste revocación de su consentimiento.</p>
                        <p><span class="font-heavy f-w-700">Destinatarios y transferencias internacionales:</span></p>
                        <p>ILERNA ONLINE no comunicará los datos personales tratados a ningún tercero, excepto que sea imprescindible para el correcto funcionamiento de la Promoción, o por solicitud administrativa y/o judicial. Tampoco se realizarán transferencias internacionales de datos a terceros países.</p>
                        <p><span class="font-heavy f-w-700">Derechos de los interesados:</span></p>
                        <p>La legislación reconoce una serie de derechos en relación con el tratamiento de sus datos personales. Así, podrá acceder, rectificar, suprimir, limitar u oponerse al tratamiento de sus datos, solicitar la portabilidad y retirar el consentimiento prestado en cualquier momento mediante correo electrónico dirigido a&nbsp;<a href="mailto:online@ilerna.com"> online@ilerna.com</a>&nbsp;o mediante correo postal a Turó de Gardeny, Complex Magical Media, Edifici 5, 25003, Lleida, indicando “DATOS PERSONALES PROMOCIÓN BLACK FRIDAY 2021 – A DISTANCIA” en el “Asunto”. En cualquier caso, también podrá dirigirse a la AEPD en <a href="http://www.aepd.es/es">&nbsp;www.aepd.es/es</a>.</p>

                        <h3><span>OCTAVA.- Contacto</span></h3>
                        <p>Los alumnos que se beneficien de la Promoción podrán contactar con ILERNA ONLINE para cualquier cuestión relacionada con la misma a través del correo electrónico&nbsp;<a href="mailto:online@ilerna.com">online@ilerna.com</a>.</p>

                        <h3><span>NOVENA.- Aceptación de las bases</span></h3>
                        <p>La participación en la Promoción realizada en virtud de las presentes Bases Legales supondrá la aceptación íntegra e irrevocable de las mismas, así como las normas que rigen las Condiciones de Venta de ILERNA en relación con la matriculación en los cursos y la Política de Privacidad y Protección de datos expuestos en la página web de ILERNA ONLINE.</p>
                        <p>Las presentes Bases Legales estarán a disposición de cualquier a través de la página web <a href="http://www.ilerna.es">www.ilerna.es</a>.</p>
                        <p>ILERNA ONLINE se reserva el derecho de modificar o ampliar las Bases Legales de la Promoción, en cuyo caso le dará el mismo grado de publicidad que a las presentes Bases Legales.</p>

                        <h3><span>DÉCIMA.- Ley aplicable y fuero</span></h3>
                        <p>La interpretación y aplicación de las presentes Bases Legales se realizará con arreglo a la legislación española vigente. Cualquier conflicto que se suscite en aplicación de las presentes Bases Legales y/o con ocasión de la promoción se resolverán por los Juzgados y Tribunales que por ley pudiera corresponder al consumidor.</p>
                        <p>En Lleida, 8 de noviembre de 2021.</p>

                        <h2 class="t-center sm-m-top"><a class="bases-underline"><span class="font-20">BASES LEGALES DEL</span></a></h2>
                        <h2 class="t-center sm-m-top"><a class="bases-underline"><span class="font-20">“BLACKFRIDAY 2021 ILERNA HÍBRIDO“</span></a></h2>

                        <h3><span>PRIMERA.-Organizador</span></h3>
                        <p>ILERNA ONLINE, S.L. (en adelante, “ILERNA ONLINE”)</p>
                        <p><span class="font-heavy f-w-700">CIF:</span> B – 25774720</p>
                        <p><span class="font-heavy f-w-700">Domicilio social:</span> Turó de Gardeny. Complex Magical Media, Edifici 5, 25003, Lleida.</p>
                        <p><span class="font-heavy f-w-700">Correo electrónico de contacto:</span>&nbsp;online@ilerna.com</p>

                        <h3><span>SEGUNDA.-Objeto de la promoción</span></h3>
                        <p>ILERNA ONLINE lanza una promoción bajo el nombre <span class="font-heavy f-w-700">“BLACKFRIDAY 2021- HÍBRIDOS”</span>, a través de la cual, ofrecerá a sus usuarios la posibilidad de beneficiarse de un <u>descuento directo</u> en las matrículas de los <i>ciclos FP híbridos</i> de ILERNA ONLINE que se realicen en cumplimiento de las presentes Bases Legales. </p>
                        <p>La presente Promoción será aplicable únicamente para los ciclos FP de ILERNA ONLINE híbridos, de tal manera que quedan expresamente excluidas las matrículas que se realicen tanto para la <i>modalidad</i> <i>online, </i>como para la <i>modalidad presencial</i> de ILERNA (centros en Lleida, Madrid y Sevilla).</p>
                        <p>La Promoción no será acumulable a cualesquiera otros descuentos, promociones u ofertas ofrecidos por ILERNA ONLINE o por cualesquiera de sus colaboradores. No obstante, ILERNA ONLINE se reserva el derecho de lanzar otras promociones durante este mismo período de tiempo, ya se encuentren dentro de su campaña global “BLACK FRIDAY21” o no. Estas promociones no serán acumulables en ningún caso, salvo que se indique lo contrario en las bases legales correspondientes.</p>

                        <h3><span>TERCERA.-Ámbito temporal</span></h3>
                        <p>La Promoción será válida únicamente para aquellas matrículas que, en cumplimiento de estas Bases Legales, se realicen en las siguientes fechas:</p>
                        <div class="doted-list no-padding-left no-text-indent">
                            <ul>
                                <li class="p-bottom">Viernes 12 de noviembre de 2021.</li>
                                <li class="p-bottom">Viernes 19 de noviembre de 2021.</li>
                                <li class="p-bottom">Entre el 22 y el 26 de noviembre de 2021, ambos incluidos.</li>
                            </ul>
                        </div>
                        <p>En adelante, “las Fechas Promocionales”.</p>
                        <p>ILERNA ONLINE se reserva el derecho a ampliar las Fechas Promocionales, en cuyo caso se dará dicha ampliación en la misma publicidad que a las presentes.</p>

                        <h3><span>CUARTA.-Requisitos de participación</span></h3>
                        <p>Podrán participar en la Promoción todas aquellas personas físicas, mayores de 18 años, no incapacitadas judicialmente y provistas de DNI, NIE o Pasaporte válido y en vigor, cuando cumplan con el resto de los requisitos establecidos en las presentes Bases Legales.</p>
                        <p>Podrán beneficiarse de esta promoción tanto los alumnos actuales de ILERNA ONLINE, como los que realizan por primera vez su matrícula. No obstante, no podrán participar en la Promoción aquellas personas que, durante las Fechas Promocionales se hallen y/o hayan prestado servicios mediante relación laboral para ILERNA ONLINE.</p>
                        <p>El usuario, dentro de las Fechas Promocionales, únicamente podrá participar en la Promoción una vez por semana.</p>
                        <p>Todos aquellos participantes que cumplan los requisitos establecidos anteriormente, así como el resto de las presentes Bases Legales y las Condiciones Generales de Venta de ILERNA ONLINE, obtendrán el descuento objeto de la Promoción.</p>
                        <p>En cualquier caso, el alumno se beneficiará de esta promoción a título individual y supondrá la aceptación íntegra de las presentes Bases Legales. En el supuesto de que alguna de las personas participantes no cumpliera con los requisitos establecidos en las presentes Bases Legales, no podrá resultar beneficiaria del descuento objeto de la Promoción.</p>

                        <h3><span>QUINTA.-Mecánica de participación. Plazo</span></h3>
                        <p>Todas aquellas personas que cumplan con los requisitos de participación deberán cumplir, asimismo, las siguientes condiciones <u>acumulativas</u>, para poder beneficiarse de la Promoción:</p>
                        <ol>
                            <li>Realizar su pedido de matrícula en alguna de las Fechas Promocionales, a través de:</li>
                            <ol type="a">
                                <li>La página web de ILERNA ONLINE: <a href="http://www.ilerna.es">www.ilerna.es</a></li>
                                <li>El número de teléfono: 900 730 222(Lleida), 954 041 212 (Sevilla) o 910 841 652 (Madrid) dentro del horario laboral establecido la página web de ILERNA ONLINE <a href="http://www.ilerna.es"> www.ilerna.es*</a></li>
                                <li>En nuestros centros donde se cursan las FP hibridas (Madrid o Sevilla)</li>
                            </ol>
                            <p>* Fuera de este horario no serán atendidas las llamadas realizadas.</p>
                            <li>Independientemente de la forma en que se realice el pedido (página web o llamada telefónica), el descuento se aplicará de forma automática antes de formalizar el pago, sin necesidad de que el usuario lleve a cabo ninguna acción expresa. </li>
                            <li>Cumplir con el resto de los requisitos y condiciones establecidos en las presentes Bases Legales.</li>
                            <li>Cumplir con las Condiciones Generales de Venta de ILERNA ONLINE y, en particular, con los requisitos académicos necesarios para cursar el ciclo de FP en el que se realice la matrícula. Para más información, CONSULTE LAS CONDICIONES DE VENTA DE ILERNA ONLINE EN <a href="https://www.ilerna.es">WWW.ILERNA.ES</a>.</li>
                        </ol>
                        <p>La presente Promoción no es compatible con ningún otro descuento, promoción u oferta ofrecido por ILERNA ONLINE.</p>

                        <h3><span>SEXTA.-Descuento</span></h3>
                        <p>La presente Promoción tiene por objeto la aplicación de un descuento directo para todas aquellas matrículas que se hayan realizado en cumplimiento de las presentes Bases Legales: </p>
                        <div class="doted-list no-padding-left no-text-indent">
                            <ul>
                                <li class="p-bottom">El viernes 12 de noviembre de 2021 se aplicará un descuento directo de <span class="font-heavy f-w-700">200€ </span>en el importe de la matrícula.</li>
                                <li class="p-bottom">El viernes 19 de noviembre de 2021 se aplicará un descuento directo de <span class="font-heavy f-w-700">175€ </span>en el importe de la matrícula.</li>
                                <li class="p-bottom">El periodo de 22 al 26 de noviembre de 2021 se aplicará un descuento directo de <span class="font-heavy f-w-700">150€ </span>en el importe de la matrícula</li>
                            </ul>
                        </div>
                        <p class="t-right">En adelante y de manera conjunta, “los Descuentos”.</p>
                        <p>Asimismo, los Descuentos aplicables a la Promoción se regirán por las siguientes condiciones:</p>
                        <ol type="I">
                            <li>Cada uno de los Descuentos será de aplicación exclusivamente <u>sobre el importe total de la matrícula.</u></li>
                            <li>En ningún caso el descuento será válido para el resto de productos y servicios ofrecidos por ILERNA ONLINE, tales como, a mero título ejemplificativo, la compra de material didáctico.</li>
                            <li>Los Descuentos se aplicarán de forma automática, sin necesidad de que el usuario lleve a cabo ninguna acción de manera expresa, siempre y cuando se hayan cumplido las condiciones expuestas en estas Bases Legales. En caso contrario, el descuento correspondiente no será de aplicación.</li>
                            <li>El descuento correspondiente se realizará a título <u>individual y nominativo</u> a aquellas personas que, en cumplimiento de las presentes Bases Legales, resulten beneficiarias de la Promoción. En ningún caso el descuento podrá ser transferido a ninguna otra persona.</li>
                            <li>Cada usuario se podrá beneficiar de alguno de los Descuentos únicamente una vez por semana dentro de las Fechas Promocionales (es decir, el día 12 de noviembre, el 19 de noviembre, o en el periodo entre el 22 y el 26 de noviembre de 2021).</li>
                            <li>El descuento aplicado en virtud de la presente Promoción <u>no podrá ser canjeable</u>, en ningún caso, por su valor en metálico, ni sustituible por cualquier otro producto o servicio de los ofrecidos por ILERNA ONLINE que excedan de lo expresamente dispuesto en las presentes Bases Legales.</li>
                        </ol>
                        <p>En este sentido <span class="font-heavy f-w-700">, ILERNA ONLINE INFORMA EXPRESAMENTE DE QUE NO SE REALIZARÁN BONOS POR EL IMPORTE DESCONTADO PARA SU UTILIZACIÓN FUTURA</span>.</p>
                        <p>Para que los Descuentos sean aplicables, además de lo anterior, deberán cumplir con el resto de las condiciones establecidas en las presentes Bases Legales.</p>

                        <h3><span>SÉPTIMA - Protección de datos de carácter personal</span></h3>
                        <p>De conformidad con la normativa vigente sobre Protección de datos personales, el tratamiento de los datos personales proporcionados para resultar beneficiario de la Promoción se regirá por lo dispuesto a continuación:</p>
                        <p><span class="font-heavy f-w-700">Responsable del tratamiento:</span></p>
                        <p><span class="font-heavy f-w-700">Identidad:</span> ILERNA ONLINE, S. L (B25774720)</p>
                        <p><span class="font-heavy f-w-700">Domicilio:</span>Turó de Gardeny, Complex Magical Media, Edifici 5, 25003, Lleida</p>
                        <p><span class="font-heavy f-w-700">Contacto:</span> <a href="mailto:online@ilerna.com">online@ilerna.com</a></p>
                        <p>Delegado de Protección de Datos: Lant Abogados (Lant Advisors, S.L.P.); email: ilerna@delegado-datos.com</p>
                        <p><span class="font-heavy f-w-700">Categorías de datos que se van a tratar: </span></p>
                        <p>Datos identificativos, de contacto, académicos y bancarios de los participantes que realicen una matrícula en virtud de los requisitos establecidos en las presentes Bases Legales (entre otros, nombre, apellidos, correo electrónico, teléfono, dirección, DNI, certificados académicos, datos de cuenta bancaria). Todos ellos resultan indispensables para poder llevar a cabo la participación en la Promoción.</p>
                        <p><span class="font-heavy f-w-700">Finalidad del tratamiento:</span></p>
                        <ol>
                            <li>Gestionar la participación en la Promoción.</li>
                            <li>Enviar comunicaciones comerciales periódicamente por medios electrónicos, para informar de las actividades, contenidos y servicios de ILERNA ONLINE solamente en el caso de que nos haya dado su consentimiento expreso para ello.</li>
                        </ol>
                        <p><span class="font-heavy f-w-700">Base legal del tratamiento:</span></p>
                        <p>La base legal que legitima a ILERNA ONLINE a tratar sus datos personales es el consentimiento expreso recabado al realizar el proceso de matrícula. Asimismo, ILERNA ONLINE precisa el tratamiento de los datos personales solicitados para ejecutar las finalidades descritas con anterioridad.</p>
                        <p><span class="font-heavy f-w-700">Conservación de los datos personales:</span></p>
                        <p>Los datos personales de los participantes serán conservados por ILERNA ONLINE durante el tiempo necesario para gestionar la Promoción, así como, en su caso, la ejecución del curso en que el participante se matricule. Una vez finalizado, los datos serán suprimidos de las bases de datos de ILERNA ONLINE. En caso de haber prestado su consentimiento para el envío de comunicaciones comerciales, sus datos serán conservados mientras no conste revocación de su consentimiento.</p>
                        <p><span class="font-heavy f-w-700">Destinatarios y transferencias internacionales:</span></p>
                        <p>ILERNA ONLINE no comunicará los datos personales tratados a ningún tercero, excepto que sea imprescindible para el correcto funcionamiento de la Promoción, o por solicitud administrativa y/o judicial. Tampoco se realizarán transferencias internacionales de datos a terceros países.</p>
                        <p><span class="font-heavy f-w-700">Derechos de los interesados:</span></p>
                        <p>La legislación reconoce una serie de derechos en relación con el tratamiento de sus datos personales. Así, podrá acceder, rectificar, suprimir, limitar u oponerse al tratamiento de sus datos, solicitar la portabilidad y retirar el consentimiento prestado en cualquier momento mediante correo electrónico dirigido a&nbsp;online@ilerna.com&nbsp;o mediante correo postal a Turó de Gardeny, Complex Magical Media, Edifici 5, 25003, Lleida, indicando “DATOS PERSONALES PROMOCIÓN  BLACKFRIDAY 2021” en el “Asunto”. En cualquier caso, también podrá dirigirse a la AEPD en<a href="http://www.aepd.es/es">&nbsp;www.aepd.es/es</a>.</p>

                        <h3><span>OCTAVA.-Contacto</span></h3>
                        <p>Los alumnos que se beneficien de la Promoción podrán contactar con ILERNA ONLINE para cualquier cuestión relacionada con la misma a través del correo electrónico&nbsp; <a href="mailto:online@ilerna.com">online@ilerna.com</a>.</p>

                        <h3><span>NOVENA.- Aceptación de las bases</span></h3>
                        <p>La participación en la Promoción realizada en virtud de las presentes Bases Legales supondrá la aceptación íntegra e irrevocable de las mismas, así como las normas que rigen las Condiciones de Venta de ILERNA en relación con la matriculación en los cursos y la Política de Privacidad y Protección de datos expuestos en la página web de ILERNA ONLINE.</p>
                        <p>Las presentes Bases Legales estarán a disposición de cualquier a través de la página web <a href="http://www.ilerna.es">www.ilerna.es</a>.</p>
                        <p>ILERNA ONLINE se reserva el derecho de modificar o ampliar las Bases Legales de la Promoción, en cuyo caso le dará el mismo grado de publicidad que a las presentes Bases Legales.</p>

                        <h3><span>DÉCIMA.-Ley aplicable y fuero</span></h3>
                        <p>La interpretación y aplicación de las presentes Bases Legales se realizará con arreglo a la legislación española vigente. Cualquier conflicto que se suscite en aplicación de las presentes Bases Legales y/o con ocasión de la promoción se resolverán por los Juzgados y Tribunales que por ley pudiera corresponder al consumidor.</p>
                        <p>En Lleida, 8 de noviembre de 2021.</p>`,
    "Una vez se matricule, podrás solicitar tu Sudadera Online": `Una vez se matricule, podrás solicitar tu Sudadera Online`,
    "Invitar a un amigo": `Invitar a un amigo`,
    "Página web de ILERNA": `Página web de ILERNA`,
    "Contraseña": `Contraseña`,
    "Datos de acceso": `Datos de acceso`,
    "Válido hasta": `Válido hasta`,
    "Número de documento": `Número de documento`,
    "Configuración de la cuenta": `Configuración de la cuenta`,
    "créditos": `créditos`,
    "nota": `nota`,
    "unidades formativas": `unidades formativas`,
    "Notificaciones": `Notificaciones`,
    "Configurar": `Configurar`,
    "Configura cómo quieres recibir las noticias y avisos relacionados con tu formación": `Configura cómo quieres recibir las noticias y avisos relacionados con tu formación`,
    "Cambiar": `Cambiar`,
    "Día": `Día`,
    "Mes": `Mes`,
    "Año": `Año`,
    "Informe de vida laboral": `Informe de vida laboral`,
    "Adjunta una copia de tu informe de vida laboral. Si te encuentras en situación de desempleo, adjunta una copia de tu certificado de demanda de empleo": `Adjunta una copia de tu informe de vida laboral. Si te encuentras en situación de desempleo, adjunta una copia de tu certificado de demanda de empleo`,
    "Si todavía no te has dado de alta en la Seguridad Social, no deberás aportar esta documentación": `Si todavía no te has dado de alta en la Seguridad Social, no deberás aportar esta documentación`,
    "other_docs_1": `Adjunta una imagen del <strong>certificado de demanda de empleo</strong>`,
    "other_docs_2": `Adjunta una imagen del <strong>informe de vida laboral</strong>`,
    "Certificado de empadronamiento": `Certificado de empadronamiento`,
    "other_docs_3": `Adjunta una imagen del <strong>certificado de empadronamiento</strong>`,
    "Publicado": `Publicado`,
    "payment_tax": ``,
    "student_requirement_1": ``,
    "student_requirement_2": ``,
    "student_requirement_3": ``,
    "student_requirement_4": ``,
    "student_technical_requirement_1": ``,
    "student_technical_requirement_2": ``,
    "send_copy_1": ``,
    "send_copy_2": ``,
    "signature_page_terms": ``,
    "Provincia": ``,
    "Cambiar dirección": `Cambiar dirección`,
    "Cambiar contraseña": `Cambiar contraseña`,
    "La nueva contraseña debe tener al menos 8 caracteres": `La nueva contraseña debe tener al menos 8 caracteres`,
    "Repite nueva contraseña": `Repite nueva contraseña`,
    "course_access_france": `---`,
    "Solicitar regalo": `Solicitar regalo`,
    "exams_terms_accept": `Antes de inscribirte a los exámenes, <span class='t-underline'>lee y acepta la <strong>normativa de asistencia</strong> y la <strong>declaración responsable.</strong></span>`,
    "No tienes ningún examen programado": `No tienes ningún examen programado`,
    "Algunas sedes de examen todavía no tienen la dirección definitiva. Puedes inscribirte en ellas con normalidad, y te comunicaremos la dirección cuando la hayamos concretado": `Algunas sedes de examen todavía no tienen la dirección definitiva. Puedes inscribirte en ellas con normalidad, y te comunicaremos la dirección cuando la hayamos concretado`,
    "Te has inscrito correctamente al examen": `Te has inscrito correctamente al examen`,
    "Inscripción a examen": `Inscripción a examen`,
    "Sede del examen": `Sede del examen`,
    "Selecciona una opción": `Selecciona una opción`,
    "Seleccionar una sede": `Seleccionar una sede`,
    "Dirección no disponible": `Dirección no disponible`,
    "Guardar Selección": `Guardar Selección`,
    "Modificar inscripción": `Modificar inscripción`,
    "Imprimir justificante": `Imprimir justificante`,
    "Ya te has inscrito a un examen en esta franja horaria": `Ya te has inscrito a un examen en esta franja horaria`,
    "Reservar Plaza": `Reservar Plaza`,
    "Inscripciones pendientes": `Inscripciones pendientes`,
    "Próximos": `Próximos`,
    "Pasados": `Pasados`,
    "Expediente académico": `Expediente académico`,
    "completado": `completado`,
    "Módulos profesionales": `Módulos profesionales`,
    "Convalidada": `Convalidada`,
    "Imprimir expediente": `Imprimir expediente`,
    "Justificante de asistencia a exámenes": `Justificante de asistencia a exámenes`,
    "Exportar justificante": `Exportar justificante`,
    "Selecciona el idioma del justificante": `Selecciona el idioma del justificante`,
    "Descargar": `Descargar`,
    "Castellano": `Castellano`,
    "Catalán": `Catalán`,
    "Exportar certificado": `Exportar certificado`,
    "Selecciona el idioma del certificado": `Selecciona el idioma del certificado`,
    "Cálculo de la nota de las Unidades Formativas (LOE)": `Cálculo de la nota de las Unidades Formativas (LOE)`,
    "terms_loe_cat_1": `La calificación final de las <strong>Unidades Formativas</strong> es un número entero del 1 al 10 <strong>sin decimales</strong> ajustándose por defecto, hasta 49 centésimas, al número entero inferior y, por exceso, a partir de 50 centésimas al número entero superior.<br /><br />Se considera aprobada la Unidad Formativa si la calificación es  igual o superior a 5 y suspensa si es inferior a 5.<br /><br />Las Unidades formativas y los módulos profesionales aprobados mantienen la calificación. No se puede aplicar ninguna medida para modificar una calificación positiva otorgada; es decir, no se pueden repetir unidades formativas ya superadas para mejorar la calificación.`,
    "Cálculo de la nota de los Módulos profesionales (LOE)": `Cálculo de la nota de los Módulos profesionales (LOE)`,
    "terms_loe_cat_2": `La calificación final del <strong>Módulo Profesional</strong> se obtiene de la media ponderada de las calificaciones finales de cada Unidad Formativa, es decir en función del peso (número de horas) de cada Unidad Formativa dentro del módulo. Se considera aprobado el módulo profesional si la calificación es  igual o superior a 5 y suspensa si es inferior a 5.<br /><br />La nota final del Módulo Profesional es un número entero del 1 al 10 <strong>sin decimales</strong> ajustándose por defecto, hasta 49 centésimas, al número entero inferior y, por exceso, a partir de 50 centésimas al número entero superior.`,
    "Cálculo de la nota final del ciclo formativo (LOE)": `Cálculo de la nota final del ciclo formativo (LOE)`,
    "terms_loe_cat_3": `Para poder superar el ciclo formativo se requiere la superación de todos los módulos profesionales que lo componen. La calificación final del ciclo formativo se expresa <strong>con dos decimales</strong> y se obtiene de la media ponderada de las calificaciones finales de los módulos profesionales que componen el ciclo formativo. <br /><br />Los módulos calificados como <strong>“CONVALIDADO (CV)”</strong> no computan en el cálculo de la nota final a excepción de aquellos casos en los que la convalidación si conlleva una nota asignada.<br /> Los módulos calificados como <strong>“APTO” o “EXENTO”</strong> no computan en el cálculo de la nota final.`,
    "Cálculo de la nota final de los módulos/créditos": `Cálculo de la nota final de los módulos/créditos`,
    "terms_logse_cat_1": `La calificación final del <strong>Módulo profesional</strong> es un número entero del 1 al 10 <strong>sin decimales</strong> ajustándose por defecto, hasta 49 centésimas, al número entero inferior y, por exceso, a partir de 50 centésimas al número entero superior.  Se considera aprobado un módulo  si la calificación es  igual o superior a 5 y suspensa si es inferior a 5.<br /><br />Para los módulos que engloban dos o más créditos la nota del módulo se calculará aplicando una media ponderada de la calificación obtenida en los diferentes créditos que lo componen.<br /><br />Los créditos aprobados mantienen la calificación. No se puede aplicar ninguna medida para modificar una calificación positiva otorgada; es decir, no se pueden repetir créditos ya superados para mejorar una calificación ni se pueden establecer otras fórmulas para incrementar una calificación positiva.`,
    "Cálculo de la nota final del ciclo formativo (LOGSE)": `Cálculo de la nota final del ciclo formativo (LOGSE)`,
    "terms_logse_cat_2": `La calificación final del ciclo se expresa con dos cifras decimales. Esta calificación es la media ponderada sin decimales de todos aquellos módulos que tienen una calificación numérica. Debemos recordar que la media ponderada se obtiene teniendo en cuenta el peso que cada módulo tiene sobre el ciclo.<br /><br />En consecuencia, en el cálculo de la calificación final del ciclo no se tienen en cuenta las calificaciones de <strong>“APTO”, “EXENTO” o “CONVALIDADO (CV)*”</strong>.<br /><br />*  A excepción de aquellos casos en los que la convalidación si conlleva una nota asignada.`,
    "Cálculo de la nota final de los módulos/créditos": `Cálculo de la nota final de los módulos/créditos`,
    "terms_mad_1": `La calificación final del <strong>Módulo profesional</strong> es un número entero del 1 al 10 <strong>sin decimales</strong>  ajustándose por defecto, hasta 49 centésimas, al número entero inferior y, por exceso, a partir de 50 centésimas al número entero superior.  Se considera aprobado un módulo  si la calificación es  igual o superior a 5 y suspensa si es inferior a 5.`,
    "Cálculo de la nota final del ciclo formativo": `Cálculo de la nota final del ciclo formativo
`,
    "terms_mad_2": `Una vez superados todos los módulos profesionales que constituyen el ciclo formativo, se determinará la calificación final del mismo. Se calculará la <strong>media aritmética simple</strong> de las calificaciones de los módulos profesionales que tienen valoración numérica.<br /><br />Del resultado se tomará la parte entera y las dos primeras cifras decimales, redondeando por exceso la cifra de las centésimas si la de las milésimas resultase ser igual o superior a 5. En dicho cálculo, por tanto, no se tendrán en cuenta las calificaciones de <strong>"Apto" y "Exento" y “CV”.</strong>`,
    "Clases presenciales realizadas": `Clases presenciales realizadas`,
    "Solicitar revisión": `Solicitar revisión`,
    "text_info_exam_review": `La nota de <strong>examen debe ser superior o igual a 5.</strong> En caso contrario no habrá media con la evaluación continua y se deberá realizar la recuperación.`,
    "apply_review_01": `IMPORTANTE: Estás a punto de solicitar la revisión de una asignatura. Este proceso implica la revisión completa de tu examen y, por tanto, <strong>tu nota se puede ver afectada tanto al alza como a la baja</strong>. Las solicitudes de revisión son enviadas a la Dirección Académica de ILERNA Online, quien estima su validez. Por ello, las solicitudes de revisión de examen que no estén debidamente justificadas no se tendrán en cuenta.`,
    "apply_review_02": `Descárgate y rellena la plantilla de solicitud de revisión. Después, sube el archivo y acepta las condiciones.`,
    "Descargar plantilla": `Descargar plantilla`,
    "Documento de solicitud": `Documento de solicitud`,
    "Subir archivo": `Subir archivo`,
    "apply_review_types": `PDF, JPEG ó PNG, de 5 MB como máximo.`,
    "Eliminar": `Eliminar`,
    "Acepto las condiciones de la revisión de examen, así como las consecuencias que puedan derivarse de ella.": `Acepto las condiciones de la revisión de examen, así como las consecuencias que puedan derivarse de ella.`,
    "Review_submitted_text": `<strong>Has solicitado la revisión del examen de este módulo.</strong> En breve nos pondremos en contacto contigo para notificarte la revisión.`,
    "¡Hola, Onliner!": `¡Hola, Onliner!`,
    "¡Ahora mismo este espacio no está disponible! No te preocupes, estamos trabajando para que puedas tener tus notas y puedas disfrutar de tu verano lo más pronto posible.": `¡Ahora mismo este espacio no está disponible! No te preocupes, estamos trabajando para que puedas tener tus notas y puedas disfrutar de tu verano lo más pronto posible.`,
    "¡Ahora mismo este espacio no está disponible! No te preocupes, estamos trabajando para que puedas tener tus notas lo más pronto posible.": `¡Ahora mismo este espacio no está disponible! No te preocupes, estamos trabajando para que puedas tener tus notas lo más pronto posible.`,
    "¡Muchas gracias por la espera!": `¡Muchas gracias por la espera!`,
    "Tu equipo de ILERNA Online": `Tu equipo de ILERNA`,
    "Se ha cancelado correctamente la inscripción al examen": `Se ha cancelado correctamente la inscripción al examen`,
    "MANTENER INSCRIPCIÓN": `MANTENER INSCRIPCIÓN`,
    "SÍ, CANCELAR INSCRIPCIÓN": `SÍ, CANCELAR INSCRIPCIÓN`,
    "exam_enrollment_cancel_text": `Tu plaza quedará libre y otro alumno podrá ocuparla. <strong>¿Estás seguro/a de que quieres cancelar esta inscripción?</strong>`,
    "Descargar justificante de asistencia": `Descargar justificante de asistencia`,
    "Descargar justificante de inscripción": `Descargar justificante de inscripción`,
    "Se ha modificado correctamente la inscripción": `Se ha modificado correctamente la inscripción`,
    "Comprar derechos de examen": `Matricularme ahora`,
    "exams_credit_text": `<p>Ya te has presentado a todas las convocatorias disponibles de esta asignatura.</p><p>Vuelve a matricularte de la asignatura con un <strong>20% de descuento</strong>.<br>Haz los ejercicios de evaluación continua de nuevo y preséntate otra vez al examen para aprobar.</p><p>En caso de haberlas realizado, te guardamos la asistencia a las clases presenciales</p>`,
    "classes_date_limit": `Las inscripciones para las clases presenciales estarán abiertas <strong>hasta el {CLASSES_DATE_LIMIT}.</strong>`,
    "Próximas": `Próximas`,
    "Pasadas": `Pasadas`,
    "exams_no_credits": `No puedes inscribirte al examen porque te has presentado a todas las convocatorias disponibles de esta asignatura. Deberás contactar con Secretaría a través del siguiente correo: <strong>secretaria@ilernaonline.com.</strong>`,
    "No has realizado aún ningún examen": `No has realizado aún ningún examen`,
    "Cancelar inscripción a examen": `Cancelar inscripción a examen`,
    "exams_date_limit": `Las inscripciones para los exámenes están abiertas hasta el {EXAM_DATE_LIMIT}. Los <strong>exámenes en Lleida, Tarragona, Barcelona, Madrid, Sevilla, Valladolid y Jerez</strong> se realizarán a través de un ordenador que ILERNA pone a tu disposición.`,
    "exams_no_building_address": `Las direcciones de las sedes estarán disponibles en los próximos días. <strong>Puedes inscribirte en la capital de tu provincia con normalidad</strong> y te mostraremos la dirección cuando la hayamos concretado.`,
    "Lo sentimos. no quedan plazas para esta sesión": `Lo sentimos. no quedan plazas para esta sesión`,
    "Estas sesiones coinciden con otras clases que tienes.": `Estas sesiones coinciden con otras clases que tienes.`,
    "La inscripción no se ha podido realizar. Por favor, vuelve a intentarlo.": `La inscripción no se ha podido realizar. Por favor, vuelve a intentarlo.`,
    "La inscripción se ha realizado correctamente.": `La inscripción se ha realizado correctamente.`,
    "La modificación de la inscripción no se ha podido realizar. Por favor, vuelve a intentarlo.": `La modificación de la inscripción no se ha podido realizar. Por favor, vuelve a intentarlo.`,
    "La modificación de la inscripción se ha realizado correctamente.": `La modificación de la inscripción se ha realizado correctamente.`,
    "La cancelación de la inscripción no se ha podido realizar. Por favor, vuelve a intentarlo.": `La cancelación de la inscripción no se ha podido realizar. Por favor, vuelve a intentarlo.`,
    "La inscripción se ha cancelado correctamente.": `La inscripción se ha cancelado correctamente.`,
    "text_info_class_mandatory": `Las clases presenciales son obligatorias. Puesto que no has asistido a todas <strong>deberás matricularte de nuevo a la asignatura.</strong> `,
    "text_info_pacs_min": `No has llegado al mínimo de PACs entregadas y por ello <strong>deberás realizar la recuperación.</strong>`,
    "Ciclo finalizado": `Ciclo finalizado`,
    "expedient_hours_done": `{hours_done} de {hours_total} horas`,
    "Horas totales realizadas": `Horas totales realizadas`,
    "Horas realizadas sin FCT": `Horas realizadas <strong>sin FCT</strong>`,
    "Inscripción a clases presenciales": `Inscripción a clases presenciales`,
    "Sede de la clase": `Sede de la clase`,
    "El miércoles recibirás un Comunicado con las notas finales de cada UF. Si lo estás revisando y aún no lo tienes, danos tiempo, a lo largo del día lo recibirás.": `En estos momentos el expediente no está disponible.`,
    "El  jueves verás aquí publicadas tus calificaciones finales. Si necesitases solicitar la revisión de algún examen, podrás hacerlo desde este apartado.": `Pronto podrás ver aquí tus notas.`,
    "Gracias por la espera ¡Ya estás en la recta final, todo esfuerzo tiene su recompensa!": `Gracias por la espera ¡Ya estás en la recta final, todo esfuerzo tiene su recompensa!`,
    "Rechazar": `Rechazar`,
    "Cesión de los derechos de imágen": `Cesión de los derechos de imágen`,
    "signature_page_accept_images": `El Grupo ILERNA dispone de espacios en Internet y perfiles en redes sociales, así como diversas publicaciones en formato publicitario o revista, donde informamos y hacemos difusión de nuestras actividades escolares lectivas, complementarias y extraescolares. Por este motivo, se divulgarán imágenes o vídeos en las que aparecen, individualmente o en grupo, alumn@s realizando dichas actividades (clases prácticas, exámenes, visitas a centros de trabajo, convivencias en aulas y centros educativos, etc.).<br /><br />
Además, gracias a los convenios realizados con diferentes instituciones, asociaciones y empresas, realizamos colaboraciones en diferentes proyectos con entidades públicas y privadas que promueven la publicación de estas imágenes en sus respectivos soportes de divulgación y difusión.Por tanto, AUTORIZO a que mi imagen pueda aparecer publicada en cualquier medio de comunicación, redes sociales, en páginas web, filmaciones destinadas a difusión pública, fotografías para revistas, publicaciones de ámbito educativo o presentaciones digitales. Asimismo, autorizo a que el material elaborado por el/la alumn@ pueda ser publicado en blogs y otros espacios de comunicación pública con finalidad educativa.`,
    "signature_page_accept_images_2": `Te informamos de la posibilidad de ejercer los siguientes derechos sobre tus datos personales: derecho de acceso, rectificación, supresión u olvido, limitación, oposición, portabilidad y retirar el consentimiento prestado en cualquier momento, dirigiéndose al Delegado de Protección de Datos a la dirección de correo: ilerna@delegado-datos.com, deberá incluir la documentación que acredite tu identidad o la representación del titular de los datos. En caso de divergencias con la empresa en relación con el tratamiento de tus datos, puedes presentar una reclamación ante la Agencia de Protección de Datos (www.aepd.es).`,
    "Tienes {num} comunicado sin leer": `Tienes {num} comunicado sin leer`,
    "Tienes {num} comunicados sin leer": `Tienes {num} comunicados sin leer`,
    "Leer comunicados": `Leer comunicados`,
    "Leer comunicado": `Leer comunicado`,
    "Tienes inscripciones a exámenes pendientes": `Tienes inscripciones a exámenes pendientes`,
    "Realiza las inscripciones": `Realiza las inscripciones`,
    "Tienes inscripciones a clases presenciales pendientes": `Tienes inscripciones a clases presenciales pendientes`,
    "FP a distancia": `FP a distancia`,
    "Secretaría": `Secretaría`,
    "Gestiones y trámites a distancia": `Gestiones y trámites a distancia`,
    "Área Onliner": `Área Onliner`,
    "Consulta toda la normativa": `Consulta toda la normativa`,
    "Metodología, evaluación y pruebas finales": `Metodología, evaluación y pruebas finales`,
    "El campus virtual": `El campus virtual`,
    "Secretaría académica": `Secretaría académica`,
    "Solicitar título": `Solicitar título`,
    "Campus Online": `Campus Online`,
    "Campus Presencial": `Campus Presencial`,
    "Normativa de las clases presenciales": `Normativa de las clases presenciales`,
    "Normas de convivencia": `Normas de convivencia`,
    "Guía de orientación para el alumno": `Guía de orientación para el alumno`,
    "Calendario académico": `Calendario académico`,
    "Metodología": `Metodología`,
    "Cálculo de notas": `Cálculo de notas`,
    "Solicitud de revisión de examen": `Solicitud de revisión de examen`,
    "Convocatorias": `Convocatorias`,
    "Trabajo final de ciclo": `Trabajo final de ciclo`,
    "El campus de ILERNA Online": `El campus de ILERNA Online`,
    "Cómo acceder a una videotutoría": `Cómo acceder a una videotutoría`,
    "Cómo navegar por la asignatura": `Cómo navegar por la asignatura`,
    "Cómo descargar el Material Didáctico y otros Recursos": `Cómo descargar el Material Didáctico y otros Recursos`,
    "Cómo enviar un mensaje privado al profesor": `Cómo enviar un mensaje privado al profesor`,
    "Cómo participar en los foros": `Cómo participar en los foros`,
    "Cómo entregar una PAC autoevaluable": `Cómo entregar una PAC autoevaluable`,
    "Cómo entregar una PAC de desarrollo": `Cómo entregar una PAC de desarrollo`,
    "Descargar justificante": `Descargar justificante`,
    "¿Deseas descargar el justificante de asistencia?": `¿Deseas descargar el justificante de asistencia?`,
    "¿Deseas descargar el justificante de inscripción?": `¿Deseas descargar el justificante de inscripción?`,
    "Estás estudiando ciclos formativos que están sujetos a diferente normativa educativa.": `Estás estudiando ciclos formativos que están sujetos a diferente normativa educativa.`,
    "Selecciona el ciclo que desees consultar": `Selecciona el ciclo que desees consultar`,
    "Dirección disponible próximamente": `Dirección disponible próximamente`,
    "Mi Cuenta": `Mi Cuenta`,
    "Formalización de matrícula": `Formalización de matrícula`,
    "Convalidaciones": `Convalidaciones`,
    "Certificados": `Certificados`,
    "Solicitud de título": `Solicitud de título`,
    "No tienes ninguna clase programada": `No tienes ninguna clase programada`,
    "No has realizado aún ninguna clase": `No has realizado aún ninguna clase`,
    "no_pending_inscriptions": `No hay inscripciones pendientes`,
    "Formación en centros de trabajo": `Formación en centros de trabajo`,
    "Requisitos Formación en Centros de Trabajo": `Requisitos Formación en Centros de Trabajo`,
    "Documentación": `Documentación`,
    "Asignación empresa": `Asignación empresa`,
    "Validación convenio": `Validación convenio`,
    "Inicio de la formación": `Inicio de la formación`,
    "Exención en tramitación": `Exención en tramitación`,
    "Fin FCT": `Fin FCT`,
    "Comentarios": `Comentarios`,
    "Añadir comentario nuevo": `Añadir comentario nuevo`,
    "Gestor de FCT": `Gestor de FCT`,
    "Yo": `Yo`,
    "Ha sucedido un error al guardar el comentario": `Ha sucedido un error al guardar el comentario`,
    "Escribe el comentario a continuación": `Escribe el comentario a continuación`,
    "Por favor, antes de enviar tu comentario, asegúrate de haber escrito algún texto.": `Por favor, antes de enviar tu comentario, asegúrate de haber escrito algún texto.`,
    "Enviar comentario": `Enviar comentario`,
    "Ha sucedido un error con la subida del archivo, prueba de nuevo": `Ha sucedido un error con la subida del archivo, prueba de nuevo`,
    "Adjuntar archivo": `Adjuntar archivo`,
    "El archivo debe ocupar menos de 5 MB": `El archivo debe ocupar menos de 5 MB`,
    "Subir ficha semanal": `Subir ficha semanal`,
    "Polo": `Polo`,
    "Forro polar": `Forro polar`,
    "Talla": `Talla`,
    "Ancho (cm)": `Ancho (cm)`,
    "Largo (cm)": `Largo (cm)`,
    "Pantalón": `Pantalón`,
    "Talla europea": `Talla europea`,
    "Cintura (cm)": `Cintura (cm)`,
    "Pijama sanitario": `Pijama sanitario`,
    "1. Pecho": `1. Pecho`,
    "2. Largo espalda": `2. Largo espalda`,
    "3. Cintura a su asiento / estirada": `3. Cintura a su asiento / estirada`,
    "4. Altura de cadera": `4. Altura de cadera`,
    "5. Cadera": `5. Cadera`,
    "6. Largo por costado": `6. Largo por costado`,
    "7. Largo entrepierna": `7. Largo entrepierna`,
    "8. Ancho de bajo": `8. Ancho de bajo`,
    "9. Elástico cintura": `9. Elástico cintura`,
    "10. Cintura una vez puesto el elástico y cerrado": `10. Cintura una vez puesto el elástico y cerrado`,
    "Bata sanitaria": `Bata sanitaria`,
    "2. Cintura": `2. Cintura`,
    "3. Cadera": `3. Cadera`,
    "4. Largo espalda": `4. Largo espalda`,
    "5. Largo manga": `5. Largo manga`,
    "6. Hombro a hombro": `6. Hombro a hombro`,
    "Guía de tallas": `Guía de tallas`,
    "Material para las prácticas": `Material para las prácticas`,
    "Uniforme": `Uniforme`,
    "Complejo Magical Media, Edificio 5 Turó de Gardeny, 25003 Lleida": `Complejo Magical Media, Edificio 5 Turó de Gardeny, 25003 Lleida`,
    "online@ilerna.com": `online@ilerna.com`,
    "A/A: Departamento de Prácticas": `A/A: Departamento de Prácticas`,
    "Turó de Gardeny, Complex Magical Media, Edificio 5": `Turó de Gardeny, Complex Magical Media, Edificio 5`,
    "25003 Lleida": `25003 Lleida`,
    "Solicitar aplazamiento de las prácticas": `Solicitar aplazamiento de las prácticas`,
    "Te proporcionaremos un uniforme para que puedas utilizar durante tu periodo de prácticas. El precio del uniforme está incluido en la matrícula. Una vez recibas el uniforme no podrás cambiarlo. Indica tu talla y valida la dirección de envío:": `Te proporcionaremos un uniforme para que puedas utilizar durante tu periodo de prácticas. El precio del uniforme está incluido en la matrícula. Una vez recibas el uniforme no podrás cambiarlo. Indica tu talla y valida la dirección de envío:`,
    "forro_polar_label": `<span>Talla del forro polar <span class='font-color-red'> *</span></span>`,
    "polo_label": `<span>Talla del polo <span class='font-color-red'> *</span></span>`,
    "pantalon_label": `<span>Talla del pantalón <span class='font-color-red'> *</span></span>`,
    "chaleco_label": `<span>Talla del chaleco <span class='font-color-red'> *</span></span>`,
    "pijama_label": `<span>Talla del pijama sanitario <span class='font-color-red'> *</span></span>`,
    "bata_sanitaria_label": `<span>Talla de la bata sanitaria <span class='font-color-red'> *</span></span>`,
    "Lunes": `Lunes`,
    "Martes": `Martes`,
    "Miércoles": `Miércoles`,
    "Jueves": `Jueves`,
    "Viernes": `Viernes`,
    "Indicar disponibilidad": `Indicar disponibilidad`,
    "Este campo es obligatorio": `Este campo es obligatorio`,
    "Disponibilidad horaria": `Disponibilidad horaria`,
    "Dia": `Dia`,
    "Horario": `Horario`,
    "Por ejemplo: 9:00 - 19:00": `Por ejemplo: 9:00 - 19:00`,
    "👋 Nos tienes que enviar por correo postal los ‘Anexos 7’ originales y el ‘Anexo 8’": `👋 Nos tienes que enviar por correo postal los ‘Anexos 7’ originales y el ‘Anexo 8’`,
    "Descárgate el": `Descárgate el`,
    "‘Informe del tutor del centro de trabajo (Anexo 8)’": `‘Informe del tutor del centro de trabajo (Anexo 8)’`,
    "e imprímelo.": `e imprímelo.`,
    "Tenéis que rellenarlo y firmarlo, tú y tu tutor de prácticas.": `Tenéis que rellenarlo y firmarlo, tú y tu tutor de prácticas.`,
    "Una vez rellenado y firmado, envíalo, junto a los ‘Anexos 7’ originales a la siguiente dirección:": `Una vez rellenado y firmado, envíalo, junto a los ‘Anexos 7’ originales a la siguiente dirección:`,
    "Reservar cita": `Reservar cita`,
    "Si tiene alguna duda puedes": `Si tiene alguna duda puedes`,
    "consultar la normativa de FCT": `consultar la normativa de FCT`,
    "También puedes contactar con nosotros a través del número": `También puedes contactar con nosotros a través del número`,
    "91 901 59 29": `919 01 59 29`,
    "Nuestro horario de atención es de lunes a jueves, desde las 9:00 hasta las 14:00 y desde las 15:00 hasta las 17:30. Los viernes, estamos disponibles de 9:00 a 14:00. Si lo deseas, puedes reservar una cita telefónica y uno de nuestros gestores te llamará.": `Nuestro horario de atención es de lunes a jueves, desde las 9:00 hasta las 14:00 y desde las 15:00 hasta las 17:30. Los viernes, estamos disponibles de 9:00 a 14:00. Si lo deseas, puedes reservar una cita telefónica y uno de nuestros gestores te llamará.`,
    "👋 Nos tienes que enviar el convenio firmado antes del {date}": `👋 Nos tienes que enviar el convenio firmado antes del {date}`,
    "Descárgate el convenio e imprime 3 copias, una para la empresa, otra para ti, y otra para ILERNA.": `Descárgate el convenio e imprime 3 copias, una para la empresa, otra para ti, y otra para ILERNA.`,
    "alert_contract_cat": `La empresa tiene que firmar y sellar los apartados <span class='font-bold'>‘REF05/Acuerdo’</span>, <span class='font-bold'>‘REF06/Plan de Actividades’</span>, <span class='font-bold'>‘REF02’</span> y <span class='font-bold'>‘REF03’.</span>`,
    "Posteriormente fírmalos tú también y envíanos una de las copias a la siguiente dirección postal:": `Posteriormente fírmalos tú también y envíanos una de las copias a la siguiente dirección postal:`,
    "👋 Si aún no nos has enviado el convenio firmado, por favor, recuerda que es necesario hacerlo.": `👋 Si aún no nos has enviado el convenio firmado, por favor, recuerda que es necesario hacerlo.`,
    "alert_contract_cat_legacy": `La empresa tiene que firmar y sellar los apartados <span class='font-bold'>‘REF05/Acuerdo’</span>, <span class='font-bold'>‘REF06/Plan de Actividades’</span>, <span class='font-bold'>‘REF02’</span> y <span class='font-bold'>‘REF03’.</span>`,
    "Posteriormente, fírmalos tú también, y envíanos una de las copias originales a la siguiente dirección postal:": `Posteriormente, fírmalos tú también, y envíanos una de las copias originales a la siguiente dirección postal:`,
    "alert_contribute_company": `<p>Mientras nosotros seguimos buscando una empresa para ti, si has encontrado una por tu cuenta, por favor, sube aquí la documentación correspondiente.</p><p> Si proporcionas una empresa, nos encargaremos de cerrar el convenio en un plazo máximo de un mes y, además, <span class='font-bold'>¡recibirás un bono de 40€ para los trámites finales de la titulación!</span></p>`,
    "Aportar empresa": `Aportar empresa`,
    "alert_exemption": `👋 Para tramitar tu exención del {exemption}% tienes que enviarnos el certificado de tareas`,
    "alert_exemption_body": `Después de revisar la documentación que proporcionaste, eres elegible para una exención del {exemption}% de las horas. Al gestionar tu convenio, se mostrará el total de horas de tu ciclo, pero se aplicará un {exemption}% de exención en el QBID.`,
    "Para que podamos tramitar tu exención, necesitamos que envíes por correo postal el certificado de tareas con firma y sello originales a la siguiente dirección:": `Para que podamos tramitar tu exención, necesitamos que envíes por correo postal el certificado de tareas con firma y sello originales a la siguiente dirección:`,
    "👋 Para tramitar tu exención del 100% tienes que enviarnos el certificado de tareas": `👋 Para tramitar tu exención del 100% tienes que enviarnos el certificado de tareas`,
    "Para que podamos tramitar tu exención, deberás remitir vía correo postal el certificado de tareas con firma y sello originales a la siguiente dirección:": `Para que podamos tramitar tu exención, deberás remitir vía correo postal el certificado de tareas con firma y sello originales a la siguiente dirección:`,
    "alert_limit_date_danger": `👋 ¡Date prisa! Fecha límite para subir la documentación {date}`,
    "alert_limit_date_danger_body": `Si quieres realizar las prácticas durante el próximo semestre, es imprescindible que subas toda la documentación requerida a través de la plataforma antes del {date}.`,
    "alert_limit_date_warning": `👋 Fecha límite para subir la documentación {date}`,
    "alert_limit_date_warning_body": `Si quieres realizar las prácticas durante este semestre, es imprescindible que subas toda la documentación requerida a través de la plataforma antes del {date}.`,
    "👋 ¡Hola! Necesitamos que nos envíes tu confirmación de rechazo de empresa.": `👋 ¡Hola! Necesitamos que nos envíes tu confirmación de rechazo de empresa.`,
    "Nos han informado que has rechazado la empresa que te hemos proporcionado.": `Nos han informado que has rechazado la empresa que te hemos proporcionado.`,
    "Para poder continuar con el proceso de asignación de empresa es imprescindible que aceptes la siguiente declaración:": `Para poder continuar con el proceso de asignación de empresa es imprescindible que aceptes la siguiente declaración:`,
    "alert_reject_info": `El alumno {name}, con nº de documento de identidad {document}, matriculado en el ILERNA Online que incluye el módulo/crédito de Formación en Centros de Trabajo (FCT) mediante el presente afirma que el centro educativo ha propuesto una empresa para iniciar las FCT, sin embargo, el alumno/a decide rechazar la empresa propuesta para la realización de las prácticas en el periodo lectivo que le ha indicado el gestor de prácticas, pasando así a la cola de gestión de su semestre en curso. `,
    "Por ello, según la normativa de ILERNA Online, se priorizará la reasignación de esta plaza a otros alumnos, poniendo tu caso en la cola de gestión. Para agilizar tu proceso en estos casos, recomendamos que busques y aportes una empresa que se adapte a tus necesidades. Recuerda que como máximo tendrás un semestre más para poder aportarla, pudiendo iniciar las prácticas sin coste alguno, ya que en caso contrario deberás volver a matricularte para posteriores semestres.": `Por ello, según la normativa de ILERNA Online, se priorizará la reasignación de esta plaza a otros alumnos, poniendo tu caso en la cola de gestión. Para agilizar tu proceso en estos casos, recomendamos que busques y aportes una empresa que se adapte a tus necesidades. Recuerda que como máximo tendrás un semestre más para poder aportarla, pudiendo iniciar las prácticas sin coste alguno, ya que en caso contrario deberás volver a matricularte para posteriores semestres.`,
    "Acepto renunciar a la empresa que me ha propuesto ILERNA.": `Acepto renunciar a la empresa que me ha propuesto ILERNA.`,
    "Aceptar y enviar": `Aceptar y enviar`,
    "👋 Nos tienes que enviar las valoraciones finales de las prácticas": `👋 Nos tienes que enviar las valoraciones finales de las prácticas`,
    "Subir valoraciones finales": `Subir valoraciones finales`,
    "fct_terms_mad": `<div>
      <h1>Introducción</h1>
      <p>La presente normativa tiene como objetivo la regulación de la <span class='font-bold'>Formación en Centros de Trabajo,</span> en adelante FCT, del alumnado de ILERNA Online. Dicha formación tiene como objetivo permitir aplicar y complementar los conocimientos adquiridos en su formación académica, así como favorecer la adquisición de competencias que le preparen para el ejercicio de actividades profesionales, faciliten su empleabilidad y fomenten su capacidad de emprendimiento.</p>
      <h1>Requisitos obligatorios</h1>
      <h2>Realización de la Formación en Centros de Trabajo</h2>
      <p>Para la realización de la Formación en Centros de Trabajo es imprescindible para los/las estudiantes conocer y cumplir las siguientes disposiciones:</p>
      <ol>
        <li>
          Haber superado <span class='font-bold'> al menos el 50% de la carga lectiva </ span> completa del plan de estudios, o <span class='font-bold'> todos los módulos profesionales </span> del primer curso. Por otra parte, es necesario <span class='font-bold'>matricularse simultáneamente en el mismo curso de FCT y proyecto</span>. <br/> <br/>
          <span class='font-bold'>Por lo tanto, aunque el/la alumno/a esté matriculado/a de las prácticas a inicio de curso, hasta que no cumpla con dichos requisitos y, en consecuencia, tenga aprobadas dichas asignaturas, no podrá iniciar sus prácticas. </span><br/><br/>
          En el periodo establecido durante el mes de junio, en el calendario escolar de cada curso académico, se celebrará la evaluación final correspondiente a la convocatoria extraordinaria del curso segundo para los alumnos que no fueron autorizados para realizar el módulo de FCT, junto con los exámenes de primero. <br/><br/>
          En la sesión de evaluación quedarán autorizados a realizar el módulo de FCT en el curso académico siguiente aquellos/as alumnos/as que cumplan los requisitos establecidos.
        </li>
        <li>
          <span class='font-bold'>Es imprescindible notificar al gestor de FCT antes del inicio de la FCT</span> cualquier tipo de exención de la misma, para su tramitación y posterior validación.<br/> <br/>
          Deberá consultar Anexo 1.<br/> <br/>
          <span class='font-bold'>En ningún caso el/la alumno/a podrá comenzar las prácticas sin previo aviso por parte del gestor de ILERNA Online.</span><br/> <br/>
          <span class='font-bold'>En el caso de que el alumno las inicie sin dicho consentimiento, ILERNA Online no se responsabilizará de ninguna incidencia o percance sufrido o realizado en dicho periodo. Así mismo, las horas realizadas no computarán sobre el total de horas a realizar de prácticas.</span>
        </li>
      </ol>
      <h1>Documentación</h1>
      <h2>¿Qué necesitas para iniciar tus prácticas en empresa?</h2>
      <p>Si estás matriculado de prácticas, independientemente del ciclo que estés cursando, desde el departamento de empresas y FCT se te enviará la siguiente documentación:</p>
      <ol>
        <li><span class='font-bold'>Documento informativo de protección de datos personales.</span> En este documento aceptarás y darás tu consentimiento para que ILERNA Online pueda disponer y facilitar, en el caso en que la empresa en la que realices las prácticas lo solicite, tu curriculum vitae y boletín de notas.</li>
        <li><span class='font-bold'>Compromiso de Confidencialidad</span> en el cual te comprometes a no revelar información confidencial y/o cualquier dato sensible al que puedas tener acceso durante la realización de tus prácticas y una vez finalizadas las mismas. Es necesario, a su vez, aportar el documento firmado al gestor/a de prácticas de ILERNA Online.</li>
        <li><span class='font-bold'>Normativa Formación en Centros de Trabajo.</span></li>
        <li><span class='font-bold'>Documento de Datos del/la Alumno/a.</span></li>
        <li><span class='font-bold'>Documento de Datos de Empresa</span> para que pueda ser cumplimentado en el supuesto que el/la alumno/a aporte empresa.</li>
        <li>Será necesario que aportes <span class='font-bold'>el padrón y un curriculum vitae actualizado,</span> el cual consientes que sea enviado a las empresas que lo soliciten por parte del centro educativo. En los ciclos sanitarios deberá aportarse, además, el certificado de delitos sexuales y la cartilla de vacunas.</li>
        <li>
          <span class='font-bold'>Si resides fuera de España, deberás facilitar al gestor de FCT la siguiente documentación:</span> <br/><br/>
          <ul>
            <li>Datos de la empresa donde realizarás las prácticas.</li>
            <li>Seguro de accidentes y de responsabilidad civil.</li>
            <li>Carta de solicitud de prácticas en el extranjero dirigida al director de ILERNA Online.</li>
            <li>Tarjeta Sanitaria Europea.</li>
          </ul>
        </li>
      </ol>
      <h1>Metodología y gestión</h1>
      <h2>Qué debes tener en cuenta antes de empezar.</h2>
      <p class='font-20 m-top-x2'>Tu propuesta de empresa</p>
      <p><span class='font-bold'>El centro educativo se compromete a proporcionarte la posibilidad de realizar la FCT en una única empresa</span> en la que se respeten los horarios solicitados de mutuo acuerdo entre la empresa y el alumno/a. A su vez, se deben poder llevar a cabo en dicha empresa actividades relacionadas con el ciclo que estás estudiando.</p>
      <p><span class='font-bold'>En caso de rechazo o negativa por tu parte a realizar la FCT en la empresa proporcionada, se considerará que renuncias a tu participación y que ILERNA Online priorizará la reasignación de esta plaza a otros alumnos/ as, poniendo tu caso en la cola de gestión.</span> Para agilizar tu proceso en estos casos, recomendamos que busques y aportes una empresa que se adecue a tus necesidades. Por ello, se facilitará un documento que deberás cumplimentar y firmar, donde se refleje el rechazo o negativa por tu parte, y devolverlo al centro mediante mensajes a tu gestor de FCT para poder seguir con el proceso.</p>
      <p class='font-20 m-top-x2'>Cancelación</p>
      <p>Los alumnos/as de primer curso podrán matricularse de FCT, pero <span class='font-bold'>la matrícula no quedará formalizada hasta que, con el cierre de actas, quede aprobado todo el primer curso</span> y, en consecuencia, se cumplan los requisitos de acceso.</p>
      <p>Si te has matriculado y no cumples con los requisitos para poder realizar la FCT, <span class='font-bold'>se causará la baja automática de la asignatura hasta el curso siguiente.</span></p>
      <p class='font-20 m-top-x2'>Aplazamiento</p>
      <p>En caso de que necesites un aplazamiento del módulo de FCT, deberás aportar la documentación que justifique las razones que se aleguen. Para poder tramitarlo, tendrás hasta el <span class='font-bold'>30 de septiembre</span> en primera instancia y hasta el <span class='font-bold'>31 de marzo</span> en segunda instancia.</p>
      <p class='font-20 m-top-x2'>Modificar horarios</p>
      <p>Si durante el periodo de prácticas fuese necesario, por motivos justificados, modificar los horarios fijados previamente en el convenio,<span class='font-bold'> deberás notificar el cambio al gestor de prácticas de ILERNA Online</span> mediante mensajes a tu gestor de FCT dentro del Campus Virtual. Tras la notificación, tu solicitud será valorada y, si esta se resuelve favorablemente, se modificará para que sea efectiva en un plazo de 15-20 días.</p>
      <p>Recuerda que <span class='font-bold'>NO podrás asistir en el horario solicitado si previamente no lo has notificado al gestor de ILERNA Online.</span> Si acudes sin tener la modificación del convenio aprobada, supondrá que estas horas no computen para el total de horas de prácticas que tengas que realizar. Además, tu tutor/a podrá valorar negativamente dicha acción afectando a tu calificación final, pudiendo ser NO APTO.</p>
      <p class='font-20 m-top-x2'>Periodo no lectivo</p>
      <p>La realización de las prácticas será durante el curso escolar, una vez cumplas con los requisitos, con la limitación de no poder ser realizadas durante periodo no lectivo, incluidas las prácticas en curso. Debido a esta característica, el centro no admite ningún tipo de reembolso por el valor de la matrícula, ni inferior, en el caso de que el alumno/a no realice las mismas.</p>
      <p class='font-20 m-top-x2'>Comunicación</p>
      <p>La comunicación entre el gestor de ILERNA Online y el alumno/a será vía mensajes a tu gestor de FCT del Campus Virtual.</p>
      <p>El alumno/a tiene la responsabilidad de realizar las revisiones oportunas de las publicaciones, notificaciones y/o mensajes que reciba por parte de ILERNA Online.</p>
      <p>El desentendimiento por parte del alumno/a del uso de esta herramienta durante la duración de la FCT afectará de manera negativa a su calificación final.</p>
      <p class='font-20 m-top-x2'>No residente en la Comunidad de Madrid</p>
      <p>En caso de que residas fuera de la Comunidad de Madrid, deberás facilitar al gestor/a de FCT un <span class='font-bold'>certificado de empadronamiento o un contrato laboral.</span></p>
      <p class='font-20 m-top-x2'>Finalización y superación</p>
      <p>Para hacer el cierre de las FCT deberás notificar al tutor de la empresa donde realizas las prácticas, con 15 días de antelación, la finalización de las mismas para que pueda cumplimentar el Informe del tutor. Una vez dispongas de él, deberás enviarlo junto con todas las fichas de valoraciones semanales por correo postal a:</p>
      <div class='fct-sign-address'>
        <div class='fct-sign-address__title'>ILERNA ONLINE S.L.</div>
        <div>A/A: Departamento de Prácticas</div>
        <div>Turó de Gardeny, Complex Magical Media, Edificio 5</div>
        <div>25003 Lleida</div>
      </div>
      <p>Si tras finalizar tus prácticas en empresa la valoración es favorable, obtendrás en tu expediente académico la calificación de APTO. En caso de que la valoración no fuese favorable, obteniendo así un NO APTO en tu boletín de notas, deberás volver a realizar tu matrícula para el siguiente curso lectivo.</p>
      <h1>Anexo 1</h1>
      <h2>Información y documentación requerida para la solicitud de exenciones</h2>
      <p>Para solicitar una exención de prácticas, <span class='font-bold'>es necesario que tengas un año de experiencia profesional cotizado a jornada completa</span> en el mismo ámbito del ciclo formativo que estás cursando y firmar el documento de Solicitud de exención del Módulo de Formación en Centros de Trabajo.</p>
      <p>De ser así, deberás aportar la documentación necesaria. El claustro de profesores estudiará en función de las tareas que has realizado si se te otorga el 50% o el 100% de la exención.</p>
      <p class='font-20 m-top-x2'>Documentación que aportar:</p>
      <ul>
        <li><span class='font-bold'>Informe de vida laboral</span> actualizado.</li>
        <li><span class='font-bold'>Certificado de tareas original,</span> lo más extenso y detallado posible, <span class='font-bold'>firmado y sellado por la empresa.</span></li>
      </ul>
      <p class='font-20 m-top-x2'>Documentación requerida para autónomos:</p>
      <ul>
        <li><span class='font-bold'>Certificado de alta</span> en el censo de obligados tributarios con una antigüedad mínima de un año.</li>
        <li><span class='font-bold'>Declaración del interesado</span> de las actividades más representativas.</li>
      </ul>
      <p class='font-14'>* Las exenciones y resoluciones favorables de las FCT constarán como exención y no computarán como nota en el expediente académico.</p>
      <p class='font-20 m-top-x2'>¿Hasta cuándo puedo solicitarla?</p>
      <p>Podrás solicitar la exención de prácticas en dos períodos. Hasta el <span class='font-bold'>31 de octubre</span> en primera convocatoria y hasta el <span class='font-bold'>30 de abril</span> en segunda convocatoria.</p>
      <p class='font-20 m-top-x2'>¿Dónde enviar la documentación?</p>
      <p>La documentación <span class='font-bold'>deberá ser enviada a Mensajes a tu gestor de FCT dentro del Campus Virtual.</span> A través del mismo canal recibirás la notificación sobre la resolución de la exención y los pasos a seguir posteriormente. La exención se tramitará a lo largo del curso y una vez tramitada, se notificará al alumno.</p>
      <h1>Anexo 2</h1>
      <h2>Compromiso de Confidencialidad para estudiantes en prácticas en instituciones y empresas externas</h2>
      <p>En calidad de estudiante de ILERNA, se compromete a cumplir el presente Compromiso de confidencialidad y buenas prácticas:</p>
      <ol>
        <li>Facilitar toda la documentación y datos personales para poder iniciar las prácticas y comunicar cualquier cambio en dicha disponibilidad.</li>
        <li>Incorporarse a la empresa, institución u organismo asignado en el plazo y condiciones acordadas con el gestor responsable de la Formación en Centros de Trabajo.</li>
        <li>Incorporarse al centro de prácticas en la fecha acordada, cumplir el horario previsto y conocer y respetar las normas de funcionamiento, seguridad y prevención de riesgos laborales del mismo.</li>
        <li>Guardar estricta puntualidad en el horario de entrada y en cualquiera de las reuniones que se establezcan con usuarios, pacientes o miembros del mismo. En caso de ausencia justificada, se avisará con al menos 24 horas de antelación tanto al tutor/a del centro colaborador como al tutor/a del centro académico.</li>
        <li>Ajustarse al calendario académico vigente para el centro educativo, aunque no sea coincidente con el calendario laboral del centro colaborador.</li>
        <li>Mantener un comportamiento adecuado y correcto en el centro donde se realicen las prácticas, evitando especialmente el uso de teléfonos móviles o de cualquier otro dispositivo comunicativo mientras se esté llevando a cabo una tarea. También se debe asistir al trabajo con la vestimenta apropiada para la función que se ejerce. Y tampoco se debe realizar, en ningún caso, una actividad con ánimo de lucro con las personas con las que se trata en el centro asignado.</li>
        <li>Realizar con diligencia y aprovechamiento las tareas, actividades formativas y los compromisos adquiridos con el centro, de acuerdo con el programa de prácticas y siguiendo las orientaciones de los tutores académicos y profesionales.</li>
        <li>Desarrollar el trabajo de las prácticas atendiendo a los principios deontológicos propios de la profesión, prestando especial atención a la obligación de guardar la confidencialidad y el secreto profesional sobre la información recibida en el centro, sin transferirla a terceros.</li>
        <li>No emitir, bajo ningún concepto, documento o informe alguno sin supervisión de los tutores académicos.</li>
        <li>Mantener contacto periódico con las personas responsables de la tutorización académica y profesional, asistiendo a las reuniones que se establezcan con objeto de supervisar las actividades que se estén llevando a cabo en las prácticas, y comunicando cualquier incidente que pueda afectar al desarrollo de las prácticas.</li>
        <li>No interrumpir, salvo motivo grave justificado, el desarrollo de las prácticas. En el caso de haber decidido no continuar con las mismas, el alumno deberá comunicarlo por escrito tanto al tutor profesional como al tutor académico, quien, a su vez, lo trasladará al centro para la oportuna resolución del caso. El abandono injustificado de las prácticas supone el SUSPENSO de la asignatura.</li>
        <li>Conocer y cumplir la normativa vigente relativa a las prácticas externas establecidas, tanto por el centro académico como por el centro de trabajo.</li>
        <li>Hacer entrega de los documentos e informes de seguimiento solicitados en las fechas establecidas.</li>
        <li>Mostrar una actitud respetuosa hacia la política de la entidad colaboradora.</li>
        <li>El alumno no recibirá ningún importe económico con la empresa o entidad en concepto de retribución por la realización de la Formación en Centros de Trabajo.</li>
        <li>Para obtener una buena evaluación, no es aconsejable que el alumno realice las prácticas en empresas de familiares ni en aquellas empresas donde presten servicios docentes que tengan relación directa con el alumno.</li>
        <li>No es posible realizar las prácticas en el domicilio del alumno, ya que la finalidad de la FCT es la aplicación de los conocimientos aprendidos en entornos reales y la compresión de las relaciones laborales.</li>
        <li>Las prácticas se rigen por el calendario escolar establecido en el Centro Educativo donde quedan detallados los plazos de matriculación, solicitudes y presentación de la documentación requerida para los trámites y gestión de las prácticas.</li>
      </ol>
      <h1>Anexo 3</h1>
      <h2>Información sobre protección de datos personales</h2>
      <p class='font-20 m-top-x2'>Responsable</p>
      <div class='fct-sign-address'>
        <div class='fct-sign-address__title'>ILERNA ONLINE S.L. (B25774729)</div>
        <div>Complejo Magical Media, Edificio 5 Turó de Gardeny, 25003 Lleida)</div>
        <div>'online@ilerna.com'</div>
      </div>
      <p class='font-20 m-top-x2'>Finalidades</p>
      <p>Gestionar las prácticas de nuestros alumnos/as en las empresas colaboradoras.</p>
      <p class='font-20 m-top-x2'>Legitimación</p>
      <p>Acuerdo de matrícula y participación en las prácticas.</p>
      <p class='font-20 m-top-x2'>Conservación de los datos</p>
      <p>Durante la vigencia de las prácticas y duración de los cursos y, durante los plazos legales de conservación para atender eventuales responsabilidades y conservación de expedientes académicos.</p>
      <p class='font-20 m-top-x2'>Destinatarios</p>
      <ul>
        <li>Empresas destinatarias de sus servicios a los que se facilitarán sus datos identificativos y curriculares, así como de salud si se precisan para prácticas en el ámbito sanitario, y certificados de penales para prácticas con menores de edad involucrados.</li>
        <li>ILERNA Online (resultados y progresos de las prácticas).</li>
        <li>Casos legalmente previstos.</li>
      </ul>
      <p class='font-20 m-top-x2'>Derechos</p>
      <ul>
        <li>Tiene derecho a solicitar el acceso, rectificación, supresión, oposición, limitación y portabilidad de sus datos personales.</li>
        <li>Puede solicitarlos dirigiéndose a los datos de contacto del responsable.</li>
        <li>En caso de divergencias, puede presentar una reclamación ante la Agencia de Protección de Datos (<a href="https://www.agpd.es" target='_blank' class='font-color-149BAB'>www.agpd.es</a>).</li>
      </ul>
    </div>`,
    "fct_terms_cat": `<div>
  <h1>Introducción</h1>
  <p>La presente normativa tiene como objetivo la regulación de la <span class='font-bold'>Formación en Centros de Trabajo,</span> en adelante FCT, del alumnado de ILERNA Online. El fin de esta formación es permitir aplicar y complementar los conocimientos adquiridos en tu formación académica, así como favorecer la adquisición de competencias que te preparen para el ejercicio de actividades profesionales, faciliten tu empleabilidad y fomenten tu capacidad de emprendimiento.</p>
  <h1>Requisitos obligatorios</h1>
  <h2>Realización de la Formación en Centros de Trabajo</h2>
  <p>Para la realización de la Formación en Centros de Trabajo es imprescindible que, como estudiante conozcas y cumplas las siguientes disposiciones:</p>
  <ol>
    <li>Para poder cursar tu FCT tienes que <span class='font-bold'>haber superado previamente al menos el 70% de los créditos/módulos del ciclo formativo</span> que estás cursando.</li>
    <li>Tendrás que <span class='font-bold'>haber superado</span> o disponer de la convalidación de la <span class='font-bold'>Unidad Formativa 2 (Prevención de Riesgos Laborales)</span> del módulo Formación y Orientación Laboral. Si estás cursando Técnico en Cuidados Auxiliares de Enfermería y/o el ciclo de Técnico en Dietética, es necesario que hayas superado o dispongas de la convalidación de Formación y Orientación Laboral.</li>
    <li>
      En caso de que residas fuera del Estado Español, deberás facilitar al Departamento de FCT la siguiente documentación:
      <ul class='m-top'>
        <li>Datos de la empresa donde realizarás las prácticas.</li>
        <li>Seguro de accidentes y de responsabilidad civil.</li>
        <li>Carta de solicitud de prácticas en el extranjero dirigida al director de ILERNA Online.</li>
        <li>Tarjeta sanitaria europea.</li>
      </ul>
    </li>
    <li>Si tienes experiencia laboral y quieres solicitar una exención de horas de la FCT deberás consultar el Anexo 1. (ver Anexo 1).</li>
    <li>El número de horas de prácticas diarias que podrás realizar estará supeditado a tu carga lectiva.</li>
    <li>En ningún caso podrás comenzar las prácticas sin avisar previamente al Departamento de FCT de ILERNA Online. En el caso de que las inicies sin dicho consentimiento, te informamos que el centro no se responsabilizará de ninguna incidencia o percance sufrido en dicho periodo. Así mismo, las horas realizadas no te computarán sobre el total de horas a realizar de tus prácticas.</li>
    <li>Deberás tener una <span class='font-bold'>disponibilidad mínima de 3 horas diarias para poder realizar tu FCT,</span> ya sea en turno de mañana o en turno de tarde.</li>
  </ol>
  <h1>Documentación</h1>
  <h2>¿Qué necesitas para iniciar tus prácticas en empresa?</h2>
  <p>Una vez matriculad@ de la FCT <span class='font-bold'>recibirás un e-mail de confirmación.</span> Cuando se te active el módulo de Formación en Centros de Trabajo en el <span class='font-bold'>Campus Virtual</span> encontrarás toda la información necesaria dentro del aula y desde allí podrás presentar la siguiente documentación requerida:</p>
  <ol>
    <li><span class='font-bold'>Documento informativo de protección de datos personales.</span> En este documento aceptarás y darás tu consentimiento para que ILERNA Online pueda disponer y facilitar, en el caso en que la empresa en la que realizarás las prácticas lo solicite, tu currículum vitae, boletín de notas, carné de vacunas y el certificado de delitos sexuales.</li>
    <li><span class='font-bold'>Compromiso y Confidencialidad</span> en el cual te comprometes a no revelar información confidencial y/o cualquier dato sensible al que puedas tener acceso durante la realización de tus prácticas. Es necesario que este documento lo envíes firmado al Departamento de FCT de ILERNA Online.</li>
    <li><span class='font-bold'>Normativa Formación en Centros de Trabajo.</span></li>
    <li><span class='font-bold'>Documento de Datos del/la Alumno/a.</span></li>
    <li><span class='font-bold'>Documento de Datos de Empresa</span> en el supuesto que aportes tú la empresa en la que quieres realizar tus prácticas.</li>
    <li>
      Para los ciclos formativos:
      <ul class='m-top'>
        <li>CFGM en Cuidados Auxiliares de Enfermería.</li>
        <li>CFGS en Higiene Bucodental.</li>
        <li>CFGS en Imagen para el Diagnóstico y Medicina Nuclear.</li>
      </ul>
      <p>Te recomendamos que aportes al Departamento de FCT de ILERNA Online una fotocopia de tu carné de vacunas completo y actualizado. En su defecto tendrás que aportar una serología (análisis de las vacunas que te han administrado hasta el momento). Las vacunas que recomendamos son las siguientes:</p>
      <ul class='m-top'>
        <li>Las establecidas a nivel nacional</li>
        <li>Triple vírica</li>
        <li>Hepatitis B</li>
        <li>Tétanos</li>
        <li>Gripe (en época de gripe)</li>
      </ul>
    </li>
    <li>
      <span class='font-bold'>Para los ciclos formativos:</span>
      <ul class='m-top'>
        <li>CFGM en Cuidados Auxiliares de Enfermería.</li>
        <li>CFGS en Educación Infantil.</li>
        <li>CFGS en Higiene Bucodental.</li>
        <li>CFGS en Imagen para el Diagnóstico y Medicina Nuclear.</li>
        <li>CFGS en Integración Social.</li>
        <li>CFGS en Audiología Protésica.</li>
      </ul>
      <p>Será indispensable que aportes al Departamento de FCT de ILERNA Online el Certificado negativo del registro central de delincuentes sexuales, de lo contrario no podremos iniciar la gestión de tus prácticas bajo ningún concepto. Puedes solicitar este certificado a través del siguiente enlace:</p>
      <p><a href="https://sede.mjusticia.gob.es/es/tramites/certificado-registro-central " target='_blank' class='font-color-149BAB'>https://sede.mjusticia.gob.es/es/tramites/certificado-registro-central </a></p>
    </li>
    <li><span class='font-bold'>Será necesario que aportes un Currículum Vitae actualizado. Recuerda que, si las empresas nos lo solicitan, podremos compartirles tu currículum.</span></li>
  </ol>
  <h1>Metodología y gestión</h1>
  <h2>Qué debes tener en cuenta antes de empezar.</h2>
  <p class='font-20 m-top-x2'>Periodo no lectivo</p>
  <p>La realización de las prácticas es atemporal e independiente del curso escolar, con la única limitación que no podrás hacerlas <span class='font-bold'>durante el mes de agosto.</span></p>
  <p><span class='font-bold'>La gestión de la FCT iniciará una vez tengamos tu documentación completa y validada a través del Campus Virtual.</span></p>
  <p class='font-20 m-top-x2'>Tu propuesta de empresa</p>
  <p>Desde ILERNA Online nos comprometemos a <span class='font-bold'>proporcionarte una empresa para realizar tus prácticas.</span> En la empresa que te propongamos podrás llevar a cabo actividades relacionadas con el ciclo que estás estudiando.</p>
  <p><span class='font-bold'>En caso de rechazo o negativa por tu parte a realizar la FCT en la empresa proporcionada, se considerará que renuncias a tu participación y que ILERNA Online priorizará la reasignación de esta plaza a otros alumnos, poniendo tu caso en la cola de gestión.</span> Para agilizar tu proceso en estos casos, recomendamos que busques y aportes una empresa que se adecue a tus necesidades. Por ello, se facilitará un documento que deberás cumplimentar y firmar, donde se refleje el rechazo o negativa por tu parte, y devolverlo al centro mediante mensajes a tu gestor de FCT dentro del Campus Virtual para poder seguir con el proceso.</p>
  <p class='font-20 m-top-x2'>Congelación de matrícula</p>
  <p>Si te has matriculado y<span class='font-bold'> no cumples con los requisitos</span> para poder realizar la FCT, te realizaremos un <span class='font-bold'>bono con el importe total de horas que has pagado de la asignatura</span> para que puedas usarlo en tu próxima matrícula.</p>
  <p>Si te has matriculado ya, <span class='font-bold'>pero por motivos de fuerza mayor no puedes cursar las prácticas</span> durante el semestre, tendrás que comunicárselo a través del Campus Virtual a tu tutor y adjuntar un documento oficial que lo acredite. Una vez revisada la documentación, te realizaremos un <span class='font-bold'>bono con el importe total que has pagado de la asignatura para que puedas usarlo en tu próxima matrícula.</span></p>
  <p class='font-20 m-top-x2'>Disponibilidad horaria</p>
  <p>Si te has <span class='font-bold'>matriculado para realizar la FCT,</span> tienes que tener en cuenta que es un “simulacro” de trabajo real en la empresa, por lo que debes disponer de un <span class='font-bold'>mínimo de 3 horas diarias para realizar tu FCT,</span> ya sea en turno de mañana o en turno de tarde. En caso de modificación de disponibilidad deberás comunicarlo a tu gestor de FCT.</p>
  <p>Tendremos en cuenta tu preferencia horaria, pero <span class='font-bold'>será la empresa quien marque y elija el horario definitivo de tus prácticas.</span></p>
  <p class='font-20 m-top-x2'>Documentación</p>
  <p>ILERNA Online te proporcionará a ti y a la empresa en la que vayas a realizar la FCT la documentación necesaria para el inicio: seguimiento, evaluación y finalización de la formación. <span class='font-bold'>Deberás participar activamente en la tramitación de dichos documentos, facilitando la comunicación entre el Departamento de FCT de ILERNA Online y la empresa.</span></p>
  <p class='font-20 m-top-x2'>Material</p>
  <p> Para realizar las prácticas de Imagen para el Diagnóstico, es necesario un dosímetro. ILERNA Online facilita la gestión del alquiler del dosímetro, que tiene un precio de 75 €. Este precio incluye el alquiler del dosímetro y las mediciones pertinentes durante todo el periodo de prácticas. En el caso de que la empresa donde realices las prácticas te proporcione el dosímetro, o no sea necesario tenerlo, no tendrás que abonar dicho importe. Si se extravía el dosímetro o no le llega a la empresa de dosimetría en el plazo establecido, <span class='font-bold'>tendrás una penalización monetaria de 50 €.</span></p>  
  <p class='font-20 m-top-x2'>QBID</p>
  <p><span class='font-bold'>Deberás cumplimentar diariamente el programa de gestión de prácticas QBID.</span> En caso de que no lleves a cabo esta acción será valorado negativamente afectando a tu calificación final, pudiendo ser la misma NO APTO. La NO superación de las prácticas implica que tendrás que volver a matricularte abonando el total del importe de esta asignatura.</p>
  <p class='font-20 m-top-x2'>Modificar horarios</p>
  <p>Si durante el periodo de prácticas <span class='font-bold'>fuese necesario, por motivos justificados, modificar los horarios fijados previamente en el convenio,</span> deberás notificar el cambio al <span class='font-bold'>Departamento de FCT de ILERNA Online.</span> Tras la notificación, tu solicitud será valorada y, si esta se resuelve favorablemente, se modificará para que sea efectiva un plazo de 15-20 días.</p>
  <p><span class='font-bold'>Recuerda que NO podrás asistir en el horario solicitado si previamente no lo has notificado al Departamento de FCT.</span> Si acudes sin tener la modificación del convenio aprobada supondrá que estas horas no computen para el total de horas de prácticas que tengas que realizar. Además, tu tutor/a podrá valorar negativamente dicha acción afectando a tu calificación final, pudiendo ser NO APTO.</p>
  <p class='font-20 m-top-x2'>Ausencias</p>
  <p>Si por motivos justificados no puedes asistir a tu centro de prácticas, deberás avisar a la empresa y registrar tu ausencia en <span class='font-bold'>el programa de gestión de prácticas QBID.</span> El programa ampliará los días de prácticas para compensar la falta de asistencia. <span class='font-bold'>Los días de ausencia los tendrás que recuperar y,</span> por lo tanto, <span class='font-bold'>la fecha de finalización de las prácticas variará.</span></p>
  <p class='font-20 m-top-x2'>Finalización</p>
  <p>Deberás notificar al tutor de la empresa donde realizas las prácticas, con <span class='font-bold'>10 días de antelación,</span> la finalización de las mismas para que pueda cumplimentar tus valoraciones finales. Una vez dispongas de ellas, deberás enviarlas a través de mensajes al gestor de FCT dentro del Campus Virtual. Además, para cerrar las prácticas es necesario que el Departamento de FCT de ILERNA Online tenga en su disposición <span class='font-bold'>toda la documentación de cierre cumplimentada,</span> tanto la tuya como la de la empresa en las que has realizado tus prácticas.</p>
  <p class='font-20 m-top-x2'>Superación de la FCT</p>
  <p>Si tras finalizar tus prácticas en empresa la valoración es favorable, obtendrás en tu expediente académico la calificación de APTO. En caso de que la valoración no fuese favorable, obteniendo así un NO APTO en tu boletín de notas, deberás volver a realizar tu matrícula para el siguiente semestre lectivo. <span class='font-bold'>Ten en cuenta, que la asignatura de Formación en Centros de Trabajo, y por normativa curricular, únicamente puede ser evaluada en cada centro educativo dos veces.</span> Esto significa, que en el caso de ser NO APTO, tan solo tendrás una oportunidad más para poder titular en ILERNA Online.</p>
  <p class='font-20 m-top-x2'>Comunicación</p>
  <p>Deberás comunicarte con el Departamento de FCT a través de mensajes al gestor de FCT en el Campus Virtual. Es tu responsabilidad realizar las revisiones oportunas de las publicaciones, notificaciones y/o mensajes que recibas por parte de ILERNA Online. El desentendimiento durante la duración de la FCT podrá afectar negativamente en tu calificación final.</p>
  <h1>Anexo 1</h1>
  <h2>Información y documentación requerida para la solicitud de exenciones</h2>
  <p class='font-20 m-top-x2'>Exención del 25%</p>
  <p>Para solicitar una <span class='font-bold'>exención del 25%</span> no es necesario que tengas experiencia profesional en el mismo ámbito del ciclo formativo que estás cursando.</p>
  <p>Sí que es indispensable que en tu informe de vida laboral consten cotizadas el doble de las horas del módulo/crédito de la FCT del ciclo en el que estás matriculado/a.</p>
  <p>Documentación que aportar:</p>
  <ul class='m-top'>
    <li>Informe de vida laboral actualizado.</li>
  </ul>
  <p class='font-20 m-top-x2'>Exenciones del 50% o del 100%</p>
  <p><span class='font-bold'>Para solicitar una exención del 50% o 100%</span> es necesario que tengas experiencia profesional en el mismo ámbito del ciclo formativo que estás cursando.</p>
  <p>Es indispensable que, en tu informe de vida laboral actualizado, si solicitas el <span class='font-bold'>50% de exención,</span> consten <span class='font-bold'>cotizadas la mitad de las horas del módulo/crédito de la FCT</span> del ciclo en el que estás matriculado/a.</p>
  <p>Si solicitas la exención del 100%, deben constar cotizadas como mínimo las horas del módulo/crédito de la FCT del ciclo en el que estás matriculada/o.</p>
  <p>Documentación que aportar:</p>
  <ul>
    <li>Informe de vida laboral actualizado.</li>
    <li>Certificado de tareas original, lo más extenso y detallado posible, firmado y sellado por la empresa.</li>
  </ul>
  <p class='font-20 m-top-x2'>Exenciones para autónomos/as</p>
  <p>Si eres autónomo/a y quieres solicitar una exención del 25%, 50% o 100%, deberás aportar la siguiente documentación. Para solicitar una exención del 50% o del 100%, es necesario que también tengas experiencia profesional en el mismo ámbito del ciclo formativo que estás cursando.</p>
  <ul class='m-top'>
    <li>Informe de vida laboral actualizado. Horas cotizadas en el informe laboral si solicitas exención:
        <ul class='m-top'>
          <li>Si solicitas el 25% de exención: Deben constar cotizadas el doble de las horas del módulo/crédito de la FCT del ciclo en el que estás matriculado/a.</li>
          <li>Si solicitas el 50% de exención: Deben constar cotizadas la mitad de las horas del módulo/crédito de la FCT del ciclo en el que estás matriculado/a.</li>
          <li>Si solicitas el 100% de la exención: Deben constar cotizadas, como mínimo, las horas del módulo/crédito de la FCT del ciclo en el que estás matriculad/o.</li>
        </ul>
    </li>
    <li class='m-top'>Certificado de alta de la actividad económica. Recibos de cotización de la Seguridad Social. Cuota de Régimen de Autónomo.</li>
    <li>Declaración trimestral (modelo 130).</li>
    <li>Certificado de tareas original, lo más extenso y detallado posible, firmado y sellado por la empresa. En su defecto, declaración jurada detallada.</li>
  </ul>
  <p class='font-14'>* Las exenciones y resoluciones favorables de la FCT constarán como exención y no computan como nota en el expediente académico.</p>
  <h2>¿Dónde enviar la documentación?</h2>
  <p>La documentación deberá ser enviada a mensajes a tu gestor de FCT dentro del Campus Virtual. A través del mismo canal, recibirás la notificación sobre la resolución de la exención y los pasos a seguir posteriormente. La exención se tramitará a lo largo del semestre, y una vez tramitada, se te notificará.</p>
  <h1>Anexo 2</h1>
  <h2>Compromiso de Confidencialidad para estudiantes en prácticas en instituciones y empresas externas</h2>
  <p>En calidad de estudiante de ILERNA, se compromete a cumplir el presente Compromiso de confidencialidad y buenas prácticas:</p>
  <ol>
    <li>Facilitar toda la documentación y datos personales para poder iniciar las prácticas y comunicar cualquier cambio en dicha disponibilidad.</li>
    <li>Incorporarse a la empresa, institución u organismo asignado en el plazo y condiciones acordadas con el gestor responsable de la Formación en Centros de Trabajo.</li>
    <li>Incorporarse al centro de prácticas en la fecha acordada, cumplir el horario previsto y conocer y respetar las normas de funcionamiento, seguridad y prevención de riesgos laborales del mismo.</li>
    <li>Guardar estricta puntualidad en el horario de entrada y en cualquiera de las reuniones que se establezcan con usuarios, pacientes o miembros del mismo. En caso de ausencia justificada, se avisará con al menos 24 horas de antelación tanto al tutor/a del centro colaborador como al tutor/a del centro académico.</li>
    <li>Ajustarse al calendario laboral vigente para el centro educativo de Catalunya.</li>
    <li>Mantener un comportamiento adecuado y correcto en el centro donde se realicen las prácticas, evitando especialmente el uso de teléfonos móviles o de cualquier otro dispositivo comunicativo mientras se esté llevando a cabo una tarea. También se debe asistir al trabajo con la vestimenta apropiada para la función que se ejerce. Y tampoco se debe realizar, en ningún caso, una actividad con ánimo de lucro con las personas con las que se trata en el centro asignado.</li>
    <li>Realizar con diligencia y aprovechamiento las tareas, actividades formativas y los compromisos adquiridos con el centro, de acuerdo con el programa de prácticas y siguiendo las orientaciones de los Tutores Académicos y Profesionales.</li>
    <li>Desarrollar el trabajo de las prácticas atendiendo a los principios deontológicos propios de la profesión, prestando especial atención a la obligación de guardar la confidencialidad y el secreto profesional sobre la información recibida en el centro, sin transferirla a terceros.</li>
    <li>No emitir, bajo ningún concepto, documento o informe alguno sin supervisión de los Tutores Académicos.</li>
    <li>Mantener contacto periódico con las personas responsables de la tutorización académica y profesional, asistiendo a las reuniones que se establezcan con objeto de supervisar las actividades que se estén llevando a cabo en las Prácticas, y comunicando cualquier incidente que pueda afectar al desarrollo de las prácticas.</li>
    <li>No interrumpir, salvo motivo grave justificado, el desarrollo de las prácticas. En el caso de haber decidido no continuar con las mismas, el alumno deberá comunicarlo por escrito tanto al Tutor Profesional como al Tutor Académico, quién, a su vez, lo trasladará al centro para la oportuna resolución del caso. El abandono injustificado de las prácticas supone el SUSPENSO de la asignatura.</li>
    <li>Conocer y cumplir la normativa vigente relativa a las prácticas externas establecidas, tanto por el centro académico como por el centro de trabajo.</li>
    <li>Hacer entrega de los documentos e informes de seguimiento solicitados en las fechas establecidas.</li>
    <li>Mostrar una actitud respetuosa hacia la política de la entidad colaboradora.</li>
    <li>El alumno no recibirá ningún importe económico con la empresa o entidad en concepto de retribución por la realización de la Formación en Centros de Trabajo.</li>
    <li>Para obtener una buena evaluación, no es aconsejable que el alumno realice las prácticas en empresas de familiares ni en aquellas empresas donde presten servicios docentes que tengan relación directa con el alumno.</li>
    <li>No es posible realizar las prácticas en el domicilio del alumno, ya que la finalidad de la FCT es la aplicación de los conocimientos aprendidos en entornos reales y la compresión de las relaciones laborales.</li>
    <li>Las prácticas se rigen por el calendario escolar establecido en el Centro Educativo donde quedan detallados los plazos de matriculación, solicitudes y presentación de la documentación requerida para los trámites y gestión de las prácticas.</li>
  </ol>
  <h1>Anexo 3</h1>
  <h2>Información sobre protección de datos personales</h2>
  <p class='font-20 m-top-x2'>Responsable</p>
  <div class='fct-sign-address'>
    <div class='fct-sign-address__title'>ILERNA ONLINE S.L. (B25774729)</div>
    <div>Complejo Magical Media, Edificio 5 Turó de Gardeny, 25003 Lleida)</div>
    <div>'online@ilerna.com'</div>
  </div>
  <p class='font-20 m-top-x2'>Finalidades</p>
  <p>Gestionar las prácticas de nuestros alumnos/as en las empresas colaboradoras.</p>
  <p class='font-20 m-top-x2'>Legitimación</p>
  <p>Acuerdo de matrícula y participación en las prácticas.</p>
  <p class='font-20 m-top-x2'>Conservación de los datos</p>
  <p>Durante la vigencia de las prácticas y duración de los cursos y, durante los plazos legales de conservación para atender eventuales responsabilidades y conservación de expedientes académicos.</p>
  <p class='font-20 m-top-x2'>Destinatarios</p>
  <ul>
    <li>Empresas destinatarias de sus servicios a los que se facilitarán sus datos identificativos y curriculares, así como de salud si se precisan para prácticas en el ámbito sanitario, y certificados de penales para prácticas con menores de edad involucrados.</li>
    <li>ILERNA Online (resultados y progresos de las prácticas).</li>
    <li>Casos legalmente previstos.</li>
  </ul>
  <p class='font-20 m-top-x2'>Derechos</p>
  <ul>
    <li>Tiene derecho a solicitar el acceso, rectificación, supresión, oposición, limitación y portabilidad de sus datos personales.</li>
    <li>Puede solicitarlos dirigiéndose a los datos de contacto del responsable.</li>
    <li>En caso de divergencias, puede presentar una reclamación ante la Agencia de Protección de Datos (<a href="https://www.agpd.es" target='_blank' class='font-color-149BAB'>www.agpd.es</a>).</li>
  </ul>
</div>`,
    "Documentación previa": `Documentación previa`,
    "ss_card_label": `<span>Número de tarjeta sanitaria <span class='font-color-red'> *</span></span>`,
    "Por favor, indica un número de tarjeta sanitaria.": `Por favor, indica un número de tarjeta sanitaria.`,
    "Centro de trabajo": `Centro de trabajo`,
    "En el caso de que aportes una empresa donde realizar tus prácticas, ILERNA te obsequiará con un bono de 40€ que podrás utilizar en tu solicitud del título.": `En el caso de que aportes una empresa donde realizar tus prácticas, ILERNA te obsequiará con un bono de 40€ que podrás utilizar en tu solicitud del título.`,
    "Tengo una empresa dónde realizar las prácticas": `Tengo una empresa dónde realizar las prácticas`,
    "Es necesario que aportes el documento con información de la empresa:": `Es necesario que aportes el documento con información de la empresa:`,
    "Descárgate e imprime el documento": `Descárgate e imprime el documento`,
    "Datos de empresa.pdf": `Datos de empresa.pdf`,
    "Proporciónaselo a la empresa para que lo rellene.": `Proporciónaselo a la empresa para que lo rellene.`,
    "Escanea el documento y vuelve a subirlo a continuación.": `Escanea el documento y vuelve a subirlo a continuación.`,
    "Ten en cuenta que si entregas este documento completo al inicio del curso, podrás comenzar las prácticas durante las primeras semanas.": `Ten en cuenta que si entregas este documento completo al inicio del curso, podrás comenzar las prácticas durante las primeras semanas.`,
    "Es importante que confirmes con la empresa el horario en el que realizarás las prácticas para agilizar la gestión. Además, debes indicarles que se prevé que las prácticas comiencen a partir de ": `Es importante que confirmes con la empresa el horario en el que realizarás las prácticas para agilizar la gestión. Además, debes indicarles que se prevé que las prácticas comiencen a partir de `,
    "Documento de empresa": `Documento de empresa`,
    "Por favor, agrega el documento con información de la empresa.": `Por favor, agrega el documento con información de la empresa.`,
    "Si no tienes una empresa disponible, no te preocupes, ya que te ofreceremos un centro adecuado donde podrás poner en práctica y desarrollar los conocimientos adquiridos durante el curso.": `Si no tienes una empresa disponible, no te preocupes, ya que te ofreceremos un centro adecuado donde podrás poner en práctica y desarrollar los conocimientos adquiridos durante el curso.`,
    "Lugar de las practicas": `Lugar de las practicas`,
    "Para facilitarte los desplazamientos, buscaremos un centro o empresa que quede cerca de tu domicilio. Si quieres realizar las prácticas en un lugar diferente, indícalo a continuación.": `Para facilitarte los desplazamientos, buscaremos un centro o empresa que quede cerca de tu domicilio. Si quieres realizar las prácticas en un lugar diferente, indícalo a continuación.`,
    "Realizar las prácticas en una población distinta al domicilio actual": `Realizar las prácticas en una población distinta al domicilio actual`,
    "province_label": `<span>Provincia <span class='font-color-red'> *</span></span>`,
    "Por favor, indica en que provincia desea hacer las prácticas.": `Por favor, indica en que provincia desea hacer las prácticas.`,
    "city_label": `<span>Población <span class='font-color-red'> *</span></span>`,
    "Por favor, indica en que población desea hacer las prácticas.": `Por favor, indica en que población desea hacer las prácticas.`,
    "Indica qué horario aproximado te iría bien para hacer las prácticas. Intentaremos que se ajuste con las necesidades de la empresa.": `Indica qué horario aproximado te iría bien para hacer las prácticas. Intentaremos que se ajuste con las necesidades de la empresa.`,
    "Dosímetro": `Dosímetro`,
    "Para llevar a cabo las prácticas, será necesario contar con un dosímetro, dependiendo de si la empresa te va a proporcionar o no. En caso de requerirlo, el coste del alquiler del dosímetro será de 75€. Si en tu caso es necesario, te facilitaremos un enlace para que puedas realizar el pago.": `Para llevar a cabo las prácticas, será necesario contar con un dosímetro, dependiendo de si la empresa te va a proporcionar o no. En caso de requerirlo, el coste del alquiler del dosímetro será de 75€. Si en tu caso es necesario, te facilitaremos un enlace para que puedas realizar el pago.`,
    "Enviar el material a una dirección distinta al domicilio actual": `Enviar el material a una dirección distinta al domicilio actual`,
    "address_label": `<span>Dirección <span class='font-color-red'> *</span></span>`,
    "Por favor, indica en que dirección desea recibir el material.": `Por favor, indica en que dirección desea recibir el material.`,
    "postal_code_label": `<span>Código postal <span class='font-color-red'> *</span></span>`,
    "Por favor, indica en que código postal desea recibir el material.": `Por favor, indica en que código postal desea recibir el material.`,
    "Por favor, indica en que provincia desea recibir el material.": `Por favor, indica en que provincia desea recibir el material.`,
    "Por favor, indica en que población desea recibir el material.": `Por favor, indica en que población desea recibir el material.`,
    "Exención en tramitación": `Exención`,
    "Si tienes experiencia laboral previa, puedes solicitar una reducción o exención total de las horas de tus prácticas en empresas. Para hacerlo, deberás demostrar una experiencia mínima relacionada con el ciclo formativo que estás cursando. El claustro de profesores evaluará la documentación que envíes y te informará sobre el tipo de exención que se te pueda conceder.": `Si tienes experiencia laboral previa, puedes solicitar una reducción o exención total de las horas de tus prácticas en empresas. Para hacerlo, deberás demostrar una experiencia mínima relacionada con el ciclo formativo que estás cursando. El claustro de profesores evaluará la documentación que envíes y te informará sobre el tipo de exención que se te pueda conceder.`,
    "fct_percent_exemption_related": `{percent_number}: Experiencia laboral <span class='font-bold'>relacionada</span> con tu ciclo formativo (<span class='font-bold'>{percent_days} días cotizados</span>).`,
    "fct_percent_exemption_no_related": `{percent_number}: Experiencia laboral <span class='font-bold'>no relacionada</span> con tu ciclo formativo (<span class='font-bold'>{percent_days} días cotizados</span>).`,
    "Solicitar exención": `Solicitar exención`,
    "Por favor, indica un porcentaje de exención.": `Por favor, indica un porcentaje de exención.`,
    "exemption_label": `<span>Selecciona <span class='font-color-red'> *</span></span>`,
    "exemption_work_types_label": `<span>Tipo de régimen de la experiencia laboral previa <span class='font-color-red'> *</span></span>`,
    "Por favor, indica que tipo de experiencia laboral has tenido.": `Por favor, indica que tipo de experiencia laboral has tenido.`,
    "Deberás proporcionar los documentos que acrediten tu experiencia laboral.": `Deberás proporcionar los documentos que acrediten tu experiencia laboral.`,
    "Una vez que el claustro de profesores evalúe tu caso, se te otorgará el porcentaje correspondiente.": `Una vez que el claustro de profesores evalúe tu caso, se te otorgará el porcentaje correspondiente.`,
    "Documentos": `Documentos`,
    "Curriculum actualizado y con foto": `Curriculum actualizado y con foto`,
    "Es importante que el currículum esté actualizado y que incluya una fotografía tuya.": `Es importante que el currículum esté actualizado y que incluya una fotografía tuya.`,
    "Por favor, agrega un curriculum actualizado.": `Por favor, agrega un curriculum actualizado.`,
    "Certificado de empadronamiento": `Certificado de empadronamiento`,
    "Es un documento informativo emitido por tu ayuntamiento que certifica tu residencia y domicilio habitual.": `Es un documento informativo emitido por tu ayuntamiento que certifica tu residencia y domicilio habitual.`,
    "Por favor, agrega el certificado de empadronamiento.": `Por favor, agrega el certificado de empadronamiento.`,
    "Certificado de delitos de naturaleza sexual": `Certificado de Delitos de Naturaleza Sexual`,
    "no_sexual_offences_description": `<span>Puedes solicitar el certificado en la <a class='font-color-149BAB' target='_blank' href="https://sede.mjusticia.gob.es/es/tramites/certificado-registro-central">web del Ministerio de Justicia del Gobierno de España</a>, o en el Departamento de Justicia de tu municipio.</span>`,
    "Por favor, agrega el certificado de delitos de naturaleza sexual.": `Por favor, agrega el certificado de delitos de naturaleza sexual.`,
    "Cartilla de vacunas": `Cartilla de vacunas`,
    "Es recomendable que proporciones una fotocopia actualizada y completa de tu carné de vacunación. En caso de que no puedas obtenerlo, deberás aportar una serología (análisis de las vacunas que te han administrado hasta el momento). Estas son las vacunas que recomendamos: Las establecidas a nivel nacional, triple vírica, hepatitis B, tétanos y gripe (en época de gripe).": `Es recomendable que proporciones una fotocopia actualizada y completa de tu carné de vacunación. En caso de que no puedas obtenerlo, deberás aportar una serología (análisis de las vacunas que te han administrado hasta el momento). Estas son las vacunas que recomendamos: Las establecidas a nivel nacional, triple vírica, hepatitis B, tétanos y gripe (en época de gripe).`,
    "Por favor, agrega la cartilla de vacunas.": `Por favor, agrega la cartilla de vacunas.`,
    "Documentos para la exención": `Documentos para la exención`,
    "Solicitud de exención": `Solicitud de exención`,
    "Descarga la plantilla:": `Descarga la plantilla:`,
    "Complétala y luego vuelve a subirla.": `Complétala y luego vuelve a subirla.`,
    "Por favor, agrega la solicitud de exención.": `Por favor, agrega la solicitud de exención.`,
    "Certificado de tareas": `Certificado de tareas`,
    "Por favor, agrega el certificado de tareas.": `Por favor, agrega el certificado de tareas.`,
    "Informe de vida laboral": `Informe de vida laboral`,
    "work_life_label": `<span>Puedes solicitar el informe en la <a class='font-color-149BAB' target='_blank' href="https://portal.seg-social.gob.es/wps/portal/importass/importass/Categorias/Vida+laboral+e+informes/Informes+sobre+tu+situacion+laboral/Informe+de+tu+vida+laboral">web de la Tesorería de la Seguridad Social.</a></span>`,
    "Por favor, agrega el informe de vida laboral.": `Por favor, agrega el informe de vida laboral.`,
    "Declaración jurada": `Declaración jurada`,
    "Certificado de alta de la actividad económica": `Certificado de alta de la actividad económica`,
    "Por favor, agrega el certificado de alta de la actividad económica.": `Por favor, agrega el certificado de alta de la actividad económica.`,
    "economic_activity_label": `<span>Puedes solicitar el certificado en la <a class='font-color-149BAB' href='https://sede.agenciatributaria.gob.es/Sede/procedimientoini/G309.shtml' target='_blank'>web de la Agencia Tributaria</a>.</span>`,
    "Recibo de cotización en la Seguridad Social": `Recibo de cotización en la Seguridad Social`,
    "Sube el recibo más actual.": `Sube el recibo más actual.`,
    "Por favor, agrega el recibo de cotización en la seguridad social.": `Por favor, agrega el recibo de cotización en la seguridad social.`,
    "Cuota de Régimen de Autónomo": `Cuota de Régimen de Autónomo`,
    "fee_label": `<span>Sube la cuota más actual. Puedes consultarlo en la <a class='font-color-149BAB' href='https://sede.seg-social.gob.es/wps/portal/sede/sede/Ciudadanos/Informes+y+Certificados/201848' target='_blank'>Sede Electrónica de la Seguridad Social</a>.</span>`,
    "Por favor, agrega la cuota de régimen de autónomo.": `Por favor, agrega la cuota de régimen de autónomo.`,
    "Declaración trimestral (modelo 130)": `Declaración trimestral (modelo 130)`,
    "model130_label": `<span>Puedes consultar las declaraciones presentadas en la <a class='font-color-149BAB' href='https://sede.agenciatributaria.gob.es/Sede/tramitacion/G601.shtml' target='_blank'>web de la Agencia Tributaria</a>.</span>`,
    "Por favor,agrega el modelo 130 de la declaración trimestral.": `Por favor,agrega el modelo 130 de la declaración trimestral.`,
    "Guardando": `Guardando`,
    "Cambios guardados": `Cambios guardados`,
    "Salir": `Salir`,
    "Enviar documentación": `Enviar documentación`,
    "Si has encontrado empresa por tu cuenta, por favor, sube aquí la documentación correspondiente.": `Si has encontrado empresa por tu cuenta, por favor, sube aquí la documentación correspondiente.`,
    "Nos pondremos en contacto con la empresa para detallar tus prácticas.": `Nos pondremos en contacto con la empresa para detallar tus prácticas.`,
    "Cuando empieces las prácticas te enviaremos el bono de 40€.": `Cuando empieces las prácticas te enviaremos el bono de 40€.`,
    "Descárgate el documento de empresa:": `Descárgate el documento de empresa:`,
    "Datos de empresa.pdf": `Datos de empresa.pdf`,
    "Rellena el documento con los datos de la empresa.": `Rellena el documento con los datos de la empresa.`,
    "Adjunta el documento a continuación:": `Adjunta el documento a continuación:`,
    "Documento de empresa": `Documento de empresa`,
    "Por favor, agrega el documento con información de la empresa.": `Por favor, agrega el documento con información de la empresa.`,
    "Enviar solicitud": `Enviar solicitud`,
    "Solicitar aplazamiento": `Solicitar aplazamiento`,
    "Para solicitar el aplazamiento de tus prácticas, tienes enviarnos la solicitud de aplazamiento, así como la documentación que justifique tu solicitud.": `Para solicitar el aplazamiento de tus prácticas, tienes enviarnos la solicitud de aplazamiento, así como la documentación que justifique tu solicitud.`,
    "En cuanto la recibamos la enviaremos a la Consejería de Educación de la Comunidad de Madrid para que evalúe tu solicitud.": `En cuanto la recibamos la enviaremos a la Consejería de Educación de la Comunidad de Madrid para que evalúe tu solicitud.`,
    "En cuanto sepamos la resolución nos pondremos en contacto contigo.": `En cuanto sepamos la resolución nos pondremos en contacto contigo.`,
    "Podrás solicitar el aplazamiento hasta el 30 de septiembre en primera instancia y hasta el 31 de marzo en segunda instancia.": `Podrás solicitar el aplazamiento hasta el 30 de septiembre en primera instancia y hasta el 31 de marzo en segunda instancia.`,
    "Descárgate la solicitud de aplazamiento:": `Descárgate la solicitud de aplazamiento:`,
    "Anexo IV. Solicitud de aplazamiento": `Anexo IV. Solicitud de aplazamiento`,
    "Rellena la solicitud con tus datos.": `Rellena la solicitud con tus datos.`,
    "Adjunta la solicitud y los documentos que acrediten tu aplazamiento a continuación:": `Adjunta la solicitud y los documentos que acrediten tu aplazamiento a continuación:`,
    "Documento de aplazamiento": `Documento de aplazamiento`,
    "Por favor, agrega el documento de aplazamiento.": `Por favor, agrega el documento de aplazamiento.`,
    "Descárgate la plantilla de valoraciones finales:": `Descárgate la plantilla de valoraciones finales:`,
    "Valoraciones finales.pdf": `Valoraciones finales.pdf`,
    "Rellena los apartados": `Rellena los apartados`,
    "REF10/Encuesta de satisfacción’ y ‘REF15/Valoración Final": `REF10/Encuesta de satisfacción’ y ‘REF15/Valoración Final`,
    "Documento de valoraciones finales": `Documento de valoraciones finales`,
    "Por favor, agrega el documento de valoraciones finales.": `Por favor, agrega el documento de valoraciones finales.`,
    "Cancelar": `Cancelar`,
    "Para poder gestionar adecuadamente tus prácticas, necesitamos que proporciones la siguiente documentación:": `Para poder gestionar adecuadamente tus prácticas, necesitamos que proporciones la siguiente documentación:`,
    "Datos del estudiante": `Datos del estudiante`,
    "Por favor, verifica y confirma la información que tenemos sobre ti, así como tus preferencias de horario para llevar a cabo las prácticas.": `Por favor, verifica y confirma la información que tenemos sobre ti, así como tus preferencias de horario para llevar a cabo las prácticas.`,
    "Datos de la empresa": `Datos de la empresa`,
    "(solo si ya tienes una): Si ya tienes seleccionada la empresa donde realizarás las prácticas, te pedimos que nos proporciones los detalles correspondientes. En caso de que aún no tengas una empresa asignada, no te preocupes, nosotros nos encargaremos de encontrar una que se ajuste a tus necesidades.": `(solo si ya tienes una): Si ya tienes seleccionada la empresa donde realizarás las prácticas, te pedimos que nos proporciones los detalles correspondientes. En caso de que aún no tengas una empresa asignada, no te preocupes, nosotros nos encargaremos de encontrar una que se ajuste a tus necesidades.`,
    "Solicitud de exención": `Solicitud de exención`,
    "Si cuentas con experiencia laboral previa, tienes la opción de solicitar una reducción parcial o total de las horas de prácticas.": `Si cuentas con experiencia laboral previa, tienes la opción de solicitar una reducción parcial o total de las horas de prácticas.`,
    "Subir documentación": `Subir documentación`,
    "Hemos recibido la documentación que has proporcionado de manera correcta.": `Hemos recibido la documentación que has proporcionado de manera correcta.`,
    "En cuanto la hayamos validado y se inicie el semestre, procederemos a los trámites pertinentes a tus prácticas.": `En cuanto la hayamos validado y se inicie el periodo, procederemos a los trámites pertinentes a tus prácticas.`,
    "Hemos detectado los siguientes errores en la documentación que has aportado:": `Hemos detectado los siguientes errores en la documentación que has aportado:`,
    "Por favor, revísalos y envía de nuevo la documentación.": `Por favor, revísalos y envía de nuevo la documentación.`,
    "Revisar documentación": `Revisar documentación`,
    "Lamentablemente, no has subido la documentación a tiempo. Por eso, no podrás empezar las prácticas el próximo semestre. Te avisaremos de nuevo cuando esté abierto el nuevo plazo de documentación.": `Lamentablemente, no has subido la documentación a tiempo. Por eso, no podrás empezar las prácticas el próximo semestre. Te avisaremos de nuevo cuando esté abierto el nuevo plazo de documentación.`,
    "Estamos en busca de una empresa que se ajuste a tus necesidades específicas.": `Estamos en busca de una empresa que se ajuste a tus necesidades específicas.`,
    "No te preocupes, una vez hayamos seleccionado la empresa adecuada para que puedas comenzar tus prácticas, nos pondremos en contacto contigo.": `No te preocupes, una vez hayamos seleccionado la empresa adecuada para que puedas comenzar tus prácticas, nos pondremos en contacto contigo.`,
    "Hasta el momento, hemos propuesto tu perfil a": `Hasta el momento, hemos propuesto tu perfil a`,
    "empresas": `empresas`,
    "Hemos recibido la información de la empresa que has proporcionado.": `Hemos recibido la información de la empresa que has proporcionado.`,
    "MAD_IT_FR_proximamente": 'Próximamente nos pondremos en contacto con ellos y te informaremos sobre los pasos a seguir.',
    "Próximamente nos pondremos en contacto con ellos y te informaremos sobre los pasos a seguir.": `Si la empresa que has aportado es correcta, adelantaremos nuestro objetivo y nos encargaremos de cerrar el convenio antes del `,
    "Estamos en conversación con la empresa para poder acordar las condiciones y fecha de inicio de tus prácticas.": `Estamos en conversación con la empresa para poder acordar las condiciones y fecha de inicio de tus prácticas.`,
    "Cuando este listo, contactaremos contigo para notificarte el acuerdo final.": `Cuando este listo, contactaremos contigo para notificarte el acuerdo final.`,
    "Convenio de prácticas:": `Convenio de prácticas:`,
    "Fecha de inicio:": `Fecha de inicio:`,
    "Fecha de fin:": `Fecha de fin:`,
    "Durante la realización de las prácticas, tienes que acceder al aplicativo Qbid y registrar las horas y las tareas que vas realizando cada día": `Durante la realización de las prácticas, tienes que acceder al aplicativo Qbid y registrar las horas y las tareas que vas realizando cada día`,
    "Guía de uso Qbid": `Guía de uso Qbid`,
    "Vídeo explicativo": `Vídeo explicativo`,
    "Cuando queden 15 días para finalizar tus prácticas, tendrás que subir las": `Cuando queden 15 días para finalizar tus prácticas, tendrás que subir las`,
    "valoraciones finales": `valoraciones finales`,
    "Cada 15 días tendrás que enviarnos la ‘Ficha semanal del alumno (Anexo 7)’ firmado por tu tutor, para hacer seguimiento.": `Cada 15 días tendrás que enviarnos la ‘Ficha semanal del alumno (Anexo 7)’ firmado por tu tutor, para hacer seguimiento.`,
    "Cuando queden 10 días para finalizar tus prácticas, tendrás que enviarnos por correo postal el": `Cuando queden 10 días para finalizar tus prácticas, tendrás que enviarnos por correo postal el`,
    "Anexo 8 (Informe del tutor del centro de trabajo)": `Anexo 8 (Informe del tutor del centro de trabajo)`,
    "firmado por tu tutor.": `firmado por tu tutor.`,
    "Fichas semanales": `Fichas semanales`,
    "Descárgate la": `Descárgate la`,
    "Ficha semana del alumno (Anexo 7)": `Ficha semana del alumno (Anexo 7)`,
    "e imprímela.": `e imprímela.`,
    "Junto a tu tutor de prácticas tenéis que rellenarla y firmarla.": `Junto a tu tutor de prácticas tenéis que rellenarla y firmarla.`,
    "Escanéala de nuevo y súbela aquí:": `Escanéala de nuevo y súbela aquí:`,
    "Fichas semanales subidas:": `Fichas semanales subidas:`,
    "Todavía no has subido ninguna ficha semanal": `Todavía no has subido ninguna ficha semanal`,
    "Tras revisar tu documentación, eres susceptible a la exención del 100% de las prácticas en empresa. A lo largo del semestre se actualizará tu expediente académico con la exención y podrás verlo a través del expediente académico": `Tras revisar tu documentación, eres susceptible a la exención del 100% de las prácticas en empresa. A lo largo del semestre se actualizará tu expediente académico con la exención y podrás verlo a través del expediente académico`,
    "El cierre de tus prácticas se ha realizado correctamente.": `El cierre de tus prácticas se ha realizado correctamente.`,
    "Podrás ver la nota en tu expediente académico.": `Podrás ver la nota en tu expediente académico.`,
    "Si con las prácticas finalizas el ciclo formativo, tras el cierre de las actas, recibirás un correo electrónico desde Secretaría Académica de ILERNA con la información necesaria para solicitar el título oficial.": `Si con las prácticas finalizas el ciclo formativo, tras el cierre de las actas, recibirás un correo electrónico desde Secretaría Académica de ILERNA con la información necesaria para solicitar el título oficial.`,
    "He leído la normativa de la Formación en centros de trabajo y acepto las condiciones.": `He leído la normativa de la Formación en centros de trabajo y acepto las condiciones.`,
    "fct_date_timeline": `d 'de' LLLL 'de' y`,
    "Esto es un borrador. Una vez hayas subido toda la información, por favor, envíala para que podamos proceder a su validación.": `<span><span class='font-bold'>Esto es un borrador.</span> Una vez hayas subido toda la información, por favor, envíala para que podamos proceder a su validación.</span>`,
    "fct_international_help": `Debe completar el documento en español y para facilitarle la tarea, le proporcionamos la traducción al francés del mismo:`,
    "fct_contribute_comp_international_help_document": `Datos de la empresa (castellano).pdf`,
    "fct_contribute_comp_international_help_document_link": `https://apifct.ilernaonline.com/api/v1/myaccount/static/company`,
    "fct_request_postponement_international_help_document": `Anexo IV. Solicitud de prórroga (castellano).pdf`,
    "fct_request_postponement_international_help_document_link": `https://apifct.ilernaonline.com/api/v1/myaccount/static/deferral`,
    "fct_exemption_international_help_document": `Solicitud de exención (castellano).pdf`,
    "fct_exemption_international_help_document_link": `https://apifct.ilernaonline.com/api/v1/myaccount/static/exemption`,
    "fct_cert_tareas_international_help_document": `Certificado de tareas (castellano).pdf`,
    "fct_cert_tareas_international_help_document_link": `https://apifct.ilernaonline.com/api/v1/myaccount/static/tasks`,
    "fct_declaracion_jurada_international_help_document": `Declaración jurada (castellano).pdf`,
    "fct_declaracion_jurada_help_document_link": `https://apifct.ilernaonline.com/api/v1/myaccount/static/affidavit`,
    "fct_anexo_8_tutor_international_help_document": `Anexo 8 (Informe del tutor del centro de trabajo)(castellano).pdf`,
    "fct_anexo_8_tutor_help_document_link": `https://apifct.ilernaonline.com/api/v1/myaccount/static/tutor`,
    "fct_anexo_7_semana_alumno_international_help_document": `Anexo 7 (Hoja de la semana del estudiante)(castellano).pdf`,
    "fct_anexo_7_semana_alumno_tutor_help_document_link": `https://apifct.ilernaonline.com/api/v1/myaccount/static/weekly`,
    "Accede a los contenidos y aulas virtuales de tus asignaturas.": `Accede a los contenidos y aulas virtuales de tus asignaturas.`,
    "DOCUMENTACION": `DOCUMENTACIÓN`,
    "ASIGNACION EMPRESA": `ASIGNACIÓN EMPRESA`,
    "VALIDACION CONVENIO": `VALIDACIÓN CONVENIO`,
    "INICIO PRACTICAS": `INICIO PRÁCTICAS`,
    "EVALUACION PRACTICAS": `EVALUACIÓN PRÁCTICAS`,
    "FIN PRACTICAS": `FIN PRÁCTICAS`,
    "EXENCION 100%": `EXENCIÓN 100%`,
    "BONO": `BONO`,
    "APLAZAMIENTO": `APLAZAMIENTO`,
    "Normativa Formación en Centros de Trabajo": `Normativa Formación en Centros de Trabajo`,
    "Exención": `Exención`,
    "Trabajador por cuenta ajena": `Trabajador por cuenta ajena`,
    "Autónomo/a": `Autónomo/a`,
    "¿Has logrado encontrar por tu cuenta una empresa para llevar a cabo tus prácticas?": `¿Has logrado encontrar por tu cuenta una empresa para llevar a cabo tus prácticas?`,
    "exams_no_building_address_2": `Podrás realizar tu inscripción en las sedes ILERNA en las fechas y horas donde quede aforo`,
    "Exámenes de asignaturas que estás cursando": `Exámenes de asignaturas que estás cursando`,
    "Exámenes de asignaturas del próximo periodo": `Exámenes de asignaturas del próximo periodo`,
    "Clases de asignaturas que estás cursando": `Clases de asignaturas que estás cursando`,
    "Clases de asignaturas del próximo periodo": `Clases de asignaturas del próximo periodo`,
    "exams_date_limit_2": `Podrás realizar tu inscripción en las sedes ILERNA en las fechas y horas donde quede aforo. El período de inscripción permanecerá abierto hasta el {EXAM_DATE_LIMIT} a las 12 del mediodía`,
    "pre_documentation_page_1": `Te recordamos que la comunicación con nuestros gestores a través de este canal`,
    "pre_documentation_page_2": `no estará disponible hasta el inicio del semestre el 8 de febrero.`,
    "pre_documentation_page_3": `Sin embargo, si necesitamos alguna información adicional de tu parte durante el proceso de validación de documentos, no te preocupes, nos pondremos en contacto contigo.`,
    "input_seguridad_social": `<span>Número de la Seguridad Social <span class='font-color-red'> *</span></span>`,
    "hint_seguridad_social": `<span>Si todavía no tienes el número porque estás afiliado/a a la Seguridad Social puedes <a class='font-color-149BAB' href="https://www.seg-social.es/wps/portal/wss/internet/InformacionUtil/44539/44084" target='_blank'> iniciar el trámite en la web del Ministerio de Inclusión, Seguridad Social y Migraciones</a>.</span> `,
    "Por favor, indica un número de Seguridad Social.": `Por favor, indica un número de Seguridad Social correcto (12 dígitos).`,
    "faltan_clases_presenciales": `No puedes presentarte al examen de esta asignatura porque no has asistido a las clases presenciales.`,
    "consulta_recomendaciones": `Consulta nuestras recomendaciones`,
    "recomendaciones_ec_titulo": `Recomendaciones de la evaluación continua`,
    "recomendaciones_ec_desc": `Para aumentar tus probabilidades de aprobar el examen final, te recomendamos completar todas las PACs`,
    "UF": `UF`,
    "porcentaje_pacs": `% PACs entregadas`,
    "nota_ec": `Nota EC`,
    "recomendacion_segun_nota_y_pacs_entregadas": `Recomendación`,
    "recomendacion_80_aprobado_ec": `¡Enhorabuena! Has aprobado la Evaluación Continua. Hasta la fecha de tu examen, puedes practicar con las PACs de recuperación (recuerda que estas actividades no subirán nota).`,
    "recomendacion_80_suspenso_ec": `Tu trabajo ha sido constante, aún así te recomendamos realizar las PACs de recuperación para poder subir tu nota de la Evaluación Continua antes de tu examen.`,
    "recomendacion_no_80_aprobado_ec": `Has aprobado la Evaluación Continua, pero no has llegado al mínimo de entregas recomendadas. Si recuperas tus PACs, irás mucho más preparad@ al examen.`,
    "recomendacion_no_80_suspenso_ec": `Para poder acudir con mayor garantía al examen recuerda que es muy importante que hagas la Evaluación Continua, ya que supone el 40% de tu nota.`,
    "faltan_pacs_en_uf": `Todavía no están disponibles todas las PACs de la UF.`,
    "no_asistencia_examen": `No has asistido a este examen. Puedes hacerlo en la próxima convocatoria extraordinaria.`,
    "Si tienes alguna duda puedes": `Si tienes alguna duda puedes`,
    "Imprime 3 copias, una para la empresa, otra para ti, y otra para ILERNA.": `Imprime 3 copias, una para la empresa, otra para ti, y otra para ILERNA.`,
    "horas": `horas`,
    "disabled_page_greeting": `¡Hola Onliner!`,
    "disabled_page_text_1": `Lamentamos informar que temporalmente enfrentamos problemas técnicos en esta sección.`,
    "disabled_page_text_2": `Estamos trabajando diligentemente para resolverlos y restaurar el servicio lo mas rápido posible.`,
    "disabled_page_text_3": `Apreciamos tu paciencia y comprensión mientras abordamos estos inconvenientes. 💙`,
    "disabled_page_farewell": `Tu equipo de ILERNA Online`,
    "Selecciona un período académico": `Selecciona un período académico`,
    "Selecciona una opción": `Selecciona una opción`,
    "no_buildings_available": `No hay eventos disponibles.`,
    "no_events_available": `Esta opción no está disponible.`,
    "El DNI está caducado. Debes actualizarlo para que podamos ponerlo en tu expediente": `El DNI está caducado. Debes actualizarlo para que podamos ponerlo en tu expediente`,
    "evau_alert_banner": `¡IMPORTANTE! Este mensaje se dirige exclusivamente a las personas que quieran presentarse a la EVAU/Selectividad este mes de junio.`,
    "evau_modal_link": `Haz click aquí para leerlo`,
    "evau_alert_documents_pending_validation": `Tu documentación para la EVAU/Selectividad está en revisión.`,
    "evau_alert_documents_validated": `Documentación para la EVAU/Selectividad validada correctamente.`,
    "evau_modal_title": `Documentación para EVAU/Selectividad`,
    "evau_modal_greeting": `Hola Onliner,`,
    "evau_modal_1": `Si vas a presentarte a las pruebas de la EVAU/Selectividad en el mes de junio, ILERNA Online realizará <span class='font-bold'>una convocatoria de exámenes anticipada para que puedas finalizar tu ciclo en el mes de mayo</span>.`,
    "evau_modal_2": `Para poder realizar la inscripción a esta convocatoria debes habernos aportado con anterioridad la documentación requerida. Una vez tu documentación se haya revisado y validado, <span class='font-bold'>podrás inscribirte a los exámenes. Esta inscripción se abrirá el jueves 2 de mayo a las 12:00 y tendrás tiempo para inscribirte hasta el martes 14 de mayo a las 12 pm.</span>`,
    "evau_modal_3": `Estos exámenes se realizarán <span class='font-bold'>el viernes, 17 de mayo</span class='font-bold'> en nuestras sedes de ILERNA. Su respectiva convocatoria de recuperación se realizará <span class='font-bold'>el viernes día 31 de mayo.</span>`,
    "evau_modal_4": `Si ya te habías inscrito previamente a la convocatoria ordinaria general, no debes preocuparte, ya que una vez realices la inscripción, tu anterior inscripción quedará anulada.`,
    "factura_presencial": `Para obtener la factura, acércate a tu centro presencial.`,
    "recognition_requested": `Convalidación solicitada`,
    "partial_recognition_requested": `Convalidación parcial solicitada`,
    "recognition_denied": `La convalidación que solicitaste ha sido denegada.`,
    "partial_recognition_denied": `La convalidación parcial que solicitaste ha sido denegada.`,
    "recognition_management_will_start": `La tramitación se iniciará en {datelimit}`,
    "recognition_management_started": `La tramitación se ha iniciado en {datelimit}`,
    "recognition_more_info": `Más información`,
    "recognition_requested_details_title": `Has solicitado la convalidación de esta asignatura.`,
    "partial_recognition_requested_details_title": `Has solicitado la convalidación parcial  de esta asignatura.`,
    "recognition_management_started_details_title": `Tramitación de la convalidación iniciada.`,
    "partial_recognition_management_started_details_title": `Tramitación de la convalidación parcial iniciada.`,
    "recognition_denied_details_title": `La convalidación que solicitaste ha sido denegada.`,
    "partial recognition_denied_details_title": `La convalidación parcial que solicitaste ha sido denegada.`,
    "recognition_denied_details_cat": `Tendrás la asignatura disponible el próximo semestre en el campus para poder cursarla.`,
    "partial_recognition_denied_details_cat": `Tendrás la asignatura disponible el próximo semestre en el campus para poder cursar las UFs que te falten.`,
    "recognition_denied_details_mad": `<span class='font-bold'>Importante:</span>Tienes que acabar de cursar la asignatura y presentarte al examen.`,
    "recognition_max_management_time_limit": `La resolución puede tardar <span class='font-bold'>hasta 6 meses</span> desde el inicio de la tramitación.`,
    "recognition_requested_must_course_cat": `<span class='font-bold'>Importante:</span>Tendrás que cursar las UFs de las que no hayas solicitado la convalidación.`,
    "recognition_requested_must_course_mad": `<span class='font-bold'>Importante:</span>Tendrás que cursar la asignatura hasta conocer la resolución. Si el organismo responsable la aprueba, podrás dejar de cursarla.`,
    "recognition_management_start_must_course_cat": `<span class='font-bold'>Importante:</span>Tienes que cursar las UFs de las que no has solicitado la convalidación.`,
    "recognition_management_start_must_course_mad": `<span class='font-bold'>Importante:</span>Tienes que cursar la asignatura hasta conocer la resolución. Si el organismo responsable la aprueba, podrás dejar de cursarla.`,
    "recognition_entity_center": `ILERNA.`,
    "recognition_entity_generalitat": `Departament d’Ensenyament de la Generalitat de Catalunya.`,
    "recognition_entity_consejeria_mad": `Consejería de Educación de la Comunidad de Madrid.`,
    "recognition_entity_ministerio": `Ministerio de Educación y Formación Profesional.`,
    "onliner_area_comming_soon": `Próximamente publicaremos más información académica sobre tu ciclo formativo`,
    "project_grade_missing_fct_grade_tooltip": `La nota de esta asignatura está ligada a la calificación de la asignatura FCT`,
    "fct_grade_missing_project_grade_tooltip": `La nota de esta asignatura está ligada a la calificación de la asignatura Proyecto`,
    "Si entregas tu documentación correcta antes del ": `Si entregas tu documentación correcta antes del `,
    "La validaremos antes del ": `La validaremos antes del `,
    "Nuestro objetivo será tener el convenio cerrado antes del ": `Nuestro objetivo será tener el convenio cerrado antes del `,
    "Plazas disponibles para el periodo: ": `Plazas disponibles para el periodo: `,
    "En breve validaremos y te informaremos si todo está correcto.": `En breve validaremos y te informaremos si todo está correcto.`,
    "Validaremos la documentación antes del ": `Validaremos la documentación antes del `,
    "En caso de que la documentación esté completa y sin errores, nuestro objetivo es tener el convenio de prácticas firmado antes del ": `En caso de que la documentación esté completa y sin errores, nuestro objetivo es tener el convenio de prácticas firmado antes del `,
    "Carta motivación": 'Carta motivación',
    "Por favor, agrega la carta de motivación.": 'Por favor, agrega la carta de motivación.',
    "Descarga el formulario:" : 'Descarga el formulario:',
    "Complétalo y súbelo de nuevo.": ' Complétalo y súbelo de nuevo.',
    "Debe completar el documento en español y para facilitarle la tarea, le proporcionamos la traducción en francés del mismo:": 'Debe completar el documento en español y para facilitarle la tarea, le proporcionamos la traducción en francés del mismo:',
    "carta_motivacion": 'Carta_Motivacion.pdf',
    "alert_MAD": `<p>Mientras nosotros seguimos buscando una empresa para ti, si has encontrado una por tu cuenta, por favor, sube aquí la documentación correspondiente. Recuerda que si proporcionas una empresa, recibirás un <strong>bono de 40€.</strong></p>`,
    "signature_page_commercial_info": `
    <ul>
    <li class="m-x2-top"><span class="font-bold">RESPONSABLE:</span> GRUPO ILERNA (https://www.ilerna.es/), formado por las mercantiles: Ilerna Online, S.L. (B25774720), Centre Integral de FP Ilerna, S.L. (B25026428), Ilerna Tarragona, S.L. (B42835900), Formación Profesional Albor, S.L. (B11944261) CDP Albor Madrid, S.L. (B42970079) y CDP Albor Córdoba, S.L. (B56078033), Turo de Gardeny, Complejo Magical Media, Edif 5 (35003), Lleida España, ilerna@ delegado-datos.com.</li>
    <li class="m-x2-top"><span class="font-bold">FINALIDADES:</span> Tramitar la matrícula, formación contratada y alta en la plataforma de formación. Remitirle información comercial sobre nuestros cursos, actividades, noticias, newsletter, bolsa de trabajo, así como cualquier oferta de servicios y productos relacionados con nuestra actividad.</li>
    <li class="m-x2-top"><span class="font-bold">LEGITIMACIÓN:</span> Ejecución del contrato de matrícula con el alumno. Interés legítimo de la entidad en mantener informados a sus alumnos.</li>
    <li class="m-x2-top"><span class="font-bold">CESIONES:</span> Centros colaboradores para la gestión de la matrícula y pago. Los datos personales que voluntariamente incorpore a la plataforma de formación serán públicos para los demás alumnos del curso.</li>
    <li class="m-x2-top"><span class="font-bold">CONSERVACIÓN:</span> Durante la vigencia de los estudios y, finalizados éstos, durante los plazos legales de conservación de los expedientes académicos o para atender eventuales responsabilidades. Envíos comerciales: hasta que el alumno solicite su baja.</li>
    <li class="m-x2-top"><span class="font-bold">DERECHOS:</span> Tiene derecho a solicitar el acceso, rectificación, supresión, oposición, limitación y portabilidad de sus datos dirigiéndose a los datos de contacto del responsable. En caso de divergencias, puede presentar una reclamación ante la Autoridad de Protección de Datos (http://www.agpd.es ).</li>
    </ul>`,
    "fct_rgpd_info": `
    <ul>
    <li class="m-x2-top"><span class="font-bold">RESPONSABLE:</span> GRUPO ILERNA (https://www.ilerna.es/ ), formado por las mercantiles: Ilerna Online, S.L. (B25774720), Centre Integral de FP Ilerna, S.L. (B25026428), Ilerna Tarragona, S.L. (B42835900), Formación Profesional Albor, S.L. (B11944261) CDP Albor Madrid, S.L. (B42970079) y CDP Albor Córdoba, S.L. (B56078033), Turo de Gardeny, Complejo Magical Media, Edif 5 (35003), Lleida España, ilerna@delegado-datos.com.</li>
    <li class="m-x2-top"><span class="font-bold">FINALIDAD:</span> Gestionar las prácticas de nuestros alumnos en las empresa colaboradoras.</li>
    <li class="m-x2-top"><span class="font-bold">LEGITIMACIÓN:</span> Acuerdo de matrícula y participación en la prácticas.</li>
    <li class="m-x2-top"><span class="font-bold">CESIONES:</span> Empresas destinatarias de sus servicios a los que de facilitarán sus datos identificativos y curriculares, así como certificados de penales para prácticas con menores de edad involucrados. Casos legalmente previstos.</li>
    <li class="m-x2-top"><span class="font-bold">CONSERVACIÓN:</span> Durante la vigencia de las prácticas y duración de los cursos y, durante los plazos legales de conservación para atender eventuales responsabilidades y conservación de expedientes académicos.</li>
    <li class="m-x2-top"><span class="font-bold">DERECHOS:</span> Puede ejercer su derecho de acceso, rectificación, supresión, portabilidad, limitación y oposición dirigiéndose a los datos del responsable. En caso de divergencias, puede presentar una reclamación ante la Agencia de Protección de Datos (http://www.aepd.es ).</li>
    </ul>`,
    "fct_rgpd_title": `Información sobre protección de datos de ILERNA`,
    "fct_rgpd_checkbox": `He leído y acepto la información sobre protección de datos de ILERNA.`,
    "signature_page_commercial_info_title": `Información sobre la protección de datos de los alumnos`,
    "signature_page_commercial_info_checkbox": `No deseo recibir informaciones comerciales.`,
    "Configurar comunicaciones": `Configurar comunicaciones`,
    "academic_info": `Información académica`,
    "academic_info_subtitle": `Recibe todas la novedades relativas a tu ciclo: inscripción a exámenes, publicación de notas, comunicados oficiales, etc.`,
    "academic_info_subtext": `Recibir notificaciones de comunicados nuevos:`,
    "academic_info_checkbox_mail": `En la dirección de email`,
    "academic_info_checkbox_app": `En la app de Móvil de ILERNA`,
    "commercial_info": `Información comercial`,
    "commercial_info_subtitle": `Entérate de nuestros descuentos para tu matrícula.`,
    "commercial_info_checkbox_receive": `Recibir e-mails con promociones y descuentos`,
    "error_cart_link": `Se ha producido un error al generar el carrito`,
    "notifications_subtitle": `Configura cómo quieres recibir las comunicaciones académicas y comerciales`,
    "download_finished_ap_cert": `Descargar certificado de ciclo finalizado`,
    "download_finished_ap_uni_cert": `Descargar certificado de ciclo finalizado para estudios universitarios`,
    "Certificado de ciclo formativo finalizado para acceder a la universidad (firma digital)": `Certificado de ciclo formativo finalizado para acceder a la universidad (firma digital)`,
    "Certificado de ciclo formativo finalizado para acceder a la universidad": `Certificado de ciclo formativo finalizado para acceder a la universidad`,
    "Certificado de ciclo formativo finalizado (firma digital)": `Certificado de ciclo formativo finalizado (firma digital)`,
    "Certificado de ciclo formativo finalizado": `Certificado de ciclo formativo finalizado`,
    "Obtén tu certificado de notas compulsado digitalmente. Podrás solicitarlo una única vez y lo recibirás por correo electrónico.": `Obtén tu certificado de notas compulsado digitalmente. Podrás solicitarlo una única vez y lo recibirás por correo electrónico.`,
    "Descarga tu certificado de notas para la universidad con sello y firma.": `Descarga tu certificado de notas para la universidad con sello y firma.`,
    "Descarga tu certificado de notas con sello y firma.": `Descarga tu certificado de notas con sello y firma.`,
    "Se ha producido un error en el envío": `Se ha producido un error en el envío`,
    "Email enviado correctamente": `Email enviado correctamente`,
    "Solicitar certificado de ciclo finalizado": `Solicitar certificado de ciclo finalizado`,
    "Enviando email": `Enviando email`,
    "Solicitar certificado": `Solicitar certificado`,
    "El certificado no esta disponible": `El certificado no esta disponible`,
    "Centro": `Centro`,
    "codigo": `código`,
    "input_IBAN": `<span class="font-bold">Número de cuenta (IBAN) <span class='font-color-red'> *</span></span>`,
    "text_IBAN": `Para poder gestionar la devolución de los 40€ por proporcionar la empresa, es necesario que nos remitas tu número de IBAN donde efectuaremos el ingreso de dicho importe.`,
    "rematricula_b2b_online": `<p>Recuerda que esta matrícula únicamente incluye <b>formación online</b> a través de ILERNA Online, si necesitas clases de apoyo presencial y tu centro las ofrece, deberás rematricularte directamente con tu centro formativo</p>`,
    "rematricula_disabled_b2b": `<p>Ya te has presentado a todas las convocatorias disponibles de esta asignatura.</p><p><b>Contacta con tu centro para formalizar la rematrícula.</b></p>`,
    "signature_page_updated_conditions": `Hola {name}. Hemos actualizado las condiciones de contratación. Por favor, léelas y fírmalas para continuar.`
};

export default t;
