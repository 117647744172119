import { h } from "preact";
import { t } from '../../../_i18n';

export default function RequestDeferralMAD () {
    return (
        <div className='m-top-x2'>
            <p className='font-bold'>{t('Las prácticas estan aplazadas.')}</p>
            <p>{t('Ponte en contacto con nosotros cuando quieras reactivar las prácticas.')}</p>
        </div>
    )
}
