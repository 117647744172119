import {createReducer} from "../../../_common/common.ts";
import {CLEAR_NOTIFICATION, NTYPE_INFO, SET_NOTIFICATION, SET_STATUS_MESSAGES} from "../actions/constants";

const initialState = [];

const reducer = createReducer(initialState, {

    [SET_STATUS_MESSAGES]: (state, status)=>{

        const messages = status.notifications ? status.notifications.map(n=>({id: null, type: NTYPE_INFO, ...n})) : [];

        // eliminamos todas las que tienen id null
        state = [...state.filter(n=>n.id !== null), ...messages];
        return state;
    },

    [SET_NOTIFICATION]: (state, not)=>{
        not.id = state.length+1;
        state.push(not);
    },

    [CLEAR_NOTIFICATION]: (state, not)=>{
        const idx = state.findIndex(n => n.id == not.id);
        if(idx === -1) return;
        state.splice(idx, 1);
    }

});

export default reducer;
