import React, { Component, createRef, Fragment, h } from "preact";
import { AppPage } from "../../_common/components/app-page";
import { connect } from "react-redux";
import {
    uploadDocument, uploadSignature, fetchStudent
} from "../../_rx/student/actions";

import { C, CF, E, FR, R } from "../../_common/layout";

import { t } from '../../_i18n';

import CanvasDraw from "react-canvas-draw";
import { EsRequirements } from "../components/es-requirements";
import { PS_SHOPID_ES } from "../../constants";
import { Form as FinalForm } from 'react-final-form'
import { TechnicalRequirements } from "../components/it-technical-requirements";
import { isSpanishStudent } from "../../utils";
import getLangByShopId, { isSpanishUser } from "../../_common/utils/getLang";
import { RadioCheckbox } from '../../_common/forms/components/components';
import AlertBox from '../../_common/components/AlertBox/AlertBox';
import { Checkbox } from "../../_common/forms/components/Checkbox/Checkbox";

import './signature-page.scss';


const TermsES = () =>
    <CF className="sm-card max-width-sm">
        <div className="sm-p-x2-top sm-p-x2-bottom sm-p-left sm-p-right t-justify">
            <h1 className='font-24'>CONDICIONES GENERALES DE CONTRATACIÓN</h1>
            <h2 className='m-x2-top'>1. Condiciones de venta</h2>
            <p>
                El presente acuerdo regula las condiciones generales de compra de los diversos productos y servicios
                ofertados en la presente página web por ILERNA
                ONLINE, S.L.U. (en adelante ILERNA Online) con CIF nº B25774720 y domicilio en Turó de Gardeny,
                Complejo Magical Media, Edificio 5 (25003) Lleida
            </p>
            <p>
                El CLIENTE, en adelante el ALUMNO, en el momento que completa el proceso de registro, y compra algún
                Producto Formativo, acepta y se somete
                expresamente a las cláusulas especificadas a continuación, así como a las condiciones de acceso y
                uso de nuestro portal y su política de privacidad, accesibles
                a través del enlace “Avisos Legales”.
            </p>
            <p>
                El ALUMNO reconoce disponer de capacidad legal suficiente para obligarse contractualmente y que
                posee los requisitos académicos necesarios para cursar
                la formación contratada.
            </p>
            <p>
                En caso de que un ALUMNO se haya prematriculado de un Producto Formativo en el cual no cumple los
                requisitos de acceso se le devolverá el 100% del
                importe total de su prematricula, siempre y cuando el mismo haya presentado la documentación de los
                requisitos de acceso hasta a un máximo de 14 días
                naturales después de la fecha de inicio del curso.
            </p>
            <p>
                Si cualquier ALUMNO PREMATRICULADO, transcurridos 30 días después de la fecha de inicio del curso,
                no ha presentado la documentación requerida
                para la formalización de su matrícula, esta dejará de tener validez académica y, en consecuencia, no
                podrá realizar los exámenes oficiales obligatorios para
                la obtención de la titulación. El ALUMNO, además, no podrá seguir la actividad académica ordinaria e
                ILERNA Online efectuará la devolución del importe
                abonado en concepto de matrícula.
            </p>
            <p>
                Independientemente de lo mencionado en los dos párrafos anteriores, teniendo en cuenta que existen
                Productos Formativos, en los que las plazas son
                limitadas, la prematricula tendrá la condición de reserva/fianza en aras a garantizar la plaza del
                alumno frente a terceras peticiones que por cupo u ocupación
                no puedan ser atendidas. Como consecuencia de ello, frente a la baja del alumno independientemente
                de su causa, el importe abonado en concepto de
                matriculación no será devuelto, pasando a ser propiedad de ILERNA Online como cláusula
                indemnizatoria contractual.
            </p>
            <p>
                Los requisitos de acceso se especifican en la página web de ILERNA Online, entrando en el Ciclo
                Formativo de Formación Profesional correspondiente.
            </p>
            <p>
                No existe la posibilidad de matricularse en un crédito/módulo ya aprobado con anterioridad en otro
                centro. En caso de que ILERNA Online identifique esta
                incompatibilidad prevalecerá siempre la primera nota obtenida y no tendrá derecho a reembolso de la
                misma, salvo en caso de desistir dentro de los 14 días
                naturales establecidos para el ejercicio del derecho de desistimiento.
            </p>
            <p>
                Del mismo modo, es incompatible estar matriculado de una misma asignatura en dos centros docentes a
                la vez y/o estar inscrito a las pruebas libres de dicha
                asignatura. En caso de incumplir esta norma, la matricula realizada en ILERNA Online y las notas
                obtenidas quedarán anuladas y por ende no tendrá derecho a
                reembolso de la misma, salvo en caso de desistir dentro de los 14 días naturales establecidos para
                el ejercicio del derecho de desistimiento.
            </p>
            <p>
                La matriculación en cada uno de los módulos profesionales requiere que el alumno no haya agotado el
                número máximo de convocatorias, según normativa
                autonómica aplicable para cada Ciclo Formativo. Serán los secretarios de los centros públicos o
                quienes asuman sus funciones en los centros privados quienes
                garanticen que se cumple dicha condición y que las matriculaciones se formalizan con documentación
                que acredita la posesión de los requisitos de acceso.
            </p>
            <p>
                Las presentes condiciones están permanentemente accesibles en nuestra página web, y pueden ser
                consultadas siempre que el alumno lo requiera.
            </p>
            <p>
                El documento de garantía es la factura de compra.
            </p>

            <h2 className='m-x2-top'>2. Procedimiento de compra de Producto Formativo</h2>
            <p>
                A través de esta página web solamente podrán ser realizados pedidos por personas físicas mayores de
                edad, que cumplan los requisitos académicos para
                acceder a la formación objeto de la compra. Solo los deportistas profesionales o las personas que, por
                causa mayor solo pueden acceder a una formación
                online (en los términos definidos en el Decreto 67/2007, de 20 de marzo, por el que se regula la
                enseñanza de formación profesional inicial en la modalidad a
                distancia, o norma que lo sustituya), podrán acceder a los Ciclos Formativos de ILERNA Online siendo
                menores de edad.
            </p>
            <p>
                Para los Ciclos Formativos, con cada matricula se incluyen dos convocatorias de examen, dentro del
                calendario escolar
            </p>
            <p>
                En la página web se detallan todos nuestros Ciclos Formativos de manera individualizada. El
                procedimiento para efectuar y gestionar la compra se va indicando
                en la página web y sólo deben seguirse los siguientes pasos:
                <ol className='p-x2-left'>
                    <li>
                        Elegir el Producto Formativo que desee adquirir.
                    </li>
                    <li>
                        Al seleccionar un curso o asignatura podrá ser añadido a la Cesta de la Compra y podrá elegir
                        entre seguir comprando o tramitar el pedido.
                    </li>
                    <li>
                        Para tramitar el pedido, si es la primera vez que accede a nuestro portal, es preciso
                        cumplimentar un formulario de registro con sus datos personales
                        y crear una cuenta personal como usuario. Completado este paso recibirá, en el correo
                        electrónico que haya indicado, la confirmación de que el
                        registro se ha realizado correctamente. En caso de que sea un usuario ya registrado, para
                        continuar con el proceso de compra bastará que introduzca
                        el nombre de usuario y contraseña que generó en la primera compra. El nombre de usuario y la
                        contraseña son personales y corresponden a la
                        persona que efectuó el proceso de registro, quien es responsable exclusivo su custodia. A través
                        de su cuenta podrá gestionar la información que
                        disponemos y modificar o actualizar sus datos.
                    </li>
                    <li>
                        A continuación, se procederá a la verificación del pedido donde se detallan uno a uno los cursos
                        o asignaturas seleccionados y se calcula el precio
                        total.
                    </li>
                    <li>
                        Asimismo, será necesario indicar una dirección a efectos de facturación de la compra realizada
                        y, en caso de envío físico de material, de entrega.
                    </li>
                    <li>
                        Una vez tramitado el proceso, será necesario marcar de manera previa una casilla de verificación
                        de lectura y aceptación de los términos y condiciones
                        de venta mediante la firma del interesado.
                    </li>
                    <li>
                        En la página web se le facilitarán los mecanismos para que pueda efectuarse el pago en función
                        del sistema elegido en cada caso.
                    </li>
                    <li>
                        Una vez introducidos los datos para el pago, deberá confirmar el pedido pulsando el botón
                        ”Comprar”.
                    </li>
                    <li>
                        Una vez pulsado el botón “Comprar” se confirmará la finalización del proceso mediante correo
                        electrónico en el que se indicarán las instrucciones
                        para el acceso a la formación y validación de la documentación necesaria, previa y requerida
                        para el acceso al Ciclo Formativo.
                    </li>
                    <li>
                        Los pedidos se procesarán una vez que se haya recibido correctamente el pago.
                    </li>
                </ol>
            </p>

            <h2 className='m-x2-top'>3. Requisitos de acceso para acceder a la condición de alumno</h2>
            <p>
                El alumno se obliga a comprobar los requisitos de acceso para poder tener la condición de alumno de cada
                Producto Formativo, descritos en la web corporativa
                y/o documentación promocional del curso puesta a disposición en la misma con anterioridad a la
                formalización de la matrícula. Así, con la formalización de la
                matrícula de un curso, el alumno declara, bajo su exclusiva responsabilidad, que ostenta todos los
                requisitos necesarios para acceder a la condición de alumno
                y poder obtener el título correspondiente.
            </p>
            <p>
                En caso de que un ALUMNO se haya prematriculado en un curso en el cual no cumpla los requisitos de
                acceso, previa formalización de la misma, se le devolverá
                el 100% del importe total de su matrícula.
            </p>
            <p>
                En cualquier caso, el Alumno que cumpla los requisitos de acceso deberá presentar a ILERNA Online la
                documentación de los requisitos de acceso, a no
                más tardar, en el plazo de 30 días desde la fecha de inicio del curso. En caso contrario, la matricula
                formalizada dejará de tener efecto y validez académica y,
                en consecuencia, el Alumno no podrá continuar con la actividad académica ni realizar los exámenes
                oficiales obligatorios para la obtención de la titulación.
            </p>
            <p>
                Excepcionalmente y para el caso de Productos Formativos con plazas limitadas, conforme a legislación
                vigente o autorización expresa, no se realizará en ningún
                caso, la devolución del importe abonado en concepto de “Reserva de plaza” debido a la condición de la
                limitación de plazas.
            </p>
            <p>
                El curso académico empezará con el inicio del semestre o año académico (dependiendo del Producto
                Formativo), cuya fecha figurará publicada en la página
                web y/o en el Campus Virtual. En dicha fecha, se dará acceso a todos los alumnos al material didáctico
                de las asignaturas adquiridas, a excepción de los
                Alumnos Premium que ostentan un acceso previo. El material estará publicado en el Campus Virtual, desde
                donde podrá ser consultado online o proceder a
                su descarga.
            </p>

            <h2 className='m-x2-top'>4. Requisitos de acceso para acceder a la condición de alumno</h2>
            <p>
                Se aceptan las siguientes formas de pago:
            </p>
            <ul>
                <li>
                    Tarjeta de Crédito: La transacción se realiza conectando directamente con la entidad bancaria a
                    través de su pasarela de pago.
                </li>
                <li className='m-x2-top'>
                    Transferencia bancaria: realizando un ingreso del importe total de la compra en la cuenta corriente
                    que se indique en el formulario.
                </li>
                <li className='m-x2-top'>
                    Financiación ajena: realizando el pago del importe total en cómodas cuotas. Mediante una entidad
                    financiera ajena a ILERNA Online, por lo que la
                    matrícula no está vinculada al contrato del préstamo, el solicitante podrá simular la financiación
                    de su matrícula a través del proceso de compra y
                    consultar sus condiciones generales antes de aceptar la financiación.
                </li>
                <li className='m-x2-top'>
                    Bizum: Pago utilizando el número de teléfono del interesado. Solo tendrá que indicar el teléfono
                    móvil, validar la operación y el dinero será transferido
                    de forma segura desde la cuenta bancaria. Se debe consultar previamente con la entidad financiera la
                    disponibilidad de Bizum.
                </li>
            </ul>
            <p>
                Una vez realizado el pago, el ALUMNO recibirá un correo electrónico de confirmación del mismo.
            </p>
            <p>
                ILERNA Online no tiene conocimiento de los datos referentes a su tarjeta de crédito. Todos nuestros
                sistemas de pago son totalmente seguros.
            </p>
            <p>
                Los pagos son previos al inicio de la formación y dentro del proceso de matriculación, por lo que ILERNA
                Online no proporcionará el acceso hasta el momento en
                que haya recibido el pago del mismo y/o se haya adquirido la condición de alumno. ILERNA Online se
                reserva el derecho a cancelar temporal o definitivamente
                los servicios prestados ante cualquier incumplimiento.
            </p>

            <h2 className='m-x2-top'>5. Facturas</h2>
            <p>
                Al realizar el pago correspondiente, recibirá la Factura de su compra en formato PDF en el correo
                electrónico que haya indicado a tal efecto en el momento
                de tramitar el pedido si así lo ha solicitado.
            </p>
            <p>
                En caso de querer obtener una factura en papel o a nombre de su empresa debe ponerse en contacto con
                atención al cliente (facturacion@ilerna.com) para
                poder facilitar los datos fiscales necesarios.
            </p>

            <h2 className='m-x2-top'>6. Cambios en matrícula</h2>
            <p>
                Existe la posibilidad de realizar modificaciones sobre las asignaturas elegidas en la matricula ya
                realizada siempre que la gestión se realice dentro de los 14 días
                naturales siguientes al pago de la misma y antes de la fecha de inicio de curso.
            </p>
            <p>
                Para ello, ILERNA Online emitirá un bono con el importe pagado por las asignaturas que no se deseen
                cursar, y se podrá aplicar en la compra por las que sea
                de interés cambiar.
            </p>
            <p>
                En caso de no querer cursar alguna de las ya abonadas, se podrá aplicar el derecho de desistimiento
                detallado en el siguiente punto de las presentes.
            </p>

            <h2 className='m-x2-top'>7. Derecho de desistimiento</h2>
            <p>
                En virtud de lo previsto en el Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se
                aprueba el texto refundido de la Ley General para la Defensa
                de los Consumidores y Usuarios y otras leyes complementarias (o normas posteriores que las substituyan),
                el alumno de ILERNA Online, como consumidor y
                usuario, tiene derecho a desistir del contrato celebrado a distancia y solicitar la devolución de su
                importe en las condiciones previstas en el presente apartado,
                al amparo de lo establecido en los artículos 102 y siguientes de la mencionada ley.
            </p>
            <p>
                El plazo para ejercitar el derecho de desistimiento expirará una vez transcurridos 14 días naturales
                desde la fecha de celebración del contrato (fecha de pago
                de matrícula y/o fecha de inicio de curso), a través de una declaración inequívoca en la que señale su
                decisión de desistir del contrato dirigida a la siguiente
                dirección: ILERNA Online. Turó de Gardeny, Complejo Magical Media, Edificio 5 (25003) Lleida, o por
                correo electrónico: online@ilerna.es.
            </p>
            <p>
                Los casos de derecho de desistimiento de alumnos que se han matriculado con el curso ya iniciado, los 14
                días naturales empezarán a contar desde la fecha
                de pago. De igual modo, los casos en los que el ALUMNO se matricule previo al inicio de curso, el
                periodo de derecho de desistimiento podrá ejercerse hasta
                14 días naturales posteriores al inicio del curso.
            </p>
            <p>
                Para ello, podrá utilizar el modelo de formulario de desistimiento que figura al final del presente
                documento, aunque su uso no es obligatorio;
            </p>
            <p>
                Para cumplir el plazo de desistimiento, basta con que la comunicación relativa al ejercicio de este
                derecho sea enviada por el alumno antes de que venza el
                plazo correspondiente.
            </p>
            <p>
                Una vez finalizado dicho plazo, el alumno no podrá desistir del contrato y la baja del alumno no
                implicará el derecho a la devolución del dinero.
            </p>
            <p>
                En caso de que el alumno ejercite su derecho de desistimiento dentro del plazo anteriormente descrito,
                se procederá a la devolución de los pagos recibidos,
                siempre que se cumpla con los requisitos, en el plazo de 14 días naturales desde la fecha en la que
                hayamos sido informados de la decisión de desistimiento
                del alumno dentro del plazo establecido al efecto.
            </p>
            <p>
                Independientemente de lo anterior, teniendo en cuenta que existen Productos Formativos, en los que las
                plazas son limitadas, la prematricula tendrá la
                condición de reserva/fianza en aras a garantizar la plaza del alumno frente a terceras peticiones que
                por cupo u ocupación no puedan ser atendidas. Como
                consecuencia de ello, frente a la baja del alumno independientemente de su causa, el importe abonado en
                concepto de matriculación no será devuelto,
                pasando a ser propiedad de ILERNA Online como cláusula indemnizatoria contractual.
            </p>
            <p>
                Procederemos a efectuar dicho reembolso utilizando el mismo medio de pago empleado por el alumno para la
                transacción inicial a no ser que el alumno haya
                dispuesto expresamente lo contrario y siempre y cuando el alumno no incurra en ningún gasto como
                consecuencia del reembolso. No obstante, en caso de
                que el alumno haya seleccionado expresamente una modalidad de entrega diferente a la modalidad menos
                costosa de entrega ordinaria, ILERNA Online no
                estará obligado a reembolsar los costes adicionales que de ello se deriven.
            </p>
            <p>
                En casos de reembolso, el alumno no podrá recibir cantidad superior al pago recibido por ILERNA Online.
            </p>
            <p>
                El derecho de desistimiento anteriormente descrito tendrá un régimen especial para los Alumnos Premium:
            </p>
            <p>
                Los alumnos que contraten el servicio Premium, tendrán un acceso anticipado al material didáctico del
                Campus Virtual a la fecha de inicio del curso, que se
                determinará de forma expresa para cada inicio de curso y pueden obtener los materiales didácticos
                impresos en formato físico de manera gratuita, por su
                condición de “Alumno Premium”.
            </p>
            <p>
                Estos ALUMNOS podrán ejercitar su derecho de desistimiento, dentro de los 14 días naturales desde el
                primer acceso al Campus Virtual.
            </p>
            <p>
                Los casos de derecho de desistimiento de alumnos premium que se han matriculado con el curso ya
                iniciado, los 14 días naturales empezarán a contar desde
                la fecha de pago.
            </p>
            <p>
                El precio del servicio Premium no es reembolsable.
            </p>
            <p>
                Se excluye del derecho de desistimiento a los contratos mencionados en el artículo 103 del Real Decreto
                Legislativo 1/2007 de 16 de noviembre por el que se
                aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras
                leyes complementarias.
            </p>

            <h2 className='m-x2-top'>8. Clases presenciales y exámenes </h2>
            <p>
                ILERNA Online dispone de algunos Productos Formativos en modalidad a distancia que incluyen, por
                normativa, horas presenciales ya sean obligatorias o
                voluntarias. En estas clases presenciales, dependiendo del Ciclo Formativo, la asistencia es obligatoria
                y evaluable; y si no se verifica su asistencia a ellas no
                se podrá superar y aprobar el módulo al que correspondan estas horas presenciales obligatorias durante
                ese curso académico. Las mismas, se desarrollarán
                siempre dentro del territorio español, independientemente del domicilio y/o país de residencia del
                ALUMNO.
            </p>
            <p>
                Si no se verifica la asistencia del ALUMNO a ellas, no podrá presentarse al examen final y, por tanto,
                deberá rematricularse.
            </p>
            <p>
                Esta normativa está sujeta a posibles modificaciones y las ciudades en las que se realizan las clases
                presenciales pueden variar cada semestre. Además, en el
                caso en que no se llegue al mínimo de 10 alumnos/as inscritos en cada sesión de las clases presenciales,
                ILERNA puede modificar las fechas y sedes en las que
                se realizan dichas clases para reubicar a los alumnos entre las diferentes ubicaciones.
            </p>
            <p>
                La metodología y evaluación de estas clases presenciales podrá ser modificada por la normativa del
                Departamento de Educación correspondiente y, por lo
                tanto, ILERNA Online no se hace responsable de los posibles cambios.
            </p>
            <p>
                Las plazas establecidas para las clases presenciales son limitadas. Por ello, ILERNA Online no garantiza
                la inscripción en la sede deseada por el ALUMNO.
                Dichas plazas en las que el ALUMNO debe inscribirse para poder asistir se publicarán indicando los días
                y las horas disponibles, que se regirán siempre
                siguiendo el horario Peninsular y se asignarán según orden de inscripción, hasta completar el aforo.
            </p>
            <p>
                De igual forma, las sesiones de exámenes obligatorios, se celebrarán dentro del calendario académico
                establecido al efecto, en sedes determinadas que en
                todo caso estarán ubicadas dentro del territorio español independientemente del domicilio y/o país de
                residencia del ALUMNO.
            </p>
            <p>
                Asimismo, todas las inscripciones realizadas fuera de plazo para convocatoria ordinaria deberán llevarse
                a cabo en las sedes de ILERNA Online, según
                disponibilidad.
            </p>

            <h2 className='m-x2-top'>9. Efectos y modificaciones de los servicios</h2>
            <p>
                El presente contrato se perfeccionará con el pago del producto, además el ALUMNO recibirá un correo
                electrónico de confirmación con una descripción de
                la compra realizada.
            </p>
            <p>
                ILERNA Online se reserva el derecho a modificar de cualquier forma las características y condiciones de
                sus servicios, siempre con el objetivo de mejorarlos y
                que suponga un beneficio para el ALUMNO.
            </p>
            <p>
                Los Ciclos Formativos de Formación Profesional que imparte ILERNA Online son oficiales y se regulan
                según la normativa vigente que establece la administración.
                En consecuencia, ILERNA Online deberá quedar exonerada de cualquier responsabilidad cuando se produzcan
                cambios o modificaciones en la normativa de
                la administración que afecte al funcionamiento o características de los ciclos formativos, y el alumno
                así lo acepta.
            </p>
            <p>
                En todo caso, todos los Ciclos Formativos, módulos y cualquier aspecto formativo, de calificación,
                gestión, evaluación, convalidación (todo ello a efectos
                enunciativos, pero no limitativos) ofertados y publicados en la web estarán sujetos en todo caso y
                momento a la normativa aplicable de educación vigente en
                cada momento, que puede estar sujeta a cambios y/o modificaciones ajenas a ILERNA Online.
            </p>
            <p>
                En relación al párrafo anterior, todo cambio sustancial de normativa será debidamente comunicado al
                ALUMNADO.
            </p>

            <h2 className='m-x2-top'>10. Obligaciones de las partes</h2>
            <p>
                ILERNA Online se compromete a proporcionar al ALUMNO los productos y servicios que hayan sido
                contratados aplicando la máxima diligencia en la prestación
                del servicio, así como a cuidar del mantenimiento de las instalaciones necesarias para el funcionamiento
                de la red, disponiendo para ello de un equipo técnico
                e informático adecuado y, en su caso, a gestionar las operaciones de logística, envío y entrega de la
                compra.
            </p>
            <p>
                El ALUMNO se compromete a utilizar los servicios que se ponen a su disposición de buena fe, sin vulnerar
                las leyes vigentes en cualquier materia o infringir
                derechos de terceras personas, así como al pago del producto seleccionado en el tiempo y forma fijado en
                las presentes condiciones generales de contratación
                y a su recepción en el lugar indicado para la entrega.
            </p>
            <p>
                Igualmente, el ALUMNO cumplirá con las Normas de Convivencia del Campus Virtual y con la Normativa de
                Organización y Funcionamiento del Centro. En
                caso de incumplimiento, ILERNA Online, como Centro Docente podrá apercibir al alumno o sancionarlo a
                tenor de la normativa vigente en cada momento.
            </p>
            <p>
                Los servicios ofertados se prestarán mayoritariamente por medios telemáticos, por lo que el ALUMNO
                acepta que puedan darse circunstancias originadas por
                problemas en la red de Internet, averías en los dispositivos informáticos y otros problemas técnicos
                imprevisibles que conlleven interrupciones puntuales en los
                servicios. ILERNA Online actuará de forma diligente para resolver estas incidencias que pudieran surgir
                en el menor tiempo posible.
            </p>

            <h2 className='m-x2-top'>11. Comunicaciones</h2>
            <p>
                Todas las notificaciones entre las partes se realizarán preferentemente a través de correo electrónico
                y/o Campus Virtual.
            </p>
            <p>
                El ALUMNO es responsable de poner en conocimiento de ILERNA cualquier modificación sobre sus datos con
                la finalidad de poder recibir correctamente las
                comunicaciones, quedando ILERNA Online exonerada de cualquier responsabilidad derivada por el
                incumplimiento de esta circunstancia.
            </p>
            <p>
                En caso de modificación el ALUMNO deberá trasladar la información a ILERNA Online mediante el e-mail o
                teléfonos indicados en nuestra página web.
            </p>

            <h2 className='m-x2-top'>12. Aceptación, perfección y validez del contrato</h2>
            <p>
                El ALUMNO reconoce haber leído y aceptado las condiciones legales de uso y la política de privacidad de
                la página web.
            </p>
            <p>
                El ALUMNO reconoce con la firma del documento de aceptación que ha entendido toda la información
                respecto a los productos y servicios, incluyendo, en su
                caso, el de entrega, ofrecidos en nuestra página web y, por lo tanto, las acepta íntegra y expresamente.
            </p>
            <p>
                El ALUMNO es plenamente consciente de la aceptación y ejecución de las presentes condiciones.
            </p>
            <p>
                Para asegurar la correcta emisión de voluntad por parte del ALUMNO y/o comprador, independientemente de
                que las condiciones generales de contratación
                siempre estén a disposición del público en general en la web corporativa; se solicitará del citado
                ALUMNO o comprador la firma expresa de las presentes
                condiciones, a efectos de demostración fehaciente e indubitada de la aceptación de las mismas; todo ello
                y en todo caso, sin perjuicio de los derechos que en
                cada momento ostente en su condición de usuario o consumidor de productos.
            </p>
            <p>
                Las presentes condiciones generales de contratación se regirán e interpretarán de acuerdo con las leyes
                españolas y demás normativa europea que sea de
                aplicación.
            </p>

            <h2 className='m-x2-top'>13. Resolución de conflictos</h2>
            <p>
                En el caso de que se produzca cualquier tipo de discrepancia o diferencia entre las partes en relación
                con la interpretación, contenido o ejecución del presente
                documento que no sea solucionada de mutuo acuerdo, las Partes se someterán a los juzgados o tribunales
                que resultaran competentes en cada caso.
            </p>
            <p>
                Independientemente de lo anterior, nuestra entidad se encuentra adherida a Confianza Online (Asociación
                sin ánimo de lucro), inscrita en el Registro Nacional
                de Asociaciones Grupo 1, Sección 1, número nacional 594400, CIF G85804011, Calle la Palma 59, Bajo A.,
                28015 Madrid (España), teléfono (+34) 91 309 13
                47 y fax (+34) 91 402 83 39 (www.confianzaonline.es). Por ende, los usuarios podrán acudir a Confianza
                Online para la resolución alternativa de eventuales
                controversias (https://www.confianzaonline.es/como-reclamar/formulario-de-reclamaciones). Si estas se
                refieren a transacciones electrónicas con consumidores,
                las reclamaciones serán resueltas por el Comité de Mediación de Confianza Online, acreditado para la
                resolución alternativa de litigios en materia de consumo.
            </p>
            <p>
                Los consumidores y usuarios disponen de la hoja de quejas o reclamaciones en formato digital, en las
                condiciones generales de contratación de la web, aunque
                su uso no es obligatorio. Para realizar la debida presentación de forma fehaciente de la reclamación
                correspondiente, deberá presentarse en todas las sedes
                físicas de ILERNA, o a la siguiente dirección: ILERNA Online. Turó de Gardeny, Complejo Magical Media,
                Edificio 5 (25003) Lleida, o por correo electrónico:
                legal@ilerna.com.
            </p>

            <h2 className='m-x2-top'>14. Datos personales</h2>
            <p>
                El proceso de contratación requiere cumplimentar formularios de datos personales con la finalidad de
                tramitar y gestionar los pedidos y, si lo autoriza, para
                remitirle información comercial sobre nuestros productos y servicios.
            </p>
            <p>
                Puede ejercitar sus derechos de protección de datos en cualquier momento dirigiéndose a
                ilerna@delegado-datos.com.
            </p>
            <p>
                El tratamiento de sus datos personales se regirá por lo establecido en la Política de Privacidad
                establecida en nuestra web.
            </p>
            <p className='font-bold'>
                Cesión de datos a Entidades Financieras
            </p>
            <p>
                De conformidad con lo dispuesto en la Reglamento (UE) 2016/679 de 27 de abril de 2016 relativo a la
                protección de las personas físicas en lo que respecta al
                tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la
                Directiva 95/46/CE (“RGPD”) queda informado que, en caso
                de que seleccione como servicio de pago una entidad financiera; su nombre, apellidos, DNI o NIE,
                dirección de correo electrónico, dirección de facturación y
                entrega y producto que quiere adquirir y precio del mismo serán cedidos a dicho tercero que pueden ser
                los siguientes:
            </p>
            <ul>
                <li>
                    APLAZAME (marca de WiZink Bank, S.A.U.), con domicilio en Madrid (28043), Calle Ulises nº 16, N.I.F
                    A81831067, con la finalidad de que
                    APLAZAME pueda tramitar la solicitud de financiación y, en su caso, analizar, evaluar y gestionar
                    los créditos que usted como usuario
                    le solicite, de acuerdo con su política de protección de datos que podrá encontrar en
                    https://aplazame.com/legal/privacy-policy/.
                    En cualquier momento, usted podrá ejercitar los derechos de acceso, rectificación, supresión
                    oposición, portabilidad y limitación de los datos que
                    como usuario le corresponden, enviando una comunicación a arco@aplazame.com
                </li>
                <li className='m-x2-top'>
                    SABADELL CONSUMER FINANCE, S.A. con domicilio en Pl. Cataluña, 1, 08201 Sabadell, N.I.F A63574719,
                    con la finalidad de que SABADELL CONSUMER
                    pueda tramitar la solicitud de financiación y, en su caso, analizar, evaluar y gestionar los
                    créditos que usted como usuario le solicite, de acuerdo con
                    su política de protección de datos que pone a disposición de los usuarios a través de la dirección
                    electrónica: https://www.sabadellconsumer.com
                    En cualquier momento, usted podrá ejercitar los derechos de acceso, rectificación, supresión
                    oposición, portabilidad y limitación de los datos que
                    como usuario le corresponden, enviando una comunicación a AtencionAlClienteSCF@bancsabadell.com
                </li>
                <li className='m-x2-top'>
                    SEQURA WORLDWIDE, S.A. con domicilio en Barcelona, Calle Ali Bei 7-9 Entresuelo, N.I.F. A-66054164,
                    con la finalidad de que SEQURA pueda
                    tramitar la solicitud de financiación y, en su caso, analizar, evaluar y gestionar los créditos que
                    usted como usuario le solicite, de acuerdo con
                    su política de protección de datos que pone a disposición de los usuarios a través de la dirección
                    electrónica: https://www.sequra.com/es
                    En cualquier momento, usted podrá ejercitar los derechos de acceso, rectificación, supresión
                    oposición, portabilidad y limitación de los datos que
                    como usuario le corresponden, enviando una comunicación a clientes@sequra.es
                </li>
            </ul>

            <h2 className='m-x2-top'>15. Grabación de llamadas</h2>
            <p>
                Por motivos de calidad y mejora del servicio todas las llamadas son grabadas.
            </p>
            <p>
                Puede oponerse a la grabación de nuestras llamadas telefónicas dirigiéndose a ilerna@delegado-datos.com.
                La finalidad de la grabación de las llamadas es
                exclusivamente para verificar la calidad del servicio. Estas llamadas serán utilizadas internamente y
                durante los plazos necesarios para la finalidad indicada.
            </p>
            <p>
                Todos tus datos serán tratados conforme a la normativa de Protección de Datos y Acceso. Puedes consultar
                nuestra Política de Privacidad en la web www.
                ilerna.com.
            </p>

            <h2 className='m-x2-top'>16. Servicio Premium</h2>
            <p>
                La contratación del Servicio Premium puede ser de carácter semestral o anual. Los alumnos que contraten
                este servicio podrán beneficiarse de las siguientes
                condiciones:
            </p>
            <ul>
                <li>
                    <span className='font-bold'>Gestor/a Premium. </span>El ALUMNO tendrá a su disposición un/a Gestor/a
                    al que podrá dirigirse para resolver cualquier tipo de dudas salvo aquella
                    que tenga que ver con el contenido didáctico de las distintas asignaturas que compete únicamente al
                    claustro de ILERNA Online. El ALUMNO
                    podrá ponerse en contacto con su gestor a través de WhatsApp, email o llamada telefónica. Además,
                    el/la Gestor/a pactará con el ALUMNO la
                    temporalidad y el canal de comunicación preferente para hacer un seguimiento mínimo mensual. El
                    gestor estará disponible de lunes a viernes de
                    9:00h a 14:00h y de 16:00h a 19:00h, salvo los días festivos nacionales, autonómicos y locales que
                    correspondan.
                </li>
                <li className='m-x2-top'>
                    <span className='font-bold'>Acceso anticipado al Campus. </span>El ALUMNO podrá acceder al Campus
                    Virtual y a todo el material didáctico de las asignaturas en las que se haya
                    matriculado antes de que comience el semestre. El material didáctico en formato digital puede sufrir
                    actualizaciones durante los periodos previos
                    al inicio del semestre.
                </li>
                <li className='m-x2-top'>
                    <span className='font-bold'>Material didáctico físico gratis. </span>El ALUMNO se beneficiará de
                    todos los materiales didácticos físicos gratuitos de aquellas asignaturas en las que se
                    matricule en el mismo momento en el que adquiera el servicio premium o en posteriores matrículas a
                    la adquisición del servicio dentro del periodo (semestre o año) de vigencia de este. Se deberá tener
                    en cuenta que, para las asignaturas o módulos troncales, no se podrán solicitar los libros
                    impresos debido a que únicamente se ofertarán de manera digital.
                </li>
            </ul>
            <p>
                Además, el alumno no recibirá los materiales didácticos en formato papel gratuitos de aquellas
                asignaturas en proceso de convalidación. Si tras la resolución,
                la convalidación es denegada, el alumno deberá solicitar los materiales didácticos físicos de dicha
                asignatura durante el semestre en que la cursará. Las
                asignaturas que sean convalidadas no se cursarán y, por este motivo, el alumno no recibirá dichos libros
                impresos.
            </p>
            <p>
                El envío de los materiales didácticos físicos será gratuito para envíos dentro de la Península y
                Baleares. Los gastos de transporte, en su caso, NO están incluidos
                en el precio del envío a las Islas Canarias, Ceuta, Melilla y extranjero. Para más información, se
                detallan las tarifas en el siguiente enlace.
            </p>
            <p>
                *ILERNA Online se reserva el derecho a rescindir el Servicio Premium a aquellos ALUMNOS que no cumplan
                las normas y reglas de convivencia de ILERNA Online. <br />
                *Estas normas pueden ser consultadas en la Guía del Estudiante que se encuentra en el Campus Virtual.
            </p>

            <h2 className='m-x2-top'>17. Venta de material didáctico y merchandising</h2>
            <p>
                Los productos que se ofrecen en la página web de merchandising propio de ILERNA Online están sujetos a
                un 21% del Impuesto sobre el Valor Añadido (IVA), a
                excepción del material didáctico a su condición de material escolar, que se encuentra exento. En
                contraprestación por el producto adquirido (material didáctico
                y/o merchandising), el ALUMNO acepta pagar expresamente a ILERNA Online las cantidades especificadas en
                el momento de la compra en la relación de
                precios establecidas en la web www.ilerna.es para los productos elegidos.
            </p>
            <p>
                Los gastos de transporte, en su caso, NO están incluidos en el precio de los productos. Para consultar
                en más detalle las tarifas y costes de transporte de los
                productos mencionados, se pueden consultar en nuestra web.
            </p>
            <p>
                Una vez finalizada la selección de productos y de manera previa a la confirmación del pedido y antes de
                proceder al pago del mismo, en pantalla aparecerá
                el precio final de la compra que incluirá, si proceden, los gastos de envío, que se reflejarán en la
                orden de pedido en el momento de efectuar la compra y en
                la factura.
            </p>
            <p>
                Como norma general, el plazo máximo de entrega en domicilio será de 7 días laborables desde el momento
                de la compra y a partir del momento en que
                hayamos confirmado su pago. Estos plazos de entrega pueden verse afectados si se realiza la compra antes
                del fin de semana, en días festivos, como también
                en épocas de alto volumen de ventas como Navidad, Black Friday, final del periodo de matriculación, etc.
                o con motivo del destino. En estos supuestos el plazo
                de entrega se ajustará al retraso derivado de la concurrencia de alguna de las circunstancias indicadas.
                Las entregas se realizarán los días laborables (lunes a
                viernes).
            </p>
            <p>
                La entrega del producto comprado a domicilio se efectuará por el transportista en la dirección indicada
                en la orden de pedido que el alumno deberá firmar
                como documento de recepción. Asimismo, el ALUMNO estará debidamente informado en el caso de que ocurra
                una incidencia o la entrega se retrase por
                cualquier motivo. En el caso de que la imposibilidad de la entrega sea ocasionada por haberse consignado
                una dirección errónea, el coste de la reexpedición
                del pedido correrá a cargo del ALUMNO.
            </p>
            <p>
                Con el objeto de que en todo momento esté informado de la gestión de su compra, recibirá por correo
                electrónico un aviso de la confirmación de su pedido
                y del pago. Asimismo, el ALUMNO puede consultar el estado de su pedido llamando en horario comercial de
                apertura al teléfono 900 730 222 o enviando un
                correo electrónico a la dirección online@ilerna.com.
            </p>
            <p>
                Todos nuestros materiales didácticos quedan sometidos a controles de calidad y están garantizados contra
                defectos de producción por un plazo de 2 años
                desde la fecha de entrega. ILERNA Online ofrece una garantía que cubre cualquier vicio de fabricación,
                de diseño o de material, aunque será necesario que
                se ponga de manifiesto la falta de conformidad en un plazo de dos meses naturales desde que tuvo
                conocimiento de ella. La garantía cubre únicamente los
                productos defectuosos. No cubre golpes, uso inadecuado, u otros que no sean atribuibles a un defecto del
                proveedor o a una tara en el producto. Durante el
                periodo de garantía el ALUMNO podrá devolver el producto y procederemos a su reparación, sustitución,
                rebaja del precio o devolución según corresponda,
                gestiones que serán gratuitas para el ALUMNO. Los gastos de transporte que se generen por las
                devoluciones bajo garantía NO serán por cuenta del ALUMNO.
            </p>
            <p>
                El alumno podrá ejercitar su derecho de devolución de la compra de material del Producto Formativo, en
                un plazo de 14 días naturales desde la recepción
                del material por el propio alumno o un tercero debidamente identificado e indicado por el alumno. En
                caso de los materiales que formen parte de un mismo
                pedido, pero cuya entrega se realice por separado, el alumno podrá ejercitar su derecho de
                desistimiento, en un plazo de 14 días naturales desde la recepción
                del último de estos materiales por el propio alumno o un tercero debidamente identificado e indicado por
                el alumno.
            </p>
            <p>
                En caso de devolución por parte del alumno, éste deberá devolver directamente los materiales a ILERNA
                ONLINE, S.L. (Dirección: Turó de Gardeny, Complejo
                Magical Media, Edif 5, 25003, Lleida), sin ninguna demora indebida y, en cualquier caso, a más tardar en
                el plazo de 14 días naturales a partir de la fecha en
                que comunique su decisión de devolución del contrato. Se considerará cumplido el plazo si efectúa el
                envío de los materiales antes de que haya concluido
                dicho plazo.
            </p>
            <p>
                ILERNA Online devolverá todos los pagos recibidos, incluidos los gastos de la entrega original de los
                bienes (con la excepción de los gastos adicionales
                resultantes de la elección por parte del alumno de una modalidad de entrega diferente a la modalidad
                menos costosa de entrega ordinaria que ofrezcamos) sin
                ninguna demora indebida y, en todo caso, a más tardar 14 días naturales a partir de la fecha en la que
                se informe a ILERNA Online de su decisión de devolver
                el material. No obstante, ILERNA Online podrá retener el reembolso de las cantidades hasta haber
                recibido los bienes, o hasta que el alumno haya presentado una prueba de la devolución/envío de los
                materiales, según qué condición se cumpla primero.
            </p>
            <p>
                Asimismo, el alumno será responsable de la disminución de valor de los materiales resultante de una
                manipulación distinta a la necesaria para establecer la
                naturaleza, las características y el funcionamiento de los materiales.
            </p>
            <p>
                El derecho de devolución del ALUMNO se aplicará a los materiales que se devuelvan en las mismas
                condiciones en las que se encontraban en el momento
                de la recepción. En consecuencia, en caso de haberse abierto el embalaje o de no devolverse en las
                mismas condiciones en las que se recibieron, sólo se
                procederá a un reembolso equivalente al valor de los materiales, teniendo en cuenta la depreciación del
                producto derivada de la concurrencia de alguno de
                los supuestos indicados.
            </p>

            <h2 className='m-x2-top'>18. Bonos y descuentos</h2>
            <p>
                ILERNA Online ostentará la potestad de emitir bonos y/o descuentos a los ALUMNOS en situaciones
                excepcionales y que considere adecuadas para cada caso
                en concreto, como medida de gracia ante situaciones excepcionales.
            </p>
            <p>
                Dichos bonos, tendrán el carácter de personal e intransferible el cual ostentará una caducidad máxima de
                DOS años desde su emisión. El no ejercicio o uso
                de dicho bono transcurrido el plazo reseñado implicará en todo caso, la imposibilidad de aplicación del
                mismo como la restitución económica del mismo.
            </p>
            <p>
                En el caso de modificación de asignaturas/módulos o de Producto formativo sobre los cuales previamente
                el alumno se haya beneficiado de descuentos
                promocionales y, que dicha modificación solicitada por el alumno (vencido el plazo de la promoción)
                implique en todo o en parte el incumplimiento de los
                requisitos establecidos para dicho descuento; implicará en todo caso que el alumno tendrá que abonar
                como consecuencia de dicho cambio e incumplimiento
                de condiciones las diferencias no abonadas en concepto de descuento.
            </p>

            <h2 className='m-x2-top'>19. Actualización de las condiciones</h2>
            <p>
                Sin perjuicio del cumplimiento de nuestras obligaciones de información, las presentes condiciones generales de contratación pueden ser modificadas para
                adaptarlas a los cambios que se produzcan, así como modificaciones legislativas o jurisprudenciales que vayan apareciendo.
            </p>

            <div className="p-x2-top" />
            <EsRequirements />
        </div>
    </CF>;

const TermsITFR = () =>
    <CF className="sm-card max-width-sm ">
        <div className={"sm-p-x2-top sm-p-x2-bottom sm-p-left sm-p-right t-justify"}>
            <div dangerouslySetInnerHTML={{ __html: t('signature_page_terms') }} />
            <div className="p-x2-top" />
            <TechnicalRequirements />
        </div>
    </CF>;

const ConditionsChanged = ({name}) => 
    <CF className="sm-card max-width-sm alert-commitment-fct m-bottom">
        <div className={"p-top p-bottom p-left p-right t-justify"}>
            <div dangerouslySetInnerHTML={{ __html: '<b>'+t('signature_page_updated_conditions').replace('{name}',name)+'</b>'}} />
        </div>
    </CF>;

let Signature = class extends Component {

    canvasRef = createRef();
    state = {
        uploading: false,
        sign: null,
        useOfImages: null,
        dataProtection: false,
        refuseCommercialInfo: false
    }

    async upload(img, docId) {
        await this.props.uploadDocument(this.props.auth.loggedUser.id, img, docId);
    }

    clear() {
        this.canvasRef.current.clear()
        this.setState({ sign: null })
    }

    async save() {
        await this.setState({ uploading: true });
        await this.props.uploadSignature(
            this.props.auth.loggedUser.id,
            this.canvasRef.current.canvas.drawing.toDataURL(),
            this.state.useOfImages,
            this.state.refuseCommercialInfo
        );
        await this.setState({ uploading: false });

    }

    async validateForm() {
        if(this.state.useOfImages === null){
            var element = document.getElementById('allow-use-images-radio-checkbox');
            document.getElementById('input-schedule-error').style.display = 'block';
            window.scrollTo({ top: element.offsetTop-50, behavior: 'smooth'});
        }
    }

    handleChecked = (value) => {
        this.setState({refuseCommercialInfo: value})
    }

    handleRadioBox = (value) => {
        this.setState({useOfImages: value})
        document.getElementById('input-schedule-error').style.display = 'none';
    }    

    handleSign = (e) => this.setState({ sign: e.canvas.drawing.toDataURL() })

    render({ status, student }) {

        const isSpanish = isSpanishUser(status.student_meta.shop_id)
        const hasSignedConditionspreviously = status.student_meta.has_signed_conditions_previously;
        
        return student && <Fragment>

            {hasSignedConditionspreviously ? <ConditionsChanged name= {student.data.name}/> : ''}

            {isSpanish ? <TermsES /> : <TermsITFR />}

            <div style="padding-top:32px" />

            <CF className="sm-card max-width-sm">
                <div className={"sm-p-x2-top sm-p-x2-bottom sm-p-left sm-p-right t-justify"}>
                    <h2>{t('signature_page_commercial_info_title')}</h2>
                    <div dangerouslySetInnerHTML={{ __html: t('signature_page_commercial_info') }} />
                    <div className='m-top-x2 d-flex font-18'>
                        <Checkbox
                            onChange={this.handleChecked}
                            value={this.state.refuseCommercialInfo}
                            label={
                                <span>
                                    { t('signature_page_commercial_info_checkbox') }
                                </span>
                            }
                        />
                    </div>
                </div>
            </CF>

            <div style="padding-top:32px" />

            <CF className="sm-card max-width-sm">
                <div className='sm-p-x2-top sm-p-x2-bottom sm-p-left sm-p-right t-justify'>
                    <h2 className='m-bottom'>{t('Cesión de los derechos de imágen')}</h2>
                    <div className='m-bottom'
                        dangerouslySetInnerHTML={{ __html: t('signature_page_accept_images') }}></div>
                    <FR id="allow-use-images-radio-checkbox" className=' m-bottom flexrow'>
                        <E className='sm-col-20 col-50'>
                            <RadioCheckbox
                                label={t('Aceptar')}
                                type='radio'
                                onChange={() => {
                                    this.handleRadioBox(true)
                                }}
                                defaultValue={this.state.useOfImages}
                            />
                        </E>
                        <E className='sm-col-20 col-50'>
                            <RadioCheckbox
                                label={t('Rechazar')}
                                type='radio'
                                onChange={() => {
                                    this.handleRadioBox(false)
                                }}                                
                                defaultValue={this.state.useOfImages === false}
                            />
                        </E>
                        <div id='input-schedule-error' className='element input-schedule-error' style="display:none">{t('Este campo es obligatorio')}</div>
                    </FR>
                    <div>{t('signature_page_accept_images_2')}</div>
                </div>
            </CF>

            <div style="padding-top:32px" />



            <div className="max-width margin-auto" style={{ paddingLeft: '4px' }}>
                <h1 className='no-margin m-bottom'>{t('Deja tu firma aquí')}</h1>
            </div>

            <CF className={"desktop-white max-width-sm dashed" + (this.state.uploading ? ' disabled-link' : '')}>
                <CanvasDraw brushRadius={5} brushColor="#000" canvasWidth="100%" ref={this.canvasRef} hideGrid={true}
                    onChange={this.handleSign} />
            </CF>

            <FR className="max-width-sm margin-auto t-right m-top flex-center-y ">
                <div id="accept-signature" className="flexrow sm-col-33 col-50" onClick={() => this.validateForm()}>
                    <button  type="button" className="btn btn-primary col-100" onClick={() => this.save()} disabled={(this.state.uploading || !this.state.sign) || (this.state.useOfImages === null)}>{t('Aceptar')}</button>
                </div>
                <button type="button" className="btn btn-ghost sm-col-33 col-50" onClick={() => this.clear()} disabled={this.state.uploading}>{t('Borrar')}</button>
                <div className="col-30 flex-center-x t-underline t-center text-primary t-upper cursor-pointer m-l d-none sm-d-block" onClick={() => history.back()}>{t('Cancelar')}</div>
            </FR>

        </Fragment>;
    }
};

Signature = connect(({ auth, status, student }) => ({ auth, status, student }),
    {
        uploadSignature
    })(Signature);


const SignaturePage = ({ history }) =>
    <AppPage
        name="signature"
        className="max-width-sm"
    >
        <Signature />
    </AppPage>
    ;

export default SignaturePage;
