import React, { h } from "preact";

export function TextLabel({className, label, value}) {

    return (
        <div className={className}>
            <div className='font-14 font-color-486268'>{label}</div>
            <div className='font-medium m-top-half'>{value}</div>
        </div>
    )
}

export const Section = ({children, className}) => <section className={`d-flex flex-space-between flex-align-start flex-column sm-no-flex-column ${className}`} >{children}</section>


