import {Component, Fragment, h} from "preact";
import DropdownSelector from "../../_common/components/dropdownSelector/dropdownSelector";


export default function AcademicProgramSelector ({academicProgramSelector, onChange}){

    return (

        <Fragment>
            {academicProgramSelector.length > 1
            ? <AcademicProgramMulti
                    academicProgramSelector={academicProgramSelector}
                    onChange={onChange}
                />
            : <div className='font-24'>{academicProgramSelector[0].name}</div>
            }
        </Fragment>
    )
}

const AcademicProgramMulti = ({academicProgramSelector, onChange}) => (
    <Fragment>
        <DropdownSelector
            values={academicProgramSelector}
            onChange={onChange}
            iconDropdownVisible={<i className='far fa-chevron-up'/>}
            iconDropdownHidden={<i className='far fa-chevron-down'/>}
        />
    </Fragment>
    )
