import React, { h } from "preact";
import {t} from "../../_i18n";
import {Card} from "../../_common/layout";
import {ButtonGhost} from "../../_common/components/buttons/buttons";
import {Section, TextLabel} from "./components";
import AlertBox from "../../_common/components/AlertBox/AlertBox";
import {Link} from "react-router-dom";

export default function DocumentData({isExpired, documentType, documentNumber, valid, updateButton}) {

    return (
        <div className='p-left-half sm-no-p-left p-right-half sm-no-p-right'>
            <Card className='m-top sm-p-x2-bottom'>
                <div className='font-24 font-medium m-top'>{t('Documento de identidad')}</div>
                {isExpired && <AlertBox type='warning' className='m-top'>{t('El DNI está caducado. Debes actualizarlo para que podamos ponerlo en tu expediente')}.</AlertBox>}
                <Section className='m-top'>
                    <div className='d-flex flex-column sm-no-flex-column'>
                        <TextLabel className='sm-m-right m-top sm-no-m-top' label={t('Tipo de documento')} value={documentType}/>
                        <TextLabel className='sm-m-right sm-m-l m-top sm-no-m-top' label={t('Número de documento')} value={documentNumber}/>
                        <TextLabel className='sm-m-l m-top sm-no-m-top' label={t('Válido hasta')} value={valid}/>
                    </div>
                    <Link to={updateButton}><ButtonGhost className='m-top sm-no-m-top full-width sm-no-full-width'>{t('Actualizar')}</ButtonGhost></Link>
                </Section>
            </Card>
        </div>
    )
}
