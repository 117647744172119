/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DocumentResponseBlocks from './DocumentResponseBlocks';

/**
 * The DocumentResponse model module.
 * @module model/DocumentResponse
 * @version 1.0.0
 */
class DocumentResponse {
    /**
     * Constructs a new <code>DocumentResponse</code>.
     * @alias module:model/DocumentResponse
     */
    constructor() { 
        
        DocumentResponse.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>DocumentResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DocumentResponse} obj Optional instance to populate.
     * @return {module:model/DocumentResponse} The populated <code>DocumentResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DocumentResponse();

            if (data.hasOwnProperty('doc_status')) {
                obj['doc_status'] = ApiClient.convertToType(data['doc_status'], 'String');
            }
            if (data.hasOwnProperty('is_work_with_children')) {
                obj['is_work_with_children'] = ApiClient.convertToType(data['is_work_with_children'], 'Boolean');
            }
            if (data.hasOwnProperty('is_health')) {
                obj['is_health'] = ApiClient.convertToType(data['is_health'], 'Boolean');
            }
            if (data.hasOwnProperty('is_dress_uniform')) {
                obj['is_dress_uniform'] = ApiClient.convertToType(data['is_dress_uniform'], 'Boolean');
            }
            if (data.hasOwnProperty('is_dress_coat')) {
                obj['is_dress_coat'] = ApiClient.convertToType(data['is_dress_coat'], 'Boolean');
            }
            if (data.hasOwnProperty('is_dress_pijama')) {
                obj['is_dress_pijama'] = ApiClient.convertToType(data['is_dress_pijama'], 'Boolean');
            }
            if (data.hasOwnProperty('is_dosimeter')) {
                obj['is_dosimeter'] = ApiClient.convertToType(data['is_dosimeter'], 'Boolean');
            }
            if (data.hasOwnProperty('fct_start_month')) {
                obj['fct_start_month'] = ApiClient.convertToType(data['fct_start_month'], 'String');
            }
            if (data.hasOwnProperty('mad_or_cat')) {
                obj['mad_or_cat'] = ApiClient.convertToType(data['mad_or_cat'], 'String');
            }
            if (data.hasOwnProperty('blocks')) {
                obj['blocks'] = DocumentResponseBlocks.constructFromObject(data['blocks']);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/DocumentResponse.DocStatusEnum} doc_status
 */
DocumentResponse.prototype['doc_status'] = undefined;

/**
 * @member {Boolean} is_work_with_children
 */
DocumentResponse.prototype['is_work_with_children'] = undefined;

/**
 * @member {Boolean} is_health
 */
DocumentResponse.prototype['is_health'] = undefined;

/**
 * @member {Boolean} is_dress_uniform
 */
DocumentResponse.prototype['is_dress_uniform'] = undefined;

/**
 * @member {Boolean} is_dress_coat
 */
DocumentResponse.prototype['is_dress_coat'] = undefined;

/**
 * @member {Boolean} is_dress_pijama
 */
DocumentResponse.prototype['is_dress_pijama'] = undefined;

/**
 * @member {Boolean} is_dosimeter
 */
DocumentResponse.prototype['is_dosimeter'] = undefined;

/**
 * @member {String} fct_start_month
 */
DocumentResponse.prototype['fct_start_month'] = undefined;

/**
 * @member {String} mad_or_cat
 */
DocumentResponse.prototype['mad_or_cat'] = undefined;

/**
 * @member {module:model/DocumentResponseBlocks} blocks
 */
DocumentResponse.prototype['blocks'] = undefined;





/**
 * Allowed values for the <code>doc_status</code> property.
 * @enum {String}
 * @readonly
 */
DocumentResponse['DocStatusEnum'] = {

    /**
     * value: "pending"
     * @const
     */
    "pending": "pending",

    /**
     * value: "validating"
     * @const
     */
    "validating": "validating",

    /**
     * value: "ok"
     * @const
     */
    "ok": "ok",

    /**
     * value: "error"
     * @const
     */
    "error": "error"
};



export default DocumentResponse;

