import {SET_HISTORIAL_MATRICULAS, SET_MATRICULA_DETALLE} from "../actions/constants";
import {createReducer} from "../../../_common/common.ts";

export const historial_matriculas = createReducer(null, {
    [SET_HISTORIAL_MATRICULAS]: (state, payload)=> payload
});

export const matricula_detalle = createReducer(null, {
    [SET_MATRICULA_DETALLE]: (state, payload)=> payload
});
