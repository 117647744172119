import React, { h, Fragment } from "preact";
import {t} from '../../../_i18n';
import './noticeModule.scss'
import GradeBox from "./gradeBox";
import {Tooltip} from "../Tooltip/Tooltip";

const statusClass = {
    'approved': 'approved',
    'failed': 'no-approved',
    'indifferent': 'indifferent'
}

const  NoticeModule = ({subTitle, title, grade, isEnrolled, enrolledStatus, approved, onClick, isEnabled,
                           large = false, gradeTooltip = '', textInfo = '',
                           hiddenGrade = false, isTrainingWorkCenterModule = false,
                           isProjectModule = false}) => {
    const classLarge = large ? 'large' : ''
    const isntEnrolled = (!isEnrolled && enrolledStatus === 0)
    const status =
      approved ? 'approved'
      : enrolledStatus === 3 ? 'failed' : 'indifferent'

    const renderGrade = () => {
        return (gradeTooltip === '') ? <GradeBox grade={grade} approved={approved} hide={isntEnrolled} status={status}/>
            : <Tooltip text={gradeTooltip}>
                <GradeBox grade={grade} approved={approved} hide={isntEnrolled} status={status}/>
            </Tooltip>
    }

    const getHiddenGradeText = () => {
        if (isProjectModule) {
            return t('project_grade_missing_fct_grade_tooltip')
        } else if (isTrainingWorkCenterModule) {
            return t('fct_grade_missing_project_grade_tooltip')
        }
        return ''
    }

    return (
        <div className={`notice-module ${isEnabled && 'pointer'} ${statusClass[status]}`} onClick={() => isEnabled && onClick()}>
            <div className='notice-module-container'>
                <div className={`notice-module-text ${classLarge}`}>
                    <div className={`notice-module-text__subtitle ${large && 'space-left'}`}>{subTitle}</div>
                    <div className={`notice-module-text__title ${large && 'space-left'}`}>{title}</div>
                    {textInfo && textInfo !== '' &&
                        <div className={`notice-module-text__info ${large && 'space-left'}`}>
                            <i className="fa fa-info-circle m-r-half"></i>
                            <span className='font-color-default' dangerouslySetInnerHTML={{__html: textInfo}}></span>
                        </div>
                    }
                </div>
                <div className={`notice-module-info ${classLarge}`}>
                    {hiddenGrade &&
                        <Tooltip text={getHiddenGradeText()}>
                            <i className="fa fa-info-circle m-r-half"></i>
                        </Tooltip>
                    }
                </div>
                <div className={`notice-module-grade ${classLarge}`}>
                    {renderGrade()}
                    <div className={`notice-module-access  ${large && 'space-left'}`}>
                        {isEnabled && <i className="fal fa-chevron-right notice-module-text__translucent"/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoticeModule
