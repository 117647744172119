/**
 * LTI Enroll Exam
 * LTI Enroll Exam for Students
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';
import EvauInscriptionStatus from '../model/EvauInscriptionStatus';
import InlineResponse200 from '../model/InlineResponse200';
import InlineResponse2001 from '../model/InlineResponse2001';
import InlineResponse400 from '../model/InlineResponse400';

/**
* EVAU service.
* @module api/EVAUApi
* @version 2.0.0
*/
export default class EVAUApi {

    /**
    * Constructs a new EVAUApi. 
    * @alias module:api/EVAUApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get documentation status
     * @param {String} studentId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2001} and HTTP response
     */
    ebauInscriptionsControllerGetStatusWithHttpInfo(studentId) {
      let postBody = null;
      // verify the required parameter 'studentId' is set
      if (studentId === undefined || studentId === null) {
        throw new Error("Missing the required parameter 'studentId' when calling ebauInscriptionsControllerGetStatus");
      }

      let pathParams = {
        'student_id': studentId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2001;
      return this.apiClient.callApi(
        '/lms/students/{student_id}/ebau-inscriptions/status', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get documentation status
     * @param {String} studentId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2001}
     */
    ebauInscriptionsControllerGetStatus(studentId) {
      return this.ebauInscriptionsControllerGetStatusWithHttpInfo(studentId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get documentation
     * @param {String} studentId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/EvauInscriptionStatus>} and HTTP response
     */
    ebauInscriptionsControllerShowCurrentWithHttpInfo(studentId) {
      let postBody = null;
      // verify the required parameter 'studentId' is set
      if (studentId === undefined || studentId === null) {
        throw new Error("Missing the required parameter 'studentId' when calling ebauInscriptionsControllerShowCurrent");
      }

      let pathParams = {
        'student_id': studentId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [EvauInscriptionStatus];
      return this.apiClient.callApi(
        '/lms/students/{student_id}/ebau-inscriptions/show', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get documentation
     * @param {String} studentId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/EvauInscriptionStatus>}
     */
    ebauInscriptionsControllerShowCurrent(studentId) {
      return this.ebauInscriptionsControllerShowCurrentWithHttpInfo(studentId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Upload documentation
     * @param {String} studentId 
     * @param {Object} opts Optional parameters
     * @param {File} opts.file Archivo a subir
     * @param {String} opts.documentTypeId ID del tipo de documento
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse200} and HTTP response
     */
    ebauInscriptionsControllerStoreWithHttpInfo(studentId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'studentId' is set
      if (studentId === undefined || studentId === null) {
        throw new Error("Missing the required parameter 'studentId' when calling ebauInscriptionsControllerStore");
      }

      let pathParams = {
        'student_id': studentId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': opts['file'],
        'document_type_id': opts['documentTypeId']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json', 'aplication/json'];
      let returnType = InlineResponse200;
      return this.apiClient.callApi(
        '/lms/students/{student_id}/ebau-inscriptions', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Upload documentation
     * @param {String} studentId 
     * @param {Object} opts Optional parameters
     * @param {File} opts.file Archivo a subir
     * @param {String} opts.documentTypeId ID del tipo de documento
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse200}
     */
    ebauInscriptionsControllerStore(studentId, opts) {
      return this.ebauInscriptionsControllerStoreWithHttpInfo(studentId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
