import React, { Fragment, h } from "preact";
import {connect} from "react-redux";
import {Card} from "../../_common/layout";
import AlertBox from "../../_common/components/AlertBox/AlertBox";


function GiftCompleted ({prestaAddress}) {
    const {address, cp, state, city} = prestaAddress;

    return (
        <Card>
            <div className='m-top-half'>
                <div>En los próximos días recibirás tu regalo en la dirección que has indicado:</div>
                <AlertBox type='info' className='m-x2-top m-x2-bottom'>
                    <div>{address}</div>
                    <div>{`${cp} ${city} - ${state}`}</div>
                </AlertBox>
                <div className='font-medium m-x2-bottom'>¡Disfrútalo! 😊</div>
            </div>
        </Card>
    )
}


GiftCompleted = connect(
    ({ status: {student_meta: {presta_address}}})=>({prestaAddress: presta_address}),
    { })(GiftCompleted);


export default ({}) => <GiftCompleted />

