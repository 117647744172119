/**
 * FCT Account API
 * FCT Account API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: areche@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from './ApiClient';
import ActivityItem from './model/ActivityItem';
import AttachedDocument from './model/AttachedDocument';
import Availability from './model/Availability';
import Cities from './model/Cities';
import CompanyInfo from './model/CompanyInfo';
import CompanyInfoDocuments from './model/CompanyInfoDocuments';
import ContractDetails from './model/ContractDetails';
import ContractStatus from './model/ContractStatus';
import ContributedDays from './model/ContributedDays';
import DayAvailability from './model/DayAvailability';
import DefaultApiResponse from './model/DefaultApiResponse';
import DocumentInfo from './model/DocumentInfo';
import DocumentLimits from './model/DocumentLimits';
import DocumentMetadata from './model/DocumentMetadata';
import DocumentRequest from './model/DocumentRequest';
import DocumentResponse from './model/DocumentResponse';
import DocumentResponseBlocks from './model/DocumentResponseBlocks';
import DownloadResponse from './model/DownloadResponse';
import Exemption from './model/Exemption';
import ExemptionDocuments from './model/ExemptionDocuments';
import ExemptionMeta from './model/ExemptionMeta';
import FCTInOtherCity from './model/FCTInOtherCity';
import FileRemovedResponse from './model/FileRemovedResponse';
import LoginResponse from './model/LoginResponse';
import MaterialAddress from './model/MaterialAddress';
import Message from './model/Message';
import MessageResponse from './model/MessageResponse';
import OtherDocs from './model/OtherDocs';
import OtherDocsDocuments from './model/OtherDocsDocuments';
import PeriodDetails from './model/PeriodDetails';
import ProvinceItem from './model/ProvinceItem';
import RefuseResponse from './model/RefuseResponse';
import ScreenData from './model/ScreenData';
import SignContractResponse from './model/SignContractResponse';
import Size from './model/Size';
import StaticInfo from './model/StaticInfo';
import StaticInfoTemplates from './model/StaticInfoTemplates';
import StudentDetails from './model/StudentDetails';
import StudentInfo from './model/StudentInfo';
import StudentInfoDocuments from './model/StudentInfoDocuments';
import SuccessResponse from './model/SuccessResponse';
import TemplateInfo from './model/TemplateInfo';
import UploadResponse from './model/UploadResponse';
import WeeklyDoc from './model/WeeklyDoc';
import AdressApi from './api/AdressApi';
import ContractManagementApi from './api/ContractManagementApi';
import DocumentMetaApi from './api/DocumentMetaApi';
import DocumentsApi from './api/DocumentsApi';
import LoginApi from './api/LoginApi';
import MessagingApi from './api/MessagingApi';
import StaticsApi from './api/StaticsApi';
import StatusApi from './api/StatusApi';


/**
* FCT_Account_API.<br>
* The <code>index</code> module provides access to constructors for all the classes which comprise the public API.
* <p>
* An AMD (recommended!) or CommonJS application will generally do something equivalent to the following:
* <pre>
* var FctAccountApi = require('index'); // See note below*.
* var xxxSvc = new FctAccountApi.XxxApi(); // Allocate the API class we're going to use.
* var yyyModel = new FctAccountApi.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* <em>*NOTE: For a top-level AMD script, use require(['index'], function(){...})
* and put the application logic within the callback function.</em>
* </p>
* <p>
* A non-AMD browser application (discouraged) might do something like this:
* <pre>
* var xxxSvc = new FctAccountApi.XxxApi(); // Allocate the API class we're going to use.
* var yyy = new FctAccountApi.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* </p>
* @module index
* @version 1.0.0
*/
export {
    /**
     * The ApiClient constructor.
     * @property {module:ApiClient}
     */
    ApiClient,

    /**
     * The ActivityItem model constructor.
     * @property {module:model/ActivityItem}
     */
    ActivityItem,

    /**
     * The AttachedDocument model constructor.
     * @property {module:model/AttachedDocument}
     */
    AttachedDocument,

    /**
     * The Availability model constructor.
     * @property {module:model/Availability}
     */
    Availability,

    /**
     * The Cities model constructor.
     * @property {module:model/Cities}
     */
    Cities,

    /**
     * The CompanyInfo model constructor.
     * @property {module:model/CompanyInfo}
     */
    CompanyInfo,

    /**
     * The CompanyInfoDocuments model constructor.
     * @property {module:model/CompanyInfoDocuments}
     */
    CompanyInfoDocuments,

    /**
     * The ContractDetails model constructor.
     * @property {module:model/ContractDetails}
     */
    ContractDetails,

    /**
     * The ContractStatus model constructor.
     * @property {module:model/ContractStatus}
     */
    ContractStatus,

    /**
     * The ContributedDays model constructor.
     * @property {module:model/ContributedDays}
     */
    ContributedDays,

    /**
     * The DayAvailability model constructor.
     * @property {module:model/DayAvailability}
     */
    DayAvailability,

    /**
     * The DefaultApiResponse model constructor.
     * @property {module:model/DefaultApiResponse}
     */
    DefaultApiResponse,

    /**
     * The DocumentInfo model constructor.
     * @property {module:model/DocumentInfo}
     */
    DocumentInfo,

    /**
     * The DocumentLimits model constructor.
     * @property {module:model/DocumentLimits}
     */
    DocumentLimits,

    /**
     * The DocumentMetadata model constructor.
     * @property {module:model/DocumentMetadata}
     */
    DocumentMetadata,

    /**
     * The DocumentRequest model constructor.
     * @property {module:model/DocumentRequest}
     */
    DocumentRequest,

    /**
     * The DocumentResponse model constructor.
     * @property {module:model/DocumentResponse}
     */
    DocumentResponse,

    /**
     * The DocumentResponseBlocks model constructor.
     * @property {module:model/DocumentResponseBlocks}
     */
    DocumentResponseBlocks,

    /**
     * The DownloadResponse model constructor.
     * @property {module:model/DownloadResponse}
     */
    DownloadResponse,

    /**
     * The Exemption model constructor.
     * @property {module:model/Exemption}
     */
    Exemption,

    /**
     * The ExemptionDocuments model constructor.
     * @property {module:model/ExemptionDocuments}
     */
    ExemptionDocuments,

    /**
     * The ExemptionMeta model constructor.
     * @property {module:model/ExemptionMeta}
     */
    ExemptionMeta,

    /**
     * The FCTInOtherCity model constructor.
     * @property {module:model/FCTInOtherCity}
     */
    FCTInOtherCity,

    /**
     * The FileRemovedResponse model constructor.
     * @property {module:model/FileRemovedResponse}
     */
    FileRemovedResponse,

    /**
     * The LoginResponse model constructor.
     * @property {module:model/LoginResponse}
     */
    LoginResponse,

    /**
     * The MaterialAddress model constructor.
     * @property {module:model/MaterialAddress}
     */
    MaterialAddress,

    /**
     * The Message model constructor.
     * @property {module:model/Message}
     */
    Message,

    /**
     * The MessageResponse model constructor.
     * @property {module:model/MessageResponse}
     */
    MessageResponse,

    /**
     * The OtherDocs model constructor.
     * @property {module:model/OtherDocs}
     */
    OtherDocs,

    /**
     * The OtherDocsDocuments model constructor.
     * @property {module:model/OtherDocsDocuments}
     */
    OtherDocsDocuments,

    /**
     * The PeriodDetails model constructor.
     * @property {module:model/PeriodDetails}
     */
    PeriodDetails,

    /**
     * The ProvinceItem model constructor.
     * @property {module:model/ProvinceItem}
     */
    ProvinceItem,

    /**
     * The RefuseResponse model constructor.
     * @property {module:model/RefuseResponse}
     */
    RefuseResponse,

    /**
     * The ScreenData model constructor.
     * @property {module:model/ScreenData}
     */
    ScreenData,

    /**
     * The SignContractResponse model constructor.
     * @property {module:model/SignContractResponse}
     */
    SignContractResponse,

    /**
     * The Size model constructor.
     * @property {module:model/Size}
     */
    Size,

    /**
     * The StaticInfo model constructor.
     * @property {module:model/StaticInfo}
     */
    StaticInfo,

    /**
     * The StaticInfoTemplates model constructor.
     * @property {module:model/StaticInfoTemplates}
     */
    StaticInfoTemplates,

    /**
     * The StudentDetails model constructor.
     * @property {module:model/StudentDetails}
     */
    StudentDetails,

    /**
     * The StudentInfo model constructor.
     * @property {module:model/StudentInfo}
     */
    StudentInfo,

    /**
     * The StudentInfoDocuments model constructor.
     * @property {module:model/StudentInfoDocuments}
     */
    StudentInfoDocuments,

    /**
     * The SuccessResponse model constructor.
     * @property {module:model/SuccessResponse}
     */
    SuccessResponse,

    /**
     * The TemplateInfo model constructor.
     * @property {module:model/TemplateInfo}
     */
    TemplateInfo,

    /**
     * The UploadResponse model constructor.
     * @property {module:model/UploadResponse}
     */
    UploadResponse,

    /**
     * The WeeklyDoc model constructor.
     * @property {module:model/WeeklyDoc}
     */
    WeeklyDoc,

    /**
    * The AdressApi service constructor.
    * @property {module:api/AdressApi}
    */
    AdressApi,

    /**
    * The ContractManagementApi service constructor.
    * @property {module:api/ContractManagementApi}
    */
    ContractManagementApi,

    /**
    * The DocumentMetaApi service constructor.
    * @property {module:api/DocumentMetaApi}
    */
    DocumentMetaApi,

    /**
    * The DocumentsApi service constructor.
    * @property {module:api/DocumentsApi}
    */
    DocumentsApi,

    /**
    * The LoginApi service constructor.
    * @property {module:api/LoginApi}
    */
    LoginApi,

    /**
    * The MessagingApi service constructor.
    * @property {module:api/MessagingApi}
    */
    MessagingApi,

    /**
    * The StaticsApi service constructor.
    * @property {module:api/StaticsApi}
    */
    StaticsApi,

    /**
    * The StatusApi service constructor.
    * @property {module:api/StatusApi}
    */
    StatusApi
};
