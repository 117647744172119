import { svcNoticeService } from "../../../_services/notice-service.js";
import {SET_COMUNICADOS, SET_COMUNICADO, SET_COMUNICADO_ADJUNTOS, SET_COMUNICADOS_STATUS} from "./constants";
import {globalCatch} from "../../../_services/global-catch";
import {SET_OFFER} from "../../jobs/actions/constants";

export const setComunicados = payload => ({type: SET_COMUNICADOS, payload});
export const setComunicado = payload => ({type: SET_COMUNICADO, payload});
export const setComunicadoAdjuntos = payload => ({type: SET_COMUNICADO_ADJUNTOS, payload});
export const setComunicadosStatus = payload => ({type: SET_COMUNICADOS_STATUS, payload});

export const fetchComunicados = () =>
    globalCatch.catch(async (dispatch) => {
        const comunicados = await (await svcNoticeService()).getComunicados();
        dispatch(setComunicados(comunicados));
    })

export const fetchComunicado = uid =>
    globalCatch.catch(async (dispatch) => {
        const comunicado = await (await svcNoticeService()).getComunicado(uid);
        dispatch(setComunicado(comunicado));
        dispatch(fetchComunicadosStatus());
    })

export const clearComunciado  = () => ({type: SET_COMUNICADO, payload: null});

export const fetchComunicadoAdjuntosMeta = uid =>
    globalCatch.catch(async (dispatch) => {
        const adjuntos = await (await svcNoticeService()).getComunicadoAdjuntosMeta(uid);
        dispatch(setComunicadoAdjuntos(adjuntos));
    })

export const fetchComunicadosStatus = () =>
    globalCatch.catch(async (dispatch) => {
        let status = await (await svcNoticeService()).getStatus();
        dispatch(setComunicadosStatus(status));
    })


