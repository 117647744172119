import { h } from "preact";
import AlertCollapse from '../../../_common/components/AlertCollapse/AlertCollapse'
import AlertBox from '../../../_common/components/AlertBox/AlertBox'
import { RadioCheckbox } from '../../../_common/forms/components/components'
import { t } from '../../../_i18n'
import { useState } from 'preact/hooks'
import { fetchStatus, rejectCompany } from '../../../_rx/fct/actions'
import { useHistory, useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import ButtonSpinner from '../../../_common/components/buttons/buttonSpinner/buttonSpinner'


export default function AlertRejectCompany ({ name, document }) {
  const [check, setCheck] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()

  const handleReject = () => {
    setLoading(true)
    rejectCompany(id)
      .then(() => {
        setLoading(false)
        dispatch(fetchStatus(id))
        history.push(`/fct/${id}`)
      })
      .catch(() => setLoading(false))
  }

  return (
    <AlertCollapse title={t('👋 ¡Hola! Necesitamos que nos envíes tu confirmación de rechazo de empresa.')}>
      <p className='font-bold'>{t('Nos han informado que has rechazado la empresa que te hemos proporcionado.')} </p>
      <p>{t('Para poder continuar con el proceso de asignación de empresa es imprescindible que aceptes la siguiente declaración:')}</p>
      <AlertBox type='dashed'>
        <p>{t('alert_reject_info').replace('name', name).replace('document', document)}</p>
        <p>{t('Por ello, según la normativa de ILERNA Online, se priorizará la reasignación de esta plaza a otros alumnos, poniendo tu caso en la cola de gestión. Para agilizar tu proceso en estos casos, recomendamos que busques y aportes una empresa que se adapte a tus necesidades. Recuerda que como máximo tendrás un semestre más para poder aportarla, pudiendo iniciar las prácticas sin coste alguno, ya que en caso contrario deberás volver a matricularte para posteriores semestres.')}</p>
        <RadioCheckbox type='checkbox' label={t('Acepto renunciar a la empresa que me ha propuesto ILERNA.')} defaultValue={check} onChange={(e) => setCheck(e)} />
        <ButtonSpinner classNameContainer='m-top' disabled={!check} onClick={handleReject} showSpinner={loading}>{t('Aceptar y enviar')}</ButtonSpinner>
      </AlertBox>
    </AlertCollapse>
  )
}


/*



*/
