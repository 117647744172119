import {Fragment, h} from "preact";
import {t} from '../../_i18n';

export const ItFrRequirements = () => <div className="max-width-sm margin-auto">
        <h1>{t('student_requirement_1')}</h1>
        <div className="innerText">
            <p>{t('student_requirement_2')}</p>
        </div>
        <div className="innerText">
            <p>{t('student_requirement_3')}</p>
        </div>
        <div className="warning-text">
            <span>{t('student_requirement_4')}</span>
        </div>
</div>;

