import React, {Component, Fragment, h} from "preact";
import ReactTooltip from "react-tooltip";

interface Props {
    children: JSX.Element,
    text: string,
    effect?: 'solid' | 'float'
}

export const Tooltip = ({children, text, effect = 'solid'}: Props) => (
    <span>
        <span
            data-for="main"
            data-tip={text}
            data-iscapture="true"
        >
            {children}
        </span>
        <ReactTooltip
            id="main"
            type={'dark'}
            effect={effect}
            multiline={true}
        />
    </span>
)