/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AcademicProgram from './AcademicProgram';
import CandidateOfferFiltersModalityList from './CandidateOfferFiltersModalityList';
import Presence from './Presence';
import State from './State';

/**
 * The CompanyOfferFilters model module.
 * @module model/CompanyOfferFilters
 * @version v1
 */
class CompanyOfferFilters {
    /**
     * Constructs a new <code>CompanyOfferFilters</code>.
     * Detalle filtros
     * @alias module:model/CompanyOfferFilters
     */
    constructor() { 
        
        CompanyOfferFilters.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CompanyOfferFilters</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CompanyOfferFilters} obj Optional instance to populate.
     * @return {module:model/CompanyOfferFilters} The populated <code>CompanyOfferFilters</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CompanyOfferFilters();

            if (data.hasOwnProperty('show_general')) {
                obj['show_general'] = ApiClient.convertToType(data['show_general'], 'Boolean');
            }
            if (data.hasOwnProperty('show_modalities')) {
                obj['show_modalities'] = ApiClient.convertToType(data['show_modalities'], 'Boolean');
            }
            if (data.hasOwnProperty('modality_list')) {
                obj['modality_list'] = ApiClient.convertToType(data['modality_list'], [CandidateOfferFiltersModalityList]);
            }
            if (data.hasOwnProperty('show_states')) {
                obj['show_states'] = ApiClient.convertToType(data['show_states'], 'Boolean');
            }
            if (data.hasOwnProperty('state_list')) {
                obj['state_list'] = ApiClient.convertToType(data['state_list'], [State]);
            }
            if (data.hasOwnProperty('show_presences')) {
                obj['show_presences'] = ApiClient.convertToType(data['show_presences'], 'Boolean');
            }
            if (data.hasOwnProperty('presence_list')) {
                obj['presence_list'] = ApiClient.convertToType(data['presence_list'], [Presence]);
            }
            if (data.hasOwnProperty('show_academic_program_scopes')) {
                obj['show_academic_program_scopes'] = ApiClient.convertToType(data['show_academic_program_scopes'], 'Boolean');
            }
            if (data.hasOwnProperty('academic_program_scope_list')) {
                obj['academic_program_scope_list'] = ApiClient.convertToType(data['academic_program_scope_list'], [AcademicProgram]);
            }
        }
        return obj;
    }


}

/**
 * @member {Boolean} show_general
 */
CompanyOfferFilters.prototype['show_general'] = undefined;

/**
 * @member {Boolean} show_modalities
 */
CompanyOfferFilters.prototype['show_modalities'] = undefined;

/**
 * @member {Array.<module:model/CandidateOfferFiltersModalityList>} modality_list
 */
CompanyOfferFilters.prototype['modality_list'] = undefined;

/**
 * @member {Boolean} show_states
 */
CompanyOfferFilters.prototype['show_states'] = undefined;

/**
 * @member {Array.<module:model/State>} state_list
 */
CompanyOfferFilters.prototype['state_list'] = undefined;

/**
 * @member {Boolean} show_presences
 */
CompanyOfferFilters.prototype['show_presences'] = undefined;

/**
 * @member {Array.<module:model/Presence>} presence_list
 */
CompanyOfferFilters.prototype['presence_list'] = undefined;

/**
 * @member {Boolean} show_academic_program_scopes
 */
CompanyOfferFilters.prototype['show_academic_program_scopes'] = undefined;

/**
 * @member {Array.<module:model/AcademicProgram>} academic_program_scope_list
 */
CompanyOfferFilters.prototype['academic_program_scope_list'] = undefined;






export default CompanyOfferFilters;

