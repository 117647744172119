/**
 * Student Account
 * Student Account Api - Validation flow
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dmartinez@ilernaonline.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PrestaAddress from './PrestaAddress';
import StudentMetaCoursingAcademicPrograms from './StudentMetaCoursingAcademicPrograms';
import StudentMetaFctContracts from './StudentMetaFctContracts';

/**
 * The StudentMeta model module.
 * @module model/StudentMeta
 * @version 1.0.0
 */
class StudentMeta {
    /**
     * Constructs a new <code>StudentMeta</code>.
     * @alias module:model/StudentMeta
     */
    constructor() { 
        
        StudentMeta.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>StudentMeta</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StudentMeta} obj Optional instance to populate.
     * @return {module:model/StudentMeta} The populated <code>StudentMeta</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StudentMeta();

            if (data.hasOwnProperty('student_type')) {
                obj['student_type'] = ApiClient.convertToType(data['student_type'], 'String');
            }
            if (data.hasOwnProperty('shop_id')) {
                obj['shop_id'] = ApiClient.convertToType(data['shop_id'], 'Number');
            }
            if (data.hasOwnProperty('canvas_id')) {
                obj['canvas_id'] = ApiClient.convertToType(data['canvas_id'], 'Number');
            }
            if (data.hasOwnProperty('avatar_url')) {
                obj['avatar_url'] = ApiClient.convertToType(data['avatar_url'], 'String');
            }
            if (data.hasOwnProperty('has_whatsapp')) {
                obj['has_whatsapp'] = ApiClient.convertToType(data['has_whatsapp'], 'Boolean');
            }
            if (data.hasOwnProperty('enabled')) {
                obj['enabled'] = ApiClient.convertToType(data['enabled'], 'Boolean');
            }
            if (data.hasOwnProperty('canvas_access')) {
                obj['canvas_access'] = ApiClient.convertToType(data['canvas_access'], 'Boolean');
            }
            if (data.hasOwnProperty('is_on_site')) {
                obj['is_on_site'] = ApiClient.convertToType(data['is_on_site'], 'Boolean');
            }
            if (data.hasOwnProperty('is_blended')) {
                obj['is_blended'] = ApiClient.convertToType(data['is_blended'], 'Boolean');
            }
            if (data.hasOwnProperty('has_any_order')) {
                obj['has_any_order'] = ApiClient.convertToType(data['has_any_order'], 'Boolean');
            }
            if (data.hasOwnProperty('has_coursing_enroll')) {
                obj['has_coursing_enroll'] = ApiClient.convertToType(data['has_coursing_enroll'], 'Boolean');
            }
            if (data.hasOwnProperty('student_name')) {
                obj['student_name'] = ApiClient.convertToType(data['student_name'], 'String');
            }
            if (data.hasOwnProperty('student_email')) {
                obj['student_email'] = ApiClient.convertToType(data['student_email'], 'String');
            }
            if (data.hasOwnProperty('has_signed_condition')) {
                obj['has_signed_condition'] = ApiClient.convertToType(data['has_signed_condition'], 'Boolean');
            }
            if (data.hasOwnProperty('has_signed_conditions_previously')) {
                obj['has_signed_conditions_previously'] = ApiClient.convertToType(data['has_signed_conditions_previously'], 'Boolean');
            }
            if (data.hasOwnProperty('allow_use_images')) {
                obj['allow_use_images'] = ApiClient.convertToType(data['allow_use_images'], 'Boolean');
            }
            if (data.hasOwnProperty('has_academic_program_document_types')) {
                obj['has_academic_program_document_types'] = ApiClient.convertToType(data['has_academic_program_document_types'], 'Boolean');
            }
            if (data.hasOwnProperty('academic_program_id')) {
                obj['academic_program_id'] = ApiClient.convertToType(data['academic_program_id'], 'Number');
            }
            if (data.hasOwnProperty('start_month')) {
                obj['start_month'] = ApiClient.convertToType(data['start_month'], 'String');
            }
            if (data.hasOwnProperty('show_expedient')) {
                obj['show_expedient'] = ApiClient.convertToType(data['show_expedient'], 'Boolean');
            }
            if (data.hasOwnProperty('presta_buy_url')) {
                obj['presta_buy_url'] = ApiClient.convertToType(data['presta_buy_url'], 'String');
            }
            if (data.hasOwnProperty('unreadCount')) {
                obj['unreadCount'] = ApiClient.convertToType(data['unreadCount'], 'Number');
            }
            if (data.hasOwnProperty('present')) {
                obj['present'] = ApiClient.convertToType(data['present'], 'Boolean');
            }
            if (data.hasOwnProperty('presta_address')) {
                obj['presta_address'] = PrestaAddress.constructFromObject(data['presta_address']);
            }
            if (data.hasOwnProperty('has_convas')) {
                obj['has_convas'] = ApiClient.convertToType(data['has_convas'], 'Boolean');
            }
            if (data.hasOwnProperty('has_fct')) {
                obj['has_fct'] = ApiClient.convertToType(data['has_fct'], 'Boolean');
            }
            if (data.hasOwnProperty('formative_zone')) {
                obj['formative_zone'] = ApiClient.convertToType(data['formative_zone'], 'String');
            }
            if (data.hasOwnProperty('has_pending_exam_inscriptions')) {
                obj['has_pending_exam_inscriptions'] = ApiClient.convertToType(data['has_pending_exam_inscriptions'], 'Boolean');
            }
            if (data.hasOwnProperty('has_pending_class_inscriptions')) {
                obj['has_pending_class_inscriptions'] = ApiClient.convertToType(data['has_pending_class_inscriptions'], 'Boolean');
            }
            if (data.hasOwnProperty('is_eso')) {
                obj['is_eso'] = ApiClient.convertToType(data['is_eso'], 'Boolean');
            }
            if (data.hasOwnProperty('fct_contracts')) {
                obj['fct_contracts'] = ApiClient.convertToType(data['fct_contracts'], [StudentMetaFctContracts]);
            }
            if (data.hasOwnProperty('online_campus_url')) {
                obj['online_campus_url'] = ApiClient.convertToType(data['online_campus_url'], 'String');
            }
            if (data.hasOwnProperty('presential_campus_url')) {
                obj['presential_campus_url'] = ApiClient.convertToType(data['presential_campus_url'], 'String');
            }
            if (data.hasOwnProperty('coursing_academic_programs')) {
                obj['coursing_academic_programs'] = ApiClient.convertToType(data['coursing_academic_programs'], [StudentMetaCoursingAcademicPrograms]);
            }
        }
        return obj;
    }


}

/**
 * @member {String} student_type
 */
StudentMeta.prototype['student_type'] = undefined;

/**
 * @member {Number} shop_id
 */
StudentMeta.prototype['shop_id'] = undefined;

/**
 * @member {Number} canvas_id
 */
StudentMeta.prototype['canvas_id'] = undefined;

/**
 * @member {String} avatar_url
 */
StudentMeta.prototype['avatar_url'] = undefined;

/**
 * @member {Boolean} has_whatsapp
 */
StudentMeta.prototype['has_whatsapp'] = undefined;

/**
 * @member {Boolean} enabled
 */
StudentMeta.prototype['enabled'] = undefined;

/**
 * @member {Boolean} canvas_access
 */
StudentMeta.prototype['canvas_access'] = undefined;

/**
 * @member {Boolean} is_on_site
 */
StudentMeta.prototype['is_on_site'] = undefined;

/**
 * @member {Boolean} is_blended
 */
StudentMeta.prototype['is_blended'] = undefined;

/**
 * @member {Boolean} has_any_order
 */
StudentMeta.prototype['has_any_order'] = undefined;

/**
 * @member {Boolean} has_coursing_enroll
 */
StudentMeta.prototype['has_coursing_enroll'] = undefined;

/**
 * @member {String} student_name
 */
StudentMeta.prototype['student_name'] = undefined;

/**
 * @member {String} student_email
 */
StudentMeta.prototype['student_email'] = undefined;

/**
 * @member {Boolean} has_signed_condition
 */
StudentMeta.prototype['has_signed_condition'] = undefined;

/**
 * @member {Boolean} has_signed_conditions_previously
 */
StudentMeta.prototype['has_signed_conditions_previously'] = undefined;

/**
 * @member {Boolean} allow_use_images
 */
StudentMeta.prototype['allow_use_images'] = undefined;

/**
 * @member {Boolean} has_academic_program_document_types
 */
StudentMeta.prototype['has_academic_program_document_types'] = undefined;

/**
 * @member {Number} academic_program_id
 */
StudentMeta.prototype['academic_program_id'] = undefined;

/**
 * @member {String} start_month
 */
StudentMeta.prototype['start_month'] = undefined;

/**
 * @member {Boolean} show_expedient
 */
StudentMeta.prototype['show_expedient'] = undefined;

/**
 * @member {String} presta_buy_url
 */
StudentMeta.prototype['presta_buy_url'] = undefined;

/**
 * @member {Number} unreadCount
 */
StudentMeta.prototype['unreadCount'] = undefined;

/**
 * @member {Boolean} present
 */
StudentMeta.prototype['present'] = undefined;

/**
 * @member {module:model/PrestaAddress} presta_address
 */
StudentMeta.prototype['presta_address'] = undefined;

/**
 * @member {Boolean} has_convas
 */
StudentMeta.prototype['has_convas'] = undefined;

/**
 * @member {Boolean} has_fct
 */
StudentMeta.prototype['has_fct'] = undefined;

/**
 * @member {String} formative_zone
 */
StudentMeta.prototype['formative_zone'] = undefined;

/**
 * @member {Boolean} has_pending_exam_inscriptions
 */
StudentMeta.prototype['has_pending_exam_inscriptions'] = undefined;

/**
 * @member {Boolean} has_pending_class_inscriptions
 */
StudentMeta.prototype['has_pending_class_inscriptions'] = undefined;

/**
 * @member {Boolean} is_eso
 */
StudentMeta.prototype['is_eso'] = undefined;

/**
 * @member {Array.<module:model/StudentMetaFctContracts>} fct_contracts
 */
StudentMeta.prototype['fct_contracts'] = undefined;

/**
 * @member {String} online_campus_url
 */
StudentMeta.prototype['online_campus_url'] = undefined;

/**
 * @member {String} presential_campus_url
 */
StudentMeta.prototype['presential_campus_url'] = undefined;

/**
 * @member {Array.<module:model/StudentMetaCoursingAcademicPrograms>} coursing_academic_programs
 */
StudentMeta.prototype['coursing_academic_programs'] = undefined;






export default StudentMeta;

