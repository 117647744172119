/**
 * New Expedient
 * Expedient for Student Account
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Certificate model module.
 * @module model/Certificate
 * @version 1.0
 */
class Certificate {
    /**
     * Constructs a new <code>Certificate</code>.
     * @alias module:model/Certificate
     */
    constructor() { 
        
        Certificate.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Certificate</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Certificate} obj Optional instance to populate.
     * @return {module:model/Certificate} The populated <code>Certificate</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Certificate();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('link')) {
                obj['link'] = ApiClient.convertToType(data['link'], 'String');
            }
            if (data.hasOwnProperty('disabled')) {
                obj['disabled'] = ApiClient.convertToType(data['disabled'], 'Boolean');
            }
            if (data.hasOwnProperty('downloable')) {
                obj['downloable'] = ApiClient.convertToType(data['downloable'], 'Boolean');
            }
            if (data.hasOwnProperty('digitally_signed')) {
                obj['digitally_signed'] = ApiClient.convertToType(data['digitally_signed'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {String} name
 */
Certificate.prototype['name'] = undefined;

/**
 * @member {String} link
 */
Certificate.prototype['link'] = undefined;

/**
 * @member {Boolean} disabled
 */
Certificate.prototype['disabled'] = undefined;

/**
 * @member {Boolean} downloable
 */
Certificate.prototype['downloable'] = undefined;

/**
 * @member {Boolean} digitally_signed
 */
Certificate.prototype['digitally_signed'] = undefined;






export default Certificate;

