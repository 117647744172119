import React, { h, Fragment } from "preact";
import { useEffect } from 'preact/hooks';
import {AppPage} from "../_common/components/app-page";
import {t} from '../_i18n';
import {CF, E, R, RE, F, FR} from "../_common/layout";
import { useSelector, useDispatch } from 'react-redux'
import {fetchComunicado, fetchComunicadoAdjuntosMeta, clearComunciado} from '../_rx/comunicados/actions'
import { formatDDMMYYYY, formatHHMM, formatFilesize, downloadFile, isMobileAgent } from "../_common/common";
import './comunicados.scss';
import {ModalWhatsapp} from "./modal-whatsapp";
import {ModalLink} from "../_common/components/modal";
import {formatDate} from "../utils";
import {ButtonGhost} from "../_common/components/buttons/buttons.tsx";
import {Link} from "react-router-dom";


export const Comunicado = (props) => {
    const dispatch = useDispatch();
    const com = useSelector( state => state.comunicado );
    const adjuntos = useSelector( state => state.comunicado_adjuntos );
    const meta = useSelector(state => state.status.student_meta);
    const IS_PREMIUM_USER = meta?.has_whatsapp;

    useEffect(() => {
        const { match } = props;
        if (!match.params || !match.params.param) {
            throw new Error(t('No uid'));
        }
        const uid = match.params.param;
        dispatch(fetchComunicado(uid));
        dispatch(fetchComunicadoAdjuntosMeta(uid));
        return () => dispatch(clearComunciado())
    }, []);


    async function onClickAdjunto(a) {
        await downloadFile(`${micuentaenv.Notice_BaseUrl}/api/v1/user/downloadAttachment/${a.uuid}`, a.filename);
    }

    return <AppPage
        name="comunicado"
        showHeader={false}
        class="max-width">
        <F className="max-width-980 comunicados">
            {!com ?
                t('Cargando')+'...' :
                <Fragment><br/><br/>
                    <div className='sm-card p-all sm-p-x2-left sm-p-x2-right sm-p-x2-top sm-p-x2-bottom'>
                        <h1>{com.title}</h1>
                        <div className='m-x2-top m-x2-bottom' dangerouslySetInnerHTML={{__html: treatBody(com.body)}} />
                        {(adjuntos && adjuntos.length > 0) && <div>
                            <hr className="separator-xxs"/>
                            {
                                adjuntos.map(attachment => <div onClick={() => onClickAdjunto(attachment)} className='t-underline m-r txt-color-primary cursor-pointer'>
                                    {attachment.filename}
                                </div>)
                            }
                        </div>
                        }
                        <hr className="separator-xxs"/>
                        {com.received_at && <span className='font-color-486268 font-14'>{t('Publicado')}: {formatDate(com.received_at)}</span>}
                    </div>

                    { IS_PREMIUM_USER &&
                        <div className='card sm-p-x2-right sm-p-x2-left p-top p-bottom m-top m-l m-r sm-no-m-l sm-no-m-r'>
                            <FR className='flex-center-y'>
                                <div className='sm-col-50 col-100 p-left p-right sm-no-p-left sm-no-p-right'>{t('Para cualquier consulta, puedes contactar con tu gestor a través de Whatsapp.')}</div>
                                <div className='sm-col-50 col-100'>
                                    {
                                        isMobileAgent()
                                            ? <div className="wa-message-us m-r m-l"
                                                   number="34639214799"
                                                   label={t('Contacta con tu gestor')}
                                                   color="white"
                                                   size="standard">
                                                    <script src="https://cdn.smooch.io/whatsapp/message-us-btn.min.js" type="text/javascript" />
                                            </div>
                                            : <Fragment>
                                                <ModalLink id="whatsapp">
                                                    <ButtonGhost className='d-flex flex-center-y background-transparent sm-auto-width m-l'>
                                                        <i className="fab fa-whatsapp font-24 m-r" />{t('Contacta con tu gestor')}
                                                    </ButtonGhost>
                                                </ModalLink>
                                                <ModalWhatsapp id="whatsapp"/>
                                              </Fragment>
                                    }

                                </div>
                            </FR>
                        </div>
                    }
                    <div className=' m-top m-l sm-no-m-l'><Link to='/' className="back breadcrumb-item">← {t('Volver')}</Link></div>
                </Fragment>
            }
        </F>
    </AppPage>
}

const treatBody = body => {
    return body.replace(/<p><br><\/p>/gi, '')
}
