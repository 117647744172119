import {Fragment, h} from "preact";
import { PS_SHOPID_ES } from '../../../constants'
import { RE } from '../../../_common/layout'
import { t } from '../../../_i18n'
import { useSelector } from 'react-redux'

export default function NotEnrolled () {
  const student_meta = useSelector(state => state.status.student_meta)

  const shopUrl = micuentaenv.SHOP_URL[student_meta.shop_id]||micuentaenv.SHOP_URL[PS_SHOPID_ES];
  return (
    <Fragment>
      <RE className="t-center">
        <span className="font-light">{t('Todavía no te has matriculado en ILERNA.')}</span>
      </RE>
      <RE className="flex-center-x">
        <div className="col-100 sm-col-40 block-center-x">
          <a className="btn btn-primary full-width" href={shopUrl}>{t('Matrícúlate ahora')}</a>
        </div>
      </RE>
    </Fragment>
  )
}
