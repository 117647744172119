import { h } from "preact";
import AlertCollapse from '../../../_common/components/AlertCollapse/AlertCollapse'
import { t } from '../../../_i18n'

export default function AlertLimitDateDanger ({ date }) {
  const textTitle = t('alert_limit_date_danger').replace('{date}', date.toFormat('dd/MM/yyyy'))
  const textBody = t('alert_limit_date_danger_body').replace('{date}', date.toFormat('dd/MM/yyyy'))

  return (
    <AlertCollapse title={textTitle} type='danger'>
      <div dangerouslySetInnerHTML={{ __html: textBody }} />
    </AlertCollapse>
  )
}
