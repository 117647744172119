import { useEffect, useState } from 'preact/hooks'
import {
  fetchAllDocuments,
  fetchCities,
  fetchProvinces, fetchStatus,
  postDocumentsForm,
  postDocumentsMetaDraft
} from '../../../_rx/fct/actions'
import { useParams, useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import useFctMeta from '../../hooks/useFctMeta'

const work_types = [
  {key: 'work_others', name: 'Trabajador por cuenta ajena'},
  {key: 'freelance', name: 'Autónomo/a'},
]

export default function useEvents () {
  const { screen_data: { contributed_days } } = useSelector(state => state.fct.screenState)
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const meta = useFctMeta(id)
  const formative_zone = meta.formative_zone
  const [isLoadingDraft, setIsLoadingDraft] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [citiesDossier, setCitiesDossier] = useState([])
  const [documents, setDocuments] = useState(null)

  const getExemptionTypes = () => {
    const percentsArray = []
    Object.keys(contributed_days).forEach(percentNumber => {
      if (contributed_days[percentNumber]) {
        const percent = percentNumber.split('_')[1]
        percentsArray.push({key: parseInt(percent), name: `${percent}%`, days: contributed_days[percentNumber]})
      }
    })
    return percentsArray
  }

  const exemption_types = getExemptionTypes()

  useEffect(() => {
    fetchProvinces().then(provinces => setProvinces(provinces.map(province => ({key: province.id, name: province.name}))))
    fetchAllDocuments(id).then((documents) => {
      if (documents.meta.fct_in_other_city.province_id !== -1 && documents.meta.fct_in_other_city.province_id) {
        fetchCities(documents.meta.fct_in_other_city.province_id).then(cities => setCities(cities.map(city => ({key: city.id, name: city.name}))))
      }
      if (documents.meta.material_address.province_id !== -1 && documents.meta.material_address.province_id) {
        fetchCities(documents.meta.material_address.province_id).then(cities => setCitiesDossier(cities.map(city => ({key: city.id, name: city.name}))))
      }
      setDocuments(documents)
    })
  }, [])

  const handleExemptionCheck = (val, form) => {
    if (!val) {
      form.change('meta.exemption.percent', 0)
      form.change('meta.exemption.type', '')
    }
  }

  const handlePracticiesPlaceCheck = (val, form) => {
    if (!val) {
      form.change('meta.fct_in_other_city.province_id', -1)
      form.change('meta.fct_in_other_city.city_id', -1)
      setCities([])
    }
  }

  const handleChangeProvincePracticies = (val, form) => {
    if (val && (val !== '' || val !== -1)) {
      fetchCities(val).then(cities => setCities(cities.map(city => ({key: city.id, name: city.name}))))
      form.change('meta.fct_in_other_city.city_id', -1)
    } else {
      setCities([])
    }
  }

  const handleDossierCheck = (val, form) => {
    if (!val) {
      form.change('meta.material_address.address', '')
      form.change('meta.material_address.cp', '')
      form.change('meta.material_address.province_id', -1)
      form.change('meta.material_address.city', -1)
      setCitiesDossier([])
    }
  }

  const handleChangeProvinceDossier = (val, form) => {
    if (val && (val !== '' || val !== -1)) {
      fetchCities(val).then(cities => setCitiesDossier(cities.map(city => ({key: city.id, name: city.name}))))
      form.change('meta.material_address.city', -1)
    } else {
      setCitiesDossier([])
    }
  }

  const saveDraft = (values) => {
    setIsLoadingDraft(true)
    postDocumentsMetaDraft(id, values)
      .finally(() => setIsLoadingDraft(false))
  }

  const submitForm = async (values) => {
    setIsLoadingSubmit(true)
    await postDocumentsMetaDraft(id, values.meta)
    postDocumentsForm(id)
      .then(() => {
        dispatch(fetchStatus(id))
        setIsLoadingSubmit(false)
        history.push(`/fct/${id}`)
      })
      .catch(() => setIsLoadingSubmit(false))
  }

  return { handleExemptionCheck, handleChangeProvincePracticies, handlePracticiesPlaceCheck, saveDraft, submitForm, handleDossierCheck, handleChangeProvinceDossier, exemption_types, work_types, documents, provinces, cities, citiesDossier, formative_zone, isLoadingDraft, isLoadingSubmit }
}
