/**
 * Notice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import InlineResponse2006 from '../model/InlineResponse2006';
import InlineResponse404 from '../model/InlineResponse404';
import Send from '../model/Send';

/**
* Send service.
* @module api/SendApi
* @version 1.0
*/
export default class SendApi {

    /**
    * Constructs a new SendApi. 
    * @alias module:api/SendApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get a send detail
     * @param {Number} id Id of the send
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Send} and HTTP response
     */
    appHttpControllersSendControllerDetailsWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersSendControllerDetails");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Send;
      return this.apiClient.callApi(
        '/api/v1/send/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get a send detail
     * @param {Number} id Id of the send
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Send}
     */
    appHttpControllersSendControllerDetails(id) {
      return this.appHttpControllersSendControllerDetailsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List sends
     * @param {Object} opts Optional parameters
     * @param {String} opts.uuid Uuid filter
     * @param {Number} opts.mailId Id of the email filter
     * @param {Number} opts.firebaseId Firebase message id filter
     * @param {Number} opts.noticeId Id of the notice filter
     * @param {String} opts.username Destination filter
     * @param {Number} opts.page Page number
     * @param {Number} opts.limit Number of elements per page
     * @param {module:model/String} opts.sort Sort by field
     * @param {module:model/String} opts.sortOrder sort order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2006} and HTTP response
     */
    appHttpControllersSendControllerIndexWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'uuid': opts['uuid'],
        'mail_id': opts['mailId'],
        'firebase_id': opts['firebaseId'],
        'notice_id': opts['noticeId'],
        'username': opts['username'],
        'page': opts['page'],
        'limit': opts['limit'],
        'sort': opts['sort'],
        'sort_order': opts['sortOrder']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2006;
      return this.apiClient.callApi(
        '/api/v1/sends', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List sends
     * @param {Object} opts Optional parameters
     * @param {String} opts.uuid Uuid filter
     * @param {Number} opts.mailId Id of the email filter
     * @param {Number} opts.firebaseId Firebase message id filter
     * @param {Number} opts.noticeId Id of the notice filter
     * @param {String} opts.username Destination filter
     * @param {Number} opts.page Page number
     * @param {Number} opts.limit Number of elements per page
     * @param {module:model/String} opts.sort Sort by field
     * @param {module:model/String} opts.sortOrder sort order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2006}
     */
    appHttpControllersSendControllerIndex(opts) {
      return this.appHttpControllersSendControllerIndexWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Mark a send as unread
     * @param {Number} id Id of the send
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Send} and HTTP response
     */
    appHttpControllersSendControllerMarkUnreadWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling appHttpControllersSendControllerMarkUnread");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Send;
      return this.apiClient.callApi(
        '/api/v1/send/{id}/mark_unread', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Mark a send as unread
     * @param {Number} id Id of the send
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Send}
     */
    appHttpControllersSendControllerMarkUnread(id) {
      return this.appHttpControllersSendControllerMarkUnreadWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Send a notice to a user
     * @param {String} typeTag Type of the notice
     * @param {String} username Username destination
     * @param {Object} opts Optional parameters
     * @param {String} opts.title 
     * @param {String} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse404} and HTTP response
     */
    appHttpControllersSendControllerSendWithHttpInfo(typeTag, username, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'typeTag' is set
      if (typeTag === undefined || typeTag === null) {
        throw new Error("Missing the required parameter 'typeTag' when calling appHttpControllersSendControllerSend");
      }
      // verify the required parameter 'username' is set
      if (username === undefined || username === null) {
        throw new Error("Missing the required parameter 'username' when calling appHttpControllersSendControllerSend");
      }

      let pathParams = {
        'type_tag': typeTag,
        'username': username
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'title': opts['title'],
        'body': opts['body']
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = InlineResponse404;
      return this.apiClient.callApi(
        '/api/v1/send/{type_tag}/{username}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Send a notice to a user
     * @param {String} typeTag Type of the notice
     * @param {String} username Username destination
     * @param {Object} opts Optional parameters
     * @param {String} opts.title 
     * @param {String} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse404}
     */
    appHttpControllersSendControllerSend(typeTag, username, opts) {
      return this.appHttpControllersSendControllerSendWithHttpInfo(typeTag, username, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Send a welcome notice
     * @param {String} email Email address
     * @param {String} lang Lenguage address
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    appHttpControllersSendControllerWelcomeWithHttpInfo(email, lang) {
      let postBody = null;
      // verify the required parameter 'email' is set
      if (email === undefined || email === null) {
        throw new Error("Missing the required parameter 'email' when calling appHttpControllersSendControllerWelcome");
      }
      // verify the required parameter 'lang' is set
      if (lang === undefined || lang === null) {
        throw new Error("Missing the required parameter 'lang' when calling appHttpControllersSendControllerWelcome");
      }

      let pathParams = {
      };
      let queryParams = {
        'email': email,
        'lang': lang
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/send/welcome', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Send a welcome notice
     * @param {String} email Email address
     * @param {String} lang Lenguage address
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    appHttpControllersSendControllerWelcome(email, lang) {
      return this.appHttpControllersSendControllerWelcomeWithHttpInfo(email, lang)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
