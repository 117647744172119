// Función para validar IBAN
export const validateIBAN = value => {
    if (!value) {
        return 'Este campo es obligatorio';
    }

    // Eliminar espacios en blanco del IBAN
    const iban = value.replace(/\s/g, '');

    // if (iban.length !== 24) {
    //     return 'El IBAN debe tener exactamente 24 caracteres';
    // }

    // Verificar que el IBAN solo contiene caracteres alfanuméricos
    if (!/^[A-Za-z0-9]+$/.test(iban)) {
        return 'El IBAN solo debe contener caracteres alfanuméricos';
    }

    // Mover los cuatro primeros caracteres al final del IBAN
    const rearrangedIBAN = iban.slice(4) + iban.slice(0, 4);

    // Reemplazar cada letra en el IBAN por dos dígitos
    const numericIBAN = rearrangedIBAN.split('').map(char => {
        const code = char.toUpperCase().charCodeAt(0);
        return code >= 65 && code <= 90 ? (code - 55).toString() : char;
    }).join('');

    // Verificar el dígito de control utilizando el Módulo 97
    let remainder = numericIBAN;
    while (remainder.length > 2) {
        const block = remainder.slice(0, 9);
        remainder = (parseInt(block, 10) % 97).toString() + remainder.slice(9);
    }

    if (parseInt(remainder, 10) % 97 !== 1) {
        return 'El IBAN ingresado no es válido';
    }

    return undefined;
};

export const formatIBAN = iban => {
    if (typeof iban !== 'string' || iban.trim() === '') {
        return '';
    }

    // Eliminar espacios en blanco del IBAN
    iban = iban.replace(/\s/g, '');

    if (iban.length < 4) {
        return iban;
    }
    // Dividir el IBAN en grupos de cuatro caracteres
    return iban.match(/.{1,4}/g).join(' ');
};

