import AlertBox from '../../../_common/components/AlertBox/AlertBox'
import { t } from '../../../_i18n'
import { Fragment, h } from 'preact'
import { ButtonPrimary } from '../../../_common/components/buttons/buttons'
import { connect } from 'react-redux'
import { fetchSubjectOfferDetails, setReviewParams } from '../../../_rx/expediente/actions'
import { LinkCampus } from '../../../app/header-component'


function AlertMessageView ({ subjectOffer, canvasId, shopId }) {
  const linkCTA = LinkCampus(`/users/${canvasId}/external_tools/36`, shopId)
  const mandatoryClassesAlert = subjectOffer.has_compulsory_classes && subjectOffer.mandatory_compulsory_classes && !subjectOffer.has_done_compulsory_classes
  const pacsAlert = false
  const allowExamInscription = subjectOffer.allowed_to_join

  const examSuspended = () => (
    <Fragment>
      <div dangerouslySetInnerHTML={{__html: t('text_info_exam_review')}}/>
      {allowExamInscription &&
        <div className='m-top'><a href={linkCTA} target='_blank'>
          <ButtonPrimary className='full-width sm-auto-width'>
            <span dangerouslySetInnerHTML={{__html: t('Inscripción a Examen')}}/>
          </ButtonPrimary>
        </a></div>
      }
    </Fragment>
  )

  const classMandatory = () => <div dangerouslySetInnerHTML={{__html: t('text_info_class_mandatory')}}/>

  const pacsMin = () => (
    <Fragment>
      <div dangerouslySetInnerHTML={{__html: t('text_info_pacs_min')}}/>
      {allowExamInscription &&
        <div className='m-top'><a href={linkCTA} target='_blank'>
          <ButtonPrimary className='full-width sm-auto-width'>
            <span dangerouslySetInnerHTML={{__html: t('Inscripción a Examen')}}/>
          </ButtonPrimary>
        </a></div>
      }
    </Fragment>
  )

  return (
    <AlertBox type='info' className='m-top m-bottom line-height-22'>
      {
        mandatoryClassesAlert ? classMandatory()
        : pacsAlert ? pacsMin()
        : examSuspended()
      }
    </AlertBox>
  )
}

AlertMessageView = connect(
  ({ status }) => ({canvasId: status.student_meta.canvas_id, shopId: status.student_meta.shop_id}),
  {fetchSubjectOfferDetails, setReviewParams})(AlertMessageView);

let AlertMessage = ({...props}) => <AlertMessageView {...props}/>


export default AlertMessage;

