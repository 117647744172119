/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from './ApiClient';
import AcademicProgram from './model/AcademicProgram';
import AdminCompany from './model/AdminCompany';
import Answer from './model/Answer';
import ApplicationsCount from './model/ApplicationsCount';
import BusinessSectors from './model/BusinessSectors';
import CandidateAcademicProgramCompletion from './model/CandidateAcademicProgramCompletion';
import CandidateCandidate from './model/CandidateCandidate';
import CandidateCompany from './model/CandidateCompany';
import CandidateCountOfferPerState from './model/CandidateCountOfferPerState';
import CandidateDocument from './model/CandidateDocument';
import CandidateLetter from './model/CandidateLetter';
import CandidateLink from './model/CandidateLink';
import CandidateOffer from './model/CandidateOffer';
import CandidateOfferFilters from './model/CandidateOfferFilters';
import CandidateOfferFiltersModalityList from './model/CandidateOfferFiltersModalityList';
import CandidateOfferItem from './model/CandidateOfferItem';
import CandidateOfferItemPaginated from './model/CandidateOfferItemPaginated';
import CandidateOffersIdApplyQuestions from './model/CandidateOffersIdApplyQuestions';
import CandidatePublicOfferItem from './model/CandidatePublicOfferItem';
import CompanyApplication from './model/CompanyApplication';
import CompanyApplicationFilers from './model/CompanyApplicationFilers';
import CompanyApplicationItem from './model/CompanyApplicationItem';
import CompanyChangeEmail from './model/CompanyChangeEmail';
import CompanyChangePassword from './model/CompanyChangePassword';
import CompanyCompany from './model/CompanyCompany';
import CompanyLogin from './model/CompanyLogin';
import CompanyNewCompany from './model/CompanyNewCompany';
import CompanyOffer from './model/CompanyOffer';
import CompanyOfferFilters from './model/CompanyOfferFilters';
import CompanyOfferItem from './model/CompanyOfferItem';
import CompanyPaginatedOfferItems from './model/CompanyPaginatedOfferItems';
import CompanyUser from './model/CompanyUser';
import ContractType from './model/ContractType';
import DefaultResponse from './model/DefaultResponse';
import Event from './model/Event';
import FormativeFamilies from './model/FormativeFamilies';
import InlineObject from './model/InlineObject';
import InlineObject1 from './model/InlineObject1';
import InlineObject2 from './model/InlineObject2';
import InlineResponse200 from './model/InlineResponse200';
import LoginResponse from './model/LoginResponse';
import OffersCount from './model/OffersCount';
import Pagination from './model/Pagination';
import Presence from './model/Presence';
import Question from './model/Question';
import Schedule from './model/Schedule';
import State from './model/State';
import Status from './model/Status';
import AdminApi from './api/AdminApi';
import CandidateApi from './api/CandidateApi';
import CompanyApi from './api/CompanyApi';
import CompanyAuthApi from './api/CompanyAuthApi';
import FixturesApi from './api/FixturesApi';
import LandingApi from './api/LandingApi';


/**
* Backend_de_bolsa_de_trabajo_para_alumno_empresa_y_admin_Pantallas_de_Alumno__P_A_xx__https__app_axure_cloud_app_project_5t0fsq_overviewPantallas_de_empresa__P_E_xx___https__app_axure_cloud_app_project_jcj3w8_overview.<br>
* The <code>index</code> module provides access to constructors for all the classes which comprise the public API.
* <p>
* An AMD (recommended!) or CommonJS application will generally do something equivalent to the following:
* <pre>
* var JobsApi = require('index'); // See note below*.
* var xxxSvc = new JobsApi.XxxApi(); // Allocate the API class we're going to use.
* var yyyModel = new JobsApi.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* <em>*NOTE: For a top-level AMD script, use require(['index'], function(){...})
* and put the application logic within the callback function.</em>
* </p>
* <p>
* A non-AMD browser application (discouraged) might do something like this:
* <pre>
* var xxxSvc = new JobsApi.XxxApi(); // Allocate the API class we're going to use.
* var yyy = new JobsApi.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* </p>
* @module index
* @version v1
*/
export {
    /**
     * The ApiClient constructor.
     * @property {module:ApiClient}
     */
    ApiClient,

    /**
     * The AcademicProgram model constructor.
     * @property {module:model/AcademicProgram}
     */
    AcademicProgram,

    /**
     * The AdminCompany model constructor.
     * @property {module:model/AdminCompany}
     */
    AdminCompany,

    /**
     * The Answer model constructor.
     * @property {module:model/Answer}
     */
    Answer,

    /**
     * The ApplicationsCount model constructor.
     * @property {module:model/ApplicationsCount}
     */
    ApplicationsCount,

    /**
     * The BusinessSectors model constructor.
     * @property {module:model/BusinessSectors}
     */
    BusinessSectors,

    /**
     * The CandidateAcademicProgramCompletion model constructor.
     * @property {module:model/CandidateAcademicProgramCompletion}
     */
    CandidateAcademicProgramCompletion,

    /**
     * The CandidateCandidate model constructor.
     * @property {module:model/CandidateCandidate}
     */
    CandidateCandidate,

    /**
     * The CandidateCompany model constructor.
     * @property {module:model/CandidateCompany}
     */
    CandidateCompany,

    /**
     * The CandidateCountOfferPerState model constructor.
     * @property {module:model/CandidateCountOfferPerState}
     */
    CandidateCountOfferPerState,

    /**
     * The CandidateDocument model constructor.
     * @property {module:model/CandidateDocument}
     */
    CandidateDocument,

    /**
     * The CandidateLetter model constructor.
     * @property {module:model/CandidateLetter}
     */
    CandidateLetter,

    /**
     * The CandidateLink model constructor.
     * @property {module:model/CandidateLink}
     */
    CandidateLink,

    /**
     * The CandidateOffer model constructor.
     * @property {module:model/CandidateOffer}
     */
    CandidateOffer,

    /**
     * The CandidateOfferFilters model constructor.
     * @property {module:model/CandidateOfferFilters}
     */
    CandidateOfferFilters,

    /**
     * The CandidateOfferFiltersModalityList model constructor.
     * @property {module:model/CandidateOfferFiltersModalityList}
     */
    CandidateOfferFiltersModalityList,

    /**
     * The CandidateOfferItem model constructor.
     * @property {module:model/CandidateOfferItem}
     */
    CandidateOfferItem,

    /**
     * The CandidateOfferItemPaginated model constructor.
     * @property {module:model/CandidateOfferItemPaginated}
     */
    CandidateOfferItemPaginated,

    /**
     * The CandidateOffersIdApplyQuestions model constructor.
     * @property {module:model/CandidateOffersIdApplyQuestions}
     */
    CandidateOffersIdApplyQuestions,

    /**
     * The CandidatePublicOfferItem model constructor.
     * @property {module:model/CandidatePublicOfferItem}
     */
    CandidatePublicOfferItem,

    /**
     * The CompanyApplication model constructor.
     * @property {module:model/CompanyApplication}
     */
    CompanyApplication,

    /**
     * The CompanyApplicationFilers model constructor.
     * @property {module:model/CompanyApplicationFilers}
     */
    CompanyApplicationFilers,

    /**
     * The CompanyApplicationItem model constructor.
     * @property {module:model/CompanyApplicationItem}
     */
    CompanyApplicationItem,

    /**
     * The CompanyChangeEmail model constructor.
     * @property {module:model/CompanyChangeEmail}
     */
    CompanyChangeEmail,

    /**
     * The CompanyChangePassword model constructor.
     * @property {module:model/CompanyChangePassword}
     */
    CompanyChangePassword,

    /**
     * The CompanyCompany model constructor.
     * @property {module:model/CompanyCompany}
     */
    CompanyCompany,

    /**
     * The CompanyLogin model constructor.
     * @property {module:model/CompanyLogin}
     */
    CompanyLogin,

    /**
     * The CompanyNewCompany model constructor.
     * @property {module:model/CompanyNewCompany}
     */
    CompanyNewCompany,

    /**
     * The CompanyOffer model constructor.
     * @property {module:model/CompanyOffer}
     */
    CompanyOffer,

    /**
     * The CompanyOfferFilters model constructor.
     * @property {module:model/CompanyOfferFilters}
     */
    CompanyOfferFilters,

    /**
     * The CompanyOfferItem model constructor.
     * @property {module:model/CompanyOfferItem}
     */
    CompanyOfferItem,

    /**
     * The CompanyPaginatedOfferItems model constructor.
     * @property {module:model/CompanyPaginatedOfferItems}
     */
    CompanyPaginatedOfferItems,

    /**
     * The CompanyUser model constructor.
     * @property {module:model/CompanyUser}
     */
    CompanyUser,

    /**
     * The ContractType model constructor.
     * @property {module:model/ContractType}
     */
    ContractType,

    /**
     * The DefaultResponse model constructor.
     * @property {module:model/DefaultResponse}
     */
    DefaultResponse,

    /**
     * The Event model constructor.
     * @property {module:model/Event}
     */
    Event,

    /**
     * The FormativeFamilies model constructor.
     * @property {module:model/FormativeFamilies}
     */
    FormativeFamilies,

    /**
     * The InlineObject model constructor.
     * @property {module:model/InlineObject}
     */
    InlineObject,

    /**
     * The InlineObject1 model constructor.
     * @property {module:model/InlineObject1}
     */
    InlineObject1,

    /**
     * The InlineObject2 model constructor.
     * @property {module:model/InlineObject2}
     */
    InlineObject2,

    /**
     * The InlineResponse200 model constructor.
     * @property {module:model/InlineResponse200}
     */
    InlineResponse200,

    /**
     * The LoginResponse model constructor.
     * @property {module:model/LoginResponse}
     */
    LoginResponse,

    /**
     * The OffersCount model constructor.
     * @property {module:model/OffersCount}
     */
    OffersCount,

    /**
     * The Pagination model constructor.
     * @property {module:model/Pagination}
     */
    Pagination,

    /**
     * The Presence model constructor.
     * @property {module:model/Presence}
     */
    Presence,

    /**
     * The Question model constructor.
     * @property {module:model/Question}
     */
    Question,

    /**
     * The Schedule model constructor.
     * @property {module:model/Schedule}
     */
    Schedule,

    /**
     * The State model constructor.
     * @property {module:model/State}
     */
    State,

    /**
     * The Status model constructor.
     * @property {module:model/Status}
     */
    Status,

    /**
    * The AdminApi service constructor.
    * @property {module:api/AdminApi}
    */
    AdminApi,

    /**
    * The CandidateApi service constructor.
    * @property {module:api/CandidateApi}
    */
    CandidateApi,

    /**
    * The CompanyApi service constructor.
    * @property {module:api/CompanyApi}
    */
    CompanyApi,

    /**
    * The CompanyAuthApi service constructor.
    * @property {module:api/CompanyAuthApi}
    */
    CompanyAuthApi,

    /**
    * The FixturesApi service constructor.
    * @property {module:api/FixturesApi}
    */
    FixturesApi,

    /**
    * The LandingApi service constructor.
    * @property {module:api/LandingApi}
    */
    LandingApi
};
