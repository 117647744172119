/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AdminCompany model module.
 * @module model/AdminCompany
 * @version v1
 */
class AdminCompany {
    /**
     * Constructs a new <code>AdminCompany</code>.
     * Elemento de empresa, misma entidad para listado y detalle. Mezcla usuario y empresa
     * @alias module:model/AdminCompany
     */
    constructor() { 
        
        AdminCompany.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AdminCompany</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AdminCompany} obj Optional instance to populate.
     * @return {module:model/AdminCompany} The populated <code>AdminCompany</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AdminCompany();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('nif')) {
                obj['nif'] = ApiClient.convertToType(data['nif'], 'String');
            }
            if (data.hasOwnProperty('text')) {
                obj['text'] = ApiClient.convertToType(data['text'], 'String');
            }
            if (data.hasOwnProperty('contact_email')) {
                obj['contact_email'] = ApiClient.convertToType(data['contact_email'], 'String');
            }
            if (data.hasOwnProperty('contact_name')) {
                obj['contact_name'] = ApiClient.convertToType(data['contact_name'], 'String');
            }
            if (data.hasOwnProperty('contact_surname')) {
                obj['contact_surname'] = ApiClient.convertToType(data['contact_surname'], 'String');
            }
            if (data.hasOwnProperty('contact_phone')) {
                obj['contact_phone'] = ApiClient.convertToType(data['contact_phone'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('offers_fct')) {
                obj['offers_fct'] = ApiClient.convertToType(data['offers_fct'], 'Number');
            }
            if (data.hasOwnProperty('offers_job')) {
                obj['offers_job'] = ApiClient.convertToType(data['offers_job'], 'Number');
            }
            if (data.hasOwnProperty('candidates_fct')) {
                obj['candidates_fct'] = ApiClient.convertToType(data['candidates_fct'], 'Number');
            }
            if (data.hasOwnProperty('candidates_job')) {
                obj['candidates_job'] = ApiClient.convertToType(data['candidates_job'], 'Number');
            }
            if (data.hasOwnProperty('completed')) {
                obj['completed'] = ApiClient.convertToType(data['completed'], 'Boolean');
            }
            if (data.hasOwnProperty('emulation_link')) {
                obj['emulation_link'] = ApiClient.convertToType(data['emulation_link'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
AdminCompany.prototype['id'] = undefined;

/**
 * @member {String} name
 */
AdminCompany.prototype['name'] = undefined;

/**
 * @member {String} nif
 */
AdminCompany.prototype['nif'] = undefined;

/**
 * @member {String} text
 */
AdminCompany.prototype['text'] = undefined;

/**
 * @member {String} contact_email
 */
AdminCompany.prototype['contact_email'] = undefined;

/**
 * @member {String} contact_name
 */
AdminCompany.prototype['contact_name'] = undefined;

/**
 * @member {String} contact_surname
 */
AdminCompany.prototype['contact_surname'] = undefined;

/**
 * @member {String} contact_phone
 */
AdminCompany.prototype['contact_phone'] = undefined;

/**
 * new, accepted
 * @member {String} status
 */
AdminCompany.prototype['status'] = undefined;

/**
 * number of offers fct
 * @member {Number} offers_fct
 */
AdminCompany.prototype['offers_fct'] = undefined;

/**
 * number of offers job
 * @member {Number} offers_job
 */
AdminCompany.prototype['offers_job'] = undefined;

/**
 * number of candidates in total fct
 * @member {Number} candidates_fct
 */
AdminCompany.prototype['candidates_fct'] = undefined;

/**
 * number of candidates in total job
 * @member {Number} candidates_job
 */
AdminCompany.prototype['candidates_job'] = undefined;

/**
 * profile completed
 * @member {Boolean} completed
 */
AdminCompany.prototype['completed'] = undefined;

/**
 * link to enter emulating the company
 * @member {String} emulation_link
 */
AdminCompany.prototype['emulation_link'] = undefined;






export default AdminCompany;

