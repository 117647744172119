
class ToastService{

    _cb = null;

    setCallback(cb){ this._cb = cb; }

    showToast(t){
        this._cb && this._cb(t);
    }

}

export const toastService = new ToastService();
