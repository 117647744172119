import { Fragment, h } from "preact";
import { useSelector } from 'react-redux'
import AlertExemption from '../../components/Alerts/AlertExemption'
import { t } from '../../../_i18n'
import {DateTime} from "luxon";
import {isSpanishUser} from "../../../_common/utils/getLang";
import useFctMeta from "../../hooks/useFctMeta";
import {useParams} from "react-router";

const STATE_3 = '3.3'

export default function Convention () {
  const screenStatus = useSelector(state => state.fct.screenState);
  const shopId = useSelector(state => state.status.student_meta.shop_id);
  const screenState = screenStatus.screen;
  const { id } = useParams();
  const meta = useFctMeta(id);
  const formative_zone = meta.formative_zone;
  let agreementCommitmentDate = screenStatus.screen_data.real_contract_limit;
  if (agreementCommitmentDate) {
    agreementCommitmentDate = DateTime.fromFormat(screenStatus.screen_data.real_contract_limit, 'yyyy-MM-dd').toFormat('dd/MM/yyyy');
  }

  return (
    <div>
      { screenState === STATE_3 && <AlertExemption exemption={screenStatus.screen_data.exemption_percent} className='m-bottom' /> }
      <p className='font-bold'>{t('Estamos en conversación con la empresa para poder acordar las condiciones y fecha de inicio de tus prácticas.')}</p>
      { formative_zone !== 'MAD' && isSpanishUser(shopId) && agreementCommitmentDate && <p>{t('Nuestro objetivo será tener el convenio cerrado antes del ')}<span className='font-bold'>{agreementCommitmentDate}.</span></p> }
      <p className='m-top'>{t('Cuando este listo, contactaremos contigo para notificarte el acuerdo final.')} </p>
    </div>
  )
}
