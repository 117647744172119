import React, {Fragment, h} from "preact";
import {Link} from "react-router-dom";
import {t} from '../../_i18n';
import {Card, CF, E, FR, R} from "../../_common/layout";

export const  InformacionContacto = ({student}) =>
    <Fragment>
        {student.address && <Card smOnly={false}>
            {/*<pre>{JSON.stringify(student,null,'  ')}</pre>*/}
            <FR className="flex-center-x flex-center-y">
                <div className="sm-col-65">
                    <h1>{t('Información de contacto')}</h1>
                </div>
                <div className="sm-col-35 d-none sm-d-flex flex-right">
                    <Link className={'btn btn-ghost'} to="/student">{t('Editar')}</Link>
                </div>
                <div className="full-width">
                    <E><span>{student.address.name} &nbsp; {student.address.city.name} &nbsp; ({student.address.city.state.name})</span></E>
                </div>
                <div className="full-width">
                    <E><span>{student.data.email}</span></E>
                </div>
                <div className="full-width">
                    <E><span>{student.address.phone} </span></E>
                </div>
                <div className="sm-col-35 sm-d-none d-flex flex-left full-width">
                    <Link className={'btn btn-ghost'} to="/student">{t('Editar')}</Link>
                </div>
            </FR>
        </Card>
        }
    </Fragment>
