import {
   SET_INVITE_FRIEND_STATUS, SET_INVITE_FRIEND_ADDRESS
} from "./constants";
import {svcPsService} from '../../../_services/ps-service'
import {globalCatch} from "../../../_services/global-catch";
import {toastService} from "../../../_services/toast-service";
import {fetchStatusPresta} from '../../statusPresta/actions'
import {t} from "../../../_i18n";

export const setInviteFriendStatus = payload => ({type: SET_INVITE_FRIEND_STATUS, payload});
export const fetchInviteFriendStatus = () => globalCatch.catch(async (dispatch) => {
   const data = await (await svcPsService()).doWhateverOperation('InviteFriendGetStatus')
   dispatch(setInviteFriendStatus(data))
   dispatch(fetchStatusPresta())
});

export const setAddressInviteFriend = payload => ({type: SET_INVITE_FRIEND_ADDRESS, payload});
export const fetchAddressInviteFriend = () => globalCatch.catch(async (dispatch) => {
   const data = await (await svcPsService()).doWhateverOperation('GetDireccionEnvio')
   dispatch(setAddressInviteFriend(data))
});

export const sendInviteFriend = (email) => globalCatch.catch(async (dispatch) => {
   await (await svcPsService()).doWhateverOperation('InviteFriendSetEmail', {email_friend: email})
   dispatch (fetchInviteFriendStatus())
});

export const sendGiftSelected = (giftSelected) => globalCatch.catch(async (dispatch) => {
   await (await svcPsService()).doWhateverOperation('InviteFriendSetGift', {
      gift: giftSelected
   })
   toastService.showToast({message:t("Tus datos se han guardado correctamente"), type:'info'});
   dispatch (fetchInviteFriendStatus())
});

