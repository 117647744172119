import {asyncLazySingleton, getHttp} from "../_common/common.ts";
import {UserApi, ProfileSettingApi} from "../_api/notice/src";

export default class NoticeService {

    constructor() {
        this._api = new UserApi();
        this._ProfileSettingApi = new ProfileSettingApi();
    }

    async login(){
        let result = await getHttp(micuentaenv.Notice_BaseUrl + '/api/login', null, true);
        if(result === false){
            result = await getHttp(micuentaenv.SSO_BaseUrl + '/get_st?service='+ encodeURIComponent(window.origin), null, true);

            //no hay cookie gt del cas
            if(result.st == false) window.location.href = micuentaenv.SSO_BaseUrl + '/login?service='+ encodeURIComponent(window.origin)

            result = await getHttp(micuentaenv.Notice_BaseUrl + '/api/login?ticket='+result.st, null, true);
            if(result.status !== 'OK') throw new Error('Notice Login failed');
        }
    }

    async logout(){
        await getHttp(micuentaenv.Notice_BaseUrl + '/api/logout');
    }

    async getComunicados() {
        const comunicados = await this._api.appHttpControllersUserControllerGetAllNoticesWithHttpInfo({ limit: 100 });

        // fix para safari. No reconoce la fecha con espacio, quiere T para que funcione `new Date("2021-01-14T12:14:01")`
        comunicados.data.data.forEach(fixComunicadoDates)
        return comunicados.data.data;
    }

    async getComunicado(uid) {
        const comunicado = await this._api.appHttpControllersUserControllerReadNotice(uid);
        fixComunicadoDates(comunicado)
        return comunicado;
    }

    async getComunicadoAdjuntosMeta(uid) {
        const adjuntos = await this._api.appHttpControllersUserControllerReadNoticeAttachments(uid);
        return adjuntos;
    }

    async downloadAdjunto(uid) {
        const binary = await this._api.appHttpControllersUserControllerDownloadAttachment(uid);
        // alert(binary.length);
        return binary;
    }

    async getProfileSettings() {
        return await this._ProfileSettingApi.appHttpControllersProfileSetControllerIndex()
    }

    async saveProfileSettings(body){
        return await this._ProfileSettingApi.appHttpControllersProfileSetControllerUpdate(body)
    }

    async getStatus() {
        const status = this._api.appHttpControllersUserControllerCountNotices();
        return status;
    }

    async markAsRead(uid) {
        await this._api.appHttpControllersUserControllerReadNotice(uid)
    }

}

function fixComunicadoDates(com){
    if(com.first_open) com.first_open = com.first_open.replace(' ', 'T');
    com.received_at = com.received_at.replace(' ', 'T');
}

export const svcNoticeService = asyncLazySingleton(async ()=>{
    const svc = new NoticeService();
    await svc.login();
    return svc;
}); // singleton
