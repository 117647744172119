/**
 * Jobs API
 * Backend de bolsa de trabajo para alumno, empresa y admin.  Pantallas de Alumno [P_A_xx]   https://app.axure.cloud/app/project/5t0fsq/overview  Pantallas de empresa [P_E_xx]    https://app.axure.cloud/app/project/jcj3w8/overview
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CompanyUser model module.
 * @module model/CompanyUser
 * @version v1
 */
class CompanyUser {
    /**
     * Constructs a new <code>CompanyUser</code>.
     * Usuario de contacto [P_E_15]
     * @alias module:model/CompanyUser
     */
    constructor() { 
        
        CompanyUser.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CompanyUser</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CompanyUser} obj Optional instance to populate.
     * @return {module:model/CompanyUser} The populated <code>CompanyUser</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CompanyUser();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('surname')) {
                obj['surname'] = ApiClient.convertToType(data['surname'], 'String');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
CompanyUser.prototype['id'] = undefined;

/**
 * @member {String} email
 */
CompanyUser.prototype['email'] = undefined;

/**
 * @member {String} name
 */
CompanyUser.prototype['name'] = undefined;

/**
 * @member {String} surname
 */
CompanyUser.prototype['surname'] = undefined;

/**
 * @member {String} phone
 */
CompanyUser.prototype['phone'] = undefined;






export default CompanyUser;

